import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const PaymentSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  color: ${p => p.theme.secondaryColor};
  ${media.smaller`
      min-width: 65%;
  `};
`;

export const SelectedPaymentType = styled.span`
  color: ${p => p.theme.secondaryColor};
  font-weight: bold;
  padding-left: 0.5rem;
  ${media.smaller`
    margin-left: 0;
    min-width: 9rem;
    display: block;
    line-height: 2rem;
  `};
`;

export const ChangePaymentType = styled.div`
  text-transform: uppercase;
  text-align: right;
  color: ${p => p.theme.primaryColor};
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-left: 1rem;
`;

export const CannotChangePaymentType = styled.div`
  text-align: right;
  color: ${p => p.theme.textColor};
  font-size: 1.2rem;
  margin-left: 1rem;
`;

export const PromoInfoText = styled.div`
  font-size: 1.4rem;
  margin-left: 1rem;
  vertical-align: center;
  color: ${p => p.theme.tertiaryColor};
  ${media.smaller`
    font-size: 1.2rem;
  `};
`;
