import React from 'react';
import {JobField, JobWrapper} from './VacanciesPage.style';

export const Job = props => {
  const {link, title, location, type} = props;
  const openLink = () => window.open(link);
  return (
    <JobWrapper key={title} onClick={openLink}>
      <JobField>{title}</JobField>
      <JobField>
        {location}, {type}
      </JobField>
    </JobWrapper>
  );
};
