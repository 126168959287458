import prod from './Root.prod.container';
import dev from './Root.dev.container';

let defaultModule;
if (process.env.NODE_ENV === 'production') {
  defaultModule = prod;
} else {
  defaultModule = dev;
}

export default defaultModule;
