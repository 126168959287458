import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

export default class ModalWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.object.isRequired,
    callback: PropTypes.func,
    showCloseButton: PropTypes.bool,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  render() {
    const {isOpen, customStyles, closeModal} = this.props;
    // const renderCloseButton = this.props.showCloseButton && <div>X</div>;
    if (typeof window === 'undefined') {
      return;
    }
    const appElement = document.getElementById('jjfoods') || undefined;
    return (
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Modal"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc={true}
        ariaHideApp={false}
        appElement={appElement}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}
