export const getCookie = name => {
  if (!document) {
    return;
  }
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
};
