import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {getTimestamp} from '../helpers/api.helper';

export default class branchApi {
  static getAllBranch() {
    const config = getApiConfig();
    return axios.get(config.branchUrl + getTimestamp()).then(res => res.data);
  }
  static getNearestBranch(postcode) {
    const config = getApiConfig();
    const url = `${config.warehouseSearchUrl}/${postcode}`;
    return axios.get(url, {params: {APIKEY: 'random'}}).then(res => res.data);
  }
  static getBranchOpeningTimes() {
    const config = getApiConfig();
    return axios
      .get(config.allWarehouseListUrl + getTimestamp())
      .then(res => res.data);
  }
}
