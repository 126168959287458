import jwt_decode from 'jwt-decode';
import {AUTHORITIES} from '../constants/constants';

export const checkStaffAccount = types =>
  types.some(type => type === AUTHORITIES.ROLE_STAFF);

export const containStaffAccount = auth => {
  try {
    const decodedAuth = !!auth && jwt_decode(auth.access_token);
    if (decodedAuth && decodedAuth.authorities) {
      return checkStaffAccount(decodedAuth.authorities);
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getStoredAccountById = (accounts, id) => {
  let reloginAccount;
  Object.values(accounts).map(account =>
    account.map(acc => {
      if (acc.id === id) {
        reloginAccount = acc;
      }
    })
  );
  return reloginAccount;
};
