import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from '../App';
import {getHistory} from '../../store/history';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Root = ({store}) => {
  const history = getHistory();
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <App history={history} />
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
