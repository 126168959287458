import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {GQL_GET_PRODUCT_DETAILS} from '../gql/productDetails.gql';
import {GQL_GET_FAVOURITE} from '../gql/fav.gql';
import {GQL_GET_RECENTS} from '../gql/recents.gql';

export default class productApi {
  static getProduct(params, headers) {
    const config = getApiConfig();
    return axios({
      url: params.host || config.graphQL,
      method: 'post',
      data: {
        query: GQL_GET_PRODUCT_DETAILS,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static addFavourite(details, headers) {
    const {itemId} = details;
    const config = getApiConfig();
    const url = `${config.profileApi}/favourites/item/${itemId}/add`;
    return axios.post(url, {}, {headers}).then(res => res.data);
  }
  static removeFavourite(details, headers) {
    const {itemId} = details;
    const config = getApiConfig();
    const url = `${config.profileApi}/favourites/item/${itemId}/remove`;
    return axios.post(url, {}, {headers}).then(res => res.data);
  }

  static getFavourites(params, headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_GET_FAVOURITE,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static getPreviouslyPurchased(params, headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_GET_RECENTS,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static getProductsByIdS(params, headers) {
    const config = getApiConfig();
    const searchUrl = `${config.apiUrl}/product/websearch`;
    return axios
      .get(searchUrl, {params, headers})
      .then(res =>
        res.data && res.data._embedded ? res.data._embedded.products : []
      );
  }
}
