import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  padding: 1rem 1rem 0 1rem;
  ${media.smaller`
    height: ${p => (p.$showInMobile ? 'auto' : '11rem')};
  `};
`;
