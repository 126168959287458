import React, {PureComponent} from 'react';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import {
  FormWrapper,
  ErrorsField,
  Error,
  LoginModalLinkWrapper,
  LoginModalLink,
  ThankYouWrapper,
  ThankYouContent,
  ButtonWrapper,
} from './LoginRegister.style';
import Loading from '../Loading/Loading';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {requestResetPassword} from '../../actions/accounts.action';
import {PASSWORD_STATUS} from '../../constants/constants';
import {FormInput} from '../../styles/components/input';
import {Row} from '../../styles/pages/pages';
import {Button} from '../../styles/components/form';

class RetrievePassword extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      regPasswordReset,
      setSubmitting,
      setErrors,
      setStatus,
      storeErrors,
    } = this.props;
    if (prevProps.regPasswordReset !== regPasswordReset) {
      setSubmitting(false);
      if (
        regPasswordReset &&
        regPasswordReset.data &&
        regPasswordReset.data.Data &&
        regPasswordReset.data.Data.MessageList &&
        regPasswordReset.data.Data.MessageList.length > 0
      ) {
        if (
          regPasswordReset.data.Data.MessageList[0].Type ===
          PASSWORD_STATUS.SUCCESS
        ) {
          setStatus({
            showThankYou: true,
            message: regPasswordReset.data.Data.MessageList[0].Message,
          });
        }
        if (
          regPasswordReset.data.Data.MessageList[0].Type ===
          PASSWORD_STATUS.ERROR
        ) {
          setErrors({
            apiError: regPasswordReset.data.Data.MessageList,
          });
        }
      }
    }
    if (storeErrors && storeErrors.passwordReset) {
      setSubmitting(false);
    }
  }

  sendResetEmail = e => {
    const {handleSubmit} = this.props;
    handleSubmit(e);
  };
  forgetPassword = () => this.setState({resetPassword: true});

  render() {
    const {
      values,
      touched,
      errors,
      storeErrors,
      isSubmitting,
      handleChange,
      handleBlur,
      backToLogin,
      status,
    } = this.props;
    if (status && status.showThankYou) {
      return (
        <FormWrapper $nonIframe={true}>
          <ThankYouWrapper>
            <ThankYouContent>{status.message}</ThankYouContent>
            <ButtonWrapper>
              <Button onClick={backToLogin}>Login</Button>
            </ButtonWrapper>
          </ThankYouWrapper>
        </FormWrapper>
      );
    }

    const renderGenericError = storeErrors && storeErrors.passwordReset && (
      <Error>
        Failed to send password reset link, please try again later. If this
        problem persists please call Customer Support
      </Error>
    );
    const renderApiError =
      errors.apiError &&
      errors.apiError.length > 0 &&
      errors.apiError.map((error, index) => (
        <Error key={index}>{error.Message}</Error>
      ));
    const renderEmailError = errors.resetEmail && touched.resetEmail && (
      <Error>{errors.resetEmail}</Error>
    );
    const submitButtonText = isSubmitting ? (
      <Loading isLight={true} />
    ) : (
      'Send password reset email'
    );
    return (
      <FormWrapper $nonIframe={true}>
        <Row>
          <ThankYouContent>
            Enter the email address for your JJs account
          </ThankYouContent>
        </Row>
        <Row>
          <FormInput
            id="resetEmail"
            type="text"
            placeholder="Email"
            value={values.resetEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.resetEmail && touched.resetEmail}
          />
        </Row>
        <LoginModalLinkWrapper>
          <LoginModalLink onClick={backToLogin}>
            Try to log in again
          </LoginModalLink>
        </LoginModalLinkWrapper>
        <ErrorsField>
          {renderEmailError}
          {renderApiError}
          {renderGenericError}
        </ErrorsField>
        <Button onClick={this.sendResetEmail} isSubmitting={isSubmitting}>
          {submitButtonText}
        </Button>
      </FormWrapper>
    );
  }
}

const config = {
  mapPropsToValues: () => ({
    resetEmail: '',
  }),
  validationSchema: Yup.object().shape({
    resetEmail: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  }),
  mapPropsToStatus: () => ({
    showThankYou: false,
  }),
  handleSubmit: (payload, {isSubmitting, props}) => {
    if (!isSubmitting) {
      props.requestResetPassword(payload.resetEmail);
    }
  },
  displayName: 'RetrievePasswordForm',
};

const mapStateToProps = state => {
  return {
    storeErrors: state.errors,
    regPasswordReset: state.regPasswordReset,
  };
};

const mapDispatchToProps = dispatch => ({
  requestResetPassword: bindActionCreators(requestResetPassword, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormik(config)(RetrievePassword));
