const overlay = {
  backgroundColor: 'rgba(120, 120, 120, 0.7)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  border: 0,
  overflowY: 'auto',
  overflow: 'hidden',
  WebkitOverflowScrolling: 'touch',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  zIndex: 998,
};

const content = {
  top: 'initial',
  left: 'initial',
  right: 'initial',
  bottom: 'initial',
  padding: 0,
  border: 0,
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  zIndex: 999,
};

const loginContent = Object.assign({}, content, {overflow: 'hidden'});

export const defaultStyles = {
  overlay,
  content,
};

export const loginStyles = {
  overlay,
  content: loginContent,
};

const errorContent = Object.assign({}, content, {
  width: 'auto',
  height: 'auto',
});

export const errorStyles = {
  overlay,
  content: errorContent,
};
