import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';

import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import {Button} from '../../styles/components/form';
import {ContinueShopping} from '../App.style';

class PackagingPage extends PureComponent {
  render() {
    return (
      <MarkdownWrapper>
        <div>
          <h1>Takeaway Packaging Wholesales</h1>
          <p>
            Buy Wholesale takeaway packaging From JJ Foodservice For Delivery or
            Collection
          </p>
          <SectionWrapper>
            <p>
              Choose from a wide range of wholesale food packaging supplies &
              catering containers: takeaway boxes, bags, cups and more
            </p>
            <p>
              Takeaway packaging is essential to good customer experience:
              high-quality eco-friendly takeaway packaging will keep the food in
              the right way and help to avoid mess.
            </p>
            <p>
              From pizza boxes and chip containers to straws and cutlery, paper
              bags, cups & lids - you can find a full range of compostable
              materials and more.
            </p>
            <p>
              Browse our full range of food packaging supplies below to find the
              perfect packaging for your food business.
            </p>
            <ContinueShopping>
              <Button left href="/category/packaging">
                Browse takeaway packaging
              </Button>
            </ContinueShopping>
          </SectionWrapper>
        </div>
      </MarkdownWrapper>
    );
  }
}

export default withRouter(PackagingPage);
