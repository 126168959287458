import React from 'react';
import {
  CLOUDINARY_PARAM,
  FASTLY_FORMAT_REGEX,
  IMAGE_PRODUCT_ID_REGEX,
  IMAGE_SIZE,
  STRAPI_CMS_URL,
} from '../constants/constants';
import {WIX_MEDIA_URL} from '../constants/wix.constants';

export const preloadImage = (images, isMobile) => {
  if (typeof window === 'undefined') {
    return;
  }
  const hiddenDiv = document.createElement('div');
  hiddenDiv.setAttribute('class', 'testing');
  hiddenDiv.style.display = 'none';
  hiddenDiv.style.width = '1px';
  hiddenDiv.style.height = '1px';
  hiddenDiv.style.overflow = 'hidden';
  images.forEach((picture, i) => {
    if (i === 0 || !picture.linkUrl) {
      return;
    }
    const img = new Image();
    img.src = isMobile ? picture.mediaMobileUrl : picture.mediaDesktopUrl;
    hiddenDiv.appendChild(img);
  });
  document.body.appendChild(hiddenDiv);
};

export const reduceImageSize = (url, large, imageSize) => {
  if (!url) {
    return;
  }
  let requestedSize = imageSize
    ? imageSize
    : large
    ? IMAGE_SIZE.LARGE
    : IMAGE_SIZE.MEDIUM;
  const jjUrl = '/image/upload/';
  const scaleSize = `${requestedSize}${CLOUDINARY_PARAM.SCALE}`;
  const urls = url.split(jjUrl);
  return [urls[0], jjUrl + scaleSize, urls[1]].join('');
};

export const reduceGroupImageSize = (images, isLarge) =>
  images.map(img => reduceImageSize(img, isLarge));

export const reduceBasketItemsImageSize = items => {
  return items.map(item => {
    if (
      item.product &&
      item.product.imageLinks &&
      item.product.imageLinks.length > 0
    ) {
      const imageLinks = reduceGroupImageSize(item.product.imageLinks, false);
      return Object.assign({}, item, {
        product: Object.assign({}, item.product, {
          imageLinks,
        }),
      });
    }
    return item;
  });
};

export const useWebp = url => {
  const isFastlyString = url.match(FASTLY_FORMAT_REGEX);
  if (url.indexOf(CLOUDINARY_PARAM.OVERLAY_TEXT) < 0 || !isFastlyString) {
    return url.replace(CLOUDINARY_PARAM.SCALE, CLOUDINARY_PARAM.SCALE_WEBP);
  }
  const imageWithoutFormat = url.split(FASTLY_FORMAT_REGEX);
  const newImageUrl = imageWithoutFormat.join('');
  const productIndex =
    (newImageUrl.match(IMAGE_PRODUCT_ID_REGEX) &&
      newImageUrl.match(IMAGE_PRODUCT_ID_REGEX).index) ||
    0;
  const newFormatString = isFastlyString[0].replace(
    CLOUDINARY_PARAM.SCALE,
    CLOUDINARY_PARAM.SCALE_WEBP
  );
  if (!productIndex) {
    return url.replace(CLOUDINARY_PARAM.SCALE, CLOUDINARY_PARAM.SCALE_WEBP);
  }
  return (
    newImageUrl.slice(0, productIndex) +
    newFormatString +
    newImageUrl.slice(productIndex)
  );
};

export const useThumbnail = url =>
  url && url.replace(IMAGE_SIZE.LARGE, IMAGE_SIZE.THUMBNAIL);

export const scaleGirdSize = banners => {
  return banners.map((banner, index) => {
    const size = index === 0 ? IMAGE_SIZE.MAIN_GRID : IMAGE_SIZE.GRID;
    return {
      ...banner,
      ...{
        mediaDesktopUrl: reduceImageSize(banner.mediaDesktopUrl, false, size),
        mediaMobileUrl: reduceImageSize(banner.mediaDesktopUrl, false, size),
      },
    };
  });
};

export const canUseWebP = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

export const wixImage = (image, isMainCateogry) => {
  if (!image || !image.url || !image.id) {
    return null;
  }
  const param = isMainCateogry
    ? 'v1/fill/w_560,h_560,al_c,q_80,usm_0.66_1.00_0.01,enc_auto'
    : 'v1/fill/w_574,h_322,fp_0.50_0.50,q_90,enc_auto';
  return image.url + `/${param}/` + image.id;
};

export const wixBlogPostImage = (id, width, height) => {
  if (!id) {
    return '';
  }
  return (
    WIX_MEDIA_URL +
    id +
    `/v1/fill/w_${width || 1480},h_${height ||
      1106},al_c,q_85,usm_0.66_1.00_0.01,enc_auto/` +
    id
  );
};

export const wixBlogPreviewImage = id => {
  if (!id) {
    return '';
  }
  return (
    WIX_MEDIA_URL + id + '/v1/fill/w_888,h_500,fp_0.50_0.50,q_90,enc_auto/' + id
  );
};

export const strapiImage = post => {
  const host = STRAPI_CMS_URL.PROD;

  const hasAttributes =
    post.attributes &&
    post.attributes.featuredImage &&
    post.attributes.featuredImage.data &&
    post.attributes.featuredImage.data.attributes;
  const hasFormats = hasAttributes && hasAttributes.formats;
  const thumbnail =
    hasFormats && hasFormats.thumbnail && host + hasFormats.thumbnail.url;
  const small = hasFormats && hasFormats.small && host + hasFormats.small.url;
  const medium =
    hasFormats && hasFormats.thumbnail && host + hasFormats.thumbnail.url;
  const large =
    hasFormats && hasFormats.thumbnail && host + hasFormats.thumbnail.url;
  const full = hasAttributes && host + hasFormats.thumbnail.url;

  return {
    thumbnail,
    small,
    medium,
    large,
    full,
  };
};
