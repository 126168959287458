import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function jjContentReducer(
  state = initialState.jjContent,
  action
) {
  switch (action.type) {
    case types.GET_JJ_CONTENT_BY_SLUG_SUCCESS:
      return action.jjContent;
    case types.GET_JJ_CONTENT_BY_SLUG_FAILED:
    case types.GET_JJ_CONTENT_BY_SLUG_REQUESTED:
      return null;
    default:
      return state;
  }
}
