export const isLocalStorageSupported = () => {
  const TEST_KEY = 'localstorage.test.key';
  try {
    localStorage.setItem(TEST_KEY, TEST_KEY);
    localStorage.removeItem(TEST_KEY); // make sure localStorage is working
    return true;
  } catch (e) {
    return false;
  }
};

export const persistDataToLocalStorage = (key, value) => {
  if (isLocalStorageSupported()) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    //todo: redo the cookie
  }
};

export const removeValueFromLocalStorage = key => {
  if (isLocalStorageSupported()) {
    localStorage.removeItem(key);
  } else {
    //todo: redo the cookie
  }
};

export const resetLocalStorage = () => {
  if (isLocalStorageSupported()) {
    localStorage.clear();
  } else {
    //todo: redo the cookie
  }
};

export const resetCookies = () => {
  if (typeof window === 'undefined') {
    return;
  }
  document.cookie = 'branch=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'JJ_USER=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const getDataFromLocalStorage = key => {
  try {
    if (isLocalStorageSupported()) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      //todo: redo the cookie
    }
  } catch (e) {
    removeValueFromLocalStorage(key);
  }
};
