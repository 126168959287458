import React, {PureComponent} from 'react';
import moment from 'moment';
import {
  Status,
  Text,
  Uppercase,
  Expired,
  Block,
  Details,
} from './BookSlotStatus.style';
import {SERVICE_TYPE} from '../../constants/constants';

export default class BookSlotStatus extends PureComponent {
  render() {
    const {
      fulfillAtSlot,
      fulfillmentType,
      isBookSlotPage,
      isCheckoutPage,
      branchName,
      isSlotExpired,
    } = this.props;
    if (!fulfillAtSlot) {
      return null;
    }
    const momentTime = moment(fulfillAtSlot.day, 'DD-MM-YYYY');
    const alreadyText = isBookSlotPage ? 'already' : '';
    const weekday = momentTime.format('dddd');
    const slotDate = momentTime.format('Do MMMM');
    if (isSlotExpired) {
      return (
        <Expired>
          Your <Uppercase>{fulfillmentType}</Uppercase> slot for {weekday}{' '}
          {slotDate} has now expired. Please book a new slot.
        </Expired>
      );
    }
    if (isCheckoutPage && fulfillmentType === null) {
      return null;
    }
    return (
      <Status>
        <Block>
          You have {alreadyText} booked a{' '}
          <Uppercase>{fulfillmentType}</Uppercase> slot:
        </Block>
        <Details>
          <Text>
            {weekday} {slotDate}
          </Text>
          <Text>
            {fulfillAtSlot.from} - {fulfillAtSlot.to}
          </Text>
          {fulfillmentType === SERVICE_TYPE.COLLECTION && branchName && (
            <Text>at {branchName}</Text>
          )}
        </Details>
      </Status>
    );
  }
}
