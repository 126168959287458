import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link2, LinkStyle} from '../../styles/components/links';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${p => p.theme.tertiaryBgColor};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.small`
    width: 100%;
  `};
`;

export const Title = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: ${p => p.theme.textColor};
`;

export const Contact = styled.p`
  padding: 1rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  text-align: center;
`;

export const Error = styled.div`
  color: ${p => p.theme.tertiaryColor};
  background-color: ${p => p.theme.errorBgColor};
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 2rem;
  margin: 1rem 1.8rem 4rem 1rem;
  width: 80%;
  text-align: center;
  ${media.small`
    width: 100%;
  `};
`;

export const Tel = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${p => p.theme.tertiaryBgColor};
`;

export const Link = styled.div`
  ${LinkStyle};
  margin-right: ${p => (p.$nomargin ? 0 : '1rem')};
  text-align: center;
  ${media.smaller`
    padding: 0 1rem;
    max-width: 60%;
  `};
  ${media.smallest`
    text-align: center;
  `};
`;
