import React, {PureComponent} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Yup from 'yup';
import {
  Container,
  Content,
  Title,
  FormWrapper,
  ErrorsField,
  Text,
  Error,
} from './PasswordResetPage.style';
import {Loading} from '../../components/Loading/Loading';
import {PASSWORD_STATUS} from '../../constants/constants';
import {
  ButtonWrapper,
  ThankYouContent,
  ThankYouTitle,
  ThankYouWrapper,
} from '../../components/LoginRegister/LoginRegister.style';
import {setNewPassword} from '../../actions/accounts.action';
import {
  getPasswordMatchSchema,
  getPasswordSchema,
} from '../../helpers/validation.helper';
import {FormInput} from '../../styles/components/input';
import {Row} from '../../styles/pages/pages';
import {Button} from '../../styles/components/form';

class PasswordResetPage extends PureComponent {
  componentDidUpdate(prevProps) {
    const {regPasswordReset, setSubmitting, setErrors, setStatus} = this.props;
    if (prevProps.regPasswordReset !== regPasswordReset) {
      setSubmitting(false);
      const hasPasswordData =
        regPasswordReset && regPasswordReset.data && regPasswordReset.data.Data;
      if (
        hasPasswordData &&
        regPasswordReset.data.Data.MessageList &&
        regPasswordReset.data.Data.MessageList.length > 0 &&
        regPasswordReset.data.Data.MessageList[0].Type ===
          PASSWORD_STATUS.SUCCESS
      ) {
        setStatus({
          showThankYou: true,
          message: regPasswordReset.data.Data.MessageList[0].Message,
        });
      }
      if (
        regPasswordReset &&
        regPasswordReset.data &&
        regPasswordReset.data.Error &&
        regPasswordReset.data.Error.Message
      ) {
        setErrors({
          apiError: [{Message: regPasswordReset.data.Error.Message}],
        });
      }
      if (
        hasPasswordData &&
        regPasswordReset.data.Data.MessageList &&
        regPasswordReset.data.Data.MessageList.length > 0 &&
        (regPasswordReset.data.Data.MessageList[0].Type ===
          PASSWORD_STATUS.ERROR ||
          regPasswordReset.data.Data.MessageList[0].Type ===
            PASSWORD_STATUS.WARNING)
      ) {
        setErrors({
          apiError: regPasswordReset.data.Data.MessageList,
        });
      }
    }
  }

  handleSubmit = e => {
    const {handleSubmit} = this.props;
    const {params} = this.props.match;
    this.props.setFieldValue('resetCode', params.code, false);
    handleSubmit(e);
  };
  showLoginModal = () => this.props.history.push('/login');

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      status,
      storeErrors,
    } = this.props;

    if (status && status.showThankYou) {
      return (
        <Container>
          <Content>
            <Title>Reset your password</Title>
            <FormWrapper nonIframe={true}>
              <ThankYouWrapper>
                <ThankYouTitle>Success!</ThankYouTitle>
                <ThankYouContent>{status.message}</ThankYouContent>
                <ButtonWrapper>
                  <Button onClick={this.showLoginModal}>Login</Button>
                </ButtonWrapper>
              </ThankYouWrapper>
            </FormWrapper>
          </Content>
        </Container>
      );
    }

    const renderGenericError = storeErrors && storeErrors.passwordReset && (
      <Error>
        Failed to reset password, please try again later. If this problem
        persists please call Customer Support
      </Error>
    );

    const renderPassword1Error = errors.password1 && touched.password1 && (
      <Error>{errors.password1}</Error>
    );
    const renderPassword2Error = errors.password2 && touched.password2 && (
      <Error>{errors.password2}</Error>
    );
    const renderButtonContent = isSubmitting ? (
      <Loading isLight={true} />
    ) : (
      'Reset password'
    );
    const renderApiError =
      errors.apiError &&
      errors.apiError.length > 0 &&
      errors.apiError.map((error, index) => (
        <Error key={index}>{error.Message}</Error>
      ));
    const renderPasswordInputs = (
      <FormWrapper>
        <Row>
          <FormInput id="resetCode" type="hidden" value={values.resetCode} />
          <FormInput
            id="password1"
            type="password"
            placeholder="New password"
            value={values.password1}
            onChange={handleChange}
            onBlur={handleBlur}
            $error={errors.password1 && touched.password1}
          />
          <FormInput
            id="password2"
            type="password"
            value={values.password2}
            placeholder="Confirm password"
            onChange={handleChange}
            onBlur={handleBlur}
            $error={errors.password2 && touched.password2}
          />
        </Row>
        <Row>
          <Text>
            Use at least 8 characters, and at least 1 number and 1 upper and
            lower case letter
          </Text>
        </Row>
        <ErrorsField>
          {renderPassword1Error}
          {renderPassword2Error}
          {renderApiError}
          {renderGenericError}
        </ErrorsField>
        <Button onClick={this.handleSubmit} isSubmitting={isSubmitting}>
          {renderButtonContent}
        </Button>
      </FormWrapper>
    );
    return (
      <Container>
        <Content>
          <Title>Reset your password</Title>
          {renderPasswordInputs}
        </Content>
      </Container>
    );
  }
}

const config = {
  mapPropsToValues: () => ({
    password1: '',
    password2: '',
    resetCode: '',
  }),
  mapPropsToStatus: () => ({
    showThankYou: false,
  }),
  validationSchema: Yup.object().shape({
    password1: getPasswordSchema(),
    password2: getPasswordMatchSchema(),
  }),
  handleSubmit: (payload, {isSubmitting, props}) => {
    if (!isSubmitting) {
      props.setNewPassword(payload);
    }
  },
  displayName: 'ResetPasswordForm',
};

const mapStateToProps = state => {
  return {
    storeErrors: state.errors,
    regPasswordReset: state.regPasswordReset,
  };
};

const mapDispatchToProps = dispatch => ({
  setNewPassword: bindActionCreators(setNewPassword, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormik(config)(PasswordResetPage));
