import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {
  GQL_GET_SHOPPING_LISTS,
  GQL_SET_SHOPPING_LISTS,
  GQL_SHOPPING_LISTS_ADD_ITEM,
  GQL_SHOPPING_LISTS_REMOVE_ITEM,
  GQL_LOOKUP_SHOPPING_LIST,
  GQL_RENAME_SHOPPING_LIST,
  GQL_DELETE_SHOPPING_LIST,
} from '../gql/shoppingList.gql';

export default class listApi {
  static getLists(headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_GET_SHOPPING_LISTS,
      },
      headers,
    }).then(res => res.data);
  }
  static createList(params, headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_SET_SHOPPING_LISTS,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static addToList(params, headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_SHOPPING_LISTS_ADD_ITEM,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static removeFromList(params, headers) {
    const config = getApiConfig();
    return axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_SHOPPING_LISTS_REMOVE_ITEM,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
  static async lookupList(params, headers) {
    const config = getApiConfig();
    const res = await axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_LOOKUP_SHOPPING_LIST,
        variables: params,
      },
      headers,
    });
    return res.data;
  }
  static async renameList(params, headers) {
    const config = getApiConfig();
    const res = await axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_RENAME_SHOPPING_LIST,
        variables: params,
      },
      headers,
    });
    return res.data;
  }
  static async deleteList(params, headers) {
    const config = getApiConfig();
    const res = await axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: GQL_DELETE_SHOPPING_LIST,
        variables: params,
      },
      headers,
    });
    return res.data;
  }
}
