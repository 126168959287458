import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Minus,
  Add,
  ValueInput,
  QuantityValue,
  Cancel,
  Update,
  LoadingWrapper,
  QuantityMessage,
  ValueSelect,
} from './Quantity.style';
import {Loading} from '../Loading/Loading';
import {
  DEFAULT_DEBOUNCE_TIME,
  KEYBOARD_KEYS,
  REACH_MAX_TIMEOUT,
  STOCK_CHECK,
} from '../../constants/constants';
import {debounce} from '../../helpers/events.helper';
import {getAvailableQuantities} from '../../helpers/array.helper';
import SentryHelper from '../../helpers/sentry.helper';

export default class Quantity extends PureComponent {
  static propTypes = {
    quantity: PropTypes.number.isRequired,
    update: PropTypes.func,
    remove: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    max: PropTypes.number,
    isLight: PropTypes.bool,
    isWide: PropTypes.bool,
    loading: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {quantity} = props;
    this.state = {
      editing: false,
      value: quantity || '',
      loading: false,
      reachMax: false,
      partialStock: false,
      availableQuantities: [],
    };
    this.reachMaxTimeout = null;
  }

  static getDerivedStateFromProps(nextProps, preState) {
    const {loading} = nextProps;
    if (preState.loading && loading && !loading.updatingBasket) {
      return {
        loading: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const {max, step} = this.props;
    this.setState({availableQuantities: getAvailableQuantities(max, step)});
  }

  componentDidUpdate(prevProps, prevState) {
    const {stockCheck} = this.props;
    if (
      stockCheck &&
      stockCheck.result === STOCK_CHECK.PARTIAL_STOCK &&
      prevProps.stockCheck &&
      (stockCheck.result !== prevProps.stockCheck.result ||
        stockCheck.quantityAsked !== prevProps.stockCheck.quantityAsked)
    ) {
      this.setState({partialStock: true});
      this.partialStockTimoutout = setTimeout(
        this.unsetPartialStock,
        REACH_MAX_TIMEOUT
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.reachMaxTimeout);
    clearTimeout(this.partialStockTimoutout);
  }

  unsetPartialStock = () => this.setState({partialStock: false});

  startEditing = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({editing: !this.state.editing});
  };

  cancel = () =>
    this.setState({
      editing: false,
      value: this.props.quantity,
    });

  add = () => {
    const {uuid, quantity, unitPrice, update, max, step} = this.props;
    if (!this.addedCount) {
      this.addedCount = 0;
    }
    this.addedCount += 1;
    const newQty = quantity + this.addedCount * step;
    this.totalCount = newQty;
    if (!quantity || !this.totalCount) {
      SentryHelper.debugQuantityZero({
        message: `Quantity.js add() - this.addedCount: ${
          this.addedCount
        } (${typeof this
          .addedCount}), quantity: ${quantity} (${typeof quantity}), newQty: ${newQty} (${typeof newQty}), step: ${step} (${typeof step}), this.totalCount: ${
          this.totalCount
        } (${typeof this.totalCount})`,
      });
    }
    if (newQty > max && max !== 0) {
      this.setState({reachMax: true});
      this.reachMaxTimeout = setTimeout(this.clearMax, REACH_MAX_TIMEOUT);
    } else {
      if (!this.debouncedAddFn) {
        this.debouncedAddFn = debounce(() => {
          update(uuid, {
            quantity: this.totalCount,
            unitPrice,
          });
          this.addedCount = 0;
          this.setState({loading: true});
        }, DEFAULT_DEBOUNCE_TIME);
      }
      this.debouncedAddFn();
    }
  };

  clearMax = () => {
    this.setState({reachMax: false});
    clearTimeout(this.reachMaxTimeout);
  };

  remove = () => {
    const {uuid, quantity, unitPrice, update, remove, step} = this.props;
    if (!this.removedCount) {
      this.removedCount = 0;
    }
    this.removedCount += 1;
    this.totalRemovedCount = quantity - this.removedCount * step;
    this.setState({reachMax: false});
    if (
      (!this.totalRemovedCount && this.totalRemovedCount !== 0) ||
      !quantity
    ) {
      SentryHelper.debugQuantityZero({
        message: `Quantity.js remove() - this.removedCount: ${
          this.removedCount
        } (${typeof this
          .removedCount}), quantity: ${quantity} (${typeof quantity}), step: ${step} (${typeof step}), this.totalRemovedCount: ${
          this.totalRemovedCount
        } (${typeof this.totalRemovedCount})`,
      });
    }
    if (!this.debouncedRemoveFn) {
      this.debouncedRemoveFn = debounce(() => {
        if (this.totalRemovedCount === 0 || this.totalRemovedCount < 0) {
          remove();
        } else {
          update(uuid, {quantity: this.totalRemovedCount, unitPrice});
        }
        this.removedCount = 0;
        this.setState({loading: true});
      }, DEFAULT_DEBOUNCE_TIME);
    }
    this.debouncedRemoveFn();
  };

  onQuantityChange = e => {
    if (e.target.value === '') {
      this.setState({value: ''});
      return;
    }
    const {max, step} = this.props;
    let value = parseInt(e.target.value, 10);
    let state = {value};
    if (value > max * step) {
      state = Object.assign({}, state, {reachMax: true});
      this.reachMaxTimeout = setTimeout(this.clearMax, REACH_MAX_TIMEOUT);
    }
    this.setState(state);
  };
  onQuantityChangeDropDown = e => {
    if (e.target.value === '') {
      this.setState({value: ''});
      return;
    }
    let value = parseFloat(e.target.value);
    let state = {value};
    this.setState(state);
  };
  onKeyUp = e => {
    if (e.key !== KEYBOARD_KEYS.ENTER) {
      return;
    }
    this.setState({loading: true, editing: false});
    this.timeout = setTimeout(this.update, 100);
  };

  update = () => {
    if (this.state.value === '') {
      return;
    }
    this.setState({reachMax: false});
    const {uuid, update, unitPrice} = this.props;
    if (!this.state.value) {
      SentryHelper.debugQuantityZero({
        message: `Quantity.js update - this.state.value: ${
          this.state.value
        } (${typeof this.state.value}),`,
      });
    }
    update(uuid, {quantity: this.state.value, unitPrice});
    this.setState({editing: false, loading: true});
  };

  render() {
    const {quantity, isLight, step, isWide} = this.props;
    const {
      editing,
      loading,
      reachMax,
      partialStock,
      availableQuantities,
    } = this.state;
    const isMultiStep = step > 1;
    const renderLoading = loading && (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderInput = !isMultiStep && !loading && (
      <ValueInput
        type="number"
        min={step}
        value={this.state.value}
        onChange={this.onQuantityChange}
        onKeyUp={this.onKeyUp}
      />
    );
    const renderReachMax = !editing && reachMax && !partialStock && (
      <QuantityMessage onClick={this.clearMax}>Limit may apply</QuantityMessage>
    );
    const renderPartialStock = partialStock && (
      <QuantityMessage>Limited Stock</QuantityMessage>
    );
    const renderValue =
      !loading && !editing && !reachMax && !partialStock && quantity;
    const defaultValue =
      renderValue && renderValue < this.state.value
        ? renderValue
        : this.state.value;
    const renderAvailableQuantities = isMultiStep && (
      <ValueSelect
        autoFocus
        defaultValue={defaultValue}
        onChange={this.onQuantityChangeDropDown}
      >
        {availableQuantities.map(qty => (
          <option key={qty} value={qty}>
            {qty}
          </option>
        ))}
      </ValueSelect>
    );
    const renderInputContent = editing && (
      <Fragment>
        <Cancel onClick={this.cancel} />
        {renderLoading}
        {renderInput}
        {renderAvailableQuantities}
        <Update onClick={this.update} />
      </Fragment>
    );
    const renderQuantity = !editing && (
      <Fragment>
        <Minus onClick={this.remove} data-rw="quantity--minus" />
        <QuantityValue onClick={this.startEditing} data-rw="quantity--value">
          {renderLoading}
          {renderValue}
          {renderPartialStock}
          {renderReachMax}
        </QuantityValue>
        <Add onClick={this.add} data-rw="product--add-button" />
      </Fragment>
    );
    return (
      <Wrapper $isLight={isLight} $isWide={isWide}>
        {renderInputContent}
        {renderQuantity}
      </Wrapper>
    );
  }
}
