import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import {
  FormWrapper,
  Column,
  Title,
  Type,
  RadioText,
  FormButton,
  ErrorsField,
  ListWrapper,
  SelectList,
  Error,
  ThankYouContent,
  ThankYouTitle,
  ButtonWrapper,
  ThankYouWrapper,
  FormSelect,
  Tel,
  InfoText,
  Details,
  Link,
} from './LoginRegister.style';
import {CheckMark} from '../../styles/components/checkbox';
import Loading from '../Loading/Loading';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {contactCS, registerUser} from '../../actions/accounts.action';
import {getPasswordSchema} from '../../helpers/validation.helper';
import {FormInput} from '../../styles/components/input';
import {Row} from '../../styles/pages/pages';
import {Button} from '../../styles/components/form';
import {
  JJ_TEL_FULL,
  REGISTER_TEL_REGEX,
  REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US,
  REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA,
} from '../../constants/constants';
import {resetPostcodeLookup} from '../../actions/lookup.action';
import {Checkbox} from '../Marketing/Checkbox';
import {
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from '../../constants/links';
import {capitalizeString} from '../../helpers/string.helper';

class Register extends PureComponent {
  static propTypes = {
    isOver18: PropTypes.bool,
    lookup: PropTypes.object,
    updateRegisterType: PropTypes.func,
    updateAge: PropTypes.func,
    postcodeLookup: PropTypes.func.isRequired,
    addressLookup: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    storeErrors: PropTypes.object,
  };

  state = {
    businessNameError: false,
    businessTypeError: false,
    postcode: null,
    showAddressField: false,
    lookupError: null,
    shouldContactCS: false,
    showDetailedErrors: false,
    registerWhereDidYouHearAboutUsRandomised: REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US.sort(
      (a, b) => 0.5 - Math.random()
    ),
  };

  componentDidUpdate(prevProps) {
    const {
      regPasswordReset,
      setSubmitting,
      setStatus,
      resetForm,
      storeErrors,
      isSubmitting,
      resetPostcodeLookup,
      values,
      contactCS,
      lookup,
      setFieldValue,
    } = this.props;
    if (storeErrors && storeErrors.register && isSubmitting) {
      // reset the submitting spinner if register failed
      setSubmitting(false);
    }
    if (prevProps.regPasswordReset !== regPasswordReset) {
      setSubmitting(false);
      if (
        regPasswordReset &&
        regPasswordReset.data &&
        regPasswordReset.data.Data &&
        regPasswordReset.data.Data.Created
      ) {
        resetForm();
        setStatus({showThankYou: true});
        if (this.state.shouldContactCS) {
          contactCS({
            firstname: values.firstname,
            surname: values.surname,
            businessName: values.businessName,
            address: values.address,
            place: values.place,
            postcode: values.postcode,
            phone1: values.phone1,
            phone2: values.phone2,
            email: values.email,
          });
        }
      } else {
        if (
          regPasswordReset &&
          regPasswordReset.data &&
          regPasswordReset.data.Data &&
          regPasswordReset.data.Data.MessageList &&
          regPasswordReset.data.Data.MessageList.length > 0
        ) {
          if (
            regPasswordReset.data.Data.MessageList.some(
              message => message.Field === 'Street'
            )
          ) {
            resetPostcodeLookup();
            this.setState({showAddressField: false});
          }
          setStatus({
            apiError: regPasswordReset.data.Data.MessageList,
          });
        } else {
          setStatus({
            apiError: [
              {
                Message:
                  'Failed to process your registration, please try again later. If this problem persists please call Customer Support',
                Details: regPasswordReset.data,
              },
            ],
          });
        }
      }
    }
    if (lookup && lookup.address) {
      let address = lookup.address.line_1;
      if (lookup.address.line_2) {
        address = `${lookup.address.line_2}, ${lookup.address.line_1}`;
      }
      if (lookup.address.line_3) {
        address += `, ${lookup.address.line_3}`;
      }
      setFieldValue('address', address);
      setFieldValue('county', lookup.address.postal_county);
      setFieldValue('place', capitalizeString(lookup.address.post_town));
      setFieldValue('latitude', lookup.address.latitude);
      setFieldValue('longitude', lookup.address.longitude);
    }
  }

  closeModal = () => {
    const {toggleModal, setStatus} = this.props;
    toggleModal();
    setStatus({showThankYou: false});
  };

  changeRegisterType = () => {
    const {updateRegisterType, isBusiness} = this.props;
    if (isBusiness) {
      this.setState({
        businessNameError: false,
      });
    }
    updateRegisterType(!isBusiness);
    this.props.setFieldValue('isBusiness', !isBusiness);
  };

  changeAge = () => {
    const {updateAge, isOver18} = this.props;
    updateAge(!isOver18);
  };

  postcodeLookup = () => {
    if (!this.props.values.postcode) {
      return;
    }
    this.props.postcodeLookup(this.props.values.postcode);
    this.setState({
      showAddressField: false,
      shouldContactCS: false,
    });
  };

  onAddressChange = e => {
    if (e.target.value > -1) {
      const {setFieldValue, lookup} = this.props;
      const selectedAddress = lookup.postcode[e.target.value];
      setFieldValue('address', selectedAddress.suggestion);
      this.props.addressLookup(selectedAddress.udprn);
    }
    this.setState({showAddressField: true});
  };

  onBusinessTypeChange = e => {
    if (this.state.businessTypeError) {
      this.setState({businessTypeError: false});
    }
    this.props.setFieldValue('businessType', e.target.value);
  };

  toggleDetailedErrors = () =>
    this.setState({showDetailedErrors: !this.state.showDetailedErrors});

  handlePostcodeChange = e => {
    e.target.value = e.target.value.replace(/\s/g, '').toUpperCase();
    this.props.handleChange(e);
  };

  handleChange = e => {
    e.target.value = e.target.value.trim();
    this.props.handleChange(e);
  };

  onWhereDidYouHearAboutUsChange = e =>
    this.props.setFieldValue('whereDidYouHearAboutUs', e.target.value);

  handleSubmit = e => {
    const {isOver18, handleSubmit, values, isBusiness} = this.props;
    if (!isOver18) {
      return;
    }
    if (isBusiness && values.businessName === '') {
      this.setState({businessNameError: true});
    }
    if (isBusiness && values.businessType === '') {
      this.setState({businessTypeError: true});
    }
    if (this.state.businessNameError || this.state.businessTypeError) {
      return;
    }
    handleSubmit(e);
  };

  handleBSNameChange = e => {
    if (this.state.businessNameError) {
      this.setState({businessNameError: false});
    }
    this.props.handleChange(e);
  };
  toggleContactCS = () => {
    this.setState({shouldContactCS: !this.state.shouldContactCS});
  };
  updateMarketingCheckbox = (id, state) => {
    this.props.setFieldValue('marketing', state);
  };
  updateTCCheckbox = (id, state) => {
    this.props.setFieldValue('tc', state);
  };

  render() {
    const {
      isBusiness,
      isOver18,
      values,
      touched,
      isSubmitting,
      handleChange,
      handleBlur,
      lookup,
      errors,
      storeErrors,
      status,
    } = this.props;
    const {
      businessNameError,
      businessTypeError,
      showAddressField,
      shouldContactCS,
      registerWhereDidYouHearAboutUsRandomised,
    } = this.state;
    if (status && status.showThankYou) {
      return (
        <FormWrapper $nonIframe={true}>
          <ThankYouWrapper>
            <ThankYouTitle>Thank you for registering</ThankYouTitle>
            <ThankYouContent>
              An email has now been sent to you with a link to activate your
              account. <br />
              Please activate it within the next 24 hours, before logging in.
            </ThankYouContent>
            <ButtonWrapper>
              <Button onClick={this.closeModal}>Close</Button>
            </ButtonWrapper>
          </ThankYouWrapper>
        </FormWrapper>
      );
    }
    const renderBusiness = isBusiness && (
      <div>
        <Row>
          <FormInput
            autoComplete="organization"
            id="businessName"
            type="text"
            placeholder="Business Name"
            value={values.businessName}
            onBlur={handleBlur}
            onChange={this.handleBSNameChange}
            error={businessNameError}
          />
          <FormSelect onChange={this.onBusinessTypeChange}>
            <option disabled="" value="">
              Business type*
            </option>
            <optgroup label="Business">
              <option value="OFC">Office Canteen</option>
              <option value="WHO">Wholesaler</option>
            </optgroup>
            <optgroup label="Education">
              <option value="ISI">Independent School</option>
            </optgroup>
            <optgroup label="Health &amp; Care">
              <option value="CHO">Care/Nursing Homes</option>
            </optgroup>
            <optgroup label="Hotel">
              <option value="HOT">Hotel</option>
            </optgroup>
            <optgroup label="Pubs">
              <option value="PUB">Pub</option>
            </optgroup>
            <optgroup label="Quick Service">
              <option value="CAF">Cafe</option>
              <option value="CHT">Chinese Takeaway</option>
              <option value="SAN">Sandwich Bar &amp; Deli</option>
              <option value="VAN">Mobile Van</option>
            </optgroup>
            <optgroup label="Restaurant">
              <option value="BUR">Burger Restaurant</option>
              <option value="CHI">Chicken Restaurant</option>
              <option value="CHR">Chinese Restaurant</option>
              <option value="FIS">Fish &amp; Chips Restaurant</option>
              <option value="IND">Indian Restaurant</option>
              <option value="KEB">Kebab Restaurant</option>
              <option value="PIZ">Pizza Restaurant</option>
              <option value="RES">General Restaurant</option>
              <option value="TRK">Turkish Restaurant</option>
            </optgroup>
            <optgroup label="Shop">
              <option value="BAK">Bakery</option>
              <option value="BUT">Butcher</option>
              <option value="SUP">Supermarket</option>
            </optgroup>
            <optgroup label="Travel &amp; Leisure">
              <option value="EVC">Event Catering</option>
            </optgroup>
            <optgroup label="Other">
              <option value="TMP">Theme Park</option>
            </optgroup>
          </FormSelect>
        </Row>
      </div>
    );
    const renderAddress = lookup && lookup.postcode && !showAddressField && (
      <Row>
        <ListWrapper>
          <SelectList onChange={this.onAddressChange}>
            <option>Please select your address</option>
            {lookup.postcode.map((address, i) => (
              <option key={i} value={i}>
                {address.suggestion}
              </option>
            ))}
          </SelectList>
        </ListWrapper>
      </Row>
    );

    const renderAddressField = showAddressField && (
      <Row>
        <FormInput
          autoComplete="address-line1"
          id="address"
          type="text"
          placeholder="Address"
          value={values.address}
          onBlur={handleBlur}
          onChange={this.handleChange}
          disabled
          $error={errors.address && touched.address}
        />
        <FormInput
          autoComplete="address-level2"
          id="place"
          type="text"
          placeholder="Place"
          value={values.place}
          onBlur={handleBlur}
          onChange={this.handleChange}
          disabled
          error={errors.place && touched.place}
        />
      </Row>
    );
    const renderAddressCheckbox = showAddressField && (
      <Row>
        <Type onClick={this.toggleContactCS}>
          <CheckMark $isChecked={shouldContactCS} />
          <RadioText $isChecked={shouldContactCS}>
            If this address is not quite right, tick this box and we will
            contact you
          </RadioText>
        </Type>
      </Row>
    );
    const renderWhereDidYouHearAboutUsOptions = registerWhereDidYouHearAboutUsRandomised.map(
      item => (
        <option value={item} key={item}>
          {item}
        </option>
      )
    );
    const renderForm = isOver18 && (
      <div>
        <Row>
          <FormInput id="isBusiness" type="hidden" value={values.isBusiness} />
          <FormInput id="latitude" type="hidden" value={values.latitude} />
          <FormInput id="longitude" type="hidden" value={values.longitude} />
          <FormInput id="county" type="hidden" value={values.county} />
          <FormInput
            autoComplete="given-name"
            id="firstname"
            type="text"
            placeholder="First Name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.firstname && touched.firstname}
            autoFocus={true}
          />
          <FormInput
            autoComplete="family-name"
            id="surname"
            type="text"
            placeholder="Surname"
            value={values.surname}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.surname && touched.surname}
          />
        </Row>
        {renderBusiness}
        <Row>
          <FormInput
            autoComplete="postcode"
            id="postcode"
            type="text"
            placeholder="Postcode"
            value={values.postcode}
            onBlur={handleBlur}
            onChange={this.handlePostcodeChange}
            error={errors.postcode && touched.postcode}
          />
          <FormButton onClick={this.postcodeLookup}>Address Lookup</FormButton>
        </Row>
        {renderAddress}
        {renderAddressField}
        {renderAddressCheckbox}
        <Row>
          <FormInput
            autoComplete="tel"
            id="phone1"
            type="text"
            placeholder="Phone 1"
            value={values.phone1}
            onBlur={handleBlur}
            onChange={this.handleChange}
            error={errors.phone1 && touched.phone1}
          />
          <FormInput
            autoComplete="tel1"
            id="phone2"
            type="text"
            placeholder="Phone 2"
            value={values.phone2}
            onBlur={handleBlur}
            onChange={this.handleChange}
            error={errors.phone2 && touched.phone2}
          />
        </Row>
        <Row>
          <FormInput
            autoComplete="new-password"
            id="email"
            type="text"
            placeholder="Email"
            value={values.email}
            onBlur={handleBlur}
            onChange={this.handleChange}
            error={errors.email && touched.email}
          />
          <FormInput
            autoComplete="new-password"
            id="password"
            type="password"
            placeholder="Password"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.password && touched.password}
          />
        </Row>
        <Row>
          <ListWrapper>
            <SelectList
              $error={
                errors.whereDidYouHearAboutUs && touched.whereDidYouHearAboutUs
              }
              onChange={this.onWhereDidYouHearAboutUsChange}
              defaultValue={REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA}
            >
              <option value={REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA} disabled>
                Where did you hear about us?
              </option>
              {renderWhereDidYouHearAboutUsOptions}
            </SelectList>
          </ListWrapper>
        </Row>
        <Row>
          <Checkbox
            onClick={this.updateMarketingCheckbox}
            text={'I would like to have special offers emailed to me'}
            id={'marketing'}
            enabled={values.marketing}
          />
        </Row>
        <Row>
          <FormInput id="tc" type="hidden" value={values.tc} />
          <Checkbox
            onClick={this.updateTCCheckbox}
            text={
              <span>
                I agree to the JJ Foodservice{' '}
                <Link href={TERMS_AND_CONDITIONS_LINK.url} target="_blank">
                  {TERMS_AND_CONDITIONS_LINK.content}
                </Link>{' '}
                and{' '}
                <Link href={PRIVACY_POLICY_LINK.url} target="_blank">
                  {PRIVACY_POLICY_LINK.content}
                </Link>{' '}
                (required)
              </span>
            }
            enabled={values.tc}
          />
        </Row>
      </div>
    );
    const renderGenericError = storeErrors && storeErrors.register && (
      <Error>
        Failed to register, please try again later. If this problem persists
        please call Customer Support
      </Error>
    );
    const renderNameError = errors.firstname && touched.firstname && (
      <Error>{errors.firstname}</Error>
    );
    const renderSurnameError = errors.surname && touched.surname && (
      <Error>{errors.surname}</Error>
    );
    const renderBusinessName = isBusiness && businessNameError && (
      <Error>business name is required</Error>
    );
    const renderBusinessType = isBusiness && businessTypeError && (
      <Error>business type is required</Error>
    );
    const renderPostcodeError = errors.postcode && touched.postcode && (
      <Error>{errors.postcode}</Error>
    );
    const renderLookupError = storeErrors && storeErrors.lookup && (
      <Error>{storeErrors.lookup}</Error>
    );
    const renderAddressError = errors.address && touched.address && (
      <Error>{errors.address}</Error>
    );
    const renderPlaceError = errors.place && touched.place && (
      <Error>{errors.place}</Error>
    );
    const renderPhone1Error = errors.phone1 && touched.phone1 && (
      <Error>{errors.phone1}</Error>
    );
    const renderPhone2Error = errors.phone2 && touched.phone2 && (
      <Error>{errors.phone2}</Error>
    );
    const renderEmailError = errors.email && touched.email && (
      <Error>{errors.email}</Error>
    );
    const renderPasswordError = errors.password && touched.password && (
      <Error>{errors.password}</Error>
    );
    const renderTCError = errors.tc && touched.tc && <Error>{errors.tc}</Error>;
    const renderApiError =
      status.apiError &&
      status.apiError.length > 0 &&
      status.apiError.map((error, index) => {
        const renderErrorDetails = error.Details &&
          this.state.showDetailedErrors && <Details>{error.Details}</Details>;
        return (
          <Error key={index} onClick={this.toggleDetailedErrors}>
            {error.Message}
            {renderErrorDetails}
          </Error>
        );
      });
    const renderWhereDidYouHearError = errors.whereDidYouHearAboutUs &&
      touched.whereDidYouHearAboutUs && (
        <Error>{errors.whereDidYouHearAboutUs}</Error>
      );
    const renderErrorsFields = isOver18 && (
      <ErrorsField>
        {renderNameError}
        {renderSurnameError}
        {renderBusinessName}
        {renderBusinessType}
        {renderPostcodeError}
        {renderLookupError}
        {renderAddressError}
        {renderPlaceError}
        {renderPhone1Error}
        {renderPhone2Error}
        {renderEmailError}
        {renderPasswordError}
        {renderApiError}
        {renderTCError}
        {renderWhereDidYouHearError}
      </ErrorsField>
    );
    const registerButtonText = isSubmitting ? (
      <Loading isLight={true} />
    ) : (
      'Register'
    );
    const renderRegisterButton = isOver18 && (
      <Button onClick={this.handleSubmit} isSubmitting={isSubmitting}>
        {registerButtonText}
      </Button>
    );
    return (
      <FormWrapper $nonIframe={true}>
        <Row margin>
          <InfoText>
            (For existing customers who have not ordered online before, please
            call
            <Tel href={`tel:${JJ_TEL_FULL}`}>01992 701 701</Tel>option 5 to set
            up your account)
          </InfoText>
        </Row>
        <Row>
          <Column>
            <Title>New customers - create an account as a:</Title>
          </Column>
          <Column>
            <Type onClick={this.changeRegisterType}>
              <CheckMark $isChecked={isBusiness} />
              <RadioText $isChecked={isBusiness}>Business</RadioText>
            </Type>
            <Type onClick={this.changeRegisterType}>
              <CheckMark $isChecked={!isBusiness} />
              <RadioText $isChecked={!isBusiness}>Person</RadioText>
            </Type>
          </Column>
        </Row>
        <Row $margin={true}>
          <Column />
          <Type onClick={this.changeAge}>
            <CheckMark $isChecked={isOver18} />
            <RadioText $isChecked={isOver18}>
              I confirm that I am over 18
            </RadioText>
          </Type>
        </Row>
        {renderForm}
        {renderErrorsFields}
        {renderRegisterButton}
        {renderGenericError}
      </FormWrapper>
    );
  }
}

const config = {
  mapPropsToValues: () => ({
    firstname: '',
    surname: '',
    businessName: '',
    businessType: '',
    postcode: '',
    address: '',
    place: '',
    county: '',
    phone1: '',
    phone2: '',
    email: '',
    password: '',
    priceGuide: false,
    byBrochures: false,
    byEmail: false,
    isBusiness: true,
    marketing: false,
    tc: false,
    latitude: '',
    longitude: '',
    whereDidYouHearAboutUs: '',
  }),
  mapPropsToStatus: () => ({
    showThankYou: false,
  }),
  validationSchema: Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    surname: Yup.string().required('Surname is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: getPasswordSchema(),
    postcode: Yup.string().required('Postcode is required'),
    address: Yup.string().required('Address is required'),
    place: Yup.string().required('Place is required'),
    phone1: Yup.string()
      .matches(REGISTER_TEL_REGEX, 'Phone 1 is not valid')
      .required('Phone is required'),
    phone2: Yup.string().matches(REGISTER_TEL_REGEX, 'Phone 2 is not valid'),
    tc: Yup.boolean().oneOf([true], 'Must accept Terms and Conditions'),
    whereDidYouHearAboutUs: Yup.string().required(
      'Please select the most appropriate option'
    ),
  }),
  handleSubmit: (payload, {isSubmitting, props}) => {
    if (!isSubmitting) {
      props.registerUser(payload);
    }
  },
  displayName: 'RegisterForm',
};

const mapStateToProps = state => {
  return {
    regPasswordReset: state.regPasswordReset,
  };
};

const mapDispatchToProps = dispatch => ({
  registerUser: bindActionCreators(registerUser, dispatch),
  contactCS: bindActionCreators(contactCS, dispatch),
  resetPostcodeLookup: bindActionCreators(resetPostcodeLookup, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormik(config)(Register));
