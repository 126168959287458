import * as Yup from 'yup';
import {PASSWORD_REGEX} from '../constants/constants';

export const getPasswordSchema = () =>
  Yup.string()
    .min(8, 'Password must be minimum 8 characters')
    .max(30, 'Passwords must be less than 30 characters long')
    .matches(
      PASSWORD_REGEX,
      'Password must have at least 1 number and 1 upper and lower case letter. No special characters allowed.'
    )
    .required('Password is required');

export const getPasswordMatchSchema = () =>
  Yup.string()
    .oneOf([Yup.ref('password1')], 'Passwords do not match')
    .required('Confirm password is required');
