import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function regPasswordResetReducer(
  state = initialState.regPasswordReset,
  action
) {
  switch (action.type) {
    case types.REGISTER_USER_SUCCESS:
    case types.PASSWORD_RESET_SUCCESS:
    case types.SET_NEW_PASSWORD_SUCCESS:
      return action.messages;
    default:
      return state;
  }
}
