import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import Login from './Login';
import Register from './Register';
import {Wrapper, Tabs, Tab} from '../../styles/pages/modal';
import {CloseButton, Link, TCText} from './LoginRegister.style';
import RetrievePassword from './RetrievePassword';
import {
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from '../../constants/links';
import LoginError from '../Errors/LoginError';

export default class LoginRegister extends PureComponent {
  static propTypes = {
    showModal: PropTypes.bool,
    lookup: PropTypes.object,
    toggleModal: PropTypes.func.isRequired,
    postcodeLookup: PropTypes.func.isRequired,
    addressLookup: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    errors: PropTypes.object,
    onLoginFinished: PropTypes.func,
    onLoginFailed: PropTypes.func,
    hashError: PropTypes.object,
    loginRedirect: PropTypes.string,
  };
  state = {
    showLogin: true,
    showRetrievePassword: false,
    isBusiness: true,
    isOver18: false,
  };

  componentDidMount() {
    if (window) {
      window.addEventListener('message', this.chooseRetrievePassword, false);
    }
    if (this.props.showRegister) {
      this.chooseRegister();
    }
  }

  chooseLogin = () =>
    this.setState({showLogin: true, showRetrievePassword: false});
  chooseRegister = () =>
    this.setState({showLogin: false, showRetrievePassword: false});
  chooseRetrievePassword = message => {
    if (message.data === 'showRetrievePassword') {
      this.setState({showRetrievePassword: !this.state.showRetrievePassword});
    }
  };
  toggleRetrievePassword = () => {
    this.setState({showRetrievePassword: !this.state.showRetrievePassword});
  };
  updateRegisterType = isBusiness => this.setState({isBusiness});
  updateAge = isOver18 => this.setState({isOver18});

  render() {
    const {
      showModal,
      toggleModal,
      postcodeLookup,
      addressLookup,
      lookup,
      errors,
      isLoggedIn,
      onLoginFinished,
      onLoginFailed,
      deviceInfo,
      hashError,
      loginRedirect,
    } = this.props;
    const {isBusiness, isOver18, showLogin, showRetrievePassword} = this.state;

    const renderHashError = hashError && <LoginError error={hashError} />;

    const renderLogin = !hashError && showLogin && (
      <Login
        isLoggedIn={isLoggedIn}
        toggleModal={toggleModal}
        retrievePassword={this.toggleRetrievePassword}
        onLoginFinished={onLoginFinished}
        onLoginFailed={onLoginFailed}
        deviceInfo={deviceInfo}
        loginRedirect={loginRedirect}
      />
    );
    const renderRegister = !showLogin && (
      <Register
        isBusiness={isBusiness}
        isOver18={isOver18}
        updateRegisterType={this.updateRegisterType}
        updateAge={this.updateAge}
        postcodeLookup={postcodeLookup}
        addressLookup={addressLookup}
        lookup={lookup}
        storeErrors={errors}
        deviceInfo={deviceInfo}
        toggleModal={toggleModal}
      />
    );
    const renderPanel = showRetrievePassword ? (
      <RetrievePassword backToLogin={this.toggleRetrievePassword} />
    ) : (
      <Fragment>
        {renderHashError}
        {renderLogin}
        {renderRegister}
      </Fragment>
    );
    const renderLoginTabName = isLoggedIn
      ? 'Logging in...'
      : !showLogin
      ? 'Register'
      : 'Login';
    const renderTabs = showRetrievePassword ? (
      <Tab $isActive={true}>Password reset</Tab>
    ) : (
      <Tab $isActive={showLogin} onClick={this.chooseLogin}>
        {renderLoginTabName}
      </Tab>
    );
    const renderTC = showLogin && (
      <TCText>
        By logging in to this online ordering website you consent to the
        required processing and storing of your data in accordance with our{' '}
        <Link href={PRIVACY_POLICY_LINK.url} target="_blank">
          {PRIVACY_POLICY_LINK.content}
        </Link>{' '}
        and{' '}
        <Link href={TERMS_AND_CONDITIONS_LINK.url} target="_blank">
          {TERMS_AND_CONDITIONS_LINK.content}
        </Link>
        .
      </TCText>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        callback={toggleModal}
        isOpen={showModal}
        closeModal={toggleModal}
      >
        <Wrapper $isLoginModal={showLogin}>
          <Tabs>
            {renderTabs}
            <CloseButton onClick={toggleModal} isLoggedIn={isLoggedIn} />
          </Tabs>
          {renderPanel}
          {renderTC}
        </Wrapper>
      </ModalWrapper>
    );
  }
}
