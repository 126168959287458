import React, {Fragment, PureComponent} from 'react';
import {
  SubWrapper,
  SubMinus,
  SubAdd,
  SubValueInput,
  QuantityValue,
  SubCancel,
  SubUpdate,
  SubLoadingWrapper,
  QuantityMessage,
  ValueSelect,
} from './Quantity.style';
import {Loading} from '../Loading/Loading';
import {DEFAULT_DEBOUNCE_TIME, KEYBOARD_KEYS} from '../../constants/constants';
import {debounce} from '../../helpers/events.helper';

export default class SubQuantity extends PureComponent {
  constructor(props) {
    super(props);
    const {quantity} = props;
    this.state = {
      editing: false,
      value: quantity || '',
      loading: false,
      partialStock: false,
      availableQuantities: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {loading} = this.props;
    if (this.state.loading && loading && loading.updateShoppingList === false) {
      this.setState({loading: false});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.partialStockTimoutout);
  }

  unsetPartialStock = () => this.setState({partialStock: false});

  startEditing = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({editing: !this.state.editing});
  };

  cancel = () =>
    this.setState({
      editing: false,
      value: this.props.quantity,
    });

  add = e => {
    e.stopPropagation();
    const {quantity, update, step} = this.props;
    if (!this.addedCount) {
      this.addedCount = 0;
    }
    this.addedCount += 1;
    this.totalCount = quantity + this.addedCount * step;
    if (!this.debouncedAddFn) {
      this.debouncedAddFn = debounce(() => {
        update(this.totalCount);
        this.addedCount = 0;
        this.setState({loading: true});
      }, DEFAULT_DEBOUNCE_TIME);
    }
    this.debouncedAddFn();
  };

  remove = e => {
    e.stopPropagation();
    const {quantity, update, remove, step} = this.props;
    if (
      quantity === 1 ||
      this.totalRemovedCount === 0 ||
      this.totalRemovedCount < 0
    ) {
      remove();
    } else {
      update(quantity - step);
    }
    this.removedCount = 0;
    this.setState({loading: true});
  };

  onQuantityChange = e => {
    if (e.target.value === '') {
      this.setState({value: ''});
      return;
    }
    let value = parseInt(e.target.value, 10);
    this.setState({value});
  };
  onQuantityChangeDropDown = e => {
    if (e.target.value === '') {
      this.setState({value: ''});
      return;
    }
    let value = parseInt(e.target.value, 10);
    this.setState({value});
  };
  onKeyUp = e => {
    if (e.key !== KEYBOARD_KEYS.ENTER) {
      return;
    }
    this.setState({loading: true, editing: false});
    this.timeout = setTimeout(this.updateQty, 100);
  };

  update = () => {
    const {quantity, update, step} = this.props;
    update(quantity + step);
    this.setState({editing: false, loading: true});
  };

  updateQty = () => {
    const {value} = this.state;
    if (value === '') {
      return;
    }
    const {update} = this.props;
    update(value);
    this.setState({editing: false, loading: true});
  };

  render() {
    const {quantity, isLight, step, isWide} = this.props;
    const {editing, loading, partialStock, availableQuantities} = this.state;
    const isMultiStep = step > 1;
    const renderLoading = loading && (
      <SubLoadingWrapper>
        <Loading isLight={false} />
      </SubLoadingWrapper>
    );
    const renderInput = !isMultiStep && !loading && (
      <SubValueInput
        type="number"
        min={step}
        value={this.state.value}
        onChange={this.onQuantityChange}
        onKeyUp={this.onKeyUp}
      />
    );
    const renderPartialStock = partialStock && (
      <QuantityMessage>Limited Stock</QuantityMessage>
    );
    const renderValue = !loading && !editing && !partialStock && quantity;
    const defaultValue =
      renderValue && renderValue < this.state.value
        ? renderValue
        : this.state.value;
    const renderAvailableQuantities = isMultiStep && (
      <ValueSelect
        autoFocus
        defaultValue={defaultValue}
        onChange={this.onQuantityChangeDropDown}
      >
        {availableQuantities.map(qty => (
          <option key={qty} value={qty}>
            {qty}
          </option>
        ))}
      </ValueSelect>
    );
    const renderInputContent = editing && (
      <Fragment>
        <SubCancel onClick={this.cancel} />
        {renderLoading}
        {renderInput}
        {renderAvailableQuantities}
        <SubUpdate onClick={this.updateQty} />
      </Fragment>
    );
    const renderQuantity = !editing && (
      <Fragment>
        <SubMinus onClick={this.remove} data-rw="sub-quantity--minus" />
        <QuantityValue
          onClick={this.startEditing}
          data-rw="sub-quantity--value"
        >
          {renderLoading}
          {renderValue}
          {renderPartialStock}
        </QuantityValue>
        <SubAdd onClick={this.add} data-rw="product--add-button" />
      </Fragment>
    );
    return (
      <SubWrapper $isLight={isLight} $isWide={isWide}>
        {renderInputContent}
        {renderQuantity}
      </SubWrapper>
    );
  }
}
