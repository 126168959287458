import initialState from './initial.state';
import * as types from '../actions/types.action';
import {addBannerIndex, filterValidContent} from '../helpers/data.helper';
import {
  canUseWebP,
  reduceImageSize,
  scaleGirdSize,
  useWebp,
} from '../helpers/image.helper';
import {IMAGE_SIZE} from '../constants/constants';
import {sortBanners} from '../helpers/array.helper';

export default function cmsReducer(state = initialState.cms, action) {
  switch (action.type) {
    case types.GET_CMS_BANNERS_SUCCESS:
      let bannerIndex = 1;
      const lowerBanners = sortBanners(
        filterValidContent(scaleGirdSize(action.banners.lowerBanners))
      ).map(banner => {
        if (banner.length) {
          banner = addBannerIndex(banner);
        } else {
          banner.index = bannerIndex;
          bannerIndex++;
        }
        return banner;
      });
      const isWebPSupported = canUseWebP();
      const topBanners = filterValidContent(
        action.banners.topBanners,
        action.branch
      ).map((banner, index) => {
        const mediaDesktopUrl = reduceImageSize(
          banner.mediaDesktopUrl,
          false,
          IMAGE_SIZE.BANNER
        );
        return {
          index,
          ...banner,
          ...{
            mediaDesktopUrl: isWebPSupported
              ? useWebp(mediaDesktopUrl)
              : mediaDesktopUrl,
          },
        };
      });
      return Object.assign({}, state, {
        banners: {
          topBanners,
          categoryAd: filterValidContent(action.banners.categoryAd),
          lowerBanners,
        },
      });

    case types.GET_CMS_ABOUTUS_SUCCESS:
      return Object.assign({}, state, {
        aboutus: action.aboutus,
      });
    case types.GET_CMS_CAREERS_SUCCESS:
      return Object.assign({}, state, {
        careers: action.careers,
      });
    case types.GET_CMS_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        customers: action.customers,
      });
    case types.GET_CMS_BROCHURES_SUCCESS:
      return Object.assign({}, state, {
        brochures: action.brochures,
      });
    case types.GET_CMS_VACANCIES_SUCCESS:
      return Object.assign({}, state, {
        vacancies: action.vacancies,
      });
    case types.GET_CMS_DEPARTMENTS_SUCCESS:
      return Object.assign({}, state, {
        departments: action.departments,
      });
    case types.GET_CMS_TEAM_SUCCESS:
      return Object.assign({}, state, {
        team: action.team,
      });
    case types.GET_CMS_HELP_SUCCESS:
      return Object.assign({}, state, {
        help: action.help,
      });
    case types.GET_CMS_PBB_FAQ_SUCCESS:
      return Object.assign({}, state, {
        payByBankFAQ: action.payByBankFAQ,
      });
    case types.GET_CMS_WHYJJ_SUCCESS:
      return Object.assign({}, state, {
        whyJJ: action.whyJJ,
      });
    case types.GET_CMS_SUPPLYUS_SUCCESS:
      return Object.assign({}, state, {
        supplyUs: action.supplyUs,
      });
    case types.GET_CMS_POLICIES_SUCCESS:
      return Object.assign({}, state, {
        policies: action.policies,
      });
    case types.GET_CMS_DELIVERIES_SUCCESS:
      return Object.assign({}, state, {
        deliveries: action.deliveries,
      });
    default:
      return state;
  }
}
