import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Wrapper, Star} from './Rating.style';
import RatingStar from './RatingStar';

export default class Rating extends PureComponent {
  static propTypes = {
    rating: PropTypes.number,
    totalStars: PropTypes.number,
    setRating: PropTypes.func,
  };
  state = {
    rating: this.props.rating,
  };

  changeRating = rating => {
    this.setState({rating});
    this.props.setRating(rating);
  };

  render() {
    const stars = new Array(this.props.totalStars || 5).fill(0);
    const renderStars = stars.map((star, index) => (
      <RatingStar
        key={index}
        index={index}
        rated={index < this.state.rating || 0}
        changeRating={this.changeRating}
        isFeedback={true}
      />
    ));
    return <Wrapper>{renderStars}</Wrapper>;
  }
}
