import {put, call, takeEvery} from 'redux-saga/effects';
import fastlyApi from '../api/fastly.api';
import * as ActionTypes from '../actions/types.action';
import {filterValidContent} from '../helpers/data.helper';

function* getBrochures() {
  try {
    const brochures = yield call(fastlyApi.getBrochures);
    let filteredBrochures = null;
    if (brochures && brochures.BROCHURES) {
      filteredBrochures = filterValidContent(brochures.BROCHURES);
    }
    yield put({
      type: ActionTypes.GET_BROCHURES_SUCCESS,
      brochures: filteredBrochures,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BROCHURES_FAILED,
      message: e.message,
    });
  }
}

export default function* getInitDataYield() {
  yield takeEvery(ActionTypes.GET_BROCHURES_REQUESTED, getBrochures);
}
