import React, {Fragment} from 'react';
import {
  Container,
  PhotoWrapper,
  Content,
  Details,
  Price,
  ButtonSmall,
  SmallDetails,
  PriceText,
} from './SmallProduct.style';
import {
  LoadingWrapper,
  NameWrapper,
  SmallInfo,
  Id,
  Description,
  SmallWeight,
  SmallPriceWrapper,
  AddButton,
  ExtraPromoSmall,
  PriceType,
  SmallName,
} from './Product.style';
import Loading from '../Loading/Loading';
import Quantity from '../Quantity/Quantity';
import ImageComponent from '../Image/Image';
import {
  getCollectionUnitPrice,
  getPriceUnavailableText,
} from '../../helpers/product.helper';
import {getAvailableAtDateString} from '../../helpers/string.helper';
import {
  PriceUnavailable,
  SmallExtraPromo,
  SmallPriceWholeWrapper,
} from './ListProduct.style';

export const SmallProduct = props => {
  const {
    product,
    currency,
    updateItem,
    deliveryPrice,
    collectionPrice,
    stockCheck,
    loading,
    loadingProps,
    addToBasket,
    goToProductDetailPage,
    remove,
    step,
    max,
    unitPrice,
    isOOs,
    renderOfferTab,
    hasProductOffer,
    discountPrice,
    renderOfferTitle,
    oosText,
  } = props;
  const renderButtonContent = loading ? (
    <LoadingWrapper>
      <Loading isLight={true} />
    </LoadingWrapper>
  ) : (
    'Add item'
  );
  const renderAvailableButton = product.basketQuantity ? (
    <Quantity
      quantity={product.basketQuantity}
      update={updateItem}
      uuid={product.basketItemUuid}
      remove={remove}
      step={step}
      max={max}
      loading={loadingProps}
      unitPrice={unitPrice}
    />
  ) : (
    <AddButton
      onClick={addToBasket}
      $isLoading={loading}
      data-rw="product--add-button"
    >
      {renderButtonContent}
    </AddButton>
  );
  const renderPromoDiscount = !!discountPrice && (
    <span>
      Extra <ExtraPromoSmall>{discountPrice}</ExtraPromoSmall> off
    </span>
  );
  const renderButton = isOOs ? (
    <AddButton disabled={true}>
      {!!stockCheck.availableAt
        ? getAvailableAtDateString(stockCheck.availableAt)
        : oosText}
    </AddButton>
  ) : (
    renderAvailableButton
  );
  const noOffers = !!product && !product.offer;
  const renderPrice = ((collectionPrice && collectionPrice > 0) ||
    (deliveryPrice && deliveryPrice > 0)) && (
    <SmallPriceWholeWrapper $hasNoOffers={noOffers}>
      {collectionPrice && (
        <Price>
          <PriceType>Collection:</PriceType>
          <PriceText $hasNoOffers={noOffers}>
            {currency}
            {collectionPrice}
          </PriceText>
        </Price>
      )}
      {deliveryPrice && (
        <Price>
          <PriceType>Delivery:</PriceType>
          <PriceText $hasNoOffers={noOffers} $alignRight={true}>
            {currency}
            {deliveryPrice}
          </PriceText>
        </Price>
      )}
    </SmallPriceWholeWrapper>
  );
  const renderPriceOffer = product &&
    product.offer &&
    product.offer.promoDisAmt &&
    product.offer.promoDisAmt > 0 &&
    ((collectionPrice && collectionPrice > 0) ||
      (deliveryPrice && deliveryPrice > 0)) && (
      <SmallPriceWholeWrapper>
        {collectionPrice && (
          <Price>
            <PriceType $isOffer={true}>{renderOfferTitle}:</PriceType>
            <PriceText $isOffer={true}>
              {currency}
              {(collectionPrice - product.offer.promoDisAmt).toFixed(2)}
            </PriceText>
          </Price>
        )}
        {deliveryPrice && (
          <Price>
            <PriceType $isOffer={true}>{renderOfferTitle}:</PriceType>
            <PriceText $isOffer={true} $alignRight={true}>
              {currency}
              {(deliveryPrice - product.offer.promoDisAmt).toFixed(2)}
            </PriceText>
          </Price>
        )}
      </SmallPriceWholeWrapper>
    );
  const renderPriceUnavailable = !collectionPrice && !deliveryPrice && (
    <PriceUnavailable>{getPriceUnavailableText()}</PriceUnavailable>
  );
  return (
    <Container image={product.image} href={product.url} key={product.id}>
      {renderOfferTab}
      <PhotoWrapper onClick={goToProductDetailPage}>
        <ImageComponent
          url={product.imageLinks && product.imageLinks[0]}
          alt={product.itemName}
        />
      </PhotoWrapper>
      <SmallDetails onClick={goToProductDetailPage}>
        <SmallExtraPromo>{renderPromoDiscount}</SmallExtraPromo>
        <SmallInfo onClick={goToProductDetailPage}>
          <Id>{product && product.itemId}</Id>
          <SmallWeight $isOffer={hasProductOffer}>
            {getCollectionUnitPrice(product)}
          </SmallWeight>
        </SmallInfo>
        <NameWrapper>
          <SmallName $isOffer={hasProductOffer}>{product.itemName}</SmallName>
        </NameWrapper>
      </SmallDetails>
      <Content onClick={goToProductDetailPage}>
        <Details>
          <Description onClick={goToProductDetailPage}>
            {product.productDescription}
          </Description>
          <SmallPriceWrapper>
            {renderPrice}
            {renderPriceOffer}
            {renderPriceUnavailable}
          </SmallPriceWrapper>
        </Details>
      </Content>
      <ButtonSmall>{renderButton}</ButtonSmall>
    </Container>
  );
};
