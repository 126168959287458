import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import BasketItem from './BasketItem';
import Substitutes from '../Substitutes/Substitutes';
import {InfoBar, OutOfStockGroup} from './BasketItem.style';
import {STOCK_CHECK} from '../../constants/constants';
import {getOosItemsSize} from '../../helpers/basket.helper';

export default class BasketItems extends PureComponent {
  static propTypes = {
    items: PropTypes.object,
    removeItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    updateBasket: PropTypes.func.isRequired,
    onBasketItemClick: PropTypes.func.isRequired,
    setSubstituteItem: PropTypes.func.isRequired,
    basketHashMap: PropTypes.object,
    substitutes: PropTypes.object,
    viewOnly: PropTypes.bool,
    isDelivery: PropTypes.bool,
    synced: PropTypes.bool,
  };

  render() {
    const {
      items,
      removeItem,
      updateItem,
      onBasketItemClick,
      viewOnly,
      loading,
      basketHashMap,
      updateBasket,
      substitutes,
      currency,
      setSubstituteItem,
      isDelivery,
      synced,
    } = this.props;
    //todo viewOnly for orderId
    const isOutOfStock =
      items && items.outOfStock && items.outOfStock.length > 0;
    const oosItemsSize = items && getOosItemsSize(items.outOfStock);
    const renderOutOfStockInfo = isOutOfStock && oosItemsSize > 0 && (
      <InfoBar $warning={true}>
        The following {oosItemsSize} item
        {oosItemsSize > 1 && `s `} in the basket need your attention. Please
        choose an alternative item or slot.
      </InfoBar>
    );
    const renderOutOfStock = isOutOfStock && (
      <OutOfStockGroup>
        {items.outOfStock.map((item, i) => {
          if (!item) {
            return;
          }
          if (
            item &&
            item.stockCheck &&
            item.stockCheck.result === STOCK_CHECK.IN_STOCK
          ) {
            return (
              <BasketItem
                item={item}
                key={item.uuid + i}
                removeItem={removeItem}
                updateItem={updateItem}
                onBasketItemClick={onBasketItemClick}
                viewOnly={viewOnly}
                loading={loading}
                isDelivery={isDelivery}
                synced={synced}
              />
            );
          }
          const pairs = [
            item,
            (substitutes && item && substitutes[item.itemId]) || null,
          ];
          return (
            <Substitutes
              key={item.uuid + i}
              currency={currency}
              pairs={pairs}
              basketHashMap={basketHashMap}
              updateBasket={updateBasket}
              removeItem={removeItem}
              updateItem={updateItem}
              onItemClick={onBasketItemClick}
              loading={loading}
              setSubstituteItem={setSubstituteItem}
              showRemoveItemButton={true}
            />
          );
        })}
      </OutOfStockGroup>
    );
    const renderInStock =
      items &&
      items.inStock &&
      items.inStock.length > 0 &&
      items.inStock.map((item, i) => (
        <BasketItem
          item={item}
          key={(item && item.uuid) + i}
          removeItem={removeItem}
          updateItem={updateItem}
          onBasketItemClick={onBasketItemClick}
          viewOnly={viewOnly}
          loading={loading}
          isDelivery={isDelivery}
          synced={synced}
        />
      ));
    return (
      <Fragment>
        {renderOutOfStockInfo}
        {renderOutOfStock}
        {renderInStock}
      </Fragment>
    );
  }
}
