import {put, call, take, fork, join, select} from 'redux-saga/effects';
import searchApi from '../api/search.api';
import * as ActionTypes from '../actions/types.action';
import {getUserSearchSuggestions} from '../helpers/data.helper';

export default function* searchSuggestions() {
  while (true) {
    const action = yield take(ActionTypes.GET_SEARCH_SUGGESTIONS_REQUESTED);
    try {
      const getAuth = state => state.auth;
      const getSettings = state => state.settings;
      const auth = yield select(getAuth);
      const settings = yield select(getSettings);

      if (!action.keyword) {
        const results = getUserSearchSuggestions(action, auth, settings);
        yield put({
          type: ActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
          searchSuggestions: results,
        });
      } else {
        const resultsFork = yield fork(
          searchApi.searchSuggestions,
          action.keyword
        );
        const results = yield join(resultsFork);
        yield put({
          type: ActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
          searchSuggestions: results,
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.GET_SEARCH_SUGGESTIONS_FAILED,
        message: e.message,
      });
    }
  }
}
