import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Button1, Button2} from '../../styles/components/buttons';
import {Link1} from '../../styles/components/links';
import {WayDown, WayUp} from '../../styles/components/arrow';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  height: 11rem;
  position: relative;
  z-index: 100;
  ${media.medium`
    width: 100%;
    height: 8rem;
    padding-top: 0;
  `};
`;

export const Logo = styled.img`
  width: 28rem;
  max-height: 4.6rem;
  ${media.medium`
    width: 22rem;
    padding-left: 2rem;
  `};
  ${media.smaller`
    width: 18rem;
    padding-left: 2rem;
  `};
  ${media.smallest`
    padding-left: 1rem;
  `};
`;

export const Links = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 70%;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-items: flex-start;
  margin-left: 2.8rem;
  ${media.medium`
    margin-left: 1rem;
  `};
  ${media.smaller`
    display: none;
  `};
`;

export const NavLink = styled(Link1)`
  font-size: 1.4rem;
  padding: 0.4rem;
`;

export const NavAnchorLink = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
  font-size: 1.4rem;
  padding: 0.4rem;
  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const ClickLink = styled(Button1)`
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0.4rem;
  display: flex;
`;

export const ArrowDown = styled(WayDown)`
  margin-top: 0.4rem;
  width: 3rem;
  height: 3rem;
  &::before {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const ArrowUp = styled(WayUp)`
  margin-top: 0.4rem;
  width: 3rem;
  height: 3rem;
`;

export const SearchForm = styled.form`
  display: flex;
`;

export const SearchInput = styled.input`
  font-size: 1.4rem;
  color: ${props => props.theme.tertiaryTextColor};
  padding-left: 2rem;
  margin-left: 2rem;
  background-color: transparent;
  border: solid 0.1rem ${props => props.theme.borderColor};
  outline: none;
  width: 22.8rem;
  height: 3.8rem;
  border-right-width: 0;
  &::placeholder {
    color: ${props => props.theme.tertiaryTextColor};
    text-transform: uppercase;
  }
  ${media.medium`
    width: 13rem;
    margin-left: 1rem;
  `};
  ${media.smaller`
    padding-left: 1rem;
    width: 11rem;
  `};
  ${media.smallest`
    width: 9.2rem;
  `};
`;

export const Search = styled(Button2)`
  width: 3.8rem;
  height: 3.8rem;
  &::after {
    font-family: 'icomoon';
    content: '\\e90a';
    display: inline-block;
    font-size: 2.2rem;
  }
  ${media.medium`
    margin-right: 2rem;
  `};
  ${media.smaller`
    margin-right: 2rem;
  `};
`;
