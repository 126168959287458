import React, {PureComponent} from 'react';

import {
  APP_REDIRECT_TIMEOUT,
  JJ_APOLLO,
  JJ_SESSION_STORAGE,
} from '../../constants/constants';
import {getLoginParams} from '../../helpers/azure.helper';
import {setErrorManually} from '../../actions/error.action';
import {persistDataToSessionStorage} from '../../helpers/sessionStorage.helper';
import Loading from '../Loading/Loading';
import {FormWrapper, LoadingWrapper, LoadingText} from './LoginRegister.style';

class Login extends PureComponent {
  constructor(props) {
    super(props);
    const loginParams = getLoginParams(
      window.location.hostname,
      null,
      props.loginRedirect
    );
    const {verifier, authUrl} = loginParams;
    const {deviceInfo} = props;
    try {
      persistDataToSessionStorage(JJ_SESSION_STORAGE.VERIFIER, verifier);
    } catch (e) {
      setErrorManually(e);
    }
    const isMobile = deviceInfo && (deviceInfo.isPhone || deviceInfo.isTablet);
    if (isMobile) {
      let now = new Date().valueOf();
      this.deepLinkTimer = setTimeout(() => {
        if (new Date().valueOf() - now > 100) return;
        window.location = authUrl;
      }, APP_REDIRECT_TIMEOUT);
      window.location.href = JJ_APOLLO.URI + JJ_APOLLO.ROUTES.LOGIN;
    } else {
      window.location = authUrl;
    }
  }

  componentWillUnmount() {
    if (this.deepLinkTimer) {
      clearTimeout(this.deepLinkTimer);
    }
  }

  render() {
    return (
      <FormWrapper $isLoginModal={true} $nonIframe={true}>
        <LoadingWrapper>
          <LoadingText>
            Please wait, you are being redirected to a login page
          </LoadingText>
          <Loading isLight={false} />
        </LoadingWrapper>
      </FormWrapper>
    );
  }
}

export default Login;
