import {put, call, takeLatest, select, cancelled} from 'redux-saga/effects';
import axios from 'axios';

import * as ActionTypes from '../actions/types.action';
import marketingApi from '../api/marketing.api';
import {getBearerHeader} from '../helpers/api.helper';
import {getMergedData} from '../helpers/data.helper';

function* getMarketingOptions(action) {
  try {
    const marketingOptions = yield call(
      marketingApi.getMarketingOptions,
      action.email
    );
    yield put({
      type: ActionTypes.GET_MARKETING_OPTIONS_SUCCESS,
      marketingOptions,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_MARKETING_OPTIONS_FAILED,
      message: e.message,
    });
  }
}

function* updateMarketingOptions(action) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  try {
    if (action.updateApi) {
      const getUpdatedMarketingOptions = state => state.marketing;
      const updatedMarketingOptions = yield select(getUpdatedMarketingOptions);
      const getAuth = state => state.auth;
      const getProfile = state => state.profile;
      const storeToken = yield select(getAuth);
      const profile = yield select(getProfile);
      const bearerHeaders = getBearerHeader(action.jjToken, storeToken);
      const headers = Object.assign({}, bearerHeaders, {
        cancelToken: source.token,
      });

      updatedMarketingOptions.merge_fields = Object.assign(
        {},
        updatedMarketingOptions.merge_fields,
        getMergedData(action.formValues || profile)
      );

      yield call(
        marketingApi.updateMarketingOptions,
        updatedMarketingOptions,
        (storeToken && storeToken.email) || action.email,
        headers
      );
      yield put({
        type: ActionTypes.UPDATE_MARKETING_OPTIONS_SUCCESS,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_MARKETING_OPTIONS_SUCCESS,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.UPDATE_MARKETING_OPTIONS_FAILED,
      message: e.message,
    });
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled');
      yield put({
        type: ActionTypes.UPDATE_MARKETING_OPTIONS_CANCELLED,
      });
    }
  }
}
export default function* marketingYield() {
  yield takeLatest(
    ActionTypes.GET_MARKETING_OPTIONS_REQUESTED,
    getMarketingOptions
  );
  yield takeLatest(
    ActionTypes.UPDATE_MARKETING_OPTIONS_REQUESTED,
    updateMarketingOptions
  );
  yield takeLatest(
    ActionTypes.UPDATE_ALL_MARKETING_OPTIONS_REQUESTED,
    updateMarketingOptions
  );
}
