import initialState from './initial.state';
import * as types from '../actions/types.action';
import {reduceImageSize} from '../helpers/image.helper';
import {IMAGE_SIZE} from '../constants/constants';

export default function brochuresReducer(
  state = initialState.brochures,
  action
) {
  switch (action.type) {
    case types.GET_BROCHURES_SUCCESS:
      return action.brochures.map(brochure => ({
        ...brochure,
        ...{
          coverImage: reduceImageSize(
            brochure.coverImage,
            false,
            IMAGE_SIZE.BROCHURE
          ),
        },
      }));
    case types.GET_BROCHURES_FAILED:
      return null;
    default:
      return state;
  }
}
