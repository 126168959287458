import {put, call, takeEvery} from 'redux-saga/effects';
import initApi from '../api/init.api';
import * as ActionTypes from '../actions/types.action';

function* init(action) {
  try {
    const initData = yield call(initApi.getInitData, action.params);
    yield put({
      type: ActionTypes.GET_INIT_DATA_SUCCESS,
      init: initData && initData[0] && initData[0].counts,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_INIT_DATA_FAILED,
      message: e.message,
    });
  }
}

export default function* getInitDataYield() {
  yield takeEvery(ActionTypes.GET_INIT_DATA_REQUESTED, init);
}
