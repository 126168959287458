import React, {Fragment, PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  OK,
  ButtonsWrapper,
  VoucherCode,
  Error,
} from './VoucherModal.style';
import {ERRORS} from '../../constants/constants';

export default class VoucherModal extends PureComponent {
  state = {
    voucherCode: '',
    showModal: true,
  };

  componentDidUpdate(prevProps) {
    const {basketVoucher, basketUuid} = this.props;

    if (basketUuid && basketVoucher) {
      this.hideModal();
    }
  }

  handleChange = event => {
    this.setState({voucherCode: event.target.value});
  };

  handleCallback = () => {
    const {callback} = this.props;
    callback(this.state.voucherCode);
  };

  hideModal = () => {
    setTimeout(() => {
      this.setState({showModal: false});
    }, 1500);
  };

  render() {
    const {
      closeModalCallback,
      cancelCallBack,
      basketVoucher,
      basketUuid,
      errors,
    } = this.props;

    if (!this.state.showModal) {
      return null;
    }
    if (
      errors &&
      errors.voucher &&
      errors &&
      errors.voucher.code === ERRORS.SLOT_EXPIRED
    ) {
      return (
        <ModalWrapper
          customStyles={loginStyles}
          isOpen={true}
          closeModal={closeModalCallback}
        >
          <StyledWrapper>
            <Content>
              <TextWrapper>
                <Title>Enter your voucher code</Title>
                <SubContent>
                  Sorry, your slot has expired. Please book a new one before
                  adding your voucher.
                </SubContent>
                <OK onClick={cancelCallBack} data-rw="modal--cancel-button">
                  OK
                </OK>
              </TextWrapper>
            </Content>
          </StyledWrapper>
        </ModalWrapper>
      );
    }
    const renderButton = (
      <OK onClick={this.handleCallback} data-rw="voucher-modal--ok-button">
        Add
      </OK>
    );
    const renderCancelButton = (
      <OK onClick={cancelCallBack} data-rw="modal--cancel-button">
        Cancel
      </OK>
    );
    const renderError = errors && errors.voucher && (
      <Error>That code is not recognised, please try again</Error>
    );
    const voucherApplied = basketVoucher && basketUuid;
    const renderForm = !voucherApplied && (
      <Fragment>
        <VoucherCode
          value={this.state.voucherCode}
          onChange={this.handleChange}
          maxLength="12"
          ref={input => input && input.focus()}
        />
        {renderError}
        <ButtonsWrapper>
          {renderButton}
          {renderCancelButton}
        </ButtonsWrapper>
      </Fragment>
    );
    const renderMessage = voucherApplied && (
      <SubContent>Voucher Accepted!</SubContent>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={true}
        closeModal={closeModalCallback}
      >
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>Enter your voucher code</Title>
              {renderForm}
              {renderMessage}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
