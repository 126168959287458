import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  TextWrapper,
  OK,
  ButtonsWrapper,
} from './ReOrderBasketModal.style';
import Loading from '../Loading/Loading';
import moment from 'moment';
import {CHECKOUT_BEFORE_TIME_FORMAT} from '../../constants/constants';
import {getEditOrderTime} from '../../helpers/basket.helper';

export default class EditOrderBasketModal extends PureComponent {
  render() {
    const {
      showModal,
      isLoadingModal,
      newBasketCallback,
      closeModalCallback,
      cutOffDateTime,
    } = this.props;
    const renderStartNewButton = <OK onClick={newBasketCallback}>OK</OK>;
    const renderCancelButton = <OK onClick={closeModalCallback}>Cancel</OK>;
    const rendering = isLoadingModal ? (
      <Loading isLight={false} />
    ) : (
      <ButtonsWrapper>
        {renderStartNewButton}
        {renderCancelButton}
      </ButtonsWrapper>
    );
    const checkoutBefore = getEditOrderTime(cutOffDateTime);
    const timeLeftForCheckout = checkoutBefore.diff(
      moment(new Date()),
      'minutes'
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
      >
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>
                You have {timeLeftForCheckout} minutes to edit your order.
              </Title>
              <p>
                You must checkout before{' '}
                {checkoutBefore.format(CHECKOUT_BEFORE_TIME_FORMAT)} or your
                edits will be lost and your order will be unchanged.
              </p>
              {rendering}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
