import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:hover label {
    visibility: visible;
  }
`;

export const TagImage = styled.img`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0.3rem;
  &:last-child {
    margin-right: 2rem;
  }
  &:only-child {
    margin-right: 0;
  }
  ${media.small`
    width: 2rem;
    height: 2rem;
  `};
  ${media.smaller`
    width: 1.8rem;
    height: 1.8rem;
  `};
  ${media.smallest`
    width: 1.6rem;
    height: 1.6rem;
  `};
`;

export const TagTooltip = styled.label`
  font-size: 1.2rem;
  padding: ${p => (p.$showTagText ? '0 0.5rem' : '0.75rem')};
  color: ${p =>
    p.$showTagText ? p.theme.primaryColor : p.theme.primaryTextColor};
  background-color: ${p =>
    p.$showTagText ? 'none' : p.theme.secondaryColorHover};
  position: ${p => (p.$showTagText ? 'relative' : 'absolute')};
  left: ${p => (p.$showTagText ? 'initial' : '50%')};
  top: ${p => (p.$showTagText ? 'initial' : '2.8rem;')};
  border-radius: 0.8rem;
  visibility: ${p => (p.$showTagText ? 'visible' : 'hidden')};
`;
