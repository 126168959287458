import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.article`
  width: 100%;
  margin-top: 2.4rem;
  padding-bottom: 4.4rem;
  background-color: ${props => props.theme.secondaryBgColor};
  ${media.medium`
    margin-top: 0;
    padding-bottom: 1rem;
  `};
`;

export const OfferTitle = styled.header`
  color: ${props => props.theme.secondaryColor};
  font-size: 3.6rem;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${media.medium`
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 2.4rem;
  `};
  ${media.smaller`
    padding-top: 0;
    padding-bottom: 2rem;
    font-size: 1.8rem;
    text-align: center;
  `};
`;

export const OfferTitleText = styled.h1`
  font-size: 3.6rem;
  font-weight: lighter;
  margin: 0;
  ${media.small`
    font-size: 2.6rem;
  `};
`;
export const OfferContent = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ButtonWrapper = styled.section`
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
