import React, {Fragment, useState, useEffect} from 'react';

import {TheButton, Wrapper, Title, InfoText} from './UnauthInfoPanel.style';
import loadingPng from '../../images/loading.png';
import {LoadingPng} from '../Loading/Loading.style';

const UnauthInfoPanel = props => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  const {loading, callback} = props;
  const renderContent = !loading && mounted && (
    <Fragment>
      <Title>Login Required</Title>
      <InfoText>You must login to view this page.</InfoText>
      <TheButton onClick={callback}>Sign in</TheButton>
    </Fragment>
  );
  const renderLoading = loading && <LoadingPng src={loadingPng} />;
  return (
    <Wrapper>
      {renderLoading}
      {renderContent}
    </Wrapper>
  );
};

export default UnauthInfoPanel;
