import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function offersReducer(state = initialState.offers, action) {
  switch (action.type) {
    case types.GET_OFFERS_TODAY_SUCCESS:
      return action.offers;
    case types.GET_MORE_OFFERS_SUCCESS:
      return {
        products: [...state.products, ...action.offers.products],
        page: action.offers.page,
      };
    default:
      return state;
  }
}
