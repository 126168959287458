import React from 'react';
import {Title, Text, TextLink} from './BrowserNotSupported.style';
import {Wrapper} from '../../styles/pages/pages';
import {SubTitle} from '../App.style';

export const BrowserNotSupported = () => (
  <Wrapper>
    <Title>Sorry...</Title>
    <SubTitle>Internet Explorer is no longer supported.</SubTitle>
    <Text>You will need to use an up-to-date browser to view our website.</Text>
    <Text>
      Chrome, Safari and Firefox are good choices, or to view a list of
      up-to-date browsers{' '}
      <TextLink href="https://browsehappy.com/" target="_blank">
        click here
      </TextLink>
      .
    </Text>
  </Wrapper>
);
