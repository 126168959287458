import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link} from 'react-router-dom';

import {Button1} from '../../styles/components/buttons';
import {fadeInOut} from '../../styles/components/keyframes';

export const MenuList = styled.aside`
  width: 100%;
  overflow-y: auto;
  background-color: ${p => p.theme.tertiaryBgColor};
  z-index: 2;
  position: absolute;
  top: ${p => (p.$extraMargin ? '17.7rem' : '15.9rem')};
  left: 0;
  right: 0;
  display: ${p => (p.$show ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  animation: ${fadeInOut} 0.3s ease-in;
  ${media.medium`
    top: 11.8rem;
    left: 0;
    right: 0;
    width: 100%;
  `};
  ${media.smaller`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${p =>
      p.$root ? `${p.theme.primaryBgColor}` : `${p.theme.tertiaryBgColor}`};
    z-index: 100;
    transition: transform 0.3s cubic-bezier(0, .52, 0, 1);
  `};
`;

export const Wrapper = styled.div`
  width: 100rem;
  border: ${p => `0.1rem solid ${p.theme.borderColor}`};
  ${media.medium`
    width: 100%;
  `};
  ${media.smaller`
    border-width: 0;
  `};
`;

export const RootLevel = styled.section`
  ${media.smaller`
    display: ${p => (p.$show ? 'block' : 'none')};
  `};
`;

export const ExtraLinks = styled.menu`
  display: flex;
  margin: 0;
  padding: 2rem;
  border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
  ${media.smaller`
    width: 100%;
    padding: 0;
    background-color: ${p => p.theme.primaryColor};
    flex-direction: column;
    border-bottom: 0;
  `};
`;

const theLinkStyle = css`
  padding: 0 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  ${media.smaller`
    width: 100%;
    line-height: 4rem;
    padding: 0 2rem;
    color: ${p => p.theme.primaryTextColor};
    border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
    &:hover {
      color:
       ${p => (p.$light ? p.theme.quaternaryColor : p.theme.secondaryColor)};
    }
  `};
`;
export const ExtraLinkWrapper = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
`;

export const ExtraLink = styled.a`
  ${theLinkStyle};
  display: block;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: ${p => (p.$noTransform ? 'none' : 'uppercase')};
  color: ${p => p.theme.quaternaryColor};
  ${media.smaller`
    color: ${p => p.theme.primaryTextColor};
  `};
`;

export const RouterLink = styled(Link)`
  ${theLinkStyle};
  ${media.smaller`
    color: ${p => p.theme.primaryTextColor};
  `};
`;

export const CategoryBar = styled(Button1)`
  display: none;
  text-transform: uppercase;
  font-size: 1.2rem;

  &:hover {
    color: ${p => p.theme.primaryTextColor};
  }

  ${media.smaller`
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 4rem;
    padding: 0 1rem 0 2rem;
    color: ${p => p.theme.primaryTextColor};
    background-color: ${p => p.theme.secondaryColor};
    border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
      &::after {
        display: inline-block;
        font-family: 'icomoon';
        content: '\\e90b';
        font-size: 3rem;
        transform: rotate(90deg);
        color: ${p => p.theme.primaryTextColor};
      }
  `};
`;

export const NavBreadCrumb = styled.section`
  display: none;
  ${media.smaller`
    display: block;
    white-space: nowrap;
    line-height: 2rem;
    padding: 1rem 1rem 1rem 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${p => p.theme.secondaryColor};
    text-transform: uppercase;
    border-bottom: ${p => `0.1rem solid ${p.theme.secondaryColor}`};
  `};
`;

export const NavBreadCrumbLink = styled.span`
  cursor: pointer;
  margin-right: 1rem;
`;

export const NavBreadCrumbSubLink = styled(NavBreadCrumbLink)`
  color: ${p => (p.$isActive ? p.theme.secondaryColor : p.theme.textColor)};
  margin-left: 1rem;
`;

export const LoggedInLinks = styled.div`
  display: none;
  ${media.smaller`
    display: flex;
    flex-direction: column;
    background-color: ${p => p.theme.primaryBgColor};
  `};
`;

export const Selected = styled.span`
  color: ${p => p.theme.secondaryColor};
  text-transform: uppercase;
  ${media.smaller`
    display: none;
  `};
`;

export const Store = styled.div`
  padding: 0 1.6rem 0 0;
  font-size: 1.2rem;
  ${media.smaller`
    width: 100%;
    line-height: 4rem;
    color: ${p => p.theme.primaryTextColor};
    padding: 0 2rem;
    border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
  `};
`;

export const ChangeStore = styled(Button1)`
  cursor: pointer;
  text-transform: capitalize;
  padding-left: 0.4rem;
  ${media.smaller`
    display: none;
  `};
`;

export const BookASlot = styled(Link)`
  display: none;
  ${media.smaller`
    display: block;
    text-transform: uppercase;
    width: 100%;
    line-height: 4rem;
    color: ${p => p.theme.primaryTextColor};
    &:hover {
      color:
       ${p => (p.$light ? p.theme.quaternaryColor : p.theme.secondaryColor)};
    }
  `};
`;

export const NavLink = styled(Link)`
  ${theLinkStyle};
  color: ${p => p.theme.quaternaryColor};
`;
