import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function nextRouteReducer(
  state = initialState.nextRoute,
  action
) {
  switch (action.type) {
    case types.NAVIGATE_TO_CHECKOUT:
    case types.NAVIGATE_TO_ROUTE:
    case types.SET_NEXT_ROUTE_SUCCESS:
      return action.nextRoute;
    case types.SET_FULFILLMENT_REQUESTED:
    case types.DELETE_FULFILL_SLOT_REQUESTED:
    case types.NAVIGATE_RESET:
      //reset nextRoute url
      return null;
    default:
      return state;
  }
}
