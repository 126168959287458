import React, {PureComponent} from 'react';
import {
  Content,
  TextWrapper,
  PaymentButtonsWrapper,
  PbbLockedText,
} from './PaymentPanel.style';
import PaymentTypeItem from './PaymentTypeItem';
import {checkHasPbbPayment} from '../../helpers/data.helper';
import {PAYMENT_TYPES} from '../../constants/constants';

export default class PaymentPanel extends PureComponent {
  constructor(props) {
    super(props);
    const {
      selectedPaymentType,
      paymentOptions,
      isEditMode,
      editOrderOriginalPaymentMethod,
    } = props;
    this.state = {
      updatedPaymentType: selectedPaymentType,
      hasPbbPayment: checkHasPbbPayment(paymentOptions),
      isPbbLockedMode:
        !!isEditMode &&
        editOrderOriginalPaymentMethod === PAYMENT_TYPES.PBB.mode,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {selectedPaymentType} = this.props;
    if (this.state.updatedPaymentType !== selectedPaymentType) {
      this.setState({updatedPaymentType: selectedPaymentType});
    }
  }

  updateCurrentPaymentType = updatedPaymentType => {
    const {isEditMode, callback, editOrderOriginalPaymentMethod} = this.props;
    if (
      isEditMode &&
      editOrderOriginalPaymentMethod !== PAYMENT_TYPES.CAS.mode
    ) {
      return;
    }
    if (this.state.isPbbLockedMode) {
      this.setState({updatedPaymentType: PAYMENT_TYPES.PBB.mode});
      return;
    }
    this.setState({updatedPaymentType});
    callback(updatedPaymentType);
  };

  render() {
    const {
      paymentOptions,
      pbbBankDetails,
      setUsePbbDefaultBank,
      isUseDefaultPbb,
      isEditMode,
      commercialCardCharge,
      separatedCommercialOption,
    } = this.props;
    const {updatedPaymentType, hasPbbPayment, isPbbLockedMode} = this.state;
    const content = paymentOptions.map((paymentOption, index) => {
      if (
        paymentOption.mode === PAYMENT_TYPES.BCDC.mode &&
        separatedCommercialOption
      ) {
        return null;
      }
      return (
        <PaymentTypeItem
          key={paymentOption.mode}
          selectedPaymentType={updatedPaymentType}
          paymentOption={paymentOption.mode}
          isSelected={updatedPaymentType === paymentOption.mode}
          callback={this.updateCurrentPaymentType}
          isBottom={index === paymentOptions.length - 1}
          pbbBankDetails={pbbBankDetails}
          isUseDefaultPbb={isUseDefaultPbb}
          setUsePbbDefaultBank={setUsePbbDefaultBank}
          isPbbLockedMode={isPbbLockedMode}
          isEditMode={isEditMode}
          commercialCardCharge={commercialCardCharge}
          isAdyenPayment={separatedCommercialOption}
        />
      );
    });
    const renderPbbLockedModeWarning = isPbbLockedMode && (
      <PbbLockedText>
        You can not switch to other payment methods while editing the Pay By
        Bank order.
      </PbbLockedText>
    );
    return (
      <Content>
        <TextWrapper>
          <PaymentButtonsWrapper>{content}</PaymentButtonsWrapper>
        </TextWrapper>
        {renderPbbLockedModeWarning}
      </Content>
    );
  }
}
