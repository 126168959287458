import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function settingsReducer(state = initialState.settings, action) {
  switch (action.type) {
    case types.GET_ALL_SETTINGS_SUCCESS:
    case types.UPDATE_SETTING_SUCCESS:
    case types.UPDATE_ALL_SETTINGS_SUCCESS:
      return action.settings;
    case types.REMOVE_BASKET_SUCCEEDED:
    case types.INVALID_BASKET_UUID_ERROR:
      if (state) {
        return Object.assign({}, state, {
          basketUuid: null,
        });
      } else {
        return null;
      }
    case types.UPDATE_SETTING_FAILED:
    case types.UPDATE_ALL_SETTINGS_FAILED:
    case types.REMOVE_AUTH_REQUESTED:
    case types.RESET_SETTING_SUCCESS:
      return null;
    default:
      return state;
  }
}
