import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class fastlyApi {
  static getBrochures() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/pdfs/brochures.json`;
    return axios.get(url).then(res => res.data);
  }
}
