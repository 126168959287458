import styled, {css} from 'styled-components';

export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: ${p => p.theme.primaryTextColor};
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.1rem;
`;

const theIcontStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    font-family: 'icomoon';
    display: inline-block;
  }
`;

export const FavoriteIcon = styled.div`
  ${theIcontStyle};
  &::before {
    content: '\\e900';
    font-size: 2rem;
  }
`;

export const PurchasedIcon = styled.div`
  ${theIcontStyle};
  &::before {
    content: '\\e903';
    font-size: 1.7rem;
  }
`;

export const CrossIcon = styled.div`
  ${theIcontStyle};
  &::before {
    content: '\\e90d';
    font-size: 3rem;
    font-weight: normal;
    transform: rotate(45deg);
  }
`;

export const BackIcon = styled.div`
  ${theIcontStyle};
  &::before {
    content: '\\e910';
    font-size: 3rem;
    font-weight: normal;
    transform: rotate(90deg);
  }
`;
