import axios from 'axios';
import config from '../config/config.json';
import {getApiConfig} from '../config/configProvider';

export default class cmsApi {
  static getBanners() {
    const serverConfig = getApiConfig();
    let fastlyUrl = serverConfig.fastlyAssets;
    if (!serverConfig.fastlyAssets) {
      fastlyUrl = config.fastlyAssets;
    }
    const url = `${fastlyUrl}/content/banners.json`;
    return axios.get(url).then(res => res.data);
  }

  static getAboutUs() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/aboutus.json`;
    return axios.get(url).then(res => res.data);
  }

  static getCareers() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/careers.json`;
    return axios.get(url).then(res => res.data);
  }

  static getCustomers() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/customers.json`;
    return axios.get(url).then(res => res.data);
  }

  static getBrochures() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/brochures.json`;
    return axios.get(url).then(res => res.data);
  }

  static getVacancies() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/vacancies.json`;
    return axios.get(url).then(res => res.data);
  }

  static getDepartments() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/vacanciesHelper.json`;
    return axios.get(url).then(res => res.data);
  }

  static getTeam() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/team.json`;
    return axios.get(url).then(res => res.data);
  }

  static getHelp() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/help.json`;
    return axios.get(url).then(res => res.data);
  }

  static getPayByBankFAQ() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/paybybank.json`;
    return axios.get(url).then(res => res.data);
  }

  static getWhyJJ() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/whyjj.json`;
    return axios.get(url).then(res => res.data);
  }

  static getSupplyUs() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/supplyus.json`;
    return axios.get(url).then(res => res.data);
  }

  static getPolicies() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/policies.json`;
    return axios.get(url).then(res => res.data);
  }

  static getDeliveries() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/deliveries.json`;
    return axios.get(url).then(res => res.data);
  }

  static getOwnBrands() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/content/ownBrands.json`;
    return axios.get(url).then(res => res.data);
  }
}
