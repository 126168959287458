import * as ActionTypes from './types.action';

export const getFulfillSlot = (branchId, fulfillmentType) => ({
  type: ActionTypes.GET_FULFILL_SLOT_REQUESTED,
  branchId,
  fulfillmentType,
});

export const setFulfillSlot = (
  uuid,
  slot,
  branchId,
  fulfillmentType,
  slotReference,
  driverInstruction,
  goCheckout
) => ({
  type: ActionTypes.SET_FULFILL_SLOT_REQUESTED,
  uuid,
  slot,
  branchId,
  fulfillmentType,
  slotReference,
  driverInstruction,
  goCheckout,
});

export const removeFulfillSlot = uuid => ({
  type: ActionTypes.DELETE_FULFILL_SLOT_REQUESTED,
  uuid,
});

export const resetFulfillSlot = () => ({
  type: ActionTypes.RESET_FULFILL_SLOTS_SUCCESS,
});
