import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import IframeComm from 'react-iframe-comm';
import {isInclusivelyBeforeDay} from 'react-dates';

import {
  Wrapper,
  Title,
  Content,
  ButtonWrapper,
  LinkButton,
  Head,
} from '../../styles/pages/pages';
import {ExtraWrapper} from '../../styles/components/wrapper';
import {
  ReportContent,
  Selector,
  Option,
  AccountText,
  FilterWrapper,
  Block,
  CheckboxBlock,
  CheckboxLabel,
  Label,
} from './ReportPage.style';
import {getApiConfig} from '../../config/configProvider';
import {
  ANALYTICS_EVENTS,
  CUSTOMER_TYPES,
  JJ_DOMAIN,
  REPORT_TYPES,
  STANDARD_FORMAT,
  TRANSACTION_TYPES,
} from '../../constants/constants';
import RenderDateRangePicker from '../../components/DatePicker/RenderDateRangePicker';
import moment from 'moment';
import {CheckMark} from '../../styles/components/checkbox';
import UnauthInfoPanel from '../../components/UnauthInfoPanel/UnauthInfoPanel';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

class ReportPage extends PureComponent {
  constructor(props) {
    super(props);
    this.today = moment();
    this.lastMonth = moment().subtract(1, 'months');
    this.sinceDate = moment(new Date('1985-01-01'));
    const cAccount = props.auth && props.auth.c_account;
    this.state = {
      reportType: REPORT_TYPES[0],
      startDate: this.lastMonth,
      endDate: this.today,
      customerList: cAccount,
      isAllTransaction: true,
    };
    this.config = getApiConfig();
  }
  componentDidMount() {
    if (window && window.location && window.location.hostname) {
      const hostname =
        window.location.hostname === JJ_DOMAIN.PROD
          ? JJ_DOMAIN.PROD
          : JJ_DOMAIN.DEV;
      this.setState({hostname});
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {auth} = this.props;
    if (!prevProps.auth && auth) {
      if (!this.state.customerList) {
        this.setState({customerList: auth.c_account});
      }
    }
  }

  toggleTransactionType = () => {
    const isAllTransaction = !this.state.isAllTransaction;
    this.setState({isAllTransaction});
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.TRANSACTION_TYPE_CHANGE, {
      isAllTransaction,
    });
  };

  onViewAccountChange = option =>
    this.setState({customerList: option.target.value});

  onReportTypeChange = option => {
    this.setState({reportType: option.target.value});
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.REPORT_TYPE_CHANGE, {
      type: option.target.value,
    });
  };

  onDatesChange = ({startDate, endDate}) => this.setState({startDate, endDate});

  isOutsideRange = date => !isInclusivelyBeforeDay(date, this.today);

  isDayBlocked = date =>
    !isInclusivelyBeforeDay(date, this.today) ||
    isInclusivelyBeforeDay(date, this.sinceDate);

  render() {
    const {
      reportType,
      startDate,
      endDate,
      hostname,
      isAllTransaction,
      customerList,
    } = this.state;
    const {accounts, auth, relogin, loading} = this.props;
    if (!auth) {
      return (
        <UnauthInfoPanel callback={relogin} loading={loading && loading.auth} />
      );
    }
    const customerNo =
      customerList === CUSTOMER_TYPES.ALL
        ? !!auth && auth.c_account
        : customerList;
    const src =
      auth &&
      customerList &&
      startDate &&
      endDate &&
      hostname &&
      `${
        this.config.reportApi
      }/MvcReportViewer.aspx?_r=/Reports/Web Customer Reports/${reportType}&datefrom=${startDate.format(
        STANDARD_FORMAT
      )}&dateto=${endDate.format(
        STANDARD_FORMAT
      )}&CustomerList=${customerList}&CustomerNo=${customerNo}&TransactionType=${
        isAllTransaction ? TRANSACTION_TYPES.ALL : TRANSACTION_TYPES.OUTSTANDING
      }&token=${auth.access_token}&host=https://${hostname}`;
    const renderAccounts =
      accounts &&
      accounts.map((account, key) => (
        <Option key={account.id + key} value={account.id || account.number}>
          {account.id} {account.name}
        </Option>
      ));
    const renderAllOption = accounts && accounts.length > 1 && (
      <Option value={CUSTOMER_TYPES.ALL}>All Accounts</Option>
    );
    const renderSelector = accounts && (
      <Selector value={customerList} onChange={this.onViewAccountChange}>
        {renderAllOption}
        {renderAccounts}
      </Selector>
    );
    const renderIframe = !!(reportType && startDate && endDate && src) && (
      <IframeComm
        attributes={{
          src,
          width: '100%',
          height: '100%',
          frameBorder: 0,
        }}
        handleReady={this.onIFrameReady}
        handleReceiveMessage={this.onReceiveMessage}
      />
    );
    const renderReportTypes = REPORT_TYPES.map(type => (
      <Option key={type} value={type}>
        {type}
      </Option>
    ));
    return (
      <Wrapper>
        <Head>
          <Title>My Reports</Title>
          <ButtonWrapper>
            <AccountText>Showing reports for</AccountText>
            {renderSelector}
          </ButtonWrapper>
        </Head>
        <Content>
          <FilterWrapper>
            <Block>
              <Label>Select date range</Label>
              <RenderDateRangePicker
                isOutsideRange={this.isOutsideRange}
                isDayBlocked={this.isDayBlocked}
                startDateId={'fromDate'}
                endDateId={'toDate'}
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.onDatesChange}
              />
            </Block>
            <Block>
              <Label>Select report type</Label>
              <Selector
                value={reportType}
                onChange={this.onReportTypeChange}
                $maxWidth={true}
              >
                {renderReportTypes}
              </Selector>
            </Block>
            <CheckboxBlock>
              <CheckboxLabel
                onClick={this.toggleTransactionType}
                $alignRight={true}
              >
                <CheckMark $isChecked={isAllTransaction} />
                All transactions
              </CheckboxLabel>
              <CheckboxLabel onClick={this.toggleTransactionType}>
                <CheckMark $isChecked={!isAllTransaction} />
                Outstanding only
              </CheckboxLabel>
            </CheckboxBlock>
          </FilterWrapper>
          <ReportContent>{renderIframe}</ReportContent>
        </Content>
        <ExtraWrapper>
          <LinkButton to="/">Continue shopping</LinkButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  loading: state.loading,
});

export default connect(mapStateToProps)(ReportPage);
