export const getVacanciesData = (vacancies, departments) => {
  let locations = ['All'];
  let categories = {};

  vacancies &&
    Object.values(vacancies).map(item => {
      if (
        item['CM:Location'] &&
        locations.indexOf(item['CM:Location'].toLowerCase()) === -1
      ) {
        locations.push(item['CM:Location'].toLowerCase());
      }
      // Check if the title starts with "Internal" and categorize under "Internal"
      if (item.title && item.title.startsWith('Internal')) {
        if (!categories['Internal']) {
          categories['Internal'] = {jobs: []};
        }
        if (
          !categories['Internal'].jobs.find(
            job => job['CM:JobRef'] === item['CM:JobRef']
          )
        ) {
          categories['Internal'].jobs.push(item);
        }
        categories['Internal'].title = 'Internal';
      } else {
        // Iterate through the rest of the departments and assign categories
        departments.map(department => {
          department.Keywords.map(keyword => {
            if (item && item.title && item.title.indexOf(keyword) > -1) {
              if (
                department &&
                department.Title &&
                categories &&
                categories[department.Title] &&
                categories[department.Title].jobs
              ) {
                if (
                  !categories[department.Title].jobs.find(
                    job => job['CM:JobRef'] === item['CM:JobRef']
                  )
                ) {
                  categories[department.Title].jobs.push(item);
                }
              } else {
                categories[department.Title] = {jobs: [item]};
              }
              categories[department.Title].title = department.Title;
              categories[department.Title].imageUrl = department.Img;
              categories[department.Title].videoUrl = department.Video;
            }
          });
        });
      }
    });

  return {
    categories,
    locations,
  };
};
