import styled from 'styled-components';
import {Label, Radio} from '../../styles/components/checkbox';

export const Service = styled(Label)`
  margin-bottom: 1rem;
  width: 40%;
`;

export const CheckMark = styled(Radio)`
  border-color: ${p =>
    p.$isChecked
      ? p.$isDelivery
        ? p.theme.primaryColor
        : p.theme.secondaryColor
      : p.theme.tertiaryTextColor};
  top: 0;
  left: 0;
  margin-right: 1rem;
  &:before {
    left: 0.1rem;
    top: 0.3rem;
    background: ${p =>
      p.$isChecked
        ? p.$isDelivery
          ? p.theme.primaryColor
          : p.theme.secondaryColor
        : p.theme.tertiaryTextColor};
  }
`;

export const Type = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${p =>
    p.$isChecked
      ? p.$isDelivery
        ? p.theme.primaryColor
        : p.theme.secondaryColor
      : p.theme.tertiaryTextColor};
`;
