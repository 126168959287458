import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function offersTodayReducer(
  state = initialState.offersToday,
  action
) {
  switch (action.type) {
    case types.GET_OFFERS_HOMEPAGE_SUCCESS:
      return action.offers;
    default:
      return state;
  }
}
