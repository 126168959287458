import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';

import {getOrderByOrderId} from '../../actions/order.action';
import {
  Wrapper,
  Title,
  ButtonWrapper,
  LinkButton,
  Head,
  ButtonSubWrapper,
} from '../../styles/pages/pages';
import {
  TheButton,
  OrderSlot,
  BasketPageContent,
  ContentBlock,
  InfoText,
  ButtonGroup,
  OrderText,
  OrderButton,
  Slot,
  SlotText,
  NoSlotText,
  TextTitle,
  BasketItemsWrapper,
  EmptyBasket,
  BasketButton,
  StatusWrapper,
  ChangeSlot,
  LoadingWrapper,
  ButtonLoadingWrapper,
  EditContentBlock,
  AdminItemBox,
  ItemBox,
  AdminTitle,
} from './BasketPage.style';
import BasketItems from '../../components/Basket/BasketItems';
import PriceSummary from '../../components/PriceSummary/PriceSummary';
import EditItems from './EditItems';
import {ExtraWrapper} from '../../styles/components/wrapper';
import BookSlotStatus from '../../components/BookSlotStatus/BookSlotStatus';
import {
  getProductsByIds,
  syncBasketItemsWithMessages,
  removeBasket,
  resetBasketItems,
  setCurrentRoute,
  setSubstituteItem,
  getBasket,
  applyVoucher,
  clearVoucherErrors,
  removeVoucher,
  setPaymentType,
  checkBasketSynced,
} from '../../actions';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
  removeValueFromLocalStorage,
} from '../../helpers/localStorage.helper';
import {
  BASKET_SYNC_TIMEOUT,
  JJ_LOCAL_STORAGE,
  ROUTES,
  SERVICE_TYPE,
  SLOT_FULFILMENT_STATUSES,
  STRING_ALL_REGEX,
} from '../../constants/constants';
import {
  enrichBasketItemsWithMessages,
  hasMinDeliveryOrderNotMet,
  shouldReFetchFullBasket,
} from '../../helpers/data.helper';
import PaymentSelector from '../../components/PaymentSelector/PaymentSelector';
import Loading from '../../components/Loading/Loading';
import {removeSetting} from '../../actions/settings.action';
import PopupModal from '../../components/PopupModal/PopupModal';
import {removeFulfillSlot, setFulfillSlot} from '../../actions/slots.action';
import {setErrorManually} from '../../actions/error.action';
import {
  checkSlotExpired,
  getOosItemsSize,
  isBasketSynced,
} from '../../helpers/basket.helper';
import {
  toggleCustomerSupportModal,
  toggleMaintenanceModal,
} from '../../actions/modal.action';
import {getPbbBankDetails} from '../../actions/pbbBankDetails.action';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

class BasketPage extends PureComponent {
  constructor(props) {
    super(props);
    const {orderId} = props.match.params;
    this.orderStatusChecked = false;
    this.orderErrorsAnalysed = false;
    this.slotStatusChecked = false;
    this.reFetchFullBasket = false;
    this.futureNoContactOption = '';
    this.isGettingBasketTotal = false;
    this.isGettingDefaultPbbBank = false;
    this.isGettingDifferentPbbBank = false;
    this.isEditBasketPaymentMethodSet = false;
    this.pageLogged = false;
    this.state = {
      orderId,
      activeOrderId: null, // if there is an outstanding order
      showModal: false,
      basketItemIds: null,
      isSlotExpired: false,
      showOosModal: false,
      editOrderOriginalPaymentMethod: null,
      editOrderTimeRemaining: null,
      adminItemCode: '',
      adminItemLoading: false,
    };
  }

  async componentDidMount() {
    const {
      getOrderByOrderId,
      location,
      updateBasket,
      setCurrentRoute,
    } = this.props;
    setCurrentRoute(ROUTES.BASKET);
    const {orderId} = this.state;
    if (orderId) {
      getOrderByOrderId(orderId);
    } else {
      const activeOrderId = await getDataFromLocalStorage(
        JJ_LOCAL_STORAGE.ORDER_ID
      );
      if (activeOrderId) {
        this.setState({activeOrderId});
      }
    }
    if (location.state && location.state.uuid) {
      updateBasket(location.state);
    }
    const editOrderOriginalPaymentMethod = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.EDIT_ORDER_PAYMENT_METHOD
    );
    if (editOrderOriginalPaymentMethod) {
      this.isEditBasketPaymentMethodSet = true;
      this.setState({editOrderOriginalPaymentMethod});
    }
  }

  setEditOrderCountdownTimer = () => {
    const {settings} = this.props;
    if (!settings || (settings && !settings.editOrderTime)) {
      return;
    }
    let editOrderTimeRemaining;
    const storedTime = moment.unix(settings.editOrderTime);
    const now = moment();
    const editOrderTimeRemainingMinutes = storedTime.diff(now, 'minutes');
    const editOrderTimeRemainingSeconds = storedTime.diff(now, 'seconds');
    if (editOrderTimeRemainingSeconds <= 60) {
      editOrderTimeRemaining = editOrderTimeRemainingSeconds + ' seconds';
    } else {
      editOrderTimeRemaining = editOrderTimeRemainingMinutes + ' minutes';
    }
    this.setState({editOrderTimeRemaining});
    if (now.diff(storedTime, 'seconds') >= 0) {
      clearInterval(this.editOrderCountdownTimer);
    }
  };

  async componentDidUpdate(prevProps) {
    const {
      order,
      basket,
      getOrderByOrderId,
      auth,
      getBasket,
      basketItems,
      settings,
      syncBasketItemsWithMessages,
      checkBasketSynced,
      loading,
      errors,
      pbbDefaultBank,
      getPbbBankDetails,
    } = this.props;
    const {editOrderOriginalPaymentMethod} = this.state;
    // always check if the paymentMethod in editMode is correct
    if (
      !!auth &&
      basket &&
      !!basket.editMode &&
      isBasketSynced(basket.syncStatus) &&
      !this.isEditBasketPaymentMethodSet &&
      editOrderOriginalPaymentMethod !== basket.paymentMethod
    ) {
      this.isEditBasketPaymentMethodSet = true;
      this.setState({editOrderOriginalPaymentMethod: basket.paymentMethod});
      await persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.EDIT_ORDER_PAYMENT_METHOD,
        basket.paymentMethod
      );
    }
    if (
      auth &&
      (!basketItems ||
        (basketItems &&
          ((basketItems.inStock && basketItems.inStock.length > 0) ||
            (basketItems.outOfStock && basketItems.outOfStock.length > 0)))) &&
      !this.gotFullBasket
    ) {
      this.gotFullBasket = true;
      getBasket(true, !!basketItems);
    }
    if (
      basket &&
      !isBasketSynced(basket.syncStatus) &&
      prevProps.basket &&
      isBasketSynced(prevProps.basket.syncStatus)
    ) {
      this.gotFullBasket = false;
    }
    // fixed basket override full basket issue while redirect from other routes
    // so we get the full basket again without notices user
    if (
      auth &&
      ((basket &&
        settings &&
        shouldReFetchFullBasket(basketItems) &&
        !this.reFetchFullBasket) ||
        (prevProps.basket &&
          basket &&
          basket.paymentMethod !== prevProps.basket.paymentMethod))
    ) {
      this.reFetchFullBasket = true;
      const getFullBasket = true;
      const silentlyFetchBasket = true;
      getBasket(getFullBasket, silentlyFetchBasket);
    }
    if (
      auth &&
      basket &&
      settings &&
      settings.pbbDefaultBankId &&
      !pbbDefaultBank &&
      !this.isGettingDefaultPbbBank
    ) {
      this.isGettingDefaultPbbBank = true;
      getPbbBankDetails(settings.pbbDefaultBankId);
    }
    if (
      settings &&
      !!settings.pbbDefaultBankId &&
      !!pbbDefaultBank &&
      settings.pbbDefaultBankId !== pbbDefaultBank.bank_id &&
      !this.isGettingDifferentPbbBank
    ) {
      this.isGettingDifferentPbbBank = true;
      getPbbBankDetails(settings.pbbDefaultBankId);
    }
    if (
      basket &&
      !isBasketSynced(basket.syncStatus) &&
      !this.isGettingBasketTotal
    ) {
      this.isGettingBasketTotal = true;
      this.interval = setInterval(
        () => checkBasketSynced(),
        BASKET_SYNC_TIMEOUT
      );
    }
    if (
      basket &&
      isBasketSynced(basket.syncStatus) &&
      this.isGettingBasketTotal
    ) {
      this.isGettingBasketTotal = false;
      clearInterval(this.interval);
    }
    // get the order details while there is an active but uncompleted order
    if (
      !order &&
      this.state.activeOrderId &&
      auth &&
      auth.enhanced &&
      !this.orderStatusChecked
    ) {
      this.orderStatusChecked = true;
      getOrderByOrderId(this.state.activeOrderId);
    }
    // sync basket items with errors after enrich basket items
    if (
      order &&
      order.messages &&
      order.messages.length > 0 &&
      !this.orderErrorsAnalysed &&
      basketItems
    ) {
      this.orderErrorsAnalysed = true;
      const basketItemsWithMessages = enrichBasketItemsWithMessages(
        basketItems,
        order.messages
      );
      syncBasketItemsWithMessages(basketItemsWithMessages);
    }
    if (
      basket &&
      basket.fulfillAtSlot &&
      !this.slotStatusChecked &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT
    ) {
      this.slotStatusChecked = true;
      const isSlotExpired = checkSlotExpired(basket, false, false);
      this.setState({isSlotExpired});
    }
    // redirect to bookSlot page after user clicks `book a new slot`
    if (
      this.redirectToBookSlot &&
      prevProps.basket &&
      prevProps.basket.fulfillAtSlot &&
      basket &&
      !basket.fulfillAtSlot
    ) {
      this.bookSlot();
    }
    if (loading && !loading.addingToBasket && this.state.adminItemLoading) {
      this.setState({adminItemLoading: false});
      if (errors && !errors.updateBasket && basket) {
        this.setState({adminItemCode: ''});
        const getFullBasket = true;
        getBasket(getFullBasket);
      }
    }
    if (
      !this.editOrderCountdownTimer &&
      basket &&
      isBasketSynced(basket.syncStatus)
    ) {
      this.editOrderCountdownTimer = setInterval(
        this.setEditOrderCountdownTimer,
        1000
      );
    }
    const basketSynced =
      basket &&
      isBasketSynced(basket.syncStatus) &&
      loading &&
      !loading.updatingBasket &&
      !loading.addingToBasket &&
      !this.isGettingBasketTotal;
    if (basketSynced && !this.pageLogged) {
      this.pageLogged = true;
      GoogleAnalyticsHelper.logPageView();
    }
  }

  componentWillUnmount() {
    const {resetBasketItems, setCurrentRoute} = this.props;
    // reset basketItems in redux to avoid the complicated logic
    resetBasketItems();
    this.gotFullBasket = false;
    this.reFetchFullBasket = false;
    setCurrentRoute(null);
    this.redirectToBookSlot = false;
    clearTimeout(this.editOrderCountdownTimer);
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  bookSlot = () => {
    const {systemMessages, toggleMaintenanceModal, history} = this.props;
    if (
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0
    ) {
      toggleMaintenanceModal(true);
      return;
    }
    history.push(`/bookSlot`);
  };

  bookNewSlot = () => {
    const {removeFulfillSlot, basket} = this.props;
    removeFulfillSlot(basket.uuid);
    this.redirectToBookSlot = true;
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.FULFILLMENT);
  };

  cleanBasket = () => {
    const {basket, openStopEditModal, cleanBasket} = this.props;
    if (basket && basket.editMode) {
      openStopEditModal();
    } else {
      cleanBasket();
    }
  };

  editItems = () => {
    if (this.props.basket.items.length > 0) {
      this.toggleModal();
    } else {
      // todo: open current order in basket
    }
  };

  toggleModal = () => this.setState({showModal: !this.state.showModal});

  toggleOosModal = () =>
    this.setState({showOosModal: !this.state.showOosModal});

  goCheckout = () => {
    const {
      navigateToCheckout,
      settings,
      toggleCustomerSupportModal,
    } = this.props;
    if (
      settings &&
      settings.customerSupportMessage &&
      settings.customerSupportMessage !== ''
    ) {
      toggleCustomerSupportModal(true);
      return;
    }
    this.setState({showOosModal: false}, () => navigateToCheckout());
  };
  startCheckout = () => {
    const {basketItems, basket, setErrorManually} = this.props;
    const oosItemsSize = basketItems && getOosItemsSize(basketItems.outOfStock);
    if (hasMinDeliveryOrderNotMet(basket)) {
      setErrorManually('BASKET_MIN_DELIVERY_ORDER_ERROR');
    } else if (oosItemsSize && oosItemsSize.length > 0) {
      this.setState({showOosModal: true});
    } else {
      this.goCheckout();
    }
  };
  handleItemCodeChange = event =>
    this.setState({adminItemCode: event.target.value});

  addItemByCode = () => {
    const {adminItemCode} = this.state;
    if (!adminItemCode) {
      return;
    }
    this.setState({adminItemLoading: true});
    this.props.updateBasket({
      itemId: adminItemCode.replace(STRING_ALL_REGEX, '').toUpperCase(),
      step: 1,
      quantity: 1,
    });
  };

  render() {
    const {
      currency,
      updateBasket,
      removeItem,
      updateItem,
      order,
      loading,
      basketHashMap,
      basketItems,
      profile,
      branchList,
      substitutes,
      setSubstituteItem,
      applyVoucher,
      clearVoucherErrors,
      removeVoucher,
      settings,
      auth,
      errors,
      promoTotal,
      onProductClick,
      isStaffAccount,
    } = this.props;
    const {
      orderId,
      isSlotExpired,
      showOosModal,
      editOrderTimeRemaining,
      adminItemCode,
      adminItemLoading,
      editOrderOriginalPaymentMethod,
    } = this.state;
    // using order as basket for view order page
    const basket = orderId ? order : this.props.basket;
    const isDelivery = !!(
      basket && basket.fulfillmentType === SERVICE_TYPE.DELIVERY
    );
    const isCollection = !!(
      basket && basket.fulfillmentType === SERVICE_TYPE.COLLECTION
    );
    const hasBasketItem = basket && basket.items && basket.items.length > 0;
    const renderBasketItems = hasBasketItem && (
      <BasketItems
        items={basketItems}
        removeItem={removeItem}
        updateItem={updateItem}
        updateBasket={updateBasket}
        basketHashMap={basketHashMap}
        substitutes={substitutes}
        onBasketItemClick={onProductClick}
        setSubstituteItem={setSubstituteItem}
        currency={currency || '£'}
        viewOnly={!!orderId}
        loading={loading}
        isDelivery={isDelivery}
        synced={isBasketSynced(basket.syncStatus)}
      />
    );
    const renderBasketPanel =
      loading && loading.gettingBasket ? (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      ) : (
        renderBasketItems
      );
    const isBasketEmpty =
      (loading &&
        !loading.gettingBasket &&
        settings &&
        (!basket || (basket && basket.items && basket.items.length === 0))) ||
      (!auth && !settings && !basket);
    const renderTitle = orderId ? 'View Order' : 'My basket';
    const renderNoItems = isBasketEmpty && (
      <EmptyBasket>Empty Basket</EmptyBasket>
    );
    const oosItemsTotalSize =
      (basketItems && getOosItemsSize(basketItems.outOfStock)) || 0;
    const onlyContainsOosItems =
      oosItemsTotalSize > 0 &&
      basket &&
      basket.items &&
      oosItemsTotalSize === basket.items.length;
    const plural = oosItemsTotalSize > 1 ? 's' : '';
    const renderOosTitle = onlyContainsOosItems
      ? 'You cannot check out'
      : `Remove unavailable item${plural}?`;
    const renderOosContent = onlyContainsOosItems
      ? `The item${plural} in your basket ${
          oosItemsTotalSize > 1 ? 'are' : 'is'
        } unavailable`
      : `Or cancel to go back and select alternatives`;
    const renderOosModal = showOosModal && (
      <PopupModal
        modalName="Oos issue"
        title={renderOosTitle}
        content={renderOosContent}
        showOKButton={true}
        showCancelButton={!onlyContainsOosItems}
        callback={onlyContainsOosItems ? this.toggleOosModal : this.goCheckout}
        closeModalCallback={this.toggleOosModal}
        showModal={showOosModal}
      />
    );
    const renderCheckoutButton = !isBasketEmpty && !orderId && (
      <TheButton onClick={this.startCheckout}>Check out now</TheButton>
    );
    const renderShoppingLink = !isBasketEmpty && !orderId && (
      <LinkButton to="/">Continue shopping</LinkButton>
    );
    const renderEditButton = orderId && (
      <TheButton $fullWidth={true} onClick={this.editItems}>
        Edit Items
      </TheButton>
    );

    const renderPriceSummary = basket && (
      <ContentBlock>
        <PriceSummary
          basket={basket}
          cleanBasket={this.cleanBasket}
          minOrder={basket.minimumOrderAmount}
          showDeleteBasketBtn={true}
          showAddVoucherBtn={true}
          showDeliveryOnly={isDelivery}
          showCollectionOnly={isCollection}
          loading={loading}
          applyVoucher={applyVoucher}
          clearVoucherErrors={clearVoucherErrors}
          removeVoucher={removeVoucher}
          errors={errors}
          openStopEditModal={this.props.openStopEditModal}
        />
      </ContentBlock>
    );
    const renderPaymentSelector = profile && basket && (
      <ContentBlock>
        <PaymentSelector
          paymentOptions={profile.paymentOptions}
          paymentMethod={basket.paymentMethod}
          basketUuid={basket.uuid}
          promoTotal={promoTotal}
          isEditMode={basket.editMode}
          slot={basket.fulfillAtSlot}
          fulfillmentType={basket.fulfillmentType}
          fulfillmentStatus={basket.fulfillmentStatus}
          isBasketPage={true}
          loading={loading}
          isSynced={isBasketSynced(basket.syncStatus)}
          editOrderOriginalPaymentMethod={editOrderOriginalPaymentMethod}
        />
      </ContentBlock>
    );
    const renderNewSlotButtonContent =
      loading && loading.slot ? (
        <ButtonLoadingWrapper>
          <Loading isLight={false} />
        </ButtonLoadingWrapper>
      ) : (
        'Book a new slot'
      );
    const isSlotSet =
      basket &&
      basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT;
    const renderChosenSlotInfo = isSlotSet && (
      <StatusWrapper>
        <BookSlotStatus
          fulfillAtSlot={basket.fulfillAtSlot}
          fulfillmentType={basket.fulfillmentType}
          isSlotExpired={isSlotExpired}
          branchName={
            branchList &&
            basket.branchId &&
            branchList[basket.branchId] &&
            branchList[basket.branchId].name
          }
        />
        {isSlotExpired && (
          <TheButton fullWidth onClick={this.bookNewSlot}>
            {renderNewSlotButtonContent}
          </TheButton>
        )}
        {!isSlotExpired && (
          <ChangeSlot onClick={this.bookSlot}>Change Slot</ChangeSlot>
        )}
      </StatusWrapper>
    );
    const renderNoSlotText = (
      <NoSlotText>
        <div>You have not yet booked a slot for delivery or collection.</div>
        <div>Prices and availability can only be confirmed when you do.</div>
      </NoSlotText>
    );
    const renderBookSlot = !orderId && basket && (
      <ContentBlock>
        <Slot>
          {(!basket.fulfillAtSlot || !isSlotSet) && (
            <TheButton $fullWidth={true} onClick={this.bookSlot}>
              Book your slot
            </TheButton>
          )}
          <InfoText $noMargin={isSlotSet}>
            <SlotText>
              {isSlotSet ? renderChosenSlotInfo : renderNoSlotText}
            </SlotText>
          </InfoText>
        </Slot>
      </ContentBlock>
    );
    const renderOrderInfo = orderId && (
      <OrderSlot>
        <TextTitle left>Order {orderId}</TextTitle>
        <OrderText>for DELIVERY on 23/7/18 between 10:00 - 14:00</OrderText>
        <ButtonGroup>
          <OrderButton>change slot</OrderButton>
          <OrderButton>cancel order</OrderButton>
        </ButtonGroup>
      </OrderSlot>
    );
    const renderEditItemsModal = this.state.showModal &&
      basket &&
      basket.item && (
        <EditItems
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          orderId={'Order #1232'}
          orderItemsLength={basket.items.length}
          basketItemsLength={basket.items.length}
        />
      );
    const renderEditOrderTime = basket &&
      basket.editMode &&
      editOrderTimeRemaining && (
        <EditContentBlock>
          EDITED ORDER {basket.axBasketId}. You must checkout in{' '}
          {editOrderTimeRemaining}, or your edits will be lost and the original
          order kept.
        </EditContentBlock>
      );
    const renderAdminAddButton = adminItemLoading ? (
      <ButtonLoadingWrapper>
        <Loading />
      </ButtonLoadingWrapper>
    ) : (
      'Add item'
    );
    const renderAdminPanel = isStaffAccount && (
      <BasketPageContent>
        <ContentBlock>
          <AdminTitle>Admin Area</AdminTitle>
          <ItemBox>
            <AdminItemBox
              placeholder="Type item code here"
              value={adminItemCode}
              onChange={this.handleItemCodeChange}
            />
            <TheButton $fullWidth={true} onClick={this.addItemByCode}>
              {renderAdminAddButton}
            </TheButton>
          </ItemBox>
        </ContentBlock>
      </BasketPageContent>
    );
    return (
      <Wrapper>
        <Head>
          <Title>{renderTitle}</Title>
          <ButtonWrapper>
            {renderEditButton}
            <ButtonSubWrapper $paddingTop={!!orderId}>
              {renderShoppingLink}
              {renderCheckoutButton}
            </ButtonSubWrapper>
          </ButtonWrapper>
        </Head>
        {renderAdminPanel}
        <BasketPageContent>
          {renderOrderInfo}
          {renderBookSlot}
          {renderEditOrderTime}
          {renderPriceSummary}
          {renderPaymentSelector}
          <BasketItemsWrapper>
            {renderBasketPanel}
            {renderNoItems}
          </BasketItemsWrapper>
        </BasketPageContent>
        <ExtraWrapper>
          <BasketButton to="/">Continue shopping</BasketButton>
          {renderCheckoutButton}
        </ExtraWrapper>
        {renderEditItemsModal}
        {renderOosModal}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  branch: state.branch,
  loginToken: state.loginToken,
  branchList: state.branchList,
  basket: state.basket,
  basketItems: state.basketItems,
  errors: state.errors,
  loading: state.loading,
  order: state.order,
  auth: state.auth,
  basketHashMap: state.basketHashMap,
  profile: state.profile,
  route: state.route,
  settings: state.settings,
  substitutes: state.substitutes,
  promoTotal: state.promoTotal,
  systemMessages: state.systemMessages,
  driverInstruction: state.driverInstruction,
  isStaffAccount: state.isStaffAccount,
});

const mapDispatchToProps = dispatch => ({
  getOrderByOrderId: bindActionCreators(getOrderByOrderId, dispatch),
  getProductsByIds: bindActionCreators(getProductsByIds, dispatch),
  syncBasketItemsWithMessages: bindActionCreators(
    syncBasketItemsWithMessages,
    dispatch
  ),
  setErrorManually: bindActionCreators(setErrorManually, dispatch),
  getBasket: bindActionCreators(getBasket, dispatch),
  removeBasket: bindActionCreators(removeBasket, dispatch),
  resetBasketItems: bindActionCreators(resetBasketItems, dispatch),
  checkBasketSynced: bindActionCreators(checkBasketSynced, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  removeSetting: bindActionCreators(removeSetting, dispatch),
  setSubstituteItem: bindActionCreators(setSubstituteItem, dispatch),
  removeFulfillSlot: bindActionCreators(removeFulfillSlot, dispatch),
  applyVoucher: bindActionCreators(applyVoucher, dispatch),
  clearVoucherErrors: bindActionCreators(clearVoucherErrors, dispatch),
  removeVoucher: bindActionCreators(removeVoucher, dispatch),
  setFulfillSlot: bindActionCreators(setFulfillSlot, dispatch),
  toggleMaintenanceModal: bindActionCreators(toggleMaintenanceModal, dispatch),
  setPaymentType: bindActionCreators(setPaymentType, dispatch),
  toggleCustomerSupportModal: bindActionCreators(
    toggleCustomerSupportModal,
    dispatch
  ),
  getPbbBankDetails: bindActionCreators(getPbbBankDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasketPage);
