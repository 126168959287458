import React, {PureComponent} from 'react';
import {List} from './ShoppingListsPage.style';

export default class ListBar extends PureComponent {
  callback = () => {
    const {list, navToList} = this.props;
    navToList(list.id, list.name);
  };
  render() {
    const {list} = this.props;
    return (
      <List key={list.id} onClick={this.callback}>
        {list.name} ({list.count})
      </List>
    );
  }
}
