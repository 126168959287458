import initialState from './initial.state';
import * as types from '../actions/types.action';
import {MARKETING_OPTIONS_STATUS} from '../constants/constants';

export default function marketingReducer(
  state = initialState.marketingOptions,
  action
) {
  switch (action.type) {
    case types.GET_MARKETING_OPTIONS_SUCCESS:
      return Object.assign({}, state, action.marketingOptions);
    case types.UPDATE_MARKETING_OPTIONS_REQUESTED: {
      const permissions =
        state.marketing_permissions &&
        state.marketing_permissions.map(permission => {
          if (permission.marketing_permission_id === action.id) {
            permission.enabled = action.state;
          }
          return permission;
        });

      const statusText =
        permissions &&
        permissions[0] &&
        (permissions[0].enabled
          ? state.status === MARKETING_OPTIONS_STATUS.UNSUBSCRIBED
            ? MARKETING_OPTIONS_STATUS.PENDING
            : MARKETING_OPTIONS_STATUS.SUBSCRIBED
          : MARKETING_OPTIONS_STATUS.UNSUBSCRIBED);
      const status = {
        status: statusText,
        ...(statusText === MARKETING_OPTIONS_STATUS.PENDING && {
          status_if_new: MARKETING_OPTIONS_STATUS.SUBSCRIBED,
        }),
      };

      return Object.assign({}, state, {
        marketing_permissions: permissions,
        ...status,
        email_address: action.email,
        patchUrl: statusText,
      });
    }
    case types.UPDATE_ALL_MARKETING_OPTIONS_REQUESTED: {
      const permissions =
        state.marketing_permissions &&
        state.marketing_permissions.map(permission => {
          permission.enabled = action.state;
          return permission;
        });

      const statusText =
        permissions &&
        permissions[0] &&
        (permissions[0].enabled
          ? state.status === MARKETING_OPTIONS_STATUS.UNSUBSCRIBED
            ? MARKETING_OPTIONS_STATUS.PENDING
            : MARKETING_OPTIONS_STATUS.SUBSCRIBED
          : MARKETING_OPTIONS_STATUS.UNSUBSCRIBED);
      const status = {
        status: statusText,
        ...(statusText === MARKETING_OPTIONS_STATUS.PENDING && {
          status_if_new: MARKETING_OPTIONS_STATUS.SUBSCRIBED,
        }),
      };

      return Object.assign({}, state, {
        marketing_permissions: permissions,
        ...status,
        email_address: action.email,
        patchUrl: statusText,
      });
    }
    default:
      return state;
  }
}
