import {put, call, takeLatest} from 'redux-saga/effects';
import branchApi from '../api/branch.api';
import * as ActionTypes from '../actions/types.action';
import {arrayToObject} from '../helpers/array.helper';

function* getBranchList() {
  try {
    const branchListArray = yield call(branchApi.getAllBranch);
    const branchList = arrayToObject(branchListArray, 'code');
    yield put({type: ActionTypes.GET_BRANCH_LIST_SUCCESS, branchList});
  } catch (e) {
    yield put({type: ActionTypes.GET_BRANCH_LIST_FAILED, message: e.message});
  }
}

export default function* getBranchListYield() {
  yield takeLatest(ActionTypes.GET_BRANCH_LIST_REQUESTED, getBranchList);
}
