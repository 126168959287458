export const WIX_NODE = {
  PARAGRAPH: 'PARAGRAPH',
  HEADING: 'HEADING',
  IMAGE: 'IMAGE',
  GALLERY: 'GALLERY',
  BULLETED_LIST: 'BULLETED_LIST',
  TEXT: 'TEXT',
  BUTTON: 'BUTTON',
  ORDERED_LIST: 'ORDERED_LIST',
  LIST_ITEM: 'LIST_ITEM',
  BLOCKQUOTE: 'BLOCKQUOTE',
  TABLE_CELL: 'TABLE_CELL',
  TABLE_ROW: 'TABLE_ROW',
  VIDEO: 'VIDEO',
  HTML: 'HTML',
  TABLE: 'TABLE',
  LINK: 'LINK',
  DIVIDER: 'DIVIDER',
};

export const POSTS_NAME = {
  BLOG: 'wixPosts',
  JJ_CONTENT: 'jjContent',
};

export const WIX_TABLE = {
  TABLE_ROW: 'TABLE_ROW',
  TABLE_CELL: 'TABLE_CELL',
};

export const DECORATIONS = {
  LINK: 'LINK',
  UNDERLINE: 'UNDERLINE',
  BOLD: 'BOLD',
  COLOR: 'COLOR',
  FONT_SIZE: 'FONT_SIZE',
  ITALIC: 'ITALIC',
};

export const WIX_MEDIA_URL = 'https://static.wixstatic.com/media/';

export const LAYOUT = {
  GRID: 'grid',
};

export const WIX_ORIENTATION = {
  COLUMNS: 'COLUMNS',
  ROWS: 'ROWS',
};

export const HEADING_LEVEL = {
  '1': '3rem',
  '2': '2.6rem',
  '3': '2.2rem',
  '4': '2rem',
  '5': '1.8rem',
  '6': '1.6rem',
};

export const HEADING_LEVEL_HEIGHT = {
  '1': '7rem',
  '2': '5rem',
  '3': '4rem',
  '4': '3rem',
};

export const YOUTUBE_URL = {
  SHORT: 'https://youtu.be/',
  FULL: 'https://www.youtube.com/',
  EMBED: 'https://www.youtube.com/embed/',
  EMBED_STRING: '/embed/',
};

export const WIX_IDS = {
  BLOG_ACC: '85a259f0-a604-438c-93d5-ce235fd8ac4c',
  BLOG_ID: 'f00ec57e-bcaa-41eb-9ad6-fc0cabcbfd49',
  JJ_CONTENT_ID: '05e68293-4b87-45ed-a1be-97ecde6b0a88',
};

export const WIX_URL = {
  GET_POSTS: 'https://www.wixapis.com/blog/v3/posts',
  GET_CATEGORIES: 'https://www.wixapis.com/blog/v3/categories',
};

export const ALIGNMENT = {
  CENTER: 'CENTER',
  AUTO: 'AUTO',
};

export const ALIGNMENT_FLEX = {
  CENTER: 'center',
  RIGHT: 'flex-end',
  LEFT: 'flex-start',
};

export const WIX_REX = {
  ZERO_WIDTH_SPACE: /^\u200B$/,
};

export const COLOR = {
  WHITE: '#FFFFFF',
  textColor0: '#000000',
  textColor1: '#000000',
  textColor2: '#607980',
  textColor3: '#607980',
  textColor4: '#757575',
  textColor5: '#b5b5b5',
  textColor6: '#90c4cf',
  textColor7: '#267df7',
  textColor8: '#ffffff',
  textColor9: '#3594cc',
  textColor10: '#2b671d',
  textColor11: '#efd033',
  textColor12: '#27ad5d',
};

export const DIVIDER = {
  SINGLE: 'SINGLE',
  LARGE: '100%',
  MEDIUM: '80%',
  SMALL: '50%',
};

export const WIX_SIZE = {
  LARGE: '100%',
  MEDIUM: '800px',
  SMALL: '400px',
};

export const BACK_UP_DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(202[4-9]|20[3-9]\d)$/;
