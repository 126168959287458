import React, {PureComponent} from 'react';
import {DeliveryContent, TheLink, Text} from './BranchLocatorPage.style';

export default class DeliveryHours extends PureComponent {
  render() {
    const {times, minOrder, isLoggedIn} = this.props;
    const renderLoginText = !isLoggedIn && ' log in and ';
    return (
      <DeliveryContent>
        <Text>
          Our trucks are out for deliveries every week day, between {times.from}{' '}
          - {times.to}
        </Text>
        <Text>
          For the specific delivery slots assigned to your address please
          {renderLoginText} view the{' '}
          <TheLink to="/bookSlot">slot booking page</TheLink>
        </Text>
        <Text>Minimum order amount: £{minOrder}</Text>
        <Text>
          Area coverage and the minimum delivery amount may vary depending on
          your location.
        </Text>
      </DeliveryContent>
    );
  }
}
