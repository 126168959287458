import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ExtraLink, ExtraLinkWrapper, NavLink} from './Menu.style';

export default class MenuLink extends PureComponent {
  static propTypes = {
    light: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.object,
    toggleMenu: PropTypes.func,
  };

  render() {
    const {light, text, url, search, state, toggleMenu} = this.props;
    if (state) {
      return (
        <NavLink
          to={{
            pathname: url,
            search,
            state,
          }}
          onClick={toggleMenu}
        >
          {text}
        </NavLink>
      );
    }
    return (
      <ExtraLinkWrapper>
        <ExtraLink $light={light} href={url}>
          {text}
        </ExtraLink>
      </ExtraLinkWrapper>
    );
  }
}
