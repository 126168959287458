import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link} from 'react-router-dom';

import {Link1} from '../../styles/components/links';
import {WayDownWhite, WayUp} from '../../styles/components/arrow';

export const Wrapper = styled.header`
  width: 100%;
  background-color: ${props => props.theme.primaryBgColor};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100rem;
  margin: 0 auto;
  ${media.medium`
    width: 100%;
    max-height: 100%;
  `};
`;

export const Left = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${media.medium`
    min-height: 3.8rem;
  `};
`;
export const Right = styled(Left)`
  justify-content: flex-end;
  ${media.smaller`
    display: flex;
    min-height: 3.8rem;
  `};
`;

export const Menu = styled.div`
  display: none;
  ${media.smaller`
    width: 4.4rem;
    height: 3.8rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-right: ${props => `solid 0.1rem ${props.theme.tertiaryTextColor}`}; 
    &::before {
      display: inline-block;
      font-family: 'icomoon';
      content: '\\e910';
      font-size: 3rem;
      font-weight: normal;
      color: ${props => props.theme.primaryTextColor};
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `};
`;

export const Description = styled.span`
  color: ${props => props.theme.secondaryTextColor};
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  ${media.medium`
    margin-left: 2rem;
  `};
  ${media.smaller`
    margin-left: 1rem;
  `};
`;

export const LoginText = styled.span`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.primaryTextColor};
  text-transform: uppercase;
  font-weight: bold;
  ${media.smaller`
    display: none;
  `};
`;

export const InfoText = styled.span`
  color: ${props => props.theme.primaryColor};
  padding: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  ${media.smaller`
    display: none;
  `};
`;

export const OldSite = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: right;
  color: ${props => props.theme.primaryTextColor};
  font-size: 1.2rem;
  ${media.smaller`
    display: none;
  `};
`;

const truncate = () => {
  return `
    display: flex;
    font-size: 1.2rem;
    padding: 0.4rem 1.2rem;
    text-transform: uppercase;
    height: 3.8rem;
    font-weight: bold;
  `;
};

export const BlueButton = styled.a`
  ${truncate()};
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p =>
    p.$disable ? p.theme.disabledColor : p.theme.primaryColor};
  cursor: pointer;
  align-items: center;
  margin-right: 0.1rem;

  &:hover {
    color: ${p => p.theme.secondaryTextColor};
    background-color: ${p =>
      p.$disable ? p.theme.tertiaryTextColor : p.theme.primaryColorHover};
  }

  ${media.smaller`
    display: none;
  `};
`;

const MainButton = styled(Link1)`
  ${truncate()};
  color: ${props => props.theme.primaryTextColor};
  background-color: ${props => props.theme.primaryColor};

  &:hover {
    color: ${props => props.theme.secondaryTextColor};
    background-color: ${props => props.theme.primaryColorHover};
  }

  ${media.smaller`
    display: none;
  `};
`;

export const Cart = styled(MainButton)`
  background-color: ${props => props.theme.secondaryColor};
  display: flex;
  justify-content: space-between;
  min-width: 10rem;
  padding: 1rem 1rem 1rem 0.8rem;

  &:hover {
    background-color: ${props => props.theme.secondaryColorHover};
  }

  &::before {
    display: ${p => (p.theme.loading ? 'none' : 'inline-block')};
    font-family: 'icomoon';
    content: '\\e908';
    font-size: 2rem;
    font-weight: normal;
    color: ${props => props.theme.primaryTextColor};
  }

  ${media.smaller`
    min-width: 5rem;
    display: flex;
  `};
`;

export const Quantity = styled.div`
  font-size: 1rem;
  min-width: 2rem;
  color: ${props => props.theme.primaryTextColor};
  padding-right: 0.8rem;
  display: ${p => (p.$isLoading ? 'none' : 'block')};
  ${media.smaller`
    padding: 0.4rem 0 0.25rem 0.4rem;
    font-size: 1.2rem
  `};
`;

export const CartText = styled.span`
  ${media.smaller`
    display: none;
  `};
`;

export const LoadingWrapper = styled.div`
  width: 2rem;

  svg {
    width: 1.8rem;
  }
`;

export const ArrowDown = styled(WayDownWhite)`
  margin-top: 0.4rem;
  width: 2rem;
  height: 3rem;
  margin-left: 1rem;
`;

export const ArrowUp = styled(WayUp)`
  margin-top: 0.4rem;
  margin-left: 1rem;
  height: 3rem;

  &::before {
    color: ${props => props.theme.primaryTextColor};
  }
`;

export const DropDownWrapper = styled.div`
  position: relative;
`;

export const DropDown = styled.div`
  position: absolute;
  background-color: ${p => p.theme.primaryBgColor};
  min-width: 16rem;
  padding: 1rem;
  top: 3.8rem;
  left: 0;
  z-index: 998;
  ${media.smaller`
    display: none;
  `};
`;

const OptionStyle = () => `
  line-height: 3rem;
  padding: 0 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  `;

export const Option = styled.div`
  ${OptionStyle()};
  color: ${p => (p.$active ? p.theme.primaryColor : p.theme.primaryTextColor)};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const OptionNoLink = styled(Option)`
  cursor: default;
`;

export const OptionLink = styled.a`
  ${OptionStyle()};
  display: block;
  color: ${p => (p.$active ? p.theme.primaryColor : p.theme.primaryTextColor)};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const OptionRouterLink = styled(Link)`
  ${OptionStyle()};
  display: block;
  color: ${p => (p.$active ? p.theme.primaryColor : p.theme.primaryTextColor)};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;
