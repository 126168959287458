import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function searchSuggestionsReducer(
  state = initialState.searchSuggestions,
  action
) {
  switch (action.type) {
    case types.GET_SEARCH_SUGGESTIONS_SUCCESS:
      return action.searchSuggestions;
    case types.GET_SEARCH_SUGGESTIONS_RESET:
    case types.GET_SEARCH_SUGGESTIONS_FAILED:
    case types.REMOVE_LOGIN_TOKEN_REQUESTED:
      return [];
    default:
      return state;
  }
}
