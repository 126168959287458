import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import Categories from './Categories';
import {
  Wrapper,
  ChangeStore,
  ExtraLink,
  ExtraLinks,
  RouterLink,
  MenuList,
  Selected,
  Store,
  CategoryBar,
  RootLevel,
  LoggedInLinks,
  BookASlot,
  NavBreadCrumb,
  NavBreadCrumbLink,
  NavBreadCrumbSubLink,
} from './Menu.style';
import ControlBar from './ControlBar';
import MenuLink from './MenuLink';
import {generateNewProductsLink} from '../../helpers/data.helper';

export default class Menu extends PureComponent {
  static propTypes = {
    showMenuList: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    toggleLoginModal: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isMultiAccounts: PropTypes.bool,
    isStaffAccount: PropTypes.bool,
    profile: PropTypes.object,
    isMaintenanceMode: PropTypes.bool,
    categoryAd: PropTypes.array,
    showShoppingList: PropTypes.bool,
  };
  originalTitle = {
    0: 'Category',
    1: null,
    2: null,
  };
  state = {
    level: -1,
    title: this.originalTitle,
    additionalLinks: [],
  };

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
    this.setMenu();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {branch} = this.props;
    if (prevProps.branch !== branch) {
      this.setMenu();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  setMenu() {
    const newProductUrl = generateNewProductsLink(
      this.props.branch,
      null,
      null,
      true
    );
    this.setState({
      additionalLinks: [
        {
          name: 'NEW PRODUCTS',
          url: newProductUrl.pathname,
          search: newProductUrl.search,
          state: {newSearch: true},
        },
        {
          name: 'Recent Purchases',
          url: '/recentpurchases',
          requireLogin: true,
          state: {newSearch: true},
        },
        {
          name: 'Favourites',
          url: '/favourites',
          requireLogin: true,
          state: {newSearch: true},
        },
        {
          name: 'Halal Range',
          url: '/search',
          search: '?q=halal&adTitle=Our%20Halal%20Range',
          state: {newSearch: true},
        },
        {
          name: 'Own brands',
          url: '/content/own-brands',
        },
        {
          name: 'Home essentials',
          url: '/search',
          search:
            '?page=0&productFeatures=JJ%20Home&q=Home%20Essentials%20&$size=12&sortType=offer',
          state: {newSearch: true},
        },
      ],
    });
  }

  handleClickOutside = e => {
    e.stopPropagation();
    const {showMenuList, browseShopRef, toggleMenu} = this.props;
    if (
      showMenuList &&
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target) &&
      browseShopRef &&
      !browseShopRef.contains(e.target)
    ) {
      toggleMenu();
    }
  };

  setRef = node => (this.wrapperRef = node);

  nextLevel = () => {
    if (this.state.level > 1) {
      return;
    }
    this.setState({level: this.state.level + 1});
  };

  goBack = () => {
    this.setState({
      level: -1,
      title: this.originalTitle,
    });
  };
  goToLevel0 = () => {
    this.setState({
      level: 0,
      title: this.originalTitle,
    });
  };
  goToLevel1 = () => {
    this.setState({level: 1});
    this.setTitle(2, null);
  };
  setTitle = (level, value) => {
    const title = Object.assign({}, this.state.title, {[level]: value});
    this.setState({title});
  };

  logout = () => {
    const {logout, toggleMenu} = this.props;
    logout();
    toggleMenu();
  };

  openSwitchAccountModal = () => this.props.toggleSwitchAccountModal(true);

  render() {
    const {
      branchList,
      branch,
      navigateTo,
      navigateToCategory,
      openChangeBranchModal,
      showMenuList,
      toggleMenu,
      categoryList,
      toggleLoginModal,
      isLoggedIn,
      profile,
      isMultiAccounts,
      isStaffAccount,
      isMaintenanceMode,
      categoryAd,
      navigateToRegister,
      showShoppingList,
    } = this.props;
    const renderExtraLinks = this.state.additionalLinks.map(link => {
      if (link.requireLogin && !isLoggedIn) {
        return;
      }
      return (
        <MenuLink
          light={true}
          key={link.name}
          url={link.url}
          text={link.name}
          search={link.search}
          state={link.state}
          toggleMenu={toggleMenu}
        />
      );
    });
    const isRootLevel = this.state.level === -1;
    const renderLastLevelBreadcrumbs = this.state.title && this.state.title[2] && (
      <Fragment>
        ><NavBreadCrumbSubLink>{this.state.title[2]}</NavBreadCrumbSubLink>
      </Fragment>
    );
    const renderBreadcrumbs = this.state.title && this.state.title[1] && (
      <NavBreadCrumb>
        <NavBreadCrumbLink onClick={this.goToLevel0}>ALL</NavBreadCrumbLink>>
        <NavBreadCrumbSubLink
          onClick={this.goToLevel1}
          $isActive={this.state.title[2]}
        >
          {this.state.title[1]}
        </NavBreadCrumbSubLink>
        {renderLastLevelBreadcrumbs}
      </NavBreadCrumb>
    );
    const renderCategories = categoryList && (
      <Categories
        activeLevel={this.state.level}
        categories={categoryList}
        nextLevel={this.nextLevel}
        setTitle={this.setTitle}
        navigate={navigateToCategory}
        categoryAd={categoryAd}
      />
    );
    const renderSwitchAccount = isLoggedIn &&
      (isMultiAccounts || isStaffAccount) && (
        <ExtraLink onClick={this.openSwitchAccountModal}>
          Switch account
        </ExtraLink>
      );
    const renderShoppingListLinkInMenu = showShoppingList && (
      <RouterLink to="/shoppingLists" onClick={toggleMenu}>
        My shopping lists
      </RouterLink>
    );
    const renderLoggedInLinks = isLoggedIn && (
      <LoggedInLinks>
        <RouterLink to="/orders" onClick={toggleMenu}>
          My orders
        </RouterLink>
        {renderShoppingListLinkInMenu}
        <RouterLink to="/account" onClick={toggleMenu}>
          My settings
        </RouterLink>
        <RouterLink to="/reports" onClick={toggleMenu}>
          View reports
        </RouterLink>
        {renderSwitchAccount}
        <ExtraLink onClick={this.logout}>LOGOUT</ExtraLink>
      </LoggedInLinks>
    );
    const renderBranch = branchList && branchList[branch] && (
      <Store>
        <BookASlot to="/bookSlot" onClick={toggleMenu}>
          Book a slot
        </BookASlot>
        <Selected>{branchList[branch].name}</Selected>
        <ChangeStore onClick={openChangeBranchModal}>(Change)</ChangeStore>
      </Store>
    );
    const renderShoppingListLink = showShoppingList && (
      <MenuLink light={true} url={'/shoppingLists'} text={'Shopping lists'} />
    );
    return (
      <MenuList
        $extraMargin={isMaintenanceMode}
        $show={showMenuList}
        $root={isRootLevel}
      >
        <Wrapper ref={this.setRef}>
          <ControlBar
            toggleMenu={toggleMenu}
            toggleLoginModal={toggleLoginModal}
            isRoot={isRootLevel}
            goBack={this.goBack}
            title={this.state.title}
            level={this.state.level}
            isLoggedIn={isLoggedIn}
            profile={profile}
            isMaintenanceMode={isMaintenanceMode}
            navigateToRegister={navigateToRegister}
          />
          <RootLevel $show={isRootLevel}>
            <CategoryBar onClick={this.nextLevel}>Categories</CategoryBar>
            <ExtraLinks>
              {renderBranch}
              <MenuLink light={true} url={'/offers'} text={'offers'} />
              {renderShoppingListLink}
              {renderExtraLinks}
            </ExtraLinks>
            {renderLoggedInLinks}
          </RootLevel>
          {renderBreadcrumbs}
          {renderCategories}
        </Wrapper>
      </MenuList>
    );
  }
}
