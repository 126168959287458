import styled from 'styled-components';
import {Button3Inverted} from '../../styles/components/buttons';
import {media} from '../../styles/components/media-queries';
import {Content} from '../../styles/pages/pages';
export const SectionWrapper = styled(Content)`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  ${media.medium`
    padding: 1rem 2rem;
  `};
`;

export const SectionHeader = styled.div`
  display: flex;
  padding: 2rem;
  font-size: 1.6rem;
  justify-content: space-between;
  color: ${p => p.theme.textColor};
`;

export const SectionBody = styled.div`
  display: flex;
  padding: 2rem;
  font-size: 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.textColor};
  min-height: 25rem;
  line-height: 2.4rem;
`;

export const Info = styled.div`
  padding: 2rem;
  font-size: 1.6rem;
  width: 100%;
  color: ${p => p.theme.textColor};
  line-height: 2.4rem;
  text-align: center;
`;

export const OrderId = styled.span`
  color: ${p => p.theme.secondaryColor};
`;

export const OrderStatus = styled.span`
  text-transform: uppercase;
  color: ${p => p.theme.quaternaryColor};
`;

export const OrderTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: ${p => p.theme.quaternaryColor};
  font-size: 2rem;
  margin: 2rem;
  line-height: 2.4rem;
  ${media.small`
    font-size: 1.8rem;
    margin: 0 2rem;
  `};
`;

export const DeliveryInfo = styled.div`
  color: ${p => p.theme.textColor};
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem;
`;

export const ErrorInfo = styled(DeliveryInfo)`
  font-size: 1.6rem;
`;

export const BoldText = styled.span`
  font-weight: bold;
  color: ${p => p.theme.secondaryColor};
`;

export const SlotInfo = styled(OrderTitle)`
  color: ${p => p.theme.secondaryColor};
  text-transform: uppercase;
  font-size: 3.4rem;
  margin: 3rem 0 2rem;
  ${media.small`
    font-size: 2.8rem;
    margin: 2rem;
  `};
`;

export const TheButton = styled(Button3Inverted)`
  width: 18rem;
  ${media.small`
    margin-top: 2rem;
    width: 100%;
  `};
`;
export const ContactLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: ${p => p.theme.primaryColor};
  padding-left: 0.5rem;
`;
