import * as ActionTypes from './types.action';

export const resetError = () => ({
  type: ActionTypes.ERROR_CLEAN_UP,
});

export const setErrorManually = (error, data) => ({
  type: ActionTypes[error],
  data,
});
