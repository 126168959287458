import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getMoreOffers, getOffers} from '../../actions/offers.action';
import Product from '../../components/Product/Product';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import Loading from '../../components/Loading/Loading';
import {Wrapper, Head, Title} from '../../styles/pages/pages';
import {
  Container,
  FilterTitle,
  FilterButtonWrapper,
  NoOffers,
} from './OffersPage.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import {
  ANALYTICS_SOURCES,
  ROUTES,
  SCROLL_THRESHOLD,
} from '../../constants/constants';
import {
  ClearFilter,
  ClearFilterButton,
  DoneButton,
  FilterButton,
  FilterInfo,
  FiltersButtons,
  FiltersWrapper,
  Status,
} from '../SearchPage/SearchPage.style';
import Filters from '../../components/Filters/Filters';
import {setCurrentRoute} from '../../actions';
import ToTheTop from '../../components/ToTheTop/ToTheTop';
import {setPrevRoute} from '../../actions/prevRoute.action';

class OffersPage extends PureComponent {
  state = {
    showFilterInMobile: false,
    isCurrentSearch: false,
    params: null,
  };

  async componentDidMount() {
    const {history, prevRoute} = this.props;
    const isNewSearch =
      history.location &&
      history.location.state &&
      history.location.state.newSearch;
    setCurrentRoute(ROUTES.OFFERS);
    if (prevRoute === ROUTES.PRODUCT && !isNewSearch) {
      return;
    }
    this.getOffers();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.search !== prevState.search) {
      const {location} = nextProps;
      const params = queryString.parse(location.search);
      const prevParams = queryString.parse(prevState.search);
      return {
        params,
        search: location.search,
        isCurrentSearch: params.q === prevParams.q,
        showFilterInMobile: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const {getOffers, branch, basket, settings, setCurrentRoute} = this.props;
    const {params} = this.state;
    setCurrentRoute(ROUTES.OFFERS);
    // sync the offers to basket fulfillment type and slot date
    if (
      (prevProps.basket &&
        prevProps.basket.fulfillment &&
        basket &&
        basket.fulfillment &&
        prevProps.basket.fulfillmentType !== basket.fulfillmentType) ||
      prevProps.settings !== settings ||
      (prevProps.branch && prevProps.branch !== branch)
    ) {
      getOffers(branch, params);
    }
  }

  async getOffers() {
    const {getOffers, branch} = this.props;
    const {params} = this.state;
    getOffers(branch, params);
  }

  loadMore = () => {
    const {getMoreOffers, branch, offers} = this.props;
    const {params} = this.state;
    getMoreOffers(branch, offers.page.number + 1, params);
  };

  updateSearchParams = (updatedParams, resetPageNumber) => {
    const {history, location} = this.props;

    const urlParams = queryString.parse(location.search);
    let params = Object.assign({}, urlParams, updatedParams);
    if (resetPageNumber) {
      params = Object.assign({}, params, {page: 0});
    }
    history.push({
      pathname: '/offers',
      search: `?${queryString.stringify(params)}`,
    });
    this.setState({params}, () => this.getOffers());
  };

  clearFilters = () => {
    const {q, b, size, advanced} = this.state.params;
    let params;
    if (q === '*' && advanced === 'true') {
      params = {q, b, advanced};
    } else {
      params = {q, b, size};
    }
    this.props.history.push({
      pathname: '/offers',
      search: `?${queryString.stringify(params)}`,
    });
    this.setState({params}, () => {
      this.getOffers();
      window.scrollTo(0, 150);
    });
  };

  toggleFilterInMobile = () =>
    this.setState({showFilterInMobile: !this.state.showFilterInMobile});

  closeFilterInMobile = () => {
    this.setState({showFilterInMobile: false});
    window.scrollTo(0, 300); // todo: replace this with element height
  };

  render() {
    const {
      offers,
      loading,
      branch,
      updateBasket,
      updateItem,
      removeItem,
      basketHashMap,
      basket,
      deviceInfo,
      page,
      aggression,
      onProductClick,
      keywordSearch,
      setPrevRoute,
    } = this.props;
    const navToProduct = itemId => {
      setPrevRoute(ROUTES.OFFERS);
      onProductClick(itemId);
    };
    const {showFilterInMobile, params, isCurrentSearch} = this.state;
    const appliedFilters = {
      sizeOrCut: !!params.sizeOrCut,
      origin: !!params.origin,
      brand: !!params.brand,
      productFeatures: !!params.productFeatures,
      categoryList: !!params.category,
    };
    const offersList =
      offers && syncBasketQuantityWithProducts(basketHashMap, offers.products);
    const renderOffer =
      offersList && offersList.length > 0 ? (
        offersList.map((product, index) => (
          <Product
            product={product}
            branch={branch}
            key={product.itemId + index}
            onProductClick={navToProduct}
            loading={loading}
            basketHashMap={basketHashMap}
            updateBasket={updateBasket}
            updateItem={updateItem}
            removeItem={removeItem}
            fulfillmentType={basket && basket.fulfillmentType}
            deviceInfo={deviceInfo}
            source={ANALYTICS_SOURCES.OFFERS}
            keywordSearch={keywordSearch}
          />
        ))
      ) : offersList && offersList.length === 0 ? (
        <NoOffers>Sorry, no offers available</NoOffers>
      ) : (
        <Loading isLight={false} />
      );
    const hasMore =
      offers && offers.page && offers.page.number < offers.page.totalPages - 1;
    const renderAllOffers = offersList && (
      <InfiniteScroll
        next={this.loadMore}
        hasMore={hasMore}
        dataLength={offersList.length}
        scrollThreshold={SCROLL_THRESHOLD}
        loader={<Loading isLight={false} />}
      >
        {renderOffer}
      </InfiniteScroll>
    );

    const renderFilterButtonName =
      page && page.totalElements ? `${page.totalElements} products ` : '';
    const renderFilterButton = offersList && offersList.length > 0 && (
      <FilterButtonWrapper>
        <FilterButton onClick={this.toggleFilterInMobile}>
          <span>Filter {renderFilterButtonName}By</span>
          <Status $selected={showFilterInMobile} />
        </FilterButton>
      </FilterButtonWrapper>
    );
    const renderFilters = aggression && (
      <Filters
        params={params}
        updateSearchParams={this.updateSearchParams}
        filters={aggression}
        appliedFilters={appliedFilters}
        showInMobile={showFilterInMobile}
        isCategorySearch={false}
        isCurrentSearch={isCurrentSearch}
        closedByDefault={true}
      />
    );

    const renderFilterTitle = page && !!page.totalElements && (
      <FilterInfo>
        <FilterTitle>{`Filter ${page.totalElements} products by:`}</FilterTitle>
        <ClearFilter onClick={this.clearFilters}>clear filters</ClearFilter>
      </FilterInfo>
    );

    const renderFiltersWrapper = offersList && offersList.length > 0 && (
      <FiltersWrapper $showInMobile={showFilterInMobile}>
        {renderFilterTitle}
        {renderFilters}
        <FiltersButtons>
          <DoneButton onClick={this.closeFilterInMobile}>Done</DoneButton>
          <ClearFilterButton onClick={this.clearFilters}>
            Clear All
          </ClearFilterButton>
        </FiltersButtons>
      </FiltersWrapper>
    );
    const renderGoTop = page && page.number > 0 && <ToTheTop />;
    const renderContent = offersList && (
      <Container>
        {renderFilterButton}
        {renderFiltersWrapper}
        {renderAllOffers}
        {renderGoTop}
      </Container>
    );

    return (
      <Wrapper>
        <Head>
          <Title>Our Special Offers</Title>
        </Head>
        {renderContent}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    branch: state.branch,
    basket: state.basket,
    loading: state.loading,
    offers: state.offers,
    basketHashMap: state.basketHashMap,
    aggression: state.aggression,
    page: state.page,
    settings: state.settings,
    prevRoute: state.prevRoute,
  };
};

const mapDispatchToProps = dispatch => ({
  getOffers: bindActionCreators(getOffers, dispatch),
  getMoreOffers: bindActionCreators(getMoreOffers, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersPage);
