import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link2} from '../../styles/components/links';

export const Error = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  width: 100%;
  margin: 4rem 0 2rem 0;
`;

export const SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-size: 2rem;
  font-weight: bold;
  padding-top: 4rem;
  padding-bottom: 2rem;
  ${media.small`
    padding-left: 3rem;
    padding-right: 3rem;
  `};
`;

export const SectionText = styled.div`
  color: ${p => p.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 2rem;
  ${media.small`
    padding-left: 1rem;
  `};
`;

export const SectionMainText = styled(SectionText)`
  color: ${p => p.theme.primaryColor};
  font-size: 2rem;
  line-height: 3rem;
`;

export const Left = styled.div`
  width: 50%;
  padding-right: 2rem;
  ${media.small`
    width: 100%;
    padding-left: 2rem;
  `};
`;

export const Right = styled.div`
  width: 50%;
  padding-left: 2rem;
  ${media.small`
    width: 100%;
    padding-right: 2rem;
  `};
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 1rem;
  padding-bottom: 2rem;
`;

export const ShopButton = styled(Link2)`
  width: 20rem;
  margin-bottom: 2rem;
  ${media.small`
    margin-left: auto;
    margin-right: auto;
  `};
  ${media.smaller`
    padding: 0 1rem;
    max-width: 60%;
  `};
`;

export const ContentTitle = styled.span`
  color: ${p => p.theme.primaryColor};
  padding-right: 1rem;

  &::before {
    font-family: 'icomoon';
    display: inline-block;
    content: '\\e910';
    font-size: 2rem;
    vertical-align: text-top;
    transform: rotate(270deg);
    color: ${props => props.theme.primaryColor};
  }
`;
