import styled from 'styled-components';

export const TopIcon = styled.span`
  width: 4rem;
  height: 4rem;
  position: fixed;
  right: 2.4rem;
  bottom: 6.4rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  z-index: 99;
  opacity: 0.9;
  background-color: ${p => p.theme.primaryColor};
  :hover {
    opacity: 1;
  }
  &::before {
    font-family: 'icomoon';
    display: inline-block;
    content: '\\e910';
    font-size: 4rem;
    vertical-align: text-top;
    transform: rotate(180deg);
    color: ${props => props.theme.primaryTextColor};
  }
`;
