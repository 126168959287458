import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  ErrorButton,
  ButtonsWrapper,
  ExtraContent,
  LinkBreak,
} from './PopupModal.style';
import {
  COMMERCIAL_CARD,
  PAYMENT_FAILED_BY_CODE,
} from '../../constants/constants';
import {
  extraErrorMessageHandler,
  paymentErrorHandler,
} from '../../helpers/basket.helper';

export default class PaymentErrorModal extends PureComponent {
  render() {
    const {
      content,
      errors,
      showModal,
      callback,
      closeModalCallback,
      isBraintree,
    } = this.props;
    const renderFailedHeadingText =
      content &&
      content.failedCode &&
      !!PAYMENT_FAILED_BY_CODE[content.failedCode]
        ? PAYMENT_FAILED_BY_CODE[content.failedCode]
        : 'There was a problem making your payment.';
    const renderFailedHeading = !isBraintree && (
      <div>{renderFailedHeadingText}</div>
    );
    const errorMessage = paymentErrorHandler(isBraintree, errors, content);
    const incorrectPaymentError = extraErrorMessageHandler(errors);
    const renderBusinessCardMsg =
      errors &&
      errors[0] &&
      errors[0].code === COMMERCIAL_CARD &&
      `If you are paying with a Business Card please select the Business Card option`;

    const renderErrorMessage = !!errorMessage && (
      <SubContent>{errorMessage}</SubContent>
    );
    const renderSubContent = !incorrectPaymentError ? (
      <SubContent>
        No funds have been taken from your account, please try again or
        <LinkBreak />
        choose a different payment method.
      </SubContent>
    ) : (
      <ExtraContent>
        {incorrectPaymentError} <LinkBreak />
        You have not been charged. <LinkBreak />
        Please use a different card or select another payment method.
        <LinkBreak />
        {renderBusinessCardMsg}
      </ExtraContent>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw="paymentErrorModal"
      >
        <StyledWrapper $isError={true} id="payment-error">
          <Content>
            <TextWrapper>
              <Title $isError={true}>SORRY, YOUR PAYMENT FAILED</Title>
              {renderFailedHeading}
              {renderErrorMessage}
              <SubContent>{renderSubContent}</SubContent>
              <ButtonsWrapper $wideButtons={true}>
                <ErrorButton
                  onClick={callback}
                  data-rw="modal--try-again-button"
                >
                  Try again
                </ErrorButton>
              </ButtonsWrapper>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
