import React, {PureComponent} from 'react';
import {AlterDot} from './Substitutes.style';

export default class SubItemsDot extends PureComponent {
  selectItem = () => {
    const {selectItem, index} = this.props;
    selectItem(index);
  };
  render() {
    const {isSelected} = this.props;
    return <AlterDot $selected={isSelected} onClick={this.selectItem} />;
  }
}
