import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {ANALYTICS_EVENTS} from '../constants/constants';

export default class analyticsApi {
  static async pageview(url, timeStamp, userInfo) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      timeStamp,
      userInfo,
      event: 'pageview',
      url,
      deviceHash: userInfo.deviceHash,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }

  static async errorEvent(event, description, error, timeStamp, userInfo) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      timeStamp,
      userInfo,
      event,
      description,
      error,
      deviceHash: userInfo.deviceHash,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }

  static async trackEvent(event, eventData, timeStamp, userInfo) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      ...eventData,
      event,
      deviceHash: userInfo.deviceHash,
      timeStamp,
      userInfo,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }

  static async identifyProduct(event, product, timeStamp, userInfo) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      timeStamp,
      userInfo,
      event,
      product,
      deviceHash: userInfo.deviceHash,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }

  static async addProduct(
    event,
    product,
    quantity,
    source,
    isDelivery,
    action = ANALYTICS_EVENTS.ADD_PRODUCT,
    timeStamp,
    userInfo
  ) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      timeStamp,
      userInfo,
      event,
      product,
      action,
      quantity,
      source,
      isDelivery,
      deviceHash: userInfo.deviceHash,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }

  static async removeProduct(
    event,
    product,
    action = ANALYTICS_EVENTS.REMOVE_PRODUCT,
    timeStamp,
    userInfo
  ) {
    const config = getApiConfig();
    let pageReferrer;
    if (typeof window !== 'undefined') {
      pageReferrer = document.referrer;
    }
    const data = {
      timeStamp,
      userInfo,
      event,
      product,
      action,
      deviceHash: userInfo.deviceHash,
      pageReferrer,
    };
    return axios
      .post(config.analyticsUrl, data)
      .then(res => res.data)
      .catch(e => e);
  }
}
