import * as ActionTypes from './types.action';

export const getHomePageOffers = branch => ({
  type: ActionTypes.GET_OFFERS_HOMEPAGE_REQUESTED,
  branch,
  size: 4,
});

export const setHomePageOffers = offers => ({
  type: ActionTypes.GET_OFFERS_HOMEPAGE_SUCCESS,
  offers: {
    products: offers,
  },
});

export const setShopBySectors = shopBySectors => ({
  type: ActionTypes.GET_SHOP_BY_SECTORS_SUCCESS,
  shopBySectors,
});

export const getOffers = (branch, params) => ({
  type: ActionTypes.GET_OFFERS_TODAY_REQUESTED,
  branch,
  params,
});

export const getMoreOffers = (branch, page, params) => ({
  type: ActionTypes.GET_MORE_OFFERS_REQUESTED,
  branch,
  page,
  params,
});
