import {DOTS} from '../constants/constants';

export const generatePagination = (totalPages, currentPage) => {
  let pages;
  if (totalPages < 7) {
    pages = Array.from(Array(totalPages - 1), (x, i) => i + 1);
  } else if (currentPage > 2 && currentPage + 2 < totalPages) {
    pages = [
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      DOTS,
    ];
  } else if (currentPage <= 2) {
    pages = [
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
      currentPage + 4,
      DOTS,
    ];
  } else if (currentPage === totalPages - 2) {
    pages = [
      1,
      DOTS,
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
    ];
  } else if (currentPage === totalPages - 1) {
    pages = [
      1,
      DOTS,
      currentPage - 4,
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
    ];
  } else if (currentPage === totalPages) {
    pages = [
      1,
      DOTS,
      currentPage - 5,
      currentPage - 4,
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
    ];
  }
  return pages;
};
