import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function lookupReducer(state = initialState.lookup, action) {
  switch (action.type) {
    case types.GET_POSTCODE_LOOKUP_SUCCESS:
      return {
        ...state,
        postcode: [...action.lookup],
      };
    case types.GET_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        address: action.lookup,
      };
    case types.RESET_POSTCODE_LOOKUP_REQUESTED:
      return null;
    default:
      return state;
  }
}
