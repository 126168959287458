import React, {PureComponent} from 'react';

import {
  AccountDetails,
  AccountStyled,
  No,
  Name,
  Address,
} from './SwitchAccount.style';

class Account extends PureComponent {
  callBack = () => {
    const {selectAccount, account} = this.props;
    selectAccount(account);
  };
  render() {
    const {account} = this.props;
    return (
      <AccountDetails
        onClick={this.callBack}
        key={account.number || account.id}
      >
        <AccountStyled>
          <No>{account.number || account.id}</No>
          <Name>{account.name || account.businessName}</Name>
        </AccountStyled>
        <Address>
          {account.addressLine || (account.address && account.address.street)}
        </Address>
      </AccountDetails>
    );
  }
}

export default Account;
