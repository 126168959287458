import React, {PureComponent} from 'react';
import {ReactSVG} from 'react-svg';
import loading from '../../images/loading.svg';
import {Spinner} from './Loading.style';

export class Loading extends PureComponent {
  render() {
    const {isLight} = this.props;
    return (
      <Spinner $isLight={isLight === undefined || null ? true : isLight}>
        <ReactSVG src={loading} />
      </Spinner>
    );
  }
}
export default Loading;
