import prod from './config.prod.store';
import dev from './config.dev.store';
let exportedModule;
if (process.env.NODE_ENV === 'production') {
  exportedModule = prod;
} else {
  exportedModule = dev;
}

export default exportedModule;
