import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {
  KEYBOARD_KEYS,
  ONLY_SPACES_REGEX,
  SEARCH_SUGGESTIONS_DEBOUNCE_TIME,
} from '../../constants/constants';
import logo from '../../images/logo.png';
import {
  NavLink,
  Logo,
  ClickLink,
  Wrapper,
  LinkWrapper,
  ArrowUp,
  ArrowDown,
  Links,
  SearchForm,
  SearchInput,
  Search,
} from './Header.style';
import SearchSuggestions from '../Search/SearchSuggestions';
import {debounce} from '../../helpers/events.helper';

export default class Header extends PureComponent {
  static propTypes = {
    showMenuList: PropTypes.bool.isRequired,
    search: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    toggleLoginModal: PropTypes.func.isRequired,
    navigateToBookSlot: PropTypes.func.isRequired,
    setRef: PropTypes.func.isRequired,
    keyword: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      keyword: props.keyword && props.keyword !== '*' ? props.keyword : '',
      selectedSuggestionIndex: -1,
    };
    this.getSearchSuggestions = debounce(() => {
      this.props.getSearchSuggestions(this.state.keyword);
    }, SEARCH_SUGGESTIONS_DEBOUNCE_TIME);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      this.state.keyword === this.props.keyword &&
      this.props.searchSuggestions.length > 0
    ) {
      this.hideSuggestions();
    }
  }

  setKeyword = e => this.setState({keyword: e.target.value});

  handleKeyDown = e => {
    if (e.key === KEYBOARD_KEYS.ARROW_UP) {
      e.preventDefault();
      let newIndex = this.state.selectedSuggestionIndex - 1;
      if (newIndex >= -1) {
        this.setSuggestionIndex(newIndex);
      }
      if (newIndex < -1) {
        this.setSuggestionIndex(this.props.searchSuggestions.length - 1);
      }
    }
    if (e.key === KEYBOARD_KEYS.ARROW_DOWN) {
      e.preventDefault();
      let newIndex = this.state.selectedSuggestionIndex + 1;
      if (newIndex >= this.props.searchSuggestions.length) {
        this.setSuggestionIndex(-1);
      }
      if (newIndex < this.props.searchSuggestions.length) {
        this.setSuggestionIndex(newIndex);
      }
    }
    if (
      this.state.keyword.length > 0 &&
      this.state.keyword !== this.props.keyword &&
      !ONLY_SPACES_REGEX.test(this.state.keyword)
    ) {
      this.getSearchSuggestions();
    }
  };

  onInputClick = () => {
    if (this.state.keyword === '') {
      this.getSearchSuggestions();
    }
  };

  setSuggestionIndex = selectedSuggestionIndex =>
    this.setState({selectedSuggestionIndex});

  hideSuggestions = () => {
    this.props.resetSearchSuggestions();
    this.resetSuggestions();
  };

  resetSuggestions = () => {
    this.setState({selectedSuggestionIndex: -1});
  };

  resetKeyword = () => {
    this.setState({keyword: ''});
  };

  onBookSlotClick = () => {
    const {isLoggedIn, navigateToBookSlot, toggleLoginModal} = this.props;
    if (isLoggedIn) {
      navigateToBookSlot();
    } else {
      toggleLoginModal();
    }
  };

  onSearchClick = e => {
    const {selectedSuggestionIndex, keyword} = this.state;
    const {search, searchSuggestions, history} = this.props;
    e.preventDefault();
    document.activeElement.blur();
    if (selectedSuggestionIndex > -1) {
      const selectedItem = searchSuggestions[selectedSuggestionIndex];
      if (selectedItem) {
        this.setState(
          {
            keyword: selectedItem.searchField,
            type: selectedItem.type,
            urlParams: selectedItem.urlParams,
          },
          () => {
            if (this.state.type === 'url') {
              history.push(this.state.urlParams);
              this.resetKeyword();
            } else {
              history.push(`/search${this.state.urlParams}`);
            }
            this.hideSuggestions();
          }
        );
      }
    } else {
      search(keyword);
    }
  };

  render() {
    const {
      showMenuList,
      toggleMenu,
      setRef,
      searchSuggestions,
      navHome,
    } = this.props;
    const renderArrow = showMenuList ? <ArrowUp /> : <ArrowDown />;
    const renderSearchSuggestions = searchSuggestions &&
      searchSuggestions.length > 0 && (
        <SearchSuggestions
          keyword={this.state.keyword}
          suggestions={this.props.searchSuggestions}
          selectedIndex={this.state.selectedSuggestionIndex}
          setSuggestionIndex={this.setSuggestionIndex}
          search={this.onSearchClick}
          onMouseLeave={this.resetSuggestions}
          hideSuggestion={this.hideSuggestions}
        />
      );

    return (
      <Wrapper>
        <a href="/" onClick={navHome}>
          <Logo src={logo} alt="JJ Foodservice" />
        </a>
        <Links>
          <LinkWrapper onClick={toggleMenu} ref={setRef}>
            {renderArrow}
            <ClickLink>Browse & shop</ClickLink>
          </LinkWrapper>
          <LinkWrapper>
            <NavLink
              to={{
                pathname: '/offers',
                state: {newSearch: true},
              }}
            >
              Offers
            </NavLink>
          </LinkWrapper>
          <LinkWrapper data-nosnippet>
            <ClickLink
              onClick={this.onBookSlotClick}
              data-rw="header--book-slot-link"
            >
              Book Slot
            </ClickLink>
          </LinkWrapper>
          <SearchForm onSubmit={this.onSearchClick}>
            <SearchInput
              placeholder="search"
              onChange={this.setKeyword}
              onFocus={this.props.onSearchInputFocus}
              onClick={this.onInputClick}
              value={this.state.keyword}
              onKeyDown={this.handleKeyDown}
              name="q"
              data-rw="header--search-input"
              autoComplete="off"
            />
            <Search
              onClick={this.onSearchClick}
              data-rw="header--search-button"
            />
            {renderSearchSuggestions}
          </SearchForm>
        </Links>
      </Wrapper>
    );
  }
}
