import React, {PureComponent} from 'react';

import {Links, Title, Status, LinksGroup, TitleWrapper} from './Footer.style';
import {getLink} from '../../helpers/link.helper';

export default class FooterLinks extends PureComponent {
  state = {
    isOpen: false,
  };

  toggle = () => this.setState({isOpen: !this.state.isOpen});

  render() {
    const {links} = this.props;
    const renderLinks =
      links &&
      links.links.map((link, index) => {
        if (link.isOpenChat) {
          return getLink(link, index, false, true);
        }
        if (link.tableLayout) {
          return link.items.map((linkItem, linkItemIndex) => {
            return getLink(linkItem, linkItemIndex, true);
          });
        }
        return getLink(link, index);
      });
    return (
      <Links>
        <TitleWrapper>
          <Title onClick={this.toggle}>
            {links.header}
            <Status $isOpen={this.state.isOpen} />
          </Title>
        </TitleWrapper>
        <LinksGroup $isOpen={this.state.isOpen}>{renderLinks}</LinksGroup>
      </Links>
    );
  }
}
