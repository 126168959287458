import React, {PureComponent} from 'react';
import queryString from 'query-string';

class DownloadPage extends PureComponent {
  componentDidMount() {
    const {location, history} = this.props;
    const search = queryString.parse(location.search);
    if (search.file) {
      window.location.href = search.file;
    } else {
      history.replace('/');
    }
  }

  render() {
    return null;
  }
}
export default DownloadPage;
