import React, {PureComponent} from 'react';
import Markdown from 'markdown-to-jsx';
import {
  Wrapper,
  Head,
  Title,
  Container,
  MarkdownWrapper,
} from '../../styles/pages/pages';
import {BrochureLink, FailedMessage} from './BrochuresPage.style';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {getBrochures} from '../../actions/brochures.action';
import {getBrochures as getCmsBrochures} from '../../actions/cms.action';
import Loading from '../../components/Loading/Loading';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {FILE_NAME_REGEX} from '../../constants/constants';
import ImageComponent from '../../components/Image/Image';
import {markdownConvert} from '../../helpers/string.helper';

export class BrochuresPage extends PureComponent {
  componentDidMount() {
    const {getBrochures, getCmsBrochures} = this.props;
    getBrochures();
    getCmsBrochures();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.brochures &&
      (prevProps.cms && prevProps.cms.brochures) !== cms.brochures
    ) {
      setCmsHeadTags(cms.brochures);
    }
  }

  render() {
    const {brochures, loading, cms} = this.props;
    const renderBrochures =
      brochures &&
      brochures.map((brochure, index) => {
        const fileName = brochure.fileLink.match(FILE_NAME_REGEX)[0];
        return (
          <BrochureLink
            key={index}
            href={`/download?file=${encodeURIComponent(
              brochure.fileLink
            )}&utm_source=${fileName}&utm_medium=web&utm_campaign=brochurepage`}
            target="_blank"
          >
            <ImageComponent url={brochure.coverImage} alt={brochure.title} />
          </BrochureLink>
        );
      });
    const renderNoBrochures = brochures === null && (
      <FailedMessage>
        Cannot fetch brochures, please try again later.
      </FailedMessage>
    );
    const renderLoading = loading && loading.brochures && (
      <Loading isLight={false} />
    );
    const brochuresContent = cms && cms.brochures;
    const renderMarkdown = brochuresContent && brochuresContent.content && (
      <Markdown>{markdownConvert(brochuresContent.content)}</Markdown>
    );
    return (
      <Wrapper>
        <Head>
          <Title>{brochuresContent && brochuresContent.heading}</Title>
        </Head>
        <MarkdownWrapper>{renderMarkdown}</MarkdownWrapper>
        <Container>
          {renderLoading}
          {renderBrochures}
          {renderNoBrochures}
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  brochures: state.brochures,
  loading: state.loading,
  cms: state.cms,
});

const mapDispatchToProps = dispatch => ({
  getBrochures: bindActionCreators(getBrochures, dispatch),
  getCmsBrochures: bindActionCreators(getCmsBrochures, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrochuresPage)
);
