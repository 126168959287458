import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {defaultStyles} from '../../components/Modal/Modal.config';
import ModalWrapper from '../../components/Modal/Modal';
import {Wrapper, Tabs, Close} from '../../styles/pages/modal';
import {
  FormWrapper,
  ModalText,
  Button,
  TheTab,
  ButtonsWrapper,
} from './EditItems.style';
import {getItemPlural, getPlural} from '../../helpers/string.helper';

export default class EditItems extends PureComponent {
  static propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    orderItemsLength: PropTypes.number,
    basketItemsLength: PropTypes.number,
    orderId: PropTypes.string,
  };

  render() {
    const {
      login,
      showModal,
      toggleModal,
      orderItemsLength,
      basketItemsLength,
      orderId,
    } = this.props;
    const renderOrderPlural =
      orderItemsLength && getItemPlural(orderItemsLength);
    const renderBasketPlural =
      basketItemsLength && getItemPlural(basketItemsLength);
    const renderPlural = basketItemsLength && getPlural(basketItemsLength);
    return (
      <ModalWrapper
        customStyles={defaultStyles}
        callback={login}
        isOpen={showModal}
        closeModal={toggleModal}
      >
        <Wrapper>
          <Tabs>
            <TheTab $isActive={true}>Edit your order</TheTab>
            <Close onClick={toggleModal} />
          </Tabs>
          <FormWrapper>
            <ModalText>
              {orderItemsLength} {renderOrderPlural} from {orderId} will be
              moved to your basket,
            </ModalText>
            <ModalText>
              You can then add or remove items before checking out again
            </ModalText>
            <ModalText margin>
              * There {renderPlural} {basketItemsLength} {renderBasketPlural}
              {` already in your basket *`}
            </ModalText>
            <ModalText>
              Empty your basket first, or add them to this order?
            </ModalText>
            <ButtonsWrapper>
              <Button left>Empty basket first</Button>
              <Button right>Add them to this order</Button>
            </ButtonsWrapper>
          </FormWrapper>
        </Wrapper>
      </ModalWrapper>
    );
  }
}
