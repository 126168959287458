import React, {PureComponent} from 'react';
import ModalWrapper from '../Modal/Modal';

import {loginStyles} from '../Modal/Modal.config';
import {
  Content,
  StyledWrapper,
  TextWrapper,
} from '../PaymentPanel/PaymentPanel.style';
import {Title, Text, MarkText, SmallButton} from './RatingsFeedback.style';
import Rating from '../Rating/Rating';
import {
  BRANCH_LOCATION_ID,
  DEFAULT_BRANCH,
  FEEDBACK_URL,
  GOOGLE_RATING_URL,
  REDIRECT_DELAY_TIME,
} from '../../constants/constants';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

export default class RatingsFeedback extends PureComponent {
  componentWillUnmount() {
    if (this.ratingFeedbackTimer) {
      clearTimeout(this.ratingFeedbackTimer);
    }
  }

  setRating = rating => {
    const {branch, closeModal, cAccount} = this.props;
    const url =
      GOOGLE_RATING_URL + BRANCH_LOCATION_ID[branch || DEFAULT_BRANCH];
    const href = rating > 3 ? url : FEEDBACK_URL + cAccount;
    this.ratingFeedbackTimer = setTimeout(
      () => window.open(href, '_blank'),
      REDIRECT_DELAY_TIME
    );
    GoogleAnalyticsHelper.trackFeedback(cAccount, rating);
    closeModal(true);
  };
  closeModalOnly = () => {
    GoogleAnalyticsHelper.trackFeedback(this.props.cAccount, -1);
    this.props.closeModal();
  };

  render() {
    const renderContent = (
      <Content>
        <TextWrapper>
          <Title>We want to hear from you!</Title>
          <Text>Have you got any feedback you’d like us to hear?</Text>
          <Text>
            Leave us a review on your recent experience at JJ Foodservice – it’s
            an important way to help us keep providing better service for our
            customers.
          </Text>
          <MarkText bold>How do you rate us?</MarkText>
          <Rating setRating={this.setRating} />
          <SmallButton onClick={this.closeModalOnly}>No, Thanks</SmallButton>
        </TextWrapper>
      </Content>
    );
    return (
      <ModalWrapper isOpen={true} customStyles={loginStyles}>
        <StyledWrapper>{renderContent}</StyledWrapper>
      </ModalWrapper>
    );
  }
}
