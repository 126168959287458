import {addBreadcrumb, captureException} from '@sentry/browser';

export default class SentryHelper {
  static debugQuantityZero = data => {
    addBreadcrumb({
      category: 'zeroQty',
      message: data.message || 'No message set',
      level: 'info',
    });
    captureException(new Error('Quantity is zero!'));
  };
}
