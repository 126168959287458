import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import {DEFAULT_DATE_FORMAT} from '../../constants/constants';
import {StyledRangePicker} from './RenderDatePicker.style';

export default class RenderDateRangePicker extends PureComponent {
  static propTypes = {
    startDateId: PropTypes.string.isRequired,
    startDate: PropTypes.object,
    endDateId: PropTypes.string.isRequired,
    endDate: PropTypes.object,
    onDatesChange: PropTypes.func.isRequired,
    isDayBlocked: PropTypes.func,
    isOutsideRange: PropTypes.func,
  };

  state = {
    focused: null,
  };

  render() {
    const {
      onDatesChange,
      isOutsideRange,
      isDayBlocked,
      startDate,
      endDate,
      startDateId,
      endDateId,
    } = this.props;
    return (
      <StyledRangePicker>
        <DateRangePicker
          startDate={startDate}
          startDateId={startDateId}
          endDate={endDate}
          endDateId={endDateId}
          onDatesChange={onDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({focusedInput})}
          showDefaultInputIcon
          numberOfMonths={1}
          displayFormat={DEFAULT_DATE_FORMAT}
          isOutsideRange={isOutsideRange}
          isDayBlocked={isDayBlocked}
        />
      </StyledRangePicker>
    );
  }
}
