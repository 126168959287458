import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function upsellingReducer(
  state = initialState.upsellingItems,
  action
) {
  switch (action.type) {
    case types.GET_UPSELLING_ITEMS_REQUESTED:
    case types.GET_UPSELLING_ITEMS_FAILED:
      return Object.assign({}, state, {
        targetedItems: [],
        forgottenItems: [],
        seeThoseDeals: [],
        flashSales: [],
      });
    case types.GET_UPSELLING_ITEMS_SUCCESS:
      const {
        haveYouForgottenTheseItems,
        didYouSeeTheseDeals,
        flashSales,
      } = action.upsellingItems;
      return {
        forgottenItems: haveYouForgottenTheseItems.results || [],
        seeThoseDeals: didYouSeeTheseDeals.results || [],
        flashSales: flashSales.results || [],
      };
    default:
      return state;
  }
}
