import initialState from './initial.state';
import * as types from '../actions/types.action';
import {getHighestTotalPages, groupInvoices} from '../helpers/array.helper';

export default function orderHistoryReducer(
  state = initialState.orderHistory,
  action
) {
  switch (action.type) {
    case types.GET_ACTIVE_ORDERS_SUCCESS:
      return {
        _links: state && state._links,
        page: state && state.page,
        _embedded: {
          ...(state && state._embedded),
          ...{
            activeOrders:
              action.activeOrders &&
              action.activeOrders._embedded &&
              action.activeOrders._embedded.orderDtoList,
          },
        },
      };
    case types.GET_INVOICES_SUCCESS:
      return {
        _links: {...(state && state._links), ...action.invoices._links},
        page: getHighestTotalPages([
          state && state.page,
          action.invoices && action.invoices.page,
          action.cancelledOrders && action.cancelledOrders.page,
        ]),
        _embedded: {
          ...(state && state._embedded),
          ...{
            invoices: groupInvoices(
              action.invoices &&
                action.invoices._embedded &&
                action.invoices._embedded.invoiceDtoes,
              action.cancelledOrders &&
                action.cancelledOrders._embedded &&
                action.cancelledOrders._embedded.orderDtoList
            ),
          },
        },
      };
    case types.SET_ACCOUNTS_SUCCESS:
    case types.REMOVE_AUTH_SUCCESS:
      return null;
    default:
      return state;
  }
}
