import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Wrapper} from './Errors.style';
import {resetError} from '../../actions/error.action';

class Errors extends PureComponent {
  componentDidMount() {
    this.timeout = setTimeout(() => this.props.resetError(), 5000);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  reset = () => this.props.resetError();
  render() {
    return <Wrapper onClick={this.reset}>{this.props.error}</Wrapper>;
  }
}
const mapDispatchToProps = dispatch => ({
  resetError: bindActionCreators(resetError, dispatch),
});
export default connect(null, mapDispatchToProps)(Errors);
