import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function regPasswordResetReducer(
  state = initialState.regPasswordReset,
  action
) {
  switch (action.type) {
    case types.CHANGE_PASSWORD_SUCCESS:
      return action.messages;
    case types.CHANGE_PASSWORD_FAILED:
      return null;
    default:
      return state;
  }
}
