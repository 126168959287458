import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {ButtonStyled} from './Product.style';

export const Container = styled.div`
  position: relative;
  min-width: 17rem;
  max-width: 17rem;
  max-height: 36.3rem;
  min-height: 33rem;
  margin: 0.5rem;
  background-color: ${props => props.theme.tertiaryBgColor};
  display: flex;
  flex-direction: column;
  ${media.small`
    max-width: 14em;
    width: 50%;
  `};
`;

export const Price = styled.div`
  color: ${props => props.theme.tertiaryTextColor};
  font-size: 1.2rem;
  ${media.small`
    font-size: 1.1rem;
  `};
`;

export const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  cursor: pointer;
  img {
    width: 10rem;
    height: 10rem;
    min-height: 10rem;
    max-height: 10rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  flex-direction: column;
  cursor: pointer;
`;

export const Details = styled(Content)`
  padding: 0;
  flex-direction: column;
`;

export const Weight = styled.span`
  font-size: 1.2rem;
  text-align: center;
  color: ${props => props.theme.tertiaryTextColor};
  line-height: 1.8rem;
  margin-top: ${p => (p.$isListView ? 0 : '0.4rem')};
  ${media.medium`
    margin-top: 0.6rem;
    margin-bottom: 0;
  `};
`;

export const ButtonSmall = styled(ButtonStyled)`
  padding: 0 1rem 1rem;
`;

export const SmallDetails = styled.div`
  padding: 0 1rem;
  cursor: pointer;
`;

export const PriceText = styled.div`
  font-size: 1.6rem;
  text-align: ${p => (p.$alignRight ? 'right' : 'left')};
  color: ${p =>
    p.$isOffer ? p.theme.tertiaryColor : p.theme.tertiaryTextColor};
  ${media.medium`
   font-size: 1.4rem;
  `};
`;
