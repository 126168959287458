import React, {PureComponent} from 'react';
import GalleryPic from './GalleryPic';
import {Pics} from './Gallery.style';

export default class Gallery extends PureComponent {
  render() {
    const {gallery, remove, isSmall} = this.props;
    const renderGallery =
      !!gallery &&
      !!gallery.length &&
      gallery.map((pic, i) => (
        <GalleryPic
          key={i}
          source={pic}
          remove={remove}
          idx={i}
          isSmall={isSmall}
        />
      ));
    return <Pics>{renderGallery}</Pics>;
  }
}
