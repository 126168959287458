import React, {PureComponent} from 'react';
import {
  ItemContainer,
  Image,
  ItemWrapper,
  Name,
  AlterDotsWrapper,
  AlterDots,
  NextOne,
  PrevOne,
  TextContent,
} from './Substitutes.style';
import SubItemsDot from './SubItemsDot';
import {ItemId} from '../Basket/BasketItem.style';
import ImageComponent from '../Image/Image';
import {IMAGE_UNAVAILABLE_SIZE} from '../../constants/constants';
import {reduceImageSize} from '../../helpers/image.helper';

export default class SubItem extends PureComponent {
  onSubItemClick = () => {
    const {onItemClick, alternativeItems, subItemIndex} = this.props;
    if (
      alternativeItems &&
      alternativeItems[subItemIndex] &&
      alternativeItems[subItemIndex].itemId
    ) {
      onItemClick(alternativeItems[subItemIndex].itemId);
    }
  };

  render() {
    const {
      unavailable,
      alternativeItems,
      subItemIndex,
      selectSubItem,
      selectPrevItem,
      selectNextItem,
      hideAlternatives,
    } = this.props;
    const item = alternativeItems && alternativeItems[subItemIndex];
    if (!item) {
      return null;
    }
    const renderPrice = !unavailable && item.price > 0 && `£${item.price}`;
    const renderDots = !hideAlternatives &&
      alternativeItems &&
      alternativeItems.length > 1 && (
        <AlterDotsWrapper>
          <PrevOne onClick={selectPrevItem} />
          <AlterDots>
            {alternativeItems.map((alterItems, i) => (
              <SubItemsDot
                key={alterItems && alterItems.itemId}
                isSelected={i === subItemIndex}
                index={i}
                selectItem={selectSubItem}
              />
            ))}
          </AlterDots>
          <NextOne onClick={selectNextItem} />
        </AlterDotsWrapper>
      );
    const imageUrl = item.imageLinks && item.imageLinks[0];
    return (
      <ItemContainer>
        <ItemWrapper
          onClick={this.onSubItemClick}
          $unavailable={unavailable}
          $hideAlternatives={hideAlternatives}
        >
          <Image>
            <ImageComponent
              url={reduceImageSize(imageUrl)}
              type={IMAGE_UNAVAILABLE_SIZE.S}
              alt={item.itemName}
            />
          </Image>
          <TextContent>
            <ItemId>{item && item.itemId}</ItemId>
            <Name $unavailable={unavailable}>{item.itemName}</Name>
            {renderPrice}
          </TextContent>
        </ItemWrapper>
        {renderDots}
      </ItemContainer>
    );
  }
}
