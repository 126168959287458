import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function accountReducer(state = initialState.accounts, action) {
  switch (action.type) {
    case types.GET_ACCOUNTS_SUCCESS:
    case types.GET_ACCOUNTS_FROM_CACHE_SUCCESS:
    case types.SET_SELECTED_ACCOUNTS_SUCCESS:
      return action.accounts;
    case types.SEARCH_ACCOUNTS_REQUESTED:
    case types.RESET_ACCOUNTS_REQUESTED:
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
