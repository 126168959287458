import {put, take, select, delay, retry} from 'redux-saga/effects';
import basketApi from '../api/basket.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {captureException, withScope} from '@sentry/browser';
import {
  BASKET_ERRORS_TYPE,
  BASKET_RETRY_TIMEOUT,
  ERRORS,
  HTTP_ACCEPTED,
  MAX_RETRY,
  ROUTES,
  UPDATING_TYPES,
} from '../constants/constants';
import {createHashMap, updateCurrentBasket} from '../helpers/data.helper';
import {syncBasketItems} from '../helpers/basket.helper';

export default function* updateBasketItem() {
  while (true) {
    const action = yield take(ActionTypes.UPDATE_BASKET_ITEM_REQUESTED);
    try {
      const getBasket = state => state.basket;
      const getAuth = state => state.auth;
      const getBasketItems = state => state.basketItems;
      const basketItems = yield select(getBasketItems);
      const storeToken = yield select(getAuth);
      if (storeToken && !storeToken.enhanced) {
        return;
      }
      const getRoute = state => state.route;
      const currentRoute = yield select(getRoute);
      const isBasketPage = currentRoute === ROUTES.BASKET;
      const headers = getBearerHeader(action.jjToken, storeToken);
      const storeBasket = yield select(getBasket);
      const basket = yield retry(
        MAX_RETRY,
        BASKET_RETRY_TIMEOUT,
        basketApi.updateBasketItem,
        action.uuid,
        action.params,
        headers
      );
      if (basket === HTTP_ACCEPTED) {
        // drop the `Accepted` response  when BE cannot handle too many requests
        yield put({type: ActionTypes.UPDATE_BASKET_ITEM_CANCELLED});
        return;
      }
      if (isBasketPage) {
        const isRemove = false;
        const newBasket = updateCurrentBasket(
          isRemove,
          storeBasket,
          basket,
          action.uuid
        );
        yield put({
          type: ActionTypes.UPDATE_BASKET_ITEM_SUCCEEDED,
          basket: newBasket,
        });
        yield put({
          type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
          basketHashMap: createHashMap(newBasket.items),
        });
        const newBasketItems = syncBasketItems(basketItems, newBasket.items);
        yield put({
          type: ActionTypes.GET_PRODUCTS_BY_IDS_SUCCESS,
          basketItems: newBasketItems,
          paymentMethod: newBasket.paymentMethod,
        });
      } else {
        yield put({type: ActionTypes.UPDATE_BASKET_ITEM_SUCCEEDED, basket});
        yield put({
          type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
          basketHashMap: createHashMap(basket.items),
        });
      }
    } catch (e) {
      const {response} = e;
      if (!response || (response && !response.data)) {
        yield put({
          type: ActionTypes.UPDATE_BASKET_ITEM_FAILED,
          message: e,
          issue: BASKET_ERRORS_TYPE.UPDATE,
        });
      } else {
        const {data} = response;
        if (data.error === ERRORS.UNAUTHORIZED) {
          yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
        } else if (
          data.error === ERRORS.EXPIRED ||
          data.code === ERRORS.NEED_AUTH
        ) {
          yield put({
            type: ActionTypes.ERROR_LOGIN_EXPIRED,
            error: {
              type: UPDATING_TYPES.ITEM,
              uuid: action.uuid,
              params: action.params,
            },
          });
        }
        yield put({
          type: ActionTypes.UPDATE_BASKET_ITEM_FAILED,
          message: data.message,
          code: data.code,
          issue: BASKET_ERRORS_TYPE.UPDATE,
        });
      }
      withScope(scope => {
        scope.setExtra('action', action);
        captureException(e);
      });
    }
  }
}
