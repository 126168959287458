import styled from 'styled-components';
import {OutlinedFavorite} from '../../components/Favorite/Favorite.style';

export const NoItemsAvailable = styled.span`
  font-size: 1.4rem;
  display: block;
  padding: 2rem;
  color: ${p => p.theme.textColor};
`;

export const NoFavouritesText = styled.div`
  display: inline;
  align-items: center;
  line-height: 2.4rem;
`;

export const FavIconWrapper = styled.span`
  margin: 0 0.5rem;
  display: inline-block;
  vertical-align: middle;
`;

export const FavoritedIcon = styled(OutlinedFavorite)`
  &::before {
    color: ${p => p.theme.tertiaryColor};
  }
`;
