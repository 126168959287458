import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  TextWrapper,
  OK,
  ButtonsWrapper,
} from './ReOrderBasketModal.style';
import Loading from '../Loading/Loading';

export default class ReOrderBasketModal extends PureComponent {
  render() {
    const {
      showModal,
      isLoadingModal,
      newBasketCallback,
      addBasketCallback,
      closeModalCallback,
    } = this.props;
    const renderStartNewButton = <OK onClick={newBasketCallback}>Start new</OK>;
    // const renderAddButton = <OK onClick={addBasketCallback}>Add</OK>; TODO use this when BULK ADD is ready on BE
    const renderCancelButton = <OK onClick={closeModalCallback}>Cancel</OK>;
    const rendering = isLoadingModal ? (
      <Loading isLight={false} />
    ) : (
      <ButtonsWrapper>
        {/*{renderAddButton}*/}
        {renderStartNewButton}
        {renderCancelButton}
      </ButtonsWrapper>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
      >
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>Create a new basket?</Title>
              <p>
                {/*TODO use this when BULK ADD is ready on BE*/}
                {/*Add this order to the items already in your basket,*/}
                {/*<br />*/}
                {/*or start a new basket?*/}
                It will remove your current basket and create a new one
              </p>
              {rendering}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
