import React, {Fragment, PureComponent} from 'react';
import {Wrapper, StatusMessage} from './SystemStatus.style';
import Markdown from 'markdown-to-jsx';
import {markdownConvert} from '../../helpers/string.helper';

export default class SystemStatus extends PureComponent {
  state = {
    index: 0,
    messages: null,
  };

  componentDidUpdate(prevProps) {
    const {systemMessages} = this.props;
    if (systemMessages && systemMessages !== prevProps.systemMessages) {
      let messages;
      if (systemMessages.maintenance && systemMessages.maintenance.length > 0) {
        messages = systemMessages.maintenance;
      } else if (
        systemMessages.announcement &&
        systemMessages.announcement.length > 0
      ) {
        messages = systemMessages.announcement;
      }
      this.setState({messages});
    }
  }
  render() {
    const {messages} = this.state;
    if (!messages) {
      return null;
    }
    const renderMessages =
      messages &&
      messages.map((msg, i) => {
        const convertedMsg = markdownConvert(decodeURIComponent(msg.message));
        if (!convertedMsg) {
          return null;
        }
        return (
          <Fragment key={i + msg.heading}>
            <StatusMessage>
              <Markdown>{convertedMsg}</Markdown>
            </StatusMessage>
          </Fragment>
        );
      });
    return <Wrapper>{renderMessages}</Wrapper>;
  }
}
