import initialState from './initial.state';
import * as types from '../actions/types.action';
import {calculateCardPromoTotal} from '../helpers/basket.helper';

export default function promoTotalReducer(
  state = initialState.promoTotal,
  action
) {
  switch (action.type) {
    case types.GET_PRODUCTS_BY_IDS_SUCCESS:
      return calculateCardPromoTotal(action.basketItems.inStock);
    case types.UPDATE_BASKET_ITEM_REQUESTED:
    case types.REMOVE_BASKET_ITEM_REQUESTED:
    case types.UPDATE_BASKET_REQUESTED:
    case types.REMOVE_BASKET_SUCCEEDED:
    case types.REMOVE_AUTH_SUCCESS: //logout clean up
      return null;
    default:
      return state;
  }
}
