import React from 'react';
import {Redirect} from 'react-router-dom';
import {Switch, Route} from 'react-router-dom';
import {routes} from './route.config';

import HomePage from '../client/HomePage/HomePage';
import SearchPage from '../client/SearchPage/SearchPage';
import ProductDetailPage from '../client/ProductDetailPage/ProductDetailPage';
import OffersPage from '../client/OffersPage/OffersPage';
import BasketPage from '../client/BasketPage/BasketPage';
import BookSlotPage from '../client/BookSlotPage/BookSlotPage';
import FavouritesPage from '../client/FavouritesPage/FavouritesPage';
import PreviouslyPurchasedPage from '../client/PreviouslyPurchasedPage/PreviouslyPurchasedPage';
import ReportPage from '../client/ReportPage/ReportPage';
import CareersPage from '../client/CareersPage/CareersPage';
import VacanciesPage from '../client/VacanciesPage/VacanciesPage';
import AccountPage from '../client/AccountPage/AccountPage';
import ConfirmationPage from '../client/ConfirmationPage/ConfirmationPage';
import OrdersPage from '../client/OrdersPage/OrdersPage';
import AboutUsPage from '../client/AboutUsPage/AboutUsPage';
import PoliciesPage from '../client/PoliciesPage/PoliciesPage';
import PackagingPage from '../client/PackagingPage/PackagingPage';
import CheckoutPage from '../client/CheckoutPage/CheckoutPage';
import {
  RELOGIN_SUCCESS_PATH,
  SUCCESS_PATH,
  SUCCESS_REGISTER_PATH,
  TRACKING_FULL_PATH,
} from '../constants/routePaths';
import SupplyUsPage from '../client/SupplyUsPage/SupplyUsPage';
import LoginSuccessPage from '../client/LoginSuccessPage/LoginSuccessPage';
import TrackingPage from '../client/TrackingPage/TrackingPage';
import ShoppingListsPage from '../client/ShoppingListsPage/ShoppingListsPage';
import ShoppingListsContentPage from '../client/ShoppingListsContentPage/ShoppingListsContentPage';
import SingleOrder from '../client/OrdersPage/SingleOrder';

export const AppRoute = (
  updateBasket,
  updateItem,
  removeItem,
  relogin,
  navigateToCheckout,
  deviceInfo,
  onProductClick,
  cleanBasket,
  openStopEditModal,
  toggleLoginModal,
  keywordSearch
) => (
  <Switch>
    <Route
      path="/"
      exact={true}
      render={props => (
        <HomePage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/search"
      render={props => (
        <SearchPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/productDetail/:itemId"
      render={props => (
        <ProductDetailPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/product/:branchName/:itemId/"
      render={props => (
        <ProductDetailPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/basket"
      render={props => (
        <BasketPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...navigateToCheckout}
          {...onProductClick}
          {...cleanBasket}
          {...openStopEditModal}
        />
      )}
    />
    <Route
      path="/orderHistory/:orderId"
      render={props => (
        <BasketPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...navigateToCheckout}
        />
      )}
    />
    <Route
      path="/offers"
      render={props => (
        <OffersPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/bookSlot"
      render={props => (
        <BookSlotPage
          {...props}
          {...updateBasket}
          {...relogin}
          {...navigateToCheckout}
        />
      )}
    />
    <Route
      path="/checkout/:basketId"
      render={props => (
        <CheckoutPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/favourites"
      render={props => (
        <FavouritesPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/confirmation"
      render={props => <ConfirmationPage {...props} {...deviceInfo} />}
    />
    <Route
      path="/careers"
      render={props => (
        <CareersPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/vacancies"
      render={props => (
        <VacanciesPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/aboutus"
      render={props => (
        <AboutUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/about-us"
      render={props => (
        <AboutUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/supplyus"
      render={props => (
        <SupplyUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/supply-us"
      render={props => (
        <SupplyUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/supplyus"
      render={props => (
        <SupplyUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/supply-us"
      render={props => (
        <SupplyUsPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route
      path="/policies"
      render={props => (
        <PoliciesPage {...props} {...updateBasket} {...deviceInfo} />
      )}
    />
    <Route path="/account" render={props => <AccountPage {...props} />} />
    <Route
      path={[
        '/packaging',
        '/food-packaging',
        '/takeaway-packaging',
        '/takeaway-packaging-wholesales',
      ]}
      render={props => (
        <PackagingPage {...props} {...updateBasket} {...keywordSearch} />
      )}
    />
    <Route
      path="/recentpurchases"
      render={props => (
        <PreviouslyPurchasedPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/reports"
      render={props => <ReportPage {...props} {...updateBasket} {...relogin} />}
    />
    <Route
      path="/orders"
      render={props => (
        <OrdersPage
          {...props}
          {...updateBasket}
          {...deviceInfo}
          {...openStopEditModal}
          {...cleanBasket}
        />
      )}
    />
    <Route
      path="/shoppingLists"
      render={props => (
        <ShoppingListsPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path="/shoppingList/:listId/:listName"
      render={props => (
        <ShoppingListsContentPage
          {...props}
          {...updateBasket}
          {...updateItem}
          {...removeItem}
          {...onProductClick}
          {...deviceInfo}
          {...keywordSearch}
        />
      )}
    />
    <Route
      path={SUCCESS_PATH}
      render={props => <LoginSuccessPage {...props} />}
    />
    <Route
      path={SUCCESS_REGISTER_PATH}
      render={props => <LoginSuccessPage {...props} {...{isRegister: true}} />}
    />
    <Route
      path={RELOGIN_SUCCESS_PATH}
      render={props => (
        <LoginSuccessPage {...props} {...{requireLogin: true}} />
      )}
    />
    <Route
      path={TRACKING_FULL_PATH}
      exact={true}
      render={props => (
        <TrackingPage
          {...props}
          {...updateBasket}
          {...relogin}
          {...toggleLoginModal}
        />
      )}
    />
    <Route
      path="/order/:orderId/"
      render={props => (
        <SingleOrder
          {...props}
          {...relogin}
          {...updateBasket}
          {...deviceInfo}
          {...openStopEditModal}
          {...toggleLoginModal}
          {...cleanBasket}
        />
      )}
    />
    <Route
      path="/invoicedOrder/:orderId/"
      render={props => (
        <SingleOrder
          {...props}
          {...relogin}
          {...updateBasket}
          {...deviceInfo}
          {...toggleLoginModal}
          {...cleanBasket}
          {...{isInvoiced: true}}
        />
      )}
    />
    <Route
      path="/own-brands"
      render={() => <Redirect to="/content/own-brands" />}
    />
    {routes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
  </Switch>
);
