import styled, {css} from 'styled-components';

export const Button1 = styled.a`
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primaryColor};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const Button2 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primaryTextColor};
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${props => props.theme.tertiaryColor};
  border: 0;

  &:hover {
    background-color: ${props => props.theme.tertiaryColorHover};
  }
`;

const theSharingStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  min-height: 4rem;
`;

export const Button3 = styled.a`
  ${theSharingStyle};
  color: ${props => props.theme.primaryTextColor};
  border: ${props => `solid 0.3rem ${props.theme.primaryTextColor}`};

  &:hover {
    background-color: ${props => props.theme.secondaryColor};
    border-color: ${props => props.theme.secondaryColor};
  }
`;

export const Button3Inverted = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  min-height: 4rem;
  width: 100%;
  background-color: ${p =>
    p.$isLoading ? p.theme.secondaryColor : 'transparent'};
  color: ${props => props.theme.secondaryColor};
  border: ${props => `solid 0.2rem ${props.theme.secondaryColor}`};

  &:hover {
    background-color: ${props => props.theme.secondaryColor};
    color: ${props => props.theme.primaryTextColor};
    border-color: ${props => props.theme.secondaryColor};
  }
`;

export const Button3a = styled(Button3)`
  color: ${p => (p.$disabled ? p.theme.disabledColor : p.theme.secondaryColor)};
  border: ${p =>
    p.$disabled
      ? `0.2rem solid ${p.theme.disabledColor}`
      : `0.2rem solid ${p.theme.secondaryColor}`};
  background-color: ${p =>
    p.$isLoading ? p.theme.secondaryColor : 'transparent'};

  &:hover {
    color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.primaryTextColor};
    background-color: ${p =>
      p.$disabled ? 'transparent' : p.theme.secondaryColor};
    border-color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.secondaryColor};
  }
`;

// same as Button3a but color is blue
export const Button3b = styled(Button3)`
  color: ${p => (p.$disabled ? p.theme.disabledColor : p.theme.primaryColor)};
  border: ${p =>
    p.$disabled
      ? `0.2rem solid ${p.theme.disabledColor}`
      : `0.2rem solid ${p.theme.primaryColor}`};
  background-color: ${p =>
    p.$isLoading ? p.theme.primaryColor : 'transparent'};

  &:hover {
    color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.primaryTextColor};
    background-color: ${p =>
      p.$disabled ? 'transparent' : p.theme.primaryColor};
    border-color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.primaryColor};
  }
`;

// same as Button3a but color is red
export const Button3c = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  min-height: 4rem;
  color: ${p =>
    p.$disabled ? p.theme.disabledColor : p.theme.tertiaryAltColor};
  border: ${p =>
    p.$disabled
      ? `0.2rem solid ${p.theme.disabledColor}`
      : `0.2rem solid ${p.theme.tertiaryAltColor}`};
  background-color: ${p =>
    p.$isLoading ? p.theme.tertiaryAltColor : 'transparent'};

  &:hover {
    color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.primaryTextColor};
    background-color: ${p =>
      p.$disabled ? 'transparent' : p.theme.tertiaryAltColor};
    border-color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.tertiaryAltColor};
  }
`;

export const Button4 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: 0;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &::after {
    font-family: 'icomoon';
    display: inline-block;
    font-size: 3rem;
    color: ${props => props.theme.primaryTextColor};
  }
`;

export const InputButton = styled.input`
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.primaryTextColor};
  background-color: transparent;
  text-transform: uppercase;
  border: solid 0.3rem ${props => props.theme.primaryTextColor};
  outline: none;
  height: 4rem;
  letter-spacing: 0.2rem;

  &::placeholder {
    color: ${props => props.theme.primaryTextColor};
  }
`;
