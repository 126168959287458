import * as ActionTypes from './types.action';

export const getAllSettings = jjToken => ({
  type: ActionTypes.GET_ALL_SETTINGS_REQUESTED,
  jjToken,
});

export const setSetting = (setting, value, jjToken) => ({
  type: ActionTypes.UPDATE_SETTING_REQUESTED,
  setting,
  value,
  jjToken,
});

export const removeSetting = (setting, jjToken) => ({
  type: ActionTypes.UPDATE_SETTING_REQUESTED,
  isRemove: true,
  setting,
  jjToken,
});

export const updateAllSettings = (settings, jjToken) => ({
  type: ActionTypes.UPDATE_ALL_SETTINGS_REQUESTED,
  settings,
  jjToken,
});

export const resetSetting = () => ({
  type: ActionTypes.RESET_SETTING_SUCCESS,
});
