import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  Container,
  Content,
  Title,
  Error,
  ErrorsContainer,
} from './RegisterActivation.style';
import {Loading} from '../../components/Loading/Loading';
import {
  ButtonWrapper,
  ThankYouContent,
  ThankYouWrapper,
} from '../../components/LoginRegister/LoginRegister.style';
import {Button} from '../../styles/components/form';
import {
  activateAccount,
  resetActivateAccount,
} from '../../actions/accounts.action';

class RegisterActivationPage extends PureComponent {
  componentDidMount() {
    const {match, activateAccountFunc} = this.props;
    if (match && match.params && match.params.code) {
      activateAccountFunc(match.params.code);
    }
  }

  componentWillUnmount() {
    const {resetActivateAccountFunc} = this.props;
    resetActivateAccountFunc();
  }

  showLoginModal = () => this.props.history.push('/login');

  render() {
    const {storeErrors, loading, activateAccount} = this.props;
    const renderMessage = activateAccount && activateAccount.Message;
    const renderButton = <Button onClick={this.showLoginModal}>Login</Button>;
    const renderLoading = loading && loading.activateAccount && (
      <Loading isLight={false} />
    );
    const renderError = storeErrors && storeErrors.activateAccount && (
      <ErrorsContainer>
        <Error>
          Activation failed. Please try registering again or call Customer
          Support
        </Error>
      </ErrorsContainer>
    );
    const renderContent = loading &&
      !loading.activateAccount &&
      storeErrors &&
      !storeErrors.activateAccount && (
        <ThankYouWrapper>
          <ThankYouContent>{renderMessage}</ThankYouContent>
          <ButtonWrapper>{renderButton}</ButtonWrapper>
        </ThankYouWrapper>
      );

    const renderTemporaryContent = loading && !loading.activateAccount && (
      <ThankYouWrapper>
        <ThankYouContent>
          Your account is now activated. Please login to continue.
        </ThankYouContent>
        <ButtonWrapper>{renderButton}</ButtonWrapper>
      </ThankYouWrapper>
    );
    return (
      <Container>
        <Content>
          <Title>Account activation</Title>
          {renderLoading}
          {renderTemporaryContent}
          {renderError}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeErrors: state.errors,
    loading: state.loading,
    activateAccount: state.activateAccount,
  };
};

const mapDispatchToProps = dispatch => ({
  activateAccountFunc: bindActionCreators(activateAccount, dispatch),
  resetActivateAccountFunc: bindActionCreators(resetActivateAccount, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterActivationPage);
