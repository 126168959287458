import {keyframes} from 'styled-components';

export const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const easeIn = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
`;

export const easeOut = keyframes`
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
`;

export const bounceUp = keyframes`
  0%, 20% {
    transform: translateY(0);
 }
  10% {
    transform: translateY(-0.5rem);
  }
`;

export const bounceDown = keyframes`
  0%, 20% {
    transform: translateY(0);
 }
  10% {
    transform: translateY(0.5rem);
  }
`;

export const rotateLeft = keyframes`
  0% {
    transform: rotate(0);
 }
  100% {
    transform: rotate(180deg);
  }
`;

export const rotateRight = keyframes`
  0% {
    transform: rotate(180deg);
 }
  100% {
    transform: rotate(0);
  }
`;

export const leftEaseIn = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
