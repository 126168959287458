import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function branchLocatorReducer(
  state = initialState.branchLocator,
  action
) {
  switch (action.type) {
    case types.GET_NEAREST_BRANCH_SUCCESS:
      return Object.assign({}, state, action.branchLocator);
    case types.GET_NEAREST_BRANCH_REQUESTED:
    case types.GET_NEAREST_BRANCH_FAILED:
      return null;
    default:
      return state;
  }
}
