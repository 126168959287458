import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function pbbBankDetailsReducer(
  state = initialState.pbbDefaultBank,
  action
) {
  switch (action.type) {
    case types.GET_PBB_BANK_DETAILS_SUCCESS:
      return action.pbbDefaultBank;
    case types.GET_PBB_BANK_DETAILS_FAILED:
      return null;
    default:
      return state;
  }
}
