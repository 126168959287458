import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {Redirect} from 'react-router-dom';
import {
  ECOSPEND_ERROR,
  ECOSPEND_STATUS,
  JJ_LOCAL_STORAGE,
  ORDER_SOURCE,
  PAYMENT_URL_REGEX,
  ANALYTICS_EVENTS,
  PAYMENT_CALLBACK_CODE,
} from '../../constants/constants';
import {getDataFromLocalStorage} from '../../helpers/localStorage.helper';
import {LinkButton, Wrapper} from '../../styles/pages/pages';
import {Content, SectionText, ButtonWrapper} from './PaymentCallbackPage.style';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

const PaymentCallbackPage = ({location, history}) => {
  const [callbackParams, setParams] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [axBasketId, setAxBasketId] = useState(null);
  const [
    orderIsCompletedInOtherDevice,
    setsOrderIsCompletedInOtherDevice,
  ] = useState(false);
  useEffect(() => {
    const params = queryString.parse(location.search);
    setParams(params);
    const urlOrderId = location.pathname.match(new RegExp(PAYMENT_URL_REGEX));
    let orderId;
    if (urlOrderId && urlOrderId[1]) {
      orderId = urlOrderId[1];
    } else {
      orderId = getDataFromLocalStorage(JJ_LOCAL_STORAGE.ORDER_ID);
    }
    setOrderId(orderId);
    const axBasketId = getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.ORDER_AX_BASKETID
    );
    setAxBasketId(axBasketId);
    if (
      params &&
      !!params.orderId &&
      !!params.bank_reference_id &&
      params.source === ORDER_SOURCE.WEB &&
      (params.status === ECOSPEND_STATUS.COMPLETED ||
        params.status === ECOSPEND_STATUS.VERIFIED)
    ) {
      if (
        orderId &&
        orderId !== params.orderId &&
        params.callback !== 1 &&
        params.callback !== 0
      ) {
        setsOrderIsCompletedInOtherDevice(true);
        history.replace(location.pathname, {});
      }
    }
  }, []);

  useEffect(() => {
    if (!callbackParams) {
      return;
    }
    const {status, error, callback} = callbackParams;
    if (
      callback &&
      (callback === PAYMENT_CALLBACK_CODE.SUCCESS ||
        callback === PAYMENT_CALLBACK_CODE.FAIL)
    ) {
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.PAYMENT_CALLBACK, {
        callback,
        status,
        error,
        orderId,
      });
    }

    if (status || error) {
      if (
        Object.values(ECOSPEND_STATUS).indexOf(status) > -1 ||
        callbackParams.error === ECOSPEND_ERROR.USER_CANCELED
      ) {
        GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.CHECKOUT_STATUS, {
          status,
          error,
          orderId,
        });
      }
    }
  }, [callbackParams]);

  if (!callbackParams) {
    return null;
  }
  if (orderIsCompletedInOtherDevice) {
    return (
      <Wrapper>
        <Content>
          <SectionText>
            Your order is being completed on the initiating device.
          </SectionText>
          <ButtonWrapper>
            <LinkButton to="/">OK</LinkButton>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    );
  }
  if (
    callbackParams.callback === PAYMENT_CALLBACK_CODE.SUCCESS ||
    callbackParams.status === ECOSPEND_STATUS.COMPLETED ||
    callbackParams.status === ECOSPEND_STATUS.VERIFIED
  ) {
    const redirectOrderID =
      orderId === null && callbackParams && callbackParams.orderId
        ? callbackParams.orderId
        : orderId;
    return (
      <Redirect
        to={{
          pathname: `/confirmation/${redirectOrderID}`,
          state: {
            orderId: redirectOrderID,
            basketId:
              axBasketId || callbackParams.basketId || callbackParams.sales_id,
          },
        }}
      />
    );
  }
  if (callbackParams.status === ECOSPEND_STATUS.CANCELED) {
    return <Redirect to={{pathname: `/basket`}} />;
  }
  if (
    callbackParams.error === ECOSPEND_ERROR.USER_CANCELED &&
    callbackParams.orderId
  ) {
    return (
      <Redirect
        to={{
          pathname: `/checkout/${callbackParams.orderId}`,
        }}
      />
    );
  }
  if (
    (callbackParams.result || callbackParams.message) &&
    (callbackParams.basketId || callbackParams.orderId)
  ) {
    return (
      <Redirect
        to={{
          pathname: `/checkout/${callbackParams.basketId ||
            callbackParams.orderId}`,
          state: {
            paymentError: {
              failedCode: callbackParams.result,
              message: callbackParams.message,
            },
          },
        }}
      />
    );
  } else {
    return null;
  }
};

export default PaymentCallbackPage;
