import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  Wrapper,
  Title,
  Content,
  ButtonWrapper,
  LinkButton,
} from '../../styles/pages/pages';
import {
  Head,
  TheButton,
  Row,
  RadioText,
  Container,
  RadioWrapper,
  RadioLabel,
  CheckMark,
  Comments,
} from './SurveyPage.style';
import Rating from '../../components/Rating/Rating';
import {ExtraWrapper} from '../../styles/components/wrapper';

class SurveyPage extends PureComponent {
  static propTypes = {
    basket: PropTypes.object.isRequired,
  };

  state = {
    counterRating: 0,
    warehouseRating: 0,
    productRating: 0,
    overallRating: 0,
    speakWithSomeOne: false,
    comments: '',
  };

  setCounterRating = counterRating => this.setState({counterRating});
  setWarehouseRating = warehouseRating => this.setState({warehouseRating});
  setProductRating = productRating => this.setState({productRating});
  setOverallRating = overallRating => this.setState({overallRating});
  setComments = e => this.setState({comments: e.target.value});
  changeSpeakType = () =>
    this.setState({speakWithSomeOne: !this.state.speakWithSomeOne});
  submit = () => {
    const {
      counterRating,
      warehouseRating,
      productRating,
      overallRating,
    } = this.state;
    if (
      counterRating === 0 &&
      warehouseRating === 0 &&
      productRating === 0 &&
      overallRating === 0
    ) {
      return;
    }
    // todo: send the data to BE
  };

  render() {
    const {
      counterRating,
      warehouseRating,
      productRating,
      overallRating,
      comments,
    } = this.state;
    const validSubmit =
      counterRating > 0 &&
      warehouseRating > 0 &&
      productRating > 0 &&
      overallRating > 0;
    return (
      <Wrapper>
        <Head>
          <Title>Survey</Title>
          <ButtonWrapper>
            <LinkButton to="/">Continue shopping</LinkButton>
          </ButtonWrapper>
        </Head>
        <Content>
          <Container>
            <Row>
              <span>Counter staff satisfaction</span>
              <Rating
                rating={counterRating}
                setRating={this.setCounterRating}
              />
            </Row>
            <Row>
              <span>Warehouse staff satisfaction</span>
              <Rating
                rating={warehouseRating}
                setRating={this.setWarehouseRating}
              />
            </Row>
            <Row>
              <span>Product satisfaction</span>
              <Rating
                rating={productRating}
                setRating={this.setProductRating}
              />
            </Row>
            <Row>
              <span>Overall company satisfaction</span>
              <Rating
                rating={overallRating}
                setRating={this.setOverallRating}
              />
            </Row>
            <Row>
              <span>Would like to speak someone</span>
              <RadioWrapper>
                <RadioLabel onClick={this.changeSpeakType}>
                  <CheckMark $isChecked={this.state.speakWithSomeOne} />
                  <RadioText $isChecked={this.state.speakWithSomeOne}>
                    Yes
                  </RadioText>
                </RadioLabel>
                <RadioLabel onClick={this.changeSpeakType}>
                  <CheckMark $isChecked={!this.state.speakWithSomeOne} />
                  <RadioText $isChecked={!this.state.speakWithSomeOne}>
                    No
                  </RadioText>
                </RadioLabel>
              </RadioWrapper>
            </Row>
            <Row>
              <Comments
                value={comments}
                onChange={this.setComments}
                rows="4"
                placeholder="Comments and ideas"
              />
            </Row>
          </Container>
        </Content>
        <ExtraWrapper>
          <TheButton disabled={!validSubmit} onClick={this.submit}>
            Submit Survey
          </TheButton>
          <LinkButton to="/">Continue shopping</LinkButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    branch: state.branch,
    branchList: state.branchList,
    basket: state.basket,
  };
};

export default connect(mapStateToProps, null)(SurveyPage);
