import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 1rem 2rem;
  margin: 1rem 0;
  font-size: 1.2rem;
  border: ${p => `solid 0.1rem ${p.theme.tertiaryColor}`};
  color: ${p => p.theme.textColor};
  ${media.small`
    margin: 1rem 0.5rem;
  `};
`;
