import axios from 'axios';
import queryString from 'query-string';

import {getApiConfig} from '../config/configProvider';

export default class authApi {
  static refresh(data) {
    const config = getApiConfig();
    const query = queryString.stringify(data);
    const url = `${config.accountUrlV2}/oauth/token?${query}`;
    return axios.post(url).then(res => res.data);
  }

  static getLoginToken(data, authUrl) {
    const url = `${authUrl}/token`;
    return axios.post(url, data).then(res => res.data);
  }

  static relogin(data) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conf = getApiConfig();
    const query = queryString.stringify(data);
    const url = `${conf.accountUrlV2}/oauth/token`;
    return axios.post(url, query, config).then(res => res.data);
  }
}
