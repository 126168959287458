import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3} from './buttons';

export const Button = styled(Button3)`
  min-height: 3.4rem;
  box-sizing: content-box;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  width: auto;
  text-transform: uppercase;
  margin-right: ${p => (p.$right ? '1rem' : 0)};
  border-color: ${p => p.theme.secondaryColor};
  color: ${p => p.theme.secondaryColor};
  background-color: ${p =>
    p.$isSubmitting ? p.theme.secondaryColor : p.theme.tertiaryBgColor};

  &:hover {
    color: ${p => p.theme.primaryTextColor};
  }

  ${media.smaller`
    margin-right: 0;
    margin-top: ${p => (p.top ? '1rem' : 0)};
  `};
`;
