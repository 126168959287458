import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
`;

export const Content = styled.div`
  width: 80rem;
  padding: 2rem;
  ${media.small`
    width: 100%;
  `};
`;

export const Title = styled.div`
  color: ${p => p.theme.secondaryColor};
  text-transform: capitalize;
  font-size: 3.6rem;
  font-weight: lighter;
  ${media.small`
    padding-left: 2rem;
    padding-right: 2rem;
  `};
  ${media.smaller`
    padding: 0;
  `};
`;

export const FormWrapper = styled.form`
  padding: 2rem;
  min-height: 12rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  ${media.small`
    flex-direction: column;
  `};
`;

export const Panel = styled.div`
  width: 50%;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.smaller`
    width: 100%;
  `};
`;

export const ErrorsField = styled.div`
  min-height: 1.6rem;
  padding: 0 0.4rem;
`;

export const Text = styled.div`
  color: ${p => p.theme.textColor};
  font-size: 1.2rem;
  padding: 0 0.5rem;
`;

export const ErrorsContainer = styled.div`
  padding: 2rem 0;
`;

export const Error = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.4rem;
  line-height: 1.6rem;
`;
