import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jjIcon from '../../images/jj_map.png';

import {
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
  Marker,
} from '@react-google-maps/api';
import {MAP_CENTER_LAT_LNG} from '../../constants/constants';

export const MapWithADirectionsRenderer = ({
  postcode,
  address,
  branchPostcode,
  googleMapApiKey,
}) => {
  const [response, setResponse] = useState(null);
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [directionsLoaded, setDirectionsLoaded] = useState(false);
  const [destinationCoords, setDestinationCoords] = useState(null);
  const directionsCallback = (result, status) => {
    if (status === 'OK') {
      setResponse(result);
    }
  };

  useEffect(() => {
    setOrigin(postcode);
  }, [postcode]);

  useEffect(() => {
    if (origin && destination) {
      setDirectionsLoaded(false);
    }
  }, [origin, destination]);

  useEffect(() => {
    const flattenAddress = address && address.replace(/(\r\n|\n|\r)/gm, ',');
    const destinationAddress =
      'JJ Foodservice,' + flattenAddress + ', ' + branchPostcode;
    setDestination(destinationAddress);

    const fetchLocations = async destinationAddress => {
      try {
        const destinationLocation = await getGeocode(destinationAddress);
        setDestinationCoords(destinationLocation);
      } catch (error) {
        setDestinationCoords(null);
      }
    };

    fetchLocations(destinationAddress);
  }, [address, branchPostcode]);

  const getGeocode = async address => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: address,
          key: googleMapApiKey,
        },
      }
    );
    if (response.data.results.length > 0) {
      return response.data.results[0].geometry.location;
    }
  };
  const containerStyle = {
    width: '100%',
    height: '100%',
  };
  const onLoad = () => setDirectionsLoaded(true);
  if (!googleMapApiKey) {
    return null;
  }
  return (
    <LoadScript googleMapsApiKey={googleMapApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={MAP_CENTER_LAT_LNG}
        zoom={6}
      >
        {origin !== '' && destination !== '' && !directionsLoaded && (
          <DirectionsService
            options={{
              origin: origin,
              destination: destination,
              travelMode: 'DRIVING',
            }}
            callback={directionsCallback}
            onLoad={onLoad}
          />
        )}
        {response && (
          <DirectionsRenderer
            options={{
              directions: response,
              suppressMarkers: true,
            }}
          />
        )}
        {destinationCoords && (
          <Marker position={destinationCoords} icon={{url: jjIcon}} />
        )}
      </GoogleMap>
    </LoadScript>
  );
};
