import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getJJContentBySlug} from '../../actions/jjContent.action';
import BlogNode from '../../components/BlogNode/BlogNode';
import {Head, MainTitle, PostNode, BlogError} from '../BlogPage/BlogPage.style';
import {Title} from '../../styles/pages/pages';
import {ROUTES} from '../../constants/constants';
import {setCurrentRoute} from '../../actions';
import {replaceContentUploadUrls} from '../../helpers/data.helper';

const JJContentPage = ({match}) => {
  const {isTablet, jjContent, loading} = useSelector(state => ({
    isTablet: state.isTablet,
    jjContent: state.jjContent,
    loading: state.loading,
  }));
  const [isGettingPost, setIsGettingPost] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentRoute(ROUTES.JJCONTENT));
    if (
      match.params.contentSlugName &&
      !isGettingPost &&
      (!jjContent ||
        (jjContent && jjContent.slug !== match.params.contentSlugName))
    ) {
      setIsGettingPost(true);
      dispatch(getJJContentBySlug(match.params.contentSlugName));
    }
  }, [match && match.params, jjContent]);
  useEffect(() => {
    if (jjContent) {
      setIsGettingPost(false);
    }
  }, [jjContent]);

  const renderTitle = jjContent &&
    jjContent.attributes &&
    jjContent.attributes.title && (
      <MainTitle>{jjContent.attributes.title}</MainTitle>
    );
  const renderError = jjContent && jjContent.error && (
    <BlogError>Failed to fetch blog post, please try again.</BlogError>
  );
  const renderNodes = jjContent &&
    jjContent.attributes &&
    jjContent.attributes.content && (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceContentUploadUrls(jjContent.attributes.content),
        }}
      ></div>
    );
  return (
    <>
      <Head data-rw="content title">
        <Title />
      </Head>
      <PostNode>
        {renderTitle}
        {renderError}
        {renderNodes}
      </PostNode>
    </>
  );
};
export default JJContentPage;
