import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function refreshTokenReducer(
  state = initialState.refreshToken,
  action
) {
  switch (action.type) {
    case types.SET_REFRESH_TOKEN_SUCCESS:
      return action.refreshToken;
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
