import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${p => p.theme.tertiaryBgColor};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SectionText = styled.div`
  color: ${p => p.theme.textColor};
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 2rem;
`;

export const ButtonWrapper = styled.div`
  width: 20rem;

  a {
    max-width: 100%;
  }
`;
