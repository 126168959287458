import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function orderReducer(state = initialState.order, action) {
  switch (action.type) {
    case types.GET_ORDER_SUCCEEDED:
    case types.SET_ORDER_AFTER_CHECKOUT:
      return action.order;
    case types.TRACK_ORDER_SUCCEEDED:
      return {...(state && state.order), tracking: action.trackingData};
    case types.TRACK_ORDER_RESET:
      return {...(state && state.order), tracking: null};
    case types.PROCESS_CHECKOUT_REQUESTED: // clear active order while checkout
    case types.SET_PAYMENT_TYPE_REQUESTED:
    case types.REMOVE_AUTH_REQUESTED:
    case types.RESET_ORDERS_SUCCESS:
    case types.GET_INVOICED_ORDER_REQUESTED:
    case types.GET_INVOICED_ORDER_FAILED:
      return null;
    default:
      return state;
  }
}
