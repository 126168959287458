import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3Inverted} from '../../styles/components/buttons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  ${media.small`
    padding: 1rem;
  `};
`;

export const TheButton = styled(Button3Inverted)`
  margin-top: 2rem;
  width: 19rem;
  ${media.smaller`
    width: 100%;
  `};
`;

export const Title = styled.span`
  font-size: 2rem;
  color: ${p => p.theme.secondaryColor};
  text-transform: uppercase;
  padding: 2rem;
`;

export const InfoText = styled.span`
  font-size: 2rem;
  line-height: 4rem;
  color: ${p => p.theme.textColor};
`;
