import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import BranchLocator from '../../components/BranchLocator/BranchLocator';
import {
  getBranchOpeningTimes,
  getNearestBranch,
  setBranch,
  clearInvalidPostcode,
} from '../../actions/branch.action';
import {MapWithADirectionsRenderer} from '../../components/Map/Map';
import {
  Container,
  Wrapper,
  MapContainer,
  BranchContainer,
  Info,
  Details,
  ContactTitle,
  NearbyBranch,
  Address,
  ContactLink,
  InfoLinkWrapper,
  InfoLink,
  InfoTitle,
  OrderNow,
  BranchList,
  Branchs,
  UPPERCASE,
} from './BranchLocatorPage.style';
import Loading from '../../components/Loading/Loading';
import Branch from './Branch';
import {
  ANALYTICS_EVENTS,
  BRANCH_NAME_MAPPING,
  GOOGLE_MAP,
  GOOGLE_STATIC_URL,
  JJ_APOLLO,
  JJ_TEL,
  MIN_ORDER_AMOUNT,
  ROUTES,
  SERVICE_TYPE,
} from '../../constants/constants';
import {
  getKeyCaseInsensitive,
  getPartialPostcode,
  getSelectedBranchData,
  validatePostcode,
} from '../../helpers/data.helper';
import {LoadingContainer} from '../../styles/components/layout';
import {
  toggleChangeBranch,
  toggleMaintenanceModal,
} from '../../actions/modal.action';
import TimeTable from './TimeTable';
import DeliveryHours from './DeliveryHours';
import {setCurrentRoute} from '../../actions';
import {getApiConfig} from '../../config/configProvider';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

class BranchLocatorPage extends PureComponent {
  constructor(props) {
    super(props);
    const isSSR = typeof window === 'undefined';
    const {match, openingTimes} = props;
    this.state = {
      postcode: match.params.postcode,
      branchName: match.params.branchName,
      selected: null,
      selectedBranch:
        openingTimes &&
        openingTimes[
          getKeyCaseInsensitive(BRANCH_NAME_MAPPING, match.params.branchName)
        ],
      chosen: isSSR,
      viewCollection: true,
      isInvalidPostcode: false,
    };
    this.viewOtherBranches = false;
    this.googleMapApiKey = null;
  }

  componentDidMount() {
    if (this.state.postcode) {
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.BRANCH_LOCATOR_SEARCH, {
        postcode: this.state.postcode,
      });
    }
    const {getBranchOpeningTimes, setCurrentRoute} = this.props;
    getBranchOpeningTimes();
    this.getNearestBranch();
    setCurrentRoute(ROUTES.BRANCH_LOCATOR);
    const config = getApiConfig();
    if (config && config.GOOGLE_MAPS_EMBED_API_KEY) {
      this.googleMapApiKey = config.GOOGLE_MAPS_EMBED_API_KEY;
    }
  }

  componentDidUpdate(prevProps, preState) {
    const {branchLocator, branch, match, openingTimes, errors} = this.props;
    const {selectedBranch, isInvalidPostcode} = this.state;
    if (branchLocator && !prevProps.branchLocator && !selectedBranch) {
      this.initBranchRequest = true;
      const selectedBranch = {
        ...branchLocator,
        ...{deliveryOpeningTime: branchLocator.deliveryDays},
      };
      this.setState({selectedBranch});
    }
    if (branchLocator && !prevProps.branchLocator) {
      let theBranch;
      if (branchLocator.inventLocationId) {
        theBranch = branchLocator.inventLocationId;
        this.setState({chosen: false});
      } else if (!isInvalidPostcode) {
        theBranch = branch;
        this.setState({chosen: true});
      }
      if (branchLocator && openingTimes && openingTimes[theBranch]) {
        this.setState({
          selectedBranch: getSelectedBranchData(openingTimes, branchLocator),
          isInvalidPostcode: false,
        });
      }
    }
    if (errors && errors.getNearestBranch && !isInvalidPostcode) {
      this.setState({
        selectedBranch: null,
        isInvalidPostcode: true,
      });
    }
    if (
      match.params.postcode !== this.state.postcode &&
      this.initBranchRequest
    ) {
      this.getNearestBranch();
      this.initBranchRequest = false;
    }
    if (
      !this.state.postcode &&
      this.state.branchName &&
      !this.viewOtherBranches
    ) {
      this.onViewBranchClick(
        getKeyCaseInsensitive(BRANCH_NAME_MAPPING, this.state.branchName)
      );
    }
  }

  onLocatorClick = postcode => {
    const {history, getNearestBranch, cleanMode} = this.props;
    const isCleanMode = cleanMode && '?mode=clean';
    this.setState({postcode}, () => {
      history.push(`/branchLocator/${postcode}${isCleanMode}`);
      const partial = getPartialPostcode(postcode);
      getNearestBranch(partial);
    });
  };

  getNearestBranch = () => {
    const {getNearestBranch} = this.props;
    const postcode = getPartialPostcode(this.state.postcode);
    if (validatePostcode(this.state.postcode)) {
      getNearestBranch(postcode);
    }
  };
  onViewBranchClick = branchId => {
    const {openingTimes, clearInvalidPostcode} = this.props;
    if (!openingTimes || !openingTimes[branchId]) {
      return;
    }
    this.viewOtherBranches = true;
    let newState = {
      chosen: true,
      selectedBranch: openingTimes[branchId],
    };
    if (this.state.isInvalidPostcode) {
      newState = Object.assign({}, newState, {
        isInvalidPostcode: false,
      });
      clearInvalidPostcode();
    }
    this.setState(newState);
    if (window) {
      window.scrollTo(0, 0);
    }
  };
  viewCollectionTimes = () => this.setState({viewCollection: true});
  viewDeliveryTimes = () => this.setState({viewCollection: false});
  orderNow = () => {
    const {
      history,
      basket,
      setBranch,
      systemMessages,
      toggleMaintenanceModal,
    } = this.props;
    const {selectedBranch, viewCollection} = this.state;
    if (!basket && viewCollection) {
      setBranch(selectedBranch.inventLocationId);
    }
    if (
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0
    ) {
      toggleMaintenanceModal(true);
      return;
    }
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return window.ReactNativeWebView.postMessage(
        `BookSlot|${selectedBranch.inventLocationId}`
      );
    }
    history.push({
      pathname: '/bookSlot',
      state: {
        fulfillmentType: SERVICE_TYPE.COLLECTION,
        branchId: selectedBranch.inventLocationId,
      },
    });
  };

  render() {
    const {
      openingTimes,
      auth,
      loading,
      profile,
      branchLocator,
      cleanMode,
    } = this.props;
    const {
      selectedBranch,
      chosen,
      postcode,
      isInvalidPostcode,
      viewCollection,
    } = this.state;
    const isOwnBranch =
      !!selectedBranch &&
      ((profile && profile.branchId === selectedBranch.inventLocationId) ||
        (!auth &&
          branchLocator &&
          selectedBranch.inventLocationId === branchLocator.inventLocationId));
    const selectedBranchZipCode =
      selectedBranch &&
      selectedBranch.address &&
      selectedBranch.address.zipcode;
    const selectedBranchAddress =
      selectedBranch &&
      selectedBranch.address &&
      selectedBranch.address.address;
    const renderLoading = (
      <LoadingContainer>
        <Loading isLight={false} />
      </LoadingContainer>
    );
    const isSearchingPostcode =
      loading && (loading.getNearestBranch || loading.getAllBranch);
    const renderMap = !isSearchingPostcode &&
      !isInvalidPostcode &&
      this.googleMapApiKey &&
      selectedBranch &&
      postcode && (
        <MapWithADirectionsRenderer
          postcode={postcode}
          googleMapApiKey={this.googleMapApiKey}
          address={selectedBranchAddress}
          branchPostcode={selectedBranchZipCode}
        />
      );
    const renderLoadingSpinner = isSearchingPostcode && renderLoading;
    const isShownNotFoundPanel =
      !isSearchingPostcode && !selectedBranch && isInvalidPostcode && postcode;
    const renderNoBranch = isShownNotFoundPanel && (
      <Container>
        <BranchList>Postcode not found, please try another one</BranchList>
      </Container>
    );
    const hasOpeningTimes =
      selectedBranch &&
      selectedBranch.inventLocationId &&
      openingTimes &&
      openingTimes[selectedBranch.inventLocationId];
    const renderCollectionTimes = viewCollection &&
      hasOpeningTimes &&
      openingTimes[selectedBranch.inventLocationId].collectionOpeningTime && (
        <TimeTable
          timetable={
            openingTimes[selectedBranch.inventLocationId].collectionOpeningTime
          }
        />
      );
    const shouldRenderDeliveryTimes =
      !viewCollection &&
      selectedBranch &&
      openingTimes &&
      openingTimes[selectedBranch.inventLocationId].deliveryOpeningTime &&
      hasOpeningTimes &&
      !chosen;
    const renderDeliveryTimes = shouldRenderDeliveryTimes && (
      <TimeTable
        timetable={selectedBranch.deliveryOpeningTime}
        isDelivery={true}
        minOrder={MIN_ORDER_AMOUNT.OTHERS}
      />
    );
    const renderNoDeliveryTimes = shouldRenderDeliveryTimes &&
      selectedBranch &&
      selectedBranch.deliveryOpeningTime &&
      selectedBranch.deliveryOpeningTime.length === 0 &&
      !chosen && (
        <DeliveryHours
          times={
            openingTimes[selectedBranch.inventLocationId].deliveryOpeningTime[0]
          }
          isLoggedIn={!!auth}
          minOrder={MIN_ORDER_AMOUNT.OTHERS}
        />
      );
    const renderOthersDeliveryTimes = !viewCollection &&
      !isOwnBranch &&
      chosen &&
      hasOpeningTimes &&
      openingTimes[selectedBranch.inventLocationId].deliveryOpeningTime && (
        <DeliveryHours
          times={
            openingTimes[selectedBranch.inventLocationId].deliveryOpeningTime[0]
          }
          isLoggedIn={!!auth}
          minOrder={
            MIN_ORDER_AMOUNT[selectedBranch.inventLocationId] ||
            MIN_ORDER_AMOUNT.OTHERS
          }
        />
      );
    const renderStreet = selectedBranchAddress && (
      <Address
        dangerouslySetInnerHTML={{
          __html: selectedBranchAddress.replace(/(\r\n|\n|\r)/gm, '<br />'),
        }}
      />
    );
    const renderContact = selectedBranch && selectedBranch.contact && (
      <Fragment>
        <ContactTitle $gap={true}>Contact</ContactTitle>
        <ContactLink href={`mailto:${selectedBranch.contact.email}`}>
          {selectedBranch.contact.email}
        </ContactLink>
        <ContactLink href={`tel:${selectedBranch.contact.phone}`}>
          {selectedBranch.contact.phone}
        </ContactLink>
      </Fragment>
    );
    const renderBranchInfo =
      loading && !loading.getNearestBranch ? (
        <Fragment>
          {renderStreet}
          <Address>{selectedBranchZipCode}</Address>
          {renderContact}
        </Fragment>
      ) : (
        renderLoading
      );
    const renderBookslotButton = viewCollection && selectedBranch && (
      <OrderNow onClick={this.orderNow}>
        {`Book a collection from ${selectedBranch.branchName}`}
      </OrderNow>
    );
    const renderBranchInfoTitle = chosen ? (
      'Your chosen branch is'
    ) : (
      <Fragment>
        The branch closest to <UPPERCASE>{postcode}</UPPERCASE>
        {' is'}
      </Fragment>
    );
    const renderBranchDetails = selectedBranch && (
      <Container>
        <Wrapper>
          <Info>
            <div>
              <ContactTitle>{renderBranchInfoTitle}:</ContactTitle>
              <NearbyBranch>{selectedBranch.branchName}</NearbyBranch>
              {renderBranchInfo}
            </div>
            <InfoLinkWrapper>
              <InfoLink
                target="_blank"
                href={`${GOOGLE_STATIC_URL}/?api=1&origin=${postcode}&destination=${selectedBranchZipCode}&travelmode=driving`}
              >
                Open in Google Maps
              </InfoLink>
              <InfoLink href={`tel: ${JJ_TEL}`}>Call JJs on {JJ_TEL}</InfoLink>
            </InfoLinkWrapper>
          </Info>
          <Details>
            <InfoTitle>{selectedBranch.branchName} open hours</InfoTitle>
            {/*<BtnWrapper>*/}
            {/*  <FulfillmentBtn*/}
            {/*    $isChecked={viewCollection}*/}
            {/*    fulfillmentType={SERVICE_TYPE.COLLECTION}*/}
            {/*    callback={this.viewCollectionTimes}*/}
            {/*  />*/}
            {/*  <FulfillmentBtn*/}
            {/*    $isChecked={!viewCollection}*/}
            {/*    fulfillmentType={SERVICE_TYPE.DELIVERY}*/}
            {/*    callback={this.viewDeliveryTimes}*/}
            {/*  />*/}
            {/*</BtnWrapper>*/}
            {renderCollectionTimes}
            {renderDeliveryTimes}
            {renderNoDeliveryTimes}
            {renderOthersDeliveryTimes}
            {renderBookslotButton}
          </Details>
        </Wrapper>
      </Container>
    );
    const branchListArray = openingTimes ? Object.entries(openingTimes) : null;
    const renderOtherBranch = branchListArray && branchListArray.length > 0 && (
      <BranchContainer>
        <BranchList>
          <InfoTitle>View {selectedBranch && 'other'} branches</InfoTitle>
          <Branchs>
            {branchListArray.map(branch => (
              <Branch
                key={branch[0]}
                branch={branch[1]}
                callback={this.onViewBranchClick}
              />
            ))}
          </Branchs>
        </BranchList>
      </BranchContainer>
    );
    const renderMapContainer = (!!postcode || !cleanMode) && (
      <MapContainer $notFound={isShownNotFoundPanel}>
        {renderLoadingSpinner}
        {renderMap}
        {renderNoBranch}
      </MapContainer>
    );
    return (
      <Fragment>
        <BranchLocator onLocatorClick={this.onLocatorClick} />
        {renderMapContainer}
        {renderBranchDetails}
        {renderOtherBranch}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
  branch: state.branch,
  basket: state.basket,
  branchLocator: state.branchLocator,
  openingTimes: state.openingTimes,
  auth: state.auth,
  systemMessages: state.systemMessages,
  slots: state.slots,
  profile: state.profile,
  errors: state.errors,
  cleanMode: state.cleanMode,
});

const mapDispatchToProps = dispatch => ({
  getNearestBranch: bindActionCreators(getNearestBranch, dispatch),
  getBranchOpeningTimes: bindActionCreators(getBranchOpeningTimes, dispatch),
  setBranch: bindActionCreators(setBranch, dispatch),
  toggleChangeBranchModal: bindActionCreators(toggleChangeBranch, dispatch),
  toggleMaintenanceModal: bindActionCreators(toggleMaintenanceModal, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  clearInvalidPostcode: bindActionCreators(clearInvalidPostcode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchLocatorPage);
