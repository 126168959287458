import * as ActionTypes from './types.action';

export const getRecommended = (itemId, fullList, branch) => ({
  type: ActionTypes.GET_RECOMMENDED_ITEMS_REQUESTED,
  itemId,
  fullList,
  branch,
});

export const resetRecommended = () => ({
  type: ActionTypes.GET_RECOMMENDED_ITEMS_RESET,
});
