import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';

import {
  ArrowDown,
  ArrowUp,
  BlueButton,
  Cart,
  CartText,
  Container,
  Description,
  DropDown,
  DropDownWrapper,
  InfoText,
  Left,
  LoadingWrapper,
  Menu,
  OldSite,
  Option,
  OptionNoLink,
  OptionRouterLink,
  Quantity,
  Right,
  Wrapper,
} from './TopBar.style';
import Loading from '../Loading/Loading';
import SystemStatus from '../ServerStatus/SystemStatus';
import {getTopBarWelcomeText} from '../../helpers/string.helper';

export default class TopBar extends PureComponent {
  static propTypes = {
    toggleLoginModal: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    navigateToCheckout: PropTypes.func.isRequired,
    navigateToSurvey: PropTypes.func.isRequired,
    navigateToBookSlot: PropTypes.func.isRequired,
    navigateToRegister: PropTypes.func.isRequired,
    openInJJsModal: PropTypes.func.isRequired,
    profile: PropTypes.object,
    isMultiAccounts: PropTypes.bool,
    isStaffAccount: PropTypes.bool,
    showShoppingList: PropTypes.bool,
    userCookie: PropTypes.string,
  };

  state = {
    showDropDown: false,
    staffLoginEmail: '',
  };

  componentDidUpdate(prevProps) {
    const {loginToken, isStaffAccount} = this.props;
    if (loginToken && loginToken !== prevProps.loginToken) {
      const loginObj = jwt_decode(loginToken);
      if (loginObj && loginObj.email) {
        this.setState({staffLoginEmail: loginObj.email});
      }
    }
    if (!loginToken && !!this.state.staffLoginEmail) {
      this.setState({staffLoginEmail: ''});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  toggleDropDown = () =>
    this.setState({showDropDown: !this.state.showDropDown});

  handleClickOutside = e => {
    if (
      this.state.showDropDown === true &&
      this.dropdownRef &&
      !this.dropdownRef.contains(e.target)
    ) {
      this.toggleDropDown();
    }
  };

  switchAccount = () => {
    this.toggleDropDown();
    this.props.toggleSwitchAccountModal(true);
  };

  setRef = node => {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.dropdownRef = node;
  };

  openInJJsModal = () => {
    this.setState({showDropDown: false});
    this.props.openInJJsModal();
  };

  logout = () => {
    this.toggleDropDown();
    this.props.logout();
  };

  gotoRegister = e => {
    e && e.preventDefault();
    this.props.navigateToRegister();
  };

  render() {
    const {
      toggleLoginModal,
      quantity,
      toggleMenu,
      navigateToCheckout,
      branch,
      branchList,
      navigateToBookSlot,
      updatingBasketStatus,
      auth,
      profile,
      isMultiAccounts,
      isStaffAccount,
      systemMessages,
      isMaintenanceMode,
      showShoppingList,
      userCookie,
    } = this.props;
    const {staffLoginEmail} = this.state;
    const isLoggedIn =
      !!auth || (!!staffLoginEmail && isStaffAccount) || userCookie;
    const renderMessage = getTopBarWelcomeText(
      profile,
      isStaffAccount,
      staffLoginEmail,
      isLoggedIn,
      userCookie
    );
    const renderSwitchAccount = (isMultiAccounts || isStaffAccount) && (
      <Option onClick={this.switchAccount}>Switch account</Option>
    );
    const renderShoppingListLink = showShoppingList && (
      <OptionRouterLink to={'/shoppingLists'} onClick={this.toggleDropDown}>
        My shopping lists
      </OptionRouterLink>
    );
    const renderCurrentAccount =
      auth && auth.c_account && `: ${auth.c_account}`;
    const renderDropDown = this.state.showDropDown && (
      <DropDown>
        <OptionNoLink $active={true}>
          Account{renderCurrentAccount}
        </OptionNoLink>
        <OptionRouterLink to={'/orders'} onClick={this.toggleDropDown}>
          My orders
        </OptionRouterLink>
        {renderShoppingListLink}
        <OptionRouterLink to={'/account'} onClick={this.toggleDropDown}>
          My settings
        </OptionRouterLink>
        <OptionRouterLink to={'/reports'} onClick={this.toggleDropDown}>
          View reports
        </OptionRouterLink>
        {renderSwitchAccount}
        <Option onClick={this.logout}>LOGOUT</Option>
      </DropDown>
    );
    const renderArrow = this.state.showDropDown ? <ArrowUp /> : <ArrowDown />;
    const renderStatusButton = isLoggedIn ? (
      <DropDownWrapper ref={this.setRef}>
        <BlueButton onClick={this.toggleDropDown}>
          My account
          {renderArrow}
        </BlueButton>
        {renderDropDown}
      </DropDownWrapper>
    ) : (
      <Fragment>
        <BlueButton
          href="/login"
          onClick={toggleLoginModal}
          $disable={isMaintenanceMode}
        >
          Sign In / Register
        </BlueButton>
      </Fragment>
    );
    const renderCart = updatingBasketStatus ? (
      <LoadingWrapper>
        <Loading isLight={true} />
      </LoadingWrapper>
    ) : (
      <Quantity $isLoading={updatingBasketStatus} data-rw="top-bar--quantity">
        {quantity}
      </Quantity>
    );
    const renderSystemMessage = systemMessages && (
      <SystemStatus systemMessages={systemMessages} />
    );
    return (
      <Wrapper>
        <Container>
          <Left>
            <Menu onClick={toggleMenu} />
            <Description>{renderMessage}</Description>
            {renderStatusButton}
          </Left>
          <Right>
            <div data-nosnippet={true}>
              <OldSite to="/help">
                <div>Having problems?</div>
                Click here for help
              </OldSite>
            </div>
            <InfoText onClick={navigateToBookSlot}>
              {branchList && branchList[branch] && branchList[branch].Name}
            </InfoText>
            <Cart to="/basket">
              {renderCart}
              <CartText data-rw="topbar--my-basket-link">My basket</CartText>
            </Cart>
            <BlueButton
              onClick={navigateToCheckout}
              $disable={isMaintenanceMode}
            >
              Check out
            </BlueButton>
          </Right>
        </Container>
        {renderSystemMessage}
      </Wrapper>
    );
  }
}
