import React, {PureComponent} from 'react';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Yup from 'yup';

import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  DetailsContent,
  ButtonsWrapper,
  Cancel,
  OK,
} from './PopupModal.style';
import {
  CountryCode,
  DetailsWrapper,
  DetailsInput,
  InputsWrapper,
  ErrorsField,
  Error,
} from './PaymentDetailsModal.style';
import {setPaymentDetails} from '../../actions/accounts.action';
import {ONLY_NUMBER_REGEX, POSTCODE_REGEX} from '../../constants/constants';

class PaymentDetailsModal extends PureComponent {
  constructor(props) {
    super(props);
    const {paymentDetails} = props;
    const {
      street1,
      street2,
      city,
      postalCode,
      emailAddress,
      phoneCountryCode,
      phoneNumber,
    } = paymentDetails;
    this.state = {
      street1,
      street2,
      city,
      postalCode,
      emailAddress,
      phoneCountryCode,
      phoneNumber,
    };
  }

  changeDetails = e => {
    this.props.handleSubmit(e);
  };
  onPhoneBlur = e => {
    const {status, handleBlur, setStatus} = this.props;
    if (status && status.countryCodeError) {
      setStatus({countryCodeError: ''});
    }
    handleBlur(e);
  };

  render() {
    const {
      values,
      touched,
      errors,
      closeModal,
      handleBlur,
      showModal,
      handleChange,
      storeErrors,
      status,
      paymentDetails,
    } = this.props;
    const renderNameError = errors.customerName && (
      <Error>{errors.customerName}</Error>
    );
    const renderStreet1Error = errors.street1 && (
      <Error>{errors.street1}</Error>
    );
    const renderStreet2Error = errors.street2 && (
      <Error>{errors.street2}</Error>
    );
    const renderPostalCodeError = errors.postalCode && (
      <Error>{errors.postalCode}</Error>
    );
    const renderCityError = errors.city && <Error>{errors.city}</Error>;
    const renderEmailError = errors.emailAddress && (
      <Error>{errors.emailAddress}</Error>
    );
    const renderPhoneCountryCodeError = errors.phoneCountryCode &&
      touched.phoneCountryCode && <Error>Invalid phone country code</Error>;
    const renderPhoneNumber = errors.phoneNumber && touched.phoneNumber && (
      <Error>Invalid phone number</Error>
    );
    const renderUpdateError = storeErrors && storeErrors.paymentDetails && (
      <Error>Failed to update payment details, please try again</Error>
    );
    const renderPhoneCountryCode = status && status.countryCodeError && (
      <Error>Please enter country code</Error>
    );
    const renderErrors = (
      <ErrorsField>
        {renderUpdateError}
        {renderNameError}
        {renderStreet1Error}
        {renderStreet2Error}
        {renderPostalCodeError}
        {renderCityError}
        {renderEmailError}
        {renderPhoneCountryCodeError}
        {renderPhoneCountryCode}
        {renderPhoneNumber}
      </ErrorsField>
    );
    const isConfirmed = !!(paymentDetails && paymentDetails.confirmed);
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModal}
        data-rw={'billing-modal'}
      >
        <StyledWrapper>
          <Content>
            <DetailsWrapper>
              <Title>{!isConfirmed && 'Confirm '}Your Billing Details</Title>
              <div>
                Your billing details must match with the credit card you are
                using. The name on the card, phone number and the address will
                be checked by the card issuer.
              </div>
              <DetailsContent>
                <DetailsInput
                  id="customerName"
                  type="text"
                  $error={errors.customerName}
                  value={values.customerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="name"
                />
                <DetailsInput
                  id="street1"
                  type="text"
                  $error={errors.street1}
                  value={values.street1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="street 1"
                />
                <DetailsInput
                  id="street2"
                  type="text"
                  $error={errors.street2}
                  value={values.street2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="street 2"
                />
                <DetailsInput
                  id="city"
                  type="text"
                  $error={errors.city}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="city"
                />
                <DetailsInput
                  id="postalCode"
                  type="text"
                  $error={errors.postalCode}
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="postcode"
                />
                <DetailsInput
                  id="emailAddress"
                  type="text"
                  $error={errors.emailAddress}
                  value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="email"
                />
                <InputsWrapper>
                  <CountryCode>
                    <DetailsInput
                      id="phoneCountryCode"
                      $error={errors.phoneCountryCode}
                      type="text"
                      value={values.phoneCountryCode}
                      onChange={handleChange}
                      onBlur={this.onPhoneBlur}
                      placeholder="Country code"
                    />
                  </CountryCode>
                  <DetailsInput
                    id="phoneNumber"
                    type="text"
                    $error={errors.phoneNumber}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={this.onPhoneBlur}
                    placeholder="Phone number"
                  />
                </InputsWrapper>
                {renderErrors}
              </DetailsContent>
              <ButtonsWrapper>
                <OK
                  onClick={this.changeDetails}
                  data-rw="modal--update-payment-details-button"
                >
                  {isConfirmed ? 'Update' : 'Confirm'}
                </OK>
                <Cancel onClick={closeModal}>Cancel</Cancel>
              </ButtonsWrapper>
            </DetailsWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}

const config = {
  mapPropsToValues: props => {
    const paymentDetails = props.paymentDetails;
    const {
      customerName,
      street1,
      street2,
      city,
      postalCode,
      emailAddress,
      phoneCountryCode,
      phoneNumber,
    } = paymentDetails;
    return {
      customerName,
      street1,
      street2,
      city,
      postalCode,
      emailAddress,
      phoneCountryCode,
      phoneNumber,
    };
  },
  validationSchema: Yup.object().shape({
    customerName: Yup.string()
      .trim()
      .max(50, 'Name is too long')
      .required('Name is required'),
    street1: Yup.string()
      .trim()
      .max(50, 'Address 1 is too long')
      .required('Address is required'),
    street2: Yup.string().max(256, 'Address 2 is too long'),
    city: Yup.string()
      .trim()
      .max(50, 'City name is too long')
      .required('City is required'),
    postalCode: Yup.string()
      .max(10, 'Invalid postcode')
      .matches(POSTCODE_REGEX, 'Invalid postcode')
      .required('Postcode is required'),
    emailAddress: Yup.string()
      .trim()
      .email('Invalid email address')
      .required('Email is required'),
    phoneCountryCode: Yup.string()
      .matches(ONLY_NUMBER_REGEX)
      .min(1)
      .max(3, 'Must be 1 to 3 number')
      .required('Country code is required'),
    phoneNumber: Yup.string()
      .matches(ONLY_NUMBER_REGEX)
      .max(11, 'Invalid Phone number')
      .required('Phone number is required'),
  }),
  handleSubmit: (payload, {isSubmitting, setStatus, props}) => {
    if (!!payload.phoneNumber && !payload.phoneCountryCode) {
      setStatus({countryCodeError: 'Please set the country code'});
      return;
    }
    const {
      city,
      customerName,
      emailAddress,
      phoneCountryCode,
      phoneNumber,
      postalCode,
      street1,
      street2,
    } = payload;
    const trimmedPayload = {
      city: city.trim(),
      customerName: customerName.trim(),
      emailAddress: emailAddress.trim(),
      phoneCountryCode,
      phoneNumber: phoneNumber.trim(),
      postalCode: postalCode.trim(),
      street1: street1.trim(),
      street2: street2.trim(),
    };
    if (!isSubmitting) {
      props.setPaymentDetails(trimmedPayload);
    }
  },
  displayName: 'PaymentDetailsForm',
};

const mapStateToProps = state => {
  return {
    storeErrors: state.errors,
    paymentDetails: state.paymentDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  setPaymentDetails: bindActionCreators(setPaymentDetails, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormik(config)(PaymentDetailsModal));
