import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function substitutesReducer(
  state = initialState.substitutes,
  action
) {
  switch (action.type) {
    case types.GET_SUBSTITUTES_SUCCEED:
      return action.substitutes;
    case types.GET_SUBSTITUTES_FAILED:
    case types.REMOVE_AUTH_REQUESTED:
    case types.RESET_SETTING_SUCCESS:
      return null;
    case types.SET_SUBSTITUTE_ITEM_SUCCEEDED:
      const {substitutes} = action;
      let originItemId;
      let subItemId;
      // remove the accepted substitute item from the substitutes if duplicated
      if (substitutes && substitutes[0]) {
        originItemId = substitutes[0].originItemId;
        subItemId = substitutes[0].itemId;
      }
      if (originItemId && subItemId) {
        const {[originItemId]: value, ...updatedSubs} = state;
        if (value) {
          Object.keys(updatedSubs).forEach(subId => {
            updatedSubs[subId] = updatedSubs[subId].filter(
              item => item.itemId !== subItemId
            );
          });
          return updatedSubs;
        }
        return state;
      }
      return state;
    default:
      return state;
  }
}
