export const GQL_GET_RECENTS = `
    query(
        $page: Int
        $size: Int
        $branch: String
        $deliveryDate: Date
        $categoryIds: [String!]
        $brand: String
        $productFeatures: [String!]
        $categoryNames: [String!]
        $sizeOrCut: [String!]
        $isNew: Boolean
        $sortType: String
        $origin: String
    ) {
        previouslyPurchasedProducts(
            page: $page
            size: $size
            branch: $branch
            deliveryDate: $deliveryDate
            categoryIds: $categoryIds
            brand: $brand
            productFeatures: $productFeatures
            categoryNames: $categoryNames
            sizeOrCut: $sizeOrCut
            isNew: $isNew
            sortType: $sortType
            origin: $origin
        ) {
            page {
                number
                size
                totalPages
                totalElements
            }
            results {
                uuid
                itemId
                itemName
                delivery {
                    price
                    priceInc
                    step
                    max
                    unitPriceDisplay
                }
                collection {
                    price
                    priceInc
                    step
                    max
                    unitPriceDisplay
                }
                collectionMultiBuy {
                    price
                    priceInc
                    step
                    min
                    max
                    unitPriceDisplay
                }
                deliveryMultiBuy {
                    price
                    priceInc
                    step
                    min
                    max
                    unitPriceDisplay
                }
                quantityStep
                quantityMax
                productDescription
                offer {
                    onOffer
                    promoTypes
                    promoTag
                    promoTagId
                    promoEnd
                    promoDiscountText
                    promoForCc
                    promoDisAmt
                }
                favourite
                imageLinks
                isAvailable
                tags
                price
            }
            facets {
                name
                data {
                    id
                    name
                    qty
                    unquery
                    query
                    selected
                    counts {
                        id
                        name
                        qty
                        unquery
                        query
                        selected
                        counts {
                        id
                        name
                        qty
                        unquery
                        query
                        selected
                        }
                    }
                }
            }
        }
    }
`;
