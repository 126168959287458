import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function paymentDetailsReducer(
  state = initialState.paymentDetails,
  action
) {
  switch (action.type) {
    case types.GET_PAYMENT_DETAILS_SUCCESS:
    case types.SET_PAYMENT_DETAILS_SUCCESS:
      return action.paymentDetails;
    case types.GET_PAYMENT_DETAILS_FAILED:
    case types.REMOVE_AUTH_REQUESTED:
    case types.SET_LOGIN_TOKEN_SUCCESS:
      return null;
    default:
      return state;
  }
}
