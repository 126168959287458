import {select, delay} from 'redux-saga/effects';

export const waitFor = function*(selector, times, ms) {
  let result;
  let time = 0;
  while (time < times) {
    result = yield select(selector);
    yield delay(ms);
    if (result) {
      return result;
    } else {
      time += 1;
    }
  }
  return null;
};
