import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dropin from 'braintree-web-drop-in';
import AdyenCheckout from '@adyen/adyen-web';

import {
  getInvoicedOrderById,
  getOrderAfterCheckout,
  reAuthOrderPayment,
} from '../../actions/order.action';
import Order from './Order';
import {resetError, setErrorManually} from '../../actions/error.action';
import {Head, Title, Wrapper} from '../../styles/pages/pages';
import {
  OrderError,
  PageContent,
  LoadingWrapper,
  ExtraWrapper,
  PayButtonWrapper,
  SubHead,
  Back,
} from './OrdersPage.style';
import {setLoginRedirect} from '../../actions/loginRedirect.action';
import {setCurrentRoute} from '../../actions';
import {
  ADYEN_ERROR,
  AX_BASKET_STATUS,
  BRAINTREE_TYPES,
  BUTTON_NAME,
  CHECKOUT_RESULT,
  HTTP_STATUS,
  JJ_LOCAL_STORAGE,
  MANUALLY_ERRORS,
  MISSING_ITEM,
  PAYMENT_ERROR_MESSAGE,
  ROUTES,
} from '../../constants/constants';
import {getDataFromLocalStorage} from '../../helpers/localStorage.helper';
import Loading from '../../components/Loading/Loading';
import {getBraintreeInitObj} from '../../helpers/braintree.helper';
import {
  authAdyenPayment,
  authBraintreePayment,
} from '../../actions/checkout.action';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {
  AdyenContent,
  BraintreeDropIn,
  TheButton,
} from '../CheckoutPage/CheckoutPage.style';
import PaymentErrorModal from '../../components/PopupModal/PaymentErrorModal';
import {submitRefundRequest} from '../../actions/email.action';
import PopupModal from '../../components/PopupModal/PopupModal';
import {resetRefundStatus} from '../../actions/status.action';
import {checkIsDev, isReadyToRefund} from '../../helpers/data.helper';
import {checkValueInObject} from '../../helpers/array.helper';
import {getPaymentMethodsConfiguration} from '../../constants/adyen.constants';
import PaymentCommercialCardModal from '../../components/PopupModal/PaymentCommercialCardModal';

class SingleOrder extends PureComponent {
  isGettingOrder = true;
  state = {
    showBraintreeButton: false,
    showAdyenButton: false,
    isSubmittingPayment: false,
    isRefundMode: false,
    isReAuthMode: false,
    accountDetails: null,
    refundItems: {},
    selectedStatus: {},
    isRefundActive: false,
    refundErrors: {},
    refundErrorsDetails: {},
    enableRefundButton: true,
    hasImageSizeError: false,
    isProcessingAdyen: false,
    isProcessingEcoSpend: false,
  };

  async componentDidMount() {
    const {
      match,
      auth,
      getOrderAfterCheckout,
      toggleLoginModal,
      setLoginRedirect,
      nextRoute,
      setCurrentRoute,
      location,
      history,
      isInvoiced,
    } = this.props;
    const storedAuthToken = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.TOKEN
    );
    setCurrentRoute(ROUTES.ORDER);
    if (location && location.state) {
      let state = {...history.location.state};
      if (location.state.setRefundMode) {
        this.setState({isRefundMode: true});
        delete state.setRefundMode;
      }
      if (location.state.setReauthMode) {
        this.setState({isReAuthMode: true});
        delete state.setReauthMode;
      }
      history.replace({...history.location, state});
    }
    if (
      !auth &&
      !storedAuthToken &&
      match &&
      match.params &&
      match.params.orderId &&
      !nextRoute
    ) {
      setLoginRedirect('/order/' + match.params.orderId + '/');
      toggleLoginModal();
      return;
    }
    if (auth && match && match.params && match.params.orderId && !isInvoiced) {
      this.isGettingOrder = false;
      getOrderAfterCheckout(match.params.orderId);
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      errors,
      relogin,
      match,
      getOrderAfterCheckout,
      getInvoicedOrderById,
      auth,
      order,
      checkout,
      nextRoute,
      history,
      isInvoiced,
      authAdyenPayment,
      adyen,
    } = this.props;
    if (errors && errors.requireLogin) {
      resetError();
      relogin();
    }
    if (
      auth &&
      match &&
      match.params &&
      match.params.orderId &&
      this.isGettingOrder
    ) {
      this.isGettingOrder = false;
      const theId = match.params.orderId;
      if (isInvoiced) {
        getInvoicedOrderById(theId, auth.c_account);
      } else {
        getOrderAfterCheckout(theId);
      }
    }
    if (order && !order.couldBeRefunded && this.state.enableRefundButton) {
      this.setState({enableRefundButton: false});
    }
    if (errors && errors.adyen) {
      window.isSubmittingAdyen = false;
      window.isSubmittingAddtional = false;
      this.adyenDropin.update();
    }
    if (
      order &&
      order.status === AX_BASKET_STATUS.PAYMENT_AUTH_REQUIRED &&
      !this.state.isReAuthMode
    ) {
      this.setState({isReAuthMode: true});
    }
    if (
      nextRoute !== prevProps.nextRoute &&
      nextRoute.indexOf(`/confirmation/`) > -1
    ) {
      if (nextRoute.indexOf('&fromApp=true') === -1) {
        history.push({
          pathname: nextRoute,
          state: {
            axBasketId: order.axBasketId,
            orderId: order.orderId,
            fromOrders: true,
          },
        });
      }
    }
    if (auth && order && !this.state.accountDetails) {
      const accountDetails = {
        axBasketId: order.axBasketId || order.salesId,
        email: auth.email,
        accountId: auth.c_account,
        name: auth.display_name,
      };
      this.setState({accountDetails});
    }
    if (adyen && adyen !== prevProps.adyen) {
      this.adyenDropin.handleAction(adyen);
    }
    if (checkout && checkout !== prevProps.checkout && window) {
      if (
        checkout.result === CHECKOUT_RESULT.PAYMENT_ADYEN &&
        !this.state.isProcessingAdyen
      ) {
        this.setState({isProcessingAdyen: true, showAdyenDropin: true});
        if (checkout.paymentMethods && checkout.responseUrl) {
          let paymentMethodsResponse;
          try {
            paymentMethodsResponse = JSON.parse(checkout.paymentMethods);
          } catch (e) {
            captureException(e);
          }
          if (!paymentMethodsResponse) {
            return;
          }
          const configuration = {
            paymentMethodsResponse,
            clientKey: checkout.clientKey,
            locale: 'en-GB',
            environment: checkIsDev(),
            analytics: {
              enabled: true,
            },
            onSubmit: (state, dropin) => {
              if (window.isSubmittingAdyen) {
                return;
              }
              window.isSubmittingAdyen = true;
              authAdyenPayment(checkout.responseUrl, state.data, order.orderId);
            },
            onAdditionalDetails: (state, dropin) => {
              if (window.isSubmittingAdyenAdditonal) {
                return;
              }
              window.isSubmittingAdyenAdditonal = true;
              const isAdditionalDetails = true;
              authAdyenPayment(
                checkout.responseUrl,
                state.data,
                order.orderId,
                isAdditionalDetails
              );
            },
            paymentMethodsConfiguration: getPaymentMethodsConfiguration(
              !!checkout.hideCvc
            ),
          };
          const checkoutAdyen = await AdyenCheckout(configuration);
          this.adyenDropin = checkoutAdyen
            .create('dropin', {
              openFirstPaymentMethod: true,
              showRemovePaymentMethodButton: true,
              onDisableStoredPaymentMethod: (
                storedPaymentMethodId,
                resolve,
                reject
              ) => {
                authAdyenPayment(
                  checkout.responseUrl,
                  null,
                  order.orderId,
                  false,
                  {
                    storedPaymentMethodId,
                    resolve,
                    reject,
                  }
                );
              },
            })
            .mount('#dropin-adyen');
        }
      } else if (
        checkout.result === CHECKOUT_RESULT.PAYMENT_ECOSPEND &&
        checkout.redirectUrl &&
        !this.state.isProcessingEcoSpend &&
        window
      ) {
        this.setState({isProcessingEcoSpend: true});
        window.location.href = checkout.redirectUrl;
      } else {
        const finalPrice = (order.price && order.price.total) || 0;
        const dropinObj = getBraintreeInitObj(
          checkout.clientToken,
          finalPrice,
          checkout.allowPaypal,
          checkout.allowApplePay,
          checkout.allowGooglePay
        );
        dropin.create(dropinObj, (createErr, instance) => {
          if (!instance) {
            return;
          }
          this.setState({showBraintreeButton: true});
          this.braintreeInstance = instance;
          try {
            this.braintreeInstance.on('paymentOptionSelected', data => {
              if (
                data &&
                (data.paymentOption === BRAINTREE_TYPES.APPLE_PAY ||
                  data.paymentOption === BRAINTREE_TYPES.GOOGLE_PAY)
              ) {
                this.setState({showBraintreeButton: false});
              }
            });
            this.braintreeInstance.on('noPaymentMethodRequestable', () => {
              this.setState({showBraintreeButton: false});
            });
            this.braintreeInstance.on('paymentMethodRequestable', event => {
              if (
                event &&
                event.paymentMethodIsSelected &&
                (event.type === BRAINTREE_TYPES.APPLE_PAY_CARD ||
                  event.type === BRAINTREE_TYPES.ANDROID_PAY_CARD)
              ) {
                this.sendNonceToServer();
              } else if (!this.state.showBraintreeButton) {
                this.setState({showBraintreeButton: true});
              }
            });
          } catch (e) {
            this.handleBraintreeFailed(e);
          }
        });
      }
    }
  }

  navToProduct = itemId => this.props.history.push(`/productDetail/${itemId}`);

  goBack = () => this.props.history.push(`/orders`);

  sendNonceToServer = () => {
    if (this.sendingNonce) {
      return;
    }
    this.sendingNonce = true;
    const {checkout, order, authBraintreePayment} = this.props;
    if (!this.braintreeInstance) {
      this.handleBraintreeFailed({
        message: PAYMENT_ERROR_MESSAGE.GENERAL,
      });
      return;
    }
    this.braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: checkout.threeDSecureParameters,
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          this.handleBraintreeFailed(requestPaymentMethodErr);
          return;
        }
        if (payload && payload.nonce) {
          this.setState({isSubmittingPayment: true});
          authBraintreePayment(
            checkout.responseUrl,
            {
              nonce: payload.nonce,
              deviceData: payload.deviceData,
            },
            order.orderId
          );
        } else {
          this.handleBraintreeFailed({
            message: PAYMENT_ERROR_MESSAGE.ANOTHER_METHOD,
          });
        }
      }
    );
  };

  handleBraintreeFailed = errors => {
    this.setState({isSubmittingPayment: false});
    this.sendingNonce = false;
    if (
      this.braintreeInstance &&
      this.braintreeInstance.clearSelectedPaymentMethod
    ) {
      this.braintreeInstance.clearSelectedPaymentMethod();
    }
    this.props.setErrorManually(
      MANUALLY_ERRORS.AUTHORIZE_BRAINTREE_PAYMENT_FAILED,
      [errors]
    );
    GoogleAnalyticsHelper.trackBraintreeError(errors);
  };

  resetBraintreeErrors = () => {
    this.sendingNonce = false;
    this.props.resetError();
  };

  closeRefundModal = () => {
    const {errors, resetRefundStatus} = this.props;
    if (errors && errors.requestRefund !== HTTP_STATUS.EXCEED_SIZE_LIMIT) {
      this.setState({
        isRefundMode: false,
        accountDetails: null,
        refundItems: {},
        selectedStatus: {},
        isRefundActive: false,
        refundErrors: {},
        refundErrorsDetails: {},
        enableRefundButton: false,
      });
    }
    resetRefundStatus();
  };

  toggleImageSizeError = () =>
    this.setState({hasImageSizeError: !this.state.hasImageSizeError});

  doRefund = () => {
    const {isRefundActive, refundItems, selectedStatus} = this.state;
    if (!isRefundActive) {
      return false;
    }
    this.setState({refundErrors: {}, refundErrorsDetails: null});
    const {order, submitRefundRequest} = this.props;
    let itemData = [];
    let refundErrors = {};
    let refundErrorsDetails = {};
    let hasError = false;
    order.items.forEach(item => {
      const itemId = item.itemId;
      if (selectedStatus[itemId]) {
        const refundItem = refundItems[itemId];
        if (isReadyToRefund(refundItem)) {
          itemData.push({...refundItem, ...{itemId}});
          refundErrors[itemId] = false;
          refundErrorsDetails[itemId] = null;
        } else {
          hasError = true;
          refundErrors[itemId] = true;
          if (!refundItem || !refundItem.reason) {
            refundErrorsDetails[itemId] = {reason: true};
          }
          if (
            !refundItem ||
            (!refundItem.photo && refundItem.reason !== MISSING_ITEM)
          ) {
            refundErrorsDetails[itemId] = {
              ...refundErrorsDetails[itemId],
              ...{photo: true},
            };
          }
        }
      } else {
        refundErrors[itemId] = false;
        refundErrorsDetails[itemId] = null;
      }
    });
    if (hasError) {
      this.setState({refundErrors, refundErrorsDetails});
      return;
    }
    if (itemData.length > 0) {
      submitRefundRequest(
        itemData,
        this.state.accountDetails,
        order.axBasketId
      );
    }
  };
  toggleSelect = itemId => {
    const {selectedStatus} = this.state;
    const newStatus = {...selectedStatus, [itemId]: !selectedStatus[itemId]};
    const isRefundActive = checkValueInObject(newStatus, true);
    this.setState({selectedStatus: newStatus, isRefundActive});
  };
  setItemPhoto = (itemId, photo, quantity, fileExt) => {
    const {refundItems, refundErrorsDetails} = this.state;
    const updatedItem = {
      [itemId]: {...refundItems[itemId], ...{photo, quantity, fileExt}},
    };
    const updatedItems = {...refundItems, ...updatedItem};
    let updatedErrorsDetails;
    let newState = {refundItems: updatedItems, hasImageSizeError: false};
    // clear error
    if (
      refundErrorsDetails &&
      refundErrorsDetails[itemId] &&
      refundErrorsDetails[itemId].photo
    ) {
      updatedErrorsDetails = {
        ...refundErrorsDetails,
        ...{[itemId]: {...refundErrorsDetails[itemId], ...{photo: false}}},
      };
    }
    if (updatedErrorsDetails) {
      newState = {...newState, refundErrorsDetails: updatedErrorsDetails};
    }
    this.setState(newState);
  };
  setItemQuantity = (itemId, quantity) => {
    const {refundItems} = this.state;
    const updatedItem = {[itemId]: {...refundItems[itemId], ...{quantity}}};
    const updatedItems = {...refundItems, ...updatedItem};
    this.setState({refundItems: updatedItems});
  };
  setItemRefundReason = (itemId, reason, quantity) => {
    const {refundItems, refundErrorsDetails} = this.state;
    const updatedItem = {
      [itemId]: {...refundItems[itemId], ...{reason, quantity}},
    };
    const updatedItems = {...refundItems, ...updatedItem};
    let newState = {refundItems: updatedItems};
    // clear error
    let updatedErrorsDetails;
    if (refundErrorsDetails[itemId] && refundErrorsDetails[itemId].reason) {
      if (reason === MISSING_ITEM) {
        updatedErrorsDetails = {...refundErrorsDetails, ...{[itemId]: null}};
      } else {
        updatedErrorsDetails = {
          ...refundErrorsDetails,
          ...{[itemId]: {...refundErrorsDetails[itemId], ...{reason: false}}},
        };
      }
    }
    if (updatedErrorsDetails) {
      newState = {...newState, ...{refundErrorsDetails: updatedErrorsDetails}};
    }
    this.setState(newState);
  };
  toggleRefundMode = () =>
    this.setState({isRefundMode: !this.state.isRefundMode});

  resetAdyenErrors = () => this.props.resetError();

  processCommercialCard = () => {
    const {resetError, checkout, authAdyenPayment, order} = this.props;
    resetError();
    authAdyenPayment(checkout.responseUrl, {type: 'ADYEN'}, order.orderId);
  };

  render() {
    const {
      basket,
      order,
      branchList,
      reAuthOrderPayment,
      openStopEditModal,
      errors,
      loading,
      match,
      checkout,
      submitRefundRequest,
      status,
      isInvoiced,
    } = this.props;
    const {
      showBraintreeButton,
      showAdyenButton,
      isRefundMode,
      isReAuthMode,
      refundItems,
      refundErrors,
      refundErrorsDetails,
      selectedStatus,
      isRefundActive,
      enableRefundButton,
      hasImageSizeError,
      isProcessingEcoSpend,
    } = this.state;
    const renderNoOrdersError = errors &&
      (errors.order || errors.invoicedOrder) &&
      loading &&
      !loading.order && (
        <OrderError>
          Failed to get order {match && match.params && match.params.orderId}.
          Please try again.
        </OrderError>
      );
    const renderAdyenErrors = errors &&
      !!errors.adyen &&
      !errors.adyen[ADYEN_ERROR.COMMERCIAL_CARD] && (
        <PaymentErrorModal
          errors={errors.adyen}
          showModal={!!errors.adyen}
          callback={this.resetAdyenErrors}
        />
      );
    const renderAdyenCommercialCard = errors &&
      !!errors.adyen &&
      errors.adyen[ADYEN_ERROR.COMMERCIAL_CARD] && (
        <PaymentCommercialCardModal
          showModal={true}
          closeModalCallback={this.resetAdyenErrors}
          callback={this.processCommercialCard}
          commercialCardFee={errors.adyen.surcharge}
        />
      );
    const renderBtDropin = checkout &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_BRAINTREE && (
        <BraintreeDropIn>
          <div id="dropin-container" />
        </BraintreeDropIn>
      );
    const renderAdyenDropin = checkout &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_ADYEN && (
        <AdyenContent id="dropin-adyen" />
      );
    const renderOrder = order && (
      <Order
        key={order.axBasketId}
        order={order}
        branchList={branchList}
        reOrder={this.showReOrderWarningModal}
        cancelOrder={this.showCancelOrderWarningModal}
        editOrder={this.showEditOrderWarningModal}
        trackOrder={this.trackOrder}
        reAuthOrderPayment={reAuthOrderPayment}
        editing={basket && basket.axBasketId === order.axBasketId}
        openStopEditModal={openStopEditModal}
        alwaysOpen={true}
        loading={loading}
        isReAuthed={showBraintreeButton || showAdyenButton}
        navToProduct={this.navToProduct}
        isRefundMode={isRefundMode}
        isReAuthMode={isReAuthMode}
        toggleRefundMode={this.toggleRefundMode}
        isOrderPage={true}
        submitRefundRequest={submitRefundRequest}
        refundItems={refundItems}
        refundErrors={refundErrors}
        refundErrorsDetails={refundErrorsDetails}
        selectedStatus={selectedStatus}
        isRefundActive={isRefundActive}
        doRefund={this.doRefund}
        toggleSelect={this.toggleSelect}
        setItemPhoto={this.setItemPhoto}
        setItemQuantity={this.setItemQuantity}
        setItemRefundReason={this.setItemRefundReason}
        enableRefundButton={enableRefundButton}
        isInvoiced={isInvoiced}
        hasImageSizeError={hasImageSizeError}
        toggleImageSizeError={this.toggleImageSizeError}
      />
    );
    const renderLoading = (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderCardPaymentButton = order && showBraintreeButton && loading && (
      <PayButtonWrapper>
        <TheButton id="submit-button" onClick={this.sendNonceToServer}>
          {loading.processBraintree ? renderLoading : BUTTON_NAME.PAY_NOW}
        </TheButton>
      </PayButtonWrapper>
    );
    const renderBraintreeErrors = errors &&
      errors.braintree &&
      errors.braintree.length > 0 && (
        <PaymentErrorModal
          isBraintree={true}
          errors={errors.braintree}
          showModal={errors.braintree && errors.braintree.length > 0}
          callback={this.resetBraintreeErrors}
        />
      );
    const hasRefundError = errors && !!errors.requestRefund;
    const renderMessageTitle = hasRefundError
      ? 'Send refund request failed'
      : 'Your request has been sent';
    const renderRefundErrorMessage =
      !!errors && errors.requestRefund === HTTP_STATUS.EXCEED_SIZE_LIMIT
        ? 'Please upload a smaller size photo'
        : 'Please try again or you can contact our customer services. ';
    const renderRefundMessage =
      !hasRefundError &&
      'Customer services will respond to your refund request within the next 48 hours.';
    const renderRefundRequestedModal = ((status && status.refundSent) ||
      hasRefundError) && (
      <PopupModal
        showModal={status.refundSent || hasRefundError}
        title={renderMessageTitle}
        content={renderRefundMessage || renderRefundErrorMessage}
        isError={hasRefundError}
        callback={this.closeRefundModal}
      />
    );
    const renderOrderId =
      order && (isInvoiced ? order.salesId : order.axBasketId);
    return (
      <Wrapper>
        <Head>
          <Title>Order {!!renderOrderId ? ': ' + renderOrderId : ''}</Title>
        </Head>
        <SubHead>
          <Back onClick={this.goBack}>&lt; Back to My Orders</Back>
        </SubHead>
        {renderBraintreeErrors}
        {renderAdyenErrors}
        {renderAdyenCommercialCard}
        {renderRefundRequestedModal}
        <PageContent noMargin>
          {loading && loading.order && renderLoading}
          {!!isProcessingEcoSpend && renderLoading}
          {renderNoOrdersError}
          {!isProcessingEcoSpend && renderOrder}
        </PageContent>
        <ExtraWrapper $alignRight={true}>
          {renderBtDropin}
          {renderAdyenDropin}
          {renderCardPaymentButton}
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    branch: state.branch,
    accounts: state.accounts,
    branchList: state.branchList,
    basket: state.basket,
    order: state.order,
    loading: state.loading,
    auth: state.auth,
    errors: state.errors,
    nextRoute: state.nextRoute,
    checkout: state.checkout,
    route: state.route,
    status: state.status,
    adyen: state.adyen,
  };
};

const mapDispatchToProps = dispatch => ({
  resetError: bindActionCreators(resetError, dispatch),
  getOrderAfterCheckout: bindActionCreators(getOrderAfterCheckout, dispatch),
  reAuthOrderPayment: bindActionCreators(reAuthOrderPayment, dispatch),
  setLoginRedirect: bindActionCreators(setLoginRedirect, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  authBraintreePayment: bindActionCreators(authBraintreePayment, dispatch),
  setErrorManually: bindActionCreators(setErrorManually, dispatch),
  submitRefundRequest: bindActionCreators(submitRefundRequest, dispatch),
  resetRefundStatus: bindActionCreators(resetRefundStatus, dispatch),
  getInvoicedOrderById: bindActionCreators(getInvoicedOrderById, dispatch),
  authAdyenPayment: bindActionCreators(authAdyenPayment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);
