import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {WayLeft, WayRight} from '../../styles/components/arrow';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
`;

export const Text = styled.span`
  padding: 0 0.2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const Previous = styled(WayLeft)`
  cursor: ${p => (p.$disabled ? 'auto' : 'pointer')};
  &:before {
    color: ${p =>
      p.$disabled ? p.theme.tertiaryTextColor : p.theme.primaryColor};
    font-size: 2rem;
  }
`;

export const Next = styled(WayRight)`
  cursor: ${p => (p.$disabled ? 'auto' : 'pointer')};
  &:before {
    color: ${p =>
      p.$disabled ? p.theme.tertiaryTextColor : p.theme.primaryColor};
    font-size: 2rem;
  }
`;
