import CryptoJS from 'crypto-js';
import {getApiConfig, parseConfig} from '../config/configProvider';
import {AZURE_ACCOUNT_TYPES, SPLITTER} from '../constants/constants';
import {getRandomDigit} from './data.helper';

export const getAzureUrl = (params, codeChallenge) =>
  `${params.azureAuthUrl}?client_id=${params.client_id}&response_type=code&redirect_uri=${params.redirectUri}&response_mode=query&scope=${params.scope}&state=${params.state}&code_challenge_method=S256&code_challenge=${codeChallenge}&prompt=${params.prompt}&from_app=${params.from_app}`;

export const generateRandomString = length => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const base64URL = string => {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

export const getLoginParams = (domain, email, redirectAfterLoginUrl) => {
  const config = getApiConfig();
  const verifier = generateRandomString(128);
  const codeChallenge = base64URL(CryptoJS.SHA256(verifier));
  let azureState = AZURE_ACCOUNT_TYPES.B2C + getRandomDigit(7);
  if (redirectAfterLoginUrl) {
    azureState = azureState + SPLITTER.LOGIN_REDIRECT + redirectAfterLoginUrl;
  }
  const redirectUri = `https://${domain}${
    !!email ? config.reLoginRedirectUrl : config.azureRedirectUrl
  }`;
  let authUrl = `${config.azureAuthUrl}/authorize?client_id=${
    config.azureClientId
  }&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${encodeURI(
    config.azureScopes
  )}&state=${azureState}&code_challenge_method=S256&code_challenge=${codeChallenge}&prompt=login`;
  if (email) {
    authUrl = authUrl + `&login_hint=${email}`;
  }
  return {
    verifier,
    authUrl,
  };
};

export const getResetPasswordLink = host => {
  let baseUrl = host || (window && window.location.hostname) || '';
  const config = parseConfig(getApiConfig());
  const redirectUri = `https://${baseUrl}${config.azureRedirectUrl}`;
  return `${config.azureResetPasswordUrl}&client_id=${config.azureResetPasswordClientId}&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid&response_type=id_token&prompt=login`;
};
