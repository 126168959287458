export const getPaymentMethodsConfiguration = hideCVC => ({
  card: {
    hasHolderName: true,
    holderNameRequired: false,
    enableStoreDetails: true,
    billingAddressRequired: false,
    name: 'Credit or Debit Card',
  },
  storedCard: {
    hideCVC,
  },
  threeDS2: {
    challengeWindowSize: '05',
  },
});
