import styled from 'styled-components';
import {LoadingContainer} from '../../styles/components/layout';

export const BraintreeWrapper = styled.div`
  min-width: 100%;
  max-height: 100%;
  padding: 3rem 2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
`;

export const LoadingWrapper = styled(LoadingContainer)`
  margin-top: 12rem;
`;
