import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware, {END} from 'redux-saga';
import {captureException, setTag} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import rootReducer from '../reducers/index.reducer';
import saga from '../saga/saga';

const onError = error => {
  setTag('environment', 'development');
  captureException(error);
};

const sagaMiddleware = createSagaMiddleware({onError});
const sentryMiddleWare = createSentryMiddleware(Sentry, {
  breadcrumbDataFromAction: action => action,
});
const composeEnhancers =
  typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const configureStore = initialState => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sentryMiddleWare, sagaMiddleware))
  );
  sagaMiddleware.run(saga);
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
};
export default configureStore;
