import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {matchPath, withRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import queryString from 'query-string';
import io from 'socket.io-client';
import {Base64} from 'js-base64';
import CookieConsent from 'react-cookie-consent';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

import {getInitData} from '../actions/init.action';
import {getSearchSuggestions, resetSearchSuggestions, search} from '../actions';
import {
  Container,
  ContinueShopping,
  CookieConsentButton,
  CookieConsentContent,
  CookieConsentStyles,
  CookieLink,
  StyledApp,
  SubTitle,
  Text,
  Title,
  Wrapper,
} from './App.style';
import Header from '../components/Header/Header';
import theme from '../styles/theme';

import TopBar from '../components/TopBar/TopBar';
import {AppRoute} from '../routes/Route';
import Footer from '../components/Footer/Footer';
import Menu from '../components/Menu/Menu';
import {
  ACCOUNT_ACTIVATION_CODE,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENTS,
  AUTH_ORIGIN,
  AX_BASKET_ERRORS,
  AX_BASKET_STATUS,
  BRANCH_MAPPING,
  CLOSE_MODAL_DELAY_TIME,
  CONTACT_ADMIN,
  DEFAULT_BRANCH,
  ERRORS,
  HIDE_ZOHO_POPUP_TIMEOUT,
  JJ_COOKIE_CONSENT,
  JJ_COOKIE_CONSENT_EXCLUDED_URLS,
  JJ_LOCAL_STORAGE,
  LOGIN_ERROR_HASH,
  MANUALLY_ERRORS,
  MAX_BASKET_SYNC_RETRIES,
  MAX_SOCKET_RETRY,
  PAGE_DES,
  PASSWORD_RESET_CODE,
  RE_GET_BASKET_TIME,
  REDIRECT_DELAY_TIME,
  REGISTER_CUSTOMER_TYPES,
  RETRY_TIMEOUT,
  ROUTES,
  SEARCH_PARAMS,
  SEARCH_SORT_TYPE,
  SERVICE_TYPE,
  SETTINGS,
  SLOT_FULFILMENT_STATUSES,
  UPDATING_TYPES,
  WEB_MODE,
  WS,
} from '../constants/constants';
import {
  checkBasketSynced,
  deleteAxBasket,
  getBasket,
  removeBasket,
  removeBasketItem,
  setCurrentRoute,
  setSubstituteItem,
  updateBasket,
  updateBasketItem,
} from '../actions';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
  removeValueFromLocalStorage,
  resetCookies,
  resetLocalStorage,
} from '../helpers/localStorage.helper';
import LoginRegister from '../components/LoginRegister/LoginRegister';
import {addressLookup, postcodeLookup} from '../actions/lookup.action';
import {
  getAuth,
  noAuthToken,
  refreshAuth,
  removeAuth,
  removeLoginToken,
  setAuth,
  setLoginToken,
} from '../actions/auth.action';
import {GlobalStyled} from '../styles/global.style';
import {GoogleAnalyticsHelper} from '../helpers/googleAnalytics.helper';
import {axiosHelper} from '../helpers/api.helper';
import {resetError, setErrorManually} from '../actions/error.action';
import {setEventManually} from '../actions/event.action';
import {getBranchList, setBranch} from '../actions/branch.action';
import SwitchAccount from '../components/SwitchAccount/SwitchAccount';
import {
  toggleAvailableOnlyModal,
  toggleCatchWeightModal,
  toggleChangeBranch,
  toggleCustomerSupportModal,
  toggleMaintenanceModal,
  toggleOpenInJJsModal,
  togglePaymentDetails,
  toggleStopEditModal,
  toggleSubstitutesModal,
  toggleSwitchAccount,
  toggleUpsellingModal,
  toggleRatingModal,
  toggleBasketSyncFailedModal,
} from '../actions/modal.action';
import {
  getAccounts,
  getPaymentDetails,
  resetAccounts,
  searchAccounts,
  setAccount,
  setSelectedAccount,
} from '../actions/accounts.action';
import Upselling from './BasketPage/Upselling';
import {setFulfillmentDetails} from '../actions/fulfillmentDetails.action';
import {getUpsellingItems} from '../actions/upselling.action';
import MobileDetect from 'mobile-detect';
import PopupModal from '../components/PopupModal/PopupModal';
import {
  getAccountById,
  getJJDeviceIdCookieValue,
  getSaveSearchKeywords,
  hasMinDeliveryOrderNotMet,
  saveTokenValidTime,
} from '../helpers/data.helper';
import {
  checkIfOnlyContainsOosItems,
  checkSlotExpired,
  getBasketTotalItems,
  getEditOrderTime,
  getItemFulfillmentName,
  getOosItemsSize,
  isBasketSynced,
  isBasketSyncFailed,
  isCatchWeightItemAddedInCurrentSession,
  isDefaultSlotItemWithOnlyOnePrice,
  noPriceForBookedSlotOnItem,
} from '../helpers/basket.helper';
import {setProfile} from '../actions/profile.action';
import {
  getAllSettings,
  resetSetting,
  setSetting,
  updateAllSettings,
} from '../actions/settings.action';
import BasketErrorsModal from '../components/PopupModal/BasketErrorsModal';
import {getApiConfig} from '../config/configProvider';
import ContactAdminModal from '../components/PopupModal/ContactAdminModal';
import SubstitutesModal from './CheckoutPage/SubstitutesModal';
import {removeFulfillSlot} from '../actions/slots.action';
import SlotModal from '../components/PopupModal/SlotModal';
import {getStoredAccountById} from '../helpers/accounts.helper';
import BranchModal from '../components/PopupModal/BranchModal';
import MaintenanceModal from '../components/PopupModal/MaintenanceModal';
import moment from 'moment';
import {getOrderByOrderId} from '../actions/order.action';
import {
  LOGIN_PATH,
  RECOMMENDED_PATH,
  REGISTER_PATH,
  SIGNUP_PATH,
  TRACKING_PATH,
} from '../constants/routePaths';
import {routes} from '../routes/route.config';
import {setPageHeadTags} from '../helpers/seo.helper';
import {captureException} from '@sentry/browser';
import {LinkButton} from '../styles/pages/pages';
import {
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from '../constants/links';
import {parse} from 'querystring';
import PaymentDetailsModal from '../components/PopupModal/PaymentDetailsModal';
import {getCallbackUrlFromUrl} from '../helpers/string.helper';
import {setCleanMode} from '../actions/cleanMode.action';
import RatingsFeedback from '../components/RatingsFeedback/RatingsFeedback';
import {getLoginParams} from '../helpers/azure.helper';
import {
  getDataFromSessionStorage,
  resetSessionStorage,
} from '../helpers/sessionStorage.helper';
import {getConfigManually} from '../helpers/config.helper';
import SmartBanner from 'react-smartbanner';
import {getCookie} from '../helpers/cookie.helper';
import {resetNextRoute} from '../actions/nextRoute.action';

export class App extends PureComponent {
  constructor(props) {
    super(props);
    let parsedHash;
    let hasHashError;
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.hash
    ) {
      parsedHash = parse(window.location.hash);
      hasHashError = props.location.hash.indexOf(LOGIN_ERROR_HASH) > -1;
    }
    this.initalSettingsRequested = false;
    this.initalAccountsRequest = false;
    this.switchAccount = false;
    this.showChangeBracnModal = false;
    this.editOrderTimer = null;
    this.editOrderExpiredTimer = null;
    this.socketRetries = 0;
    this.shouldReGetBasket = false;
    this.isFirstSearch = true;
    this.ratingModalIsShown = false;
    this.ratingModalTimer = null;
    this.stopEditTimer = null;
    this.isBranchSetFromUrl = false;
    this.state = {
      errorOccurred: false,
      showLoginModal: props.location.pathname === LOGIN_PATH,
      showRegisterModal: props.location.pathname === REGISTER_PATH,
      showMenuList: false,
      isLoggedIn: false,
      updatingItemBeforeLogin: null,
      serverError: false,
      basketServerError: false,
      contactAdmin: null,
      basketUuid: null,
      accountError: false,
      noActiveAccount: false,
      basketError: null,
      azureError: false,
      showSlotModal: false,
      invalidBasketError: false,
      basketAlreadyPickedError: false,
      axBasketNotFoundError: false,
      basketServerSyncError: false,
      showEditOrderTimeoutModal: false,
      showEditOrderExpiredModal: false,
      hashError: hasHashError && {
        details: parsedHash.error_description,
      },
      isDeliveryDisabled: false,
    };
    if (
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.userAgent
    ) {
      this.mobileDetect = new MobileDetect(window.navigator.userAgent);
      this.deviceInfo = {
        isPhone: !!this.mobileDetect.phone(),
        isTablet: !!this.mobileDetect.tablet(),
        isIPhone: this.mobileDetect.is('iPhone'),
        isIos: this.mobileDetect.os() === 'iOS',
        userAgent: this.mobileDetect.userAgent(),
      };

      // TODO remove this when new API is ready and email is redirecting to the right URL
      if (props.location.hash.indexOf(PASSWORD_RESET_CODE) > -1) {
        const parsedHash = queryString.parse(location.hash);
        props.history.push(`/passwordReset/${parsedHash.passwordresetcode}`);
      }

      if (props.location.hash.indexOf(ACCOUNT_ACTIVATION_CODE) > -1) {
        const parsedHash = queryString.parse(location.hash);
        props.history.push(
          `/activateAccount/${parsedHash.accountactivationcode}`
        );
      }
      if (props.location && props.location.search) {
        const params = queryString.parse(props.location.search);
        if (params.mode === WEB_MODE.CLEAN) {
          props.setCleanMode();
        }
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {errors, basket} = props;
    if (errors) {
      if (errors.serverError && !state.serverError) {
        return {
          serverError: true,
        };
      }
      if (errors.contactAdmin && !state.contactAdmin) {
        return {
          contactAdmin: errors.contactAdmin,
        };
      }
      if (errors.getAccountFailed && !state.accountError) {
        return {
          accountError: true,
        };
      }
      if (errors.noActiveAccount && !state.noActiveAccount) {
        return {
          noActiveAccount: true,
        };
      }
      if (errors.updateBasket && !state.basketError) {
        return {
          basketError: errors.updateBasket,
        };
      }
      if (errors.invalidBasket) {
        return {
          invalidBasketError: true,
        };
      }
      if (errors.basketAlreadyPicked) {
        return {
          basketAlreadyPickedError: true,
        };
      }
      if (errors.basketNotEditable) {
        return {
          basketNotEditableError: true,
        };
      }
      if (errors.axBasketNotFound) {
        return {
          axBasketNotFoundError: true,
        };
      }
      if (
        (errors && errors.basketServerSyncRetries > MAX_BASKET_SYNC_RETRIES) ||
        errors.checkoutServerError
      ) {
        return {
          basketServerSyncError: true,
        };
      }
      if (!basket && state.basketUuid) {
        //clean up the state basketUuid when basket is wiped
        return {
          basketUuid: null,
        };
      }
      if (errors.azure) {
        return {
          azureError: true,
        };
      }
    }
    return null;
  }

  componentDidCatch(error, info) {
    if (!this.state.errorOccurred) {
      this.setState({errorOccurred: true});
    }
    captureException(error);
    GoogleAnalyticsHelper.logError(
      ANALYTICS_EVENT_CATEGORIES.JAVASCRIPT_ERROR,
      error
    );
  }

  hideZohoTextPopup = () => {
    const el = document.getElementById('titlediv');
    if (el && el.style) {
      el.style.display = 'none';
    }
  };

  async componentDidMount() {
    if (!window.serverConfig) {
      await getConfigManually();
    }
    this.hideZohoTimer = setTimeout(
      this.hideZohoTextPopup,
      HIDE_ZOHO_POPUP_TIMEOUT
    );
    const {
      setAuthToken,
      getAllBranches,
      getAccounts,
      setFulfillmentDetails,
      getAuthToken,
      noAuthToken,
      setLoginToken,
    } = this.props;
    await axiosHelper(axios, setAuthToken);
    getAuthToken();
    getAllBranches();
    this.getInitData();
    const storedFulfillmentDetails = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.FULFILLMENT
    );
    if (storedFulfillmentDetails) {
      setFulfillmentDetails(storedFulfillmentDetails);
    }
    const storedAuthToken = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.TOKEN
    );
    const loginToken = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.LOGIN_TOKEN
    );
    if (loginToken) {
      setLoginToken(loginToken);
      const fromCache = true;
      getAccounts(loginToken, fromCache);
    }
    // websocket
    let query;
    let auth;
    if (storedAuthToken) {
      query = {token: storedAuthToken};
      auth = {
        access_token: storedAuthToken,
        ...jwt_decode(storedAuthToken),
      };
    }
    this.initWS(query);
    const expiredTimestamp = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.TOKEN_VALID_UNTIL
    );
    const now = new Date().getTime();
    // only set the token if its not expired
    if (auth && auth.exp) {
      // refresh token 5 mins before it expired
      if (
        expiredTimestamp * 1000 - now < 5 * 60 * 1000 ||
        auth.exp * 1000 < new Date().getTime()
      ) {
        return this.toRefreshToken();
      } else {
        setAuthToken(auth);
      }
    } else if (!loginToken) {
      noAuthToken();
    }
  }

  componentDidUpdate(prevProps, preState) {
    const {
      branch,
      location,
      errors,
      resetError,
      auth,
      loginToken,
      accounts,
      toggleSwitchAccountModal,
      basket,
      getBasket,
      profile,
      setProfile,
      settings,
      getAllSettings,
      setSetting,
      getAccounts,
      route,
      history,
      setBranch,
      loading,
      reloginAccount,
      nextRoute,
      paymentDetails,
      getPaymentDetails,
      toggleRatingModal,
      resetNextRoute,
    } = this.props;

    if (route !== prevProps.route && typeof window !== 'undefined') {
      if (route !== ROUTES.PRODUCT && route !== ROUTES.BASKET) {
        // logPageView for /basket and /product are in the respective pages
        GoogleAnalyticsHelper.logPageView();
      }
      if (prevProps.route) {
        document.title = PAGE_DES.TITLE;
      }

      const routeObj = routes.filter(
        r => r.path && r.path.toString().indexOf(route) > -1
      )[0];
      if (routeObj && routeObj.loadData) {
        const match = matchPath(location.pathname, routeObj);
        routeObj.loadData(match).then(og => {
          if (og) {
            // only set head tags when og available
            setPageHeadTags(og);
          }
        });
        // SearchPage has it's own loadData in its ComponentDidUpdate
      }
    }
    if (location.pathname === RECOMMENDED_PATH) {
      history.replace('/');
      this.navigateToCheckout();
    }
    if (
      (basket && basket.axBasketId && prevProps.basket === null) ||
      (basket &&
        basket.axBasketId &&
        prevProps.basket &&
        prevProps.basket.axBasketId !== basket.axBasketId)
    ) {
      GoogleAnalyticsHelper.setUserInfo({axBasketId: basket.axBasketId});
    }
    if (
      auth &&
      auth.c_account &&
      auth.c_account !== (prevProps.auth && prevProps.auth.c_account)
    ) {
      GoogleAnalyticsHelper.setUserInfo({c_account: auth.c_account});
    }
    if (
      auth &&
      auth.login_account &&
      auth.login_account !== (prevProps.auth && prevProps.auth.login_account)
    ) {
      GoogleAnalyticsHelper.setUserInfo({l_account: auth.login_account});
    }
    if (
      profile &&
      profile.customerType !==
        (prevProps.profile && prevProps.profile.customerType)
    ) {
      GoogleAnalyticsHelper.setUserInfo({customerType: profile.customerType});
    }
    if (
      location.pathname === LOGIN_PATH &&
      location.pathname !== prevProps.location.pathname &&
      !this.state.showLoginModal
    ) {
      this.setState({showLoginModal: true});
    }
    if (
      !auth &&
      location.pathname === REGISTER_PATH &&
      (!this.state.showLoginModal || !this.state.showRegisterModal)
    ) {
      this.setState({showLoginModal: true, showRegisterModal: true});
    }
    if (!auth && location.pathname === SIGNUP_PATH) {
      // redirect to sign in/register when its /register
      const loginRegisterParams = getLoginParams(
        window.location.hostname,
        null,
        null
      );
      window.location = loginRegisterParams.authUrl;
    }
    if (
      auth &&
      (!!this.state.showLoginModal || !!this.state.showRegisterModal) &&
      (location.pathname === LOGIN_PATH || location.pathname === REGISTER_PATH)
    ) {
      this.setState({showLoginModal: false, showRegisterModal: false});
      history.push('/');
    }
    if (branch !== prevProps.branch) {
      this.getInitData();
    }
    if (auth && prevProps.auth !== auth && auth.enhanced) {
      this.getRefreshToken();
      if (this.state.updatingItemBeforeLogin) {
        this.updateOutstandingBasketRequest();
        this.setState({updatingItemBeforeLogin: null});
      }
      this.reconnectWS(true);
      if (nextRoute && nextRoute.indexOf(TRACKING_PATH) === 0) {
        // redirect to tracking page after login
        history.push(nextRoute);
      }
    }
    if (nextRoute && nextRoute.indexOf('&fromApp=true') > 0) {
      const callbackUrl = getCallbackUrlFromUrl(nextRoute);
      if (window && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(callbackUrl);
      } else {
        captureException(new Error('ReactNativeWebView is not supported'));
      }
    }
    if (nextRoute && nextRoute === ROUTES.BOOK_SLOT) {
      resetNextRoute();
      this.navigateToBookSlot();
    }
    if (
      prevProps.profile &&
      profile &&
      auth &&
      auth.c_account === profile.id &&
      this.switchAccount
    ) {
      this.switchAccount = false;
      getAllSettings();
      if (
        route !== ROUTES.HOME &&
        route !== ROUTES.ORDERS &&
        route !== ROUTES.SETTINGS
      ) {
        history.push('/');
      }
    }
    if (
      ((profile && profile !== prevProps.profile) || !paymentDetails) &&
      !this.gettingPaymentDetail &&
      auth &&
      auth.enhanced
    ) {
      this.gettingPaymentDetail = true;
      getPaymentDetails();
    }
    if (
      basket &&
      basket.uuid &&
      auth &&
      auth.enhanced &&
      !this.state.basketUuid
    ) {
      this.setState({basketUuid: basket.uuid});
    }
    if (errors && errors.basketServerError) {
      if (
        ((prevProps.errors && !prevProps.errors.basketServerError) ||
          this.shouldReGetBasket) &&
        !this.state.basketServerError
      ) {
        this.setState({basketServerError: true});
        this.shouldReGetBasket = false;
      }
    }
    if (
      auth &&
      auth.enhanced &&
      !basket &&
      !this.state.basketUuid &&
      route !== ROUTES.CONFIRMATION &&
      route !== ROUTES.CHECKOUT &&
      route !== ROUTES.BASKET &&
      loading &&
      !loading.gettingBasket &&
      !(errors && errors.basketServerError)
    ) {
      getBasket();
    }
    let newBranch = DEFAULT_BRANCH;
    if (
      !basket &&
      profile &&
      settings &&
      settings.fulfillment === SERVICE_TYPE.DELIVERY
    ) {
      newBranch = profile.branchId;
    } else {
      const params = queryString.parse(location.search);
      newBranch =
        (basket && basket.branchId) ||
        (settings && settings.branchId) ||
        (profile && profile.branchId) ||
        branch;
      // load branch from url only on first cycle of CDU - to avoid conflict with changing branch manually
      // it makes infinite loop changing branch back and forth cause of the URL param change
      if (!this.isBranchSetFromUrl && params && params.b) {
        newBranch = params.b;
      }
      this.isBranchSetFromUrl = true;
    }
    if (newBranch && newBranch !== branch) {
      setBranch(newBranch, () => this.getInitData(newBranch));
    }
    if (
      !accounts &&
      auth &&
      prevProps.auth !== auth &&
      !this.initalAccountsRequest
    ) {
      if (
        auth.exp * 1000 > new Date().getTime() ||
        auth.origin === AUTH_ORIGIN.REFRESH
      ) {
        this.initalAccountsRequest = true;
        getAccounts(auth, false);
      }
    }
    if (accounts && accounts.length > 0 && auth && auth.enhanced && !profile) {
      const account = getAccountById(accounts, auth.c_account);
      if (account) {
        setProfile(account);
      }
    }
    if (auth && auth.enhanced && !settings && !this.initalSettingsRequested) {
      this.initalSettingsRequested = true;
      getAllSettings();
    }
    if (accounts && !!loginToken && !prevProps.accounts) {
      if (accounts.length === 1) {
        this.selectAccount(accounts[0]);
      } else if (!!reloginAccount) {
        const storedAccounts = getDataFromLocalStorage(
          JJ_LOCAL_STORAGE.ACCOUNTS
        );
        if (!storedAccounts) {
          toggleSwitchAccountModal(true);
        }
        const matchingAccount = getStoredAccountById(
          storedAccounts,
          reloginAccount
        );
        if (matchingAccount) {
          this.selectAccount(matchingAccount);
        } else {
          toggleSwitchAccountModal(true);
        }
      } else if (loginToken && !auth) {
        toggleSwitchAccountModal(true);
      }
    }
    if (
      !this.state.showSlotModal &&
      basket &&
      basket.items &&
      basket.items.length > 0 &&
      (basket.fulfillmentStatus ===
        SLOT_FULFILMENT_STATUSES.SLOT_NOT_AVAILABLE ||
        basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.SLOT_ADJUSTED ||
        basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.TYPE_ADJUSTED) &&
      basket.fulfillmentStatus !==
        (prevProps && prevProps.basket && prevProps.basket.fulfillmentStatus)
    ) {
      this.setState({showSlotModal: true});
    }

    // remove basket if editing a basket and ax basket is expired
    if (
      basket &&
      basket.editMode &&
      basket.syncStatus === AX_BASKET_STATUS.EXPIRED
    ) {
      this.cleanBasket();
      history.push(`/`);
    }
    if (
      auth &&
      settings &&
      basket &&
      basket.editMode &&
      isBasketSynced(basket.syncStatus) &&
      !this.state.showEditOrderExpiredModal
    ) {
      if (settings.editOrderTime) {
        const storedTime = moment.unix(settings.editOrderTime);
        const time = moment(storedTime).diff(moment(new Date()));

        if (window && window.document) {
          document.title = PAGE_DES.TITLE;
        }
        if (time < 0) {
          return this.setState({
            showEditOrderExpiredModal: true,
          });
        } else {
          const editOrderTimeout = time - 10 * 60 * 1000; // show warning 10 minutes before 2 hr expiration
          if (editOrderTimeout > 0) {
            clearTimeout(this.editOrderTimer);
            this.editOrderTimer = setTimeout(() => {
              this.setState({
                showEditOrderTimeoutModal: true,
              });
              if (window && window.document) {
                document.title = `${PAGE_DES.TITLE} - Check out soon!`;
              }
            }, editOrderTimeout);
          }
          clearTimeout(this.editOrderExpiredTimer);
          this.editOrderExpiredTimer = setTimeout(() => {
            this.setState({
              showEditOrderExpiredModal: true,
            });
          }, time);
        }
      } else {
        if (loading && !loading.settings) {
          const editOrderTime = getEditOrderTime(basket.cutOffDateTime).unix();
          setSetting(SETTINGS.EDIT_ORDER_TIME, editOrderTime);
        }
      }
    } else {
      clearTimeout(this.editOrderTimer);
      clearTimeout(this.editOrderExpiredTimer);
    }
    if (basket && isBasketSyncFailed(basket.syncStatus)) {
      this.props.toggleBasketSyncFailedModal(true);
    }
    if (
      auth &&
      auth.enhanced &&
      !this.ratingModalIsShown &&
      settings &&
      settings.showRatings === 'false'
    ) {
      this.ratingModalIsShown = true;
      toggleRatingModal(true);
    }
    if (
      profile &&
      profile.customerType === REGISTER_CUSTOMER_TYPES.OVE &&
      this.state.contactAdmin === CONTACT_ADMIN.LOW &&
      !this.state.isDeliveryDisabled
    ) {
      this.setState({isDeliveryDisabled: true});
    }

    if (errors) {
      if (errors.requireLogin) {
        resetError();
        this.relogin();
      }
      if (errors.forceLogout) {
        resetError();
        this.logout();
      }
      if (errors.token) {
        switch (errors.token.type) {
          case UPDATING_TYPES.BASKET: {
            this.loginWithItem({
              type: UPDATING_TYPES.BASKET,
              item: errors.token.item,
            });
            break;
          }
          case UPDATING_TYPES.ITEM: {
            this.loginWithItem({
              type: UPDATING_TYPES.ITEM,
              uuid: errors.token.uuid,
              params: errors.token.params,
            });
            break;
          }
          case UPDATING_TYPES.REMOVE: {
            this.loginWithItem({
              type: UPDATING_TYPES.REMOVE,
              uuid: errors.token.uuid,
            });
            break;
          }
        }
        resetError();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutLogin);
    clearTimeout(this.tokenTimeout);
    clearTimeout(this.hideZohoTimer);
    if (this.connectTimeout) {
      clearTimeout(this.connectTimeout);
    }
    if (this.editOrderTimer) {
      clearTimeout(this.editOrderTimer);
    }
    if (this.editOrderExpiredTimer) {
      clearTimeout(this.editOrderExpiredTimer);
    }
    if (this.stopEditTimer) {
      clearTimeout(this.stopEditTimer);
    }
    if (this.ratingModalTimer) {
      clearTimeout(this.ratingModalTimer);
    }
  }

  resetGenericError = () => this.setState({errorOccurred: false});

  getInitData = newBranch => {
    const {getInitData, branch} = this.props;
    getInitData({branch: newBranch || branch});
  };

  toRefreshToken = async () => {
    const {freshAuthToken, refreshToken} = this.props;
    let refreshTokenString;
    if (refreshToken) {
      refreshTokenString = refreshToken;
    } else {
      refreshTokenString = await getDataFromLocalStorage(
        JJ_LOCAL_STORAGE.REFRESH_TOKEN
      );
    }
    if (!refreshTokenString) {
      this.logout();
      return;
    }
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN_VALID_UNTIL);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.REFRESH_TOKEN);
    freshAuthToken(refreshTokenString);
  };

  initWS = query => {
    const config = getApiConfig();
    if (!config) {
      return;
    }
    const newQuery = {
      ...query,
      ...{source: 'web', version: config.releaseVersion},
    };
    this.socketRetries = 0;
    this.ws = io(config.socketUrl, {
      transports: ['websocket', 'polling'],
      query: newQuery,
    });
    this.ws.on(WS.ORDER_UPDATED, data => this.handleOrderUpdated(data));
    this.ws.on(WS.BASKET_SYNC_UPDATED, data =>
      this.handleBasketSyncUpdated(data)
    );
    this.ws.on(
      WS.FORCE_RELOAD,
      () => (window.location.href = window.location.href)
    );
    this.ws.on(WS.BASKET_DELETED, data => this.handleBasketDelete(data));
    this.ws.on(WS.PROFILE_RELOAD, () => this.props.getAllSettings());
    this.ws.on(WS.ANNOUNCEMENT_MESSAGE, data => {
      const {setEventManually, setErrorManually} = this.props;
      let messages;
      try {
        messages = JSON.parse(data);
      } catch (e) {
        setErrorManually(MANUALLY_ERRORS.SET_ANNOUNCEMENT_MESSAGES_FAILED);
      }
      if (messages) {
        setEventManually(
          MANUALLY_ERRORS.SET_ANNOUNCEMENT_MESSAGES_SUCCESS,
          messages
        );
      }
    });
    this.ws.on(WS.MAINTENANCE_MESSAGE, data => {
      const {setEventManually, setErrorManually} = this.props;
      let messages;
      try {
        messages = JSON.parse(data);
      } catch (e) {
        setErrorManually(MANUALLY_ERRORS.SET_MAINTENANCE_MESSAGES_FAILED);
      }
      if (messages) {
        setEventManually(
          MANUALLY_ERRORS.SET_MAINTENANCE_MESSAGES_SUCCESS,
          messages
        );
      }
    });
    this.ws.on(WS.ERROR, err => this.handleWebSocketError(err));
    this.ws.on(WS.CONNECT_ERROR, err => this.handleWebSocketError(err));
    this.ws.on(WS.CONNECT_FAILED, err => {});
    this.ws.on(WS.CONNECT_TIMEOUT, err => {});
  };

  // reconnect the WS every time while user login/logout
  reconnectWS = (immediately, isLogout) => {
    const {auth} = this.props;
    const time = immediately ? 0 : RETRY_TIMEOUT;
    clearTimeout(this.connectTimeout);
    if (!this.ws) {
      return;
    }
    this.ws.disconnect();
    const config = getApiConfig();
    const query = !!auth && !isLogout ? {token: auth.access_token} : null;
    const newQuery = {
      ...query,
      ...{source: 'web', version: config && config.releaseVersion},
    };
    this.ws.query = newQuery;
    this.ws.io.opts.query = newQuery;
    this.connectTimeout = setTimeout(() => this.ws.connect(), time);
  };

  handleOrderUpdated = data => {
    const event = JSON.parse(data);
    const {order, location, getOrderByOrderId} = this.props;
    if (
      event &&
      event.orderId &&
      (location.pathname.indexOf(event.orderId) > -1 ||
        (order && event.basketId === order.uuid))
    ) {
      getOrderByOrderId(event.orderId);
    }
  };

  handleBasketSyncUpdated = data => {
    const {
      basket,
      checkBasketSynced,
      checkout,
      history,
      route,
      getBasket,
    } = this.props;
    // redirect to basket page if basket items are updated
    if (checkout && route === ROUTES.CHECKOUT) {
      history.push('/basket');
      return;
    }
    let requestingBasket = false;
    const event = JSON.parse(data);
    if (route !== ROUTES.CONFIRMATION && route !== ROUTES.CHECKOUT) {
      const silentlyFetchBasket = true;
      requestingBasket = true;
      const onlyUpdateTotalPrice =
        event.status === AX_BASKET_STATUS.IN_PROGRESS;
      let getFullBasket = route === ROUTES.BASKET && !onlyUpdateTotalPrice;
      const fromWebSocket = true;
      getBasket(
        getFullBasket,
        silentlyFetchBasket,
        onlyUpdateTotalPrice,
        fromWebSocket
      );
    }
    if (requestingBasket) {
      return;
    }
    if (
      basket &&
      event.basketId === basket.uuid &&
      event.status === AX_BASKET_STATUS.SYNCED
    ) {
      checkBasketSynced();
    }
  };

  handleBasketDelete = data => {
    const {basket, history, route, nextRoute} = this.props;
    const event = JSON.parse(data || {});
    if (event && basket && event.basketId === basket.uuid) {
      if (route === ROUTES.CHECKOUT) {
        this.removeBasketAsync();
        if (!nextRoute) {
          history.push('/basket');
        }
      } else {
        this.removeBasketAsync();
      }
    }
  };

  handleWebSocketError = err => {
    if (err === ERRORS.INVALID_TOKEN) {
      this.socketRetries += 1;
      if (this.socketRetries > MAX_SOCKET_RETRY) {
        return;
      }
      return this.toRefreshToken();
    } else if (err !== ERRORS.INVALID_TOKEN || err !== ERRORS.WEB_SOCKET) {
      captureException(err);
    }
  };

  getRefreshToken = () => {
    const {auth} = this.props;
    if (!auth || !(auth && auth.exp)) {
      return;
    }

    if (this.tokenTimeout) {
      clearInterval(this.tokenTimeout);
    }
    if (auth.expires_in) {
      this.tokenTimeout = setTimeout(
        this.toRefreshToken,
        auth.expires_in * 1000
      );
    }
  };

  toggleMenu = () => {
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.BROWSE_MENU, {
      category: ANALYTICS_EVENT_CATEGORIES.MENU,
    });
    this.setState({showMenuList: !this.state.showMenuList});
  };

  closeMenu = () => this.setState({showMenuList: false});

  logoutReset = () => {
    const {setBranch, removeAuthToken, removeLoginToken} = this.props;
    setBranch(DEFAULT_BRANCH);
    this.initalSettingsRequested = false;
    this.initalAccountsRequest = false;
    if (this.editOrderTimer) {
      clearTimeout(this.editOrderTimer);
    }
    if (this.editOrderExpiredTimer) {
      clearTimeout(this.editOrderExpiredTimer);
    }
    this.setState({basketUuid: false});
    removeAuthToken();
    removeLoginToken();
    GoogleAnalyticsHelper.clearUserInfo();
  };

  logout = () => {
    const {modal, toggleSwitchAccountModal} = this.props;
    if (modal && modal.switchAccount) {
      toggleSwitchAccountModal(false);
    }
    resetLocalStorage();
    resetSessionStorage();
    resetCookies();
    this.logoutReset();
    this.reconnectWS(true, true);
    this.props.history.push('/');
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.LOGOUT);
  };

  relogin = () => {
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN_VALID_UNTIL);
    this.logoutReset();
    this.timeoutLogin = setTimeout(
      () => this.setState({showLoginModal: true}),
      100
    );
  };

  //todo: error popup
  onLoginFailed = e => {
    this.setState({showLoginModal: false});
    throw e;
  };

  doUpdateBasket = (item, analyticsObj) => {
    const {
      auth,
      basket,
      updateBasket,
      systemMessages,
      toggleMaintenanceModal,
      toggleAvailableOnlyModal,
      toggleCatchWeightModal,
      toggleCustomerSupportModal,
      settings,
      basketHashMap,
    } = this.props;
    if (!auth) {
      if (
        systemMessages &&
        systemMessages.maintenance &&
        systemMessages.maintenance.length > 0
      ) {
        toggleMaintenanceModal(true);
        return;
      }
      this.loginWithItem({
        type: UPDATING_TYPES.BASKET,
        item,
      });
      return;
    }
    if (
      isDefaultSlotItemWithOnlyOnePrice(basket, item, settings) ||
      noPriceForBookedSlotOnItem(basket, item)
    ) {
      toggleAvailableOnlyModal({item, analyticsObj}, true);
      return;
    }
    if (!isCatchWeightItemAddedInCurrentSession(item)) {
      toggleCatchWeightModal(true);
    }
    updateBasket(item);
    if (basket && analyticsObj) {
      GoogleAnalyticsHelper.addProduct(
        analyticsObj.product,
        analyticsObj.quantity,
        analyticsObj.source,
        basket.fulfillmentType === SERVICE_TYPE.DELIVERY
      );
    }
  };

  doUpdateItem = (uuid, params) => {
    const {auth, updateItem} = this.props;
    if (!auth) {
      this.loginWithItem({
        type: UPDATING_TYPES.BASKET,
        uuid,
        params,
      });
      return;
    }
    // fixed the 400 error on sentry due to BE api issue
    if (params && params.quantity && params.quantity < 0) {
      return;
    }
    updateItem(uuid, params);
  };

  doRemoveItem = (uuid, product) => {
    const {auth, basket, removeItem} = this.props;
    if (!auth) {
      this.loginWithItem({
        type: UPDATING_TYPES.BASKET,
        uuid,
      });
      return;
    }
    removeItem(uuid);
    if (basket && product) {
      GoogleAnalyticsHelper.removeProduct(product);
    }
  };

  updateOutstandingBasketRequest = () => {
    const {updateBasket, updateItem, removeItem} = this.props;
    const item = this.state.updatingItemBeforeLogin;
    if (item) {
      switch (item.type) {
        case UPDATING_TYPES.BASKET: {
          updateBasket(item.item);
          break;
        }
        case UPDATING_TYPES.ITEM: {
          updateItem(item.uuid, item.params);
          break;
        }
        case UPDATING_TYPES.REMOVE: {
          removeItem(item.uuid);
          break;
        }
      }
    }
  };

  onLoginFinished = value => {
    this.setState({showLoginModal: false});
    const loginToken = queryString.parse(value);
    const {setLoginToken, getAccounts, branch} = this.props;
    if (loginToken.expires_in) {
      saveTokenValidTime(loginToken.expires_in);
    }
    if (loginToken.access_token) {
      setLoginToken(loginToken);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.LOGIN_TOKEN, loginToken);
      getAccounts(loginToken);
      if (branch) {
        GoogleAnalyticsHelper.setDimension(branch);
      }
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.LOGIN);
    } else {
      this.onLoginFailed(loginToken);
    }
  };

  toggleLoginModal = e => {
    e && e.preventDefault();
    const {systemMessages, toggleMaintenanceModal} = this.props;
    if (
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0
    ) {
      toggleMaintenanceModal(true);
      return;
    }
    const utsb = getDataFromSessionStorage('utsdb');
    const qsData = {
      referer: utsb && utsb['jjfoodservice-siq_ref'],
      deviceId: getCookie(JJ_LOCAL_STORAGE.DEVICE_ID),
    };
    window.location.href = LOGIN_PATH + '?' + queryString.stringify(qsData);
  };

  closeUpsellingModal = () => this.props.toggleUpsellingModal(false);
  closeSubstitutesModal = () => this.props.toggleSubstitutesModal(false);

  closeSwitchAccountModal = () => {
    const {auth, accounts, errors, resetError} = this.props;
    if (!auth) {
      return;
    }
    if (
      auth &&
      (auth.origin === AUTH_ORIGIN.USER_PASS ||
        auth.origin === AUTH_ORIGIN.REFRESH) &&
      accounts &&
      accounts.length > 0
    ) {
      return;
    }
    if (errors && errors.getAccountFailed) {
      resetError();
      this.logout();
    }
    this.props.toggleSwitchAccountModal(false);
  };

  openChangeBranchModal = () => this.props.toggleChangeBranchModal(true);

  closeBasketSyncFailedModal = () => {
    this.props.toggleBasketSyncFailedModal(false);
    this.props.history.push('/');
  };

  closeChangeBranchModal = () => {
    this.closeMenu();
    this.props.toggleChangeBranchModal(false);
  };
  closePaymentDetails = () => this.props.togglePaymentDetails(false);
  openStopEditModal = () => this.props.toggleStopEditModal(true);
  closeStopEditModal = () => this.props.toggleStopEditModal(false);
  closeAvailableOnlyModal = () =>
    this.props.toggleAvailableOnlyModal(null, false);
  closeCatchWeightModal = () => this.props.toggleCatchWeightModal(false);
  stopEdit = url => {
    const {history} = this.props;
    this.closeStopEditModal();
    this.cleanBasket();
    this.stopEditTimer = setTimeout(() => {
      history.push(url || '/');
    }, REDIRECT_DELAY_TIME);
  };

  removeBasketAsync = () => {
    const {removeBasket, updateAllSettings} = this.props;
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.FULFILLMENT);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.BASKET_UUID);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.ORDER_ID);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.ORDER_AX_BASKETID);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.EDIT_ORDER_PAYMENT_METHOD);
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.CATCH_WEIGHT_ITEMS);
    removeBasket();
    updateAllSettings({
      [SETTINGS.EDIT_ORDER_TIME]: null,
    });
  };

  cleanBasket = () => {
    const {basket, deleteAxBasket} = this.props;
    clearTimeout(this.editOrderExpiredTimer);
    clearTimeout(this.editOrderTimer);
    this.removeBasketAsync();
    if (basket) {
      deleteAxBasket();
    }
    GoogleAnalyticsHelper.clearUserInfo();
  };

  closeEditOrderTimeoutModal = () => {
    clearTimeout(this.editOrderTimer);
    this.setState({
      showEditOrderTimeoutModal: !this.state.showEditOrderTimeoutModal,
    });
  };

  closeEditOrderExpiredModal = () => {
    this.stopEdit('/orders');
    this.setState({
      showEditOrderTimeoutModal: false,
      showEditOrderExpiredModal: false,
    });
  };

  availableOnly = () => {
    const {modal} = this.props;
    const itemData =
      modal && modal.availableOnly && modal.availableOnly.itemData;
    this.doUpdateBasket(itemData.item, itemData.analyticsObj);
    this.closeAvailableOnlyModal();
    const itemAvailableOnlyFulfillment =
      itemData && getItemFulfillmentName(itemData.item);
    this.navigateToBookSlot(false, itemAvailableOnlyFulfillment);
  };

  changeBranch = branchId => {
    const {auth, setBranch} = this.props;
    this.closeChangeBranchModal();
    if (!!auth) {
      this.navigateToBookSlot();
    } else if (branchId) {
      // change branch if user is not logged in
      setBranch(branchId);
    }
  };

  openInJJsModal = () => {
    this.closeMenu();
    this.props.toggleOpenInJJsModal(true);
  };

  closeOpenInJJsModal = () => {
    this.closeMenu();
    this.props.toggleOpenInJJsModal(false);
  };
  closeSlotModal = () => {
    this.closeMenu();
    this.setState({showSlotModal: false});
  };

  openInJJs = () => {
    const config = getApiConfig();
    const url = config.jjSiteUrl;
    const href = `${url}/#default~STARTSHOPPING/salesorderdetails/?token=${this.props.auth.jj_token}`;
    this.closeOpenInJJsModal();
    window.open(href, '_blank');
  };

  selectAccount = (account, isSwitchAccount) => {
    const {setProfile, setAccount, auth, loginToken, resetSetting} = this.props;
    this.setState({basketUuid: null});
    this.switchAccount = !!isSwitchAccount;
    if (isSwitchAccount) {
      resetSetting();
    }
    removeValueFromLocalStorage(JJ_LOCAL_STORAGE.BASKET_UUID);
    setProfile(account);
    document.cookie = 'JJ_USER=' + encodeURIComponent(account.name);
    if ((loginToken && !auth) || isSwitchAccount) {
      // data returns from AX has no number but id
      setAccount(account.number || account.id);
    }
  };

  loginWithItem = updatingItem => {
    this.setState({
      showLoginModal: true,
      updatingItemBeforeLogin: updatingItem,
    });
  };

  search = keyword => {
    const {
      location,
      search,
      history,
      setCurrentRoute,
      branch,
      basket,
      settings,
      profile,
      setSetting,
      auth,
    } = this.props;
    const trimmedKeyword = encodeURIComponent(keyword.trim());
    if (trimmedKeyword === '') {
      return;
    }
    let b = (settings && settings.branchId) || (profile && profile.branchId);
    if (basket) {
      b = basket.branchId;
    }
    const searchParams = Object.assign({}, SEARCH_PARAMS, {
      b: b || branch,
    });
    setCurrentRoute(ROUTES.SEARCH);
    if (location.pathname === '/search') {
      const params = {...searchParams, ...{q: trimmedKeyword}};
      search(params);
    }

    if (trimmedKeyword && settings) {
      getSaveSearchKeywords(auth, keyword, settings, this.isFirstSearch).then(
        result => {
          this.isFirstSearch = false;
          if (result.save) {
            setSetting(
              SETTINGS.USER_SEARCH_KEYWORDS,
              Base64.encode(JSON.stringify(result.keywords))
            );
          }
        }
      );
    }

    history.push({
      pathname: '/search',
      search: `?q=${trimmedKeyword}&${queryString.stringify(searchParams)}`,
    });
  };

  navigateToSurvey = () => this.props.history.push('/survey/id');
  navigateToBasket = () => this.props.history.push('/basket');
  navigateToRegister = () => this.props.history.push('/register');

  navigateToCategory = (query, state) => {
    this.toggleMenu();
    const {branch, location, search, history} = this.props;
    if (location.pathname === '/search') {
      const urlParams = queryString.parse(location.search);
      const newParams = {
        b: urlParams.b,
        category: urlParams.category,
      };
      const params = Object.assign({}, newParams, queryString.parse(query), {
        q: '*',
        advanced: 'true',
        page: 0,
        sortType: SEARCH_SORT_TYPE.CATEGORY,
      });
      search(params);
    }
    history.push({
      pathname: '/search',
      search: `?q=*&advanced=true&b=${branch}&${query}&sortType=${SEARCH_SORT_TYPE.CATEGORY}&page=0`,
      state,
    });
  };

  navigateToBookSlot = (hasSlotExpired, preSelectedFulFillmentType) => {
    const {systemMessages, toggleMaintenanceModal} = this.props;
    if (
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0
    ) {
      toggleMaintenanceModal(true);
      return;
    }
    let locationProps = {
      pathname: '/bookSlot',
      state: {
        previousUrl: window && window.location && window.location.href,
      },
    };
    if (hasSlotExpired) {
      locationProps = Object.assign({}, locationProps, {
        state: {
          ...locationProps.state,
          ...{slotRemoved: true},
        },
      });
    }
    if (preSelectedFulFillmentType) {
      locationProps = Object.assign({}, locationProps, {
        state: {
          ...locationProps.state,
          ...{fulfillmentType: preSelectedFulFillmentType},
        },
      });
    }
    this.props.history.push(locationProps);
  };

  navigateToCheckout = async skipUpselling => {
    const {
      basket,
      history,
      setErrorManually,
      toggleSubstitutesModal,
      modal,
      systemMessages,
      toggleMaintenanceModal,
    } = this.props;
    if (
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0
    ) {
      toggleMaintenanceModal(true);
      return;
    }
    if (!basket) {
      history.push('/basket');
      return;
    }
    if (
      !!checkSlotExpired(basket) ||
      basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT
    ) {
      this.navigateToBookSlot();
      return;
    }
    const checkOosBasket = checkIfOnlyContainsOosItems(basket && basket.items);
    if (checkOosBasket) {
      setErrorManually(MANUALLY_ERRORS.BASKET_ONLY_OOS_ITEMS_ERROR);
      return;
    }
    const totalItemsNumber = getBasketTotalItems(basket && basket.items);
    if (totalItemsNumber === 0) {
      setErrorManually(MANUALLY_ERRORS.BASKET_NO_ITEMS_ERROR);
      return;
    }
    if (hasMinDeliveryOrderNotMet(basket)) {
      setErrorManually(MANUALLY_ERRORS.BASKET_MIN_DELIVERY_ORDER_ERROR);
      return;
    }
    const showSubstitutesModal =
      this.haveOutStandingOutOfStockItems() &&
      (!modal || (modal && !modal.substitutes));
    // show oos modal after upselling
    if (showSubstitutesModal) {
      toggleSubstitutesModal(true);
      return;
    }
    if (basket.fulfillAtSlot) {
      if (skipUpselling) {
        return history.push(`/checkout/${basket.uuid}`);
      }
      this.props.toggleUpsellingModal(true);
    } else {
      this.navigateToBookSlot();
    }
  };

  onProductClick = itemId => {
    if (!itemId) {
      return;
    }
    const {history, branch, basket, modal} = this.props;
    const branchId = (basket && basket.branchId) || branch || DEFAULT_BRANCH;
    if (modal && modal.upselling) {
      history.push(RECOMMENDED_PATH);
    }
    this.closeUpsellingModal();
    this.closeSubstitutesModal();
    const productUrl = `/product/${BRANCH_MAPPING[branchId]}/${itemId}/`;
    history.push(productUrl);
  };

  onSearchInputFocus = () => {
    const {auth, toggleChangeBranchModal} = this.props;
    if (!!auth || this.showChangeBracnModal) {
      return;
    }
    this.showChangeBracnModal = true;
    toggleChangeBranchModal(true);
  };

  haveOutStandingOutOfStockItems = () => {
    const {basket, route, modal} = this.props;
    const basketHasOutOfStockItem =
      basket && basket.items && getOosItemsSize(basket.items) > 0;
    if (basketHasOutOfStockItem) {
      // If the modal is already open, we treat as user skipping this step, so we return false as nothign to do with the substitutes
      return !(
        (modal && modal.substitutes) ||
        (route && route === ROUTES.BASKET)
      );
    }
    return false;
  };

  goCheckout = skipUpselling => {
    const {toggleUpsellingModal, history, basket, modal} = this.props;
    toggleUpsellingModal(false);
    if (!basket) {
      return;
    }
    this.navigateToCheckout(skipUpselling);
  };

  acceptAllSubstitutions = () => {
    // TODO when BE ready for bulk substitutions
  };

  setBrowseShopRef = node => (this.browseShopRef = node);

  getAccountFailed = () => {
    this.props.resetError();
    this.logout();
    this.setState({accountError: false});
  };

  handleNoActiveAccount = () => {
    this.props.resetError();
    this.logout();
    this.setState({noActiveAccount: false});
  };
  resetInvalidBasket = () => {
    const {resetError} = this.props;
    resetError();
    if (this.state.invalidBasketError) {
      this.setState({invalidBasketError: false});
    }
  };

  resetPickedOrder = () => {
    const {resetError, history} = this.props;
    this.cleanBasket();
    resetError();
    if (this.state.basketAlreadyPickedError) {
      this.setState({basketAlreadyPickedError: false});
    }
    history.push('/');
  };

  resetNotEditableOrder = () => {
    const {resetError, history} = this.props;
    resetError();
    this.setState({basketNotEditableError: false});
    history.push('/orders');
  };

  resetAxBasketNotFound = () => {
    const {resetError, history} = this.props;
    this.cleanBasket();
    resetError();
    this.setState({axBasketNotFoundError: false});
    history.push('/');
  };

  resetBasketServerSyncError = () => {
    const {resetError, basket, deleteAxBasket, history} = this.props;
    this.cleanBasket();
    if (basket) {
      deleteAxBasket();
    }
    resetError();
    if (this.state.basketServerSyncError) {
      this.setState({basketServerSyncError: false});
    }
    history.push('/');
  };

  closeContactAdminModal = () => {
    if (this.state.contactAdmin === CONTACT_ADMIN.CRITICAL) {
      this.logout();
    }
    this.props.resetError();
    if (this.state.contactAdmin) {
      this.setState({contactAdmin: null});
    }
  };

  navigateTo = url => {
    this.toggleMenu();
    this.props.history.push(url);
  };

  refreshPage = () => window && window.location && window.location.reload();

  resetBasketServerError = () => {
    this.setState({basketServerError: false});
    clearTimeout(this.getBasketTimer);
    this.getBasketTimer = setTimeout(() => {
      this.props.resetError();
      this.shouldReGetBasket = true;
    }, RE_GET_BASKET_TIME);
  };

  resetBasketError = () => {
    this.props.resetError();
    this.setState({basketError: null});
  };
  resetAzureError = () => {
    this.props.resetError();
    this.setState({azureError: false});
  };

  closeMaintenanceModal = () => this.props.toggleMaintenanceModal(false);

  closeRatingsModal = delay => {
    const {toggleRatingModal, setSetting} = this.props;
    setSetting(SETTINGS.RATINGS, true);
    if (!delay) {
      toggleRatingModal(false);
    } else {
      this.ratingModalTimer = setTimeout(
        () => toggleRatingModal(false),
        CLOSE_MODAL_DELAY_TIME
      );
    }
  };
  closeCustomerSupportMessageModal = () =>
    this.props.toggleCustomerSupportModal(false);

  navHomeReset = e => {
    e && e.preventDefault();
    const {history} = this.props;
    history.replace('/');
    history.push({pathname: '/', state: {}});
  };

  render() {
    const {
      initData,
      basket,
      postcodeLookup,
      addressLookup,
      lookup,
      errors,
      location,
      auth,
      loginToken,
      branch,
      branchList,
      accounts,
      modal,
      addingBasketLoading,
      updatingBasketLoading,
      toggleSwitchAccountModal,
      basketHashMap,
      upsellingItems,
      loading,
      getUpsellingItems,
      profile,
      searchAccounts,
      removeBasket,
      basketItems,
      substitutes,
      removeFulfillSlot,
      systemMessages,
      setSubstituteItem,
      getBasket,
      getAccounts,
      resetAccounts,
      settings,
      searchSuggestions,
      getSearchSuggestions,
      resetSearchSuggestions,
      history,
      cms,
      isStaffAccount,
      setSelectedAccount,
      cleanMode,
      loginRedirect,
      paymentDetails,
      userCookie,
    } = this.props;
    const {
      serverError,
      basketServerError,
      contactAdmin,
      showLoginModal,
      showMenuList,
      accountError,
      basketError,
      azureError,
      showSlotModal,
      invalidBasketError,
      basketAlreadyPickedError,
      basketNotEditableError,
      axBasketNotFoundError,
      basketServerSyncError,
      noActiveAccount,
      showRegisterModal,
      errorOccurred,
      hashError,
      isDeliveryDisabled,
    } = this.state;
    const updatingBasketStatus = addingBasketLoading || updatingBasketLoading;
    const isMaintenanceMode =
      systemMessages &&
      systemMessages.maintenance &&
      systemMessages.maintenance.length > 0;
    const renderLoginModal = showLoginModal && (
      <LoginRegister
        toggleModal={this.toggleLoginModal}
        showModal={showLoginModal}
        isLoggedIn={!!auth}
        postcodeLookup={postcodeLookup}
        addressLookup={addressLookup}
        lookup={lookup}
        errors={errors}
        onLoginFinished={this.onLoginFinished}
        onLoginFailed={this.onLoginFailed}
        deviceInfo={this.deviceInfo}
        showRegister={showRegisterModal}
        hashError={hashError}
        loginRedirect={loginRedirect}
      />
    );
    const renderSwitchAccountModal = modal && modal.switchAccount && (
      <SwitchAccount
        showModal={modal.switchAccount}
        closeModal={this.closeSwitchAccountModal}
        selectAccount={this.selectAccount}
        searchAccounts={searchAccounts}
        getAccounts={getAccounts}
        resetAccounts={resetAccounts}
        loading={loading}
        accounts={accounts}
        removeBasket={removeBasket}
        errors={errors}
        profile={profile}
        auth={auth}
        loginToken={loginToken}
        isStaffAccount={isStaffAccount}
        setSelectedAccount={setSelectedAccount}
      />
    );
    const renderPaymentDetailsModal = modal && modal.paymentDetails && (
      <PaymentDetailsModal
        showModal={true}
        paymentDetails={paymentDetails}
        closeModal={this.closePaymentDetails}
      />
    );
    const branchListArray = branchList ? Object.entries(branchList) : null;
    const renderChangeBranchModal =
      modal &&
      modal.changeBranch &&
      (!!auth ? (
        <PopupModal
          modalName="Change Branch"
          content={
            'To order from a new branch please book a collection slot there'
          }
          title={'Change Branch'}
          showOKButton={true}
          callback={this.changeBranch}
          closeModalCallback={this.closeChangeBranchModal}
          showModal={modal.changeBranch}
          tag={'changeBranch'}
        />
      ) : (
        branchListArray && (
          <BranchModal
            branchList={branchListArray}
            branchId={branch}
            showOKButton={true}
            callback={this.changeBranch}
            closeModalCallback={this.closeChangeBranchModal}
            showModal={modal.changeBranch}
          />
        )
      ));
    const renderOpenInJJsModal = modal && modal.openJJsOrders && (
      <PopupModal
        content={
          'Please login to the old JJs website to view your orders and invoices'
        }
        title={'My Orders'}
        showOKButton={true}
        callback={this.openInJJs}
        closeModalCallback={this.closeOpenInJJsModal}
        showModal={modal.openJJsOrders}
      />
    );
    const renderCustomerSupportModal = modal &&
      modal.customerSupportMessage && (
        <PopupModal
          modalName="Customer Support"
          showModal={modal.customerSupportMessage}
          message={settings.customerSupportMessage}
          showOkButton={true}
          callback={this.closeCustomerSupportMessageModal}
        />
      );
    const renderBasketSyncFailed = !!auth &&
      modal &&
      modal.basketSyncFailed && (
        <PopupModal
          showModal={modal.basketSyncFailed}
          callback={this.closeBasketSyncFailedModal}
          hideModal={this.closeBasketSyncFailedModal}
          showOkButton={true}
          showCancelButton={false}
          okButtonText={'OK'}
          modalName={'Basket sync failed'}
          message={'There was a problem getting your basket, please try again.'}
        />
      );
    const renderRatingsFeedback = modal && modal.showRatings && auth && (
      <RatingsFeedback
        branch={branch}
        closeModal={this.closeRatingsModal}
        cAccount={auth.c_account}
      />
    );
    const renderMaintenanceModal = modal &&
      isMaintenanceMode &&
      modal.maintenance && (
        <MaintenanceModal
          messages={systemMessages.maintenance}
          callback={this.closeMaintenanceModal}
          closeModalCallback={this.closeMaintenanceModal}
          showModal={modal.maintenance}
        />
      );
    const renderSlotModal = basket && showSlotModal && basket.fulfillAtSlot && (
      <SlotModal
        navigateToBookSlot={this.navigateToBookSlot}
        closeModalCallback={this.closeSlotModal}
        showModal={showSlotModal}
        fulfillmentStatus={basket.fulfillmentStatus}
        fulfillment={basket.fulfillment}
        fulfillAtSlot={basket.fulfillAtSlot}
      />
    );
    const renderCatchWeightModal = modal && modal.catchWeight && (
      <PopupModal
        content={'The total weight and price of this item can vary.'}
        title={'Note'}
        showOKButton={true}
        callback={this.closeCatchWeightModal}
        closeModalCallback={this.closeCatchWeightModal}
        showModal={modal.catchWeight}
      />
    );
    let keyword;
    if (location.pathname === '/search') {
      const params = queryString.parse(location.search);
      keyword = params.q || '';
    }
    const renderServerError = serverError && (
      <PopupModal
        modalName="server Error"
        content={'Please click OK to refresh.'}
        title={'Oops, something went wrong.'}
        isError={true}
        showOKButton={true}
        callback={this.refreshPage}
        closeModalCallback={this.refreshPage}
        showModal={serverError}
        tag={'serverError'}
      />
    );
    const renderBasketServerError = basketServerError && (
      <PopupModal
        modalName="Load Basket Error"
        content={'Please try again later.'}
        title={'Sorry, we were unable to load your basket.'}
        isError={true}
        showOKButton={true}
        callback={this.resetBasketServerError}
        closeModalCallback={this.resetBasketServerError}
        showModal={basketServerError}
        tag={'basketServerError'}
      />
    );
    const renderAzureLoginError = azureError && (
      <PopupModal
        modalName="Login Error"
        content={'Please try again later'}
        title={'Failed to login'}
        isError={true}
        showOKButton={true}
        callback={this.resetAzureError}
        closeModalCallback={this.resetAzureError}
        showModal={azureError}
        tag={'azureError'}
      />
    );
    const renderContactAdminModal = contactAdmin && (
      <ContactAdminModal
        callback={this.closeContactAdminModal}
        closeModalCallback={this.closeContactAdminModal}
        showModal={contactAdmin}
        isDeliveryDisabled={isDeliveryDisabled}
      />
    );
    const renderBasketError = basketError && (
      <BasketErrorsModal
        error={basketError}
        callback={this.resetBasketError}
        closeModalCallback={this.resetBasketError}
        minOrder={!!basket && basket.minimumOrderAmount}
        showModal={basketError}
        removeFulfillSlot={removeFulfillSlot}
        basket={basket}
        navigateToBookSlot={this.navigateToBookSlot}
        navigateToBasket={this.navigateToBasket}
      />
    );
    const renderAccountError = accountError && (
      <PopupModal
        modalName="Get Account Error"
        content={'Failed to get your account details.'}
        title={'Oops, something went wrong.'}
        isError={true}
        showOKButton={true}
        callback={this.getAccountFailed}
        closeModalCallback={this.getAccountFailed}
        showModal={accountError}
        tag={'accountError'}
      />
    );
    const renderNoActiveAccount = noActiveAccount && (
      <PopupModal
        modalName="No active account"
        content={
          'If you have a new account please activate it using the email link sent to you. Otherwise please call Customer Support.'
        }
        title={'no active account found'}
        isError={true}
        showOKButton={true}
        callback={this.handleNoActiveAccount}
        closeModalCallback={this.handleNoActiveAccount}
        showModal={noActiveAccount}
        tag={'noActiveAccount'}
      />
    );
    const renderInvalidBasketError = invalidBasketError && (
      <PopupModal
        modalName="Get Basket error"
        content={'Failed to get your basket details.'}
        title={'Basket error'}
        isError={true}
        showOKButton={true}
        callback={this.resetInvalidBasket}
        closeModalCallback={this.resetInvalidBasket}
        showModal={invalidBasketError}
        tag={'invalidBasketError'}
      />
    );
    const renderBasketAlreadyPickedError = basketAlreadyPickedError && (
      <PopupModal
        modalName="Picking error"
        content={AX_BASKET_ERRORS.STARTED_PICKING}
        title={'Basket error'}
        isError={true}
        showOKButton={true}
        callback={this.resetPickedOrder}
        closeModalCallback={this.resetPickedOrder}
        showModal={basketAlreadyPickedError}
        tag={'basketAlreadyPickedError'}
      />
    );
    const renderBasketNotEditableError = basketNotEditableError && (
      <PopupModal
        modalName="Basket Not Editable"
        content={AX_BASKET_ERRORS.NOT_EDITABLE}
        title={'Basket error'}
        isError={true}
        showOKButton={true}
        callback={this.resetNotEditableOrder}
        closeModalCallback={this.resetNotEditableOrder}
        showModal={basketNotEditableError}
      />
    );
    const renderAxBasketNotFoundError = axBasketNotFoundError && (
      <PopupModal
        modalName="Basket Not Found"
        content={AX_BASKET_ERRORS.AX_BASKET_NOT_FOUND}
        title={'Basket error'}
        isError={true}
        showOKButton={true}
        callback={this.resetAxBasketNotFound}
        closeModalCallback={this.resetAxBasketNotFound}
        showModal={axBasketNotFoundError}
        tag={'axBasketNotFoundError'}
      />
    );
    const renderBasketServerSyncError = basketServerSyncError && (
      <PopupModal
        modalName="Restart Order"
        content={'Sorry, there was a problem loading your basket.'}
        subContent={'Please start your order again.'}
        title={'Basket error'}
        isError={true}
        showOKButton={true}
        callback={this.resetBasketServerSyncError}
        closeModalCallback={this.resetBasketServerSyncError}
        showModal={basketServerSyncError}
        tag={'basketServerSyncError'}
      />
    );
    const renderUpsellingModal = modal && modal.upselling && (
      <Upselling
        showModal={modal.upselling}
        upsellingItems={upsellingItems}
        toggleModal={this.closeUpsellingModal}
        goToCheckout={this.goCheckout}
        basket={basket}
        onProductClick={this.onProductClick}
        updateBasket={this.doUpdateBasket}
        loading={loading}
        basketHashMap={basketHashMap}
        updateItem={this.doUpdateItem}
        removeItem={this.doRemoveItem}
        getUpsellingItems={getUpsellingItems}
        fulfillmentType={basket && basket.fulfillmentType}
        deviceInfo={this.deviceInfo}
      />
    );
    const isMultiAccounts = accounts && accounts.length > 1;
    const renderSubstitutesModal = modal && modal.substitutes && (
      <SubstitutesModal
        showModal={modal.substitutes}
        toggleModal={this.closeSubstitutesModal}
        goToCheckout={this.goCheckout}
        onBasketItemClick={this.onProductClick}
        basketItems={basketItems}
        substitutes={substitutes}
        setSubstituteItem={setSubstituteItem}
        updateBasket={this.doUpdateBasket}
        updateItem={this.doUpdateItem}
        acceptAll={this.acceptAllSubstitutions}
        getBasket={getBasket}
        settings={settings}
        loading={loading}
        fulfillmentType={basket && basket.fulfillmentType}
      />
    );
    const renderEditOrderTimeouModal = this.state.showEditOrderTimeoutModal && (
      <PopupModal
        modalName="10 minutes checkout"
        content={
          'You have 10 minutes left to checkout, otherwise any changes to your original order will be lost'
        }
        title={'Sorry!'}
        showOKButton={true}
        callback={this.closeEditOrderTimeoutModal}
        closeModalCallback={this.closeEditOrderTimeoutModal}
        showModal={this.state.showEditOrderTimeoutModal}
      />
    );
    const renderEditOrderExpiredModal = this.state
      .showEditOrderExpiredModal && (
      <PopupModal
        modalName="Edit Order Error"
        content={
          'Sorry, your edits have now been lost and your original order is unchanged. If your order is still editable you may try again.'
        }
        title={'Sorry'}
        showOKButton={true}
        okButtonText={'View Orders'}
        callback={this.closeEditOrderExpiredModal}
        closeModalCallback={this.closeEditOrderExpiredModal}
        showModal={this.state.showEditOrderExpiredModal}
      />
    );
    const renderStopEditModal = modal && modal.stopEdit && (
      <PopupModal
        modalName="Stop editing"
        content={`Quit editing order ${basket &&
          basket.axBasketId} and start a new order?`}
        title={'Stop editing?'}
        showOKButton={true}
        showCancelButton={true}
        okButtonText={'Yes'}
        cancelButtonText={'No'}
        callback={this.stopEdit}
        closeModalCallback={this.closeStopEditModal}
        cancelCallBack={this.closeStopEditModal}
        showModal={modal.stopEdit}
      />
    );
    const itemData =
      modal && modal.availableOnly && modal.availableOnly.itemData;
    const preSelectedFulfillmentType =
      itemData && getItemFulfillmentName(itemData.item);
    const isDefaultSlotItem =
      itemData &&
      isDefaultSlotItemWithOnlyOnePrice(basket, itemData.item, settings);
    const renderAvailableOnlyModal = modal &&
      modal.availableOnly &&
      modal.availableOnly.status && (
        <PopupModal
          content={`This product is for ${preSelectedFulfillmentType} only.`}
          title={isDefaultSlotItem ? `Want to collect?` : `Book a new slot?`}
          showOKButton={true}
          showCancelButton={true}
          okButtonText={
            isDefaultSlotItem
              ? `Book a ${preSelectedFulfillmentType} slot`
              : `Change slot to ${preSelectedFulfillmentType}`
          }
          cancelButtonText={'Cancel'}
          callback={this.availableOnly}
          closeModalCallback={this.closeAvailableOnlyModal}
          cancelCallBack={this.closeAvailableOnlyModal}
          showModal={modal.availableOnly && modal.availableOnly.status}
          wideButtons={true}
          tag={'modal.availableOnly.status'}
        />
      );
    const renderAppRoute = !errorOccurred && (
      <AppRoute
        updateBasket={this.doUpdateBasket}
        updateItem={this.doUpdateItem}
        removeItem={this.doRemoveItem}
        relogin={this.relogin}
        navigateToCheckout={this.navigateToCheckout}
        deviceInfo={this.deviceInfo}
        basketHashMap={basketHashMap}
        changeBranch={this.changeBranch}
        onProductClick={this.onProductClick}
        cleanBasket={this.cleanBasket}
        openStopEditModal={this.openStopEditModal}
        toggleLoginModal={this.toggleLoginModal}
        keywordSearch={this.search}
      />
    );
    const renderGenericError = errorOccurred && (
      <Fragment>
        <Title>Oops!</Title>
        <SubTitle>Something went wrong!</SubTitle>
        <Text>
          We already notified our developers to fix this. Meanwhile, please
          continue shopping if you can.
        </Text>
        <ContinueShopping>
          <LinkButton to="/" onClick={this.resetGenericError}>
            Continue shopping
          </LinkButton>
        </ContinueShopping>
      </Fragment>
    );
    const hideCookieConsent =
      JJ_COOKIE_CONSENT_EXCLUDED_URLS.indexOf(location.pathname) > -1;
    const renderCookieConsent = !cleanMode && !hideCookieConsent && (
      <CookieConsent
        cookieName={JJ_COOKIE_CONSENT}
        style={CookieConsentStyles}
        contentStyle={CookieConsentContent}
        buttonStyle={CookieConsentButton}
        buttonText={'Close'}
      >
        Cookies and related technologies are required to make this site operate
        properly. If you register or login you will need to consent to our{' '}
        <CookieLink href={TERMS_AND_CONDITIONS_LINK.url} target="_blank">
          {TERMS_AND_CONDITIONS_LINK.content}
        </CookieLink>{' '}
        and{' '}
        <CookieLink href={PRIVACY_POLICY_LINK.url} target="_blank">
          {PRIVACY_POLICY_LINK.content}
        </CookieLink>
        .
      </CookieConsent>
    );
    const showShoppingList = settings && settings.shoppingList === 'true';
    const renderTopBar = !cleanMode && (
      <TopBar
        userCookie={userCookie}
        toggleMenu={this.toggleMenu}
        toggleLoginModal={this.toggleLoginModal}
        openInJJsModal={this.openInJJsModal}
        navigateToCheckout={this.navigateToCheckout}
        navigateToSurvey={this.navigateToSurvey}
        navigateToRegister={this.navigateToRegister}
        quantity={(basket && basket.items && basket.items.length) || 0}
        loginToken={loginToken}
        profile={profile}
        logout={this.logout}
        branch={branch}
        auth={auth}
        branchList={branchList}
        toggleSwitchAccountModal={toggleSwitchAccountModal}
        updatingBasketStatus={updatingBasketStatus}
        navigateToBookSlot={this.navigateToBookSlot}
        isMultiAccounts={isMultiAccounts}
        isStaffAccount={isStaffAccount}
        systemMessages={systemMessages}
        isMaintenanceMode={isMaintenanceMode}
        showShoppingList={showShoppingList}
      />
    );
    const renderMenu = !cleanMode && (
      <Menu
        branchList={branchList}
        branch={branch}
        showMenuList={showMenuList}
        toggleMenu={this.toggleMenu}
        toggleLoginModal={this.toggleLoginModal}
        categoryList={initData && initData.categoryList}
        navigateToCategory={this.navigateToCategory}
        openChangeBranchModal={this.openChangeBranchModal}
        openInJJsModal={this.openInJJsModal}
        isLoggedIn={!!auth}
        profile={profile}
        toggleSwitchAccountModal={toggleSwitchAccountModal}
        logout={this.logout}
        navigateTo={this.navigateTo}
        browseShopRef={this.browseShopRef}
        isMultiAccounts={isMultiAccounts}
        isStaffAccount={isStaffAccount}
        isMaintenanceMode={isMaintenanceMode}
        categoryAd={cms && cms.banners && cms.banners.categoryAd}
        navigateToRegister={this.navigateToRegister}
        showShoppingList={showShoppingList}
      />
    );

    const renderHeader = !cleanMode && (
      <Header
        showMenuList={showMenuList}
        search={this.search}
        onSearchInputFocus={this.onSearchInputFocus}
        toggleMenu={this.toggleMenu}
        keyword={keyword}
        isLoggedIn={!!auth}
        toggleLoginModal={this.toggleLoginModal}
        navigateToBookSlot={this.navigateToBookSlot}
        setRef={this.setBrowseShopRef}
        searchSuggestions={searchSuggestions}
        getSearchSuggestions={getSearchSuggestions}
        resetSearchSuggestions={resetSearchSuggestions}
        branch={branch}
        history={history}
        navHome={this.navHomeReset}
      />
    );
    const isMobile =
      this.deviceInfo && (this.deviceInfo.isPhone || this.deviceInfo.isTablet);
    const showSmartBanner =
      this.deviceInfo &&
      ((this.deviceInfo.userAgent !== 'Safari' && this.deviceInfo.isIos) ||
        !this.deviceInfo.isIos);
    const renderSmartBanner = !cleanMode && isMobile && showSmartBanner && (
      <SmartBanner
        title={'JJ Foodservice Ordering App'}
        author={'JJ Foodservice Limited'}
        position={'top'}
      />
    );
    const renderFooter = !cleanMode && <Footer />;
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <GlobalStyled />
          {renderSmartBanner}
          {renderLoginModal}
          {renderSwitchAccountModal}
          {renderPaymentDetailsModal}
          {renderUpsellingModal}
          {renderSubstitutesModal}
          {renderServerError}
          {renderBasketServerError}
          {renderContactAdminModal}
          {renderBasketError}
          {renderAzureLoginError}
          {renderAccountError}
          {renderNoActiveAccount}
          {renderInvalidBasketError}
          {renderBasketAlreadyPickedError}
          {renderBasketNotEditableError}
          {renderAxBasketNotFoundError}
          {renderBasketServerSyncError}
          {renderChangeBranchModal}
          {renderOpenInJJsModal}
          {renderSlotModal}
          {renderCatchWeightModal}
          {renderRatingsFeedback}
          {renderMaintenanceModal}
          {renderCustomerSupportModal}
          {renderBasketSyncFailed}
          {renderEditOrderTimeouModal}
          {renderEditOrderExpiredModal}
          {renderStopEditModal}
          {renderAvailableOnlyModal}
          {renderTopBar}
          {renderMenu}
          <StyledApp>
            {renderHeader}
            <Wrapper role="main">
              {renderGenericError}
              {renderAppRoute}
            </Wrapper>
            {renderFooter}
            {renderCookieConsent}
          </StyledApp>
        </Container>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data,
  branch: state.branch,
  branchList: state.branchList,
  initData: state.init,
  basket: state.basket,
  lookup: state.lookup,
  errors: state.errors,
  auth: state.auth,
  route: state.route,
  accounts: state.accounts,
  modal: state.modal,
  addingBasketLoading: state.loading.addingToBasket,
  updatingBasketLoading: state.loading.updatingBasket,
  basketHashMap: state.basketHashMap,
  recommendedItems: state.recommendedItems,
  loading: state.loading,
  checkout: state.checkout,
  upsellingItems: state.upsellingItems,
  profile: state.profile,
  settings: state.settings,
  basketItems: state.basketItems,
  systemMessages: state.systemMessages,
  substitutes: state.substitutes,
  searchSuggestions: state.searchSuggestions,
  loginToken: state.loginToken,
  refreshToken: state.refreshToken,
  cms: state.cms,
  reloginAccount: state.reloginAccount,
  isStaffAccount: state.isStaffAccount,
  cleanMode: state.cleanMode,
  nextRoute: state.nextRoute,
  loginRedirect: state.loginRedirect,
  paymentDetails: state.paymentDetails,
});

const mapDispatchToProps = dispatch => ({
  getInitData: bindActionCreators(getInitData, dispatch),
  search: bindActionCreators(search, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  getBasket: bindActionCreators(getBasket, dispatch),
  postcodeLookup: bindActionCreators(postcodeLookup, dispatch),
  addressLookup: bindActionCreators(addressLookup, dispatch),
  setLoginToken: bindActionCreators(setLoginToken, dispatch),
  setAuthToken: bindActionCreators(setAuth, dispatch),
  freshAuthToken: bindActionCreators(refreshAuth, dispatch),
  removeAuthToken: bindActionCreators(removeAuth, dispatch),
  removeLoginToken: bindActionCreators(removeLoginToken, dispatch),
  getAccounts: bindActionCreators(getAccounts, dispatch),
  setAccount: bindActionCreators(setAccount, dispatch),
  resetAccounts: bindActionCreators(resetAccounts, dispatch),
  updateBasket: bindActionCreators(updateBasket, dispatch),
  updateItem: bindActionCreators(updateBasketItem, dispatch),
  removeItem: bindActionCreators(removeBasketItem, dispatch),
  deleteAxBasket: bindActionCreators(deleteAxBasket, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
  setErrorManually: bindActionCreators(setErrorManually, dispatch),
  setEventManually: bindActionCreators(setEventManually, dispatch),
  getAllBranches: bindActionCreators(getBranchList, dispatch),
  toggleSwitchAccountModal: bindActionCreators(toggleSwitchAccount, dispatch),
  toggleChangeBranchModal: bindActionCreators(toggleChangeBranch, dispatch),
  toggleOpenInJJsModal: bindActionCreators(toggleOpenInJJsModal, dispatch),
  toggleUpsellingModal: bindActionCreators(toggleUpsellingModal, dispatch),
  toggleSubstitutesModal: bindActionCreators(toggleSubstitutesModal, dispatch),
  toggleMaintenanceModal: bindActionCreators(toggleMaintenanceModal, dispatch),
  toggleStopEditModal: bindActionCreators(toggleStopEditModal, dispatch),
  toggleAvailableOnlyModal: bindActionCreators(
    toggleAvailableOnlyModal,
    dispatch
  ),
  toggleCatchWeightModal: bindActionCreators(toggleCatchWeightModal, dispatch),
  toggleCustomerSupportModal: bindActionCreators(
    toggleCustomerSupportModal,
    dispatch
  ),
  toggleBasketSyncFailedModal: bindActionCreators(
    toggleBasketSyncFailedModal,
    dispatch
  ),
  getUpsellingItems: bindActionCreators(getUpsellingItems, dispatch),
  setFulfillmentDetails: bindActionCreators(setFulfillmentDetails, dispatch),
  setProfile: bindActionCreators(setProfile, dispatch),
  getAllSettings: bindActionCreators(getAllSettings, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch),
  updateAllSettings: bindActionCreators(updateAllSettings, dispatch),
  resetSetting: bindActionCreators(resetSetting, dispatch),
  searchAccounts: bindActionCreators(searchAccounts, dispatch),
  removeBasket: bindActionCreators(removeBasket, dispatch),
  setSubstituteItem: bindActionCreators(setSubstituteItem, dispatch),
  removeFulfillSlot: bindActionCreators(removeFulfillSlot, dispatch),
  setBranch: bindActionCreators(setBranch, dispatch),
  getAuthToken: bindActionCreators(getAuth, dispatch),
  noAuthToken: bindActionCreators(noAuthToken, dispatch),
  getSearchSuggestions: bindActionCreators(getSearchSuggestions, dispatch),
  resetSearchSuggestions: bindActionCreators(resetSearchSuggestions, dispatch),
  getOrderByOrderId: bindActionCreators(getOrderByOrderId, dispatch),
  checkBasketSynced: bindActionCreators(checkBasketSynced, dispatch),
  setSelectedAccount: bindActionCreators(setSelectedAccount, dispatch),
  getPaymentDetails: bindActionCreators(getPaymentDetails, dispatch),
  togglePaymentDetails: bindActionCreators(togglePaymentDetails, dispatch),
  toggleRatingModal: bindActionCreators(toggleRatingModal, dispatch),
  setCleanMode: bindActionCreators(setCleanMode, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
