import * as ActionTypes from './types.action';

export const getProductDetail = details => ({
  type: ActionTypes.GET_PRODUCT_DETAIL_REQUESTED,
  details,
});

export const setProductDetail = productDetail => ({
  type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
  productDetail,
});

export const getProductsByIds = ids => ({
  type: ActionTypes.GET_PRODUCTS_BY_IDS_REQUESTED,
  ids,
});

export const resetProductDetail = () => ({
  type: ActionTypes.RESET_PRODUCT_DETAIL_SUCCESS,
});
