import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import storeLocator from '../../images/store_locator.jpg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 10rem;
  margin-top: 0;
  ${media.smaller`
    flex-direction: column;
    padding: 0 1rem;
  `};
`;

export const Content = styled.div`
  margin-left: 2.8rem;
  margin-right: 2.8rem;
  display: flex;
  ${media.smaller`
    margin: 1rem 2rem;
    width: 100%;
    flex-direction: column;
  `};
`;
export const ContentRight = styled(Content)`
  ${media.smaller`
    flex-direction: row;
    justify-content: space-between;
  `};
`;

export const Text = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.4rem;
  display: block;
  align-items: center;
  color: ${props => props.theme.primaryTextColor};
  padding-right: 4rem;
  z-index: 3;
`;

export const BoldText = styled(Text)`
  text-transform: uppercase;
`;

export const FavoriteWrapper = styled.div`
  z-index: 3;
  padding-left: 0.5rem;
`;

export const ColorDimmer = styled.div`
  background-color: ${p =>
    p.$isOffer ? p.theme.dimmerRed : p.theme.dimmerGreen};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;

export const Background = styled.div`
  background-image: url(${storeLocator});
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

export const Icons = styled.div`
  z-index: 5;
  display: flex;
  position: relative;
`;
