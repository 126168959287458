import React, {PureComponent} from 'react';
import {Wrapper} from './SearchSuggestion.style';
import SuggestionItem from './SuggestionItem';

export default class SearchSuggestions extends PureComponent {
  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  handleClickOutside = e => {
    e.stopPropagation();
    const {hideSuggestion} = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      hideSuggestion();
    }
  };

  setRef = node => (this.wrapperRef = node);

  render() {
    const {
      suggestions,
      keyword,
      selectedIndex,
      setSuggestionIndex,
      search,
      onMouseLeave,
    } = this.props;
    const items = suggestions.map((suggestion, index) => {
      const strippedKeyword = keyword.replace(/[|\\{}()[\]^$+*?.-]/g, '\\$&');
      const parts = suggestion.searchField.split(
        new RegExp(`(${strippedKeyword})`, 'gi')
      );

      return (
        <SuggestionItem
          key={index}
          index={index}
          selected={index === selectedIndex}
          setSuggestionIndex={setSuggestionIndex}
          search={search}
          parts={parts}
          keyword={keyword}
        />
      );
    });
    return (
      <Wrapper onMouseLeave={onMouseLeave} ref={this.setRef}>
        {items}
      </Wrapper>
    );
  }
}
