import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function recommendedReducer(
  state = initialState.recommendedItems,
  action
) {
  switch (action.type) {
    case types.GET_RECOMMENDED_ITEMS_SUCCESS:
      return action.recommendedItems;
    case types.GET_PRODUCT_DETAIL_REQUESTED:
    case types.GET_RECOMMENDED_ITEMS_RESET:
    case types.GET_RECOMMENDED_ITEMS_FAILED:
      return null;
    default:
      return state;
  }
}
