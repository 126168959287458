import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function initReducer(state = initialState.init, action) {
  switch (action.type) {
    case types.GET_INIT_DATA_SUCCESS:
      return Object.assign({}, state, {
        categoryList: action.init,
      });
    default:
      return state;
  }
}
