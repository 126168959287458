import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  FullWideButton,
  LongButtonsWrapper,
} from './PopupModal.style';
import {addTwoValues} from '../../helpers/data.helper';

export default class PaymentCommercialCardModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paymentTotalAmount: null,
    };
  }

  componentDidMount() {
    const {commercialCardFee, amount} = this.props;
    if (commercialCardFee && amount) {
      const paymentTotalAmount = addTwoValues(amount, commercialCardFee);
      this.setState({paymentTotalAmount});
    }
  }

  render() {
    const {
      showModal,
      callback,
      closeModalCallback,
      commercialCardFee,
    } = this.props;
    const {paymentTotalAmount} = this.state;
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw="commercialCardModal"
      >
        <StyledWrapper id="payment-error">
          <Content>
            <TextWrapper>
              <Title>Proceed with Business Card Payment?</Title>
              <SubContent>
                {`Hello, it looks like the card you have provided is a business card, which incurs an additional processing fee of £${commercialCardFee ||
                  ''} (inc. vat).`}
              </SubContent>
              <SubContent>
                You have the option to proceed with this payment, accepting the
                fee, or you can choose to cancel and use a different payment
                method, such as Pay By Bank, which does not incur any additional
                fees.
              </SubContent>
              <LongButtonsWrapper>
                <FullWideButton
                  onClick={callback}
                  data-rw="modal--accept-commercial-card-charge"
                >
                  {`Accept & Pay £${
                    paymentTotalAmount
                      ? paymentTotalAmount
                      : commercialCardFee + ' Fee'
                  }`}
                </FullWideButton>
                <FullWideButton
                  onClick={closeModalCallback}
                  data-rw="modal--try-again-button"
                >
                  Cancel & Choose Another Payment Method
                </FullWideButton>
              </LongButtonsWrapper>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
