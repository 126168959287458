import * as ActionTypes from './types.action';

export const getOrderByOrderId = orderId => ({
  type: ActionTypes.GET_ORDER_REQUESTED,
  orderId,
});

export const getInvoicedOrderById = (salesId, accountId) => ({
  type: ActionTypes.GET_INVOICED_ORDER_REQUESTED,
  salesId,
  accountId,
});

export const getOrderAfterCheckout = orderId => ({
  type: ActionTypes.GET_ORDER_AFTER_CHECKOUT_REQUESTED,
  orderId,
});

export const cancelOrder = orderId => ({
  type: ActionTypes.CANCEL_ORDER_REQUESTED,
  orderId,
});

export const getOrderHistory = (accountId, dateFrom, dateTo, page) => ({
  type: ActionTypes.GET_ORDER_HISTORY_REQUESTED,
  accountId,
  dateFrom,
  dateTo,
  page,
});

export const resetOrder = () => ({
  type: ActionTypes.RESET_ORDERS_SUCCESS,
});

export const trackOrder = axBasketId => ({
  type: ActionTypes.TRACK_ORDER_REQUESTED,
  axBasketId,
});

export const resetTrackOrder = () => ({
  type: ActionTypes.TRACK_ORDER_RESET,
});

export const reAuthOrderPayment = orderId => ({
  type: ActionTypes.REAUTH_ORDER_REQUESTED,
  orderId,
});
