import * as ActionTypes from './types.action';

export const getJJContentBySlug = contentSlugName => ({
  type: ActionTypes.GET_JJ_CONTENT_BY_SLUG_REQUESTED,
  contentSlugName,
});

export const getShopBySectors = () => ({
  type: ActionTypes.GET_SHOP_BY_SECTORS_REQUESTED,
});

export const setJJContentBySlug = jjContent => ({
  type: ActionTypes.GET_JJ_CONTENT_BY_SLUG_SUCCESS,
  jjContent,
});
