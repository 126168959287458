import {createStore, applyMiddleware} from 'redux';
import {captureException, setTag} from '@sentry/browser';
import createSagaMiddleware, {END} from 'redux-saga';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import rootReducer from '../reducers/index.reducer';
import saga from '../saga/saga';

const onError = error => {
  setTag('environment', 'production');
  captureException(error);
};

const sagaMiddleware = createSagaMiddleware({onError});
const sentryMiddleWare = createSentryMiddleware(Sentry, {});

const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(sentryMiddleWare, sagaMiddleware)
  );
  sagaMiddleware.run(saga);
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
};

export default configureStore;
