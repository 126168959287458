import React, {PureComponent} from 'react';

import {
  PriceText,
  PriceType,
  UnitPriceText,
} from '../../components/Product/Product.style';
import {Price} from './ProductDetailPage.style';

export default class MultiPrices extends PureComponent {
  render() {
    const {prices, hasMixOffer} = this.props;
    return prices.map((price, index) => (
      <Price key={index}>
        <PriceType $isOffer={hasMixOffer}>
          {price.min && `Buy ${price.min}+:`}
        </PriceType>
        <PriceText $isOffer={hasMixOffer}>
          {price.priceInc && `£${price.priceInc.toFixed(2)}`}
        </PriceText>
        {index === prices.length - 1 && price.unitPriceDisplay && (
          <UnitPriceText>{price.unitPriceDisplay}</UnitPriceText>
        )}
      </Price>
    ));
  }
}
