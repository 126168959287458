import * as ActionTypes from './types.action';

export const postcodeLookup = postcode => ({
  type: ActionTypes.GET_POSTCODE_LOOKUP_REQUESTED,
  postcode,
});

export const addressLookup = udprn => ({
  type: ActionTypes.GET_ADDRESS_LOOKUP_REQUESTED,
  udprn,
});

export const resetPostcodeLookup = postcode => ({
  type: ActionTypes.RESET_POSTCODE_LOOKUP_REQUESTED,
});
