import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function shoppingListLookupReducer(
  state = initialState.shoppingListLookup,
  action
) {
  switch (action.type) {
    case types.GET_LISTS_BY_ID_SUCCESS:
      return action.shoppingListLookup || [];
    case types.GET_LISTS_BY_ID_REQUESTED:
    case types.GET_LISTS_BY_ID_FAILED:
      return null;
    case types.GET_MORE_LISTS_BY_ID_SUCCESS:
      return {
        page: action.shoppingListLookup.page,
        results: [...state.results, ...action.shoppingListLookup.results],
      };
    case types.REMOVE_FROM_SHOPPING_LIST_LOOKUP_SUCCESS:
      const newPage = Object.assign({}, state && state.page, {
        totalElements:
          state && state.page && state.page.totalElements
            ? state.page.totalElements - 1
            : 0,
      });
      const results =
        state &&
        state.results &&
        state.results.filter(item => item.itemId !== action.itemId);
      return {page: newPage, results};
    case types.RENAME_SHOPPING_LIST_SUCCESS:
      return Object.assign({}, state, {
        name: action.results && action.results.name,
      });
    case types.RESET_SHOPPING_LIST_SUCCESS:
      return null;
    default:
      return state;
  }
}
