import * as ActionTypes from './types.action';

export const addFavourite = product => ({
  type: ActionTypes.ADD_TO_FAVOURITES_REQUESTED,
  product,
});

export const removeFavourite = product => ({
  type: ActionTypes.REMOVE_FROM_FAVOURITES_REQUESTED,
  product,
});

export const getFavourites = (page, params) => ({
  type: ActionTypes.GET_FAVOURITES_REQUESTED,
  page,
  params,
});
