import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function basketItemsReducer(
  state = initialState.basketItems,
  action
) {
  switch (action.type) {
    case types.GET_PRODUCTS_BY_IDS_SUCCESS:
    case types.SYNC_BASKET_ITEMS_WITH_MESSAGE_SUCCESS:
    case types.SYNC_BASKET_ITEMS_SUCCESS:
    case types.SET_SUBSTITUTE_ITEM_SUCCEEDED:
      return action.basketItems;
    case types.RESET_BASKET_ITEMS_SUCCEEDED:
    case types.REMOVE_BASKET_SUCCEEDED:
    case types.REMOVE_AUTH_SUCCESS: //logout clean up
      return null;
    default:
      return state;
  }
}
