import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {captureException} from '@sentry/browser';
import {GQL_GET_OFFERS} from '../gql/offers.gql';
export default class offersApi {
  static async getHomepageData(params, isFromServer) {
    try {
      const config = getApiConfig();
      const urlGraphQL = isFromServer ? config.graphQlInternal : config.graphQL;
      return axios({
        url: urlGraphQL,
        method: 'post',
        data: {
          query: GQL_GET_OFFERS,
          variables: params,
        },
      }).then(res => res.data);
    } catch (e) {
      return {};
    }
  }
  static async getOffersToday(params, headers) {
    try {
      const config = getApiConfig();
      return axios({
        url: config.graphQL,
        method: 'post',
        data: {
          query: GQL_GET_OFFERS,
          variables: params,
        },
        headers,
      }).then(res => res.data);
    } catch (e) {
      return [];
    }
  }
  static aggregate(params, headers) {
    const config = getApiConfig();
    const url = `${config.offersFacetsUrl}/product/offersfacets`;
    return axios.get(url, {params, headers}).then(res => res.data);
  }
}
