import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ActionButton,
  Date,
  ItemProp,
  ItemPropValue,
  Items,
  OrderId,
  OrderItem,
  OrderRow,
  OrderWrapper,
  RowTitle,
  Total,
  Feedback,
  ReOrder,
  ItemPropNonLink,
  RequestButton,
} from './OrdersPage.style';

import {isServiceItem} from '../../helpers/basket.helper';
import {ORDER_HISTORY_DATE_FORMAT} from '../../constants/constants';

export default class Invoice extends PureComponent {
  static propTypes = {
    invoices: PropTypes.array,
    reOrder: PropTypes.func,
  };

  state = {
    detailsOpen: false,
  };

  componentDidMount() {
    const {invoices} = this.props;
    let invoicesTotal = 0;
    invoices.map(invoice => {
      invoicesTotal = parseFloat(invoicesTotal + (invoice.invoiceTotal || 0));
    });
    this.setState({invoicesTotal: invoicesTotal.toFixed(2)});
  }

  openClose = () => {
    this.setState({detailsOpen: !this.state.detailsOpen});
  };

  doReOrder = () => {
    const {invoices, reOrder} = this.props;
    if (invoices && invoices.length > 0) {
      reOrder(invoices[0].salesId);
    }
  };

  viewPdf = () => {
    const {auth, invoices, viewPdf} = this.props;
    if (!!auth && window) {
      if (invoices.length === 1) {
        const pdf =
          invoices[0] &&
          invoices[0]._links &&
          invoices[0]._links.pdf &&
          invoices[0]._links.pdf.href;
        window.open(`${pdf}?access_token=${auth.access_token}`);
      } else {
        viewPdf(invoices);
      }
    }
  };

  navToOrder = () => {
    const {invoices, goToOrder} = this.props;
    const isInvoiced = true;
    goToOrder(invoices[0].salesId, {setRefundMode: true}, isInvoiced);
  };

  render() {
    const {invoices} = this.props;
    const {invoicesTotal} = this.state;

    const renderDetails =
      this.state.detailsOpen &&
      invoices.map((invoice, index) => {
        const renderItems =
          invoice.items &&
          invoice.items.length > 0 &&
          invoice.items.map((item, i) => {
            const isSameDayDelivery = isServiceItem(item);
            const orderItem = isSameDayDelivery
              ? item.itemName || item.itemId
              : `${item.itemId} ${item.itemName && ' - ' + item.itemName} x ${
                  item.quantityInvoiced
                }`;

            const renderOrderItem = isSameDayDelivery ? (
              <ItemPropNonLink>{orderItem}</ItemPropNonLink>
            ) : (
              <ItemProp href={`/productDetail/${item.itemId}`}>
                {orderItem}
              </ItemProp>
            );
            return (
              <OrderItem key={item.itemId + i}>
                {renderOrderItem}
                <ItemPropValue>£{item.linePrice}</ItemPropValue>
              </OrderItem>
            );
          });
        return (
          <Items key={index}>
            Invoice {index + 1} <br />
            {renderItems}
          </Items>
        );
      });

    const renderDetailsButton = this.state.detailsOpen ? 'hide' : 'view';
    const viewInvoicesButtonText =
      invoices.length === 1 ? 'View invoice' : 'View invoices';

    const renderInvoicedOrderDescription = (
      <ActionButton onClick={this.viewPdf}>
        {viewInvoicesButtonText}
      </ActionButton>
    );
    const renderInvoiceDate = moment(invoices[0].invoiceDateTime).format(
      ORDER_HISTORY_DATE_FORMAT
    );

    const renderReOrderLink = (
      <ReOrder onClick={this.doReOrder}>Reorder these items</ReOrder>
    );
    const renderRequestBtn = invoices &&
      invoices[0] &&
      !!invoices[0].couldBeRefunded && (
        <RequestButton onClick={this.navToOrder}>Request Refund</RequestButton>
      );
    return (
      <OrderWrapper key={invoices[0].salesId}>
        <OrderRow>
          <OrderId>
            <RowTitle>Order</RowTitle> {invoices[0].salesId}
          </OrderId>
          <Total>£{invoicesTotal}</Total>
          <ActionButton onClick={this.openClose}>
            {renderDetailsButton}
          </ActionButton>
          {renderInvoicedOrderDescription}
          <Date>
            <RowTitle>Invoiced on {renderInvoiceDate}</RowTitle>
            {renderReOrderLink}
          </Date>
          {renderRequestBtn}
        </OrderRow>
        {renderDetails}
      </OrderWrapper>
    );
  }
}
