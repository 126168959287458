import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Left,
  Close,
  BackButton,
  GoBack,
  Title,
  Name,
  Account,
  LoginRegister,
} from './ControlBar.style';

export default class ControlBar extends PureComponent {
  static propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    title: PropTypes.object.isRequired,
    toggleLoginModal: PropTypes.func.isRequired,
    isRoot: PropTypes.bool,
    level: PropTypes.number,
    isLoggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    isMaintenanceMode: PropTypes.bool,
    goBack: PropTypes.func.isRequired,
    navigateToRegister: PropTypes.func.isRequired,
  };
  login = e => {
    e.preventDefault();
    const {toggleLoginModal, toggleMenu, isMaintenanceMode} = this.props;
    toggleMenu();
    if (isMaintenanceMode) {
      return;
    }
    toggleLoginModal();
  };
  register = e => {
    e.preventDefault();
    const {navigateToRegister, toggleMenu, isMaintenanceMode} = this.props;
    toggleMenu();
    if (isMaintenanceMode) {
      return;
    }
    navigateToRegister();
  };

  render() {
    const {
      toggleMenu,
      isRoot,
      isLoggedIn,
      profile,
      level,
      goBack,
      title,
      isMaintenanceMode,
    } = this.props;
    const renderLogin =
      isRoot &&
      (isLoggedIn ? (
        <Left>
          <Name>{profile && profile.name}</Name>
          <Account>Account: {profile && profile.id}</Account>
        </Left>
      ) : (
        <Left onClick={this.login} $disable={isMaintenanceMode}>
          <LoginRegister>Sign in / Register</LoginRegister>
        </Left>
      ));
    const renderBack = !isRoot && (
      <BackButton onClick={goBack}>
        <GoBack />
        Back
      </BackButton>
    );
    const renderTitle = !isRoot && <Title>{title[level]}</Title>;
    return (
      <Fragment>
        <Wrapper $isRoot={isRoot}>
          {renderBack}
          {renderLogin}
          {renderTitle}
          <Close onClick={toggleMenu} />
        </Wrapper>
      </Fragment>
    );
  }
}
