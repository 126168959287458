import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function branchReducer(state = initialState.branch, action) {
  switch (action.type) {
    case types.SET_BRANCH_SUCCESS:
      if (typeof window !== 'undefined') {
        document.cookie = `branch=${action.branch}`;
      }
      return action.branch;
    default:
      return state;
  }
}
