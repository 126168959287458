import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function isUseDefaultPbbReducer(
  state = initialState.isUseDefaultPbb,
  action
) {
  switch (action.type) {
    case types.SET_USE_DEFAULT_PBB_BANK_SUCCESS:
      return action.status;
    default:
      return state;
  }
}
