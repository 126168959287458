import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function vacanciesReducer(
  state = initialState.vacancies,
  action
) {
  switch (action.type) {
    case types.GET_VACANCIES_SUCCESS:
      return Object.assign({}, state, action.vacancies);
    default:
      return state;
  }
}
