import * as ActionTypes from './types.action';

export const getMarketingOptions = email => ({
  type: ActionTypes.GET_MARKETING_OPTIONS_REQUESTED,
  email,
});

export const updateMarketingOptions = (
  id,
  state,
  email,
  updateApi,
  formValues
) => ({
  type: ActionTypes.UPDATE_MARKETING_OPTIONS_REQUESTED,
  id,
  state,
  email,
  updateApi,
  formValues,
});

export const updateAllMarketingOptions = (email, state) => ({
  type: ActionTypes.UPDATE_ALL_MARKETING_OPTIONS_REQUESTED,
  email,
  state,
  updateApi: true,
});
