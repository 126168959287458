import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function slotReducer(state = initialState.slots, action) {
  switch (action.type) {
    case types.GET_FULFILL_SLOT_SUCCESS:
      return action.slots;
    case types.GET_FULFILL_SLOT_FAILED:
    case types.RESET_FULFILL_SLOTS_SUCCESS:
      return null;
    default:
      return state;
  }
}
