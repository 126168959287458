import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import moment from 'moment';

import {
  StyledWrapper,
  Content,
  Title,
  TextWrapper,
  OK,
} from './PopupModal.style';
import {Link} from '../LoginRegister/LoginRegister.style';
import {ORDER_HISTORY_PDF_DATE_FORMAT} from '../../constants/constants';

export default class InvoicesPDFModal extends PureComponent {
  render() {
    const {showModal, callback, invoices, accessToken} = this.props;

    const renderButton = (
      <OK onClick={callback} data-rw="modal--ok-button">
        Close
      </OK>
    );

    const renderContent = invoices && (
      <div>
        {invoices.map((invoice, index) => {
          const date = moment(invoice.invoiceDateTime).format(
            ORDER_HISTORY_PDF_DATE_FORMAT
          );
          const name = invoice.invoiceId;
          const pdf =
            invoice._links && invoice._links.pdf && invoice._links.pdf.href;

          return (
            <TextWrapper>
              <Link href={`${pdf}?access_token=${accessToken}`} target="_blank">
                View Invoice {index + 1} ({name})
              </Link>{' '}
              {date}
            </TextWrapper>
          );
        })}
      </div>
    );

    return (
      <ModalWrapper customStyles={loginStyles} isOpen={!!showModal}>
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>View invoices</Title>
              <div>{renderContent}</div>
              {renderButton}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
