import {put, call, takeLatest, select} from 'redux-saga/effects';
import settingsApi from '../api/settings.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {ERRORS} from '../constants/constants';

function* getAllSettings(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const settings = yield call(settingsApi.getAllSettings, headers);
    if (settings) {
      yield put({type: ActionTypes.GET_ALL_SETTINGS_SUCCESS, settings});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.GET_ALL_SETTINGS_FAILED,
        message: e.message,
      });
    }
  }
}

function* updateSetting(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    let settings;
    if (action.isRemove) {
      settings = yield call(settingsApi.removeSetting, action.setting, headers);
    } else {
      settings = yield call(
        settingsApi.updateSetting,
        action.setting,
        action.value,
        headers
      );
    }
    if (settings) {
      yield put({type: ActionTypes.UPDATE_SETTING_SUCCESS, settings});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({type: ActionTypes.UPDATE_SETTING_FAILED, message: e.message});
    }
  }
}

function* updateAllSettings(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const settings = yield call(
      settingsApi.updateAllSettings,
      action.settings,
      headers
    );
    if (settings) {
      yield put({type: ActionTypes.UPDATE_ALL_SETTINGS_SUCCESS, settings});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.UPDATE_ALL_SETTINGS_FAILED,
        message: e.message,
      });
    }
  }
}

export default function* settingsYield() {
  yield takeLatest(ActionTypes.GET_ALL_SETTINGS_REQUESTED, getAllSettings);
  yield takeLatest(ActionTypes.UPDATE_SETTING_REQUESTED, updateSetting);
  yield takeLatest(
    ActionTypes.UPDATE_ALL_SETTINGS_REQUESTED,
    updateAllSettings
  );
}
