import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function searchReducer(
  state = initialState.searchResults,
  action
) {
  switch (action.type) {
    case types.GET_MORE_SEARCH_RESULTS_SUCCESS:
      return [...state, ...action.searchResults];
    case types.GET_SEARCH_RESULTS_SUCCESS:
      return action.searchResults;
    default:
      return state;
  }
}
