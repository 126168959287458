import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
export const Wrapper = styled.article`
  width: 100%;
  margin-top: 2.4rem;
  padding-bottom: 4.4rem;
  ${media.medium`
    margin-top: 0;
    padding-bottom: 1rem;
  `};
`;

export const SectionTitle = styled.section`
  color: ${props => props.theme.secondaryColor};
  font-size: 3.6rem;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;
