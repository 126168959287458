import * as ActionTypes from './types.action';

export const search = (params, jjToken, fulfillmentDetails) => ({
  type: ActionTypes.GET_SEARCH_RESULTS_REQUESTED,
  params,
  jjToken,
  fulfillmentDetails,
});

export const moreSearch = (params, token, fulfillmentDetails) => ({
  type: ActionTypes.GET_MORE_SEARCH_RESULTS_REQUESTED,
  params,
  token,
  fulfillmentDetails,
});

export const getSearchSuggestions = keyword => ({
  type: ActionTypes.GET_SEARCH_SUGGESTIONS_REQUESTED,
  keyword,
});

export const resetSearchSuggestions = () => ({
  type: ActionTypes.GET_SEARCH_SUGGESTIONS_RESET,
});

export const getSearchFromServerSide = searchResults => ({
  type: ActionTypes.GET_SEARCH_RESULTS_SUCCESS,
  searchResults,
});

export const getSearchPageFromServerSide = page => ({
  type: ActionTypes.GET_SEARCH_RESULTS_PAGE_SUCCESS,
  page,
});

export const getFacetsFromServerSide = aggression => ({
  type: ActionTypes.GET_SEARCH_AGGREGATE_SUCCESS,
  aggression,
});

export const getSearchCategoryDescription = ({category, brand}) => ({
  type: ActionTypes.GET_SEARCH_CATEGORY_DESCRIPTION_REQUESTED,
  category,
  brand,
});
