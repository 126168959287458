import initialState from './initial.state';
import * as types from '../actions/types.action';
import {PAYMENT_TYPES} from '../constants/constants';

export default function profileReducer(state = initialState.profile, action) {
  switch (action.type) {
    case types.SET_PROFILE_SUCCESS:
    case types.SET_SELECTED_ACCOUNTS_SUCCESS:
      let paymentOptions = [];
      if (!action.account) {
        return null;
      }
      if (action.account.paymentOptions) {
        paymentOptions = [...action.account.paymentOptions];
        const foundIdx = paymentOptions.findIndex(
          el => el.mode === PAYMENT_TYPES.PBB.mode
        );
        if (foundIdx > 0) {
          const pbbOption = paymentOptions[foundIdx];
          paymentOptions.splice(foundIdx, 1);
          paymentOptions.unshift(pbbOption);
        }
      }
      if (paymentOptions.length > 0) {
        return Object.assign({}, action.account, {paymentOptions});
      }
      return action.account;
    case types.REFRESH_AX_ACCOUNT_SUCCESS:
      return action.account || null;
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
