import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link2} from '../../styles/components/links';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const HTag = css`
  color: ${p => p.theme.secondaryColor};
  text-transform: capitalize;
  font-size: 3.6rem;
  font-weight: lighter;
  display: inline-block;
  ${media.small`
    padding-left: 2rem;
    padding-right: 2rem;
  `};
  ${media.smaller`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
`;

export const MarkdownWrapper = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${p => (p.noMargin ? '2rem' : '4rem 0 2rem')};
  ${media.medium`
    padding: 0 2rem;
  `};
  ${media.smaller`
    margin: 0;
  `};

  h1 {
    ${HTag};
  }

  h2 {
    ${HTag};
    font-size: 3rem;
  }

  h3 {
    ${HTag};
    font-size: 2.6rem;
  }

  h4 {
    ${HTag};
    font-size: 2.2rem;
  }

  p,
  li,
  span {
    color: ${p => p.theme.textColor};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  p > a {
    color: ${p => p.theme.primaryColor};
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .tac {
    text-align: center;
  }

  .w70p {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.h1`
  color: ${p => p.theme.secondaryColor};
  text-transform: capitalize;
  font-size: 3.6rem;
  font-weight: lighter;
  margin: 0;
  ${media.small`
    padding: 0;
  `};
`;

export const UserInfo = styled.span`
  color: ${p => p.theme.quaternaryColor};
  font-size: 1.4rem;
  align-self: center;
  margin-right: 1rem;
  ${media.smaller`
    text-align: left;
    align-self: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
  `};
`;

export const SubTitle = styled(Title)`
  font-size: 2rem;
  ${media.smaller`
    padding-left: 0;
  `};
`;

export const Head = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.medium`
    padding: 2rem;
  `};
  ${media.smaller`
    flex-direction: column;
    padding: 2rem;
  `};
`;

export const Content = styled.div`
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  margin-top: ${p => (p.noMargin ? 0 : '4rem')};
  ${media.smaller`
    margin-top: 0;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${media.smaller`
    justify-content: space-between;
    flex-direction: column;
    padding-top: 2rem;
  `};
`;
export const ButtonSubWrapper = styled.div`
  display: flex;
  ${media.smaller`
    padding-top: ${p => (p.paddingTop ? '1rem' : 0)};
    justify-content: space-between;
    flex-direction: row;
  `};
`;

export const LinkButton = styled(Link2)`
  margin-right: ${p => (p.$nomargin ? 0 : '1rem')};
  text-align: center;
  ${media.smaller`
    padding: 0 1rem;
    max-width: 60%;
  `};
  ${media.smallest`
    text-align: center;
  `};
`;

export const ContainerStyle = css`
  width: 100%;
  display: flex;
  background-color: ${props => props.theme.secondaryBgColor};
  padding: 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  ${media.medium`
    margin-top: 0;
  `};
  ${media.smaller`
    padding: 0;
  `};
`;

export const Container = styled.div`
  ${ContainerStyle};

  .infinite-scroll-component {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
`;

export const Row = styled.div`
  display: flex;
  color: ${p => p.theme.textColor};
  margin-bottom: ${p => (p.$margin ? '1rem' : 0)};
  line-height: 3rem;
  ${media.small`
    flex-direction: column;
  `};
`;

export const SectionWrapper = styled.section`
  padding: 1rem 0;
  ${media.small`
    padding: 1rem;
  `};
`;

export const HideOnMobile = styled.span`
  ${media.smaller`
    display: none;
  `};
`;
