import {captureException} from '@sentry/browser';
import jwt_decode from 'jwt-decode';
import authApi from '../api/auth.api';
import {formatPostcode} from './string.helper';

import {
  ERRORS,
  HTTP_STATUS,
  MAX_RETRY,
  AX_MOBILE_REGEX,
  JJ_LOCAL_STORAGE,
  REGISTER_CUSTOMER_TYPES,
  GQL_ERROR_MESSAGE,
  REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA,
} from '../constants/constants';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
} from './localStorage.helper';
import {getApiConfig} from '../config/configProvider';
import {saveTokenValidTime} from './data.helper';
import {checkStaffAccount} from './accounts.helper';

let expiredAuthorization;
let newRefreshedToken;

export const getBearerHeader = (jjToken, storedTokens) => {
  let headers;
  if (jjToken || (storedTokens && storedTokens.access_token)) {
    const token = jjToken || storedTokens;
    headers = {
      Authorization: `Bearer ${
        typeof token === 'string' ? token : token.access_token
      }`,
    };
  }
  return headers;
};

const getTokenDataFromHeader = headers => {
  let token = null;
  if (headers && headers.Authorization) {
    const headerAuth = headers.Authorization.split('Bearer ');
    if (headerAuth && headerAuth[1]) {
      token = {
        access_token: headerAuth[1],
        grant_type: 'refresh_token',
      };
    }
  }
  return token;
};

const requestsLimit = {};

const refreshExpiredToken = async (
  axios,
  setAuthToken,
  res,
  config,
  unAuthError
) => {
  const originalRequest = config;
  const conf = getApiConfig();
  const refreshTokenData = await getDataFromLocalStorage(
    JJ_LOCAL_STORAGE.REFRESH_TOKEN
  );
  const tokenData = await getDataFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN);
  if (!tokenData || !refreshTokenData) {
    return {error: res.data || res};
  }
  expiredAuthorization = getBearerHeader(tokenData);
  let jwtToken;
  try {
    jwtToken = jwt_decode(tokenData);
  } catch (e) {
    return {error: res.data};
  }
  if (!!unAuthError && checkStaffAccount(jwtToken.authorities)) {
    return {error: res.data};
  }
  const refreshData = {
    refresh_token: refreshTokenData,
    grant_type: 'refresh_token',
    scope: conf.azureScopes,
    account: jwtToken.c_account,
  };
  try {
    const token = await authApi.refresh(refreshData);
    if (token) {
      const {access_token, refresh_token} = token;
      // set expired token
      const decodedToken = jwt_decode(access_token);
      const newAuth = {
        access_token: access_token,
        ...decodedToken,
      };
      setAuthToken(newAuth);
      // set new refreshed token
      newRefreshedToken = getBearerHeader(access_token);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.TOKEN, access_token);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.REFRESH_TOKEN, refresh_token);
      if (decodedToken.expires_in) {
        saveTokenValidTime(decodedToken.expires_in);
      }
      originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
      if (!requestsLimit[originalRequest.url]) {
        requestsLimit[originalRequest.url] = 1;
      } else if (requestsLimit[originalRequest.url] > MAX_RETRY) {
        requestsLimit[originalRequest.url] = 0;
        return {error: res};
      } else {
        requestsLimit[originalRequest.url] += 1;
      }
      return axios(originalRequest);
    }
  } catch (err) {
    const theError = (err && err.response && err.response.data) || {
      tokenRefresh: 'failed to refresh token',
    };
    return {error: theError};
  }
  return {error: res.data};
};

export const axiosHelper = async (axios, setAuthToken) => {
  axios.interceptors.request.use(req => {
    if (
      req &&
      req.headers &&
      req.headers.Authorization &&
      expiredAuthorization &&
      expiredAuthorization.Authorization === req.headers.Authorization &&
      newRefreshedToken
    ) {
      req.headers.Authorization = newRefreshedToken.Authorization;
    }
    return req;
  });
  axios.interceptors.response.use(
    async response => {
      if (
        response &&
        response.data &&
        response.data.errors &&
        response.data.errors.length === 1 &&
        response.data.errors[0] &&
        response.data.errors[0].message === GQL_ERROR_MESSAGE.UNAUTHORIZED
      ) {
        const result = await refreshExpiredToken(
          axios,
          setAuthToken,
          response,
          response.config,
          false
        );
        if (result) {
          if (result.error) {
            return Promise.reject(result.error);
          }
          return Promise.resolve(result);
        }
      }
      return response;
    },
    async error => {
      if (error && error.response) {
        const {status, data} = error.response;
        if (status === HTTP_STATUS.UNAUTHORIZED) {
          const unAuthError = true;
          const refreshResult = await refreshExpiredToken(
            axios,
            setAuthToken,
            error,
            error.config,
            unAuthError
          );
          if (refreshResult && refreshResult.error) {
            return Promise.reject(refreshResult.error);
          }
        }
        if (
          data &&
          (data.code === ERRORS.HTTP_SERVER_ERROR ||
            data.code === ERRORS.ILLEGAL_ARGUMENT)
        ) {
          return Promise.reject(data);
        }
        if (status === HTTP_STATUS.SERVER_ERROR) {
          return Promise.reject(data);
        }
      }
      return Promise.reject(error);
    }
  );
};

const getPhoneNumber = num => {
  const phone = num.replace(/ /g, '');
  return phone[0] === '0' ? phone.substr(1) : phone;
};

const checkPhoneType = num => {
  if (AX_MOBILE_REGEX.test(num)) {
    return 2;
  }
  return 1;
};

export const convertToOldRegisterApi = data => {
  let phonePrimary, phoneAlternative, phonePrimaryType, phoneAlternativeType;
  try {
    const {phone1, phone2} = data;
    phonePrimary = getPhoneNumber(phone1);
    phonePrimaryType = checkPhoneType(phonePrimary);
    if (phone2) {
      phoneAlternative = getPhoneNumber(phone2);
      phoneAlternativeType = checkPhoneType(phoneAlternative);
    }
  } catch (e) {
    captureException(e);
  }
  return {
    type: 'json',
    Version: 'v1',
    AccountType: data.isBusiness ? 2 : 1,
    CustomerName: `${data.firstname} ${data.surname}`,
    DateofBirthString: '10/10/2001',
    Country: 'GBR',
    County: data.county,
    Street: data.address,
    Postcode: formatPostcode(data.postcode),
    City: data.place,
    InvalidAddress: false,
    CustomerContactPrimary: phonePrimary,
    PhonePrimaryType: phonePrimaryType,
    CustomerContactAlternative: phoneAlternative,
    PhoneAlternativeType: phoneAlternativeType,
    CustomerEmail: data.email,
    CustomerPassword: data.password,
    latitude: data.latitude,
    longitude: data.longitude,
    ...(data.isBusiness && {
      BusinessName: data.businessName,
      TradingName: data.businessName,
    }),
    CustomerType: data.businessType || REGISTER_CUSTOMER_TYPES.CNM,
    marketing: data.marketing,
    ...(data.whereDidYouHearAboutUs !==
      REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA &&
      data.whereDidYouHearAboutUs && {
        WhereDidYouHearAboutUs: data.whereDidYouHearAboutUs,
      }),
  };
};

export const getTimestamp = isAppending => {
  const timestamp = Math.floor(Date.now() / 1000);
  return `${isAppending ? '&' : '?'}timestamp=${timestamp}`;
};
