import {put, call, takeLatest} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import blogApi from '../api/blog.api';

function* getBlogCategories() {
  try {
    const categoriesHomeData = yield call(blogApi.getAllBlogCategories);
    yield put({
      type: ActionTypes.GET_BLOG_CATEGORIES_HOME_INFO_SUCCESS,
      categoriesHomeData,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BLOG_CATEGORIES_HOME_INFO_FAILED,
      message: e.message,
    });
  }
}

function* getBlogCategoryBySlug(action) {
  try {
    const blogPosts = yield call(blogApi.getAllBlogCategoryBySlug, action.slug);
    yield put({
      type: ActionTypes.GET_BLOG_CATEGORY_SLUG_SUCCESS,
      blogPosts,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BLOG_CATEGORY_SLUG_FAILED,
      message: e.message,
    });
  }
}

function* getPostById(action) {
  try {
    const blogPost = yield call(blogApi.getBlogPostById, action.postId);
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_ID_SUCCESS,
      blogPost,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_ID_FAILED,
      message: e.message,
    });
  }
}

function* getPostBySlug(action) {
  try {
    const blogPost = yield call(blogApi.getBlogPostBySlug, action.postSlugName);
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_SLUG_SUCCESS,
      blogPost,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_SLUG_FAILED,
      message: e.message,
    });
  }
}

function* getPostByTitle(action) {
  try {
    const blogPostsSearch = yield call(
      blogApi.getBlogPostByTitle,
      action.postTitle
    );
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_TITLE_SUCCESS,
      blogPostsSearch,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BLOG_POST_BY_TITLE_FAILED,
      message: e.message,
    });
  }
}

export default function* getBranchYield() {
  yield takeLatest(
    ActionTypes.GET_BLOG_CATEGORIES_HOME_INFO_REQUESTED,
    getBlogCategories
  );
  yield takeLatest(
    ActionTypes.GET_BLOG_CATEGORY_SLUG_REQUESTED,
    getBlogCategoryBySlug
  );
  yield takeLatest(ActionTypes.GET_BLOG_POST_BY_ID_REQUESTED, getPostById);
  yield takeLatest(ActionTypes.GET_BLOG_POST_BY_SLUG_REQUESTED, getPostBySlug);
  yield takeLatest(
    ActionTypes.GET_BLOG_POST_BY_TITLE_REQUESTED,
    getPostByTitle
  );
}
