import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {CategoryBlocks, BreadCrumb} from './BlogPage.style';
import {getBlogCategoryBySlug} from '../../actions/blog.action';
import {strapiImage} from '../../helpers/image.helper';
import {DEFAULT_DATE_FORMAT} from '../../constants/constants';
import {Title, Head, Wrapper} from '../../styles/pages/pages';
import {
  BreadCrumbLinks,
  BreadCrumbResult,
  CurrentPage,
  Separator,
} from '../SearchPage/SearchPage.style';
import {PostBlockItem} from './PostBlockItem';

const BlogCategoryPage = ({match}) => {
  const {blogPosts} = useSelector(state => ({
    blogPosts: state.blogPosts,
  }));
  const [categoryTitle, setCategoryTitle] = useState('');
  const [isGettingPosts, setIsGettingPosts] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.slugName && !isGettingPosts) {
      const slugName = match.params.slugName;
      dispatch(getBlogCategoryBySlug(slugName));
      setIsGettingPosts(true);
      setCategoryTitle(slugName && slugName.replace('-', ' '));
    }
  }, [match && match.params]);

  const renderBlogPosts =
    blogPosts &&
    blogPosts.map((post, index) => {
      const strapiImg = strapiImage(post);
      return (
        <PostBlockItem
          key={post.id + index}
          slug={!!post && post.attributes.slug}
          categorySlug={match.params.slugName || 'post'}
          postImage={
            strapiImg.medium ||
            strapiImg.small ||
            strapiImg.thumbnail ||
            strapiImg.full
          }
          lastPublishedDate={moment(
            post.attributes.wixCreatedAt || post.attributes.createdAt
          ).format(DEFAULT_DATE_FORMAT)}
          title={post.attributes.title}
          minutesToRead={
            post && !!post.minutesToRead && `${post.minutesToRead} mins`
          }
        />
      );
    });
  return (
    <Wrapper>
      <Head>
        <Title>
          {categoryTitle ||
            (match.params.slugName && match.params.slugName.replace('-', ' '))}
        </Title>
      </Head>
      <CategoryBlocks>
        <BreadCrumb>
          <BreadCrumbLinks to="/">Home &gt; </BreadCrumbLinks>
          <BreadCrumbLinks to="/blog">Blog</BreadCrumbLinks>
          <CurrentPage>
            <Separator>&gt;</Separator>
            <BreadCrumbResult>{categoryTitle}</BreadCrumbResult>
          </CurrentPage>
        </BreadCrumb>
        {renderBlogPosts}
      </CategoryBlocks>
    </Wrapper>
  );
};
export default BlogCategoryPage;
