import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function branchListReducer(
  state = initialState.branchList,
  action
) {
  switch (action.type) {
    case types.GET_BRANCH_LIST_SUCCESS:
      return action.branchList;
    default:
      return state;
  }
}
