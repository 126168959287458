import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Wrapper, TheHead, Content, ArrowDown, ArrowUp} from './Filter.style';
import {FILTERS, FILTERS_LIMIT, FILTERS_NAME} from '../../constants/constants';
import FilterItem from './FilterItem';
import {getCategoriesSize} from '../../helpers/data.helper';

export default class Filter extends PureComponent {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    isCategorySearch: PropTypes.bool.isRequired,
    isRoot: PropTypes.bool.isRequired,
    openStatus: PropTypes.object,
    toggleStatus: PropTypes.func,
    filterCounts: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const {filter} = props;
    const isCategoryList = filter.name === FILTERS_NAME.categoryList;
    let totalCategoriesSize = 0;
    if (isCategoryList) {
      totalCategoriesSize = getCategoriesSize(filter.counts);
    }
    this.state = {
      onlyTopLevel: totalCategoriesSize > FILTERS_LIMIT,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {filter} = this.props;
    const isCategoryList = filter.name === FILTERS_NAME.categoryList;
    if (filter !== prevProps.filter && isCategoryList) {
      const totalCategoriesSize = getCategoriesSize(filter.counts);
      this.setState({onlyTopLevel: totalCategoriesSize > FILTERS_LIMIT});
    }
  }

  toggle = () => this.props.toggleStatus(this.props.filter.name);

  render() {
    const {
      filter,
      filterCounts,
      onFilterChange,
      isRoot,
      isApplied,
      index,
      isCategorySearch,
      openStatus,
    } = this.props;
    const renderItems =
      filterCounts &&
      filterCounts.map(item => (
        <FilterItem
          key={item.id + item.query}
          item={item}
          onCheckboxChange={onFilterChange}
          isRoot={isRoot}
          isChecked={item.selected}
          parentIndex={item.id + index}
          parentQuery={item.query}
          onlyTopLevel={this.state.onlyTopLevel}
          isCategorySearch={isCategorySearch}
        />
      ));
    const renderArrow =
      filter &&
      filter.counts &&
      filter.counts.length > 0 &&
      !!openStatus[filter.name] ? (
        <ArrowDown />
      ) : (
        <ArrowUp />
      );
    return (
      <Wrapper>
        <TheHead onClick={this.toggle} $isApplied={isApplied}>
          {FILTERS[filter.name]}
          {renderArrow}
        </TheHead>
        <Content $isShown={openStatus[filter.name]}>{renderItems}</Content>
      </Wrapper>
    );
  }
}
