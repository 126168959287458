import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const StyledPicker = styled.div`
  max-height: 3.8rem;
  .SingleDatePicker {
    width: 96%;
  }
  .SingleDatePickerInput__withBorder {
    border-color: ${p => p.theme.borderColor};
  }
  .SingleDatePickerInput {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .DateInput {
    width: 80%;
  }
  .DateInput_input {
    font-size: 1.4rem;
    line-height: normal;
    padding: 1rem;
    color: ${p => p.theme.textColor};
  }
  .DateInput_input__focused {
    color: ${p =>
      p.$isDelivery ? p.theme.primaryColor : p.theme.secondaryColor};
    border-bottom-color: ${p =>
      p.$isDelivery ? p.theme.primaryColor : p.theme.secondaryColor};
  }
  .DateInput_fang,
  .SingleDatePicker_picker {
    margin-top: -1.2rem;
    z-index: 10;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    border-color: ${p =>
      p.$isDelivery ? p.theme.primaryColor : p.theme.secondaryColor};
    background-color: ${p =>
      p.$isDelivery ? p.theme.primaryColor : p.theme.secondaryColor};
  }
  .SingleDatePickerInput_calendarIcon {
    ${media.smaller`
      margin: 0 0.5rem;
    `};
  }
`;

export const StyledRangePicker = styled.div`
  margin-right: 2rem;
  .DateInput {
    width: 34%;
    max-height: 4rem;
  }
  .DateRangePickerInput {
    max-height: 4rem;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    border-color: ${p => p.theme.secondaryColor};
    background-color: ${p => p.theme.secondaryColor};
  }
  .DateInput_input__focused {
    border-bottom-color: ${p => p.theme.secondaryColor};
  }
  .DateInput_input {
    text-align: center;
    font-size: 1.6rem;
    color: ${p => p.theme.textColor};
    height: 3.8rem;
  }
  .CalendarDay__selected_span {
    border-color: ${p => p.theme.selectionRangeColor};
    background-color: ${p => p.theme.selectionRangeColor};
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: ${p => `33px solid ${p.theme.secondaryColor}`};
  }
  ${media.small`
    .DateRangePickerInput_calendarIcon {
      display: none;
    }
    .DateInput {
      width: 42%;
      max-height: 4rem;
    }
  `};
  ${media.smaller`
    margin-right: 0;
  `};
`;
