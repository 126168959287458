import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {
  Button3,
  Button3Inverted,
  Button3b,
} from '../../styles/components/buttons';
import {Dropdown} from '../../styles/components/dropdown';

export const Container = styled.div`
  width: 100%;
  background-color: ${p => p.theme.primaryColor};
  padding: 2rem;
`;

export const Title = styled.div`
  width: 26rem;
  text-transform: capitalize;
  font-size: 2rem;
  color: ${p => p.theme.primaryTextColor};
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: ${p => p.theme.secondaryColor};
  ${media.small`
    width: 100%;
  `};
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${p => p.theme.tertiaryBgColor};
  min-height: 17rem;
  padding: 2rem;
  display: flex;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const SlotContent = styled.div`
  padding: 2rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  border-bottom: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
`;

export const Panel = styled.div`
  width: ${p => (p.$fullWidth ? '100%' : '50%')};
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.small`
    width: ${p => (p.$fullWidth ? '100%' : '60%')};
  `};
  ${media.smaller`
    width: 100%;
  `};
`;

export const FormPanel = styled(Panel)`
  display: ${p => (p.$hide ? 'none' : 'block')};
  color: ${p =>
    p.$isDelivery ? p.theme.primaryColor : p.theme.secondaryColor};
  ${media.small`
    width: ${p => (p.$fullWidth ? '100%' : '40%')};
  `};
  ${media.smaller`
    width: 100%;
  `};
`;

export const FulfillmentWrapper = styled.div`
  display: flex;
  justify-content: ${p => (p.$center ? 'center' : 'flex-start')};
`;

export const Fulfillment = styled.div`
  display: flex;
  margin: 1rem 0;
  width: ${p => (p.$center ? '40%' : '100%')};
  justify-content: ${p => (p.$center ? 'center' : 'flex-start')};
  ${media.smaller`
    width: 100%;
  `};
`;

export const SlotChosenTitle = styled.div`
  text-align: center;
  font-size: 1.6rem;
  width: 100%;
  padding: 1rem;
`;

export const FulfillmentInfo = styled.div`
  display: ${p => (p.$hide ? 'none' : 'block')};
  padding-right: 3rem;
`;

export const NextSlotWrapper = styled.div`
  display: flex;
  line-height: 2rem;
  padding-bottom: 1rem;
  cursor: pointer;
  ${media.smaller`
    flex-direction: column;
    min-height: 2rem;
  `};
`;
export const NextSlot = styled.span`
  color: ${p => p.theme.secondaryColor};
  padding-left: 0.4rem;
  ${media.smaller`
     display: block;
  `};
`;

export const BranchWrapper = styled.div`
  display: flex;
  line-height: 2rem;
  padding-bottom: 3rem;
  cursor: pointer;
  ${media.smaller`
    flex-direction: column;
    padding-bottom: 2rem;
  `};
`;

export const Branch = styled.span`
  color: ${p => p.theme.primaryColor};
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  padding: 0 0.4rem;
  ${media.small`
    padding: 0;
  `};
  ${media.smaller`
    margin-top: 1rem;
    align-items: center;
    height: 4rem;
  `};
`;

export const LoadingWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  min-height: 2rem;
  color: ${p => (p.$warning ? p.theme.tertiaryColor : p.theme.textColor)};
`;

export const TextWrapper = styled.div`
  margin: 2rem 0;
`;

export const OrderingFrom = styled(Text)`
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 0.4rem;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 1rem 1rem 2rem;
  ${media.small`
    display: block;
  `};
  ${media.smaller`
    margin: 2rem 0;
  `};
`;

export const Block = styled.div`
  width: 50%;
  position: relative;
  ${media.small`
    width: 100%;
    margin-bottom: 2rem;
  `};
`;

export const ButtonWithText = styled.div`
  display: flex;
  width: 100%;
`;

export const WideBlock = styled.div`
  width: 100%;
  position: relative;
  ${media.small`
    margin-bottom: 2rem;
  `};
`;

export const InputLabel = styled.div`
  text-transform: uppercase;
  width: 100%;
  min-height: 3rem;
`;

export const InputDateLabel = styled(InputLabel)`
  min-height: 2rem;
  padding: 1rem 1rem 0;
  margin: 0;
  ${media.smaller`
    padding: 0;
    margin-bottom: -1rem;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const Button = styled(Button3)`
  min-height: 3.4rem;
  width: 43%;
  box-sizing: content-box;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  margin-top: 2rem;
  margin-right: ${p => (p.$left ? '1rem' : 0)};
  margin-left: ${p => (p.$right ? '1rem' : 0)};
  text-transform: uppercase;
  ${media.smaller`
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  `};
`;

export const ChangeSlotButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChangeSlotButton = styled(Button3Inverted)`
  min-height: 3.4rem;
  width: 43%;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  ${media.smaller`
    width: 100%;
  `};
`;
export const OptionInfo = styled.input`
  width: 96%;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  padding-left: 1rem;
  border: solid 0.1rem
    ${p => (p.$isError ? p.theme.tertiaryColor : p.theme.borderColor)};
  outline: none;
  height: 3.8rem;

  &::placeholder {
    color: ${p => p.theme.quaternaryTextColor};
  }

  margin-bottom: ${p => (!p.$isError && p.$standalone ? '1rem' : 0)};
`;

export const Time = styled(Dropdown)`
  color: ${p => p.theme.tertiaryTextColor};
  width: 96%;
  position: relative;
  z-index: 2;
  background-color: ${p => p.theme.tertiaryBgColor};
  border: solid 0.1rem
    ${p => (p.$isError ? p.theme.tertiaryColor : p.theme.borderColor)};
  margin-bottom: ${p => (!p.$isError && p.$standalone ? '1rem' : 0)};
`;

export const InputError = styled.div`
  color: ${p => p.theme.tertiaryColor};
  background-color: ${p => p.theme.errorBgColor};
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 1rem;
  margin: 0 1.8rem 1rem 0;
`;

export const SlotButton = styled(Button3b)`
  min-width: 13rem;
  max-width: 13rem;
  text-align: center;
  color: ${p =>
    p.$disabled
      ? p.theme.disabledColor
      : p.$isSelected
      ? p.theme.primaryTextColor
      : p.theme.primaryColor};
  background-color: ${p =>
    p.$isSelected ? p.theme.primaryColor : 'transparent'};

  &:hover {
    svg {
      fill: ${p => p.theme.primaryTextColor};
    }
  }

  ${media.smaller`
    min-width: 11rem;
    max-width: 11rem;
  `};
`;

export const NextSlotButton = styled.div`
  visibility: hidden;
  width: 1px;
  height: 1px;
`;

export const ButtonInfo = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: ${p => p.theme.secondaryColor};
`;

export const CheckBoxWrapper = styled.p`
  padding-left: 6rem;
`;
