import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function isStaffAccountReducer(
  state = initialState.isStaffAccount,
  action
) {
  if (action.type === types.SET_STAFF_ACCOUNT_SUCCESS) {
    return action.isStaffAccount;
  } else {
    return state;
  }
}
