import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function searchCategoryDescriptionReducer(
  state = initialState.searchCategoryDescription,
  action
) {
  switch (action.type) {
    case types.GET_SEARCH_CATEGORY_DESCRIPTION_SUCCESS:
      return action.description;
    case types.GET_SEARCH_CATEGORY_DESCRIPTION_FAILED:
    default:
      return state;
  }
}
