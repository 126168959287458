import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';

import {getBranchList, setBranch} from '../../actions/branch.action';
import {search} from '../../actions/search.action';
import {
  ANALYTICS_EVENTS,
  DATE_PICKER_REGEX,
  DEFAULT_BRANCH,
  FORM_ERRORS,
  JJ_LOCAL_STORAGE,
  JJ_TEL,
  MAX_DRIVER_INSTRUCTION_LENGTH,
  MAX_REFERENCE_LENGTH,
  NO_CONTACT_OPTION,
  ORDER_SERVICE_DATE_FORMAT,
  ROUTES,
  SERVICE_TYPE,
  SETTINGS,
  SLOT_FULFILMENT_STATUSES,
} from '../../constants/constants';
import RenderDatePicker from '../../components/DatePicker/RenderDatePicker';
import {
  Container,
  Title,
  Content,
  Panel,
  FormPanel,
  Fulfillment,
  Text,
  OrderingFrom,
  BranchWrapper,
  Branch,
  Row,
  WideBlock,
  Block,
  InputLabel,
  OptionInfo,
  InputError,
  ButtonWrapper,
  Button,
  ChangeSlotButtonWrapper,
  ChangeSlotButton,
  Time,
  SlotContent,
  TextWrapper,
  LoadingWrapper,
  FulfillmentInfo,
  SlotChosenTitle,
  FulfillmentWrapper,
  SlotButton,
  ButtonWithText,
  ButtonInfo,
  InputDateLabel,
  NextSlotButton,
  NextSlotWrapper,
  NextSlot,
  CheckBoxWrapper,
} from './BookSlotPage.style';
import Errors from '../../components/Errors/Errors';
import {
  getFulfillSlot,
  setFulfillSlot,
  removeFulfillSlot,
} from '../../actions/slots.action';
import {setDriverInstruction} from '../../actions/driverInstruction.action';
import {resetError} from '../../actions/error.action';
import PopupModal from '../../components/PopupModal/PopupModal';
import Loading from '../../components/Loading/Loading';
import {CenterContent} from '../../styles/components/layout';
import UnauthInfoPanel from '../../components/UnauthInfoPanel/UnauthInfoPanel';
import BookSlotStatus from '../../components/BookSlotStatus/BookSlotStatus';
import SlotsModal from '../../components/PopupModal/SlotsModal';
import {removeValueFromLocalStorage} from '../../helpers/localStorage.helper';
import AllBranchList from '../../components/AllBranchList/AllBranchList';
import {setCurrentRoute} from '../../actions';
import FulfillmentBtn from '../../components/FulfillmentBtn/FulfillmentBtn';
import queryString from 'query-string';
import {
  checkSlotExpired,
  checkSlotTimeAvailability,
  getNextSlotAfterNext,
  getPreselectedDate,
  shouldNavigateForBookSlot,
  updateDriverInstructions,
} from '../../helpers/basket.helper';
import {
  removeSetting,
  setSetting,
  updateAllSettings,
} from '../../actions/settings.action';
import {
  ifAfterChargePeriod,
  checkIfToday,
  getTodayDate,
  getTomorrowDate,
  getDDTime,
  hasChargeFee,
  getSlotByTime,
  getInvalidNumberError,
  getNextSlotInfo,
} from '../../helpers/data.helper';
import SlotFeeModal from '../../components/PopupModal/SlotFeeModal';
import {Link} from '../../components/PopupModal/PopupModal.style';
import {Checkbox} from '../../components/Marketing/Checkbox';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {ContactLink} from '../TrackingPage/TrackingPage.style';

class BookSlotPage extends PureComponent {
  constructor(props) {
    super(props);
    const {basket, branch, settings, profile, location} = props;
    this.showNearlyExpiredModal = false;
    this.updatingSlot = false;
    this.expiredSlotRemoved = false;
    this.setFromSettings = false;
    this.setFromBasket = false;
    this.setDriverInstructionsFromSettings = false;
    this.setContactNumberFromSettings = false;
    this.setIntialFulfillment = false;
    this.getIntialSlot = false;
    this.reserveButtonClicked = false;
    this.checkOutButtonClicked = false;
    this.goCheckout = false;
    this.todaySlotTimer = null;
    this.state = {
      fulfillmentType:
        (location.state && location.state.fulfillmentType) ||
        (basket &&
          basket.fulfillAtSlot &&
          basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
          basket.fulfillmentType) ||
        (settings &&
          settings.fulfillment &&
          settings.fulfillment.toUpperCase()) ||
        null,
      date: null,
      time: {},
      timesRange: null,
      currentSlotTime: '',
      branchId:
        (basket && basket.branchId) ||
        (settings && settings.branchId) ||
        (profile && profile.branchId) ||
        branch,
      loadingData: !basket,
      slotExpired: false,
      slotNearlyExpired: false,
      slotTimestamp: null,
      reservingSlot: false,
      previousRoute: null,
      goCheckout: false,
      selectANewSlot: false,
      slotReference: '',
      driverInstruction: '',
      deliveryContactNumber: '',
      previousUrl: null,
      isTodaySlot: false,
      hasSddSlot: false,
      todayDate: '',
      tomorrowDate: '',
      isInvalidSDD: false,
      incurNDDTime: null,
      incurSDDTime: null,
      showSlotFeeModal: false,
      nextSlotInfo: '',
      slotTimeNotSetError: false,
      showSlotTimeNotSetModal: false,
      informMeAboutDeliveryAreaChange: false,
    };
  }

  static getDerivedStateFromProps(props) {
    const {nextRoute, errors, loading} = props;
    if (nextRoute) {
      return {
        redirect: nextRoute,
      };
    }
    if (loading && loading.gettingBasket) {
      return {
        loadingData: true,
      };
    }
    if (errors && (errors.getSlot || errors.setSlot)) {
      return {
        error: errors.getSlot || errors.setSlot,
        reservingSlot: false,
        loadingData: false,
      };
    }
    return null;
  }

  async componentDidMount() {
    const {setCurrentRoute, route, location} = this.props;
    this.setState({
      previousRoute: route,
      previousUrl: (location.state && location.state.previousUrl) || null,
    });
    setCurrentRoute(ROUTES.BOOK_SLOT);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history,
      getFulfillSlot,
      errors,
      slots,
      resetError,
      relogin,
      basket,
      auth,
      settings,
      navigateToCheckout,
      branch,
      location,
      profile,
    } = this.props;
    const {
      branchId,
      redirect,
      fulfillmentType,
      selectANewSlot,
      driverInstruction,
      reservingSlot,
      loadingData,
      goCheckout,
      previousRoute,
      previousUrl,
    } = this.state;
    if (prevState.redirect !== redirect) {
      history.push(redirect);
    }
    if (errors && errors.requireLogin) {
      resetError();
      relogin();
    }
    if (settings && settings.fulfillment && fulfillmentType === null) {
      this.setState({fulfillmentType: settings.fulfillment.toUpperCase()});
    }
    if (
      !this.setFromBasket &&
      !selectANewSlot &&
      basket &&
      basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
      basket.fulfillmentType &&
      fulfillmentType !== basket.fulfillmentType
    ) {
      // fixed the fulfillmentType is different to the the settings
      this.setFromBasket = true;
      this.setState({fulfillmentType: basket.fulfillmentType});
    }
    if (
      settings &&
      ((basket && !basket.fulfillAtSlot) || !basket || selectANewSlot)
    ) {
      if (!this.setFromSettings) {
        this.setFromSettings = true;
        this.setState({
          branchId:
            (location && location.state && location.state.branchId) ||
            (settings && settings.branchId) ||
            (profile && profile.branchId) ||
            DEFAULT_BRANCH,
        });
      }
      if (!this.setContactNumberFromSettings) {
        this.setContactNumberFromSettings = true;
        this.setState({
          deliveryContactNumber:
            (settings &&
              (settings.deliveryContactNumber || settings.deliveryNumber)) ||
            '',
        });
      }
      if (!this.setDriverInstructionsFromSettings) {
        if (
          driverInstruction === '' ||
          driverInstruction !== (basket && basket.driverInstruction) ||
          driverInstruction !== (settings && settings.instructions)
        ) {
          this.setDriverInstructionsFromSettings = true;
          let isContactless = false;
          if (
            basket &&
            basket.driverInstruction &&
            basket.driverInstruction.includes(NO_CONTACT_OPTION)
          ) {
            isContactless = true;
          }
          const driverInstruction = (
            (basket && basket.driverInstruction) ||
            (settings && settings.instructions) ||
            ''
          )
            .replace(new RegExp(/ - Contactless.*/), '')
            .replace(
              `${settings && settings[SETTINGS.DELIVERY_CONTACT_NUMBER]} - `,
              ''
            );
          this.setState({driverInstruction, isContactless});
        }
      }
    }
    if (
      !this.getIntialSlot &&
      fulfillmentType !== null &&
      (!branchId || prevProps.branch !== branch) &&
      auth &&
      auth.enhanced &&
      basket &&
      !basket.fulfillAtSlot &&
      settings
    ) {
      this.getIntialSlot = true;
      getFulfillSlot(branch, fulfillmentType);
      this.setState({branchId: branch});
    }
    if (!this.setIntialFulfillment && fulfillmentType !== null && settings) {
      this.setIntialFulfillment = true;
      let myFulfillmentType = settings.fulfillment
        ? settings.fulfillment.toUpperCase()
        : SERVICE_TYPE.COLLECTION;
      if (location.state && location.state.fulfillmentType) {
        myFulfillmentType = location.state.fulfillmentType;
        history.replace(location.pathname, {});
      }
      this.setState({fulfillmentType: myFulfillmentType}, () => {
        getFulfillSlot(branchId, myFulfillmentType);
      });
    }
    if (
      (basket && !basket.fulfillAtSlot && !prevProps.basket) ||
      (this.state.loadingData && basket && basket.fulfillAtSlot)
    ) {
      this.setState({
        loadingData: false,
      });
    }
    // get corresponding slot when user remove the current slot
    if (
      basket &&
      prevProps.basket &&
      prevProps.basket.fulfillAtSlot &&
      !basket.fulfillAtSlot &&
      fulfillmentType !== null &&
      settings
    ) {
      getFulfillSlot(branchId, fulfillmentType);
    }
    if (
      basket &&
      basket.fulfillAtSlot &&
      prevProps.basket &&
      prevProps.basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
      prevProps.basket.fulfillAtSlot !== basket.fulfillAtSlot &&
      errors &&
      !errors.updateBasket &&
      ((prevProps.basket &&
        JSON.stringify(basket.fulfillAtSlot) !==
          JSON.stringify(prevProps.basket.fulfillAtSlot)) ||
        !prevProps.basket ||
        JSON.stringify(basket.fulfillment) !==
          JSON.stringify(prevProps.basket.fulfillment) ||
        this.reserveButtonClicked ||
        this.checkOutButtonClicked)
    ) {
      if (reservingSlot || loadingData) {
        this.setState({
          reservingSlot: false,
          loadingData: false,
        });
      }
      if (
        prevProps.basket.fulfillAtSlot.day === basket.fulfillAtSlot.day &&
        prevProps.basket.fulfillAtSlot.from === basket.fulfillAtSlot.from &&
        prevProps.basket.fulfillAtSlot.to === basket.fulfillAtSlot.to
      ) {
        return;
      }
      if (goCheckout) {
        navigateToCheckout();
        return;
      }
      if (previousRoute) {
        switch (previousRoute) {
          case ROUTES.SEARCH:
          case ROUTES.SEARCH_RESULTS:
            if (previousUrl) {
              try {
                const url = queryString.parse(previousUrl.split('?')[1]);
                const newParams = Object.assign(
                  {},
                  {previousUrl: url},
                  {
                    b: branchId,
                    page: 0,
                  }
                );
                history.push({
                  pathname: '/search',
                  search: queryString.stringify(newParams),
                });
              } catch (e) {
                window && window.history.back();
              }
            } else {
              window && window.history.back();
            }
            break;
          case ROUTES.OFFERS:
            history.push('/offers');
            break;
          case ROUTES.FAVOURITES:
            history.push('/favourites');
            break;
          case ROUTES.RECENT_PURCHASES:
            history.push('/recentpurchases');
            break;
          case ROUTES.PRODUCT:
            window && window.history.back();
            break;
          default:
            history.push('/');
        }
      } else if (this.reserveButtonClicked) {
        // prevent redirect after browser refresh
        history.push('/');
      }
    }
    if (
      fulfillmentType !== null &&
      (prevState.branchId !== branchId ||
        (auth &&
          settings &&
          auth.enhanced &&
          auth.c_account !== (prevProps.auth && prevProps.auth.c_account)))
    ) {
      this.setState({
        date: null,
        isTodaySlot: false,
        timesRange: null,
      });
      getFulfillSlot(branchId, fulfillmentType);
    }
    if (
      basket &&
      basket.fulfillAtSlot &&
      basket.fulfillAtSlot.day &&
      basket.fulfillAtSlot.from &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
      !this.state.slotExpired &&
      !this.updatingSlot &&
      !this.expiredSlotRemoved
    ) {
      const slotStatus = checkSlotExpired(
        basket,
        true,
        this.showNearlyExpiredModal
      );
      if (slotStatus) {
        if (slotStatus.slotExpired) {
          if (location.state && location.state.slotRemoved) {
            this.expiredSlotRemoved = true;
            history.replace(location.pathname, {});
          } else {
            this.setState({slotExpired: true});
          }
        } else if (slotStatus.slotNearlyExpired) {
          this.showNearlyExpiredModal = true;
          this.setState({
            slotNearlyExpired: true,
            slotTimestamp: slotStatus.slotTimestamp,
          });
        }
      }
    }

    if (slots && prevProps.slots !== slots) {
      const keyOfDays = Object.keys(slots);
      if (keyOfDays.length <= 0) {
        // todo popup error
      }
      const isDelivery = this.state.fulfillmentType === SERVICE_TYPE.DELIVERY;
      let firstDay = getPreselectedDate(keyOfDays, isDelivery);
      const time = slots[firstDay] && slots[firstDay].availability[0];
      const todayDate = getTodayDate();
      const isTodaySlot = firstDay === todayDate;
      const tomorrowDate = getTomorrowDate();
      let incurNDDTime;
      let incurSDDTime;
      const nextSlotInfo = getNextSlotInfo(
        firstDay,
        slots,
        isTodaySlot,
        firstDay === tomorrowDate
      );
      if (isDelivery) {
        if (slots[todayDate]) {
          incurSDDTime = getDDTime(slots[todayDate], true);
          if (
            slots &&
            slots[todayDate] &&
            slots[todayDate].availability &&
            slots[todayDate].availability[0] &&
            slots[todayDate].availability[0].timeStampToCutOffUTC
          ) {
            // disable the today's button if pass cutofftime
            const chargeTime =
              slots[todayDate].availability[0].timeStampToCutOffUTC;
            const duration = moment.utc().valueOf() - chargeTime * 1000;
            if (duration < 0) {
              this.todaySlotTimer = setTimeout(
                this.disableSDD,
                Math.abs(duration)
              );
            } else {
              this.disableSDD();
            }
          }
        }
        if (slots[tomorrowDate]) {
          incurNDDTime = getDDTime(slots[tomorrowDate]);
        }
      }
      const hasSddSlot =
        keyOfDays && keyOfDays[0] && slots[keyOfDays[0]].availability[0]
          ? checkIfToday(keyOfDays[0])
          : false;
      this.setState({
        date: firstDay,
        timesRange: slots[firstDay],
        isTodaySlot,
        hasSddSlot,
        time,
        slotReference: (basket && basket.slotReference) || '',
        todayDate,
        tomorrowDate,
        incurNDDTime,
        incurSDDTime,
        nextSlotInfo,
      });
    }
  }

  componentWillUnmount() {
    if (this.todaySlotTimer) {
      clearTimeout(this.todaySlotTimer);
    }
  }

  disableSDD = () => {
    this.setState({isInvalidSDD: true});
  };

  handleSlotReferenceChange = e => {
    e.preventDefault();
    this.setState({
      slotReference: e.target.value,
    });
  };
  handleDriverInstructionChange = e => {
    e.preventDefault();
    this.setState({
      driverInstruction: e.target.value,
    });
  };
  handleContactNumberChange = e => {
    e.preventDefault();
    this.setState({
      deliveryContactNumber: e.target.value.replace(/[^\d+]/gi, ''),
      deliveryContactNumberError: null,
    });
  };
  resetError = () => {
    const {branch, getFulfillSlot, errors, basket} = this.props;
    if (errors && errors.setSlot) {
      getFulfillSlot(
        branch,
        this.state.fulfillmentType || (basket && basket.fulfillmentType)
      );
    }
    this.props.resetError();
    this.setState({error: null});
  };

  postCodeOutsideOfDeliveryArea = () => {
    this.props.resetError();
    this.setState({error: null});
    location.reload();
  };
  hideSlotTimeNoSetModal = () =>
    this.setState({showSlotTimeNotSetModal: false});

  changeToDelivery = () => {
    this.setIntialFulfillment = true;
    this.setFromSettings = false;
    this.setState({
      fulfillmentType: SERVICE_TYPE.DELIVERY,
      date: null,
      timesRange: null,
    });
    this.props.getFulfillSlot(this.state.branchId, SERVICE_TYPE.DELIVERY);
  };

  changeToCollection = () => {
    this.setIntialFulfillment = true;
    this.setState({
      fulfillmentType: SERVICE_TYPE.COLLECTION,
      date: null,
      isTodaySlot: null,
      timesRange: null,
    });
    this.props.getFulfillSlot(this.state.branchId, SERVICE_TYPE.COLLECTION);
  };

  setNextAvailableSlotAfterNext = () => {
    // for pingdom testing purpose
    const {slots} = this.props;
    const {fulfillmentType} = this.state;
    const timesObj = getNextSlotAfterNext(
      slots,
      fulfillmentType === SERVICE_TYPE.DELIVERY
    );
    if (timesObj.selectedTimesRange && timesObj.time) {
      const {time, selectedTimesRange} = timesObj;
      this.setState({
        date: selectedTimesRange.date,
        timesRange: selectedTimesRange,
        time,
        currentSlotTime: `${time.from} - ${time.to}`,
      });
    }
  };

  changeBranch = e => {
    const branchId = e.target.value;
    this.setState({branchId});
    this.props.setBranch(branchId);
  };

  onSwitchToToday = () => {
    const {todayDate, isInvalidSDD} = this.state;
    if (isInvalidSDD) {
      return;
    }
    if (ifAfterChargePeriod(this.props.slots[todayDate], true)) {
      this.setState({isInvalidSDD: true});
      return;
    }
    this.onDateChange(todayDate);
  };

  onSwitchToTomorrow = () => {
    const {tomorrowDate} = this.state;
    if (ifAfterChargePeriod(this.props.slots[tomorrowDate])) {
      this.setState({incurNDDTime: null});
      return;
    }
    this.onDateChange(tomorrowDate);
  };

  onDateChange = selectedDate => {
    if (!selectedDate) {
      return;
    }
    const day =
      selectedDate instanceof moment
        ? selectedDate.format(ORDER_SERVICE_DATE_FORMAT)
        : selectedDate;
    const selectedTimesRange = this.props.slots[day];
    if (
      !selectedTimesRange ||
      (selectedTimesRange && !selectedTimesRange.availability)
    ) {
      return;
    }
    const time =
      selectedTimesRange &&
      checkSlotTimeAvailability(
        this.state.time,
        selectedTimesRange.availability
      );
    const isTodaySlot = checkIfToday(selectedTimesRange.date);
    this.setState({
      date: selectedTimesRange.date,
      timesRange: selectedTimesRange,
      time,
      isTodaySlot,
      currentSlotTime: '',
    });
  };

  settingFulfillmentSlot = goCheckout => {
    const {
      setDriverInstruction,
      updateAllSettings,
      basket,
      setFulfillSlot,
    } = this.props;
    const {
      driverInstruction,
      branchId,
      fulfillmentType,
      slotReference,
      timesRange,
      deliveryContactNumber,
      selectANewSlot,
    } = this.state;
    if (driverInstruction) {
      setDriverInstruction(driverInstruction);
    }
    const driverInstructions = updateDriverInstructions(
      driverInstruction,
      this.state.isContactless,
      deliveryContactNumber
    );
    if (timesRange && !timesRange.date) {
      return;
    }
    const time = Object.assign({}, this.state.time, {
      day: timesRange.date,
    });
    // stop resetting same basket slot
    if (
      selectANewSlot &&
      basket.slotReference === slotReference &&
      basket.driverInstructions === driverInstructions &&
      basket.fulfillAtSlot.day === time.day &&
      basket.fulfillAtSlot.from === time.from &&
      basket.fulfillAtSlot.to === time.to
    ) {
      this.setState({selectANewSlot: false});
      return;
    }
    updateAllSettings({
      [SETTINGS.INSTRUCTIONS]: driverInstruction,
      [SETTINGS.DELIVERY_CONTACT_NUMBER]: deliveryContactNumber,
    });

    this.setState({
      reservingSlot: true,
      goCheckout,
      selectANewSlot: false,
    });
    setFulfillSlot(
      basket && basket.uuid,
      time,
      branchId,
      fulfillmentType,
      slotReference,
      driverInstructions,
      goCheckout
    );
  };
  onTimeChange = e => {
    this.setState({slotTimeNotSetError: false});
    const {slots} = this.props;
    const {date} = this.state;
    const [from, to] = e.target.value.split(' - ');
    let chargeValue = 0;
    if (slots && slots[date] && slots[date].availability) {
      const slot = getSlotByTime(slots[date].availability, from, to);
      chargeValue = slot.chargeValue;
    }
    const time = {
      day: date,
      from,
      to,
      chargeValue,
      isAvailable: true,
    };
    this.setState({
      time,
      currentSlotTime: `${from} - ${to}`,
    });
  };

  isDayBlocked = date => {
    const {slots} = this.props;
    const {todayDate, fulfillmentType, isInvalidSDD} = this.state;
    const theDate = date.format(ORDER_SERVICE_DATE_FORMAT);
    if (
      fulfillmentType === SERVICE_TYPE.DELIVERY &&
      todayDate === theDate &&
      isInvalidSDD
    ) {
      return true;
    }
    return !(slots && slots[theDate]);
  };

  selectANewSlot = () => {
    const {basket, getFulfillSlot} = this.props;
    this.expiredSlotRemoved = false;
    const fulfillmentType =
      (basket && basket.fulfillmentType) || this.state.fulfillmentType;
    this.setState({selectANewSlot: true, fulfillmentType}, () => {
      getFulfillSlot(this.state.branchId, fulfillmentType);
    });
  };

  hasExtraDeliveryFee = () => {
    const {
      fulfillmentType,
      date,
      tomorrowDate,
      todayDate,
      time,
      selectANewSlot,
    } = this.state;
    const {slots, basket} = this.props;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    if (
      basket &&
      basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
      !selectANewSlot
    ) {
      return false;
    }
    if (
      isDelivery &&
      time &&
      (date === tomorrowDate || date === todayDate) &&
      hasChargeFee(slots[date])
    ) {
      this.setState({showSlotFeeModal: true});
      return true;
    }
    return false;
  };

  isAnyInvalidFields = () => {
    const {
      fulfillmentType,
      deliveryContactNumber,
      currentSlotTime,
    } = this.state;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    let isInvalid = false;
    if (!currentSlotTime) {
      this.setState({slotTimeNotSetError: true, showSlotTimeNotSetModal: true});
      isInvalid = true;
    }
    if (isDelivery) {
      const deliveryContactNumberError = getInvalidNumberError(
        deliveryContactNumber
      );
      if (deliveryContactNumberError) {
        this.setState({deliveryContactNumberError});
        isInvalid = true;
      }
    }
    return isInvalid;
  };

  onCheckoutClicked = () => {
    this.reserveButtonClicked = false;
    this.checkOutButtonClicked = true;
    if (this.isAnyInvalidFields() || this.hasExtraDeliveryFee()) {
      return;
    }
    this.reserveAndCheckout();
  };

  onReserveClicked = () => {
    this.reserveButtonClicked = true;
    this.checkOutButtonClicked = false;
    if (this.isAnyInvalidFields() || this.hasExtraDeliveryFee()) {
      return;
    }
    this.reserveBookSlot();
  };

  reserveAndCheckout = () => {
    const {navigateToCheckout, basket} = this.props;
    const {selectANewSlot} = this.state;
    this.expiredSlotRemoved = false;
    if (shouldNavigateForBookSlot(basket, selectANewSlot)) {
      return navigateToCheckout();
    } else {
      const {date, timesRange} = this.state;
      if (!date || !timesRange) {
        return;
      }
      const goCheckout = true;
      this.settingFulfillmentSlot(goCheckout);
    }
  };

  reserveBookSlot = () => {
    const {history, basket} = this.props;
    const {fulfillmentType, deliveryContactNumber, selectANewSlot} = this.state;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    this.expiredSlotRemoved = false;
    if (shouldNavigateForBookSlot(basket, selectANewSlot)) {
      history.push('/');
    } else {
      const {date, timesRange} = this.state;
      if (!date || !timesRange) {
        return;
      }
      const showSlotPanel =
        !basket ||
        selectANewSlot ||
        (basket &&
          basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT) ||
        !basket.fulfillAtSlot;
      if (isDelivery && !deliveryContactNumber && showSlotPanel) {
        return this.setState({
          deliveryContactNumberError: true,
        });
      }
      this.settingFulfillmentSlot();
    }
  };

  handleSlotsModalCallback = () => {
    const {slotExpired, slotNearlyExpired} = this.state;
    const {removeFulfillSlot, basket} = this.props;
    if (slotExpired) {
      this.updatingSlot = true;
      removeFulfillSlot(basket.uuid);
      removeValueFromLocalStorage(JJ_LOCAL_STORAGE.FULFILLMENT);
      this.setState({
        slotExpired: false,
      });
    }
    if (slotNearlyExpired) {
      this.setState({
        slotNearlyExpired: false,
      });
    }
  };
  hideSlotFeeModal = () => this.setState({showSlotFeeModal: false});

  updateDeliveryCheckbox = () => {
    this.setState(
      {
        informMeAboutDeliveryAreaChange: !this.state
          .informMeAboutDeliveryAreaChange,
      },
      () => {
        if (this.state.informMeAboutDeliveryAreaChange) {
          GoogleAnalyticsHelper.trackEvent(
            ANALYTICS_EVENTS.USER_INTEREST_DELIVERY_AREA_CHANGE,
            {
              email: this.props.auth.email,
            }
          );
        }
      }
    );
  };

  render() {
    const {
      profile,
      errors,
      basket,
      branchList,
      auth,
      relogin,
      settings,
      loading,
      slots,
    } = this.props;
    if (!auth) {
      return (
        <UnauthInfoPanel callback={relogin} loading={loading && loading.auth} />
      );
    }
    const branchListArray = branchList ? Object.entries(branchList) : null;
    const renderFulfillmentError = errors && errors.fulfillment && (
      <Errors error={errors.fulfillment} />
    );
    const renderError = errors && (errors.getSlot || errors.setSlot) && (
      <PopupModal
        modalName="Bookslot Error"
        content={errors.getSlot || errors.setSlot}
        title={`${errors.getSlot ? 'Get' : 'Set'} slots failed`}
        isError={true}
        showDismissButton={true}
        callback={this.resetError}
        closeModalCallback={this.resetError}
        showModal={!!this.state.error}
        tag={'errors.getSlot || errors.setSlot'}
      />
    );
    const renderNoDeliveryModal = errors &&
      errors.postCodeOutsideOfDeliveryArea && (
        <PopupModal
          modalName="Bookslot Error"
          content={
            <div>
              <p>
                Unfortunately, we currently do not deliver to your postcode. For
                assistance and to explore other delivery options, please
                <br />
                contact the customer service on
                <ContactLink href={`tel: ${JJ_TEL}`}>01992 701 701</ContactLink>
              </p>
              <CheckBoxWrapper>
                <Checkbox
                  onClick={this.updateDeliveryCheckbox}
                  text={
                    "I'd like to be informed when JJ starts delivering to my area"
                  }
                  id={'marketing'}
                  enabled={this.state.informMeAboutDeliveryAreaChange}
                />
              </CheckBoxWrapper>
            </div>
          }
          title="Bookslot Error"
          isError={true}
          showDismissButton={true}
          callback={this.postCodeOutsideOfDeliveryArea}
          closeModalCallback={this.postCodeOutsideOfDeliveryArea}
          showModal={errors.postCodeOutsideOfDeliveryArea}
          tag={'errors.postCodeOutsideOfDeliveryArea'}
        />
      );
    const {
      fulfillmentType,
      branchId,
      date,
      time,
      timesRange,
      currentSlotTime,
      slotExpired,
      slotNearlyExpired,
      slotTimestamp,
      loadingData,
      reservingSlot,
      selectANewSlot,
      todayDate,
      tomorrowDate,
      isInvalidSDD,
      incurNDDTime,
      incurSDDTime,
      showSlotFeeModal,
      deliveryContactNumberError,
      nextSlotInfo,
      slotTimeNotSetError,
      showSlotTimeNotSetModal,
    } = this.state;
    const showSlotPanel =
      !basket ||
      selectANewSlot ||
      (basket &&
        basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT) ||
      !basket.fulfillAtSlot;
    const showBookSlotStatus =
      basket &&
      basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    const isCollection = fulfillmentType === SERVICE_TYPE.COLLECTION;
    const isSlotNotSelected = fulfillmentType === null;
    const showSlotModal =
      showBookSlotStatus && (slotExpired || slotNearlyExpired);
    const renderSlotModal = showSlotModal && (
      <SlotsModal
        isExpired={slotExpired}
        isDelivery={isDelivery}
        showModal={showSlotModal}
        slotTimestamp={slotTimestamp}
        callback={this.handleSlotsModalCallback}
      />
    );
    const renderSlotFeeModal = showSlotFeeModal && (
      <SlotFeeModal
        closeModalCallback={this.hideSlotFeeModal}
        showModal={showSlotFeeModal}
        date={date}
        isToday={date === todayDate}
        isTomorrow={date === tomorrowDate}
        time={time}
        isReservedOnly={this.reserveButtonClicked}
        checkout={this.reserveAndCheckout}
        reserve={this.reserveBookSlot}
        hideModal={this.hideSlotFeeModal}
      />
    );
    const renderSlotTimeNotSetModal = showSlotTimeNotSetModal && (
      <PopupModal
        modalName="Bookslot Time Slot Error"
        title="Slot time not set"
        subContent="Please select a slot time before continuing."
        isError={true}
        showModal={showSlotTimeNotSetModal}
        callback={this.hideSlotTimeNoSetModal}
        closeModalCallback={this.hideSlotTimeNoSetModal}
        showOKButton={true}
        tag={'showSlotTimeNotSetModal'}
      />
    );
    const renderBranches = branchId &&
      branchListArray &&
      branchListArray.length > 0 && (
        <AllBranchList
          branchList={branchListArray}
          selectorValue={branchId}
          callback={this.changeBranch}
        />
      );
    const renderLocation = isDelivery
      ? profile && profile.address && profile.address.postcode
      : renderBranches;
    const renderServiceInfo = isDelivery
      ? `For the wellbeing of customers and staff we will deliver to the main door of the house or communal entry point of a block of flats. Most delivery slots are 4 hours long.`
      : `All collection slots are 30 minutes long.`;
    const minimumOrderAmount = timesRange && timesRange.minimumOrder;
    const renderMinOrder = isDelivery &&
      minimumOrderAmount &&
      minimumOrderAmount > 0 && (
        <TextWrapper>
          Your total minimum on each delivery day is £{minimumOrderAmount}
        </TextWrapper>
      );
    const renderDeliveryContactNumberError = deliveryContactNumberError && (
      <InputError>
        {deliveryContactNumberError === FORM_ERRORS.REQUIRED &&
          'Delivery Phone Number is required'}
        {deliveryContactNumberError === FORM_ERRORS.INVALID &&
          'Delivery Phone Number is invalid'}
      </InputError>
    );
    const renderNote = isDelivery && (
      <Fragment>
        <OptionInfo
          placeholder="Delivery Phone Number (required)"
          value={this.state.deliveryContactNumber}
          onChange={this.handleContactNumberChange}
          $standalone={true}
          $isError={deliveryContactNumberError}
          type="tel"
        />
        {renderDeliveryContactNumberError}
        <OptionInfo
          placeholder="Driver instructions (optional)"
          value={this.state.driverInstruction}
          onChange={this.handleDriverInstructionChange}
          maxLength={MAX_DRIVER_INSTRUCTION_LENGTH}
          $standalone={true}
        />
      </Fragment>
    );
    const renderDatePicker = date && (
      <RenderDatePicker
        isDelivery={isDelivery}
        onDateChange={this.onDateChange}
        date={moment(new Date(date.replace(DATE_PICKER_REGEX, '$2/$1/$3')))}
        isDayBlocked={this.isDayBlocked}
      />
    );
    const renderTimesRange = timesRange && timesRange.availability && (
      <Time
        onChange={this.onTimeChange}
        value={currentSlotTime}
        $isError={slotTimeNotSetError}
      >
        <option key={-1} value="" disabled={true}>
          Please select your slot time
        </option>
        {timesRange.availability.map((slotTime, index) => {
          if (!slotTime.isAvailable) {
            return;
          }
          return (
            <option key={index} value={`${slotTime.from} - ${slotTime.to}`}>
              {slotTime.from} - {slotTime.to}
            </option>
          );
        })}
      </Time>
    );
    const showTodayButton =
      isDelivery && slots && todayDate && !!slots[todayDate];
    const showTomorrowButton =
      isDelivery && slots && tomorrowDate && !!slots[tomorrowDate];
    const renderSDDText =
      incurSDDTime &&
      date === todayDate &&
      (isInvalidSDD
        ? `only available before ${incurSDDTime}`
        : `Order by ${incurSDDTime}. ${
            !!time && !!time.chargeValue && time.chargeValue > 0
              ? '£' + time.chargeValue
              : ''
          } delivery fee applies`);
    const renderTodayButton = showTodayButton && (
      <Row>
        <WideBlock>
          <ButtonWithText>
            <SlotButton
              disabled={isInvalidSDD}
              isSelected={date === todayDate && !isInvalidSDD}
              onClick={this.onSwitchToToday}
            >
              Today
            </SlotButton>
            <ButtonInfo>{renderSDDText}</ButtonInfo>
          </ButtonWithText>
        </WideBlock>
      </Row>
    );
    const renderTomorrowButton = showTomorrowButton && (
      <Row>
        <ButtonWithText>
          <SlotButton
            $isSelected={date === tomorrowDate}
            onClick={this.onSwitchToTomorrow}
          >
            Tomorrow
          </SlotButton>
          <ButtonInfo>
            {!!incurNDDTime &&
              date === tomorrowDate &&
              `Slots booked after ${incurNDDTime} incur a ${
                !!time && !!time.chargeValue && time.chargeValue > 0
                  ? '£' + time.chargeValue
                  : ''
              } fee`}
          </ButtonInfo>
        </ButtonWithText>
      </Row>
    );
    const renderDeliveryButtons = (showTodayButton || showTomorrowButton) && (
      <Fragment>
        <InputDateLabel>Day</InputDateLabel>
        {renderTodayButton}
        {renderTomorrowButton}
      </Fragment>
    );
    const renderSlotsDetails =
      date && timesRange ? (
        <Fragment>
          {renderDeliveryButtons}
          <Row>
            <Block>
              <InputLabel>
                {showTodayButton || showTomorrowButton ? 'Or date' : 'Day'}
              </InputLabel>
              {renderDatePicker}
            </Block>

            <Block>
              <InputLabel>Time</InputLabel>
              {renderTimesRange}
            </Block>
          </Row>
          <Row>
            <WideBlock>
              <InputLabel>Your info</InputLabel>
              {renderNote}
              <OptionInfo
                placeholder={'Your reference (optional)'}
                value={this.state.slotReference}
                onChange={this.handleSlotReferenceChange}
                maxLength={MAX_REFERENCE_LENGTH}
                $standalone={true}
              />
            </WideBlock>
          </Row>
        </Fragment>
      ) : (
        <CenterContent>
          <Loading isLight={false} />
        </CenterContent>
      );
    const renderBookSlotStatus = showBookSlotStatus && (
      <BookSlotStatus
        fulfillAtSlot={basket.fulfillAtSlot}
        fulfillmentType={basket.fulfillmentType}
        isBookSlotPage={true}
        branchName={
          branchList &&
          basket.branchId &&
          branchList[basket.branchId] &&
          branchList[basket.branchId].name
        }
      />
    );
    const renderSlotChosenTitle = isSlotNotSelected && (
      <>
        <SlotChosenTitle>Hi There!</SlotChosenTitle>
        <SlotChosenTitle>
          Please choose Delivery or Collection to keep shopping
        </SlotChosenTitle>
      </>
    );
    const renderNextSlot = !!nextSlotInfo && (
      <NextSlotWrapper>
        Your next available slot: <NextSlot>{nextSlotInfo}</NextSlot>
      </NextSlotWrapper>
    );
    const renderSlotPanel = showSlotPanel ? (
      <Content>
        <Panel $fullWidth={isSlotNotSelected}>
          {renderSlotChosenTitle}
          <FulfillmentWrapper $center={isSlotNotSelected}>
            <Fulfillment $center={isSlotNotSelected}>
              <FulfillmentBtn
                isChecked={isDelivery}
                fulfillmentType={SERVICE_TYPE.DELIVERY}
                callback={this.changeToDelivery}
                data-rw="bookslot--fulfillment-delivery-button"
              />
              <FulfillmentBtn
                isChecked={isCollection}
                fulfillmentType={SERVICE_TYPE.COLLECTION}
                callback={this.changeToCollection}
                data-rw="bookslot--fulfillment-collection-button"
              />
            </Fulfillment>
          </FulfillmentWrapper>
          <FulfillmentInfo $hide={isSlotNotSelected}>
            {renderNextSlot}
            <BranchWrapper>
              <OrderingFrom>
                {`You are ordering for ${fulfillmentType}`}
                {isDelivery ? ` to` : ` from`}
              </OrderingFrom>
              <Branch>{renderLocation}</Branch>
            </BranchWrapper>
            <Text $nested={true}>{renderServiceInfo}</Text>
            {renderMinOrder}
            <Text>
              {isCollection &&
                'Please arrive within your allocated time slot. If you arrive early, you may be asked to wait.'}
            </Text>
          </FulfillmentInfo>
          <NextSlotButton
            data-rw="bookslot-nextAvailableSlotAfterNext"
            onClick={this.setNextAvailableSlotAfterNext}
          />
        </Panel>
        <FormPanel $hide={isSlotNotSelected} $isDelivery={isDelivery}>
          {renderSlotsDetails}
        </FormPanel>
      </Content>
    ) : (
      <SlotContent>
        <Text>
          {renderBookSlotStatus}
          <Text>
            {slotExpired &&
              'Your slot has now expired, please select a new slot.'}
          </Text>
        </Text>
        <ChangeSlotButtonWrapper>
          <ChangeSlotButton onClick={this.selectANewSlot}>
            Select a new slot
          </ChangeSlotButton>
        </ChangeSlotButtonWrapper>
      </SlotContent>
    );
    const renderReserve = showSlotPanel && 'Reserve & ';
    const renderSlotPanelWithLoading =
      (!basket && !settings) || loadingData || reservingSlot ? (
        <Content>
          <LoadingWrapper>
            <Loading isLight={false} />
          </LoadingWrapper>
        </Content>
      ) : (
        renderSlotPanel
      );
    const renderButtons = (!isSlotNotSelected || showBookSlotStatus) && (
      <ButtonWrapper>
        <Button
          $left={true}
          onClick={this.onReserveClicked}
          data-rw="bookslot--reserve-continue-button"
        >
          {renderReserve}continue shopping
        </Button>
        <Button
          $right={true}
          onClick={this.onCheckoutClicked}
          data-rw="bookslot--reserve-checkout-button"
        >
          {renderReserve}checkout
        </Button>
      </ButtonWrapper>
    );
    const renderTitleService =
      fulfillmentType === SERVICE_TYPE.UNSELECTED || !fulfillmentType
        ? 'your'
        : fulfillmentType && fulfillmentType.toLowerCase();
    return (
      <Fragment>
        {renderFulfillmentError}
        {renderError}
        {renderNoDeliveryModal}
        {renderSlotModal}
        {renderSlotFeeModal}
        {renderSlotTimeNotSetModal}
        <Container>
          <Title>Book {renderTitleService} Slot</Title>
          {renderSlotPanelWithLoading}
          {renderButtons}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchResults: state.searchResults,
    loading: state.loading,
    branch: state.branch,
    branchList: state.branchList,
    basket: state.basket,
    errors: state.errors,
    nextRoute: state.nextRoute,
    route: state.route,
    slots: state.slots,
    auth: state.auth,
    profile: state.profile,
    settings: state.settings,
  };
};

const mapDispatchToProps = dispatch => ({
  search: bindActionCreators(search, dispatch),
  getBranchList: bindActionCreators(getBranchList, dispatch),
  setBranch: bindActionCreators(setBranch, dispatch),
  getFulfillSlot: bindActionCreators(getFulfillSlot, dispatch),
  setFulfillSlot: bindActionCreators(setFulfillSlot, dispatch),
  removeFulfillSlot: bindActionCreators(removeFulfillSlot, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setDriverInstruction: bindActionCreators(setDriverInstruction, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch),
  removeSetting: bindActionCreators(removeSetting, dispatch),
  updateAllSettings: bindActionCreators(updateAllSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookSlotPage);
