import {put, call, takeLatest, select} from 'redux-saga/effects';
import cmsApi from '../api/cms.api';
import * as ActionTypes from '../actions/types.action';
import {DEFAULT_BRANCH} from '../constants/constants';

function* getBanners() {
  const getBranch = state => state.branch;
  const branchId = yield select(getBranch);
  const branch = branchId || DEFAULT_BRANCH;
  try {
    const banners = yield call(cmsApi.getBanners);
    yield put({
      type: ActionTypes.GET_CMS_BANNERS_SUCCESS,
      banners,
      branch,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_BANNERS_FAILED,
      message: e.message,
    });
  }
}

function* getAboutUs() {
  try {
    const aboutus = yield call(cmsApi.getAboutUs);
    yield put({
      type: ActionTypes.GET_CMS_ABOUTUS_SUCCESS,
      aboutus,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_ABOUTUS_FAILED,
      message: e.message,
    });
  }
}

function* getPolicies() {
  try {
    const policies = yield call(cmsApi.getPolicies);
    yield put({
      type: ActionTypes.GET_CMS_POLICIES_SUCCESS,
      policies,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_POLICIES_FAILED,
      message: e.message,
    });
  }
}

function* getCareers() {
  try {
    const careers = yield call(cmsApi.getCareers);
    yield put({
      type: ActionTypes.GET_CMS_CAREERS_SUCCESS,
      careers,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_CAREERS_FAILED,
      message: e.message,
    });
  }
}

function* getCustomers() {
  try {
    const customers = yield call(cmsApi.getCustomers);
    yield put({
      type: ActionTypes.GET_CMS_CUSTOMERS_SUCCESS,
      customers,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_CUSTOMERS_FAILED,
      message: e.message,
    });
  }
}

function* getBrochures() {
  try {
    const brochures = yield call(cmsApi.getBrochures);
    yield put({
      type: ActionTypes.GET_CMS_BROCHURES_SUCCESS,
      brochures,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_BROCHURES_FAILED,
      message: e.message,
    });
  }
}

function* getVacancies() {
  try {
    const vacancies = yield call(cmsApi.getVacancies);
    yield put({
      type: ActionTypes.GET_CMS_VACANCIES_SUCCESS,
      vacancies,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_VACANCIES_FAILED,
      message: e.message,
    });
  }
}

function* getDepartments() {
  try {
    const departments = yield call(cmsApi.getDepartments);
    yield put({
      type: ActionTypes.GET_CMS_DEPARTMENTS_SUCCESS,
      departments: departments && departments.Departments,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_DEPARTMENTS_FAILED,
      message: e.message,
    });
  }
}

function* getTeam() {
  try {
    const team = yield call(cmsApi.getTeam);
    yield put({
      type: ActionTypes.GET_CMS_TEAM_SUCCESS,
      team,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_TEAM_FAILED,
      message: e.message,
    });
  }
}

function* getHelp() {
  try {
    const help = yield call(cmsApi.getHelp);
    yield put({
      type: ActionTypes.GET_CMS_HELP_SUCCESS,
      help,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_HELP_FAILED,
      message: e.message,
    });
  }
}

function* getPayByBankFAQ() {
  try {
    const payByBankFAQ = yield call(cmsApi.getPayByBankFAQ);
    yield put({
      type: ActionTypes.GET_CMS_PBB_FAQ_SUCCESS,
      payByBankFAQ,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_PBB_FAQ_FAILED,
      message: e.message,
    });
  }
}

function* getWhyJJ() {
  try {
    const whyJJ = yield call(cmsApi.getWhyJJ);
    yield put({
      type: ActionTypes.GET_CMS_WHYJJ_SUCCESS,
      whyJJ,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_WHYJJ_FAILED,
      message: e.message,
    });
  }
}
function* getSupplyUs() {
  try {
    const supplyUs = yield call(cmsApi.getSupplyUs);
    yield put({
      type: ActionTypes.GET_CMS_SUPPLYUS_SUCCESS,
      supplyUs,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_SUPPLYUS_FAILED,
      message: e.message,
    });
  }
}
function* getDeliveries() {
  try {
    const deliveries = yield call(cmsApi.getDeliveries);
    yield put({
      type: ActionTypes.GET_CMS_DELIVERIES_SUCCESS,
      deliveries,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_CMS_DELIVERIES_FAILED,
      message: e.message,
    });
  }
}
export default function* getCmsDataYield() {
  yield takeLatest(ActionTypes.GET_CMS_BANNERS_REQUESTED, getBanners);
  yield takeLatest(ActionTypes.GET_CMS_ABOUTUS_REQUESTED, getAboutUs);
  yield takeLatest(ActionTypes.GET_CMS_POLICIES_REQUESTED, getPolicies);
  yield takeLatest(ActionTypes.GET_CMS_CAREERS_REQUESTED, getCareers);
  yield takeLatest(ActionTypes.GET_CMS_CUSTOMERS_REQUESTED, getCustomers);
  yield takeLatest(ActionTypes.GET_CMS_BROCHURES_REQUESTED, getBrochures);
  yield takeLatest(ActionTypes.GET_CMS_VACANCIES_REQUESTED, getVacancies);
  yield takeLatest(ActionTypes.GET_CMS_DEPARTMENTS_REQUESTED, getDepartments);
  yield takeLatest(ActionTypes.GET_CMS_TEAM_REQUESTED, getTeam);
  yield takeLatest(ActionTypes.GET_CMS_HELP_REQUESTED, getHelp);
  yield takeLatest(ActionTypes.GET_CMS_DELIVERIES_REQUESTED, getDeliveries);
  yield takeLatest(ActionTypes.GET_CMS_WHYJJ_REQUESTED, getWhyJJ);
  yield takeLatest(ActionTypes.GET_CMS_SUPPLYUS_REQUESTED, getSupplyUs);
  yield takeLatest(ActionTypes.GET_CMS_PBB_FAQ_REQUESTED, getPayByBankFAQ);
}
