import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.1rem;
  padding: 1rem;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p => p.theme.textColor};
`;

export const StatusMessage = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  margin: 0.2rem;
  p {
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: ${p => p.theme.primaryTextColor};
  }
  ${media.small`
    font-size: 1.4rem;
  `};
`;
