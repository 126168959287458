import React from 'react';
import PropTypes from 'prop-types';

import {
  MarketingWrapper,
  Wrapper,
  SubGrid,
  ExtraGridsWrapper,
} from './MarketingGrids.style';
import {ANALYTICS_EVENTS, PROTOCOL_REGEX} from '../../constants/constants';
import {SideGrid} from './SideGrid';
import {ExtraGrids} from './ExtraGrids';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {generateNewProductsLink} from '../../helpers/data.helper';
import {NEW_PRODUCT} from '../../constants/routePaths';

const MarketingGrids = props => {
  const {
    history,
    branch,
    ads,
    isMobile,
    isOwnLabels,
    isMainGridOnly,
    noGap,
  } = props;
  let grids;
  grids = isOwnLabels ? ads.slice(1, 5) : ads.slice(1);

  const onGridClick = (linkUrl, bannerIndex, isMain) => {
    const url =
      linkUrl === NEW_PRODUCT ? generateNewProductsLink(branch) : linkUrl;
    const trackCategory = isOwnLabels
      ? ANALYTICS_EVENTS.OWN_LABELS
      : isMain
      ? ANALYTICS_EVENTS.HOME_LOWER_BIG
      : ANALYTICS_EVENTS.HOME_LOWER_SMALL;
    const title = GoogleAnalyticsHelper.getAnalyticsMarketingGridEventName(
      bannerIndex,
      isMain
    );
    GoogleAnalyticsHelper.trackEvent(title, {
      url,
      title,
      category: trackCategory,
    });
    const protocol = new RegExp(PROTOCOL_REGEX, 'i');
    if (protocol.test(url) && window) {
      const win = window.open(url, 'blank');
      win.focus();
    } else {
      history.push(url);
    }
  };
  if (grids.length === 0) {
    return null;
  }
  const renderExtraGrids =
    isOwnLabels &&
    !isMainGridOnly &&
    ads.map((grid, index) => (
      <ExtraGrids
        key={index}
        grid={grid}
        callback={onGridClick}
        isMobile={isMobile}
        index={index}
        bannerIndex={grid.index}
      />
    ));
  let subGrid;
  const renderGrids =
    !isOwnLabels &&
    ads.map((grid, index) => {
      if (Array.isArray(grid)) {
        subGrid = grid.map((sub, subIndex) => (
          <SideGrid
            key={subIndex + index}
            grid={sub}
            callback={onGridClick}
            isMobile={isMobile}
            bannerIndex={sub.index}
          />
        ));
        return <SubGrid key={index + '-sub-' + grid.id}>{subGrid}</SubGrid>;
      }
      return (
        <SideGrid
          key={index + '-' + grid.id}
          extraPadding={index === 0 && noGap}
          isMain={true}
          grid={grid}
          callback={onGridClick}
          isMobile={isMobile}
          bannerIndex={grid.index}
        />
      );
    });

  return (
    <MarketingWrapper $noGap={noGap}>
      <Wrapper $noGap={noGap}>{renderGrids}</Wrapper>
      <ExtraGridsWrapper>{renderExtraGrids}</ExtraGridsWrapper>
    </MarketingWrapper>
  );
};

MarketingGrids.propTypes = {
  grids: PropTypes.object,
};

export default MarketingGrids;
