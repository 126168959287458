import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Content} from '../../styles/pages/pages';
import {BlockWithArrow} from '../../styles/components/blockWithArrow';
import {WayDown, WayUp} from '../../styles/components/arrow';
import {LinkStyle} from '../../styles/components/links';

export const VacanciesPageContent = styled(Content)`
  border-width: 2rem;
  line-height: 2rem;
  ${media.smaller`
    border-width: 1rem;
  `};
`;

export const VacanciesLocations = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 75%;
`;

export const SectionWrapper = styled.div`
  padding: 1rem 2rem;
`;

export const Intro = styled.p`
  color: ${p => p.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 2rem;
  margin: 0.5rem 0;
`;

export const LocationWrapper = styled.div`
  padding: 2rem;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p =>
    p.$isActive ? p.theme.primaryColor : p.theme.secondaryColor};
  margin: 0.2rem;
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: capitalize;
`;

export const JobWrapper = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.secondaryBgColorHover};
  }

  display: flex;
  flex-direction: row;
  ${media.smaller`
    display: block;
    margin-bottom: 1rem;
  `};
`;

export const JobField = styled.div`
  padding: 1rem;
  flex-grow: 1;
  width: 50%;
  text-align: right;

  &:first-child {
    width: 50%;
    text-align: left;
  }

  ${media.smaller`
    display: block;
    margin-bottom: 1rem;
    max-width: 100%;
    text-align: left;
    &:last-child {
      text-align: left;
    }
  `};
`;

export const NoJobs = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
`;

export const Error = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
`;

export const Details = styled.div`
  padding: 2rem;
  width: 100%;
  display: ${p => (p.$collapsed ? 'none' : 'flex')};
  flex-direction: column;
  color: ${p => p.theme.textColor};
  font-size: 1.4rem;
  ${media.smaller`
    padding: 1rem 2rem;
  `};
`;

export const Block = styled(BlockWithArrow)`
  width: 100%;
  max-width: 100%;
  height: 4.4rem;
  margin: 0 0 0.1rem 0;
  font-size: 1.4rem;
`;

export const ArrowDown = styled(WayDown)`
  position: absolute;
  right: 1rem;

  &::before {
    color: ${p => p.theme.primaryTextColor};
  }
`;

export const ArrowUp = styled(WayUp)`
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;

  &::before {
    color: ${p => p.theme.primaryTextColor};
  }
`;

export const Selector = styled.select`
  ${LinkStyle};
  min-width: 20rem;
  width: ${p => (p.$maxWidth ? '100%' : 'auto')};
  text-indent: 1rem;
  margin-bottom: 2rem;
  ${media.smaller`
    text-indent: 0;
  `};
`;

export const Option = styled.option`
  padding: 0 2rem;
`;

export const Img = styled.img`
  width: 100%;
`;
