import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {
  CategoryBlocks,
  LatestPost,
  PostImage,
  Excerpt,
  BlogTitle,
  PostBar,
  LastModify,
  BlogSearch,
  BlogSearchButton,
  BlogSearchWrapper,
} from './BlogPage.style';
import {getBlogCategories, getBlogPostByTitle} from '../../actions/blog.action';
import {Title, Head, Wrapper} from '../../styles/pages/pages';
import {CategoryBlockItem} from './CategoryBlockItem';
import {strapiImage} from '../../helpers/image.helper';
import {DEFAULT_DATE_FORMAT, KEYBOARD_KEYS} from '../../constants/constants';
import Loading from '../../components/Loading/Loading';

const BlogCategoriesPage = () => {
  const [blogQuery, setBlogQuery] = useState('');
  const {blogCategories, loading, blogPostsSearch} = useSelector(state => ({
    blogCategories: state.blogCategories,
    blogPostsSearch: state.blogPostsSearch,
    loading: state.loading,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!blogCategories) {
      dispatch(getBlogCategories());
    }
  }, []);

  const handleOnKeyPress = e => {
    if (e.key !== KEYBOARD_KEYS.ENTER) {
      return;
    }
    dispatch(getBlogPostByTitle(blogQuery));
  };
  const renderBlogPostSearchLoading = loading && loading.blogPostsSearch && (
    <Loading isLight={false} />
  );

  const renderCategories =
    blogCategories &&
    blogCategories.categories &&
    blogCategories.categories.map(category => {
      if (
        category.attributes.slug === 'press-releases' ||
        category.attributes.slug === 'blog'
      ) {
        return null;
      }
      return (
        <CategoryBlockItem
          key={category.id}
          slug={category.attributes.slug}
          bgImg={strapiImage(category).small}
          label={category.attributes.name}
        />
      );
    });
  const renderLatestPosts =
    blogCategories &&
    blogCategories.latestPosts &&
    blogCategories.latestPosts.map((post, index) => {
      const renderLastUpdate = !!post && (
        <PostBar>
          <LastModify>
            {(!!post.attributes.wixUpdatedAt || !!post.attributes.updatedAt) &&
              `${moment(
                post.attributes.wixUpdatedAt || post.attributes.updatedAt
              ).format(DEFAULT_DATE_FORMAT)}`}
          </LastModify>
        </PostBar>
      );
      return (
        <LatestPost
          key={post.id + index}
          to={'/blog/post/' + post.attributes.slug}
        >
          <PostImage src={strapiImage(post).full} />
          {renderLastUpdate}
          <BlogTitle>{post.attributes.title}</BlogTitle>
          <Excerpt>{post.attributes.excerpt}</Excerpt>
        </LatestPost>
      );
    });

  const renderSearchResults =
    blogPostsSearch &&
    blogPostsSearch.length > 0 &&
    blogPostsSearch.map((post, index) => {
      const renderLastUpdate = !!post && (
        <PostBar>
          <LastModify>
            {(!!post.attributes.wixUpdatedAt || !!post.attributes.updatedAt) &&
              `${moment(
                post.attributes.wixUpdatedAt || post.attributes.updatedAt
              ).format(DEFAULT_DATE_FORMAT)}`}
          </LastModify>
        </PostBar>
      );
      return (
        <LatestPost
          key={post.id + index}
          to={'/blog/post/' + post.attributes.slug}
        >
          <PostImage src={strapiImage(post).full} />
          {renderLastUpdate}
          <BlogTitle>{post.attributes.title}</BlogTitle>
          <Excerpt>{post.attributes.excerpt}</Excerpt>
        </LatestPost>
      );
    });

  return (
    <Wrapper>
      <Head>
        <Title>JJ Blog</Title>
      </Head>
      <BlogSearchWrapper>
        <BlogSearch
          name="blog-query"
          value={blogQuery}
          onChange={e => setBlogQuery(e.target.value)}
          placeholder={'Search in blog posts...'}
          onKeyPress={handleOnKeyPress}
        />
        <BlogSearchButton
          onClick={() => {
            dispatch(getBlogPostByTitle(blogQuery));
          }}
        >
          Search
        </BlogSearchButton>
      </BlogSearchWrapper>
      {renderBlogPostSearchLoading}
      {renderSearchResults}
      <CategoryBlocks>{renderCategories}</CategoryBlocks>
      {renderLatestPosts}
    </Wrapper>
  );
};
export default BlogCategoriesPage;
