import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class branchApi {
  static getPostcodeLookup(postcode) {
    const config = getApiConfig();
    const url = `${config.postcodeLookup}/autocomplete/addresses`;
    return axios
      .get(url, {
        params: {
          api_key: config.IDEAL_POSTCODES_API_KEY,
          query: postcode,
        },
      })
      .then(res => res.data);
  }
  static getAddressLookup(udprn) {
    const config = getApiConfig();
    const url = `${config.postcodeLookup}/udprn/${udprn}`;
    return axios
      .get(url, {
        params: {
          api_key: config.IDEAL_POSTCODES_API_KEY,
        },
      })
      .then(res => res.data);
  }
}
