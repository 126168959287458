import {put, call, takeLatest} from 'redux-saga/effects';
import lookupApi from '../api/lookup.api';
import * as ActionTypes from '../actions/types.action';
import {POSTCODE_LOOKUP_SUCCESS_CODE} from '../constants/constants';

function* postcodeLookup(action) {
  try {
    const results = yield call(lookupApi.getPostcodeLookup, action.postcode);
    if (
      results &&
      results.code === POSTCODE_LOOKUP_SUCCESS_CODE &&
      results.result &&
      results.result.hits
    ) {
      yield put({type: ActionTypes.RESET_ERRORS});
      yield put({
        type: ActionTypes.GET_POSTCODE_LOOKUP_SUCCESS,
        lookup: results.result.hits,
      });
    } else {
      yield put({
        type: ActionTypes.GET_POSTCODE_LOOKUP_FAILED,
        error: results.message,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_POSTCODE_LOOKUP_FAILED,
      message: e.message,
    });
  }
}

function* addressLookup(action) {
  try {
    const results = yield call(lookupApi.getAddressLookup, action.udprn);
    if (
      results &&
      results.code === POSTCODE_LOOKUP_SUCCESS_CODE &&
      results.result
    ) {
      yield put({type: ActionTypes.RESET_ERRORS});
      yield put({
        type: ActionTypes.GET_ADDRESS_LOOKUP_SUCCESS,
        lookup: results.result,
      });
    } else {
      yield put({
        type: ActionTypes.GET_ADDRESS_LOOKUP_FAILED,
        error: results.message,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_ADDRESS_LOOKUP_FAILED,
      message: e.message,
    });
  }
}

export default function* postcodeLookupYield() {
  yield takeLatest(ActionTypes.GET_POSTCODE_LOOKUP_REQUESTED, postcodeLookup);
  yield takeLatest(ActionTypes.GET_ADDRESS_LOOKUP_REQUESTED, addressLookup);
}
