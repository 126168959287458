import styled from 'styled-components';
import {rotateRight, rotateLeft} from './keyframes';

export const WayDown = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  animation-play-state: paused;
  animation: ${rotateRight} 0.2s ease-out alternate;
  position: relative;
  &::before {
    font-family: 'icomoon';
    content: '\\e910';
    font-size: 3rem;
    font-weight: normal;
    color: ${props => props.theme.secondaryColor};
  }
`;

export const WayDownWhite = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  animation-play-state: paused;
  animation: ${rotateRight} 0.2s ease-out alternate;
  position: relative;
  &::before {
    font-family: 'icomoon';
    content: '\\e910';
    font-size: 3rem;
    font-weight: normal;
    color: ${props => props.theme.primaryTextColor};
  }
`;

export const WayUp = styled(WayDown)`
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);
  transform-origin: center 1.34rem 0;
  animation: ${rotateLeft} 0.2s ease-out alternate;
`;

export const WayLeft = styled(WayDown)`
  width: 2rem;
  height: 2rem;
  transform: rotate(90deg);
`;

export const WayRight = styled(WayDown)`
  width: 2rem;
  height: 2rem;
  transform: rotate(270deg);
`;
