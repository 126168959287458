import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link2} from '../../styles/components/links';
import {Button3Inverted} from '../../styles/components/buttons';
import {Content} from '../../styles/pages/pages';
import {LoadingContainer} from '../../styles/components/layout';
import {Input} from '../../styles/components/input';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const TheButton = styled(Button3Inverted)`
  width: 19rem;
  text-align: center;
  margin-right: ${p => (p.$extraMargin ? '1rem' : 0)};
  ${media.smaller`
    width: ${p => (p.$fullWidth ? '100%' : '15rem')};
  `};
  ${media.smallest`
    text-align: center;
    height: auto;
  `};
`;

export const BasketButton = styled(Link2)`
  margin-right: ${p => (p.$nomargin ? 0 : '1rem')};
  ${media.smaller`
    padding: 0 1rem;
    max-width: 60%;
  `};
  ${media.smallest`
    text-align: center;
  `};
`;

export const NoSlotText = styled.div`
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const BasketPageContent = styled(Content)`
  border-top: ${p => `solid 2rem ${p.theme.secondaryBgColor}`};
  ${media.smaller`
    border-width: 1rem;
  `};
`;

export const ContentBlock = styled.div`
  width: 100%;
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  border-top: 0;
  padding: 2rem 2rem 1rem;
  ${media.smaller`
    border-left: 0;
    border-right: 0;
    padding: 1rem 2rem;
  `};
`;

export const EditContentBlock = styled.div`
  width: 100%;
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  background-color: ${p => p.theme.tertiaryColor};
  color: ${p => p.theme.primaryTextColor};
  font-size: 1.4rem;
  text-align: center;
  border-top: 0;
  padding: 1rem;
  ${media.smaller`
    border-left: 0;
    border-right: 0;
    padding: 1rem 2rem;
  `};
`;

export const InfoText = styled.div`
  margin: ${p => (p.$noMargin ? 0 : '0 2rem')};
  width: 100%;
  ${media.smaller`
    margin: 0 0 1rem;
  `};
`;

export const Unavailable = styled.div`
  padding-bottom: 1rem;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryColor};
`;

export const OrderSlot = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  border-bottom-width: 0;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const OrderText = styled.div`
  font-size: 1.2rem;
  color: ${p => p.theme.textColor};
  padding: 1rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
  ${media.smaller`
    width: 100%;
  `};
`;

export const Slot = styled.div`
  display: flex;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const SlotText = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  line-height: 2rem;
  width: 100%;
`;

export const Text = styled.span`
  font-size: 1.2rem;
  color: ${p => p.theme.textColor};
  padding: 1rem 0;
  max-width: 75%;
  text-align: ${p => (p.$left ? 'left' : 'center')};
  ${media.smaller`
    text-align: left;
    max-width: 100%;
    padding-top: 0;
  `};
`;

export const BoldText = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
`;

export const OrderButton = styled.span`
  font-size: 1.2rem;
  cursor: pointer;
  color: ${p => p.theme.secondaryColor};
  text-decoration: underline;
  padding: 1rem;
`;

export const BasketItemsWrapper = styled.div`
  width: 100%;
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  border-top-width: 0;
  ${media.smaller`
    border: 0;
  `};
`;

export const TextTitle = styled(Text)`
  color: ${p => p.theme.secondaryColor};
  text-align: ${p => (p.$left ? 'left' : 'center')};
  font-weight: bold;
  ${media.smaller`
    text-align: center;
    min-width: 40%;
  `};
`;

export const EmptyBasket = styled(BasketItemsWrapper)`
  padding: 2rem 1rem;
  font-size: 1.4rem;
  border: ${p => `solid 1rem ${p.theme.tertiaryBgColor}`};
  color: ${p => p.theme.textColor};
`;

export const StatusWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ChangeSlot = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => p.theme.primaryColor};
  text-decoration: underline;
  text-align: right;
  display: flex;
  align-items: center;
`;

export const LoadingWrapper = styled(LoadingContainer)`
  flex-direction: column;
  padding: 4rem;
`;

export const ButtonLoadingWrapper = styled(LoadingContainer)`
  svg {
    height: 3rem;
    width: 3rem;
    fill: ${p => p.theme.secondaryColor};
  }
`;

export const AdminTitle = styled.div`
  font-size: 2rem;
  font-width: bold;
  color: ${p => p.theme.tertiaryAltColor};
  text-transform: uppercase;
  padding-bottom: 2rem;
`;

export const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const AdminItemBox = styled(Input)`
  flex-basis: 40%;
  color: ${p => p.theme.textColor};
  border-color: ${p => p.theme.borderColor};
  border-style: solid;
  border-width: 0.1rem;
  margin-right: 2rem;
`;
