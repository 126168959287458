export const GQL_GET_SHOPPING_LISTS = `
    query {
        shoppingLists {
            id,
            name,
            count,
            items,
            {
                itemId,
                quantity
            }
        }
    }
`;

export const GQL_SET_SHOPPING_LISTS = `
    mutation CreateANewList($name: String!) {
        createShoppingList(name: $name) {
            id,
            name,
            count
        }
    }
`;

export const GQL_SHOPPING_LISTS_ADD_ITEM = `
    mutation ($id: String!, $itemId: String!, $quantity: Int!) {
        addItemToShoppingList(id: $id, itemId: $itemId, quantity: $quantity) {
            id,
            name,
            count,
            items,
            {
                itemId,
                quantity
            }
        }
    }
`;

export const GQL_SHOPPING_LISTS_REMOVE_ITEM = `
    mutation ($id: String!, $itemId: String!) {
        removeItemFromShoppingList(id: $id, itemId: $itemId){
            id,
            name,
            count,
            items,
            {
                itemId,
                quantity
            }
        }
    }
`;

export const GQL_RENAME_SHOPPING_LIST = `
    mutation($id: String!, $name: String!) {
        renameShoppingList(id: $id, name: $name) {
            id
            name
            count
            items {
                itemId
                quantity
            }
        }
    }
`;

export const GQL_DELETE_SHOPPING_LIST = `
    mutation DeleteList($id: String!){
        deleteShoppingList(id: $id)
    }
`;

export const GQL_LOOKUP_SHOPPING_LIST = `
    query($shoppingListId: String!, $page: Int, $size: Int, $deliveryDate: Date) {
    lookupShoppingList(
        shoppingListId: $shoppingListId
        page: $page
        size: $size
        deliveryDate: $deliveryDate
    ) {
        page {
            number
            size
            totalPages
            totalElements
        }
        results {
            uuid
            itemId
            itemName
            shoppingLists {
                shoppingListId
                quantity
            }
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collectionMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            deliveryMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            productDescription
            offer {
                onOffer
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
    }
}`;
