import React, {PureComponent} from 'react';
import queryString from 'query-string';
import {CurrentPage, Separator} from './SearchPage.style';

class BreadCrumbLink extends PureComponent {
  callback = e => {
    e && e.preventDefault();
    const {callback, link} = this.props;
    callback(queryString.parse(link.query), true);
  };

  render() {
    const {link, length, index} = this.props;
    const href = `/search?q=*&${link.query}`;
    return (
      <CurrentPage href={href} $normal={!link.selected} onClick={this.callback}>
        {index < length && <Separator>&#9656;</Separator>}
        {link.name}
      </CurrentPage>
    );
  }
}

export default BreadCrumbLink;
