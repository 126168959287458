import styled from 'styled-components';
import {FavoriteIcon} from '../../styles/components/icons';

export const OutlinedFavorite = styled(FavoriteIcon)`
  z-index: 2;
  &::before {
    color: ${p =>
      p.$isWhite ? p.theme.primaryTextColor : p.theme.tertiaryColor};
  }
`;

export const Favorited = styled(FavoriteIcon)`
  z-index: 2;
  &::before {
    content: '\\e901';
    color: ${p =>
      p.$isWhite ? p.theme.primaryTextColor : p.theme.tertiaryColor};
  }
`;
