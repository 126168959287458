import {all} from 'redux-saga/effects';

import init from './init.saga';
import basket from './basket.saga';
import order from './order.saga';
import search from './search.saga';
import searchMore from './searchMore.saga';
import searchSuggestions from './searchSuggestions.saga';
import productDetail from './productDetail.saga';
import offers from './offers.saga';
import branchList from './branchList.saga';
import branch from './branch.saga';
import lookup from './lookup.saga';
import checkout from './checkout.saga';
import recommended from './recommended.saga';
import slots from './slots.saga';
import auth from './auth.saga';
import account from './account.saga';
import favourite from './favourites.saga';
import previouslyPurchased from './previouslyPurchased.saga';
import upselling from './upselling.saga';
import settings from './settings.saga';
import vacancies from './vacancies.saga';
import marketingOptions from './marketingOptions.saga';
import updateBasket from './updateBasket.saga';
import updateBasketItem from './updateBasketItem.saga';
import brochures from './brochures.saga';
import getCmsData from './cms.saga';
import shoppingList from './shoppingLists.saga';
import email from './email.saga';
import pbbBankDetails from './pbbBankDetails.saga';
import blog from './blog.saga';
import jjContent from './jjContent.saga';
import searchCategoryDescription from './searchCategoryDescription.saga';

export default function* saga() {
  yield all([
    account(),
    auth(),
    basket(),
    blog(),
    email(),
    init(),
    getCmsData(),
    updateBasket(),
    updateBasketItem(),
    branch(),
    branchList(),
    brochures(),
    checkout(),
    favourite(),
    lookup(),
    jjContent(),
    offers(),
    order(),
    pbbBankDetails(),
    previouslyPurchased(),
    productDetail(),
    recommended(),
    search(),
    searchMore(),
    searchSuggestions(),
    settings(),
    shoppingList(),
    slots(),
    upselling(),
    vacancies(),
    marketingOptions(),
    searchCategoryDescription(),
  ]);
}
