import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {UPSELLING_GQL} from '../gql/upselling.gql';

export default class UpsellingApi {
  static async getUpsellingItems(params, headers) {
    const config = getApiConfig();
    return await axios({
      url: config.graphQL,
      method: 'post',
      data: {
        query: UPSELLING_GQL,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
}
