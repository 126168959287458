import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function isMobileReducer(state = initialState.isMobile, action) {
  switch (action.type) {
    case types.SET_ISMOBILE_SUCCESS:
      return action.isMobile;
    default:
      return state;
  }
}
