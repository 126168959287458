import React from 'react';
import {STOCK_CHECK} from '../constants/constants';
import {PriceUnavailable} from '../components/Product/Product.style';
import SentryHelper from './sentry.helper';

export const syncBasketQuantityWithProducts = (basketHashMap, items) => {
  let converted = items;
  if (basketHashMap && items) {
    converted = items.map(product =>
      syncBasketQuantityWithOneProduct(basketHashMap, product)
    );
  }
  return converted;
};

export const syncBasketQuantityWithOneProduct = (basketHashMap, item) => {
  if (basketHashMap && item && item.itemId) {
    const hashMapItem = basketHashMap[item.itemId];
    if (!hashMapItem) {
      return item;
    }
    const basketQuantity =
      hashMapItem &&
      hashMapItem.stockCheck &&
      hashMapItem.stockCheck.result &&
      (hashMapItem.stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        hashMapItem.stockCheck.result === STOCK_CHECK.DISCONTINUED ||
        hashMapItem.stockCheck.result === STOCK_CHECK.NOT_ALLOWED)
        ? hashMapItem.stockCheck.quantityAsked
        : hashMapItem.quantity;
    if (!basketQuantity) {
      SentryHelper.debugQuantityZero({
        message: `product.helper.js syncBasketQuantityWithOneProduct - basketQuantity: ${basketQuantity} (${typeof basketQuantity}),`,
      });
    }
    return hashMapItem && hashMapItem.quantity > -1
      ? Object.assign({}, item, {
          basketQuantity: basketQuantity,
          basketItemUuid: hashMapItem.basketItemUuid,
        })
      : item;
  }
  return item;
};

export const getLogistics = product => {
  return [
    ['origin', product.origin],
    ['storage', product.storageCondition],
    ['net weight', product.itemNetWeight + 'kg'],
    ['collection unit price', getCollectionUnitPrice(product)],
    [
      'delivery unit price',
      (!!product.delivery && product.delivery.unitPriceDisplay) || '',
    ],
    ['size or cut', product.sizeOrCut],
    ['quantity per layer', product.qtyPerLayer],
    ['pallet quantity', product.standardPalletQty],
    [
      'dimension',
      product.depth + ' x ' + product.height + ' x ' + product.width + 'cm',
    ],
  ];
};

export const getNutrition = product => {
  return [
    ['Energy (KJ)', product.energyKJ],
    ['Energy (KCAL)', product.energyKCAL],
    ['Fat (g)', product.fatG],
    ['- of which Saturates (g)', product.fatSaturatesG],
    ['Carbohydrate (g)', product.carbohydrateG],
    ['- of which Sugars (g)', product.carbohydrateSugarsG],
    ['Protein (g)', product.proteinG],
    ['Salt (g)', product.sodiumG],
  ];
};

export const getPriceUnavailableText = alignLeft => (
  <PriceUnavailable $left={alignLeft}>Price unavailable</PriceUnavailable>
);

export const getBrandUrl = (branchName, brand) => {
  if (!branchName) {
    return null;
  }
  return `/brand/${branchName}/${brand}`;
};

export const generateProductInfo = (item, isCollection) => {
  if (!item.quantity) {
    SentryHelper.debugQuantityZero({
      message: `product.helper.js generateProductInfo - item.quantity: ${
        item.quantity
      } (${typeof item.quantity}),`,
    });
  }
  return {
    mealUuid: item.uuid,
    itemId: item.itemId,
    quantity: item.quantity,
    step: item.step,
    unitPrice: isCollection ? item.collectionPrice : item.deliveryPrice,
  };
};

export const generateProductArrayInfo = (items, isCollection) =>
  items.map(item => {
    return generateProductInfo(item, isCollection);
  });

export const getProductStep = (product, isDelivery) => {
  if (!product) {
    return 1;
  }
  return (
    product.quantityStep ||
    (isDelivery
      ? (product.delivery && product.delivery.step) || 1
      : (product.collection && product.collection.step) || 1)
  );
};

export const getProductMax = (product, isDelivery) => {
  if (!product) {
    return 0;
  }
  return (
    product.quantityMax ||
    (isDelivery
      ? (product.delivery && product.delivery.max) || 0
      : (product.collection && product.collection.max) || 0)
  );
};

export const getCollectionUnitPrice = product => {
  const cheapPriceIndex =
    product &&
    product.collectionMultiBuy &&
    product.collectionMultiBuy.length - 1;
  return (
    (!!product.collection &&
      ((product.collectionMultiBuy &&
        product.collectionMultiBuy[cheapPriceIndex] &&
        product.collectionMultiBuy[cheapPriceIndex].unitPriceDisplay) ||
        product.collection.unitPriceDisplay)) ||
    ''
  );
};
