import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {Tabs} from '../../styles/pages/modal';
import {
  ReLoginWrapper,
  ReLoginCloseButton,
  ReLoginHeader,
  ContentWrapper,
  PasswordWrapper,
  LogoutButton,
  Password,
  LoginText,
  LoginButton,
} from './LoginRegister.style';
import {getApiConfig} from '../../config/configProvider';
import Loading from '../Loading/Loading';
import {JJ_SESSION_STORAGE, KEYBOARD_KEYS} from '../../constants/constants';
import {getLoginParams} from '../../helpers/azure.helper';
import {persistDataToSessionStorage} from '../../helpers/sessionStorage.helper';
import {setErrorManually} from '../../actions/error.action';

export default class ReLoginModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
    };
  }

  setPassword = e => {
    const {errors, resetError} = this.props;
    if (errors && errors.relogin) {
      resetError();
    }
    e.preventDefault();
    this.setState({password: e.target.value});
  };
  keyDownHandler = e => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      this.submit();
    }
  };
  submit = () => {
    const config = getApiConfig();
    const {auth, submitPassword, logout} = this.props;
    const {password} = this.state;
    if (!password) {
      return;
    }
    if (!auth) {
      logout();
      return;
    }
    const loginParams = getLoginParams(window.location.hostname);
    try {
      persistDataToSessionStorage(
        JJ_SESSION_STORAGE.VERIFIER,
        loginParams.verifier
      );
    } catch (e) {
      setErrorManually(e);
    }
    const data = {
      username: auth.asub + '@' + config.jjaAccount,
      grant_type: 'password',
      account: auth.c_account,
      password,
    };
    submitPassword(data);
  };

  render() {
    const {showModal, closeModal, auth, logout, loading, errors} = this.props;
    const renderExtra = auth.email && (
      <ReLoginHeader>Your login has expired</ReLoginHeader>
    );
    const renderNotYou = auth.given_name && auth.email && (
      <LogoutButton onClick={logout}>Not {auth.given_name}?</LogoutButton>
    );
    const renderLoading =
      loading && loading.relogin ? <Loading isLight={false} /> : 'Login';
    const isError = errors && errors.relogin;
    const renderTitle = isError
      ? 'Failed to login, please re-enter your password'
      : 'Please re-enter your password to continue';
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={showModal}
        closeModal={closeModal}
      >
        <ReLoginWrapper>
          <Tabs>
            {renderExtra}
            <ReLoginCloseButton onClick={closeModal} />
          </Tabs>
          <LoginText $isError={isError}>{renderTitle}</LoginText>
          <PasswordWrapper>
            <Password
              $isError={isError}
              type="password"
              onChange={this.setPassword}
              onKeyDown={this.keyDownHandler}
            />
          </PasswordWrapper>
          {renderNotYou}
          <ContentWrapper>
            <LoginButton onClick={closeModal} data-rw="modal--cancel-button">
              Cancel
            </LoginButton>
            <LoginButton onClick={this.submit} data-rw="modal--relogin-button">
              {renderLoading}
            </LoginButton>
          </ContentWrapper>
        </ReLoginWrapper>
      </ModalWrapper>
    );
  }
}
