import React, {PureComponent} from 'react';
import Markdown from 'markdown-to-jsx';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import {Error} from './SupplyUsPage.style';
import ImageComponent from '../../components/Image/Image';
import Loading from '../../components/Loading/Loading';
import {getSupplyUs} from '../../actions/cms.action';
import {chooseColumnsStyle} from '../../helpers/data.helper';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class SupplyUsPage extends PureComponent {
  componentDidMount() {
    const {getSupplyUs} = this.props;
    getSupplyUs();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.supplyUs &&
      (prevProps.cms && prevProps.cms.supplyUs) !== cms.supplyUs
    ) {
      setCmsHeadTags(cms.supplyUs);
    }
  }

  render() {
    const {cms, loading, errors, deviceInfo} = this.props;
    const isMobile = deviceInfo && (deviceInfo.isPhone || deviceInfo.isTablet);
    const supplyUs = cms && cms.supplyUs;
    const renderMarkdown = supplyUs && supplyUs.content && (
      <div className={chooseColumnsStyle(isMobile)}>
        <Markdown>{markdownConvert(supplyUs.content)}</Markdown>
      </div>
    );
    const renderMedia = supplyUs && supplyUs.media && (
      <ImageComponent url={supplyUs.media} alt={supplyUs.heading} />
    );
    const renderLoading = loading && loading.supplyUs && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.supplyUs && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <div className="tac">
          <h1 className="tac">{supplyUs && supplyUs.heading}</h1>
          <p className="tac w70p">{supplyUs && supplyUs.pageTitle}</p>
        </div>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getSupplyUs: bindActionCreators(getSupplyUs, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplyUsPage)
);
