import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  Row,
  Title,
  Description,
  Content,
  LoadingWrapper,
  Text,
  SwitchText,
  Switch,
  SwitchWrapper,
} from './MarketingOptions.style';
import {
  getMarketingOptions,
  updateMarketingOptions,
} from '../../actions/marketing.action';
import {
  MAILCHIMP_EMAIL,
  MARKETING_PERMISSION_IDS,
} from '../../constants/constants';
import {Checkbox} from './Checkbox';
import {Loading} from '../Loading/Loading';
import PopupModal from '../PopupModal/PopupModal';
import {updateAllMarketingOptions} from '../../actions';

class MarketingOptions extends PureComponent {
  state = {
    marketing: null,
    updated: false,
    switch: false,
    allEnabled: false,
  };
  gotMarketingData = false;

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {email, marketing, simpleView} = this.props;
    if (
      prevProps.marketing !== marketing &&
      marketing &&
      marketing.email_address === MAILCHIMP_EMAIL &&
      !this.gotMarketingData
    ) {
      this.getData(email);
      this.gotMarketingData = true;
    }
    if (prevProps.marketing !== marketing && simpleView) {
      const isOn =
        marketing &&
        marketing.marketing_permissions &&
        marketing.marketing_permissions.every(item => item.enabled === true);
      if (isOn) {
        this.setState({updated: true, allEnabled: true});
      }
    }
  }

  getData = email => {
    const {getMarketingOptions} = this.props;
    getMarketingOptions(email || MAILCHIMP_EMAIL);
  };

  resetMarketingOptions = () => this.props.resetMarketingOptions();

  toggleMarketingType = (id, state) => {
    const {email, updateMarketingOptions, formValues} = this.props;
    this.setState({updated: true});
    const updateApi = !!email;
    updateMarketingOptions(id, state, email, updateApi, formValues);
  };

  resetError = () => {
    window && window.location && window.location.reload();
  };

  toggleSwitch = () => {
    const {email, updateAllMarketingOptions} = this.props;
    this.setState({switch: !this.state.switch}, () => {
      updateAllMarketingOptions(email, this.state.switch);
    });
  };

  render() {
    const {
      marketing,
      hideIfSubscribed,
      hideWhileLoading,
      loading,
      errors,
      simpleView,
      email,
    } = this.props;
    if (loading && loading.loadMarketing && hideWhileLoading) {
      return null;
    }
    const optedInToEmail =
      marketing &&
      marketing.marketing_permissions &&
      marketing.marketing_permissions.filter(
        permission =>
          permission.enabled &&
          permission.marketing_permission_id === MARKETING_PERMISSION_IDS.EMAIL
      ).length > 0;

    if (hideIfSubscribed && optedInToEmail && !this.state.switch) {
      return null;
    }

    const renderError = errors && errors.updateMarketing && (
      <PopupModal
        title={'Error'}
        content={`Failed to update marketing options, please try again`}
        showModal={errors && errors.updateMarketing}
        isError={true}
        callback={this.resetError}
      />
    );
    const renderLoading = loading &&
      (loading.updateMarketing || loading.loadMarketing) && (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      );
    if (simpleView) {
      const isOn = this.state.switch || this.state.allEnabled;
      const renderContent = !isOn && (
        <Content>
          <SwitchWrapper>
            <Switch $isOn={isOn} onClick={this.toggleSwitch} />
            <SwitchText>YES, tell me about Special Offers</SwitchText>
          </SwitchWrapper>
          <Text>Receive special offers by email, post or text message.</Text>
          <Text>You can opt out at any time.</Text>
        </Content>
      );
      const renderThankYou = isOn && hideIfSubscribed && this.state.switch && (
        <Content>
          <Text>
            Thanks for opting in to receive special offers by email, post or
            text message.
          </Text>
          <Text>You can unsubscribe at any time.</Text>
        </Content>
      );
      return (
        <Row>
          {renderError}
          <Title>Special Offer Emails</Title>
          {renderContent}
          {renderThankYou}
          {renderLoading}
        </Row>
      );
    }
    const renderMarketingOptions =
      marketing &&
      marketing.marketing_permissions &&
      marketing.marketing_permissions.map(option => (
        <Checkbox
          key={option.marketing_permission_id}
          onClick={this.toggleMarketingType}
          text={option.text}
          id={option.marketing_permission_id}
          enabled={
            (marketing.updated &&
              marketing.updated[option.marketing_permission_id]) ||
            option.enabled
          }
        />
      ));
    return (
      <Row>
        {renderError}
        <Title>Special Offer Emails</Title>
        <Content>{renderMarketingOptions}</Content>
        {renderLoading}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  marketing: state.marketing,
  auth: state.auth,
  loading: state.loading,
  errors: state.errors,
});

const mapDispatchToProps = dispatch => ({
  getMarketingOptions: bindActionCreators(getMarketingOptions, dispatch),
  updateMarketingOptions: bindActionCreators(updateMarketingOptions, dispatch),
  updateAllMarketingOptions: bindActionCreators(
    updateAllMarketingOptions,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingOptions);
