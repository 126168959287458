import styled from 'styled-components';
import {media} from '../styles/components/media-queries';
import theme from '../styles/theme';
import {isIE} from '../helpers/browser.helper';

export const StyledApp = styled.div`
  position: relative;
  width: 100rem;
  max-width: 100rem;
  margin: 0 auto;
  justify-content: center;
  z-index: 1;
  ${media.medium`
    width: 100%;
  `};
`;

export const Container = styled.div`
  width: 100%;
  ${media.medium`
    max-width: 100%;
    overflow: hidden;
  `};
`;
const mainElementStyle = `
  position: relative;
`;
export const Wrapper = isIE()
  ? styled.div(mainElementStyle)
  : styled.main(mainElementStyle);

export const Title = styled.h1`
  font-size: 6rem;
  font-weight: normal;
  padding: 2rem;
  text-align: center;
  color: ${p => p.theme.tertiaryColor};
`;

export const SubTitle = styled.h4`
  font-size: 3rem;
  font-weight: normal;
  text-align: center;
  color: ${p => p.theme.textColor};
`;

export const Text = styled.p`
  font-size: 2rem;
  text-align: center;
  color: ${p => p.theme.textColor};
  margin: 2rem 5rem;
`;

export const ContinueShopping = styled.div`
  max-width: 30rem;
  margin: 5rem auto;
`;

export const CookieLink = styled.a`
  color: ${p => p.theme.primaryColor};
`;

export const CookieConsentStyles = {
  backgroundColor: theme.primaryBgColor,
  color: theme.secondaryTextColor,
  fontSize: '1.4rem',
  justifyContent: 'center',
};

export const CookieConsentButton = {
  fontSize: '1.2rem',
  color: theme.primaryTextColor,
  backgroundColor: theme.secondaryColor,
};

export const CookieConsentContent = {
  margin: '1.5rem',
  flex: '0 1 auto',
};
