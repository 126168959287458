import * as ActionTypes from './types.action';

export const setLoginRedirect = loginRedirect => ({
  type: ActionTypes.SET_LOGIN_REDIRECT_SUCCESS,
  loginRedirect,
});

export const resetLoginRedirect = () => ({
  type: ActionTypes.RESET_LOGIN_REDIRECT_SUCCESS,
});
