import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {captureException, withScope} from '@sentry/browser';
import {
  Wrapper,
  ItemsWrapper,
  MessageWrapper,
  RemoveButton,
  QuantityWrapper,
  LoadingWrapper,
  Arrow,
  ButtonsWrapper,
  AcceptButton,
  AcceptButtonWrapper,
  RemoveButtonWrapper,
  ArrowWrapper,
  AlternativeText,
} from './Substitutes.style';
import OriginItem from './OriginItem';
import SubItem from './SubItem';
import Quantity from '../Quantity/Quantity';
import {Loading} from '../Loading/Loading';
import {
  getProductMax,
  getProductStep,
  syncBasketQuantityWithOneProduct,
} from '../../helpers/product.helper';
import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENTS,
  ERRORS,
} from '../../constants/constants';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {getProductItem} from '../../helpers/basket.helper';

export default class Substitutes extends PureComponent {
  static propTypes = {
    pairs: PropTypes.array.isRequired,
    isDelivery: PropTypes.bool,
    currency: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
    updateBasket: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    updateOosItems: PropTypes.func,
    removeOosItem: PropTypes.func,
    removeItem: PropTypes.func,
    setSubstituteItem: PropTypes.func.isRequired,
    basketHashMap: PropTypes.object,
    loading: PropTypes.object,
    showQtyBeforeAccept: PropTypes.bool,
    showRemoveItemButton: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const hasProduct = props.pairs && props.pairs[0] && props.pairs[0].product;
    this.state = {
      showQtySelector: false,
      subItem: props.pairs[1] && props.pairs[1][0],
      loading: false,
      removing: false,
      subItemIndex: 0,
      quantityAsked:
        (props.pairs[0] &&
          props.pairs[0].stockCheck &&
          props.pairs[0].stockCheck.quantityAsked) ||
        1,
      availableAt:
        props.pairs[0] &&
        props.pairs[0].stockCheck &&
        props.pairs[0].stockCheck.availableAt,
      max: getProductMax(hasProduct, props.isDelivery),
      step: getProductStep(hasProduct, props.isDelivery),
    };
    this.initOOsItem = false;
  }

  static getDerivedStateFromProps(props) {
    if (props.loading && !props.loading.addingToBasket) {
      return {
        loading: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const {basketHashMap, pairs, showQtyBeforeAccept} = this.props;
    if (prevProps.basketHashMap !== basketHashMap && pairs) {
      const subItem = syncBasketQuantityWithOneProduct(basketHashMap, pairs[1]);
      this.setState({subItem});
    }
    if (showQtyBeforeAccept && !this.initOOsItem && pairs && pairs[0]) {
      this.initOOsItem = true;
      this.updateItem(pairs[0].uuid, {quantity: 1});
    }
  }

  addToBasket = e => {
    const {updateBasket, loading, pairs} = this.props;
    if (loading && loading.addToBasket) {
      return;
    }
    e.preventDefault();
    this.setState({loading: true});
    if (pairs && pairs[1]) {
      const item = getProductItem(pairs[1], pairs[0].quantity);
      updateBasket(item, pairs[1]);
    }
  };

  remove = () => {
    const {removing} = this.state;
    if (removing) {
      return;
    }
    const {removeItem, pairs, removeOosItem} = this.props;
    this.setState({removing: true});
    if (pairs[0] && pairs[0].uuid) {
      if (this.state.showQtySelector) {
        this.setState({showQtySelector: false, removing: false});
        removeOosItem(pairs[0].uuid);
      } else {
        removeItem(pairs[0].uuid, pairs[0].product);
      }
    } else {
      withScope(scope => {
        scope.setExtra('pairs', pairs);
        captureException(new Error(ERRORS.REMOVE_ITEM));
      });
    }
  };

  selectSubItem = subItemIndex => this.setState({subItemIndex});
  selectPrevItem = () => {
    const {subItemIndex} = this.state;
    if (subItemIndex === 0) {
      return;
    }
    this.setState({subItemIndex: subItemIndex - 1});
  };
  selectNextItem = () => {
    const {subItemIndex} = this.state;
    if (subItemIndex >= this.props.pairs[1].length - 1) {
      return;
    }
    this.setState({subItemIndex: subItemIndex + 1});
  };
  acceptAlternative = () => {
    const {
      setSubstituteItem,
      pairs,
      showQtyBeforeAccept,
      isDelivery,
    } = this.props;
    const {step, subItemIndex} = this.state;
    const originQuantity =
      pairs[0] && pairs[0].stockCheck && pairs[0].stockCheck.quantityAsked;
    const subItem = pairs[1] && pairs[1][subItemIndex];
    const subStep = isDelivery
      ? (subItem && subItem.delivery && subItem.delivery.step) || 1
      : (subItem && subItem.collection && subItem.collection.step) || 1;
    const quantity =
      originQuantity === 1
        ? originQuantity * subStep || 1
        : (originQuantity / step) * subStep;
    const item = {
      uuid: pairs[0].uuid,
      originItemId: pairs[0].itemId,
      quantity,
      itemId: subItem && subItem.itemId,
    };
    if (showQtyBeforeAccept && !this.state.showQtySelector) {
      return this.setState({showQtySelector: true});
    }
    setSubstituteItem([item]);
    const actionGA = `${ANALYTICS_EVENTS.ACCEPT_SUBSTITUTE} (${pairs[0] &&
      pairs[0].itemId} -> ${pairs[1] &&
      pairs[1][this.state.subItemIndex] &&
      pairs[1][this.state.subItemIndex].itemId})`;
    const actionJoachlytics = ANALYTICS_EVENTS.ACCEPT_SUBSTITUTE;
    const dataGA = {
      action: ANALYTICS_EVENTS.ACCEPT_SUBSTITUTE,
      category: ANALYTICS_EVENT_CATEGORIES.SUBSTITUTE,
    };
    const dataJoachlytics = {
      originalItem: `${pairs[0] && pairs[0].itemId}`,
      substituteItem: `${pairs[1] &&
        pairs[1][this.state.subItemIndex] &&
        pairs[1][this.state.subItemIndex].itemId}`,
      action: ANALYTICS_EVENTS.ACCEPT_SUBSTITUTE,
      category: ANALYTICS_EVENT_CATEGORIES.SUBSTITUTE,
    };
    GoogleAnalyticsHelper.trackCustomEvent(
      actionGA,
      dataGA,
      actionJoachlytics,
      dataJoachlytics
    );
  };
  updateItem = (uuid, opts) => {
    const {showQtyBeforeAccept, updateItem, updateOosItems, pairs} = this.props;
    if (showQtyBeforeAccept) {
      const itemId =
        pairs &&
        pairs[1] &&
        pairs[1][this.state.subItemIndex] &&
        pairs[1][this.state.subItemIndex].itemId;
      if (opts.quantity > 0 && itemId) {
        this.setState({quantityAsked: opts.quantity});
        updateOosItems(uuid, opts.quantity, itemId);
      }
    } else {
      updateItem(uuid, opts);
    }
  };

  render() {
    const {
      pairs,
      onItemClick,
      currency,
      loading,
      showQtyBeforeAccept,
      showRemoveItemButton,
    } = this.props;
    const {subItem, subItemIndex, availableAt} = this.state;
    const renderSubstitute = subItem ? (
      <SubItem
        onItemClick={onItemClick}
        currency={currency}
        subItemIndex={subItemIndex}
        alternativeItems={pairs[1]}
        selectSubItem={this.selectSubItem}
        selectPrevItem={this.selectPrevItem}
        selectNextItem={this.selectNextItem}
        hideAlternatives={this.state.showQtySelector}
      />
    ) : (
      <MessageWrapper $unavailable={true}>
        No alternative available
      </MessageWrapper>
    );
    const renderQuantity = this.state.showQtySelector && showQtyBeforeAccept && (
      <QuantityWrapper>
        <Quantity
          quantity={this.state.quantityAsked}
          update={this.updateItem}
          uuid={pairs[0].uuid}
          remove={this.remove}
          step={this.state.step}
          max={this.state.max}
          loading={showQtyBeforeAccept ? {updatingBasket: false} : loading}
          isWide={showQtyBeforeAccept}
          unitPrice={pairs[0].unitPrice}
        />
      </QuantityWrapper>
    );
    const renderRemoveButton =
      this.props.showRemoveItemButton &&
      (this.state.removing ? (
        <RemoveButtonWrapper>
          <LoadingWrapper>
            <Loading isLight={false} />
          </LoadingWrapper>
        </RemoveButtonWrapper>
      ) : (
        <RemoveButtonWrapper>
          <RemoveButton onClick={this.remove}>Remove</RemoveButton>
        </RemoveButtonWrapper>
      ));
    const hasSubItems = pairs[1] && pairs[1].length > 1;
    const acceptButton = (
      <AcceptButton onClick={this.acceptAlternative}>
        Accept <AlternativeText>Substitution</AlternativeText>
      </AcceptButton>
    );
    const renderAcceptButtonLoading = loading && loading.loadingSubstitutes && (
      <AcceptButton>
        <Loading isLight={false} />
      </AcceptButton>
    );
    const renderAcceptButton =
      loading &&
      !loading.loadingSubstitutes &&
      !this.state.showQtySelector &&
      acceptButton;
    return (
      <Wrapper>
        <ItemsWrapper>
          <OriginItem
            onItemClick={onItemClick}
            currency={currency}
            unavailable={true}
            item={pairs[0]}
            hasSubItems={hasSubItems}
            hideAlternatives={this.state.showQtySelector}
            availableAt={availableAt}
          />
          <ArrowWrapper $hasSubItems={hasSubItems}>
            <Arrow />
          </ArrowWrapper>
          {renderSubstitute}
        </ItemsWrapper>
        <ButtonsWrapper
          $hasAlternative={subItem}
          $showRemoveItemButton={showRemoveItemButton}
        >
          <AcceptButtonWrapper $show={subItem}>
            {renderAcceptButton}
            {renderAcceptButtonLoading}
            {renderQuantity}
          </AcceptButtonWrapper>
          {renderRemoveButton}
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}
