import * as ActionTypes from './types.action';

export const getAccounts = (jjToken, fromCache, tradingAccountId) => ({
  type: ActionTypes.GET_ACCOUNTS_REQUESTED,
  jjToken,
  fromCache,
  tradingAccountId,
});

export const setAccount = (account, jjToken) => ({
  type: ActionTypes.SET_ACCOUNTS_REQUESTED,
  account,
  jjToken,
});

export const refreshAxAccount = accountId => ({
  type: ActionTypes.REFRESH_AX_ACCOUNT_REQUESTED,
  accountId,
});

export const setSelectedAccount = account => ({
  type: ActionTypes.SET_SELECTED_ACCOUNTS_SUCCESS,
  accounts: [account],
});

export const resetAccounts = () => ({
  type: ActionTypes.RESET_ACCOUNTS_REQUESTED,
});

export const searchAccounts = (keywords, jjToken) => ({
  type: ActionTypes.SEARCH_ACCOUNTS_REQUESTED,
  keywords,
  jjToken,
});

export const registerUser = payload => ({
  type: ActionTypes.REGISTER_USER_REQUESTED,
  payload,
});

export const requestResetPassword = email => ({
  type: ActionTypes.PASSWORD_RESET_REQUESTED,
  email,
});

export const setNewPassword = payload => ({
  type: ActionTypes.SET_NEW_PASSWORD_REQUESTED,
  payload,
});

export const changePassword = payload => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUESTED,
  payload,
});

export const activateAccount = payload => ({
  type: ActionTypes.ACTIVATE_ACCOUNT_REQUESTED,
  payload,
});

export const resetActivateAccount = () => ({
  type: ActionTypes.ACTIVATE_ACCOUNT_RESET,
});

export const contactCS = payload => ({
  type: ActionTypes.CONTACT_CUSTOMER_SERVICE_REQUESTED,
  payload,
});

export const getPaymentDetails = () => ({
  type: ActionTypes.GET_PAYMENT_DETAILS_REQUESTED,
});

export const setPaymentDetails = paymentDetails => ({
  type: ActionTypes.SET_PAYMENT_DETAILS_REQUESTED,
  paymentDetails,
});
