import * as ActionTypes from './types.action';

export const getBlogCategories = () => ({
  type: ActionTypes.GET_BLOG_CATEGORIES_HOME_INFO_REQUESTED,
});

export const setBlogCategories = blogCategories => ({
  type: ActionTypes.GET_BLOG_CATEGORIES_HOME_INFO_SUCCESS,
  blogCategories,
});

export const getBlogCategoryBySlug = slug => ({
  type: ActionTypes.GET_BLOG_CATEGORY_SLUG_REQUESTED,
  slug,
});

export const setBlogCategoryBySlug = blogPosts => ({
  type: ActionTypes.GET_BLOG_CATEGORY_SLUG_SUCCESS,
  blogPosts,
});

export const getBlogPostById = postId => ({
  type: ActionTypes.GET_BLOG_POST_BY_ID_REQUESTED,
  postId,
});

export const getBlogPostBySlug = postSlugName => ({
  type: ActionTypes.GET_BLOG_POST_BY_SLUG_REQUESTED,
  postSlugName,
});

export const getBlogPostByTitle = postTitle => ({
  type: ActionTypes.GET_BLOG_POST_BY_TITLE_REQUESTED,
  postTitle,
});

export const setBlogPost = blogPost => ({
  type: ActionTypes.GET_BLOG_POST_BY_ID_SUCCESS,
  blogPost,
});
