import {JJ_TEL, JJ_TEL_FULL} from './constants';

export const TERMS_AND_CONDITIONS_LINK = {
  content: 'Terms and conditions',
  url: '/content/terms-and-conditions',
};

export const PRIVACY_POLICY_LINK = {
  content: 'Privacy policy',
  url: 'https://www.jjfoodservice.com/content/privacy-policy',
  external: true,
  blank: true,
};

export const FOOTER_LINKS = [
  {
    header: 'Contact us',
    links: [
      {
        title: 'Live Chat',
        isOpenChat: true,
      },
      {
        title: 'Branch Locations & Opening Hours',
        url: '/branchLocator/',
      },
      {
        content: '7 Solar Way, Enfield, EN3 7XY',
        url: 'https://goo.gl/maps/HZafHVWSo4TFbFeE8',
        external: true,
      },
      {
        content: JJ_TEL,
        url: `tel:${JJ_TEL_FULL}`,
        external: true,
      },
    ],
  },
  {
    header: 'Customer Services',
    links: [
      {
        content: 'Frequently Asked Questions',
        url: '/content/frequently-asked-questions',
      },
      {
        content: 'JJ Pay By Bank FAQ',
        url: '/content/jj-pay-by-bank-faq',
      },
      {
        content: 'Vendor self service',
        url: 'https://vss.jjfoodservice.com/',
        external: true,
        blank: true,
      },
      TERMS_AND_CONDITIONS_LINK,
      {
        content: 'Policies, Reports And Certificates',
        url: '/content/our-policies-reports-and-certificates',
        external: false,
        blank: false,
      },
      {
        content: 'Export Enquiry',
        url: 'mailto:export@jjfoodservice.com',
        external: true,
        blank: true,
      },
    ],
  },
  {
    header: 'General',
    links: [
      {
        content: 'JJ Careers',
        url: '/content/careers',
      },
      {
        content: 'Brochures',
        url: '/brochures',
      },
      {
        content: 'About Us',
        url: '/content/about-us',
      },
      {
        content: 'Our Mission',
        url: '/content/our-mission-vision-and-values',
      },
      {
        content: 'Supply Us',
        url: '/content/supply-us',
      },
      {
        content: 'CSR & Sustainability',
        url:
          '/content/our-pledge-to-sustainability-and-corporate-social-responsibility',
      },
    ],
  },
  {
    header: 'Follow Us',
    links: [
      {
        content: 'Facebook',
        url: 'https://www.facebook.com/jjfoodservice',
        external: true,
        blank: true,
      },
      {
        content: 'Twitter',
        url: 'https://twitter.com/jjfoodservice',
        external: true,
        blank: true,
      },
      {
        content: 'Instagram',
        url: 'https://www.instagram.com/jjfoodservice',
        external: true,
        blank: true,
      },
      {
        content: 'TikTok',
        url: 'https://www.tiktok.com/@jjfoodservice',
        external: true,
        blank: true,
      },
      {
        content: 'LinkedIn',
        url: 'https://www.linkedin.com/company/jj-food-service-limited',
        external: true,
        blank: true,
      },
      {
        content: 'YouTube',
        url: 'https://www.youtube.com/channel/UCt4UlV3a_3DW_-Zlrqn9EVQ',
        external: true,
        blank: true,
      },
      {
        content: 'JJ Blog',
        url: '/blog',
        external: true,
      },
    ],
  },
];
