import React, {PureComponent} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {captureException} from '@sentry/browser';

import {Container} from './LoginSuccessPage.style';
import {Loading} from '../../components/Loading/Loading';
import {
  ANALYTICS_EVENTS,
  AZURE_ACCOUNT_TYPES,
  JJ_LOCAL_STORAGE,
  JJ_SESSION_STORAGE,
  SPLITTER,
} from '../../constants/constants';
import {
  getDataFromSessionStorage,
  persistDataToSessionStorage,
} from '../../helpers/sessionStorage.helper';
import {setErrorManually} from '../../actions/error.action';
import {getTokenByCode, reGetTokenByCode} from '../../actions/auth.action';
import {getAccounts} from '../../actions/accounts.action';
import {
  getDataFromLocalStorage,
  removeValueFromLocalStorage,
} from '../../helpers/localStorage.helper';
import LoginError from '../../components/Errors/LoginError';
import {resetNextRoute, setNextRoute} from '../../actions/nextRoute.action';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {getConfigManually} from '../../helpers/config.helper';
import {SUCCESS_REGISTER_WELCOME_PATH} from '../../constants/routePaths';

class LoginSuccessPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoggedIn: !!this.azState,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      accounts,
      getAccounts,
      loginToken,
      history,
      errors,
      requireLogin,
      nextRoute,
      resetNextRoute,
      isRegister,
    } = this.props;
    if (loginToken && !prevProps.loginToken) {
      getAccounts(loginToken);
    }
    if (errors && errors.azure) {
      history.push('/');
    }
    if (accounts && accounts !== prevProps.accounts) {
      // navigate back to basket if requireLogin in the checkout flow
      history.push(
        requireLogin
          ? '/basket'
          : isRegister
          ? SUCCESS_REGISTER_WELCOME_PATH
          : '/'
      );
    }
    if (nextRoute) {
      resetNextRoute();
    }
  }

  async componentDidMount() {
    if (!window.serverConfig) {
      // fixed the SamSung login issue, which wipes out window variable after redirect
      await getConfigManually();
    }
    const {
      location,
      requireLogin,
      isRegister,
      getLoginToken,
      getReLoginToken,
      setNextRoute,
      history,
    } = this.props;
    // preventing browser back button to remember the login token after logout
    let previousAzureState;
    let verifier;
    try {
      verifier = getDataFromSessionStorage(JJ_SESSION_STORAGE.VERIFIER);
      previousAzureState = getDataFromSessionStorage(
        JJ_SESSION_STORAGE.AZURE_STATE
      );
    } catch (e) {
      setErrorManually(e);
    }
    const query = queryString.parse(location.search);
    if (previousAzureState && previousAzureState === query.state) {
      history.push(isRegister ? SUCCESS_REGISTER_WELCOME_PATH : '/');
      return;
    }
    if (query.code) {
      if (verifier) {
        const data = {
          code: query.code,
          code_verifier: verifier,
        };
        const domain = window.location.hostname;
        persistDataToSessionStorage(
          JJ_SESSION_STORAGE.AZURE_STATE,
          query.state
        );
        const isStaffAdAccount =
          query.state.indexOf(AZURE_ACCOUNT_TYPES.AD) === 0;
        if (query.state.indexOf(SPLITTER.LOGIN_REDIRECT) > 0) {
          const results = query.state.split(SPLITTER.LOGIN_REDIRECT);
          if (results && results[1]) {
            await GoogleAnalyticsHelper.trackEvent(
              ANALYTICS_EVENTS.LOGIN_SUCCESS
            );
            // redirect after login
            setNextRoute(results[1]);
          }
        }
        if (requireLogin) {
          const cAccount = getDataFromLocalStorage(JJ_LOCAL_STORAGE.C_ACCOUNT);
          removeValueFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN);
          getReLoginToken(data, cAccount, isStaffAdAccount, domain);
        } else {
          getLoginToken(data, isStaffAdAccount, domain, isRegister);
        }
      }
    } else {
      // Show error message if found in url params
      if (query.error) {
        let details;
        try {
          details = decodeURIComponent(query.error_description);
        } catch (e) {
          details = query.error_description;
        }
        this.setState({
          error: details,
        });
        GoogleAnalyticsHelper.logError(details, ANALYTICS_EVENTS.AZURE_ERROR);
        captureException(new Error(details));
      }
    }
  }

  render() {
    const {error} = this.state;
    const renderLoading = !error && <Loading isLight={false} />;
    const renderError = error && <LoginError error={error} />;
    return (
      <Container>
        {renderLoading}
        {renderError}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loginToken: state.loginToken,
  accounts: state.accounts,
  errors: state.errors,
});
const mapDispatchToProps = dispatch => ({
  getLoginToken: bindActionCreators(getTokenByCode, dispatch),
  getReLoginToken: bindActionCreators(reGetTokenByCode, dispatch),
  getAccounts: bindActionCreators(getAccounts, dispatch),
  setNextRoute: bindActionCreators(setNextRoute, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccessPage);
