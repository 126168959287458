import initialState from './initial.state';
import * as types from '../actions/types.action';
import {
  ANALYTICS_EVENTS,
  BASKET_ERRORS_TYPE,
  ROUTES,
} from '../constants/constants';
import {GoogleAnalyticsHelper} from '../helpers/googleAnalytics.helper';
import {GET_BASKET_SUCCEEDED} from '../actions/types.action';

export default function analyticsReducer(
  state = initialState.analytics,
  action
) {
  switch (action.type) {
    case types.UPDATE_SETTING_REQUESTED:
      if (action.setting && action.value) {
        GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SET_SETTINGS, {
          setting: action.setting,
          value: action.value,
        });
      }
      return state;

    case types.SET_FULFILL_SLOT_REQUESTED:
      if (action.uuid && action.fulfillmentType) {
        GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SET_FULFILLMENT, {
          basketUuid: action.uuid,
          fulfillmentType: action.fulfillmentType,
        });
      }
      return state;

    case types.REMOVE_BASKET_SUCCEEDED:
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.REMOVE_BASKET);
      return state;

    case types.ADD_TO_FAVOURITES_REQUESTED:
      if (action.product) {
        GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.ADD_FAVOURITE, {
          product: action.product,
        });
      }
      return state;

    case types.REMOVE_FROM_FAVOURITES_REQUESTED:
      if (action.product) {
        GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.REMOVE_FAVOURITE, {
          product: action.product,
        });
      }
      return state;

    case types.CHECKOUT_SLOT_EXPIRED:
      const issue =
        action.page === ROUTES.CHECKOUT
          ? BASKET_ERRORS_TYPE.SLOT_EXPIRED_DURING_CHECKOUT
          : BASKET_ERRORS_TYPE.SLOT_EXPIRED;
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.CHECKOUT_SLOT_EXPIRED, {
        issue,
      });
      return state;

    case types.PROCESS_CHECKOUT_REQUESTED:
      if (action.basket) {
        GoogleAnalyticsHelper.trackConfirmation(
          action.basket.items,
          action.basket.fulfillmentType
        );
      }
      return state;

    case types.AUTHORIZE_NON_CARD_PAYMENT_REQUESTED:
    case types.AUTHORIZE_ADYEN_PAYMENT_REQUESTED:
      if (action.basket) {
        GoogleAnalyticsHelper.trackSuccess(action.basket.items, {
          id: action.basket.uuid,
          revenue: action.basket.price && action.basket.price.total,
          shipping:
            action.basket.price && action.basket.price.delivery.toString(),
        });
      }
      return state;

    case types.GET_BASKET_SUCCEEDED:
      GoogleAnalyticsHelper.setFulfillment(action.basket.fulfillmentType);
      GoogleAnalyticsHelper.updateStore({basket: action.basket});
      return state;

    case types.GET_SEARCH_RESULTS_REQUESTED:
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SEARCH, action.params);
      return state;

    case types.GET_SEARCH_RESULTS_SUCCESS:
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SEARCH_RESULTS, {
        results: action.searchResults.length,
      });
      return state;

    case types.REGISTER_USER_FAILED:
    case types.REGISTER_USER_SUCCESS:
      let messageData = {
        message: action.message,
        messages: action.messages && action.messages.data,
      };
      const actionType =
        action.messages &&
        action.messages.data &&
        action.messages.data.Data &&
        action.messages.data.Data.Created
          ? types.REGISTER_USER_SUCCESS
          : types.REGISTER_USER_FAILED;
      GoogleAnalyticsHelper.trackEvent(actionType, {
        payload: Object.assign({}, action.payload),
        ...(actionType === types.REGISTER_USER_FAILED && messageData),
      });
      return state;

    case types.SET_PAYMENT_TYPE_SUCCESS:
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SET_PAYMENT_TYPE, {
        paymentMethod: action.basket.paymentMethod,
      });
      return state;

    case types.GET_PRODUCT_DETAIL_SUCCESS:
      GoogleAnalyticsHelper.updateStore({productDetail: action.productDetail});
      return state;

    case types.SEND_REFUND_EMAIL_REQUESTED:
      GoogleAnalyticsHelper.requestRefund(
        action.items,
        action.accountDetails,
        action.axBasketId
      );
      return state;
    default:
      return state;
  }
}
