import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function basketHashMapReducer(
  state = initialState.basketHashMap,
  action
) {
  switch (action.type) {
    case types.SET_BASKET_HASHMAP_SUCCEEDED:
      return action.basketHashMap;
    case types.REMOVE_AUTH_REQUESTED:
    case types.RESET_SETTING_SUCCESS:
    case types.REMOVE_BASKET_SUCCEEDED:
      return null;
    default:
      return state;
  }
}
