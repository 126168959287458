import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  BranchText,
  BranchContent,
  ViewBranch,
  Postcode,
  BranchDetails,
} from './BranchLocatorPage.style';

export default class Branch extends PureComponent {
  static propTypes = {
    onViewBranchClicks: PropTypes.func,
    branch: PropTypes.object,
  };

  onLinkClick = () => this.props.callback(this.props.branch.inventLocationId);

  render() {
    const {branch} = this.props;
    return (
      <BranchText key={branch.branchName}>
        <BranchContent>{branch.branchName}</BranchContent>
        <BranchDetails>
          <Postcode>{branch.address && branch.address.zipcode}</Postcode>
          <ViewBranch onClick={this.onLinkClick}>view</ViewBranch>
        </BranchDetails>
      </BranchText>
    );
  }
}
