import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import {DEFAULT_DATE_FORMAT} from '../../constants/constants';
import {StyledPicker} from './RenderDatePicker.style';

export default class RenderDatePicker extends PureComponent {
  static propTypes = {
    date: PropTypes.object,
    onDateChange: PropTypes.func.isRequired,
    isDelivery: PropTypes.bool,
    isDayBlocked: PropTypes.func,
  };

  state = {
    focused: null,
  };

  handleOnFocusChange = value => {
    this.setState({focused: value.focused});
  };

  render() {
    const {date, isDayBlocked, onDateChange, isDelivery} = this.props;
    return (
      <StyledPicker $isDelivery={isDelivery}>
        <SingleDatePicker
          date={date}
          onDateChange={onDateChange}
          focused={this.state.focused}
          onFocusChange={this.handleOnFocusChange}
          id={'date'}
          required={true}
          readOnly={true}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          displayFormat={DEFAULT_DATE_FORMAT}
          showDefaultInputIcon
          isDayBlocked={isDayBlocked}
        />
      </StyledPicker>
    );
  }
}
