import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function initReducer(state = initialState.modal, action) {
  switch (action.type) {
    case types.TOGGLE_SWITCH_ACCOUNT_MODAL_SUCCESS:
      return Object.assign({}, state, {
        switchAccount: action.status,
      });
    case types.TOGGLE_UPSELLING_MODAL_SUCCESS:
      return Object.assign({}, state, {
        upselling: action.status,
      });
    case types.TOGGLE_SUBSTITUTES_MODAL_SUCCESS:
      return Object.assign({}, state, {
        substitutes: action.status,
      });
    case types.SET_ACCOUNTS_SUCCESS:
    case types.SET_ACCOUNTS_FAILED:
    case types.SET_AUTH_SUCCESS:
      return Object.assign({}, state, {
        switchAccount: false,
      });
    case types.TOGGLE_CHANGE_BRANCH_MODAL_SUCCESS:
      return Object.assign({}, state, {
        changeBranch: action.status,
      });
    case types.TOGGLE_OPEN_IN_JJS_MODAL_SUCCESS:
      return Object.assign({}, state, {
        openJJsOrders: action.status,
      });
    case types.TOGGLE_MAINTENANCE_MODAL_SUCCESS:
      return Object.assign({}, state, {
        maintenance: action.status,
      });
    case types.TOGGLE_STOP_EDIT_MODAL_SUCCESS:
      return Object.assign({}, state, {
        stopEdit: action.status,
      });
    case types.TOGGLE_AVAILABLE_ONLY_MODAL_SUCCESS:
      return Object.assign({}, state, {
        availableOnly: {status: action.status, itemData: action.itemData},
      });
    case types.TOGGLE_RELOGIN_MODAL_SUCCESS:
      return Object.assign({}, state, {
        relogin: action.status,
      });
    case types.GET_TOKEN_BY_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        relogin: false,
      });
    case types.TOGGLE_PAYMENT_DETAILS_MODAL_SUCCESS:
      return Object.assign({}, state, {
        paymentDetails: action.status,
      });
    case types.SET_PAYMENT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        paymentDetails: false,
      });
    case types.TOGGLE_CATCH_WEIGHT_MODAL_SUCCESS:
      return Object.assign({}, state, {
        catchWeight: action.status,
      });
    case types.TOGGLE_BASKET_SYNC_FAILED_MODAL_SUCCESS:
      return Object.assign({}, state, {
        basketSyncFailed: action.status,
      });
    case types.TOGGLE_RATING_MODAL_SUCCESS:
      return Object.assign({}, state, {
        showRatings: action.status,
      });
    case types.TOGGLE_CUSTOMER_SUPPORT_MODAL_SUCCESS:
      return Object.assign({}, state, {
        customerSupportMessage: action.status,
      });
    default:
      return state;
  }
}
