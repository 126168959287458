import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {TheTab} from './EditItems.style';

export default class UpsellingTab extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool,
    isVisible: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  tabClick = () => {
    const {index, onClick} = this.props;
    onClick(index);
  };

  render() {
    const {isActive, title, isVisible} = this.props;
    return (
      <TheTab
        $isActive={isActive}
        $isInline={true}
        onClick={this.tabClick}
        $isVisible={isVisible}
      >
        {title}
      </TheTab>
    );
  }
}
