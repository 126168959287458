import React, {PureComponent} from 'react';
import {DropdownStandard} from '../../styles/components/dropdown';

export default class AllBranchList extends PureComponent {
  render() {
    const {defaultValue, callback, branchList, selectorValue} = this.props;
    const renderBranches =
      branchList &&
      branchList.length > 0 &&
      branchList.map(branch => (
        <option value={branch[1].code} key={branch[1].code}>
          {branch[1].name}
        </option>
      ));
    return (
      <DropdownStandard
        onChange={callback}
        defaultValue={defaultValue}
        value={selectorValue}
      >
        {renderBranches}
      </DropdownStandard>
    );
  }
}
