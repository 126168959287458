import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const MarketingWrapper = styled.article`
  margin-top: ${p => (p.$noGap ? 0 : '4.4rem')};
  ${media.medium`
      margin-top: ${p => (p.$noGap ? 0 : '2rem')};
  `};
  ${media.small`
      margin-top: ${p => (p.$noGap ? '-1rem' : 0)};
  `};
`;
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 49rem;
  ${media.medium`
    padding: 0 2rem;
    height: 42rem;
  `};
  ${media.small`
    flex-direction: coloumn;
    padding: 0 2rem;
    height: 38rem;
  `};
  ${media.smaller`
    display: block;
    flex-direction: coloumn;
    padding: ${p => (p.$noGap ? '0 1rem' : '1rem 1rem 0 1rem')};
    height: 74rem;
  `};
  ${media.smallest`
    display: block;
    flex-direction: coloumn;
    height: 61rem;
  `};
`;

export const MainGrid = styled.a`
  width: 50%;
  height: 96%;
  background-size: cover;
  cursor: pointer;
  margin-right: ${p => (p.$extraPadding ? '2rem' : 0)};
  min-width: ${p => (p.$extraPadding ? '50%;' : 'auto')};
  ${media.smaller`
    width: 96%;
    height: 46%;
    margin: 2%;
    display: block;
  `};

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`;

export const SubGrid = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 50%;
  height: 100%;
  overflow: hidden;
  ${media.smaller`
    width: 100%;
    height: 50%;
    margin-top: 4%;
  `};
`;

export const ExtraGridsWrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 100%;
  flex-wrap: wrap;
  ${media.medium`
    padding: 0 1rem 0 1rem;
  `};
`;

export const Grid = styled.a`
  width: 46%;
  height: 46%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  margin-left: 4%;
  margin-bottom: 4%;
  display: flex;
  ${media.smaller`
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
  `};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ExtraGrid = styled.a`
  width: 46%;
  height: 46%;
  max-width: 23%;
  max-height: 23%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  margin-bottom: 2%;
  margin-left: 2%;
  display: flex;
  ${media.medium`
    margin-right: 1%;
    margin-left: 1%;
  `};
  ${media.smaller`
    max-width: 46%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 4%;
  `};

  img {
    width: 100%;
    height: 100%;
  }
`;
