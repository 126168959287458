import {put, takeLatest, call} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import pbbBankDetailsApi from '../api/pbbBankDetails.api';
import {ECOSPEND_ERROR} from '../constants/constants';

function* getPbbBankDetails(action) {
  try {
    const pbbDefaultBank = yield call(
      pbbBankDetailsApi.getBankDetailsById,
      action.bankId
    );
    if (
      pbbDefaultBank &&
      pbbDefaultBank.error &&
      pbbDefaultBank.error === ECOSPEND_ERROR.INVALID_BANK_ID
    ) {
      yield put({
        type: ActionTypes.GET_PBB_BANK_DETAILS_FAILED,
        message: pbbDefaultBank.error,
      });
      return;
    }
    yield put({
      type: ActionTypes.GET_PBB_BANK_DETAILS_SUCCESS,
      pbbDefaultBank,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_PBB_BANK_DETAILS_FAILED,
      message: e.message,
    });
  }
}

export default function* pbbDefaultBankYield() {
  yield takeLatest(
    ActionTypes.GET_PBB_BANK_DETAILS_REQUESTED,
    getPbbBankDetails
  );
}
