import React, {PureComponent} from 'react';

import {
  ItemProp,
  ItemPropValue,
  OrderItem,
  ItemPropNonLink,
  OrderFullRow,
  SelectBox,
  CheckboxLabel,
  SelectBoxWrapper,
  ItemTagText,
  ButtonRow,
  UploadPic,
  UploadImage,
  OrderFullRowWrapper,
  OrderFullRowSubWrapper,
  QtySelector,
  ReasonMobileRow,
  HideOnMobileBlock,
  GalleryWrapper,
  ButtonGroup,
  ButtonImageGroup,
  GalleryDesktop,
  SelectorButton,
  BatchInfo,
} from './OrdersPage.style';
import RefundQty from './RefundQty';
import CaptureModal from '../../components/PopupModal/CaptureModal';
import Gallery from '../../components/Gallery/Gallery';
import {
  FILE_EXT_REGEX,
  QUALITY_ISSUE,
  REFUND_REASONS,
  SIZE_LIMIT,
} from '../../constants/constants';
import {isReadyToRefund} from '../../helpers/data.helper';

export default class TheOrderItem extends PureComponent {
  constructor(props) {
    super(props);
    const {alwaysOpen} = props;
    this.state = {
      detailsOpen: !!alwaysOpen,
      statusChangedAtFormatted: null,
      refundQty: 1,
      showCamera: false,
      refundReason: '',
      showBatchInfo: false,
    };
  }

  toggleSelect = () => {
    const {toggleSelect, item, isRefundMode} = this.props;
    if (!isRefundMode) {
      return;
    }
    toggleSelect(item.itemId);
  };

  onProductClick = e => {
    e.preventDefault();
    const {isRefundMode, item, navToProduct} = this.props;
    if (isRefundMode) {
      this.toggleSelect();
    } else {
      navToProduct && navToProduct(item.itemId);
    }
  };

  toggleCamera = () => this.setState({showCamera: !this.state.showCamera});

  setSavePhoto = (photo, fileExt) => {
    const {item, setItemPhoto} = this.props;
    setItemPhoto(item.itemId, photo, this.state.refundQty, fileExt);
  };

  setRefundQty = qty => {
    const {item, setItemQuantity} = this.props;
    this.setState({refundQty: qty});
    setItemQuantity(item.itemId, qty);
  };

  onReasonChange = e => {
    const reason = e.target.value;
    const {item, setItemRefundReason} = this.props;
    this.setState({
      refundReason: reason,
      showBatchInfo: reason === QUALITY_ISSUE,
    });
    setItemRefundReason(item.itemId, reason, this.state.refundQty);
  };

  removePhoto = () => {
    this.setSavePhoto(null);
  };

  uploadFile = e => {
    const file = e.target.files[0];
    if (!/\.(jpe?g|png)$/i.test(file.name)) {
      return;
    }
    const fileExt = file.name.match(FILE_EXT_REGEX)[0];
    const size = (parseFloat(file.size) / 1000).toFixed(2);
    if (size > SIZE_LIMIT.REQUEST_REFUND_IMAGE) {
      this.props.toggleImageSizeError();
      return;
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.setSavePhoto(reader.result, fileExt);
      },
      false
    );
    if (e.target.files[0]) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    const {
      isRefundMode,
      item,
      isSameDayDelivery,
      isSelected,
      showCaptureButton,
      refundItem,
      refundError,
      refundErrorsDetail,
    } = this.props;
    const {refundQty, showCamera, refundReason, showBatchInfo} = this.state;
    const renderCameraModal = showCamera && (
      <CaptureModal
        showModal={showCamera}
        closeModalCallback={this.toggleCamera}
        itemId={item.itemId}
        setSavePhoto={this.setSavePhoto}
      />
    );
    const itemName = item && (item.name || item.itemName);
    const renderOrderItem =
      !!item &&
      (isSameDayDelivery ? (
        <ItemPropNonLink>{itemName || item.itemId}</ItemPropNonLink>
      ) : (
        <ItemProp
          href={`/productDetail/${item.itemId}`}
          onClick={this.onProductClick}
        >
          {item.itemId} {!!itemName && ' - '} {itemName}
          <ItemTagText> x {item.quantity || item.quantityInvoiced}</ItemTagText>
        </ItemProp>
      ));
    const readyForRefund = isSelected && isReadyToRefund(refundItem);
    const renderSelectedBox = isSelected && <SelectBox />;
    const hasPhoto = !!refundItem && refundItem.photo;
    const renderGallery = hasPhoto && (
      <Gallery gallery={[refundItem.photo]} isSmall={true} />
    );
    const renderGalleryDesktop = isSelected && (
      <HideOnMobileBlock>
        <GalleryDesktop>{renderGallery}</GalleryDesktop>
      </HideOnMobileBlock>
    );
    const renderGalleryMobile = isSelected && (
      <GalleryWrapper>{renderGallery}</GalleryWrapper>
    );
    const renderCheckBox = isRefundMode && !!item && (
      <SelectBoxWrapper
        data-id={item.itemId + item.name}
        $isSelected={isSelected}
        onClick={this.toggleSelect}
      >
        {renderSelectedBox}
        <CheckboxLabel />
      </SelectBoxWrapper>
    );
    const noPhotosError =
      refundErrorsDetail && refundErrorsDetail.photo && isSelected;
    const renderUploadBtn = !hasPhoto && (
      <UploadImage
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        onChange={this.uploadFile}
        $isError={noPhotosError}
      />
    );
    const renderCameraBtn = !hasPhoto && showCaptureButton && (
      <UploadPic
        noMarginLeft={true}
        $isError={noPhotosError}
        onClick={this.toggleCamera}
        $isCaptureIcon={true}
      >
        Capture
      </UploadPic>
    );
    const renderRemoveBtn = hasPhoto && (
      <UploadPic noMarginRight hasMarginLeft onClick={this.removePhoto}>
        Remove Photo
      </UploadPic>
    );
    const renderOptions = REFUND_REASONS.map((item, i) => (
      <option key={i} value={item}>
        {item}
      </option>
    ));
    const renderReasons = (
      <QtySelector
        data-rw="reason-selector"
        onChange={this.onReasonChange}
        value={refundReason}
      >
        <option value="">Reason for refund</option>
        {renderOptions}
      </QtySelector>
    );
    const renderBatchInfo = showBatchInfo && (
      <BatchInfo>
        Quality issue: Please send us a picture of the label with batch number.
      </BatchInfo>
    );
    const renderButtons = isSelected && item && (
      <ButtonRow data-rw="button-row" $hasPhoto={hasPhoto}>
        {renderRemoveBtn}
        <HideOnMobileBlock>
          <SelectorButton
            $hasMargin={true}
            $isError={
              refundErrorsDetail && refundErrorsDetail.reason && isSelected
            }
          >
            {renderReasons}
          </SelectorButton>
        </HideOnMobileBlock>
        <RefundQty
          quantity={item.quantity || item.quantityInvoiced}
          catchWeightItem={!!item.catchWeightItem}
          refundQty={refundQty}
          setRefundQty={this.setRefundQty}
        />
        {renderUploadBtn}
        {renderCameraBtn}
      </ButtonRow>
    );
    const renderExtra = isSelected && item && (
      <ReasonMobileRow>
        <SelectorButton
          $isError={
            refundErrorsDetail && refundErrorsDetail.reason && isSelected
          }
        >
          {renderReasons}
        </SelectorButton>
      </ReasonMobileRow>
    );
    const thePrice = !!item && (item.price || item.linePrice);
    return (
      <OrderFullRowWrapper
        $isSelected={isSelected}
        $isReady={readyForRefund}
        $isError={refundError}
      >
        {renderCameraModal}
        <OrderFullRowSubWrapper>
          <OrderFullRow>
            {renderCheckBox}
            <OrderItem>
              {renderOrderItem}
              <ItemPropValue onClick={this.toggleSelect}>
                £{thePrice && thePrice.toFixed(2)}
              </ItemPropValue>
            </OrderItem>
          </OrderFullRow>
          <ButtonImageGroup data-rw="button-image-group">
            <ButtonGroup>
              {renderButtons}
              {renderExtra}
            </ButtonGroup>
            {renderGalleryMobile}
          </ButtonImageGroup>
        </OrderFullRowSubWrapper>
        {renderGalleryDesktop}
        {renderBatchInfo}
      </OrderFullRowWrapper>
    );
  }
}
