import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function shopBySectorsReducer(
  state = initialState.shopBySectorsReducer,
  action
) {
  switch (action.type) {
    case types.GET_SHOP_BY_SECTORS_SUCCESS:
      return action.shopBySectors;
    case types.GET_SHOP_BY_SECTORS_REQUESTED:
    case types.GET_SHOP_BY_SECTORS_FAILED:
      return null;
    default:
      return state;
  }
}
