import * as ActionTypes from './types.action';

export const updateBasketItem = (uuid, params, jjToken) => ({
  type: ActionTypes.UPDATE_BASKET_ITEM_REQUESTED,
  uuid,
  params,
  jjToken,
});

export const removeBasketItem = (uuid, jjToken) => ({
  type: ActionTypes.REMOVE_BASKET_ITEM_REQUESTED,
  uuid,
  jjToken,
});

export const getBasket = (
  withProductDetails,
  silentlyFetch,
  onlyUpdateTotalPrice,
  fromWebSocket
) => {
  let actionType = ActionTypes.GET_BASKET_REQUESTED;
  if (silentlyFetch || fromWebSocket) {
    actionType = ActionTypes.GET_BASKET_SILENTLY_REQUESTED;
  }
  return {
    type: actionType,
    withProductDetails,
    onlyUpdateTotalPrice,
  };
};

export const checkBasketSynced = () => ({
  type: ActionTypes.CHECK_BASKET_SYNC_REQUESTED,
});
export const updateBasket = (item, jjToken) => ({
  type: ActionTypes.UPDATE_BASKET_REQUESTED,
  item,
  jjToken,
});

export const removeBasket = () => ({
  type: ActionTypes.REMOVE_BASKET_SUCCEEDED,
});

export const setFulfillment = fulfillment => ({
  type: ActionTypes.SET_FULFILLMENT_REQUESTED,
  fulfillment,
});

export const setPaymentType = paymentType => ({
  type: ActionTypes.SET_PAYMENT_TYPE_REQUESTED,
  paymentType,
});

export const syncBasketItemsWithMessages = basketItems => ({
  type: ActionTypes.SYNC_BASKET_ITEMS_WITH_MESSAGE_SUCCESS,
  basketItems,
});

export const resetBasketItems = () => ({
  type: ActionTypes.RESET_BASKET_ITEMS_SUCCEEDED,
});

export const setSubstituteItem = substitutes => ({
  type: ActionTypes.SET_SUBSTITUTE_ITEM_REQUESTED,
  substitutes,
});

export const reOrderBasket = (uuid, jjToken) => ({
  type: ActionTypes.REORDER_BASKET_REQUESTED,
  uuid,
  jjToken,
});

export const editOrder = (orderId, jjToken) => ({
  type: ActionTypes.EDIT_ORDER_REQUESTED,
  orderId,
  jjToken,
});

export const applyVoucher = (voucher, jjToken) => ({
  type: ActionTypes.ADD_VOUCHER_REQUESTED,
  jjToken,
  voucher,
});

export const clearVoucherErrors = () => ({
  type: ActionTypes.CLEAR_VOUCHER_ERRORS_SUCCEEDED,
});

export const removeVoucher = uuid => ({
  type: ActionTypes.REMOVE_VOUCHER_REQUESTED,
  uuid,
});

export const deleteAxBasket = () => ({
  type: ActionTypes.REMOVE_AX_BASKET_REQUESTED,
});

export const addAllShoppingListToBaskets = listId => ({
  type: ActionTypes.ADD_ALL_SHOPPING_LIST_ITEMS_REQUESTED,
  listId,
});
