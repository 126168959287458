import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Wrapper} from '../../styles/pages/modal';
import {Close} from '../../styles/pages/modal';

export const CloseButton = styled(Close)`
  display: none;
  ${media.small`
    display: flex;
  `};
`;

export const SwitchAccountWrapper = styled(Wrapper)`
  width: 60rem;
`;

export const PanelWrapper = styled.div`
  max-height: 80vh;
  background-color: ${p => p.theme.tertiaryBgColor};
  padding: 1rem 1rem 3rem 1rem;
  position: relative;
  ${media.small`
    padding-top: 0;
  `};
  ${media.smaller`
    max-height: 86vh;
    padding: 1rem;
  `};
`;

export const AccountList = styled.div`
  overflow-y: scroll;
  max-height: 40rem;
  margin-top: 0.5rem;
  &&::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.8rem;
  }
  &&::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: ${p => p.theme.primaryColor};
  }
  ${media.smaller`
    max-height: 80vh;
    height: 80rem;
  `};
`;

export const AccountDetails = styled.div`
  display: flex;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  text-transform: capitalize;
  cursor: pointer;
  overflow-x: hidden;
  &:hover {
    background-color: ${p => p.theme.secondaryBgColor};
  }
  ${media.small`
    display: block;
  `};
`;

export const AccountStyled = styled.div`
  display: flex;
`;

export const LoadingWrapper = styled.div`
  padding-top: 2rem;
`;

export const No = styled.div`
  padding-right: 1rem;
  line-height: 2.4rem;
  color: ${p => p.theme.primaryColor};
`;

export const Name = styled(No)`
  color: ${p => p.theme.secondaryColor};
`;

export const Address = styled(No)`
  padding-right: 0;
  color: ${p => p.theme.textColor};
`;

export const Error = styled.div`
  padding: 2rem 2rem 0;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryColor};
`;
export const NoResults = styled.div`
  padding: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
`;

export const SearchAccount = styled.input`
  width: 100%;
  padding: 1rem;
  outline: none;
  ${media.small`
    margin-top: 1rem;
  `};
  ${media.smaller`
    margin-top: 0;
    background-color: ${p => p.theme.secondaryBgColor};
    border: 0;
  `};
`;
