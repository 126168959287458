import * as ActionTypes from './types.action';
import {GET_BRANCH_LIST_SUCCESS} from './types.action';

export const setBranch = branch => ({
  type: ActionTypes.SET_BRANCH_SUCCESS,
  branch,
});

export const getBranchList = () => ({
  type: ActionTypes.GET_BRANCH_LIST_REQUESTED,
});

export const setBranchList = branchList => ({
  type: ActionTypes.GET_BRANCH_LIST_SUCCESS,
  branchList,
});

export const getNearestBranch = postcode => ({
  type: ActionTypes.GET_NEAREST_BRANCH_REQUESTED,
  postcode,
});

export const setNearestBranch = branchLocator => ({
  type: ActionTypes.GET_NEAREST_BRANCH_SUCCESS,
  branchLocator,
});

export const clearInvalidPostcode = () => ({
  type: ActionTypes.CLEAR_INVALID_POSTCODE_SUCCESS,
});

export const getBranchOpeningTimes = () => ({
  type: ActionTypes.GET_BRANCH_OPENING_TIMES_REQUESTED,
});

export const setBranchOpeningTimes = openingTimes => ({
  type: ActionTypes.GET_BRANCH_OPENING_TIMES_SUCCESS,
  openingTimes,
});
