export const GQL_GET_PRODUCT_DETAILS = `query(
    $itemIds: [String!]!
    $deliveryDate: Date
    $branch: String
    $includeNonPublicItems: Boolean
) {
    searchProductsByItemId(
        itemIds: $itemIds
        deliveryDate: $deliveryDate
        branch: $branch
        includeNonPublicItems: $includeNonPublicItems
    ) {
        results {
            uuid
            itemId
            itemName
            price
            productDescription
            offer {
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collectionMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            deliveryMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            favourite
            imageLinks
            isAvailable
            tags
            isNew
            allergensDeclaration
            cookingInstruction
            ingredients
            ingredientsHTML
            energyKJ
            energyKCAL
            fatG
            fatSaturatesG
            carbohydrateG
            carbohydrateSugarsG
            proteinG
            sodiumG
            brand
            origin
            storageCondition
            itemNetWeight
            sizeOrCut
            qtyPerLayer
            standardPalletQty
            depth
            height
            width
            categoryName
            previouslyPurchased
            productFeatures
            branches {
              locationId
              locationName
            }
            shoppingLists {
                shoppingListId
                quantity
            }
            video {
              videoURL
            }
            category1Id
            category2Id
            category3Id
            category1Name
            category2Name
            category3Name
        }
    }
}`;
