import jwt_decode from 'jwt-decode';
import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.SET_AUTH_SUCCESS:
      return action.auth;
    case types.SET_ACCOUNTS_SUCCESS:
    case types.REFRESH_AUTH_SUCCESS:
    case types.GET_TOKEN_BY_PASSWORD_SUCCESS:
      if (!action.access_token) {
        return state;
      }
      const auth = {
        access_token: action.access_token,
        ...jwt_decode(action.access_token),
      };
      return Object.assign({}, state, auth);
    case types.REMOVE_AUTH_REQUESTED:
    case types.SET_LOGIN_TOKEN_SUCCESS: // clear the enhanced token when login token is fetched
      return null;
    default:
      return state;
  }
}
