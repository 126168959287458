import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function blogPostsReducer(
  state = initialState.blogPosts,
  action
) {
  switch (action.type) {
    case types.GET_BLOG_CATEGORY_SLUG_SUCCESS:
      return action.blogPosts;
    case types.GET_BLOG_CATEGORY_SLUG_REQUESTED:
    case types.GET_BLOG_CATEGORY_SLUG_FAILED:
      return null;
    default:
      return state;
  }
}
