import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {CrossIcon} from '../../styles/components/icons';

export const Item = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  color: ${p => p.theme.textColor};
  border-top: ${p => `solid 0.1rem ${p.theme.secondaryBgColor}`};
  &:hover {
    background-color: ${p => p.theme.secondaryBgColorHover};
  }
  ${media.smaller`
    height: 11rem;
  `};
`;

export const Product = styled.div`
  display: flex;
  flex-grow: 1;
  padding-left: 2rem;
  ${media.smaller`
    width: 40%;
    justify-content: space-between;
  `};
  ${media.smallest`
    padding-left: 1rem;
  `};
`;

export const ProductInfo = styled.div`
  cursor: pointer;
  width: 50%;
  display: flex;
  align-items: center;
  ${media.medium`
    width: 60%;
  `};
  ${media.smaller`
    width: 63%;
  `};
`;

export const Details = styled.div`
  padding: 0 2rem;
  width: 100%;
  ${media.small`
    padding: 0 1rem 0 2rem;
  `};
  ${media.smaller`
    padding: 0 0 0 1rem;
    min-height: 5rem;
  `};
`;

export const ProductContent = styled(ProductInfo)`
  width: 50%;
  position: relative;
  justify-content: space-between;
  flex-grow: 1;
  ${media.medium`
    width: 46%;
  `};
  ${media.small`
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  `};
  ${media.smaller`
    width: 37%;
    padding: 2rem 1rem 1rem;
  `};
  ${media.smallest`
    padding: 2rem 0 1rem;
  `};
`;

export const ImageWrapper = styled.div`
  img {
    min-width: 7rem;
    height: 7rem;
    max-height: 7rem;
    ${media.smaller`
      min-width: 5rem;
      height: 5rem;
      max-height: 5rem;
    `};
  }
`;

export const Name = styled.span`
  height: 100%;
  padding-right: 0.4rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${p => p.theme.secondaryColor};
  font-size: ${p => (p.$isLarge ? '1.4rem' : '1.2rem')};
`;

export const ItemId = styled.div`
  font-size: 1.6rem;
  color: ${p => p.theme.primaryColor};
  ${media.small`
    font-size: 1.4rem;
  `};
`;

export const Price = styled.div`
  position: relative;
  text-align: right;
  width: 8rem;
  line-height: 2rem;
  margin-right: 1rem;
  color: ${p => (p.$isNotSet ? p.theme.disabledColor : p.theme.textColor)};
  ${media.medium`
    width: 8rem;
  `};
  ${media.smaller`
    width: 7.4rem;
    font-size: 1.2rem;
  `};
  ${media.smallest`
    font-size: 1.2rem;
    width: 7rem;
  `};
`;

export const ItemVat = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0;
  color: ${p => p.theme.disabledColor};
  font-size: 1.1rem;
`;

export const LoadingWrapper = styled.div`
  padding: 0 2rem;
  min-width: 9.7rem;
  ${media.medium`
    max-width: 6rem;
    padding-left: 1rem;
    min-width: 6rem;
  `};
  ${media.small`
    position: absolute;
    right: 1rem;
    top: 4.2rem;
    height: 4rem;
    max-width: 2rem;
    &::before {
      position: absolute;
      right: 1rem;
    }
  `};
  ${media.smaller`
    top: 1.8rem;
    &::before {
      right: 0;
    }
  `};
`;

export const Remove = styled(CrossIcon)`
  display: flex;
  position: relative;
  height: 4rem;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
  cursor: pointer;
  flex-shrink: 1;
  flex-direction: row-reverse;
  padding-right: 2rem;
  ${media.medium`
    visibility: hidden;
    max-width: 6rem;
    flex-direction: row;
    &::before {
      visibility: visible;
      position: absolute;
      right: 2rem;
    }
  `};
  ${media.small`
    position: absolute;
    right: 1rem;
    top: 4.2rem;
    height: 4rem;
    max-width: 2rem;
    &::before {
      position: absolute;
      right: 1rem;
    }
  `};
  ${media.smaller`
    top: 1.6rem;
    &::before {
      right: 0;
    }
  `};
  &::before {
    color: ${p => p.theme.tertiaryColor};
  }
`;

export const QuantityStyled = styled.div`
  min-width: 14rem;
  max-width: 14rem;
  ${media.small`
    max-width: 10rem;
    min-width: 10rem;
    margin: 0.5rem 0;
  `};
`;

export const PromotionContent = styled.div`
  padding-top: 1rem;
`;

export const ProductMessage = styled.div`
  font-size: 1.2rem;
  position: absolute;
  right: 2rem;
  color: ${p => p.theme.tertiaryColor};
  bottom: 1rem;
  ${media.small`
    display: none;
  `};
`;

export const ProductMessageMobile = styled.div`
  background-color: ${p => p.theme.tertiaryTextColor};
  padding: 1rem;
  color: ${p => p.theme.primaryTextColor};
  display: none;
  ${media.small`
    display: block;
  `};
`;

export const InfoBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p =>
    p.$warning ? p.theme.primaryColorHover : p.theme.tertiaryTextColor};
`;

export const OutOfStockGroup = styled.div`
  padding: 1rem 0;
  ${media.smaller`
    padding: 1rem;
  `};
`;
