import {call, put, takeLatest} from 'redux-saga/effects';
import emailApi from '../api/email.api';
import * as ActionTypes from '../actions/types.action';
import {BASE64_PRE_REGEX, URL} from '../constants/constants';

function* requestRefund(action) {
  const isDev = window.location.host.indexOf(URL.JJS_PROD) === -1;
  const {items, accountDetails} = action;
  const lineBreak = '<br /><br />';
  let message = isDev ? 'This is a testing email from JJ web' + lineBreak : '';
  const attachments = {};
  items.map(item => {
    if (item.photo) {
      const fileName = item.itemId + (!!item.fileExt ? item.fileExt : '.png');
      attachments[fileName] = item.photo.replace(BASE64_PRE_REGEX, '');
    }
    message +=
      item.itemId +
      ' x ' +
      (item.quantity || 1) +
      ' : ' +
      item.reason +
      lineBreak;
  });
  message += 'Please verify about request.' + lineBreak;
  message += 'Thank you' + lineBreak;
  message += 'From JJ Foodservice Website.' + lineBreak;
  const targetEmail = 'customerservice@jjfoodservice.zohodesk.eu';
  const data = {
    to: {sales: targetEmail},
    subject: `${isDev ? '[TESTING]' : ''} Item Refund Requested - ${
      accountDetails.accountId
    } : ${accountDetails.axBasketId} `,
    fields: {
      Customer: accountDetails.name,
      'Customer Email': accountDetails.email,
      'Customer Account': accountDetails.accountId,
      'Order No': accountDetails.axBasketId,
    },
    message,
    attachments,
  };
  try {
    const result = yield call(emailApi.requestRefund, JSON.stringify(data));
    if (result === 'email sent') {
      yield put({
        type: ActionTypes.SEND_REFUND_EMAIL_SUCCESS,
      });
    } else {
      yield put({
        type: ActionTypes.SEND_REFUND_EMAIL_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.SEND_REFUND_EMAIL_FAILED,
      errorCode: e && e.response && e.response.status,
    });
  }
}

export default function* getEmailYield() {
  yield takeLatest(ActionTypes.SEND_REFUND_EMAIL_REQUESTED, requestRefund);
}
