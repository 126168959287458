import React from 'react';
import {Title, Text} from './NotFound.style';
import {Wrapper} from '../../styles/pages/pages';

export const NotFound = () => (
  <Wrapper>
    <Title>404</Title>
    <Text>Sorry, page not found</Text>
  </Wrapper>
);
