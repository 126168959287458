import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function pageReducer(state = initialState.page, action) {
  switch (action.type) {
    case types.GET_SEARCH_RESULTS_PAGE_SUCCESS:
    case types.GET_MORE_SEARCH_RESULTS_PAGE_SUCCESS:
    case types.GET_OFFERS_RESULTS_PAGE_SUCCESS:
      return Object.assign({}, state, action.page);
    default:
      return state;
  }
}
