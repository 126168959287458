import {put, call, takeLatest} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import jjContentApi from '../api/jjContent.api';

function* getContentBySlug(action) {
  try {
    const jjContent = yield call(
      jjContentApi.getContentBySlug,
      action.contentSlugName
    );
    yield put({
      type: ActionTypes.GET_JJ_CONTENT_BY_SLUG_SUCCESS,
      jjContent,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_JJ_CONTENT_BY_SLUG_FAILED,
      message: e.message,
    });
  }
}

function* getShopBySectors() {
  try {
    const shopBySectors = yield call(
      jjContentApi.getContentBySlug,
      'shop-by-sectors'
    );
    yield put({
      type: ActionTypes.GET_SHOP_BY_SECTORS_SUCCESS,
      shopBySectors,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_SHOP_BY_SECTORS_FAILED,
      message: e.message,
    });
  }
}

export default function* jjContentYield() {
  yield takeLatest(
    ActionTypes.GET_JJ_CONTENT_BY_SLUG_REQUESTED,
    getContentBySlug
  );
  yield takeLatest(ActionTypes.GET_SHOP_BY_SECTORS_REQUESTED, getShopBySectors);
}
