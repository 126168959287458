import React from 'react';
import {OfferTitleText} from '../Offers/Offers.style';
import {LeftLine, RightLine} from '../../styles/components/lines';
import {SectionTitle, Wrapper} from './ShopBySectors.style';
import {replaceContentUploadUrls} from '../../helpers/data.helper';

const ShopBySectors = ({shopBySectors}) => {
  const renderShopBySectors =
    shopBySectors &&
    shopBySectors.attributes &&
    shopBySectors.attributes.content;

  return (
    <Wrapper>
      <SectionTitle id="shop-by-sectors">
        <LeftLine />
        <OfferTitleText>Shop By Sector</OfferTitleText>
        <RightLine />
      </SectionTitle>
      <div
        dangerouslySetInnerHTML={{
          __html: replaceContentUploadUrls(renderShopBySectors),
        }}
      ></div>
    </Wrapper>
  );
};
export default ShopBySectors;
