import React, {PureComponent} from 'react';
import {Service, CheckMark, Type} from './FulfillmentBtn.style';
import {SERVICE_TYPE} from '../../constants/constants';

export default class FulfillmentBtn extends PureComponent {
  render() {
    const {callback, isChecked, fulfillmentType} = this.props;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    return (
      <Service onClick={callback}>
        <CheckMark
          $isChecked={isChecked}
          $isDelivery={isDelivery}
          data-rw={`bookslot--fulfillment-${fulfillmentType}-radio`}
        />
        <Type $isChecked={isChecked} $isDelivery={isDelivery}>
          {fulfillmentType}
        </Type>
      </Service>
    );
  }
}
