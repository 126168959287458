import axios from 'axios';
import queryString from 'query-string';

import {getApiConfig} from '../config/configProvider';
import {convertToOldRegisterApi} from '../helpers/api.helper';

export default class accountApi {
  static getAccounts(headers) {
    const config = getApiConfig();
    const url = `${config.accountUrlV2}/accounts`;
    return axios.get(url, {headers}).then(res => res.data);
  }

  static setAccounts(data) {
    const config = getApiConfig();
    const url = `${config.accountUrlV2}/oauth/token`;
    return axios.post(url, data).then(res => res.data);
  }

  static searchAccounts(keywords, headers) {
    const config = getApiConfig();
    const url = `${config.accountSearch}/search?query=${keywords}`;
    return axios.get(url, {headers}).then(res => res.data);
  }

  static register(payload) {
    const config = getApiConfig();
    const payloadData = convertToOldRegisterApi(payload);
    return axios.post(
      config.olympusRegisterUrl,
      queryString.stringify(payloadData),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
  }

  static getAccountById(accountId, headers) {
    const config = getApiConfig();
    const url = `${config.accountUrl}/account/${accountId}`;
    return axios.get(url, {headers}).then(res => [res.data]);
  }

  static refreshAxAccountById(accountId, headers) {
    const config = getApiConfig();
    const url = `${config.accountUrl}/account/${accountId}`;
    return axios.post(url, null, {headers}).then(res => res.data);
  }

  static sendResetEmail(email) {
    const config = getApiConfig();
    const data = {
      Version: 'v1',
      Email: email,
    };
    return axios.post(
      `${config.registerUrl}/Registration/Password/Reset/Request`,
      queryString.stringify(data),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
  }

  static passwordReset(code, password) {
    const config = getApiConfig();
    const data = {
      Version: 'v1',
      Email: code,
      PASSWORD: password,
    };
    return axios.post(
      `${config.registerUrl}/Registration/Password/Reset/Confirm`,
      queryString.stringify(data),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
  }

  static changePassword(email, oldPassword, password) {
    const config = getApiConfig();
    const data = {
      Version: 'v1',
      Email: email,
      PASSWORD: oldPassword,
      Caccount: password,
    };
    return axios.put(
      `${config.registerUrl}/Registration/Password/Change/`,
      queryString.stringify(data),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
  }

  static activateAccount(id) {
    const config = getApiConfig();
    const data = {
      Id: id,
      Version: 'v1',
    };
    return axios.post(
      `${config.registerUrl}/Registration/Account/Activate/`,
      queryString.stringify(data),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
  }
  static getPaymentDetails(headers) {
    const config = getApiConfig();
    const url = `${config.accountUrl}/payment_details`;
    return axios.get(url, {headers}).then(res => res.data);
  }

  static setPaymentDetails(data, headers) {
    const config = getApiConfig();
    const url = `${config.accountUrl}/payment_details`;
    return axios.post(url, data, {headers}).then(res => res.data);
  }
}
