import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function reloginAccountReducer(
  state = initialState.reloginAccount,
  action
) {
  switch (action.type) {
    case types.SET_RELOGIN:
      return action.cAccount;
    case types.RESET_RELOGIN:
      return null;
    default:
      return state;
  }
}
