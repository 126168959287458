import initialState from './initial.state';
import * as types from '../actions/types.action';
import {SYSTEM_MESSAGE} from '../constants/constants';
import {getFilteredSystemMessage} from '../helpers/data.helper';

export default function systemMessagesReducer(
  state = initialState.systemMessages,
  action
) {
  switch (action.type) {
    case types.SET_ANNOUNCEMENT_MESSAGES_SUCCESS: {
      let systemMessages = {};
      const announcement = getFilteredSystemMessage(action.data);
      systemMessages = Object.assign({}, state, {
        announcement,
      });
      return systemMessages;
    }
    case types.SET_MAINTENANCE_MESSAGES_SUCCESS: {
      let systemMessages = {};
      const maintenance = getFilteredSystemMessage(action.data);
      systemMessages = Object.assign({}, state, {
        maintenance,
      });
      return systemMessages;
    }
    default:
      return state;
  }
}
