import React from 'react';
import {CheckboxLabel} from './MarketingOptions.style';
import {CheckMark} from '../../styles/components/checkbox';

export const Checkbox = props => {
  const {onClick, text, enabled, id} = props;
  const onClickCheckbox = () => {
    onClick(id, !enabled);
  };
  return (
    <CheckboxLabel onClick={onClickCheckbox}>
      <CheckMark $isChecked={enabled} />
      {text}
    </CheckboxLabel>
  );
};
