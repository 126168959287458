import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class emailApi {
  static contactCS(data) {
    const config = getApiConfig();
    const url = `${config.onBoardingUrl}/mail/incorrect-address`;
    return axios.post(url, data).then(res => res.data);
  }
  static async requestRefund(data) {
    const config = getApiConfig();
    const headerConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return axios
      .post(
        config.emailRefundUrl,
        `data=` + encodeURIComponent(data),
        headerConfig
      )
      .then(res => res.data);
  }
}
