import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function openingTimesReducer(
  state = initialState.openingTimes,
  action
) {
  switch (action.type) {
    case types.GET_BRANCH_OPENING_TIMES_SUCCESS:
      return action.openingTimes;
    case types.GET_BRANCH_OPENING_TIMES_FAILED:
      return {};
    default:
      return state;
  }
}
