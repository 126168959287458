import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dropin from 'braintree-web-drop-in';
import {
  BRAINTREE_TYPES,
  BUTTON_NAME,
  MANUALLY_ERRORS,
  PAYMENT_ERROR_MESSAGE,
} from '../../constants/constants';
import {TheButton} from '../CheckoutPage/CheckoutPage.style';
import {
  authBraintreePayment,
  getCheckoutData,
} from '../../actions/checkout.action';
import {
  BraintreeWrapper,
  ButtonWrapper,
  LoadingWrapper,
} from './BraintreePage.style';
import {setCleanMode} from '../../actions/cleanMode.action';
import PaymentErrorModal from '../../components/PopupModal/PaymentErrorModal';
import {resetError, setErrorManually} from '../../actions/error.action';
import {getBraintreeInitObj} from '../../helpers/braintree.helper';
import Loading from '../../components/Loading/Loading';

class BraintreePage extends PureComponent {
  constructor(props) {
    super(props);
    props.setCleanMode();
    const {
      orderId,
      responseUrl,
      amount,
      clientToken,
      threeDS,
      allowPaypal,
    } = props.match.params;

    let threeDSParam;
    try {
      threeDSParam = JSON.parse(decodeURIComponent(threeDS));
    } catch (e) {
      threeDSParam = null;
    }
    this.state = {
      price: decodeURIComponent(amount),
      orderId: decodeURIComponent(orderId),
      token: decodeURIComponent(clientToken),
      allowPaypal: allowPaypal === 'true',
      threeDSParam,
      responseUrl: decodeURIComponent(responseUrl),
      showBraintreeButton: false,
      isSubmittingPayment: false,
      isReloading: false,
    };
  }

  componentDidMount() {
    const {price, token, threeDSParam, allowPaypal} = this.state;
    const dropinObj = getBraintreeInitObj(token, price, allowPaypal);
    if (price && token && threeDSParam) {
      dropin.create(dropinObj, (createErr, instance) => {
        if (!instance) {
          return;
        }
        this.setState({showBraintreeButton: true});
        this.braintreeInstance = instance;
        try {
          this.braintreeInstance.on('paymentOptionSelected', data => {
            if (
              data &&
              (data.paymentOption === BRAINTREE_TYPES.APPLE_PAY ||
                data.paymentOption === BRAINTREE_TYPES.GOOGLE_PAY)
            ) {
              this.setState({showBraintreeButton: false});
            }
          });
          this.braintreeInstance.on('noPaymentMethodRequestable', () => {
            this.setState({showBraintreeButton: false});
          });
          this.braintreeInstance.on('paymentMethodRequestable', event => {
            if (
              event &&
              event.paymentMethodIsSelected &&
              (event.type === BRAINTREE_TYPES.APPLE_PAY_CARD ||
                event.type === BRAINTREE_TYPES.ANDROID_PAY_CARD)
            ) {
              this.sendNonceToServer();
            } else if (!this.state.showBraintreeButton) {
              this.setState({showBraintreeButton: true});
            }
          });
        } catch (e) {
          this.setState({isSubmittingPayment: false});
          this.handleBraintreeFailed(e);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {nextRoute, errors} = this.props;
    if (nextRoute && !prevProps.nextRoute) {
      if (nextRoute && window && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(nextRoute);
      }
    }
    if (errors && errors.braintree && this.state.isSubmittingPayment) {
      this.setState({isSubmittingPayment: false});
    }
  }

  sendNonceToServer = () => {
    const {authBraintreePayment} = this.props;
    const {threeDSParam, orderId, responseUrl} = this.state;
    if (!this.braintreeInstance) {
      this.handleBraintreeFailed({
        message: PAYMENT_ERROR_MESSAGE.GENERAL,
      });
      return;
    }
    this.braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: threeDSParam,
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          this.handleBraintreeFailed(requestPaymentMethodErr);
          return;
        }
        if (payload && payload.nonce) {
          this.setState({isSubmittingPayment: true});
          const fromApp = true;
          authBraintreePayment(
            responseUrl,
            {
              nonce: payload.nonce,
              deviceData: payload.deviceData,
            },
            orderId,
            fromApp
          );
        } else {
          this.handleBraintreeFailed({
            message: PAYMENT_ERROR_MESSAGE.ANOTHER_METHOD,
          });
        }
      }
    );
  };
  clearSelectedPaymentMethod = () => {
    if (
      this.braintreeInstance &&
      this.braintreeInstance.clearSelectedPaymentMethod
    ) {
      this.braintreeInstance.clearSelectedPaymentMethod();
    }
  };
  handleBraintreeFailed = errors => {
    this.clearSelectedPaymentMethod();
    this.props.setErrorManually(
      MANUALLY_ERRORS.AUTHORIZE_BRAINTREE_PAYMENT_FAILED,
      [errors]
    );
  };

  resetBraintreeErrors = () => {
    this.setState({isReloading: true});
    this.props.resetError();
    this.clearSelectedPaymentMethod();
    location.reload();
  };

  render() {
    const {errors} = this.props;
    const {isSubmittingPayment, isReloading} = this.state;
    const renderBrainTreeButton = this.state.showBraintreeButton && (
      <TheButton id="submit-button" onClick={this.sendNonceToServer}>
        {BUTTON_NAME.PAY_NOW}
      </TheButton>
    );
    const renderBraintreeErrors = errors &&
      errors.braintree &&
      errors.braintree.length > 0 && (
        <PaymentErrorModal
          isBraintree={true}
          errors={errors.braintree}
          showModal={errors.braintree.length > 0}
          callback={this.resetBraintreeErrors}
        />
      );
    if (isSubmittingPayment || isReloading) {
      return (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      );
    }
    return (
      <Fragment>
        {renderBraintreeErrors}
        <BraintreeWrapper>
          <div id="dropin-container" />
          <ButtonWrapper>{renderBrainTreeButton}</ButtonWrapper>
        </BraintreeWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  checkout: state.checkout,
  errors: state.errors,
  loadingProps: state.loading,
  nextRoute: state.nextRoute,
});

const mapDispatchToProps = dispatch => ({
  getCheckoutData: bindActionCreators(getCheckoutData, dispatch),
  authBraintreePayment: bindActionCreators(authBraintreePayment, dispatch),
  setCleanMode: bindActionCreators(setCleanMode, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
  setErrorManually: bindActionCreators(setErrorManually, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(BraintreePage);
