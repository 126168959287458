import {put, call, select, takeLatest, delay} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import productApi from '../api/product.api';
import {getBearerHeader} from '../helpers/api.helper';
import {
  checkProductDetails,
  enrichBasketItems,
  getValidUSDate,
  processedProductDetail,
  sortBasketItems,
} from '../helpers/data.helper';
import {
  BASKET_RECHECK_TIMEOUT,
  JJ_LOCAL_STORAGE,
  MAX_RETRY,
  MESSAGES,
  ORDER_SERVICE_DATE_FORMAT,
  STORE_DELAY_TIME,
} from '../constants/constants';
import {getDataFromLocalStorage} from '../helpers/localStorage.helper';
import moment from 'moment';
import {waitFor} from '../helpers/saga.helper';

function* getProduct(action) {
  try {
    const getAuth = state => state.auth;
    const getBranch = state => state.branch;
    const getBasket = state => state.basket;
    let branchId = yield select(getBranch);
    let storeToken = yield select(getAuth);
    let basket = yield select(getBasket);
    const times = 3;
    const ms = 300;
    if (!storeToken) {
      storeToken = yield waitFor(getAuth, times, ms);
    }
    const headers = getBearerHeader(action.jjToken, storeToken);
    let fulfillmentDetailsFromLocal;
    if (!basket) {
      fulfillmentDetailsFromLocal = yield getDataFromLocalStorage(
        JJ_LOCAL_STORAGE.FULFILLMENT
      );
      basket = yield waitFor(getBasket, MAX_RETRY, BASKET_RECHECK_TIMEOUT);
    }
    let time = 0;
    while (!branchId) {
      time += 1;
      branchId = yield select(getBranch);
      yield delay(STORE_DELAY_TIME);
      if (time > 10) {
        break;
      }
    }
    let deliveryDate = moment(new Date()).format(ORDER_SERVICE_DATE_FORMAT);
    if (basket && basket.fulfillAtSlot && basket.fulfillAtSlot.day) {
      deliveryDate = basket.fulfillAtSlot.day;
    } else if (fulfillmentDetailsFromLocal) {
      deliveryDate = fulfillmentDetailsFromLocal.date;
    }
    const branchSpecificProductDetail = yield call(
      productApi.getProduct,
      {
        itemIds: [action.details.itemId],
        deliveryDate: getValidUSDate(deliveryDate),
        branch: action.details.branchId || branchId,
      },
      headers
    );
    if (
      branchSpecificProductDetail &&
      branchSpecificProductDetail.errors &&
      branchSpecificProductDetail.errors.length > 0
    ) {
      yield put({
        type: ActionTypes.GET_PRODUCT_DETAIL_FAILED,
        message: MESSAGES.GET_PRODUCT_DETAILS_FAILED,
      });
    }
    if (checkProductDetails(branchSpecificProductDetail)) {
      const genericProductDetail = yield call(
        productApi.getProduct,
        {
          itemIds: [action.details.itemId],
          deliveryDate: getValidUSDate(deliveryDate),
          branch: '',
        },
        headers
      );
      const genericProductDetailData =
        processedProductDetail(
          genericProductDetail.data.searchProductsByItemId.results[0]
        ) || null;

      yield put({
        type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
        productDetail: genericProductDetailData,
      });
    } else {
      const branchSpecificProductDetailData =
        processedProductDetail(
          branchSpecificProductDetail.data.searchProductsByItemId.results[0]
        ) || null;
      yield put({
        type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
        productDetail: branchSpecificProductDetailData,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_PRODUCT_DETAIL_FAILED,
      message: e.message,
    });
  }
}

function* getProductsByIds(action) {
  try {
    const getAuth = state => state.auth;
    const getBranch = state => state.branch;
    const getBasket = state => state.basket;
    const storeToken = yield select(getAuth);
    const branchId = yield select(getBranch);
    const basket = yield select(getBasket);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      b: branchId,
      q: action.ids.join(','),
      size: 12,
    };
    const products = yield call(productApi.getProductsByIdS, params, headers);
    const basketItems = enrichBasketItems(basket.items, products);
    yield put({
      type: ActionTypes.GET_PRODUCTS_BY_IDS_SUCCESS,
      basketItems: sortBasketItems(basketItems),
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_PRODUCTS_BY_IDS_FAILED,
      message: e.message,
    });
  }
}

export default function* getProductDetailYield() {
  yield takeLatest(ActionTypes.GET_PRODUCT_DETAIL_REQUESTED, getProduct);
  yield takeLatest(ActionTypes.GET_PRODUCTS_BY_IDS_REQUESTED, getProductsByIds);
}
