import styled from 'styled-components';
import {PurchasedIcon} from '../../styles/components/icons';

export const IconWrapper = styled.div`
  position: relative;
  margin-left: 0.7rem;
`;

export const PreviouslyPurchasedIcon = styled(PurchasedIcon)`
    z-index: 2;
    cursor: pointer;
    padding-top: 0.1rem;

    &::before {
        color: ${p =>
          p.$isWhite ? p.theme.primaryTextColor : p.theme.tertiaryColor};
`;
