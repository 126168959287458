import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function activateAccountReducer(
  state = initialState.activateAccount,
  action
) {
  switch (action.type) {
    case types.ACTIVATE_ACCOUNT_SUCCESS:
      return action.messages;
    case types.ACTIVATE_ACCOUNT_FAILED:
      return action.message;
    case types.ACTIVATE_ACCOUNT_REQUESTED:
    case types.ACTIVATE_ACCOUNT_RESET:
      return null;
    default:
      return state;
  }
}
