import initialState from './initial.state';
import * as types from '../actions/types.action';
import {containListId, getQtyById} from '../helpers/array.helper';
import SentryHelper from '../helpers/sentry.helper';

export default function productDetailReducer(
  state = initialState.productDetail,
  action
) {
  switch (action.type) {
    case types.GET_PRODUCT_DETAIL_SUCCESS:
      return action.productDetail;
    case types.ADD_TO_SHOPPING_LIST_SUCCESS:
      if (!state) {
        return null;
      }
      const shoppingLists = [...state.shoppingLists];
      const isUpdating = containListId(shoppingLists, action.shoppingList.id);
      const qty = getQtyById(action.shoppingList, action.itemId);
      if (!qty) {
        SentryHelper.debugQuantityZero({
          message: `productDetail.reducer.js ADD_TO_SHOPPING_LIST_SUCCESS - qty: ${qty} (${typeof qty}), action.itemId: ${
            action.itemId
          }`,
        });
      }
      const updatedList = {
        shoppingListId: action.shoppingList.id,
        quantity: qty,
      };
      if (isUpdating) {
        for (let i = 0; i < shoppingLists.length; i++) {
          if (shoppingLists[i].shoppingListId === action.shoppingList.id) {
            shoppingLists[i].quantity = qty;
            break;
          }
        }
      } else {
        shoppingLists.push(updatedList);
      }
      return Object.assign({}, state, {shoppingLists});
    case types.REMOVE_FROM_SHOPPING_LIST_SUCCESS:
      if (state && !state.shoppingLists) {
        return state;
      }
      const deletedShoppingLists =
        state &&
        state.shoppingLists.filter(
          item => item.shoppingListId !== action.listId
        );
      return Object.assign({}, state, {shoppingLists: deletedShoppingLists});
    case types.GET_PRODUCT_DETAIL_FAILED:
    case types.RESET_PRODUCT_DETAIL_SUCCESS:
      return null;
    default:
      return state;
  }
}
