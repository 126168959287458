import {combineReducers} from 'redux';
// import {filterActions} from 'redux-ignore';
import init from './init.reducer';
import basket from './basket.reducer';
import basketItems from './basketItems.reducer';
import order from './order.reducer';
import orderHistory from './orderHistory.reducer';
import basketHashMap from './basketHashMap.reducer';
import searchResults from './search.reducer';
import searchSuggestions from './searchSuggestions.reducer';
import page from './page.reducer';
import aggression from './aggression.reducer';
import loading from './loading.reducer';
import productDetail from './productDetail.reducer';
import offersToday from './offersToday.reducer';
import offers from './offers.reducer';
import branch from './branch.reducer';
import branchList from './branchList.reducer';
import branchLocator from './branchLocator.reducer';
import route from './route.reducer';
import lookup from './lookup.reducer';
import errors from './errors.reducer';
import profile from './profile.reducer';
import auth from './auth.reducer';
import accounts from './accounts.reducer';
import nextRoute from './nextRoute.reducer';
import checkout from './checkout.reducer';
import recommendedItems from './recommended.reducer';
import slots from './slots.reducer';
import modal from './modal.reducer';
import favourites from './favourites.reducer';
import fulfillmentDetails from './fulfillmentDetails.reducer';
import previouslyPurchased from './previouslypurchased.reducer';
import upsellingItems from './upselling.reducer';
import vacancies from './vacancies.reducer';
import marketing from './marketing.reducer';
import settings from './settings.reducer';
import analytics from './analytics.reducer';
import substitutes from './substitutes.reducer';
import systemMessages from './systemMessages.reducer';
import openingTimes from './openingTimes.reducer';
import brochures from './brochures.reducer';
import regPasswordReset from './regPasswordReset.reducer';
import password from './password.reducer';
import cms from './cms.reducer';
import promoTotal from './promoTotal.reducer';
import activateAccount from './activateAccount.reducer';
import loginToken from './loginToken.reducer';
import refreshToken from './refreshToken.reducer';
import driverInstruction from './driverInstruction.reducer';
import reloginAccount from './reloginAccount.reducer';
import isStaffAccount from './isStaffAccount.reducer';
import cleanMode from './cleanMode.reducer';
import loginRedirect from './loginRedirect.reducer';
import paymentDetails from './paymentDetails.reducer';
import prevRoute from './prevRoute.reducer';
import isMobile from './mobile.reducer';
import shoppingLists from './shoppingLists.reducer';
import shoppingListLookup from './shoppingListLookup.reducer';
import pbbBankDetails from './pbbBankDetails.reducer';
import isUseDefaultPbb from './isUseDefaultPbb.reducer';
import status from './status.reducer';
import blogCategories from './blogCategories.reducer';
import blogPosts from './blogPosts.reducer';
import blogPost from './blogPost.reducer';
import blogPostsSearch from './blogPostsSearch.reducer';
import jjContent from './jjContent.reducer';
import shopBySectors from './shopBySectors.reducer';
import adyen from './adyen.reducer';
import prerender from './prerender.reducer';
import searchCategoryDescription from './searchCategoryDescription.reducer';

const rootReducer = combineReducers({
  accounts,
  activateAccount,
  aggression,
  analytics,
  auth,
  adyen,
  basket,
  basketItems,
  basketHashMap,
  blogCategories,
  blogPost,
  blogPosts,
  blogPostsSearch,
  branch,
  branchList,
  branchLocator,
  brochures,
  checkout,
  cleanMode,
  driverInstruction,
  cms,
  errors,
  favourites,
  fulfillmentDetails,
  init,
  isStaffAccount,
  isUseDefaultPbb,
  jjContent,
  loading,
  lookup,
  loginRedirect,
  loginToken,
  marketing,
  modal,
  nextRoute,
  offers,
  offersToday,
  openingTimes,
  order,
  orderHistory,
  page,
  password,
  paymentDetails,
  pbbBankDetails,
  prerender,
  prevRoute,
  previouslyPurchased,
  productDetail,
  profile,
  promoTotal,
  refreshToken,
  regPasswordReset,
  reloginAccount,
  route,
  recommendedItems,
  searchResults,
  searchSuggestions,
  searchCategoryDescription,
  settings,
  shopBySectors,
  shoppingListLookup,
  shoppingLists,
  slots,
  status,
  substitutes,
  systemMessages,
  vacancies,
  upsellingItems,
  isMobile,
});

export default rootReducer;
