import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Banner,
  Blocks,
  Block,
  Header,
  Content,
  Description,
  Button,
  Clickable,
} from './Banners.style';

export default class Banners extends PureComponent {
  static propTypes = {
    ads: PropTypes.array,
  };
  state = {
    selected: 0,
  };

  componentDidMount() {
    this.setAdsInterval();
  }

  componentWillUnmount() {
    this.unmounted = true;
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }

  next = () => {
    clearInterval(this.interval);
    this.setNextBanner();
  };

  setNextBanner = () => {
    this.setState({
      selected:
        this.state.selected === this.props.ads.length - 1
          ? 0
          : this.state.selected + 1,
    });
  };

  setAdsInterval = () => {
    if (this.unmounted) {
      return;
    }
    this.interval = setInterval(this.setNextBanner, 3000);
  };

  selectAds = selected => {
    this.setState({selected});
    clearInterval(this.interval);
  };

  onBannerClick = () => {
    const {callback, ads} = this.props;
    const {selected} = this.state;
    const {linkUrl, tabTitle} = ads[selected];
    callback(linkUrl, tabTitle, ads[selected].index);
  };

  render() {
    const {ads, isMobile} = this.props;
    const {selected} = this.state;
    const selectedAds = ads[selected];
    if (ads.length === 0) {
      return null;
    }
    const {content1, content2, button} = selectedAds;
    return (
      <Banner $ads={selectedAds} title={selectedAds.alt} $isMobile={isMobile}>
        <span role="img" aria-label={selectedAds.alt} />
        <Clickable onClick={this.onBannerClick}>
          <Content>
            {content1 && <Header>{content1}</Header>}
            {content2 && <Description>{content2}</Description>}
            {button && <Button>{button}</Button>}
          </Content>
        </Clickable>
        <Blocks>
          {ads.map((ad, index) => (
            <Block
              key={index}
              onClick={() => this.selectAds(index)}
              $selected={ad.id === selectedAds.id}
            >
              {ad.tabTitle}
            </Block>
          ))}
        </Blocks>
      </Banner>
    );
  }
}
