import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import FilterSubItem from './FilterItem';
import queryString from 'query-string';
import {
  StyledFilterItem,
  CheckBox,
  Number,
  StyledItem,
  ItemLabel,
} from './Filter.style';

export default class FilterItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    isRoot: PropTypes.bool,
    parentIndex: PropTypes.string,
  };

  state = {
    checked: !!this.props.isChecked,
  };

  componentDidUpdate(prevProps, preState) {
    const {checked} = preState;
    if (checked !== this.props.isChecked) {
      this.setState({checked: this.props.isChecked});
    }
  }

  onItemChange = () => {
    const {
      item,
      onCheckboxChange,
      parentQuery,
      isCategorySearch,
      isRoot,
    } = this.props;
    // only apply `parentQuery` on the category search result
    const unQuery =
      isCategorySearch && !isRoot ? parentQuery || item.unquery : item.unquery;
    const query = this.state.checked ? unQuery : item.query;
    const updatedParams = queryString.parse(query);
    onCheckboxChange(updatedParams);
    this.setState({checked: !this.state.checked});
  };

  render() {
    const {
      item,
      onCheckboxChange,
      isRoot,
      parentIndex,
      isCategorySearch,
      onlyTopLevel,
    } = this.props;
    const renderSubItem =
      !onlyTopLevel &&
      item.counts &&
      item.counts.length > 0 &&
      item.counts.map(subItem => (
        <FilterSubItem
          key={subItem.query}
          item={subItem}
          onCheckboxChange={onCheckboxChange}
          isChecked={subItem.selected}
          parentIndex={parentIndex + item.id}
          parentQuery={item.query}
          isCategorySearch={isCategorySearch}
        />
      ));
    const renderQuantity = ((item.counts && item.counts.length === 0) ||
      (isRoot && !item.counts)) && <Number>({item.qty})</Number>;
    const id = parentIndex + item.id;
    return (
      <StyledItem>
        <StyledFilterItem key={id}>
          <CheckBox
            type="checkbox"
            value={item.name}
            onChange={this.onItemChange}
            checked={this.state.checked}
            id={id}
          />
          <ItemLabel htmlFor={id}>
            {item.name || 'None'}
            {renderQuantity}
          </ItemLabel>
        </StyledFilterItem>
        {renderSubItem}
      </StyledItem>
    );
  }
}
