import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function aggressionReducer(
  state = initialState.aggression,
  action
) {
  switch (action.type) {
    case types.GET_OFFERS_AGGREGATE_SUCCESS:
    case types.GET_SEARCH_AGGREGATE_SUCCESS:
    case types.GET_MORE_SEARCH_AGGREGATE_SUCCESS:
      return action.aggression;
    default:
      return state;
  }
}
