import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {WayLeft, WayRight} from '../../styles/components/arrow';

export const Link = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0 0.6rem;
  cursor: ${p => (p.$isLink ? 'pointer' : 'auto')};
  color: ${p => (p.$active ? p.theme.primaryColor : p.theme.textColor)};
`;
