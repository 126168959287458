import React, {PureComponent} from 'react';
import {IconWrapper, PopularImage} from './ToolTipsImage.style';
import {ToolTips} from '../../styles/components/toolTips';
import {MESSAGE_TIMEOUT} from '../../constants/constants';

export default class ToolTipsImage extends PureComponent {
  state = {
    showToolTip: false,
  };

  componentWillUnmount() {
    this.closeToolTip();
  }

  onToolTipClick = () => {
    this.setState({showToolTip: true});
    this.timeout = setTimeout(
      () => this.setState({showToolTip: false}),
      MESSAGE_TIMEOUT
    );
  };
  openToolTip = () => {
    if (this.state.showTooTip) {
      return;
    }
    this.setState({showToolTip: true});
  };
  closeToolTip = () => this.setState({showToolTip: false});

  render() {
    const {url, toolTipsContent} = this.props;
    return (
      <IconWrapper
        onMouseOver={this.openToolTip}
        onMouseLeave={this.closeToolTip}
      >
        <ToolTips $show={this.state.showToolTip}>{toolTipsContent}</ToolTips>
        <PopularImage
          src={url}
          onClick={this.onToolTipClick}
          alt={toolTipsContent}
        />
      </IconWrapper>
    );
  }
}
