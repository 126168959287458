import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getVacancies} from '../../actions/vacancies.action';
import {
  getCmsDepartments,
  getVacancies as getCmsVacancies,
} from '../../actions/cms.action';
import {Wrapper, Title, Head, MarkdownWrapper} from '../../styles/pages/pages';
import {
  VacanciesPageContent,
  VacanciesLocations,
  NoJobs,
  SectionWrapper,
  Selector,
  Option,
  Error,
} from './VacanciesPage.style';
import {VacanciesBlock} from './VacanciesBlock';
import {getVacanciesData} from '../../helpers/vacancies.helper';
import {Location} from './Location';
import Loading from '../../components/Loading/Loading';
import Markdown from 'markdown-to-jsx';
import {LOCATION_FILTERS} from '../../constants/constants';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class Vacancies extends PureComponent {
  state = {
    locationFilter: LOCATION_FILTERS.ALL,
    vacanciesData: null,
  };

  componentDidMount() {
    const {getVacancies, getCmsVacancies, getCmsDepartments} = this.props;
    getVacancies();
    getCmsDepartments();
    getCmsVacancies();
  }

  componentDidUpdate(prevProps) {
    const {vacancies, cms} = this.props;
    if (
      prevProps.vacancies &&
      vacancies &&
      prevProps.vacancies !== vacancies &&
      cms &&
      cms.departments
    ) {
      this.setState({
        vacanciesData: getVacanciesData(vacancies, cms.departments),
      });
    }
    if (
      cms &&
      cms.vacancies &&
      (prevProps.cms && prevProps.cms.vacancies) !== cms.vacancies
    ) {
      setCmsHeadTags(cms.vacancies);
    }
  }

  setLocationFilter = locationFilter => {
    this.setState({
      locationFilter:
        (locationFilter.target && locationFilter.target.value) ||
        locationFilter,
    });
  };

  render() {
    const {
      deviceInfo,
      getVacanciesFailed,
      getDepartmentsFailed,
      gettingVacancies,
      cms,
      loading,
      errors,
    } = this.props;

    const vacancies = cms && cms.vacancies;
    const renderMarkdown = vacancies && vacancies.content && (
      <Markdown>{markdownConvert(vacancies.content)}</Markdown>
    );
    const renderLoading = loading &&
      (loading.vacancies || loading.departments) && <Loading isLight={false} />;
    const renderContentError = errors && errors.vacancies && (
      <Error>Failed to load content, please try again later.</Error>
    );

    const {vacanciesData} = this.state;

    const renderContent =
      vacanciesData &&
      vacanciesData.categories &&
      Object.keys(vacanciesData.categories).map((key, index) => {
        const category = vacanciesData.categories[key];
        const jobs = category.jobs.filter(
          item =>
            this.state.locationFilter === 'All' ||
            this.state.locationFilter === item['CM:Location'].toLowerCase()
        );
        return (
          jobs &&
          jobs.length > 0 && (
            <VacanciesBlock
              key={category.title + index}
              index={index}
              title={category.title}
              items={jobs}
              imageUrl={category.imageUrl}
              videoUrl={category.videoUrl}
            />
          )
        );
      });

    const renderLocationFilter =
      vacanciesData &&
      vacanciesData.locations &&
      vacanciesData.locations.length > 2 &&
      (deviceInfo && deviceInfo.isPhone ? (
        <Selector
          value={this.state.locationFilter}
          onChange={this.setLocationFilter}
        >
          {vacanciesData.locations.map((location, index) => (
            <Option key={index} value={location}>
              {location}
            </Option>
          ))}
        </Selector>
      ) : (
        vacanciesData.locations.map((location, index) => (
          <Location
            location={location}
            isActive={this.state.locationFilter === location}
            setLocationFilter={this.setLocationFilter}
            key={index}
          />
        ))
      ));

    const renderNoJobs = loading &&
      !loading.vacancies &&
      vacanciesData &&
      vacanciesData.length === 0 &&
      !getVacanciesFailed && <NoJobs>No open positions available</NoJobs>;

    const renderError = loading &&
      !(loading.vacancies || loading.departments) &&
      (getVacanciesFailed || getDepartmentsFailed) && (
        <Error>Failed to get vacancies, please try again later</Error>
      );

    return (
      <Wrapper>
        <Head>
          <Title>{vacancies && vacancies.heading}</Title>
        </Head>
        <SectionWrapper>
          <MarkdownWrapper>{renderMarkdown}</MarkdownWrapper>
          <VacanciesLocations>{renderLocationFilter}</VacanciesLocations>
          <VacanciesPageContent>
            {renderLoading}
            {renderError}
            {renderContentError}
            {gettingVacancies ? (
              <Loading isLight={false} />
            ) : vacanciesData &&
              vacanciesData.categories &&
              Object.keys(vacanciesData.categories).length > 0 ? (
              renderContent
            ) : (
              renderNoJobs
            )}
          </VacanciesPageContent>
        </SectionWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  vacancies: state.vacancies,
  departments: state.departments,
  loading: state.loading,
  getVacanciesFailed: state.errors && state.errors.getVacanciesFailed,
  getDepartmentsFailed: state.errors && state.errors.departments,
  gettingVacancies: state.loading && state.loading.gettingVacancies,
});

const mapDispatchToProps = dispatch => ({
  getVacancies: bindActionCreators(getVacancies, dispatch),
  getCmsVacancies: bindActionCreators(getCmsVacancies, dispatch),
  getCmsDepartments: bindActionCreators(getCmsDepartments, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vacancies);
