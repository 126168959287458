import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

const strapiUrl = '/api/olympus/strapi';
export default class jjContentApi {
  static getContentBySlug(contentName, hostDomain) {
    const config = getApiConfig();
    return axios
      .get(
        `${hostDomain || ''}${config.strapiUrl ||
          strapiUrl}/jjContentBySlug/${contentName}`
      )
      .then(res => res.data);
  }
}
