import React, {PureComponent} from 'react';
import Markdown from 'markdown-to-jsx';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import {Error} from './AboutUsPage.style';
import ImageComponent from '../../components/Image/Image';
import Loading from '../../components/Loading/Loading';
import {getAboutUs} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';

class AboutUsPage extends PureComponent {
  componentDidMount() {
    const {getAboutUs} = this.props;
    getAboutUs();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.aboutus &&
      (prevProps.cms && prevProps.cms.aboutus) !== cms.aboutus
    ) {
      setCmsHeadTags(cms.aboutus);
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const aboutUs = cms && cms.aboutus;
    const renderMarkdown = aboutUs && aboutUs.content && (
      <div className="columns-1">
        <Markdown>{aboutUs.content}</Markdown>
      </div>
    );
    const renderMedia = aboutUs && aboutUs.media && (
      <ImageComponent url={aboutUs.media} alt={aboutUs.heading} />
    );
    const renderLoading = loading && loading.aboutus && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.aboutus && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <div className="tac">
          <h1 className="tac">{aboutUs && aboutUs.heading}</h1>
          <p className="tac w70p">{aboutUs && aboutUs.pageTitle}</p>
          {renderLoading}
          {renderError}
          <SectionWrapper>{renderMarkdown}</SectionWrapper>
          {renderMedia}
        </div>
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getAboutUs: bindActionCreators(getAboutUs, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AboutUsPage)
);
