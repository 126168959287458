import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Wrapper, Title, LinkButton} from '../../styles/pages/pages';
import {
  Head,
  AccountContent,
  Info,
  HeaderSection,
  HeaderTitle,
  AccountNo,
  AccountRow,
  ChangePassword,
  Name,
  Block,
  Row,
  AccountTitle,
  AccountDetails,
  Edit,
  SwitchAccount,
  AccountText,
  AccountInput,
  AccountTextField,
  ButtonWrapper,
  TheSaveButton,
  SelectorWrapper,
  ContentSection,
  BlockContent,
  AccountTextFieldWrapper,
  ButtonLoadingWrapper,
  ButtonLink,
} from './AccountPage.style';
import {getCancelOrEdit} from '../../helpers/string.helper';
import {ExtraWrapper} from '../../styles/components/wrapper';
import {getMarketingOptions} from '../../actions/marketing.action';
import {toggleSwitchAccount} from '../../actions/modal.action';
import MarketingOptions from '../../components/Marketing/MarketingOptions';
import {
  removeSetting,
  setSetting,
  updateAllSettings,
} from '../../actions/settings.action';
import {
  SERVICE_TYPE,
  SETTINGS,
  PAYMENT_TYPES,
  DEFAULT_PAYMENT_TYPE,
  MAX_DRIVER_INSTRUCTION_LENGTH,
  ROUTES,
  JJ_DOMAIN,
} from '../../constants/constants';
import AllBranchList from '../../components/AllBranchList/AllBranchList';
import {DropdownStandard} from '../../styles/components/dropdown';
import {setBranch} from '../../actions/branch.action';
import {MaxChars} from '../../styles/components/input';
import {setCurrentRoute} from '../../actions';
import {getAddressLine} from '../../helpers/data.helper';
import {getResetPasswordLink} from '../../helpers/azure.helper';
import {refreshAxAccount} from '../../actions/accounts.action';
import Loading from '../../components/Loading/Loading';
import {TheButton} from '../BasketPage/BasketPage.style';
import {getApiConfig} from '../../config/configProvider';

class AccountPage extends PureComponent {
  constructor(props) {
    super(props);
    const {branch, settings, profile} = props;
    const hasAddress = !!(profile && profile.address);
    this.state = {
      branchId:
        (settings && settings.branchId) ||
        (profile && profile.branchId) ||
        branch,
      paymentType: PAYMENT_TYPES.CDC.mode,
      editingEmail: false,
      editingPhone: false,
      editingInstructions: false,
      editingContactNumber: false,
      showSaveDetailButton: false,
      email: (hasAddress && profile.address.email) || null,
      phoneNumber: (hasAddress && profile.address.phoneNumber) || null,
      addressLine: (hasAddress && getAddressLine(profile.address)) || null,
      fulfillment:
        (settings &&
          settings.fulfillment &&
          settings.fulfillment.toUpperCase()) ||
        SERVICE_TYPE.UNSELECTED,
      instructions:
        (settings && settings.instructions) ||
        (hasAddress && profile.address.instructions) ||
        '',
      deliveryContactNumber: (settings && settings.deliveryContactNumber) || '',
      hasCard: false,
      showChangePasswordModal: false,
      orderDetailsFinderUrl: '',
    };
  }

  componentDidUpdate(prevProp) {
    const {settings, profile, auth} = this.props;
    if (settings) {
      if (settings.branchId && settings.branchId !== this.state.branchId) {
        this.setState({branchId: settings.branchId});
      }
      if (
        settings.paymentType &&
        settings.paymentType !== this.state.paymentType
      ) {
        const updatedPaymentType =
          settings.adyen === 'true' &&
          settings.paymentType === PAYMENT_TYPES.BCDC.mode
            ? PAYMENT_TYPES.CDC.mode
            : settings.paymentType;
        this.setState({paymentType: updatedPaymentType});
      }
      if (
        settings.fulfillment &&
        settings.fulfillment.toUpperCase() &&
        ((prevProp.settings &&
          settings.fulfillment !== prevProp.settings.fulfillment) ||
          !prevProp.settings)
      ) {
        this.setState({fulfillment: settings.fulfillment.toUpperCase()});
      }
    }
    if (profile && profile.address && profile !== prevProp.profile) {
      const {email, phoneNumber, instructions} = profile.address;
      const addressLine = profile.address
        ? getAddressLine(profile.address)
        : '';
      this.setState({
        email,
        phoneNumber,
        addressLine,
        instructions,
      });
    }
    if (auth && auth !== prevProp.auth) {
      this.setOrderUrl();
    }
  }

  componentDidMount() {
    const {setCurrentRoute} = this.props;
    setCurrentRoute(ROUTES.SETTINGS);
    if (!this.state.orderDetailsFinderUrl) {
      this.setOrderUrl();
    }
  }

  setOrderUrl = () => {
    const {auth} = this.props;
    if (!auth) {
      return;
    }
    const config = getApiConfig();
    const isProd =
      !!window && window.location && window.location.host === JJ_DOMAIN.PROD;
    const url =
      config.orderDetailsFinder +
      `?access_token=${auth.access_token}&prod=${isProd}`;
    this.setState({orderDetailsFinderUrl: url});
  };
  checkEditing = () =>
    !!(
      this.state.editingEmail ||
      this.state.editingPhone ||
      this.state.editingInstructions ||
      this.state.editingContactNumber
    );

  editEmail = () => {
    let showSaveDetailButton = !this.state.showSaveDetailButton;
    if (this.checkEditing()) {
      showSaveDetailButton = true;
    }
    this.setState({
      editingEmail: !this.state.editingEmail,
      showSaveDetailButton,
    });
  };

  editPhone = () => {
    let showSaveDetailButton = !this.state.showSaveDetailButton;
    if (this.checkEditing()) {
      showSaveDetailButton = true;
    }
    this.setState({
      editingPhone: !this.state.editingPhone,
      showSaveDetailButton,
    });
  };

  editInstructions = () => {
    let showSaveDetailButton = !this.state.showSaveDetailButton;
    if (this.checkEditing()) {
      showSaveDetailButton = true;
    }
    this.setState({
      editingInstructions: !this.state.editingInstructions,
      showSaveDetailButton,
      instructions:
        (this.props.settings && this.props.settings.instructions) || '',
    });
  };

  editContactNumber = () => {
    let showSaveDetailButton = !this.state.showSaveDetailButton;
    if (this.checkEditing()) {
      showSaveDetailButton = true;
    }
    this.setState({
      editingContactNumber: !this.state.editingContactNumber,
      showSaveDetailButton,
      deliveryContactNumber:
        (this.props.settings &&
          (this.props.settings.deliveryContactNumber ||
            this.props.settings.deliveryNumber)) ||
        '',
    });
  };

  updatingEmailField = e => this.setState({email: e.target.value});
  updatingPhoneField = e => this.setState({phoneNumber: e.target.value});
  updatingInstructionsField = e =>
    this.setState({instructions: e.target.value});
  updatingContactNumberField = e =>
    this.setState({deliveryContactNumber: e.target.value});
  toggleSwitchAccount = () => this.props.toggleSwitchAccountModal(true);

  updateSettings = () => {
    const {instructions, deliveryContactNumber} = this.state;
    const {updateAllSettings} = this.props;

    updateAllSettings({
      [SETTINGS.INSTRUCTIONS]: instructions,
      [SETTINGS.DELIVERY_CONTACT_NUMBER]: deliveryContactNumber,
    });
    this.setState({
      showSaveDetailButton: false,
      editingInstructions: false,
      editingContactNumber: false,
    });
  };
  changeBranch = e => {
    const {setBranch, setSetting} = this.props;
    const branchId = e.target.value;
    this.setState({branchId});
    setBranch(branchId); //todo test whole flow after BE is ready
    setSetting(SETTINGS.BRANCH, branchId);
  };

  goToChangePassword = () => (window.location.href = getResetPasswordLink());

  changePaymentType = e => {
    const paymentType = e.target.value;
    this.setState({paymentType});
    this.props.setSetting(SETTINGS.PAYMENT_TYPE, paymentType);
  };

  changeFulfillment = e => {
    const {setSetting, removeSetting} = this.props;
    const fulfillment = e.target.value;
    this.setState({fulfillment});
    if (fulfillment === SERVICE_TYPE.UNSELECTED) {
      removeSetting(SETTINGS.FULFILLMENT);
    } else {
      setSetting(SETTINGS.FULFILLMENT, fulfillment);
    }
  };

  refreshAxAcc = () => {
    const {refreshAxAccount, profile} = this.props;
    if (profile && profile.id) {
      refreshAxAccount(profile.id);
    }
  };

  setBraintreeSettings = e =>
    this.props.setSetting(SETTINGS.BRAINTREE, e.target.value);

  setAdyenPay = e => this.props.setSetting(SETTINGS.ADYEN, e.target.value);

  setApplePay = e => this.props.setSetting(SETTINGS.APPLE_PAY, e.target.value);
  setGooglePay = e =>
    this.props.setSetting(SETTINGS.GOOGLE_PAY, e.target.value);
  setPalPal = e => this.props.setSetting(SETTINGS.PAYPAL, e.target.value);

  render() {
    const {
      profile,
      accounts,
      auth,
      settings,
      branchList,
      isStaffAccount,
      loading,
    } = this.props;
    const {
      editingEmail,
      editingPhone,
      editingInstructions,
      editingContactNumber,
      email,
      phoneNumber,
      addressLine,
      instructions,
      deliveryContactNumber,
      fulfillment,
      showSaveDetailButton,
      branchId,
      paymentType,
      hasCard,
      orderDetailsFinderUrl,
    } = this.state;
    const renderEmail = editingEmail ? (
      <AccountInput
        id="email"
        type="text"
        placeholder="Edit email"
        value={email}
        onChange={this.updatingEmailField}
      />
    ) : (
      <AccountText>{profile && profile.email}</AccountText>
    );

    const renderPhone = editingPhone ? (
      <AccountInput
        id="phoneNumber"
        type="text"
        placeholder="Edit phone number"
        value={phoneNumber}
        onChange={this.updatingPhoneField}
      />
    ) : (
      <AccountText>{profile && profile.phoneNumber}</AccountText>
    );
    const renderInstructions = editingInstructions ? (
      <AccountTextFieldWrapper>
        <AccountTextField
          id="deliveryInstructions"
          type="text"
          placeholder="Edit delivery instructions"
          value={instructions}
          onChange={this.updatingInstructionsField}
          maxLength={MAX_DRIVER_INSTRUCTION_LENGTH}
        />
        <MaxChars>
          {instructions.length}/{MAX_DRIVER_INSTRUCTION_LENGTH}
        </MaxChars>
      </AccountTextFieldWrapper>
    ) : (
      <AccountText>{settings && settings.instructions}</AccountText>
    );

    const renderContactNumber = editingContactNumber ? (
      <AccountTextFieldWrapper>
        <AccountTextField
          id="deliveryContactNumber"
          type="text"
          placeholder="Edit delivery contact number"
          value={deliveryContactNumber}
          onChange={this.updatingContactNumberField}
        />
      </AccountTextFieldWrapper>
    ) : (
      <AccountText>
        {settings && settings[SETTINGS.DELIVERY_CONTACT_NUMBER]}
      </AccountText>
    );

    const renderEditingInstruStatus = getCancelOrEdit(editingInstructions);
    const renderEditingContactNumber = getCancelOrEdit(editingContactNumber);

    const renderDetailsSaveButton = showSaveDetailButton && (
      <ButtonWrapper>
        <TheSaveButton onClick={this.updateSettings}>Save</TheSaveButton>
      </ButtonWrapper>
    );
    const renderSwitchAccount = accounts && accounts.length > 1 && (
      <SwitchAccount onClick={this.toggleSwitchAccount}>
        Switch Account
      </SwitchAccount>
    );
    const branchListArray = branchList ? Object.entries(branchList) : null;
    const renderBranches = branchListArray &&
      branchListArray.length > 0 &&
      branchList[branchId] &&
      branchList[branchId].code && (
        <AllBranchList
          selectorValue={branchId}
          branchList={branchListArray}
          callback={this.changeBranch}
        />
      );
    const paymentOptions =
      profile && profile.paymentOptions && profile.paymentOptions.length > 0
        ? profile.paymentOptions
        : DEFAULT_PAYMENT_TYPE;
    const renderPaymentTypes =
      profile &&
      paymentOptions.map(type => {
        if (PAYMENT_TYPES[type.mode]) {
          if (
            settings &&
            settings.adyen === 'true' &&
            type.mode === PAYMENT_TYPES.BCDC.mode
          ) {
            return;
          }
          return (
            <option value={type.mode} key={type.mode}>
              {PAYMENT_TYPES[type.mode].title}
            </option>
          );
        }
      });
    const renderRefreshAxText =
      loading && loading.refreshAx ? (
        <ButtonLoadingWrapper>
          <Loading isLight={false} />
        </ButtonLoadingWrapper>
      ) : (
        'Refresh ax account'
      );
    const renderRefreshAxButton = !!isStaffAccount && (
      <TheButton extraMargin fullWidth onClick={this.refreshAxAcc}>
        {renderRefreshAxText}
      </TheButton>
    );
    const renderOrderDetailFinderButton = !!isStaffAccount && (
      <ButtonLink href={orderDetailsFinderUrl} target="_blank">
        Order Details Finder
      </ButtonLink>
    );
    const renderStoredCards = hasCard && (
      <Block>
        <Row>
          <AccountTitle>Stored cards</AccountTitle>
          <AccountDetails>
            <AccountText>Mastercard ending 1234 expires 6/22</AccountText>
            <Edit>edit</Edit>
          </AccountDetails>
        </Row>
        <Row>
          <AccountTitle />
          <AccountDetails>
            <AccountText>Visa ending 2234 expires 9/20</AccountText>
            <Edit>edit</Edit>
          </AccountDetails>
        </Row>
      </Block>
    );
    const renderOptions = (
      <Fragment>
        <option value={'true'}>Enabled</option>
        <option value={'false'}>Disabled</option>
      </Fragment>
    );
    const renderExtraFeatures = (isStaffAccount ||
      (settings && settings.debugger)) && (
      <Block>
        <Row>
          <AccountTitle>Braintree payment</AccountTitle>
          <AccountDetails>
            <SelectorWrapper>
              <DropdownStandard
                value={
                  !settings || !settings.braintree ? 'true' : settings.braintree
                }
                onChange={this.setBraintreeSettings}
              >
                {renderOptions}
              </DropdownStandard>
            </SelectorWrapper>
          </AccountDetails>
        </Row>
        <Row>
          <AccountTitle>Adyen Pay</AccountTitle>
          <AccountDetails>
            <SelectorWrapper>
              <DropdownStandard
                value={!settings || settings.adyen === 'true'}
                onChange={this.setAdyenPay}
              >
                {renderOptions}
              </DropdownStandard>
            </SelectorWrapper>
          </AccountDetails>
        </Row>
        <Row>
          <AccountTitle>Apple Pay</AccountTitle>
          <AccountDetails>
            <SelectorWrapper>
              <DropdownStandard
                value={!settings || settings.allowApplePay === 'true'}
                onChange={this.setApplePay}
              >
                {renderOptions}
              </DropdownStandard>
            </SelectorWrapper>
          </AccountDetails>
        </Row>
        <Row>
          <AccountTitle>Google Pay</AccountTitle>
          <AccountDetails>
            <SelectorWrapper>
              <DropdownStandard
                value={!settings || settings.allowGooglePay === 'true'}
                onChange={this.setGooglePay}
              >
                {renderOptions}
              </DropdownStandard>
            </SelectorWrapper>
          </AccountDetails>
        </Row>
        <Row>
          <AccountTitle>Paypal</AccountTitle>
          <AccountDetails>
            <SelectorWrapper>
              <DropdownStandard
                value={!settings || settings.allowPaypal === 'true'}
                onChange={this.setPalPal}
              >
                {renderOptions}
              </DropdownStandard>
            </SelectorWrapper>
          </AccountDetails>
        </Row>
      </Block>
    );
    const renderMarketingOptions = auth && auth.email && (
      <Block>
        <MarketingOptions email={auth.email} />
      </Block>
    );
    const renderMyLoginDetails = auth && (
      <HeaderSection>
        <AccountRow>
          <HeaderTitle>My Login: </HeaderTitle>
          <AccountNo>{auth && auth.email}</AccountNo>
        </AccountRow>
        <ChangePassword onClick={this.goToChangePassword}>
          Reset Password
        </ChangePassword>
      </HeaderSection>
    );
    return (
      <Wrapper>
        <Head>
          <Title>Account Settings</Title>
          {renderMyLoginDetails}
        </Head>
        <ContentSection>
          <BlockContent>
            <Row>
              <AccountTitle>Trading Account</AccountTitle>
              <AccountDetails>
                <Info>
                  <span>{profile && profile.id}</span>
                  <Name>{profile && profile.name}</Name>
                  {renderSwitchAccount}
                </Info>
              </AccountDetails>
            </Row>
          </BlockContent>
        </ContentSection>
        <AccountContent>
          <Block>
            <Row>
              <AccountTitle>Business Name</AccountTitle>
              <AccountDetails>
                <AccountText>{profile && profile.businessName}</AccountText>
              </AccountDetails>
              {renderRefreshAxButton}
              {renderOrderDetailFinderButton}
            </Row>
            <Row>
              <AccountTitle>Email</AccountTitle>
              <AccountDetails>{renderEmail}</AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Phone</AccountTitle>
              <AccountDetails>{renderPhone}</AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Default Service</AccountTitle>
              <AccountDetails>
                <SelectorWrapper>
                  <DropdownStandard
                    value={fulfillment}
                    onChange={this.changeFulfillment}
                  >
                    <option value={SERVICE_TYPE.DELIVERY}>
                      {SERVICE_TYPE.DELIVERY}
                    </option>
                    <option value={SERVICE_TYPE.COLLECTION}>
                      {SERVICE_TYPE.COLLECTION}
                    </option>
                    <option value={SERVICE_TYPE.UNSELECTED}>
                      {SERVICE_TYPE.UNSELECTED}
                    </option>
                  </DropdownStandard>
                </SelectorWrapper>
              </AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Default Payment</AccountTitle>
              <AccountDetails>
                <SelectorWrapper>
                  <DropdownStandard
                    onChange={this.changePaymentType}
                    value={paymentType}
                  >
                    {renderPaymentTypes}
                  </DropdownStandard>
                </SelectorWrapper>
              </AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Collection Branch</AccountTitle>
              <AccountDetails>
                <SelectorWrapper>{renderBranches}</SelectorWrapper>
              </AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Delivery Address</AccountTitle>
              <AccountDetails>
                <AccountText>{addressLine}</AccountText>
              </AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Delivery Contact Number</AccountTitle>
              <AccountDetails>
                {renderContactNumber}
                <Edit onClick={this.editContactNumber}>
                  {renderEditingContactNumber}
                </Edit>
              </AccountDetails>
            </Row>
            <Row>
              <AccountTitle>Delivery Instructions</AccountTitle>
              <AccountDetails>
                {renderInstructions}
                <Edit onClick={this.editInstructions}>
                  {renderEditingInstruStatus}
                </Edit>
              </AccountDetails>
            </Row>
            {renderDetailsSaveButton}
          </Block>
          {renderStoredCards}
          {renderMarketingOptions}
          {renderExtraFeatures}
        </AccountContent>
        <ExtraWrapper>
          <LinkButton to="/">Continue shopping</LinkButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  branch: state.branch,
  branchList: state.branchList,
  basket: state.basket,
  profile: state.profile,
  marketing: state.marketing,
  accounts: state.accounts,
  settings: state.settings,
  loading: state.loading,
  isStaffAccount: state.isStaffAccount,
});

const mapDispatchToProps = dispatch => ({
  getMarketingOptions: bindActionCreators(getMarketingOptions, dispatch),
  toggleSwitchAccountModal: bindActionCreators(toggleSwitchAccount, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch),
  updateAllSettings: bindActionCreators(updateAllSettings, dispatch),
  removeSetting: bindActionCreators(removeSetting, dispatch),
  setBranch: bindActionCreators(setBranch, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  refreshAxAccount: bindActionCreators(refreshAxAccount, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
