import {put, call, takeLatest, select} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import productApi from '../api/product.api';
import {getBearerHeader} from '../helpers/api.helper';
import {DEFAULT_BRANCH} from '../constants/constants';
import {processedGQLSearchResults} from '../helpers/data.helper';

function* addFavourite(action) {
  const getAuth = state => state.auth;
  const storeToken = yield select(getAuth);
  const headers = getBearerHeader(action.jjToken, storeToken);

  try {
    const favourite = yield call(
      productApi.addFavourite,
      action.product,
      headers
    );
    yield put({type: ActionTypes.ADD_TO_FAVOURITES_SUCCESS, favourite});
  } catch (e) {
    yield put({type: ActionTypes.ADD_TO_FAVOURITES_FAILED, message: e.message});
  }
}

function* removeFavourite(action) {
  const getAuth = state => state.auth;
  const storeToken = yield select(getAuth);
  const headers = getBearerHeader(action.jjToken, storeToken);

  try {
    const favourite = yield call(
      productApi.removeFavourite,
      action.product,
      headers
    );
    yield put({type: ActionTypes.REMOVE_FROM_FAVOURITES_SUCCESS, favourite});
  } catch (e) {
    yield put({
      type: ActionTypes.REMOVE_FROM_FAVOURITES_FAILED,
      message: e.message,
    });
  }
}

function* getFavourites(action) {
  const getAuth = state => state.auth;
  const getBranch = state => state.branch;
  const storeToken = yield select(getAuth);
  const branchId = yield select(getBranch);
  const branch = branchId || DEFAULT_BRANCH;
  const headers = getBearerHeader(action.jjToken, storeToken);
  let params = Object.assign({}, action.params, {branch});
  if (action.page) {
    params = Object.assign({}, params, {page: action.page});
  }

  try {
    const favourites = yield call(productApi.getFavourites, params, headers);
    if (!favourites || favourites.errors) {
      yield put({
        type: ActionTypes.GET_OFFERS_TODAY_FAILED,
        message: favourites.errors[0],
      });
      return;
    }
    yield put({
      type: ActionTypes.GET_FAVOURITES_SUCCESS,
      favourites: processedGQLSearchResults(
        favourites &&
          favourites.data &&
          favourites.data.favouriteProducts &&
          favourites.data.favouriteProducts.results
      ),
      page:
        favourites &&
        favourites.data &&
        favourites.data.favouriteProducts &&
        favourites.data.favouriteProducts.page,
    });
  } catch (e) {
    yield put({type: ActionTypes.GET_FAVOURITES_FAILED, message: e.message});
  }
}
export default function* favouriteYield() {
  yield takeLatest(ActionTypes.ADD_TO_FAVOURITES_REQUESTED, addFavourite);
  yield takeLatest(
    ActionTypes.REMOVE_FROM_FAVOURITES_REQUESTED,
    removeFavourite
  );
  yield takeLatest(ActionTypes.GET_FAVOURITES_REQUESTED, getFavourites);
}
