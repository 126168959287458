import {put, call, takeLatest, select} from 'redux-saga/effects';
import upsellingApi from '../api/upselling.api';
import * as ActionTypes from '../actions/types.action';
import {PAGE_SIZE} from '../constants/constants';
import {getBearerHeader} from '../helpers/api.helper';
import {getValidUSDate} from '../helpers/data.helper';

function* upsellingItems(action) {
  try {
    const getBranch = state => state.branch;
    const branch = yield select(getBranch);
    const getBasket = state => state.basket;
    const basket = yield select(getBasket);
    const getAuth = state => state.auth;
    const auth = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, auth);
    const itemIds = action.itemIds;
    const deliveryDate = getValidUSDate(
      basket && basket.fulfillAtSlot && basket.fulfillAtSlot.day
    );
    const params = {
      branch,
      itemIds,
      filter: action.filter,
      account: auth && auth.c_account,
      deliveryDate,
      size: parseInt(PAGE_SIZE.S, 10),
    };
    const upsellingItems = yield call(
      upsellingApi.getUpsellingItems,
      params,
      headers
    );
    if (
      upsellingItems &&
      upsellingItems.errors &&
      upsellingItems.errors.length > 0
    ) {
      yield put({
        type: ActionTypes.GET_UPSELLING_ITEMS_FAILED,
        message: upsellingItems.errors[0],
      });
    } else {
      yield put({
        type: ActionTypes.GET_UPSELLING_ITEMS_SUCCESS,
        upsellingItems: upsellingItems.data,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_UPSELLING_ITEMS_FAILED,
      message: e.message,
    });
  }
}

export default function* upsellingYield() {
  yield takeLatest(ActionTypes.GET_UPSELLING_ITEMS_REQUESTED, upsellingItems);
}
