import {put, call, take, fork, join, select} from 'redux-saga/effects';
import searchApi from '../api/search.api';
import * as ActionTypes from '../actions/types.action';
import {getUserSearchSuggestions} from '../helpers/data.helper';

export default function* searchCategoryDescription() {
  while (true) {
    const action = yield take(
      ActionTypes.GET_SEARCH_CATEGORY_DESCRIPTION_REQUESTED
    );
    try {
      if (action.category) {
        const resultsFork = yield fork(
          searchApi.searchCategoryDescription,
          action.category
        );
        const results = yield join(resultsFork);
        yield put({
          type: ActionTypes.GET_SEARCH_CATEGORY_DESCRIPTION_SUCCESS,
          description: results.Description,
        });
      }
      if (action.brand) {
        const resultsFork = yield fork(
          searchApi.searchOwnBrandsDescription,
          action.brand
        );
        const results = yield join(resultsFork);
        yield put({
          type: ActionTypes.GET_SEARCH_CATEGORY_DESCRIPTION_SUCCESS,
          description: results.Description,
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.GET_SEARCH_CATEGORY_DESCRIPTION_FAILED,
        message: e.message,
      });
    }
  }
}
