import {StaticRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';

export const getHistory = location => {
  if (__SERVER__) {
    const staticRouter = new StaticRouter();
    staticRouter.props = {location, context: {}, basename: ''};
    return staticRouter.render().props.history;
  }
  return createBrowserHistory();
};
