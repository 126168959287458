import {captureException} from '@sentry/browser';
import config from './config.json';
let serverConfig;

export const getApiConfig = () => {
  // only return default config for the local development
  if (!serverConfig && !!module.hot) {
    return config;
  }
  if (typeof window !== 'undefined') {
    return window.serverConfig;
  }
  if (typeof serverConfig === 'string') {
    return JSON.parse(serverConfig);
  }
  return serverConfig;
};

export const parseConfig = envConfig => {
  if (!envConfig && !!module.hot) {
    return config;
  }
  if (typeof envConfig === 'object') {
    return envConfig;
  }
  return JSON.parse(envConfig.replace(/\\"/g, '"'));
};

export const setServerConfig = (config, fromServerSide, release) => {
  if (!config || !Object.keys(config).length) {
    captureException(
      'could not set CONFIG_JS from server',
      config && JSON.stringify(config)
    );
    return;
  }
  const releaseVersion = release && release.releaseNumber;
  try {
    serverConfig = fromServerSide ? config : parseConfig(config);
    if (typeof window !== 'undefined') {
      window.serverConfig = {...serverConfig, ...{releaseVersion}};
    }
  } catch (e) {
    captureException('failed to parse config', config);
  }
};
