import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link} from 'react-router-dom';

export const Wrapper = styled.footer`
  display: flex;
  position: relative;
  width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 4.4rem;
  padding-bottom: 4.4rem;
  ${media.medium`
    width: 100%;
    padding: 2rem;
    margin: 0;
  `};
  ${media.smaller`
    width: 100%;
     padding: 2rem 0;
     padding-bottom: 0;
  `};
`;

export const Logo = styled.img`
  width: 17rem;
  height: 2.8rem;
  ${media.smaller`
    margin-left: 2rem;
  `};
`;

export const Container = styled.section`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4.4rem;
  ${media.medium`
    margin-top: 2rem;
  `};
  ${media.smaller`
     display: block;
     margin-top: 2rem;
  `};
`;

export const Links = styled.nav`
  display: flex;
  flex-direction: column;
  width: 25%;

  &:last-child,
  &:nth-last-child(2) {
    width: 15%;
  }

  ${media.smaller`
     width: 100%;
     &:last-child,
     &:nth-last-child(2) {
       width: 100%;
     }
  `};
`;

const commonRowStyle = css`
  display: block;
  text-decoration: none;
  font-size: 1.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  text-transform: ${p => (p.$plain ? 'initial' : 'capitalize')};
  color: ${p => (p.$info ? p.theme.tertiaryTextColor : p.theme.primaryColor)};
  ${media.medium`
    min-height: 4rem;
  `};
  ${media.smaller`
     padding: 0 2rem;
     line-height: 4rem;
     font-weight: normal;
     margin-bottom: 0.1rem;
     border-bottom:
       ${props =>
         props.$last ? 0 : `solid 0.1rem ${props.theme.borderColor}`};
  `};
`;

export const ExternalLink = styled.div`
  ${commonRowStyle};
  display: ${p => (p.$istablelayout ? 'flex' : 'block')};
  ${media.medium`
    flex-direction: column
  `}
`;

const commonLinkStyle = css`
  text-decoration: none;
  text-transform: ${p => (p.$plain ? 'initial' : 'capitalize')};
  color: ${p => (p.$info ? p.theme.tertiaryTextColor : p.theme.primaryColor)};
  display: inline;

  &:hover {
    color: ${p => p.theme.secondaryColor};
  }
`;

export const ExternalLinkContent = styled.a`
  ${commonLinkStyle};
`;

export const ExternalInfoContent = styled.span`
  ${commonLinkStyle};
`;

export const TheLink = styled(Link)`
  ${commonRowStyle};
  display: ${p => (p.$istablelayout ? 'flex' : 'block')};

  &:hover {
    color: ${p => p.theme.secondaryColor};
  }
`;

export const TitleWrapper = styled.h2`
  margin: 0;
  font-size: 1.6rem;
`;
export const Title = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  line-height: 3rem;
  color: ${props => props.theme.tertiaryTextColor};
  ${media.smaller`
    width: 100%;
    text-transform: uppercase;
    color: ${props => props.theme.primaryTextColor};
    background-color: ${props => props.theme.secondaryColor};
    padding: 0 2rem;
    font-size: 1.4rem;
    line-height: 4rem;
    font-weight: normal;
    margin-bottom: 0.1rem;
  `};
`;

export const Status = styled.div`
  display: none;
  ${media.smaller`
    display: block;
    &::before {
      font-family: 'icomoon';
      display: ${p => (p.$isOpen ? 'none' : 'block')};
      content: '\\e90d';
      font-size: 3rem;
    }
    &::after {
      font-family: 'icomoon';
      display: ${p => (p.$isOpen ? 'block' : 'none')};
      content: '\\e90c';
      font-size: 3rem;
    }
  `};
`;

export const HighLight = styled.span`
  padding-right: 0.4rem;
  display: ${p => (p.$linebreak || p.$istablelayout ? 'block' : 'inline')};
  width: ${p => (p.$istablelayout ? '90px' : 'auto')};
  min-width: ${p => (p.$istablelayout ? '90px' : 'auto')};
  color: ${p => (p.$istablelayout ? p.theme.tertiaryTextColor : 'inherit')};
  font-weight: ${p => (p.$istablelayout ? 'bold' : 'normal')};
`;

export const LinksGroup = styled.div`
  ${media.smaller`
    display: ${p => (p.$isOpen ? 'block' : 'none')};
  `};
`;

export const CopyRight = styled.small`
  color: ${props => props.theme.tertiaryTextColor};
  font-size: 1.2rem;
  margin: 0;
  padding: 2rem 0;
  ${media.smaller`
    padding: 2rem;
  `};
`;

export const FraudWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  ${media.smaller`
    flex-direction: column;
    padding-left: 0;
  `};
`;

export const Text = styled.p`
  margin: 0 0 1rem 0;
  line-height: 1.8rem;
`;

export const TextLink = styled.a`
  color: ${props => props.theme.secondaryColor};

  &:hover {
    color: ${props => props.theme.secondaryColorHover};
  }
`;
