import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Status = styled.div`
  font-size: 1.4rem;
  display: flex;
  flex-grow: 1;
  color: ${p => p.theme.textColor};
  ${media.medium`
    flex-direction: column;
  `};
  ${media.smallest`
    max-width: 60%;
  `};
`;

export const Text = styled.div`
  font-weight: bold;
`;

export const Expired = styled.div`
  align-items: center;
  color: ${p => p.theme.tertiaryColor};
  ${media.smallest`
    margin-right: 0.5rem;
  `};
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`;

export const Block = styled.div`
  min-width: 25rem;
  ${media.medium`
    min-width: 100%;
    max-width: 100%;
  `};
`;
export const Details = styled.div`
  padding-left: 1rem;
  ${media.medium`
    padding: 1.5rem 0;
  `};
`;
