import {put, call, takeLatest, select} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import vacanciesApi from '../api/vacancies.api';

function* getVacancies(action) {
  try {
    const vacancies = yield call(vacanciesApi.getVacancies);
    yield put({
      type: ActionTypes.GET_VACANCIES_SUCCESS,
      vacancies: vacancies && vacancies.item,
    });
  } catch (e) {
    yield put({type: ActionTypes.GET_VACANCIES_FAILED, message: e.message});
  }
}

export default function* vacanciesYield() {
  yield takeLatest(ActionTypes.GET_VACANCIES_REQUESTED, getVacancies);
}
