import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  Wrapper,
  Title,
  Head,
  LinkButton,
  Content,
} from '../../styles/pages/pages';
import {
  Info,
  OrderId,
  OrderTitle,
  SectionHeader,
  OrderStatus,
  SectionBody,
  DeliveryInfo,
  SlotInfo,
  BoldText,
  TheButton,
  ErrorInfo,
  ContactLink,
} from './TrackingPage.style';
import {setCurrentRoute} from '../../actions';
import {
  JJ_LOCAL_STORAGE,
  JJ_TEL,
  ORDER_TRACKING,
  ROUTES,
} from '../../constants/constants';
import {trackOrder} from '../../actions/order.action';
import Loading from '../../components/Loading/Loading';
import {ExtraWrapper} from '../../styles/components/wrapper';
import {setLoginRedirect} from '../../actions/loginRedirect.action';
import {getDataFromLocalStorage} from '../../helpers/localStorage.helper';
import {getOrderStatus} from '../../helpers/data.helper';

class TrackingPage extends PureComponent {
  constructor(props) {
    super(props);
    const {match} = props;
    this.state = {
      axOrderId: match.params.orderId,
      orderStatus: null,
      eta: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {auth, trackOrder, order, loading, errors} = this.props;
    if (
      auth &&
      loading &&
      (!prevProps.auth || this.state.axOrderId !== prevState.axOrderId)
    ) {
      if (!order || (order && !order.tracking)) {
        trackOrder(this.state.axOrderId);
      }
    }
    if (
      (!errors || (errors && !errors.trackOrder)) &&
      order &&
      order.tracking &&
      order.tracking.eta &&
      order !== prevProps.order
    ) {
      const newStatus = getOrderStatus(order.tracking.eta);
      if (newStatus.status !== this.state.orderStatus) {
        this.setState({orderStatus: newStatus.status, eta: newStatus.eta});
      }
    }
  }

  async componentDidMount() {
    const {
      auth,
      setCurrentRoute,
      trackOrder,
      toggleLoginModal,
      setLoginRedirect,
    } = this.props;
    const {axOrderId} = this.state;
    const storedAuthToken = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.TOKEN
    );
    if (!auth && !storedAuthToken) {
      setLoginRedirect('/order/track/' + axOrderId);
      toggleLoginModal();
      return;
    }
    setCurrentRoute(ROUTES.TRACKING);
    if (auth && auth.enhanced) {
      trackOrder(axOrderId);
    }
  }

  goBack = () => this.props.history.push(`/${ROUTES.ORDERS}`);

  render() {
    const {auth, order, errors, loading} = this.props;
    const {axOrderId, orderStatus, eta} = this.state;
    const renderShoppingLink = (
      <LinkButton to="/">Continue shopping</LinkButton>
    );
    const isLoadingTracking = loading && loading.trackOrder;
    const hasError = errors && errors.trackOrder;
    const renderError = hasError && !isLoadingTracking && (
      <ErrorInfo>
        Sorry, No tracking information available for this order
      </ErrorInfo>
    );
    const renderLoading = (!auth || isLoadingTracking) && !hasError && (
      <Loading isLight={false} />
    );
    const deliveringBetweenMsg =
      orderStatus === ORDER_TRACKING.DELIVERING && ' will be with you between';
    const deliveringDelayMsg =
      orderStatus === ORDER_TRACKING.DELAYED && ' has been ';
    const renderDeliveryInfo = (orderStatus === ORDER_TRACKING.DELIVERING ||
      orderStatus === ORDER_TRACKING.DELAYED) && (
      <DeliveryInfo>
        Our vehicle <BoldText>{order.tracking.vehicleNumberPlate}</BoldText>{' '}
        driven by <BoldText>{order.tracking.primaryDriverName}</BoldText>
        {deliveringBetweenMsg} {deliveringDelayMsg}
      </DeliveryInfo>
    );
    const renderCancelHeader =
      orderStatus === ORDER_TRACKING.CANCELLED && `Unfortunately, `;
    const renderResult = !isLoadingTracking &&
      !hasError &&
      order &&
      order.tracking && (
        <>
          <OrderTitle>
            {renderCancelHeader}Your order <BoldText>#{axOrderId}</BoldText>
            {orderStatus === ORDER_TRACKING.DELIVERED ||
            orderStatus === ORDER_TRACKING.CANCELLED
              ? ' has been '
              : ' is now '}
            {(orderStatus === ORDER_TRACKING.DELIVERING ||
              orderStatus === ORDER_TRACKING.DELAYED) &&
              ORDER_TRACKING.DELIVERING}
          </OrderTitle>
          <DeliveryInfo>{renderDeliveryInfo}</DeliveryInfo>
        </>
      );
    const deliveringFooterMsg =
      orderStatus === ORDER_TRACKING.DELIVERING &&
      `We take special care to endeavour that deliveries are made within your chosen delivery slot, however, please be advised that the actual delivery times may differ depending on the road conditions and traffic.`;
    const deliveryDelayFooterMsg =
      orderStatus === ORDER_TRACKING.DELAYED &&
      `We take special care to endeavour that deliveries are made within your chosen delivery slot, however, please be advised that the actual delivery times may differ depending on the road conditions and traffic.`;
    const deliveryCancelFooterMsg =
      orderStatus === ORDER_TRACKING.CANCELLED &&
      `Unfortunately, a problem occurred during delivery and we had to cancel your order. Payment will not be taken for this order.`;
    const renderCancelledContactDetails = orderStatus ===
      ORDER_TRACKING.CANCELLED && (
      <p>
        Please contact the customer service for more information on
        <ContactLink href={`tel: ${JJ_TEL}`}>01992 701 701</ContactLink>
        <p>We´re very sorry for the inconvenience.</p>
      </p>
    );
    return (
      <Wrapper>
        <Head>
          <Title>Order status</Title>
          <TheButton onClick={this.goBack}>Back to My Orders</TheButton>
        </Head>
        <Content>
          <SectionHeader>
            <span>
              Order <OrderId>#{axOrderId}</OrderId>
            </span>
            <OrderStatus>
              {orderStatus === ORDER_TRACKING.DELAYED
                ? ORDER_TRACKING.DELIVERING
                : orderStatus}
            </OrderStatus>
          </SectionHeader>
          <SectionBody>
            {renderLoading}
            {renderResult}
            <SlotInfo>{eta || orderStatus}</SlotInfo>
            {renderError}
          </SectionBody>
          <Info>
            {deliveringFooterMsg}
            {deliveryDelayFooterMsg}
            {deliveryCancelFooterMsg}
            {renderCancelledContactDetails}
          </Info>
        </Content>
        <ExtraWrapper>{renderShoppingLink}</ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  order: state.order,
  errors: state.errors,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  trackOrder: bindActionCreators(trackOrder, dispatch),
  setLoginRedirect: bindActionCreators(setLoginRedirect, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingPage);
