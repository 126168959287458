import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  ButtonWrapper,
  Title,
  Subtitle,
  Locator,
  Go,
} from './BranchLocator.style';
import {KEYBOARD_KEYS, STRING_NUM_REGEX} from '../../constants/constants';
import {validatePostcode} from '../../helpers/data.helper';

class BranchLocator extends PureComponent {
  state = {
    postcode: null,
  };

  onGoClick = () => {
    if (!this.state.postcode) {
      return;
    }
    if (validatePostcode(this.state.postcode)) {
      this.props.onLocatorClick(this.state.postcode);
    }
  };

  handleOnChange = e => this.setState({postcode: e.target.value.trim()});

  handleOnKeyPress = e => {
    if (e.key !== KEYBOARD_KEYS.ENTER) {
      return;
    }
    const {postcode} = this.state;
    if (postcode && postcode.trim()) {
      const validString = postcode.trim().replace(STRING_NUM_REGEX, '');
      if (validString.length > 1 && validatePostcode(validString)) {
        this.props.onLocatorClick(validString);
      }
    }
  };

  render() {
    return (
      <Wrapper>
        <Content>
          <Title>Find a branch</Title>
          <Subtitle>
            View the location, open times and special offers of your nearest
            branch
          </Subtitle>
        </Content>
        <ButtonWrapper>
          <Locator
            type="text"
            placeholder="Enter postcode"
            onChange={this.handleOnChange}
            onKeyPress={this.handleOnKeyPress}
          />
          <Go onClick={this.onGoClick}>Go</Go>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

BranchLocator.propTypes = {
  onLocatorClick: PropTypes.func.isRequired,
};

export default BranchLocator;
