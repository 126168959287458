import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  BranchesWrapper,
  Branches,
  OK,
  MemoText,
} from './PopupModal.style';
import AllBranchList from '../AllBranchList/AllBranchList';
import {DEFAULT_BRANCH} from '../../constants/constants';

export default class BranchModal extends PureComponent {
  state = {
    branchId: this.props.branchId || DEFAULT_BRANCH,
  };
  changeBranch = e => this.setState({branchId: e.target.value});
  setBranch = () => this.props.callback(this.state.branchId);

  render() {
    const {showModal, branchList, closeModalCallback} = this.props;
    const renderBranches = branchList && (
      <Branches>
        <AllBranchList
          branchList={branchList}
          selectorValue={this.state.branchId}
          callback={this.changeBranch}
        />
      </Branches>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw={'branch-modal'}
      >
        <StyledWrapper>
          <Content>
            <BranchesWrapper>
              <Title>Which branch do you wish to browse?</Title>
              <MemoText>
                Prices may vary by branch, please select your branch to see
                accurate prices.
              </MemoText>
              {renderBranches}
              <OK onClick={this.setBranch}>OK</OK>
            </BranchesWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
