import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class OrderApi {
  static getOrder(orderId, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${orderId}`;
    return axios.get(url, {headers}).then(res => res.data);
  }
  static getOrderHistory(headers, params, status) {
    const config = getApiConfig();
    return axios
      .get(`${config.orderHistoryUrl}/${status}`, {headers, params})
      .then(res => res.data)
      .catch(error => {
        return {error};
      });
  }
  static async getInvoicedOrder(salesId, accountId, headers) {
    const config = getApiConfig();
    const url = `${config.invoicesUrl}/${salesId}?accountId=${accountId}`;
    const res = await axios.get(url, {headers});
    return res.data;
  }
  static getInvoices(headers, params) {
    const config = getApiConfig();
    return axios
      .get(config.invoicesUrl, {headers, params})
      .then(res => res.data)
      .catch(error => {
        return {error};
      });
  }
  static cancelOrder(orderId, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${orderId}`;
    return axios.delete(url, {headers}).then(res => res.data);
  }
  static reAuthOrder(orderId, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${orderId}/reauth`;
    return axios.post(url, null, {headers}).then(res => res.data);
  }
  static trackOrder(axBasketId, headers) {
    const config = getApiConfig();
    const url = `${config.orderTrackerUrl}/${axBasketId}`;
    return axios.get(url, {headers}).then(res => res.data);
  }
}
