export const GQL_GET_SEARCH = `
    query(
        $page: Int
        $size: Int
        $branch: String
        $deliveryDate: Date
        $brand: String
        $productFeatures: [String!]
        $sizeOrCut: [String!]
        $isNew: Boolean
        $sortType: String
        $origin: String
        $query: String!
        $categoryIds: [String!]
        $categoryNames: [String!]
        $includeNonPublicItems: Boolean
        $first: [String!]
    ) {
        searchProducts (
            page: $page
            size: $size
            branch: $branch
            deliveryDate: $deliveryDate
            brand: $brand
            productFeatures: $productFeatures
            sizeOrCut: $sizeOrCut
            isNew: $isNew
            sortType: $sortType
            origin: $origin
            query: $query
            categoryIds: $categoryIds
            categoryNames: $categoryNames
            includeNonPublicItems: $includeNonPublicItems
            first: $first
        ) {
        page {
            number
            size
            totalPages
            totalElements
        }
        results {
            uuid
            itemId
            itemName
            averageScore
            category1Id
            category1Name
            category2Id
            category2Name
            category3Id
            category3Name
            categoryName
            isNew
            brand
            previouslyPurchased
            reviewCount
            tags
            quantityStep
            quantityMax
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collectionMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            deliveryMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            quantityStep
            quantityMax
            productDescription
            offer {
                onOffer
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
        facets {
            name
            data {
            id
            name
            qty
            unquery
            query
            selected
            counts {
                id
                name
                qty
                unquery
                query
                selected
                counts {
                    id
                    name
                    qty
                    unquery
                    query
                    selected
                }
            }
            }
        }
        }
    }
`;
