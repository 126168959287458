import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3Inverted} from '../../styles/components/buttons';

export const CheckboxLabel = styled.label`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  line-height: 3rem;
  padding-right: 2rem;
  ${media.smaller`
    line-height: 2rem;
    margin-bottom: 1rem;
  `};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  position: relative;
  flex-direction: row;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const Title = styled.span`
  color: ${p => p.theme.secondaryColor};
  padding: 1rem 1rem 1rem 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  width: 20rem;
`;

export const Description = styled.div`
  color: ${p => p.theme.textColor};
  margin-bottom: 1rem;
  line-height: 2rem;
`;

export const Content = styled.span`
  padding: 1rem 1rem 1rem 2rem;
  line-height: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
`;

export const Switch = styled.div`
  width: 6rem;
  height: 2.5rem;
  background-color: ${p =>
    p.$isOn ? p.theme.secondaryColor : p.theme.disabledColor};
  position: relative;
  padding: 1.4rem;
  border-radius: 2rem;
  &::after {
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    position: absolute;
    ${p => (p.$isOn ? 'right: 0.2rem' : 'left: 0.2rem')};
    top: 0.2rem;
    background-color: ${p => p.theme.secondaryBgColor};
    border: 1px solid ${p => p.theme.borderDarkerColor};
    border-radius: 2rem;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin: 0 0 2rem 0;
`;

export const SwitchText = styled.span`
  margin: 0.5rem 0 1rem 1rem;
  line-height: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  position: relative;
`;

export const Text = styled.p`
  padding: 0;
  margin: 0 0 2rem 0;
  line-height: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  font-style: italic;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const TheButton = styled(Button3Inverted)`
  width: 10rem;
  ${media.smaller`
    width: 100%;
  `};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
