import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function shoppingListReducer(
  state = initialState.shoppingLists,
  action
) {
  switch (action.type) {
    case types.GET_ALL_LISTS_SUCCESS:
      return action.shoppingLists || [];
    case types.CREATE_A_SHOPPING_LIST_SUCCESS:
      return [...state, action.shoppingList];
    case types.ADD_TO_SHOPPING_LIST_SUCCESS:
    case types.REMOVE_FROM_SHOPPING_LIST_SUCCESS:
      if (!state) {
        return null;
      }
      return state.map(el => {
        if (el.id === action.listId) {
          let items = [];
          if (el.items.length > 1) {
            items = el.items.filter(obj => obj.itemId !== action.itemId);
          }
          return {
            id: el.id,
            name: el.name,
            count: el.count - 1,
            items,
          };
        } else {
          return el;
        }
      });
    case types.RENAME_SHOPPING_LIST_SUCCESS:
      return state.map(el => {
        if (el.id === action.results.id) {
          return Object.assign({}, el, {name: action.results.name});
        } else {
          return el;
        }
      });
    case types.GET_ALL_LISTS_REQUESTED:
    case types.GET_ALL_LISTS_FAILED:
      return null;
    case types.DELETE_SHOPPING_LIST_SUCCESS:
      return state && state.filter(el => el.id !== action.listId);
    default:
      return state;
  }
}
