import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

const DecoratedLine = styled.span`
  position: relative;
  width: 9rem;
  height: 0.2rem;
  margin-right: 3rem;
  background-color: ${props => props.theme.secondaryColor};
  ${media.small`
    width: 5rem;
  `};
  ${media.smaller`
    width: 4rem;
  `};
`;

export const LeftLine = styled(DecoratedLine)`
  &::before {
    content: '';
    position: absolute;
    margin-top: -0.2rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: ${props => props.theme.secondaryColor};
    border-radius: 3px;
  }
  ${media.medium`
    margin-right: 1rem;
  `};
`;

export const RightLine = styled(DecoratedLine)`
  margin-left: 3rem;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    margin-top: -0.2rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: ${props => props.theme.secondaryColor};
    border-radius: 3px;
  }
  ${media.medium`
    margin-right: 0;
    margin-left: 1rem;
  `};
`;
