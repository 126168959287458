import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Tab} from '../../styles/pages/modal';
import {Button3} from '../../styles/components/buttons';
import {Wrapper} from '../../styles/pages/modal';

export const FormContainer = styled.div`
  max-height: 80vh;
  position: relative;
  ${media.small`
    max-height: calc(100vh - 3.6rem);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  `};
`;

export const FormWrapper = styled.form`
  display: flex;
  align-items: center;
  padding: 2rem;
  min-height: calc(80vh - 6.5rem);
  overflow-y: auto;
  max-height: calc(80vh - 6.5rem);
  background-color: ${props => props.theme.secondaryBgColor};
  ${media.small`
    padding: 0;
    max-height: 100%;
    flex-grow: 1;
    align-items: flex-start;
  `};
`;

export const ModalText = styled.div`
  font-size: 1.6rem;
  line-height: 3rem;
  color: ${p => p.theme.textColor};
  text-align: center;
  margin-top: ${p => (p.$margin ? '3rem' : '0')};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Button = styled(Button3)`
  min-height: 3.4rem;
  box-sizing: content-box;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  margin-top: 2rem;
  margin-right: ${p => (p.$left ? '1rem' : '0')};
  margin-left: ${p => (p.$right ? '1rem' : '0')};
  width: 50%;
  text-transform: uppercase;
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.secondaryColor};

  &:hover {
    color: ${p => p.theme.primaryTextColor};
  }

  ${media.small`
    width: auto;
    margin-left: 0;
    flex-grow: 1;
    &:first-child {
      margin-right: 1rem;
    }
  `};
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const TheTab = styled(Tab)`
  max-width: ${p => (p.$isInline ? `auto` : `20rem`)};
  flex-grow: ${p => (p.$isInline ? `0` : `1`)};
  display: ${p => (p.$isVisible ? 'block' : 'none')};
  ${media.smaller`
    width: auto;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    display: ${p => (p.$isActive && p.$isVisible ? 'block' : 'none')};
  `};
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: calc(80vh - 6.5rem);
`;

export const StyledModalWrapper = styled(Wrapper)`
  ${media.small`
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  `};
`;
