import {MANUALLY_ERRORS} from '../constants/constants';

const checkSupported = () => {
  if (window && window.sessionStorage) {
    return true;
  } else {
    throw new Error(MANUALLY_ERRORS.SESSION_STORAGE_UNSUPPORTED);
  }
};

export const persistDataToSessionStorage = (key, value) => {
  if (checkSupported()) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeValueFromSessionStorage = key => {
  if (checkSupported()) {
    sessionStorage.removeItem(key);
  }
};

export const resetSessionStorage = () => {
  if (checkSupported()) {
    sessionStorage.clear();
  }
};

export const getDataFromSessionStorage = key => {
  if (checkSupported()) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      removeValueFromSessionStorage(key);
    }
  }
};
