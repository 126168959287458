import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Markdown from 'markdown-to-jsx';
import {bindActionCreators} from 'redux';

import ImageComponent from '../../components/Image/Image';
import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getCareers} from '../../actions/cms.action';
import {chooseColumnsStyle} from '../../helpers/data.helper';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class CareersPage extends PureComponent {
  componentDidMount() {
    const {getCareers} = this.props;
    getCareers();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.careers &&
      (prevProps.cms && prevProps.cms.careers) !== cms.careers
    ) {
      setCmsHeadTags(cms.careers);
    }
  }

  render() {
    const {cms, loading, errors, deviceInfo} = this.props;
    const isMobile = deviceInfo && (deviceInfo.isPhone || deviceInfo.isTablet);
    const careers = cms && cms.careers;
    const renderMarkdown = careers && careers.content && (
      <div className={chooseColumnsStyle(isMobile)}>
        <Markdown>{markdownConvert(careers.content)}</Markdown>
      </div>
    );
    const renderMedia = careers && careers.media && (
      <ImageComponent url={careers.media} alt={careers.heading} />
    );
    const renderLoading = loading && loading.careers && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.careers && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{careers && careers.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getCareers: bindActionCreators(getCareers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareersPage);
