import axios from 'axios';
import {TIMEOUT_TIME} from '../constants/constants';
import {getApiConfig} from '../config/configProvider';

export default class settingsApi {
  static getAllSettings(headers) {
    const config = getApiConfig();
    const url = `${config.profileApi}/settings`;
    return axios
      .get(url, {headers, timeout: TIMEOUT_TIME})
      .then(res => res.data);
  }
  static getSetting(setting, headers) {
    const config = getApiConfig();
    const url = `${config.profileApi}/settings`;
    return axios.get(url, {headers}).then(res => res.data);
  }
  static updateSetting(setting, value, headers) {
    const config = getApiConfig();
    const url = `${config.profileApi}/settings`;
    return axios
      .put(`${url}/add/${setting}/${encodeURIComponent(value)}/`, null, {
        headers,
      })
      .then(res => res.data);
  }
  static updateAllSettings(settings, headers) {
    const config = getApiConfig();
    const url = `${config.profileApi}/settings`;
    return axios.post(`${url}`, settings, {headers}).then(res => res.data);
  }
  static removeSetting(setting, headers) {
    const config = getApiConfig();
    const url = `${config.profileApi}/settings`;
    return axios
      .put(`${url}/remove/${setting}/`, null, {headers})
      .then(res => res.data);
  }
}
