import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const Radio = styled.span`
  height: 1.2rem;
  min-width: 1.2rem;
  border-radius: 50%;
  border-color: ${p =>
    p.$isChecked ? p.theme.secondaryColor : p.theme.tertiaryTextColor};
  border-width: 0.2rem;
  border-style: solid;

  &:before {
    content: '';
    position: absolute;
    display: ${p => (p.$isChecked ? 'block' : 'none')};
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }
`;

export const BigRadio = styled.span`
  height: 1.6rem;
  min-width: 1.6rem;
  border-radius: 50%;
  border-color: ${p =>
    p.$isChecked ? p.theme.secondaryColor : p.theme.disabledColor};
  border-width: 0.2rem;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    display: ${p => (p.$isChecked ? 'block' : 'none')};
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${p => p.theme.secondaryColor};
  }
`;

export const CheckMark = styled(Radio)`
  position: relative;
  top: 0;
  left: 0;
  margin-right: 1rem;

  &:before {
    top: -0.1rem;
    background-color: ${p => p.theme.secondaryColor};
  }
`;

export const Checkbox = styled.input`
  height: 1.2rem;
  min-width: 1.2rem;
  outline: none;
  border-color: ${p =>
    p.$isChecked ? p.theme.secondaryColor : p.theme.tertiaryTextColor};
  border-width: 0.2rem;
  border-style: solid;

  &:before {
    content: '';
    position: absolute;
    display: ${p => (p.$isChecked ? 'block' : 'none')};
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
  }
`;

export const TickBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  border-width: 0.2rem;
  border-style: solid;
  border-color: ${p =>
    p.$isChecked ? p.theme.secondaryColor : p.theme.disabledColor};

  &:before {
    content: '\\2713';
    display: ${p => (p.$isChecked ? 'block' : 'none')};
    color: ${p => p.theme.secondaryColor};
    font-size: 1.8rem;
    position: absolute;
    left: 0;
    top: -0.4rem;
  }
`;
