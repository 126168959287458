import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function blogPostsSearchReducer(
  state = initialState.blogPostsSearch,
  action
) {
  switch (action.type) {
    case types.GET_BLOG_POST_BY_TITLE_SUCCESS:
      return action.blogPostsSearch;
    case types.GET_BLOG_POST_BY_TITLE_REQUESTED:
    case types.GET_BLOG_POST_BY_TITLE_FAILED:
      return null;
    default:
      return state;
  }
}
