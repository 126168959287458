import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function cleanModeReducer(
  state = initialState.cleanMode,
  action
) {
  switch (action.type) {
    case types.SET_CLEAN_MODE_SUCCESS:
      return true;
    default:
      return state;
  }
}
