import React, {PureComponent} from 'react';

import logo from '../../images/logo.png';
import FooterLinks from './FooterLinks';
import {
  Wrapper,
  Logo,
  Container,
  CopyRight,
  FraudWrapper,
} from './Footer.style';
import {FOOTER_LINKS} from '../../constants/links';

export default class Footer extends PureComponent {
  render() {
    const renderLinks = FOOTER_LINKS.map(links => (
      <FooterLinks links={links} key={links.header} />
    ));
    return (
      <Wrapper>
        <FraudWrapper>
          <Logo src={logo} alt="JJ Foodservice" />
        </FraudWrapper>
        <Container>{renderLinks}</Container>
        <CopyRight>&#169; JJ Food Service Ltd. All Rights Reserved.</CopyRight>
      </Wrapper>
    );
  }
}
