import React, {PureComponent} from 'react';
import {
  ContentText,
  Day,
  Date,
  FromTo,
  MinOrder,
  Min,
} from './BranchLocatorPage.style';
import {CLOSED_TIME} from '../../constants/constants';

export default class TimeTable extends PureComponent {
  render() {
    const {timetable, isDelivery, minOrder} = this.props;

    const renderTimesTable = timetable.slice(0, 7).map((day, i) => {
      const renderDetails =
        day &&
        (day.from !== CLOSED_TIME && day.to !== CLOSED_TIME
          ? `${day.from} - ${day.to}`
          : `CLOSED`);
      const renderMinOrder = isDelivery && (
        <MinOrder>
          <Min>min</Min> £{day.minOrderValue || minOrder}
        </MinOrder>
      );
      return (
        <ContentText key={i}>
          <Day>{day.day}</Day>
          {renderMinOrder}
          <Date>{day.date}</Date>
          <FromTo>{renderDetails}</FromTo>
        </ContentText>
      );
    });
    return <>{renderTimesTable}</>;
  }
}
