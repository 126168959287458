import React, {PureComponent} from 'react';
import Markdown from 'markdown-to-jsx';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import ImageComponent from '../../components/Image/Image';
import Loading from '../../components/Loading/Loading';
import {getPolicies} from '../../actions/cms.action';
import {chooseColumnsStyle} from '../../helpers/data.helper';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class PoliciesPage extends PureComponent {
  componentDidMount() {
    const {getPolicies} = this.props;
    getPolicies();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.policies &&
      (prevProps.cms && prevProps.cms.policies) !== cms.policies
    ) {
      setCmsHeadTags(cms.policies);
    }
  }

  render() {
    const {cms, loading, errors, deviceInfo} = this.props;
    const isMobile = deviceInfo && (deviceInfo.isPhone || deviceInfo.isTablet);
    const policies = cms && cms.policies;
    const renderMarkdown = policies && policies.content && (
      <div className={chooseColumnsStyle(isMobile)}>
        <Markdown>{markdownConvert(policies.content)}</Markdown>
      </div>
    );
    const renderMedia = policies && policies.media && (
      <ImageComponent url={policies.media} alt={policies.heading} />
    );
    const renderLoading = loading && loading.policies && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.policies && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{policies && policies.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getPolicies: bindActionCreators(getPolicies, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PoliciesPage)
);
