import {put, call, all, takeLatest, select} from 'redux-saga/effects';
import searchApi from '../api/search.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {processedGQLSearchResults} from '../helpers/data.helper';
import {getDeliveryDate} from '../helpers/search.helper';
import {DEFAULT_BRANCH} from '../constants/constants';

function* searchMore(action) {
  try {
    const getAuth = state => state.auth;
    const getBasket = state => state.basket;
    const getSettings = state => state.settings;
    const getBranch = state => state.branch;
    const storeToken = yield select(getAuth);
    const basket = yield select(getBasket);
    const settings = yield select(getSettings);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const branch = yield select(getBranch);
    let params = action.params;
    const deliveryDate = getDeliveryDate(
      basket,
      action.fulfillmentDetails,
      settings
    );
    if (deliveryDate) {
      params = Object.assign({}, params, {deliveryDate});
    }
    params.b = branch || DEFAULT_BRANCH; // make sure it gets the branch
    const result = yield call(searchApi.search, params, headers);
    yield all([
      put({
        type: ActionTypes.GET_MORE_SEARCH_RESULTS_SUCCESS,
        searchResults:
          result &&
          result.data &&
          result.data.searchProducts &&
          result.data.searchProducts.results &&
          processedGQLSearchResults(result.data.searchProducts.results),
      }),
      put({
        type: ActionTypes.GET_MORE_SEARCH_RESULTS_PAGE_SUCCESS,
        page:
          result &&
          result.data &&
          result.data.searchProducts &&
          result.data.searchProducts.page,
      }),
      put({
        type: ActionTypes.GET_MORE_SEARCH_AGGREGATE_SUCCESS,
        aggression:
          result &&
          result.data &&
          result.data.searchProducts &&
          result.data.searchProducts.facets,
      }),
    ]);
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MORE_SEARCH_RESULTS_FAILED,
      error,
    });
  }
}

export default function* getNewSearchYield() {
  yield takeLatest(ActionTypes.GET_MORE_SEARCH_RESULTS_REQUESTED, searchMore);
}
