export const GQL_FREQUENTLY_BOUGHT = `
    query(
        $branch: String
        $deliveryDate: Date
        $itemId: String!
        $size: Int
    ) {
        lookupFrequentlyBoughtTogether (
            branch: $branch
            deliveryDate: $deliveryDate
            itemId: $itemId
            size: $size
        ) {
        results {
            uuid
            itemId
            itemName
            averageScore
            isNew
            previouslyPurchased
            reviewCount
            tags
            quantityStep
            quantityMax
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collectionMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            deliveryMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            quantityStep
            quantityMax
            productDescription
            offer {
                onOffer
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
        }
    }
`;
