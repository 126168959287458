import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import IframeComm from 'react-iframe-comm';

import {MediaWrapper, VideoWrapper} from './ProductDetailPage.style';
import ImageComponent from '../../components/Image/Image';
import {IMAGE_UNAVAILABLE_SIZE} from '../../constants/constants';

export default class MainMedia extends PureComponent {
  static propTypes = {
    media: PropTypes.object,
    hasSubImages: PropTypes.bool,
  };
  render() {
    const {media, hasSubImages, callback, name} = this.props;
    if (media && media.videoUrl) {
      return (
        <VideoWrapper>
          <IframeComm
            attributes={{
              src: media.videoUrl + '?rel=0&autoplay=1',
              width: '100%',
              height: '100%',
              frameBorder: 0,
            }}
          />
        </VideoWrapper>
      );
    }
    return (
      <MediaWrapper $hasSubImages={hasSubImages}>
        <ImageComponent
          url={media && media.url}
          alt={name}
          onClickHandler={callback}
          type={IMAGE_UNAVAILABLE_SIZE.L}
        />
      </MediaWrapper>
    );
  }
}
