import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  ImageWrapper,
  Item,
  Remove,
  Product,
  ProductInfo,
  Details,
  ProductContent,
  Name,
  ItemId,
  Price,
  QuantityStyled,
  PromotionContent,
  LoadingWrapper,
  ProductMessage,
  ProductMessageMobile,
  ItemVat,
} from './BasketItem.style';
import Quantity from '../Quantity/Quantity';
import Loading from '../Loading/Loading';
import Promotion from '../Promotion/Promotion';
import {
  checkHasMixOffer,
  handleBasketItemMessage,
  handleStockCheckMessage,
} from '../../helpers/data.helper';
import {
  IMAGE_UNAVAILABLE_SIZE,
  PROMO_TAGS,
  STOCK_CHECK,
} from '../../constants/constants';
import ImageComponent from '../Image/Image';
import {isServiceItem} from '../../helpers/basket.helper';
import {getProductMax, getProductStep} from '../../helpers/product.helper';

export default class BasketItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    removeItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    onBasketItemClick: PropTypes.func,
    viewOnly: PropTypes.bool,
    loading: PropTypes.object,
    isDelivery: PropTypes.bool,
    synced: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const {item, isDelivery} = props;
    let step = 1;
    let max = 0;
    if (item) {
      const {product} = item;
      step = getProductStep(product, isDelivery);
      max = getProductMax(product, isDelivery);
    }
    this.state = {
      removing: false,
      step,
      max,
    };
  }

  remove = () => {
    if (this.state.removing) {
      return;
    }
    const {removeItem, item} = this.props;
    this.setState({removing: true});
    //todo set `removing: false` if removeBasketItemFailed errors
    removeItem(item.uuid, item.product);
  };

  onProductClick = () => {
    const {onBasketItemClick, item} = this.props;
    const isSameDayDelivery = isServiceItem(item);
    if (isSameDayDelivery) {
      return;
    }
    if (item && item.product && item.product.itemId) {
      onBasketItemClick(item.product.itemId);
    }
  };

  render() {
    const {item, updateItem, viewOnly, loading, synced} = this.props;
    const isSameDayDelivery = isServiceItem(item);
    const renderQuantity = item && !viewOnly && (
      <QuantityStyled>
        <Quantity
          quantity={item.quantity}
          update={updateItem}
          uuid={item.uuid}
          remove={this.remove}
          loading={loading}
          step={this.state.step}
          max={this.state.max}
          unitPrice={item.unitPrice}
        />
      </QuantityStyled>
    );
    const renderRemove =
      !viewOnly && this.state.removing ? (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      ) : (
        <Remove onClick={this.remove}>Remove</Remove>
      );
    const renderPromo = !isSameDayDelivery &&
      item &&
      item.product &&
      item.product.offer &&
      item.product.offer.promoDiscountText && (
        <PromotionContent>
          <Promotion
            offer={item.product.offer}
            promo={item.product.offer.promoDiscountText}
            isPbbOffer={
              item.product.offer.promoTagId === PROMO_TAGS.Pay_By_Bank
            }
            hasMixOffer={checkHasMixOffer(item.product)}
            alignLeft={true}
            isPureText={true}
            isOneLine={true}
          />
        </PromotionContent>
      );
    const renderMessage = item && item.messages && (
      <ProductMessage>{handleBasketItemMessage(item.messages)}</ProductMessage>
    );
    const renderStockMessage =
      item &&
      synced &&
      item.stockCheck &&
      item.stockCheck.result &&
      item.stockCheck.result !== STOCK_CHECK.IN_STOCK &&
      (!item.messages || (item.messages && !item.messages.length)) &&
      handleStockCheckMessage(item.stockCheck, item.quantity);
    const renderStockMessageDesktop = renderStockMessage && (
      <ProductMessage>{renderStockMessage}</ProductMessage>
    );
    const renderStockMessageMobile = renderStockMessage && (
      <ProductMessageMobile>{renderStockMessage}</ProductMessageMobile>
    );
    const renderVat = item && !!item.quantity && !!item.vat && item.vat > 0 && (
      <ItemVat>+ £{Math.abs(item.vat).toFixed(2)} VAT</ItemVat>
    );
    if (!item) {
      return null;
    }
    const renderId = isSameDayDelivery ? (
      <ItemId>{item.product && item.product.itemName}</ItemId>
    ) : (
      <ItemId>{item.itemId}</ItemId>
    );
    const renderItemName =
      !isSameDayDelivery && item.product && item.product.itemName;
    const renderWeight = !isSameDayDelivery && <span>{item.weight}</span>;
    const renderExtras = !isSameDayDelivery && (
      <Fragment>
        {renderQuantity}
        {renderRemove}
        {renderMessage}
        {renderStockMessageDesktop}
      </Fragment>
    );
    return (
      <Fragment>
        <Item>
          <Product>
            <ProductInfo>
              <ImageWrapper>
                <ImageComponent
                  url={
                    item.product &&
                    item.product.imageLinks &&
                    item.product.imageLinks[0]
                  }
                  alt={item.product && item.product.itemName}
                  onClickHandler={this.onProductClick}
                  type={IMAGE_UNAVAILABLE_SIZE.S}
                />
              </ImageWrapper>
              <Details>
                <Name
                  onClick={this.onProductClick}
                  $isLarge={isSameDayDelivery}
                >
                  {renderId}
                  {renderItemName}
                </Name>
                {renderWeight}
                {renderPromo}
              </Details>
            </ProductInfo>
            <ProductContent>
              <Price>
                £{item.price && item.price.toFixed(2)}
                {renderVat}
              </Price>
              {renderExtras}
            </ProductContent>
          </Product>
        </Item>
        {renderStockMessageMobile}
      </Fragment>
    );
  }
}
