import {put, call, takeLatest} from 'redux-saga/effects';
import branchApi from '../api/branch.api';
import * as ActionTypes from '../actions/types.action';
import {arrayToObject} from '../helpers/array.helper';

function* getNearestBranch(action) {
  try {
    const branchLocator = yield call(
      branchApi.getNearestBranch,
      action.postcode
    );
    if (
      !action.postcode ||
      (branchLocator &&
        (branchLocator.address === {} ||
          (!branchLocator.branchName && !branchLocator.inventLocationId)))
    ) {
      yield put({
        type: ActionTypes.GET_NEAREST_BRANCH_FAILED,
        message: 'Invalid postcode',
      });
      return;
    }
    yield put({
      type: ActionTypes.GET_NEAREST_BRANCH_SUCCESS,
      branchLocator: branchLocator,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_NEAREST_BRANCH_FAILED,
      message: e.message,
    });
  }
}

function* getBranchOpeningTimes() {
  try {
    const result = yield call(branchApi.getBranchOpeningTimes);
    let openingTimes;
    if (result) {
      openingTimes = arrayToObject(result, 'inventLocationId');
    }
    if (openingTimes) {
      yield put({
        type: ActionTypes.GET_BRANCH_OPENING_TIMES_SUCCESS,
        openingTimes,
      });
    } else {
      yield put({
        type: ActionTypes.GET_BRANCH_OPENING_TIMES_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_BRANCH_OPENING_TIMES_FAILED,
      message: e.message,
    });
  }
}

export default function* getBranchYield() {
  yield takeLatest(ActionTypes.GET_NEAREST_BRANCH_REQUESTED, getNearestBranch);
  yield takeLatest(
    ActionTypes.GET_BRANCH_OPENING_TIMES_REQUESTED,
    getBranchOpeningTimes
  );
}
