import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function statusReducer(state = initialState.status, action) {
  switch (action.type) {
    case types.SEND_REFUND_EMAIL_SUCCESS:
      return {...state, ...{refundSent: true}};
    case types.SEND_REFUND_EMAIL_FAILED:
    case types.SEND_REFUND_EMAIL_RESET:
      return {...state, ...{refundSent: false}};
    default:
      return state;
  }
}
