import {
  put,
  call,
  takeLatest,
  select,
  fork,
  join,
  delay,
  retry,
} from 'redux-saga/effects';
import orderApi from '../api/order.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {
  AX_BASKET_ERRORS,
  AX_ORDER_STATUS,
  ERRORS,
  MAX_ORDER_RETRY,
  GET_ORDER_RETRY_TIMEOUT,
  ROUTES,
} from '../constants/constants';
import {isLessThanAWeekOldByDate} from '../helpers/array.helper';

function* getOrder(action) {
  try {
    if (!action.orderId) {
      return;
    }
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    const order = yield call(orderApi.getOrder, action.orderId, headers);
    if (order.status === AX_ORDER_STATUS.EDIT_EXPIRED) {
      yield put({
        type: ActionTypes.AX_BASKET_ALREADY_PICKED,
        message: AX_BASKET_ERRORS.STARTED_PICKING,
      });
    } else {
      yield put({type: ActionTypes.GET_ORDER_SUCCEEDED, order});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_ORDER_FAILED,
      message: e.message,
    });
  }
}

function* getNewOrder(orderId, headers) {
  try {
    const order = yield call(orderApi.getOrder, orderId, headers);
    if (order) {
      return order;
    }
  } catch (e) {
    return ERRORS.ORDER_FAILED;
  }
}

function* reAuthOrderPayment(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    const results = yield call(orderApi.reAuthOrder, action.orderId, headers);
    if (results) {
      yield put({
        type: ActionTypes.PROCESS_CHECKOUT_SUCCESS,
        checkout: results,
      });
      yield put({
        type: ActionTypes.REAUTH_ORDER_SUCCEEDED,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.REAUTH_ORDER_FAILED,
      message: e.message,
    });
  }
}

function* getFinalOrder(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    let order;
    let i = 0;
    order = yield call(getNewOrder, action.orderId, headers);
    if (order.status === AX_ORDER_STATUS.SLOT_EXPIRED) {
      yield put({
        type: ActionTypes.CHECKOUT_SLOT_EXPIRED,
        page: ROUTES.CHECKOUT,
      });
      return;
    }
    if (order.status === AX_ORDER_STATUS.EDIT_EXPIRED) {
      yield put({
        type: ActionTypes.AX_BASKET_ALREADY_PICKED,
        message: AX_BASKET_ERRORS.STARTED_PICKING,
      });
      return;
    }
    if (order.status === AX_ORDER_STATUS.TOTAL_DISCREPANCY) {
      yield put({
        type: ActionTypes.CHECKOUT_TOTAL_DISCREPANCY,
        page: ROUTES.BASKET,
      });
      return;
    }
    if (
      order &&
      (order.status === AX_ORDER_STATUS.PAYMENT_AUTHORIZED || !order.status)
    ) {
      while (i < MAX_ORDER_RETRY) {
        yield delay(GET_ORDER_RETRY_TIMEOUT);
        i += 1;
        const orderFork = yield fork(getNewOrder, action.orderId, headers);
        let updatedOrder = yield join(orderFork);
        if (updatedOrder) {
          if (updatedOrder.status === AX_ORDER_STATUS.SLOT_EXPIRED) {
            yield put({
              type: ActionTypes.CHECKOUT_SLOT_EXPIRED,
              page: ROUTES.CHECKOUT,
            });
            break;
          }
          if (updatedOrder.status === AX_ORDER_STATUS.EDIT_EXPIRED) {
            yield put({
              type: ActionTypes.AX_BASKET_ALREADY_PICKED,
              message: AX_BASKET_ERRORS.STARTED_PICKING,
            });
            break;
          }
          if (
            updatedOrder.status === AX_ORDER_STATUS.SUCCESS ||
            updatedOrder.status === AX_ORDER_STATUS.SUBMITTED ||
            updatedOrder.status === AX_ORDER_STATUS.PAYMENT_VOIDED ||
            updatedOrder.status === AX_ORDER_STATUS.CANCELLED
          ) {
            order = updatedOrder;
            break;
          }
        }
      }
    }
    if (
      order &&
      order.orderId &&
      order !== ERRORS.ORDER_FAILED &&
      i < MAX_ORDER_RETRY
    ) {
      yield put({type: ActionTypes.GET_ORDER_SUCCEEDED, order});
    } else {
      yield put({type: ActionTypes.GET_ORDER_AFTER_CHECKOUT_FAILED});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_ORDER_AFTER_CHECKOUT_FAILED,
      message: e.message,
    });
  }
}

function* getOrderHistory(action) {
  try {
    if (!action.accountId) {
      return;
    }
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    let params = {
      accountId: action.accountId,
      size: 20,
    };
    if (action.page) {
      params = Object.assign({}, params, {page: action.page});
    }
    if (action.dateFrom) {
      params = Object.assign({}, params, {dateFrom: action.dateFrom});
    }
    if (action.dateTo) {
      params = Object.assign({}, params, {dateTo: action.dateTo});
    }

    const activeParams = {
      accountId: action.accountId,
      size: 200,
    };

    const invoicesFork = yield fork(orderApi.getInvoices, headers, params);

    const cancelledOrdersFork = yield fork(
      orderApi.getOrderHistory,
      headers,
      params,
      'cancelled'
    );

    const activeOrdersFork = yield fork(
      orderApi.getOrderHistory,
      headers,
      activeParams,
      'open'
    );

    const invoices = yield join(invoicesFork);
    const cancelledOrders = yield join(cancelledOrdersFork);
    const activeOrders = yield join(activeOrdersFork);

    if (invoices.error) {
      yield put({
        type: ActionTypes.GET_INVOICES_FAILED,
        message: invoices.error,
      });
    } else if (cancelledOrders.error) {
      yield put({
        type: ActionTypes.GET_ORDER_HISTORY_FAILED,
        message: invoices.error,
      });
    } else {
      yield put({
        type:
          action.type === ActionTypes.GET_ORDER_HISTORY_REQUESTED
            ? ActionTypes.GET_INVOICES_SUCCESS
            : ActionTypes.GET_MORE_INVOICES_SUCCESS,
        invoices,
        cancelledOrders,
      });
    }

    if (activeOrders.error) {
      yield put({
        type: ActionTypes.GET_ACTIVE_ORDERS_FAILED,
        message: activeOrders.error,
      });
    } else {
      yield put({
        type: ActionTypes.GET_ACTIVE_ORDERS_SUCCESS,
        activeOrders,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    }
    yield put({
      type: ActionTypes.GET_ORDER_HISTORY_FAILED,
      message: e.message,
    });
  }
}

function* cancelOrder(action) {
  try {
    if (!action.orderId) {
      return;
    }
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    const order = yield call(orderApi.cancelOrder, action.orderId, headers);
    yield put({type: ActionTypes.CANCEL_ORDER_SUCCEEDED, order});
  } catch (e) {
    yield put({
      type: ActionTypes.CANCEL_ORDER_FAILED,
      message: e.message,
    });
  }
}

function* trackOrder(action) {
  try {
    if (!action.axBasketId) {
      return;
    }
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const trackingData = yield call(
      orderApi.trackOrder,
      action.axBasketId,
      headers
    );
    yield put({type: ActionTypes.TRACK_ORDER_SUCCEEDED, trackingData});
  } catch (e) {
    yield put({
      type: ActionTypes.TRACK_ORDER_FAILED,
      message: e.message,
    });
  }
}

function* getInvoicedOrder(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const invoicedOrder = yield retry(
      MAX_ORDER_RETRY,
      GET_ORDER_RETRY_TIMEOUT,
      orderApi.getInvoicedOrder,
      action.salesId,
      action.accountId,
      headers
    );
    if (invoicedOrder) {
      try {
        if (isLessThanAWeekOldByDate(invoicedOrder.invoiceDateTime)) {
          invoicedOrder.couldBeRefunded = true;
        }
      } catch (e) {
        yield put({
          type: ActionTypes.GET_INVOICED_ORDER_FAILED,
          message: e.message,
        });
      }

      yield put({type: ActionTypes.GET_ORDER_SUCCEEDED, order: invoicedOrder});
    } else {
      yield put({type: ActionTypes.GET_INVOICED_ORDER_FAILED});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_INVOICED_ORDER_FAILED,
      message: e.message,
    });
  }
}

export default function* getOrderYield() {
  yield takeLatest(ActionTypes.GET_ORDER_REQUESTED, getOrder);
  yield takeLatest(
    ActionTypes.GET_ORDER_AFTER_CHECKOUT_REQUESTED,
    getFinalOrder
  );
  yield takeLatest(ActionTypes.CANCEL_ORDER_REQUESTED, cancelOrder);
  yield takeLatest(ActionTypes.GET_ORDER_HISTORY_REQUESTED, getOrderHistory);
  yield takeLatest(
    ActionTypes.GET_MORE_ORDER_HISTORY_REQUESTED,
    getOrderHistory
  );
  yield takeLatest(ActionTypes.TRACK_ORDER_REQUESTED, trackOrder);
  yield takeLatest(ActionTypes.REAUTH_ORDER_REQUESTED, reAuthOrderPayment);
  yield takeLatest(ActionTypes.GET_INVOICED_ORDER_REQUESTED, getInvoicedOrder);
}
