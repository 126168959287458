import {fork, put, takeEvery, select, join, all} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import {DEFAULT_BRANCH, OFFER_PARAMS} from '../constants/constants';
import offersApi from '../api/offers.api';
import {waitFor} from '../helpers/saga.helper';
import {getBearerHeader} from '../helpers/api.helper';
import {
  getDateParams,
  getValidUSDate,
  getValidDeliveryDate,
  processedGQLSearchResults,
} from '../helpers/data.helper';

function* offers(action) {
  try {
    const branch = action.branch || DEFAULT_BRANCH;
    const getAuth = state => state.auth;
    const getBasket = state => state.basket;
    const getSettings = state => state.settings;
    const storeToken = yield select(getAuth);
    let basket = yield select(getBasket);
    const settings = yield select(getSettings);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (storeToken && (!action.page || action.page === 0)) {
      const times = 6;
      const ms = 200;
      basket = yield waitFor(getBasket, times, ms);
    }
    let dateParams = {};
    if (basket && basket.fulfillAtSlot && basket.fulfillAtSlot.day) {
      dateParams = {
        deliveryDate: getValidUSDate(
          getValidDeliveryDate(basket.fulfillAtSlot.day)
        ),
      };
    } else {
      dateParams = getDateParams(basket, settings);
    }
    let params = {
      ...action.params,
      branch,
      ...dateParams,
      size: action.size || OFFER_PARAMS.size,
      sortType: action.sortType || OFFER_PARAMS.sortType,
    };
    if (action.page) {
      params = Object.assign({}, params, {page: parseInt(action.page, 10)});
    }
    if (params.page) {
      params.page = parseInt(params.page, 10);
    }
    if (params.category) {
      params.categoryIds = params.category;
      delete params['category'];
    }
    const offersFork = yield fork(offersApi.getOffersToday, params, headers);
    const offers = yield join(offersFork);
    if (!offers || offers.errors) {
      yield put({
        type: ActionTypes.GET_OFFERS_TODAY_FAILED,
        message: offers.errors[0],
      });
      return;
    }
    let offerType;
    if (action.type === ActionTypes.GET_OFFERS_HOMEPAGE_REQUESTED) {
      offerType = ActionTypes.GET_OFFERS_HOMEPAGE_SUCCESS;
    } else {
      offerType = action.page
        ? ActionTypes.GET_MORE_OFFERS_SUCCESS
        : ActionTypes.GET_OFFERS_TODAY_SUCCESS;
    }
    yield all([
      put({
        type: offerType,
        offers: {
          products:
            offers.data &&
            offers.data.productOffers &&
            offers.data.productOffers.results &&
            processedGQLSearchResults(offers.data.productOffers.results),
          page:
            offers.data &&
            offers.data.productOffers &&
            offers.data.productOffers.page,
        },
      }),
      put({
        type: ActionTypes.GET_OFFERS_AGGREGATE_SUCCESS,
        aggression:
          offers.data &&
          offers.data.productOffers &&
          offers.data.productOffers.facets,
      }),
      put({
        type: ActionTypes.GET_OFFERS_RESULTS_PAGE_SUCCESS,
        page:
          offers.data &&
          offers.data.productOffers &&
          offers.data.productOffers.page,
      }),
    ]);
  } catch (error) {
    yield put({
      type: ActionTypes.GET_OFFERS_TODAY_FAILED,
      error,
    });
  }
}

export default function* getOffersYield() {
  yield takeEvery(ActionTypes.GET_OFFERS_HOMEPAGE_REQUESTED, offers);
  yield takeEvery(ActionTypes.GET_OFFERS_TODAY_REQUESTED, offers);
  yield takeEvery(ActionTypes.GET_MORE_OFFERS_REQUESTED, offers);
}
