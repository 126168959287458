import {captureException} from '@sentry/browser';
import {setServerConfig} from '../config/configProvider';

export const getConfigManually = async () => {
  try {
    const response = await fetch('/api/olympus/info');
    const result = await response.json();
    if (result) {
      setServerConfig(result, false);
    }
  } catch (e) {
    captureException(e);
  }
};
