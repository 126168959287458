import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Wrapper} from './Thumbnail.style';
import ImageComponent from '../../components/Image/Image';
import {useThumbnail} from '../../helpers/image.helper';

export default class Thumbnail extends PureComponent {
  static propTypes = {
    media: PropTypes.object,
    callback: PropTypes.func.isRequired,
  };

  onThumbnailClick = () => this.props.callback(this.props.media);
  render() {
    const {media} = this.props;
    const url = useThumbnail(media.url);
    return (
      <Wrapper onClick={this.onThumbnailClick}>
        <ImageComponent data-src={url} url={url} />
      </Wrapper>
    );
  }
}
