import React, {PureComponent} from 'react';

import {defaultStyles} from '../../components/Modal/Modal.config';
import ModalWrapper from '../../components/Modal/Modal';
import {Tabs, Close, TabsWrapper} from '../../styles/pages/modal';
import {
  FormContainer,
  FormWrapper,
  Button,
  ItemsWrapper,
  ButtonsWrapper,
  StyledModalWrapper,
} from './EditItems.style';
import Product from '../../components/Product/Product';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import Loading from '../../components/Loading/Loading';
import {LoadingContainer} from '../../styles/components/layout';
import {ANALYTICS_SOURCES, UPSELLING_STEPS} from '../../constants/constants';
import UpsellingTab from './UpsellingTab';
import {getBasketItemsCodes} from '../../helpers/basket.helper';

export default class Upselling extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formStates: [
        {
          nextButton: 'Next',
          title: 'Your exclusive deals this week',
        },
        {
          nextButton: 'Next',
          title: 'Have you forgotten...',
        },
        {
          nextButton: 'Next',
          title: 'Did you see these deals?',
        },
        {
          nextButton: 'Checkout',
          title: 'Flash sales',
        },
      ],
      currentFormState: 0,
    };
  }

  componentDidMount() {
    this.getUpsellingItems();
  }

  componentDidUpdate(prevProps) {
    const {upsellingItems, loading} = this.props;
    const {currentFormState} = this.state;
    // We skip to next tab if the tabs content is empty
    if (
      loading &&
      !loading.upselling &&
      upsellingItems &&
      (!upsellingItems[UPSELLING_STEPS[currentFormState]] ||
        upsellingItems[UPSELLING_STEPS[currentFormState]].length === 0)
    ) {
      this.goToNextScreen();
    }
  }

  getUpsellingItems() {
    let itemIds;
    const {getUpsellingItems, basket} = this.props;
    if (basket && basket.items && basket.items.length > 0) {
      itemIds = getBasketItemsCodes(basket.items);
    }
    getUpsellingItems(itemIds, itemIds);
  }

  goToNextScreen = () => {
    const {loading} = this.props;
    if (loading && loading.addingToBasket) {
      return;
    }
    const {currentFormState} = this.state;
    if (currentFormState === this.state.formStates.length - 1) {
      const skipUpselling = true;
      this.props.goToCheckout(skipUpselling);
    } else {
      const newFormState = currentFormState + 1;
      this.setState({
        currentFormState: newFormState,
      });
    }
  };

  setFormState = newFormState => {
    this.setState({
      currentFormState: newFormState,
    });
  };

  render() {
    const {
      showModal,
      toggleModal,
      goToCheckout,
      upsellingItems,
      onProductClick,
      updateBasket,
      loading,
      branch,
      updateItem,
      removeItem,
      basketHashMap,
      deviceInfo,
      fulfillmentType,
    } = this.props;
    const {formStates, currentFormState} = this.state;
    const recommendedItems =
      upsellingItems &&
      UPSELLING_STEPS[currentFormState] &&
      upsellingItems[UPSELLING_STEPS[currentFormState]] &&
      syncBasketQuantityWithProducts(
        basketHashMap,
        upsellingItems[UPSELLING_STEPS[currentFormState]]
      );
    const renderItems =
      recommendedItems &&
      recommendedItems.map(item => (
        <Product
          key={item && item.itemId}
          product={item}
          onProductClick={onProductClick}
          updateBasket={updateBasket}
          loading={loading}
          branch={branch}
          basketHashMap={basketHashMap}
          updateItem={updateItem}
          removeItem={removeItem}
          deviceInfo={deviceInfo}
          fulfillmentType={fulfillmentType}
          source={`${ANALYTICS_SOURCES.RECOMMENDED} - ${formStates[currentFormState].title}`}
          isLimitedSpace={true}
        />
      ));
    const renderLoading = loading && loading.upselling && (
      <LoadingContainer>
        <Loading isLight={false} />
      </LoadingContainer>
    );
    const nextButtonTitle =
      loading &&
      !loading.addingToBasket &&
      formStates[currentFormState] &&
      formStates[currentFormState].nextButton;

    const tabs =
      upsellingItems &&
      formStates.map((tab, index) => {
        const isVisible =
          upsellingItems[UPSELLING_STEPS[index]] &&
          upsellingItems[UPSELLING_STEPS[index]].length > 0;
        return (
          <UpsellingTab
            isActive={index === currentFormState}
            key={index}
            index={index}
            title={tab.title}
            onClick={this.setFormState}
            isVisible={isVisible}
          />
        );
      });
    const renderLoadingBasket = loading && loading.addingToBasket && (
      <Loading isLight={false} />
    );
    const renderButtons = loading && !loading.upselling && (
      <ButtonsWrapper>
        <Button $left={true} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          $right={true}
          onClick={this.goToNextScreen}
          data-rw="recommended--next-screen"
        >
          {renderLoadingBasket}
          {nextButtonTitle}
        </Button>
      </ButtonsWrapper>
    );

    const renderTabs = loading && !loading.upselling && (
      <Tabs>
        <TabsWrapper>{tabs}</TabsWrapper>
        <Close onClick={toggleModal} />
      </Tabs>
    );

    return (
      <ModalWrapper
        customStyles={defaultStyles}
        callback={goToCheckout}
        isOpen={showModal}
        closeModal={toggleModal}
        data-rw="recommended--modal"
      >
        <StyledModalWrapper>
          {renderTabs}
          <FormContainer>
            <FormWrapper>
              <ItemsWrapper>
                {renderLoading}
                {renderItems}
              </ItemsWrapper>
            </FormWrapper>
            {renderButtons}
          </FormContainer>
        </StyledModalWrapper>
      </ModalWrapper>
    );
  }
}
