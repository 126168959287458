import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import {Wrapper} from './Filters.style';
import {buildOpenStatus} from '../../helpers/data.helper';

export default class Filters extends PureComponent {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    updateSearchParams: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    isCategorySearch: PropTypes.bool.isRequired,
    isCurrentSearch: PropTypes.bool,
    closedByDefault: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const {closedByDefault, filters, totalCategoryListCount} = props;
    this.state = {
      openStatus: buildOpenStatus(
        filters,
        closedByDefault,
        totalCategoryListCount
      ),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isCurrentSearch,
      closedByDefault,
      totalCategoryListCount,
      filters,
    } = this.props;
    if (
      !isCurrentSearch &&
      ((filters && filters !== prevProps.filters) ||
        totalCategoryListCount !== prevProps.totalCategoryListCount)
    ) {
      this.setState({
        openStatus: buildOpenStatus(
          filters,
          closedByDefault,
          totalCategoryListCount
        ),
      });
    }
  }

  toggleStatus = filterName => {
    const {openStatus} = this.state;
    const newOpenStatus = Object.assign({}, openStatus, {
      [filterName]: !openStatus[filterName],
    });
    this.setState({openStatus: newOpenStatus});
  };

  updateSearchParams = updatedParams =>
    this.props.updateSearchParams(updatedParams, true);

  render() {
    const {
      filters,
      appliedFilters,
      showInMobile,
      isCategorySearch,
      closedByDefault,
    } = this.props;
    const renderFilters =
      filters &&
      filters.map((filter, index) => (
        <Filter
          filter={filter}
          key={filter.name}
          filterCounts={filter && (filter.data || filter.counts)}
          isRoot={true}
          onFilterChange={this.updateSearchParams}
          isApplied={appliedFilters[filter.name]}
          index={index}
          isCategorySearch={isCategorySearch}
          closedByDefault={closedByDefault}
          openStatus={this.state.openStatus}
          toggleStatus={this.toggleStatus}
        />
      ));
    return <Wrapper $showInMobile={showInMobile}>{renderFilters}</Wrapper>;
  }
}
