import styled from 'styled-components';

export const BrochureLink = styled.a`
  width: 30rem;
  max-width: 30rem;
  height: 42.6rem;
  margin: 1rem 0;
  source,
  img {
    width: 30rem;
  }
`;

export const FailedMessage = styled.span`
  padding: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
`;
