import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function initReducer(
  state = initialState.loginRedirect,
  action
) {
  switch (action.type) {
    case types.SET_LOGIN_REDIRECT_SUCCESS:
      return action.loginRedirect;
    case types.RESET_LOGIN_REDIRECT_SUCCESS:
      return null;
    default:
      return state;
  }
}
