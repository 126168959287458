import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {InputButton, Button2} from '../../styles/components/buttons';
import storeLocator from '../../images/store_locator.jpg';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(${storeLocator});
  background-size: cover;
  width: 100%;
  min-height: 10rem;
  padding: 2.8rem;
  ${media.smaller`
    height: 12rem;
    background-size: cover;
    flex-direction: column;
    padding: 2rem;
  `};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  ${media.small`
    flex-direction: column;
  `};
  ${media.smaller`
    width: 100%;
    align-items: flex-start;
  `};
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  width: 20rem;
  min-width: 20rem;
  text-transform: uppercase;
  color: ${props => props.theme.primaryTextColor};
  display: flex;
  align-items: center;
  &::before {
    font-family: 'icomoon';
    content: '\\e911';
    display: inline-block;
    font-size: 3.2rem;
    padding-right: 2rem;
  }
  ${media.small`
    width: 19rem;
    min-width: 19rem;
    line-height: 2.8rem;
    align-items: flex-start;
  `};
  ${media.smaller`
    width: auto;
  `};
`;

export const Subtitle = styled.p`
  font-size: 1.4rem;
  padding: 0 2rem;
  color: ${props => props.theme.primaryTextColor};
  ${media.small`
    display: none;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 2.8rem;
  ${media.medium`
    margin: 0;
  `};
  ${media.smaller`
    width: 100%
  `};
`;

export const Locator = styled(InputButton)`
  width: 22rem;
  &:focus::placeholder {
    color: transparent;
  }
  ${media.smaller`
    width: 100%
  `};
`;

export const Go = styled(Button2)`
  width: 4rem;
  height: 4rem;
`;
