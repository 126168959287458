import {
  AX_BASKET_STATUS,
  ADYEN_ERROR,
  CHARGE_TYPES,
  CONTACT_FREE,
  ERRORS,
  JJ_LOCAL_STORAGE,
  LEAD_TIME,
  MESSAGES,
  NO_CONTACT_OPTION,
  OFFER_END_DATE_FULL_FORMAT,
  PAYMENT_TYPES,
  PROMO_TAGS,
  SERVICE_TYPE,
  SLOT_DATE_FORMAT,
  SLOT_FULFILMENT_STATUSES,
  SPECIAL_PRODUCT,
  STOCK_CHECK,
  UTC_TIME_FORMAT,
} from '../constants/constants';
import moment from 'moment';
import {
  checkHasCardPayment,
  checkHasPbbPayment,
  getCurrentUTCTime,
} from './data.helper';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
} from './localStorage.helper';
import {getProductStep} from './product.helper';
import SentryHelper from './sentry.helper';

export const replaceOosItemsBySub = (itemsGroup, substitutes, items) => {
  const outOfStock = itemsGroup.outOfStock.map(oos => {
    const sub = items.filter(
      item =>
        oos &&
        item.substitutions &&
        item.substitutions[0] &&
        item.substitutions[0] === oos.itemId
    );
    let replacementItem;
    if (sub) {
      if (sub.length > 1) {
        // filter the sub item against the basket items
        sub.forEach((s, i) =>
          itemsGroup.inStock.filter(inStock => {
            if (s && s.itemId === inStock.itemId) {
              sub.splice(i, 1);
            }
          })
        );
      }
      if (sub[0]) {
        replacementItem = sub[0];
      } else {
        return oos;
      }
      if (oos && substitutes && substitutes[oos.itemId]) {
        const subWithProduct = substitutes[oos.itemId].filter(
          sb => sb.itemId === sub[0].itemId
        );
        if (subWithProduct && subWithProduct[0]) {
          return Object.assign({}, replacementItem, {
            product: subWithProduct[0],
          });
        }
        return replacementItem;
      }
      return replacementItem;
    }
    return oos;
  });
  return Object.assign({}, itemsGroup, {outOfStock});
};

export const updateBasketAfterSubstitutions = (newBasket, basket) => {
  const items = newBasket.items.map(item => {
    const matchedItem = basket.items.filter(
      bi => item && bi.itemId === item.itemId
    );
    if (matchedItem && matchedItem[0]) {
      const {product} = matchedItem[0];
      return Object.assign({}, item, {product});
    }
    return item;
  });
  if (items && items.length > 0) {
    return Object.assign({}, newBasket, {items});
  }
  return newBasket;
};

export const getItemsNotInFirstArray = (first, second) => {
  let result = [];
  first.forEach(item => {
    second.forEach(sub => {
      if (sub.itemId !== item.itemId) {
        result.push(sub);
      }
    });
  });
  return result;
};

export const syncItemWithAX = (itemGroup, syncedItems, instockItem) => {
  let serviceItems = [];
  if (!syncedItems) {
    return itemGroup;
  }
  const syncedSpecialItems = getSpecialItems(syncedItems);
  const currentSpecialItems = getSpecialItems(itemGroup);
  if (
    instockItem &&
    itemGroup.length > 0 &&
    syncedSpecialItems.length > 0 &&
    currentSpecialItems.length === 0
  ) {
    serviceItems = getSpecialItems(syncedItems);
  }
  if (syncedSpecialItems.length > 0 && currentSpecialItems.length > 0) {
    serviceItems = getItemsNotInFirstArray(
      currentSpecialItems,
      syncedSpecialItems
    );
  }
  const updatedItemGroup = itemGroup.map(item => {
    const matchedItem = syncedItems.filter(
      bi => item && bi.itemId === item.itemId
    );
    if (matchedItem && matchedItem[0]) {
      const {
        priceIncVat,
        unitPriceIncVat,
        vat,
        quantity,
        stockCheck,
        unitPrice,
        price,
      } = matchedItem[0];
      return Object.assign({}, item, {
        priceIncVat,
        unitPriceIncVat,
        vat,
        quantity,
        stockCheck,
        unitPrice,
        price,
      });
    }
  });
  return [...serviceItems, ...updatedItemGroup].filter(
    item => item !== undefined
  );
};

export const syncBasketItems = (itemGroups, syncedItems) => {
  return {
    inStock: syncItemWithAX(
      (itemGroups && itemGroups.inStock) || [],
      syncedItems,
      true
    ),
    outOfStock: syncItemWithAX(
      (itemGroups && itemGroups.outOfStock) || [],
      syncedItems
    ),
  };
};

export const getOosItemsSize = items => {
  if (!items) {
    return 0;
  }
  return items.filter(
    item =>
      item &&
      item.stockCheck &&
      (item.stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        item.stockCheck.result === STOCK_CHECK.DISCONTINUED ||
        item.stockCheck.result === STOCK_CHECK.NOT_ALLOWED)
  ).length;
};

export const getBasketItemsCodes = items =>
  items
    .filter(item => item !== undefined)
    .map(item => item && item.itemId)
    .join(',');

const checkExpiresDate = (today, date) => {
  if (!date || date === '') {
    return false;
  }
  return today.diff(moment(date, OFFER_END_DATE_FULL_FORMAT), 'day') < 0;
};

export const calculateCardPromoTotal = items => {
  const today = moment().startOf('day');
  let totalCC = 0;
  let totalPBB = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (
      item.product &&
      item.product.offer &&
      checkExpiresDate(today, item.product.offer.promoEnd)
    ) {
      if (item.product.offer.promoTagId === PROMO_TAGS.ONLINE_CARD) {
        totalCC += item.product.offer.promoDisAmt * (item.quantity || 1);
      }
      if (item.product.offer.promoTagId === PROMO_TAGS.Pay_By_Bank) {
        totalPBB += item.product.offer.promoDisAmt * (item.quantity || 1);
      }
    }
  }
  return {onlineCard: totalCC, payByBank: totalPBB};
};

export const getBasketTotalItems = (items, isCheckoutPage) => {
  let total = 0;
  if (!Array.isArray(items)) {
    return total;
  }
  if (isCheckoutPage) {
    return items.length;
  }
  for (let i = 0; i < items.length; i++) {
    if (
      items &&
      items[i] &&
      items[i].stockCheck &&
      items[i].stockCheck.result !== STOCK_CHECK.OUT_OF_STOCK &&
      items[i].stockCheck.result !== STOCK_CHECK.DISCONTINUED &&
      items[i].stockCheck.result !== STOCK_CHECK.NOT_ALLOWED
    ) {
      total += 1;
    }
  }
  return total;
};

export const checkIfOnlyContainsOosItems = items => {
  let total = 0;
  if (!Array.isArray(items)) {
    return false;
  }
  for (let i = 0; i < items.length; i++) {
    if (
      items[i] &&
      items[i].stockCheck &&
      (items[i].stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        items[i].stockCheck.result === STOCK_CHECK.DISCONTINUED ||
        items[i].stockCheck.result === STOCK_CHECK.NOT_ALLOWED)
    ) {
      total += 1;
    }
  }

  return total === items.length && total !== 0;
};

export const getSlotTimestamp = (date, time) =>
  moment.utc(`${date}, ${time}`, UTC_TIME_FORMAT).valueOf();

export const checkSlotExpired = (
  basket,
  isGettingExpiring,
  showNearlyExpiredModal
) => {
  if (!basket || !basket.cutOffDateTime) {
    return false;
  }
  const currentUTC = moment.utc();
  const cutOffDateTime = moment.utc(basket.cutOffDateTime);
  if (currentUTC.isAfter(cutOffDateTime)) {
    return {slotExpired: true};
  }
  if (
    !showNearlyExpiredModal &&
    currentUTC.add(1, 'h').isAfter(cutOffDateTime) &&
    isGettingExpiring
  ) {
    return {
      slotNearlyExpired: true,
      slotTimestamp: cutOffDateTime.valueOf(),
    };
  }
  return null;
};

export const getSlotTimesLeft = (basket, order) => {
  const cutOffDateTimeString =
    (basket && basket.cutOffDateTime) || (order && order.cutOffDateTime);
  if (!cutOffDateTimeString) {
    return 0;
  }
  const cutOffDateTime = moment.utc(cutOffDateTimeString).valueOf();
  const now = getCurrentUTCTime();
  return cutOffDateTime - now - LEAD_TIME;
};

export const checkSlotTimeAvailability = (currentTime, times) => {
  if (!times && times.length === 0) {
    return null;
  }
  const result = times.some(
    time =>
      time.from === currentTime.from &&
      time.to === currentTime.to &&
      time.isAvailable
  );
  if (result) {
    return currentTime;
  }
  return times[0];
};

export const getSpecialItems = items =>
  items.filter(item => item.itemId.indexOf(SPECIAL_PRODUCT.SERVICE_ITEM) === 0);

export const getNormalItems = items =>
  items.filter(item => item.itemId.indexOf(SPECIAL_PRODUCT.SERVICE_ITEM) !== 0);

export const isServiceItem = item =>
  !!item && item.itemId.indexOf(SPECIAL_PRODUCT.SERVICE_ITEM) === 0;

export const getPreselectedDate = (dates, isDelivery) => {
  if (
    isDelivery &&
    dates[0] === moment().format(SLOT_DATE_FORMAT) &&
    dates[1]
  ) {
    return dates[1];
  }
  return dates[0];
};

export const isSameDayDelivery = (slot, fulfillmentType, fulfillmentStatus) =>
  slot.day === moment().format(SLOT_DATE_FORMAT) &&
  fulfillmentType === SERVICE_TYPE.DELIVERY &&
  fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT;

export const isCW2ChargeForTomorrow = slot =>
  slot &&
  slot.day ===
    moment(new Date())
      .add(1, 'day')
      .format(SLOT_DATE_FORMAT) &&
  slot.chargeType === CHARGE_TYPES.SDD;

export const isCW2Charge = (slot, fulfillmentType) =>
  isCW2ChargeForTomorrow(slot) && fulfillmentType === SERVICE_TYPE.DELIVERY;

export const updateDriverInstructions = (
  driverInstruction,
  noContactOption,
  deliveryContactNumber
) => {
  let finalDriverInstructions = '';

  if (driverInstruction) {
    finalDriverInstructions = driverInstruction;
  }
  if (noContactOption) {
    if (driverInstruction) {
      finalDriverInstructions = finalDriverInstructions + ' - ';
    }
    finalDriverInstructions =
      finalDriverInstructions + CONTACT_FREE.DELIVERY + NO_CONTACT_OPTION;
  }

  if (deliveryContactNumber) {
    finalDriverInstructions =
      deliveryContactNumber + ' - ' + finalDriverInstructions;
  }

  return finalDriverInstructions;
};

export const getProductItem = (product, quantity) => {
  if (!quantity) {
    SentryHelper.debugQuantityZero({
      message: `basket.helper.js getProductItem() - quantity: ${quantity} (${typeof quantity}), itemId: ${
        product.itemId
      }`,
    });
  }
  return {
    uuid: product.uuid,
    quantity: quantity || 1,
    itemId: product.itemId,
    step: getProductStep(product, true),
    pricesAvailable: {
      delivery: !!(product.delivery && product.delivery.price),
      collection: !!(product.collection && product.collection.price),
    },
    deliveryPrice:
      (product.delivery && product.delivery.price) || product.price,
    collectionPrice:
      (product.collection && product.collection.price) || product.price,
  };
};

export const isDefaultSlotItemWithOnlyOnePrice = (basket, item, settings) => {
  const settingsFulfillment =
    settings && settings.fulfillment && settings.fulfillment.toLowerCase();
  const hasOnlyOnePrice =
    !!item.pricesAvailable &&
    ((item.pricesAvailable.delivery && !item.pricesAvailable.collection) ||
      (!item.pricesAvailable.delivery && item.pricesAvailable.collection));
  const hasNoPriceSettings =
    !!item.pricesAvailable && !item.pricesAvailable[settingsFulfillment];

  return (
    ((item &&
      basket &&
      basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
      hasNoPriceSettings) ||
      (!basket && hasNoPriceSettings)) &&
    hasOnlyOnePrice
  );
};

export const getItemFulfillmentName = item =>
  item &&
  item.pricesAvailable &&
  (item.pricesAvailable.collection
    ? SERVICE_TYPE.COLLECTION
    : SERVICE_TYPE.DELIVERY);

export const noPriceForBookedSlotOnItem = (basket, item) => {
  const hasOnlyOnePrice =
    item.pricesAvailable &&
    ((item.pricesAvailable.delivery && !item.pricesAvailable.collection) ||
      (!item.pricesAvailable.delivery && item.pricesAvailable.collection));

  return (
    basket &&
    item &&
    hasOnlyOnePrice &&
    !item.pricesAvailable[basket.fulfillmentType.toLowerCase()]
  );
};

export const getEditOrderTime = cutOffDateTime => {
  const cutOffTime = moment(cutOffDateTime);
  const twoHoursFromNow = moment(new Date()).add(2, 'hours');
  if (twoHoursFromNow.diff(cutOffTime) > 0) {
    return cutOffTime;
  } else {
    return twoHoursFromNow;
  }
};

export const getNextSlotAfterNext = (slots, isDelivery) => {
  const keyOfDays = Object.keys(slots);
  let firstDay = getPreselectedDate(keyOfDays, isDelivery);
  const firstDayIndex = keyOfDays.findIndex(date => date === firstDay);
  const firstDaySlot = slots[firstDay];
  let selectedDate;
  let time;
  if (firstDaySlot.availability && firstDaySlot.availability.length === 1) {
    selectedDate = keyOfDays[firstDayIndex + 1];
    time = slots[selectedDate] && slots[selectedDate].availability[0];
  } else {
    time = slots[firstDay] && slots[firstDay].availability[1];
    selectedDate = firstDay;
  }
  const selectedTimesRange = slots[selectedDate];
  return {
    selectedTimesRange,
    time,
  };
};

export const shouldNavigateForBookSlot = (basket, selectANewSlot) =>
  !!(
    basket &&
    basket.fulfillAtSlot &&
    basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
    !selectANewSlot
  );

export const isCatchWeightItemAddedInCurrentSession = item => {
  const isCatchQty = item.step > 1 && item.quantity === item.step;
  if (!isCatchQty) {
    return true;
  }
  const catchWeightItems =
    getDataFromLocalStorage(JJ_LOCAL_STORAGE.CATCH_WEIGHT_ITEMS) || '{}';
  if (catchWeightItems[item.itemId]) {
    return true;
  }
  persistDataToLocalStorage(JJ_LOCAL_STORAGE.CATCH_WEIGHT_ITEMS, {
    ...catchWeightItems,
    [item.itemId]: true,
  });
  return false;
};

export const getPromoInfo = (promoTotal, paymentType, paymentOptions) => {
  if (
    !!promoTotal &&
    promoTotal.onlineCard === 0 &&
    promoTotal.payByBank === 0
  ) {
    return null;
  }
  if (!promoTotal) {
    return null;
  }
  const hasPbbPayment = checkHasPbbPayment(paymentOptions);
  const hasCardPayment = checkHasCardPayment(paymentOptions);
  const promoPriceCcText =
    !!promoTotal &&
    promoTotal.onlineCard &&
    `£${promoTotal.onlineCard.toFixed(2)}`;
  const promoPricePbbText =
    !!promoTotal &&
    promoTotal.payByBank &&
    `£${promoTotal.payByBank.toFixed(2)}`;
  if (paymentType === PAYMENT_TYPES.PBB.mode) {
    if (promoTotal.payByBank > 0) {
      return `Pay by bank payment saving ${promoPricePbbText}`;
    }
  } else if (paymentType === PAYMENT_TYPES.CDC.mode) {
    if (promoTotal.payByBank > 0 && hasPbbPayment) {
      return `Switch to PAY BY BANK to save ${promoPricePbbText}`;
    } else if (promoTotal.onlineCard > 0) {
      return `Online payment saving ${promoPriceCcText}`;
    }
  } else {
    if (promoTotal.payByBank > 0 && hasPbbPayment) {
      return `Switch to PAY BY BANK to save ${promoPricePbbText}`;
    } else if (promoTotal.onlineCard > 0 && hasCardPayment) {
      return `Switch to CARD to save ${promoPriceCcText}`;
    }
  }
  return null;
};

export const paymentErrorHandler = (isBraintree, errors, content) => {
  let message = isBraintree
    ? errors && errors[0] && errors[0].message
    : (content && content.message) || '';
  if (
    message === ERRORS.COMMERCIAL_CARD_ERROR ||
    message === ERRORS.CONSUMER_CARD_ERROR
  ) {
    message = 'Payment not accepted';
  }
  return message;
};

export const extraErrorMessageHandler = errors => {
  let message = '';
  if (errors) {
    const errorMsg = errors[0] && errors[0].message;
    if (
      errorMsg === ERRORS.COMMERCIAL_CARD_ERROR ||
      errors[ADYEN_ERROR.COMMERCIAL_CARD]
    ) {
      message = MESSAGES.COMMERCIAL_CARD_FAILED;
    }
    if (
      errorMsg === ERRORS.CONSUMER_CARD_ERROR ||
      errors[ADYEN_ERROR.CONSUMER_CARD]
    ) {
      message = MESSAGES.CONSUMER_CARD_FAILED;
    }
    if (errors[ADYEN_ERROR.REFUSED] || errors[ADYEN_ERROR.THREE_DS_FAILED]) {
      message =
        errors[ADYEN_ERROR.REFUSED] || errors[ADYEN_ERROR.THREE_DS_FAILED];
    }
  }
  return message;
};
export const isBasketSynced = status =>
  status && status === AX_BASKET_STATUS.SYNCED;
export const isBasketSyncFailed = status =>
  status &&
  (status === AX_BASKET_STATUS.FAILURE ||
    status === AX_BASKET_STATUS.ERROR ||
    status === AX_BASKET_STATUS.EXPIRED ||
    status === AX_BASKET_STATUS.PICKED);
