import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function driverInstructionReducer(
  state = initialState.driverInstruction,
  action
) {
  switch (action.type) {
    case types.SET_DRIVER_INSTRUCTION_SUCCESS:
      return action.driverInstruction;
    default:
      return state;
  }
}
