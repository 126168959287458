import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {CategoryStyled} from './Categories.style';

export default class Category extends PureComponent {
  static propTypes = {
    link: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
    nextLevel: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired,
  };

  state = {
    touched: false,
  };

  onCategoryClick = () => {
    const {hasSubCategory, nextLevel, callback, navigate, link} = this.props;
    if (hasSubCategory) {
      nextLevel();
    } else {
      navigate(link.query, {newSearch: true});
    }
    callback(this.props.position, link.name);
    this.setState({touched: true});
  };

  render() {
    const {link, isSelected, hasSubCategory} = this.props;
    const shouldBeSelected =
      (isSelected && this.state.touched) ||
      (isSelected && hasSubCategory && !this.state.touched);
    return (
      <CategoryStyled
        $isSelected={shouldBeSelected}
        $hasSubCategory={hasSubCategory}
        onClick={this.onCategoryClick}
      >
        {link.name}
      </CategoryStyled>
    );
  }
}
