import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    case types.GET_SEARCH_RESULTS_REQUESTED:
      return Object.assign({}, state, {searchResults: true});
    case types.GET_SEARCH_RESULTS_FAILED:
      return Object.assign({}, state, {searchResults: null});
    case types.GET_SEARCH_RESULTS_SUCCESS:
    case types.GET_SEARCH_RESULTS_PAGE_SUCCESS:
      return Object.assign({}, state, {searchResults: false});
    case types.GET_PRODUCT_DETAIL_REQUESTED:
      return Object.assign({}, state, {searchResults: true});
    case types.GET_PRODUCT_DETAIL_FAILED:
    case types.GET_PRODUCT_DETAIL_SUCCESS:
      return Object.assign({}, state, {searchResults: false});
    case types.UPDATE_BASKET_REQUESTED:
      return Object.assign({}, state, {addingToBasket: true});
    case types.UPDATE_BASKET_FAILED:
      return Object.assign({}, state, {
        addingToBasket: false,
        updatingBasket: false,
      });
    case types.ADD_TO_BASKET_FINISHED:
      return Object.assign({}, state, {addingToBasket: false});
    case types.SET_PAYMENT_TYPE_REQUESTED:
    case types.UPDATE_BASKET_ITEM_REQUESTED:
    case types.REMOVE_BASKET_ITEM_REQUESTED:
      return Object.assign({}, state, {updatingBasket: true});
    case types.UPDATE_BASKET_ITEM_SUCCEEDED:
    case types.REMOVE_BASKET_ITEM_SUCCEEDED:
    case types.SET_PAYMENT_TYPE_SUCCESS:
    case types.UPDATE_BASKET_ITEM_FAILED:
    case types.REMOVE_BASKET_ITEM_FAILED:
    case types.SET_PAYMENT_TYPE_FAILED:
    case types.UPDATE_BASKET_ITEM_CANCELLED:
      return Object.assign({}, state, {updatingBasket: false});
    case types.GET_BASKET_REQUESTED:
      return Object.assign({}, state, {gettingBasket: true});
    case types.GET_BASKET_SUCCEEDED:
    case types.GET_BASKET_FAILED:
      return Object.assign({}, state, {gettingBasket: false});
    case types.GET_PREVIOUSLY_PURCHASED_REQUESTED:
    case types.GET_FAVOURITES_REQUESTED:
      return Object.assign({}, state, {gettingProducts: true});
    case types.GET_PREVIOUSLY_PURCHASED_SUCCESS:
    case types.GET_PREVIOUSLY_PURCHASED_FAILED:
    case types.GET_FAVOURITES_SUCCESS:
    case types.GET_FAVOURITES_FAILED:
      return Object.assign({}, state, {gettingProducts: false});
    case types.GET_VACANCIES_REQUESTED:
      return Object.assign({}, state, {
        gettingVacancies: true,
      });
    case types.GET_VACANCIES_SUCCESS:
    case types.GET_VACANCIES_FAILED:
      return Object.assign({}, state, {
        gettingVacancies: false,
      });
    case types.SEARCH_ACCOUNTS_REQUESTED:
    case types.SET_ACCOUNTS_REQUESTED:
      return Object.assign({}, state, {accounts: true});
    case types.GET_ACCOUNTS_SUCCESS:
    case types.GET_ACCOUNTS_FAILED:
    case types.SET_ACCOUNTS_SUCCESS:
    case types.SET_ACCOUNTS_FAILED:
    case types.SEARCH_ACCOUNTS_FAILED:
      return Object.assign({}, state, {accounts: false});
    case types.GET_FULFILL_SLOT_REQUESTED:
    case types.DELETE_FULFILL_SLOT_REQUESTED:
      return Object.assign({}, state, {slot: true});
    case types.GET_FULFILL_SLOT_SUCCESS:
    case types.GET_FULFILL_SLOT_FAILED:
    case types.DELETE_FULFILL_SLOT_SUCCESS:
    case types.DELETE_FULFILL_SLOT_FAILED:
      return Object.assign({}, state, {slot: false});
    case types.GET_MARKETING_OPTIONS_REQUESTED:
      return Object.assign({}, state, {loadMarketing: true});
    case types.GET_MARKETING_OPTIONS_SUCCESS:
    case types.GET_MARKETING_OPTIONS_FAILED:
      return Object.assign({}, state, {loadMarketing: false});
    case types.UPDATE_MARKETING_OPTIONS_REQUESTED:
      return Object.assign({}, state, {updateMarketing: true});
    case types.UPDATE_MARKETING_OPTIONS_SUCCESS:
    case types.UPDATE_MARKETING_OPTIONS_FAILED:
      return Object.assign({}, state, {updateMarketing: false});
    case types.GET_OFFERS_HOMEPAGE_REQUESTED:
    case types.GET_OFFERS_TODAY_REQUESTED:
    case types.GET_MORE_OFFERS_REQUESTED:
      return Object.assign({}, state, {offers: true});
    case types.GET_OFFERS_HOMEPAGE_SUCCESS:
    case types.GET_OFFERS_HOMEPAGE_FAILED:
    case types.GET_OFFERS_TODAY_SUCCESS:
    case types.GET_OFFERS_TODAY_FAILED:
    case types.GET_MORE_OFFERS_SUCCESS:
    case types.GET_MORE_OFFERS_FAILED:
      return Object.assign({}, state, {offers: false});
    case types.GET_ORDER_HISTORY_REQUESTED:
      return Object.assign({}, state, {orderHistory: true});
    case types.GET_INVOICES_SUCCESS:
    case types.GET_ORDER_HISTORY_SUCCESS:
    case types.GET_ORDER_HISTORY_FAILED:
    case types.GET_ACTIVE_ORDERS_FAILED:
      return Object.assign({}, state, {orderHistory: false});
    case types.CANCEL_ORDER_REQUESTED:
      return Object.assign({}, state, {cancellingOrder: true});
    case types.CANCEL_ORDER_SUCCEEDED:
    case types.CANCEL_ORDER_FAILED:
      return Object.assign({}, state, {cancellingOrder: false});
    case types.GET_BROCHURES_REQUESTED:
      return Object.assign({}, state, {brochures: true});
    case types.GET_BROCHURES_SUCCESS:
    case types.GET_BROCHURES_FAILED:
      return Object.assign({}, state, {brochures: false});
    case types.GET_UPSELLING_ITEMS_REQUESTED:
      return Object.assign({}, state, {upselling: true});
    case types.GET_UPSELLING_ITEMS_FAILED:
    case types.GET_UPSELLING_ITEMS_SUCCESS:
      return Object.assign({}, state, {upselling: false});
    case types.GET_RECOMMENDED_ITEMS_REQUESTED:
      return Object.assign({}, state, {recommended: true});
    case types.GET_RECOMMENDED_ITEMS_FAILED:
    case types.GET_RECOMMENDED_ITEMS_SUCCESS:
      return Object.assign({}, state, {recommended: false});
    case types.UPDATE_SETTING_REQUESTED:
      return Object.assign({}, state, {settings: true});
    case types.UPDATE_SETTING_SUCCESS:
    case types.UPDATE_SETTING_FAILED:
      return Object.assign({}, state, {settings: false});
    case types.PROCESS_CHECKOUT_REQUESTED:
      return Object.assign({}, state, {
        loadingCheckout: true,
        processBraintree: false,
      });
    case types.PROCESS_CHECKOUT_FAILED:
    case types.PROCESS_CHECKOUT_SUCCESS:
    case types.PROCESS_CHECKOUT_VALIDATION_FAILED:
      return Object.assign({}, state, {loadingCheckout: false});
    case types.AUTHORIZE_BRAINTREE_PAYMENT_REQUESTED:
      return Object.assign({}, state, {processBraintree: true});
    case types.AUTHORIZE_BRAINTREE_PAYMENT_FAILED:
      return Object.assign({}, state, {processBraintree: false});
    case types.SET_SUBSTITUTE_ITEM_REQUESTED:
      return Object.assign({}, state, {loadingSubstitutes: true});
    case types.SET_SUBSTITUTE_ITEM_IN_BASKET_SUCCEEDED:
      return Object.assign({}, state, {loadingSubstitutes: false});
    case types.GET_CMS_BANNERS_REQUESTED:
      return Object.assign({}, state, {banners: true});
    case types.GET_CMS_BANNERS_FAILED:
    case types.GET_CMS_BANNERS_SUCCESS:
      return Object.assign({}, state, {banners: false});
    case types.GET_CMS_ABOUTUS_REQUESTED:
      return Object.assign({}, state, {aboutus: true});
    case types.GET_CMS_ABOUTUS_FAILED:
    case types.GET_CMS_ABOUTUS_SUCCESS:
      return Object.assign({}, state, {aboutus: false});
    case types.GET_CMS_CAREERS_REQUESTED:
      return Object.assign({}, state, {careers: true});
    case types.GET_CMS_CAREERS_FAILED:
    case types.GET_CMS_CAREERS_SUCCESS:
      return Object.assign({}, state, {careers: false});
    case types.GET_CMS_DELIVERIES_REQUESTED:
      return Object.assign({}, state, {deliveries: true});
    case types.GET_CMS_DELIVERIES_FAILED:
    case types.GET_CMS_DELIVERIES_SUCCESS:
      return Object.assign({}, state, {deliveries: false});
    case types.GET_CMS_BROCHURES_REQUESTED:
      return Object.assign({}, state, {brochures: true});
    case types.GET_CMS_BROCHURES_FAILED:
    case types.GET_CMS_BROCHURES_SUCCESS:
      return Object.assign({}, state, {brochures: false});
    case types.GET_CMS_VACANCIES_REQUESTED:
      return Object.assign({}, state, {vacancies: true});
    case types.GET_CMS_VACANCIES_FAILED:
    case types.GET_CMS_VACANCIES_SUCCESS:
      return Object.assign({}, state, {vacancies: false});
    case types.GET_CMS_DEPARTMENTS_REQUESTED:
      return Object.assign({}, state, {departments: true});
    case types.GET_CMS_DEPARTMENTS_FAILED:
    case types.GET_CMS_DEPARTMENTS_SUCCESS:
      return Object.assign({}, state, {departments: false});
    case types.GET_CMS_TEAM_REQUESTED:
      return Object.assign({}, state, {team: true});
    case types.GET_CMS_TEAM_FAILED:
    case types.GET_CMS_TEAM_SUCCESS:
      return Object.assign({}, state, {team: false});
    case types.ACTIVATE_ACCOUNT_REQUESTED:
      return Object.assign({}, state, {activateAccount: true});
    case types.ACTIVATE_ACCOUNT_FAILED:
    case types.ACTIVATE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {activateAccount: false});
    case types.TRACK_ORDER_REQUESTED:
      return Object.assign({}, state, {trackOrder: true});
    case types.TRACK_ORDER_FAILED:
    case types.TRACK_ORDER_SUCCEEDED:
      return Object.assign({}, state, {trackOrder: false});
    case types.GET_AUTH_REQUESTED:
      return Object.assign({}, state, {auth: true});
    case types.SET_AUTH_SUCCESS:
    case types.GET_AUTH_FAILED:
      return Object.assign({}, state, {auth: false});
    case types.GET_SEARCH_SUGGESTIONS_REQUESTED:
      return Object.assign({}, state, {searchSuggestions: true});
    case types.GET_SEARCH_SUGGESTIONS_SUCCESS:
    case types.GET_SEARCH_SUGGESTIONS_FAILED:
      return Object.assign({}, state, {searchSuggestions: false});
    case types.SET_FULFILL_SLOT_REQUESTED:
      return Object.assign({}, state, {setFulfillSlot: true});
    case types.SET_FULFILL_SLOT_SUCCESS:
    case types.SET_FULFILL_SLOT_FAILED:
      return Object.assign({}, state, {setFulfillSlot: false});
    case types.GET_NEAREST_BRANCH_REQUESTED:
      return Object.assign({}, state, {getNearestBranch: true});
    case types.GET_NEAREST_BRANCH_SUCCESS:
    case types.GET_NEAREST_BRANCH_FAILED:
      return Object.assign({}, state, {getNearestBranch: false});
    case types.GET_BRANCH_OPENING_TIMES_REQUESTED:
      return Object.assign({}, state, {getAllBranch: true});
    case types.GET_BRANCH_OPENING_TIMES_SUCCESS:
    case types.GET_BRANCH_OPENING_TIMES_FAILED:
      return Object.assign({}, state, {getAllBranch: false});
    case types.GET_CMS_POLICIES_REQUESTED:
      return Object.assign({}, state, {policies: true});
    case types.GET_CMS_POLICIES_FAILED:
    case types.GET_CMS_POLICIES_SUCCESS:
      return Object.assign({}, state, {policies: false});
    case types.GET_CMS_PBB_FAQ_REQUESTED:
      return Object.assign({}, state, {payByBankFAQ: true});
    case types.GET_CMS_PBB_FAQ_FAILED:
    case types.GET_CMS_PBB_FAQ_SUCCESS:
      return Object.assign({}, state, {payByBankFAQ: false});
    case types.GET_TOKEN_BY_PASSWORD_REQUESTED:
      return Object.assign({}, state, {relogin: true});
    case types.GET_TOKEN_BY_PASSWORD_SUCCESS:
    case types.GET_TOKEN_BY_PASSWORD_FAILED:
      return Object.assign({}, state, {relogin: false});
    case types.REFRESH_AX_ACCOUNT_REQUESTED:
      return Object.assign({}, state, {refreshAx: true});
    case types.REFRESH_AX_ACCOUNT_FAILED:
    case types.REFRESH_AX_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {refreshAx: false});
    case types.GET_ORDER_AFTER_CHECKOUT_REQUESTED:
    case types.GET_INVOICED_ORDER_REQUESTED:
      return Object.assign({}, state, {order: true});
    case types.GET_ORDER_SUCCEEDED:
    case types.GET_INVOICED_ORDER_FAILED:
    case types.GET_ORDER_AFTER_CHECKOUT_FAILED:
      return Object.assign({}, state, {order: false});
    case types.GET_ALL_LISTS_REQUESTED:
    case types.GET_ALL_LISTS_FAILED:
      return Object.assign({}, state, {getShoppingLists: true});
    case types.GET_ALL_LISTS_SUCCESS:
      return Object.assign({}, state, {getShoppingLists: false});
    case types.ADD_TO_SHOPPING_LIST_REQUESTED:
      return Object.assign({}, state, {updateShoppingList: true});
    case types.ADD_TO_SHOPPING_LIST_SUCCESS:
    case types.ADD_TO_SHOPPING_LIST_FAILED:
      return Object.assign({}, state, {updateShoppingList: false});
    case types.CREATE_A_SHOPPING_LIST_REQUESTED:
      return Object.assign({}, state, {createAShoppingList: true});
    case types.CREATE_A_SHOPPING_LIST_SUCCESS:
    case types.CREATE_A_SHOPPING_LIST_FAILED:
      return Object.assign({}, state, {createAShoppingList: false});
    case types.ADD_ALL_SHOPPING_LIST_ITEMS_REQUESTED:
      return Object.assign({}, state, {addAllShoppingList: true});
    case types.ADD_ALL_SHOPPING_LIST_ITEMS_SUCCESS:
    case types.ADD_ALL_SHOPPING_LIST_ITEMS_FAILED:
      return Object.assign({}, state, {addAllShoppingList: false});
    case types.RENAME_SHOPPING_LIST_REQUESTED:
      return Object.assign({}, state, {renameShoppingList: false});
    case types.RENAME_SHOPPING_LIST_SUCCESS:
    case types.RENAME_SHOPPING_LIST_FAILED:
      return Object.assign({}, state, {renameShoppingList: false});
    case types.GET_LISTS_BY_ID_REQUESTED:
      return Object.assign({}, state, {getShoppingListLookup: true});
    case types.GET_LISTS_BY_ID_SUCCESS:
    case types.GET_LISTS_BY_ID_FAILED:
      return Object.assign({}, state, {getShoppingListLookup: false});
    case types.GET_MORE_LISTS_BY_ID_REQUESTED:
      return Object.assign({}, state, {getMoreShoppingListLookup: true});
    case types.GET_MORE_LISTS_BY_ID_SUCCESS:
    case types.GET_MORE_LISTS_BY_ID_FAILED:
      return Object.assign({}, state, {getMoreShoppingListLookup: false});
    case types.REAUTH_ORDER_REQUESTED:
      return Object.assign({}, state, {orderReAuth: true});
    case types.REAUTH_ORDER_FAILED:
    case types.REAUTH_ORDER_SUCCEEDED:
      return Object.assign({}, state, {orderReAuth: false});
    case types.SEND_REFUND_EMAIL_REQUESTED:
      return Object.assign({}, state, {requestRefund: true});
    case types.SEND_REFUND_EMAIL_SUCCESS:
    case types.SEND_REFUND_EMAIL_FAILED:
    case types.SEND_REFUND_EMAIL_RESET:
      return Object.assign({}, state, {requestRefund: false});
    case types.GET_BLOG_POST_BY_TITLE_REQUESTED:
      return Object.assign({}, state, {blogPostsSearch: true});
    case types.GET_BLOG_POST_BY_TITLE_SUCCESS:
    case types.GET_BLOG_POST_BY_TITLE_FAILED:
      return Object.assign({}, state, {blogPostsSearch: false});
    default:
      return state;
  }
}
