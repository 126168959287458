import * as ActionTypes from './types.action';

export const getBanners = () => ({
  type: ActionTypes.GET_CMS_BANNERS_REQUESTED,
});

export const setBanner = banners => ({
  type: ActionTypes.GET_CMS_BANNERS_SUCCESS,
  banners,
});

export const getAboutUs = () => ({
  type: ActionTypes.GET_CMS_ABOUTUS_REQUESTED,
});

export const getCareers = () => ({
  type: ActionTypes.GET_CMS_CAREERS_REQUESTED,
});

export const getCustomers = () => ({
  type: ActionTypes.GET_CMS_CUSTOMERS_REQUESTED,
});

export const getBrochures = () => ({
  type: ActionTypes.GET_CMS_BROCHURES_REQUESTED,
});
export const getVacancies = () => ({
  type: ActionTypes.GET_CMS_VACANCIES_REQUESTED,
});

export const getCmsDepartments = () => ({
  type: ActionTypes.GET_CMS_DEPARTMENTS_REQUESTED,
});

export const getTeam = () => ({
  type: ActionTypes.GET_CMS_TEAM_REQUESTED,
});

export const getHelp = () => ({
  type: ActionTypes.GET_CMS_HELP_REQUESTED,
});

export const getPayByBankFAQ = () => ({
  type: ActionTypes.GET_CMS_PBB_FAQ_REQUESTED,
});

export const getPolicies = () => ({
  type: ActionTypes.GET_CMS_POLICIES_REQUESTED,
});

export const getDeliveries = () => ({
  type: ActionTypes.GET_CMS_DELIVERIES_REQUESTED,
});

export const getWhyJJ = () => ({
  type: ActionTypes.GET_CMS_WHYJJ_REQUESTED,
});

export const getSupplyUs = () => ({
  type: ActionTypes.GET_CMS_SUPPLYUS_REQUESTED,
});
