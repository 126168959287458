import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function routeReducer(state = initialState.route, action) {
  switch (action.type) {
    case types.SET_CURRENT_ROUTE_SUCCESS:
      return action.route;
    default:
      return state;
  }
}
