import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

export const LinkStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  color: ${p =>
    p.$disabled ? p.theme.tertiaryTextColor : p.theme.secondaryColor};
  background-color: transparent;
  border: ${p =>
    `solid 0.2rem ${
      p.$disabled ? p.theme.tertiaryTextColor : p.theme.secondaryColor
    }`};
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 2rem;
  height: 4rem;
  outline: none;

  &:hover {
    color: ${p =>
      p.$disabled ? p.theme.tertiaryTextColor : p.theme.primaryTextColor};
    background-color: ${p =>
      p.$disabled ? 'transparent' : p.theme.secondaryColor};
    border-color: ${p =>
      p.$disabled ? p.theme.tertiaryTextColor : p.theme.secondaryColor};
  }

  &[disabled] {
    pointer-events: none;
  }
`;

export const Link1 = styled(Link)`
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primaryColor};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const Link2 = styled(Link)`
  ${LinkStyle};
`;
