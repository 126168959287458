import * as ActionTypes from './types.action';

export const getShoppingList = () => ({
  type: ActionTypes.GET_ALL_LISTS_REQUESTED,
});

export const getShoppingListById = listId => ({
  type: ActionTypes.GET_LISTS_BY_ID_REQUESTED,
  listId,
});

export const getMoreShoppingListItems = (listId, page) => ({
  type: ActionTypes.GET_MORE_LISTS_BY_ID_REQUESTED,
  listId,
  page,
});

export const resetShoppingListLookup = () => ({
  type: ActionTypes.RESET_SHOPPING_LIST_SUCCESS,
});

export const createAShoppingList = name => ({
  type: ActionTypes.CREATE_A_SHOPPING_LIST_REQUESTED,
  name,
});

export const renameAShoppingList = (listId, updatedName) => ({
  type: ActionTypes.RENAME_SHOPPING_LIST_REQUESTED,
  listId,
  updatedName,
});

export const deleteAShoppingList = listId => ({
  type: ActionTypes.DELETE_SHOPPING_LIST_REQUESTED,
  listId,
});

export const addToShoppingList = (listId, itemId, quantity) => ({
  type: ActionTypes.ADD_TO_SHOPPING_LIST_REQUESTED,
  listId,
  itemId,
  quantity,
});

export const removeFromShoppingList = (listId, itemId, isLookupPage) => ({
  type: ActionTypes.REMOVE_FROM_SHOPPING_LIST_REQUESTED,
  listId,
  itemId,
  isLookupPage,
});
