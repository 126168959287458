import styled from 'styled-components';
import {Tab} from '../../styles/pages/modal';

export const TheTab = styled(Tab)`
  flex-grow: 1;
  width: 100%;
  background-color: ${p => p.theme.tertiaryColor};
`;

export const AcceptAllWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
`;
