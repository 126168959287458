import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {LinkButton} from '../../styles/pages/pages';

export const Container = styled.section`
  display: flex;
  background-color: ${props => props.theme.secondaryBgColor};
  margin-top: 2rem;
  padding: 1rem;
  .infinite-scroll-component {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  ${media.smaller`
    display: block;
    padding: 0;
  `};
`;

export const FilterButtonWrapper = styled.div`
  display: none;
  background-color: ${p => p.theme.secondaryBgColor};
  ${media.smaller`
    padding: 1rem 0;
    margin: 0 1rem;
    display: block;
  `};
`;

export const NoOffers = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  color: ${p => p.theme.textColor};
  font-size: 1.6rem;
  padding: 2rem;
  text-align: center;
`;

export const OrderLinkButton = styled(LinkButton)`
  ${media.small`
    max-width: 100%;
    padding: 1rem;
  `};
`;

export const FilterTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
  color: ${props => props.theme.textColor};
  margin: 1rem 0 0 1rem;
`;
