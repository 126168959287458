import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {getTimestamp} from '../helpers/api.helper';

export default class BasketApi {
  static getBasket(headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets`;
    return axios.get(url, {headers}).then(res => res.data);
  }

  static getBasketWithProductDetails(headers) {
    const config = getApiConfig();
    const url = `${config.basketAggregatorUrl}`;
    return axios.get(url, {headers}).then(res => res.data);
  }
  static updateBasket(isUpdating, data, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets${
      isUpdating ? '/items_multi' : ''
    }`;
    return axios.post(url, data, {headers}).then(res => res.data);
  }

  static setSubstitutes(data, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/items`;
    return axios.patch(url, data, {headers}).then(res => res.data);
  }

  static updateBasketItem(itemUuid, data, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/items/${itemUuid}`;
    return axios.put(url, data, {headers}).then(res => res && res.data);
  }

  static removeBasketItem(itemUuid, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/items/${itemUuid}`;
    return axios.delete(url, {headers}).then(res => res.data);
  }

  static setConsumerInfo(data) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/consumer`;
    return axios.put(url, data);
  }

  static getFulfillSlot(data, fulfillmentUrl, headers) {
    const config = getApiConfig();
    const url = `${config.slotUrl}/${fulfillmentUrl}?${data}${getTimestamp(
      true
    )}`;
    return axios.get(url, {headers}).then(res => res.data);
  }

  static setFulfillSlot(data, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/fulfill-at-slot`;
    return axios.put(url, data, {headers}).then(res => res.data);
  }

  static deleteFulfillSlot(headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/fulfill-at-slot`;
    return axios.delete(url, {headers}).then(res => res.data);
  }

  static setPaymentType(data, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/payment`;
    return axios.put(url, data, {headers}).then(res => res.data);
  }

  static setBasketInfo(data) {
    return axios
      .all([
        BasketApi.setConsumerInfo(data.consumer),
        BasketApi.setPaymentType(data.payment),
      ])
      .then(result => console.log(result));
  }

  static setFulfillment(fulfillment, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/fulfillment`;
    return axios.put(url, fulfillment, {headers}).then(res => res.data);
  }

  static reOrderBasket(uuid, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${uuid}/reorder`;
    return axios.post(url, {}, {headers}).then(res => res.data);
  }
  static addVoucher(code, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/vouchers`;
    const data = {code};
    return axios
      .post(url, data, {headers})
      .then(res => res.data)
      .catch(res => res.response.data);
  }

  static removeVoucher(headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/vouchers`;
    return axios
      .delete(url, {headers})
      .then(res => res.data)
      .catch(res => res.response.data);
  }

  static editOrder(orderId, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${orderId}/edit`;
    return axios.put(url, {}, {headers}).then(res => res.data);
  }

  static deleteAxBasket(headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets`;
    return axios.delete(url, {headers}).then(res => res.data);
  }

  static async addAllShoppingListItems(listId, headers) {
    const config = getApiConfig();
    const url = `${config.basketUrl}/baskets/shopping_list/${listId}`;
    return axios.put(url, {}, {headers}).then(res => res.data);
  }
}
