import styled from 'styled-components';

export const ToolTips = styled.div`
  z-index: 99;
  font-size: 1.1rem;
  position: absolute;
  padding: 0.3rem 0.6rem;
  left: -140%;
  top: 120%;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  user-select: none;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p => p.theme.dimmerBlack};
  display: ${p => (p.$show ? 'block' : 'none')};

  &::before {
    display: inline;
    content: '';
    position: absolute;
    top: -20%;
    left: 40%;
    border: solid 0.4rem transparent;
    border-bottom-color: ${p => p.theme.dimmerBlack};
    opacity: 0.85;
  }
`;
