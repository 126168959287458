import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Markdown from 'markdown-to-jsx';
import {bindActionCreators} from 'redux';

import ImageComponent from '../../components/Image/Image';
import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getWhyJJ} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class WhyJJPage extends PureComponent {
  componentDidMount() {
    this.props.getWhyJJ();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.whyJJ &&
      (prevProps.cms && prevProps.cms.whyJJ) !== cms.whyJJ
    ) {
      setCmsHeadTags(cms.whyJJ);
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const whyJJ = cms && cms.whyJJ;
    const renderMarkdown = whyJJ && whyJJ.content && (
      <div className="columns-1">
        <Markdown>{markdownConvert(whyJJ.content)}</Markdown>
      </div>
    );
    const renderMedia = whyJJ && whyJJ.media && (
      <ImageComponent url={whyJJ.media} alt={whyJJ.heading} />
    );
    const renderLoading = loading && loading.whyJJ && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.whyJJ && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <div className="tac">
          <h1 className="tac">{whyJJ && whyJJ.heading}</h1>
          <p className="tac w70p">{whyJJ && whyJJ.pageTitle}</p>
          {renderLoading}
          {renderError}
          <SectionWrapper>{renderMarkdown}</SectionWrapper>
          {renderMedia}
        </div>
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getWhyJJ: bindActionCreators(getWhyJJ, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhyJJPage);
