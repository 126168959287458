import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class PbbBankDetailsApi {
  static async getBankDetailsById(bankId) {
    const config = getApiConfig();
    const url = `${config.bankDetails}/${bankId}`;
    const res = await axios.get(url);
    return (res && res.data) || {};
  }
}
