import {put, call, takeLatest, select} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import productApi from '../api/product.api';
import {getBearerHeader} from '../helpers/api.helper';
import {processedGQLSearchResults} from '../helpers/data.helper';

function* getPreviouslyPurchased(action) {
  const getAuth = state => state.auth;
  const storeToken = yield select(getAuth);
  const headers = getBearerHeader(action.jjToken, storeToken);
  let params = action.params;
  if (action.page) {
    params = Object.assign({}, params, {page: action.page});
  }
  try {
    const result = yield call(
      productApi.getPreviouslyPurchased,
      params,
      headers
    );
    if (!result || result.errors) {
      yield put({
        type: ActionTypes.GET_PREVIOUSLY_PURCHASED_FAILED,
        message: result.errors[0],
      });
      return;
    }
    yield put({
      type: ActionTypes.GET_PREVIOUSLY_PURCHASED_SUCCESS,
      previouslyPurchased:
        result &&
        result.data &&
        result.data.previouslyPurchasedProducts &&
        result.data.previouslyPurchasedProducts.results &&
        processedGQLSearchResults(
          result.data.previouslyPurchasedProducts.results
        ),
      page:
        result &&
        result.data &&
        result.data.previouslyPurchasedProducts &&
        result.data.previouslyPurchasedProducts.page,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_PREVIOUSLY_PURCHASED_FAILED,
      message: e.message,
    });
  }
}
export default function* previouslyPurchasedYield() {
  yield takeLatest(
    ActionTypes.GET_PREVIOUSLY_PURCHASED_REQUESTED,
    getPreviouslyPurchased
  );
}
