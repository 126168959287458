import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function prerenderReducer(
  state = initialState.prerender,
  action
) {
  switch (action.type) {
    case types.GET_SEARCH_RESULTS_SUCCESS:
    case types.GET_SEARCH_RESULTS_PAGE_SUCCESS:
    case types.GET_OFFERS_RESULTS_PAGE_SUCCESS:
    case types.GET_NEAREST_BRANCH_SUCCESS:
    case types.GET_PRODUCT_DETAIL_SUCCESS:
    case types.GET_BLOG_POST_BY_SLUG_SUCCESS:
    case types.GET_BLOG_POST_BY_ID_SUCCESS:
    case types.GET_JJ_CONTENT_BY_SLUG_SUCCESS: {
      if (typeof window !== 'undefined') {
        window.prerenderReady = true;
      }
      return state;
    }
    default:
      return state;
  }
}
