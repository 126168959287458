import * as ActionTypes from './types.action';
import {TOGGLE_CATCH_WEIGHT_MODAL_SUCCESS} from './types.action';

export const toggleSwitchAccount = status => ({
  type: ActionTypes.TOGGLE_SWITCH_ACCOUNT_MODAL_SUCCESS,
  status,
});

export const toggleUpsellingModal = status => ({
  type: ActionTypes.TOGGLE_UPSELLING_MODAL_SUCCESS,
  status,
});

export const toggleChangeBranch = status => ({
  type: ActionTypes.TOGGLE_CHANGE_BRANCH_MODAL_SUCCESS,
  status,
});

export const toggleOpenInJJsModal = status => ({
  type: ActionTypes.TOGGLE_OPEN_IN_JJS_MODAL_SUCCESS,
  status,
});

export const toggleMaintenanceModal = status => ({
  type: ActionTypes.TOGGLE_MAINTENANCE_MODAL_SUCCESS,
  status,
});

export const toggleSubstitutesModal = status => ({
  type: ActionTypes.TOGGLE_SUBSTITUTES_MODAL_SUCCESS,
  status,
});

export const toggleStopEditModal = status => ({
  type: ActionTypes.TOGGLE_STOP_EDIT_MODAL_SUCCESS,
  status,
});

export const toggleAvailableOnlyModal = (itemData, status) => ({
  type: ActionTypes.TOGGLE_AVAILABLE_ONLY_MODAL_SUCCESS,
  itemData,
  status,
});

export const toggleCatchWeightModal = status => ({
  type: ActionTypes.TOGGLE_CATCH_WEIGHT_MODAL_SUCCESS,
  status,
});
export const toggleBasketSyncFailedModal = status => ({
  type: ActionTypes.TOGGLE_BASKET_SYNC_FAILED_MODAL_SUCCESS,
  status,
});

export const toggleReloginModel = status => ({
  type: ActionTypes.TOGGLE_RELOGIN_MODAL_SUCCESS,
  status,
});

export const toggleCustomerSupportModal = status => ({
  type: ActionTypes.TOGGLE_CUSTOMER_SUPPORT_MODAL_SUCCESS,
  status,
});

export const togglePaymentDetails = status => ({
  type: ActionTypes.TOGGLE_PAYMENT_DETAILS_MODAL_SUCCESS,
  status,
});

export const toggleRatingModal = status => ({
  type: ActionTypes.TOGGLE_RATING_MODAL_SUCCESS,
  status,
});
