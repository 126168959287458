import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class SubstitutesApi {
  static getSubstitutes(groupIds, ids, params, headers) {
    const config = getApiConfig();
    const url = `${config.apiUrl}/product/searchbyalternativegroupid/${groupIds}/${ids}`;
    return axios.get(url, {params, headers}).then(res => res.data);
  }
}
