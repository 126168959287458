import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function previouslyPurchasedReducer(
  state = initialState.previouslyPurchased,
  action
) {
  switch (action.type) {
    case types.GET_PREVIOUSLY_PURCHASED_SUCCESS:
      return {
        page: {...(state && state.page), ...action.page},
        _embedded: {
          products:
            action.page.number > 0 &&
            state &&
            state._embedded &&
            state._embedded.products &&
            state._embedded.products.length > 0
              ? [
                  ...((state && state._embedded && state._embedded.products) ||
                    []),
                  ...action.previouslyPurchased,
                ]
              : action.previouslyPurchased,
        },
      };
    case types.GET_PREVIOUSLY_PURCHASED_REQUESTED:
      return action.params.page.number === 0 ? null : state;
    case types.GET_PREVIOUSLY_PURCHASED_FAILED:
    case types.RESET_SETTING_SUCCESS:
      return null;
    default:
      return state;
  }
}
