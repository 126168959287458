import axios from 'axios';
import {DEFAULT_BRANCH} from '../constants/constants';
import {getApiConfig} from '../config/configProvider';

export default class initApi {
  static getInitData(params) {
    const config = getApiConfig();
    const branchUrl =
      !!params.branch && params.branch !== DEFAULT_BRANCH
        ? `_${params.branch}`
        : '';
    const url = `${config.fastlyAssets}/category/category_tree${branchUrl}.json`;
    return axios.get(url).then(res => res.data);
  }
  static getSystemMessages() {
    const config = getApiConfig();
    const url = `${config.fastlyAssets}/ordering-messages/announcement.json`;
    return axios.get(url).then(res => res.data);
  }
}
