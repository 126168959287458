import {put, call, takeLatest, select} from 'redux-saga/effects';
import basketApi from '../api/basket.api';
import queryString from 'query-string';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {
  ERRORS,
  JJ_LOCAL_STORAGE,
  PAYMENT_TYPES,
  SERVICE_TYPE,
  SLOT_FULFILMENT_STATUSES,
} from '../constants/constants';
import {persistDataToLocalStorage} from '../helpers/localStorage.helper';
import {arrayToObject} from '../helpers/array.helper';
import {isCW2Charge, isSameDayDelivery} from '../helpers/basket.helper';

function* getFulfillSlot(action) {
  try {
    const getAuth = state => state.auth;
    const getBranch = state => state.branch;
    const getSettings = state => state.settings;
    const getProfile = state => state.profile;
    const storeToken = yield select(getAuth);
    const branch = yield select(getBranch);
    const settings = yield select(getSettings);
    const profile = yield select(getProfile);
    const headers = getBearerHeader(null, storeToken);
    let fulfillmentUrl;
    switch (action.fulfillmentType) {
      case SERVICE_TYPE.DELIVERY:
        fulfillmentUrl = 'delivery/slots2';
        break;
      case SERVICE_TYPE.COLLECTION:
        fulfillmentUrl = 'collection/slots';
        break;
      default:
        fulfillmentUrl = 'delivery/slots2';
    }
    const data = {
      branchId:
        action.branchId ||
        (settings && settings.branchId) ||
        (profile && profile.branchId) ||
        branch,
      accountId: storeToken.c_account,
    };
    const result = yield call(
      basketApi.getFulfillSlot,
      queryString.stringify(data),
      fulfillmentUrl,
      headers
    );
    if (result) {
      const slots = arrayToObject(result, 'date');
      if (result.length === 0) {
        yield put({
          type: ActionTypes.GET_EMPTY_FULFILL_SLOT,
          slots: [],
        });
      } else {
        yield put({
          type: ActionTypes.GET_FULFILL_SLOT_SUCCESS,
          slots,
        });
      }
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    yield put({type: ActionTypes.GET_FULFILL_SLOT_FAILED, message: e.message});
  }
}

function* setFulfillSlot(action) {
  try {
    const getAuth = state => state.auth;
    const getBasket = state => state.basket;
    const getSettings = state => state.settings;
    const getProfile = state => state.profile;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(null, storeToken);
    const storeBasket = yield select(getBasket);
    let settings = yield select(getSettings);
    const profile = yield select(getProfile);
    let paymentMethod =
      (storeBasket &&
        storeBasket.fulfillmentStatus !==
          SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT &&
        storeBasket.paymentMethod) ||
      (settings &&
        settings.paymentType &&
        PAYMENT_TYPES[settings.paymentType] &&
        PAYMENT_TYPES[settings.paymentType].mode) ||
      PAYMENT_TYPES.CDC.mode;
    if (
      profile &&
      profile.paymentOptions &&
      profile.paymentOptions.length === 1 &&
      profile.paymentOptions[0].mode
    ) {
      paymentMethod = profile.paymentOptions[0].mode;
    }
    if (
      (paymentMethod !== PAYMENT_TYPES.CDC.mode ||
        paymentMethod !== PAYMENT_TYPES.BCDC.mode) &&
      action.slot &&
      (isSameDayDelivery(action.slot, action.fulfillmentType) ||
        isCW2Charge(action.slot, action.fulfillmentType))
    ) {
      paymentMethod = PAYMENT_TYPES.CDC.mode;
    }
    const {dateTimeToCutOff, ...slotFulfill} = action.slot;
    const result = yield call(
      basketApi.setFulfillSlot,
      {
        fulfillAtSlot: slotFulfill,
        branchId: action.branchId,
        fulfillmentType: action.fulfillmentType,
        slotReference: action.slotReference,
        driverInstruction: action.driverInstruction,
        paymentMethod,
      },
      headers
    );
    if (result) {
      yield put({
        type: ActionTypes.SET_FULFILL_SLOT_SUCCESS,
        basket: result,
      });
      const fulfillment = {
        date: result.fulfillAtSlot && result.fulfillAtSlot.day,
        fulfillmentType: result.fulfillmentType,
      };
      yield put({
        type: ActionTypes.SET_FULFILLMENT_DETAILS_SUCCESS,
        fulfillmentDetails: fulfillment,
      });
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.FULFILLMENT, fulfillment);
      if (action.goCheckout) {
        if (
          storeBasket &&
          storeBasket.items &&
          storeBasket.items.length === 0
        ) {
          yield put({type: ActionTypes.BASKET_NO_ITEMS_ERROR});
        }
      }
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.SET_FULFILL_SLOT_FAILED,
        message: e.message,
      });
    }
  }
}

function* deleteFulfillSlot() {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(null, storeToken);
    const basket = yield call(basketApi.deleteFulfillSlot, headers);
    if (basket) {
      yield put({
        type: ActionTypes.DELETE_FULFILL_SLOT_SUCCESS,
        basket,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    yield put({
      type: ActionTypes.DELETE_FULFILL_SLOT_FAILED,
      message: e.message,
    });
  }
}

export default function* getFulfillSlotYield() {
  yield takeLatest(ActionTypes.GET_FULFILL_SLOT_REQUESTED, getFulfillSlot);
  yield takeLatest(ActionTypes.SET_FULFILL_SLOT_REQUESTED, setFulfillSlot);
  yield takeLatest(
    ActionTypes.DELETE_FULFILL_SLOT_REQUESTED,
    deleteFulfillSlot
  );
}
