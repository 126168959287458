import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function favouritesReducer(
  state = initialState.favourites,
  action
) {
  switch (action.type) {
    case types.ADD_TO_FAVOURITES_SUCCESS:
    case types.REMOVE_FROM_FAVOURITES_SUCCESS:
      return Object.assign({}, state, action.favourites);
    case types.GET_FAVOURITES_SUCCESS:
      return {
        page: {...(state && state.page), ...action.page},
        _embedded: {
          products:
            action.page.number > 0 &&
            state &&
            state._embedded &&
            state._embedded.products &&
            state._embedded.products.length > 0
              ? [
                  ...((state && state._embedded && state._embedded.products) ||
                    []),
                  ...action.favourites,
                ]
              : action.favourites,
        },
      };
    case types.REMOVE_AUTH_REQUESTED:
    case types.RESET_SETTING_SUCCESS:
      return null;
    default:
      return state;
  }
}
