export const SEARCH_SUGGESTIONS_DEFAULT_KEYWORDS = [
  'FAVOURITES',
  'RECENT PURCHASES',
];
export const SEARCH_SUGGESTIONS_DEFAULT_LINKS = [
  {
    searchField: 'FAVOURITES',
    type: 'url',
    urlParams: '/favourites',
  },
  {
    searchField: 'RECENT PURCHASES',
    type: 'url',
    urlParams: '/recentpurchases',
  },
];

export const SEARCH_SUGGESTIONS_DEFAULT_SUGGESTIONS = [
  {
    searchField: 'halal',
    type: 'keyword',
    urlParams: '?q=halal',
  },
  {
    searchField: 'chicken',
    type: 'keyword',
    urlParams: '?q=chicken',
  },
  {
    searchField: 'beef',
    type: 'keyword',
    urlParams: '?q=beef',
  },
  {
    searchField: 'fresh tomato',
    type: 'keyword',
    urlParams: '?q=fresh tomato',
  },
  {
    searchField: 'chips',
    type: 'keyword',
    urlParams: '?q=chips',
  },
  {
    searchField: 'drinks',
    type: 'keyword',
    urlParams: '?q=drinks',
  },
  {
    searchField: 'oil',
    type: 'keyword',
    urlParams: '?q=oil',
  },
  {
    searchField: 'water',
    type: 'keyword',
    urlParams: '?q=water',
  },
  {
    searchField: 'veg',
    type: 'keyword',
    urlParams: '?q=veg',
  },
  {
    searchField: 'cheese',
    type: 'keyword',
    urlParams: '?q=cheese',
  },
  {
    searchField: 'burger',
    type: 'keyword',
    urlParams: '?q=burger',
  },
  {
    searchField: 'coke',
    type: 'keyword',
    urlParams: '?q=coke',
  },
  {
    searchField: 'box',
    type: 'keyword',
    urlParams: '?q=box',
  },
  {
    searchField: 'drink',
    type: 'keyword',
    urlParams: '?q=drink',
  },
  {
    searchField: 'vegan',
    type: 'keyword',
    urlParams: '?q=vegan',
  },
  {
    searchField: 'fish',
    type: 'keyword',
    urlParams: '?q=fish',
  },
  {
    searchField: 'cod',
    type: 'keyword',
    urlParams: '?q=cod',
  },
  {
    searchField: 'pizza',
    type: 'keyword',
    urlParams: '?q=pizza',
  },
  {
    searchField: 'sauce',
    type: 'keyword',
    urlParams: '?q=sauce',
  },
  {
    searchField: 'packaging',
    type: 'keyword',
    urlParams: '?q=packaging',
  },
  {
    searchField: 'tomato',
    type: 'keyword',
    urlParams: '?q=tomato',
  },
];

export const SAVE_TO_SETTINGS_NUMBER = 3;
