import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {GQL_FREQUENTLY_BOUGHT} from '../gql/frequentlybought.gql';

export default class RecommendedApi {
  static get(params, fullList, headers) {
    const config = getApiConfig();
    if (!fullList) {
      params.size = 4;
    }
    return axios({
      url: params.host || config.graphQL,
      method: 'post',
      data: {
        query: GQL_FREQUENTLY_BOUGHT,
        variables: params,
      },
      headers,
    }).then(res => res.data);
  }
}
