import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3} from '../../styles/components/buttons';
import {Link} from 'react-router-dom';

export const Container = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  padding: 2rem 2rem 0 2rem;
  color: ${props => props.theme.textColor};
`;

export const MapContainer = styled.div`
  margin: 2rem 0 0;
  height: ${p => (p.$notFound ? '11rem' : '35rem')};
  min-height: ${p => (p.$notFound ? '11rem' : '35rem')};
  &:empty {
    min-height: 0;
    height: 0;
  }
  ${media.small`
    height: 46rem;
    min-height: 46rem;
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  font-size: 1.4rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  padding: 4rem;
  ${media.small`
    flex-direction: column;
  `};
`;

export const BranchContainer = styled(Container)`
  padding: 2rem;
`;

export const BranchList = styled(Wrapper)`
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 50%;
  ${media.small`
    padding-bottom: 2rem;
  `};
`;

export const NearbyBranch = styled.div`
  color: ${p => p.theme.secondaryColor};
  font-size: 1.6rem;
  line-height: 2.8rem;
`;

export const ContactTitle = styled(NearbyBranch)`
  margin-top: ${p => (p.$gap ? '1rem' : 0)};
  color: ${p => p.theme.primaryColor};
`;

export const Address = styled(NearbyBranch)`
  color: ${p => p.theme.textColor};
`;

export const UPPERCASE = styled.span`
  text-transform: uppercase;
`;

export const ContactLink = styled.a`
  display: block;
  text-transform: lowercase;
  color: ${p => p.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.8rem;
`;

export const InfoLinkWrapper = styled.div`
  ${media.small`
    padding: 2rem 0;
  `};
`;

export const InfoLink = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  outline: none;
  display: block;
  font-weight: bold;
  line-height: 2.4rem;
  cursor: pointer;
`;

export const Details = styled.div`
  flex-basis: 50%;
  height: 100%;
`;

export const InfoTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: ${p => p.theme.primaryColor};
  margin-bottom: 2rem;
`;

export const MinOrder = styled.span`
  ${media.small`
     font-size: 1.2rem;
  `};
`;
export const Min = styled.span`
  ${media.small`
     display: none;
  `};
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${p => p.theme.textColor};
  line-height: 2.4rem;
  margin: 1rem 0;
`;

export const DeliveryContent = styled(ContentText)`
  flex-direction: column;
  font-size: 1.6rem;
`;

export const Day = styled.div`
  width: 22%;
  ${media.small`
     font-size: 1.2rem;
  `};
`;

export const Date = styled.div`
  width: 30%;
  text-align: center;
  ${media.small`
     font-size: 1.2rem;
  `};
`;

export const FromTo = styled.div`
  width: 30%;
  white-space: nowrap;
  text-align: center;
  ${media.small`
     font-size: 1.2rem;
  `};
`;

export const OrderNow = styled(Button3)`
  text-transform: uppercase;
  margin-top: 4rem;
  color: ${props => props.theme.secondaryColor};
  border-color: ${props => props.theme.secondaryColor};
  &:hover {
    color: ${props => props.theme.primaryTextColor};
  }
  ${media.medium`
     margin-top: 2rem;
  `};
`;

export const Branchs = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const BranchText = styled(ContentText)`
  width: 50%;
  ${media.small`
    width: 100%;
  `};
`;

export const BranchDetails = styled.div`
  display: flex;
  width: 50%;
`;

export const BranchContent = styled.div`
  padding-right: 2rem;
  width: 50%;
`;

export const Postcode = styled.div`
  width: 50%;
`;

export const ViewBranch = styled.a`
  text-decoration: none;
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
`;

export const TheLink = styled(Link)`
  color: ${p => p.theme.primaryColor};
`;

export const Text = styled.p`
  margin: 0 0 1rem 0;
`;
