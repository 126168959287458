import React, {PureComponent} from 'react';
import unavailable_s from '../../images/products/unavailable_s.png';
import unavailable_m from '../../images/products/unavailable_m.png';
import unavailable_l from '../../images/products/unavailable_l.png';
import {IMAGE_UNAVAILABLE_SIZE} from '../../constants/constants';
import {useWebp} from '../../helpers/image.helper';
import {Img} from './Image.style';

export default class ImageComponent extends PureComponent {
  state = {
    error: false,
  };

  handleImageError = () => this.setState({error: true});

  render() {
    const {url, alt, type, onClickHandler, isLazyLoad} = this.props;
    let imageUnavailableUrl = unavailable_m;
    if (type === IMAGE_UNAVAILABLE_SIZE.S) {
      imageUnavailableUrl = unavailable_s;
    }
    if (type === IMAGE_UNAVAILABLE_SIZE.L) {
      imageUnavailableUrl = unavailable_l;
    }
    if (this.state.error || !url) {
      return <Img src={imageUnavailableUrl} alt={alt} />;
    }
    return (
      <picture>
        <source srcSet={useWebp(url)} type="image/webp" />
        <Img
          data-src={url}
          src={url}
          alt={alt}
          onError={this.handleImageError}
          onClick={onClickHandler}
          className={isLazyLoad ? 'lazyload' : ''}
        />
      </picture>
    );
  }
}
