import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;
`;

export const PopularImage = styled.img`
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 0.3rem;
`;
