import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function blogCategoriesReducer(
  state = initialState.blogCategories,
  action
) {
  switch (action.type) {
    case types.GET_BLOG_CATEGORIES_HOME_INFO_SUCCESS:
      return action.categoriesHomeData || null;
    case types.GET_BLOG_CATEGORIES_HOME_INFO_REQUESTED:
      return null;
    default:
      return state;
  }
}
