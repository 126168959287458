import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getBanners} from '../../actions/cms.action';
import Banners from '../../components/Banners/Banners';
import BranchLocator from '../../components/BranchLocator/BranchLocator';
import MarketingGrids from '../../components/MarketingGrids/MarketingGrids';
import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENTS,
  PROTOCOL_REGEX,
  ROUTES,
} from '../../constants/constants';
import {setCurrentRoute} from '../../actions';
import {preloadImage} from '../../helpers/image.helper';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {resetNextRoute} from '../../actions/nextRoute.action';
import {getShopBySectors} from '../../actions/jjContent.action';
import ShopBySectors from '../../components/ShopBySectors/ShopBySectors';

class HomePage extends PureComponent {
  static propTypes = {
    offers: PropTypes.array,
  };

  constructor(props) {
    super(props);
    if (props.cms && props.cms.banners && props.cms.banners.topBanners) {
      preloadImage(props.cms.banners.topBanners);
      this.preloadTopBanners = true;
    }
  }

  componentDidMount() {
    const {setCurrentRoute, getBanners, getShopBySectors} = this.props;
    setCurrentRoute(ROUTES.HOME);
    getBanners();
    getShopBySectors();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {cms, history, nextRoute} = this.props;
    if (
      cms &&
      cms.banners &&
      cms.banners.topBanners &&
      !this.preloadTopBanners
    ) {
      this.preloadTopBanners = true;
      preloadImage(cms.banners.topBanners);
    }

    // for login redirect
    if (nextRoute) {
      const redirectRoute = nextRoute;
      resetNextRoute();
      history.push(redirectRoute);
    }
  }

  onLocatorClick = postcode =>
    this.props.history.push(`/branchLocator/${postcode}`);

  onBannerClick = (url, title, index) => {
    const bannerTitle = GoogleAnalyticsHelper.getAnalyticsTopBannerEventName(
      index
    );
    const data = {
      url,
      title: bannerTitle,
      category: ANALYTICS_EVENT_CATEGORIES.HOMEPAGE_BANNERS,
    };
    GoogleAnalyticsHelper.trackCustomEvent(
      bannerTitle,
      data,
      ANALYTICS_EVENTS.GENERIC_BANNER_CLICK,
      data
    );
    const protocol = new RegExp(PROTOCOL_REGEX, 'i');
    if (protocol.test(url) && window) {
      const win = window.open(url, 'blank');
      win.focus();
    } else {
      if (url.indexOf('?') > -1) {
        const urlObj = url.split('?');
        this.props.history.push({
          pathname: urlObj[0],
          search: urlObj[1],
          state: {
            searchTitle: title,
          },
        });
      } else {
        this.props.history.push(url);
      }
    }
  };

  render() {
    const {branch, history, cms, isMobile, shopBySectors} = this.props;
    const renderBanners = cms && cms.banners && cms.banners.topBanners && (
      <Banners
        ads={cms.banners.topBanners}
        callback={this.onBannerClick}
        isMobile={isMobile}
      />
    );
    const renderMarketingGrid1 = cms &&
      cms.banners &&
      cms.banners.lowerBanners && (
        <MarketingGrids
          history={history}
          branch={branch}
          ads={cms.banners.lowerBanners.slice(0, 2)}
          isMobile={isMobile}
        />
      );
    const renderMarketingGrid2 = cms &&
      cms.banners &&
      cms.banners.lowerBanners && (
        <MarketingGrids
          history={history}
          branch={branch}
          ads={cms.banners.lowerBanners.slice(2, 4)}
          isMobile={isMobile}
          noGap={true}
        />
      );

    const renderShopBySectors = shopBySectors && (
      <ShopBySectors shopBySectors={shopBySectors} />
    );
    return (
      <Fragment>
        {renderBanners}
        <BranchLocator onLocatorClick={this.onLocatorClick} />
        {renderMarketingGrid1}
        {renderMarketingGrid2}
        {renderShopBySectors}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
  error: state.error,
  branch: state.branch,
  auth: state.auth,
  basket: state.basket,
  basketHashMap: state.basketHashMap,
  cms: state.cms,
  nextRoute: state.nextRoute,
  isMobile: state.isMobile,
  shopBySectors: state.shopBySectors,
});

const mapDispatchToProps = dispatch => ({
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  getBanners: bindActionCreators(getBanners, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
  getShopBySectors: bindActionCreators(getShopBySectors, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
