import styled from 'styled-components';

export const Title = styled.div`
  font-size: 6rem;
  padding: 2rem;
  text-align: center;
  color: ${p => p.theme.tertiaryColor};
`;

export const Text = styled.div`
  font-size: 3rem;
  text-align: center;
  color: ${p => p.theme.textColor};
`;
