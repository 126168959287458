import React, {PureComponent} from 'react';
import PopupModal from './PopupModal';
import {AX_ERRORS} from '../../constants/constants';

export default class CheckoutErrorsModal extends PureComponent {
  state = {
    message: {
      title: '',
      content: '',
    },
  };

  componentDidMount() {
    const {errors} = this.props;
    if (errors.length > 0) {
      errors.forEach(err => this.setContent(err.code));
    }
  }

  setContent = code => {
    switch (code) {
      case AX_ERRORS.QUANTITY_UNAVAILABLE: {
        this.setState({
          message: {
            title: 'Product quantity not available',
            content: 'Please review your basket',
          },
        });
        break;
      }
      case AX_ERRORS.UNAVAILABLE: {
        this.setState({
          message: {
            title: 'Product not available',
            content: 'Please review your basket',
          },
        });
        break;
      }
      case AX_ERRORS.DAILY_LIMIT: {
        this.setState({
          message: {
            title: 'Product is over daily limit',
            content: 'Please review your basket',
          },
        });
        break;
      }
      case AX_ERRORS.OUT_OF_STOCK: {
        this.setState({
          message: {
            title: 'Product is out of stock',
            content: 'Please find alternative items',
          },
        });
        break;
      }
      default: {
        this.setState({
          message: {
            title: 'Failing to validate the basket',
            content: 'Please try again',
          },
        });
      }
    }
  };

  render() {
    const {showModal, callback} = this.props;
    const {message} = this.state;
    return (
      <PopupModal
        content={message.content}
        title={message.title}
        isError={true}
        showModal={showModal}
        callback={callback}
        showOKButton={true}
        showCancelButton={false}
        closeModalCallback={callback}
      />
    );
  }
}
