import queryString from 'query-string';
import {
  AD_ERRORS,
  BASKET_ERRORS_TYPE,
  JJ_APOLLO,
  POSTCODE_REGEX,
  PRODUCT_DESCRIPTION_REGEX,
  RESERVED_WORD,
  ROUTES,
} from '../constants/constants';

export const getItemPlural = itemLength => (itemLength > 1 ? `items` : 'item');

export const getPlural = itemLength => (itemLength > 1 ? `are` : 'is');

export const getCancelOrEdit = cancel => (cancel ? `cancel` : 'edit');

export const formatPostcode = postCode => {
  let parts = postCode.match(POSTCODE_REGEX);
  if (parts && parts.length > 0) {
    parts.shift();
    return parts.join(' ').toUpperCase();
  } else {
    return postCode.toUpperCase();
  }
};

export const wrapLongDescription = description =>
  description.replace(PRODUCT_DESCRIPTION_REGEX, '($1) ');

export const getBasketErrorMessage = type => {
  let message = 'Product cannot be added';
  switch (type) {
    case BASKET_ERRORS_TYPE.PAYMENT_TYPE:
      message = 'Payment cannot be updated';
      break;
    case BASKET_ERRORS_TYPE.REMOVE:
      message = 'Product cannot be remove';
      break;
    case BASKET_ERRORS_TYPE.UPDATE:
      message = 'Basket cannot be update';
      break;
    case BASKET_ERRORS_TYPE.SLOT_EXPIRED:
    case BASKET_ERRORS_TYPE.SLOT_EXPIRED_DURING_CHECKOUT:
      message = 'Order cannot be processed';
  }
  return message;
};

export const getTopBarWelcomeText = (
  profile,
  isStaffAccount,
  email,
  isLoggedIn,
  isSSRCookie
) => {
  if (isSSRCookie) {
    return `Welcome ${decodeURIComponent(isSSRCookie)}`;
  }
  let message = `Welcome to JJ's online store`;
  if (!isLoggedIn) {
    return message;
  }
  if (isStaffAccount && !profile && email) {
    message = `Welcome ${email}`;
  } else if (profile && profile.name !== RESERVED_WORD.PRIVATE) {
    message = `Welcome ${profile.name || profile.tradingName}`;
  } else {
    message = 'Welcome back';
  }
  return message;
};

export const getLoginError = error => {
  if (
    error &&
    error.details &&
    error.details.indexOf(AD_ERRORS.LOGIN_CANCELLED) > -1
  ) {
    return 'You have NOT completed the registration process. Log back in to pick up registration where you left off.';
  }
  return error.details || error;
};

export const getCallbackUrlFromUrl = routeString => {
  const fullUrl = routeString.split('/confirmation/');
  if (fullUrl.length !== 2) {
    return null;
  }
  const param = fullUrl[1].split('?');
  const queryParams = queryString.parse(param[1]);
  if (param[0]) {
    return `?basketUuid=${param[0]}&callback=${queryParams.callback}`;
  }
};

export const getBackButtonText = route => {
  let pageName = 'Search Results';
  if (route === ROUTES.FAVOURITES) {
    pageName = 'Favourites';
  }
  if (route === ROUTES.RECENT_PURCHASES) {
    pageName = 'Recent Purchases';
  }
  if (route === ROUTES.OFFERS) {
    pageName = 'My Offers';
  }
  if (route === ROUTES.SHOPPING_LISTS_CONTENT) {
    pageName = 'My Shopping Lists';
  }
  return ` Back To ${pageName} `;
};

export const capitalizeString = string => {
  const lowerCasedString = (string || '').toLowerCase();
  return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
};

export const generateAppRedirectHtml = (url, appUrl, sessionStorageData) => {
  const sessionStorageItems = sessionStorageData
    .map(sessionStorageDataItem => {
      return `if ('${sessionStorageDataItem &&
        sessionStorageDataItem.key}'.length > 0 && '${sessionStorageDataItem &&
        sessionStorageDataItem.value}'.length > 0) {
      sessionStorage.setItem('${sessionStorageDataItem.key}', JSON.stringify('${
        sessionStorageDataItem.value
      }'));   
    };\n\n`;
    })
    .join('');

  return `<html lang='en'>
    <head>
      <title>Redirecting...</title>
      <style>
        body { text-align: center; }
        h1 {
            line-height: 1.15;
            -webkit-text-size-adjust: 100%;
            font-family: "Open Sans", sans-serif;
            -webkit-font-smoothing: antialiased;
            box-sizing: border-box;
            margin: .67em 0;
            color: rgb(38, 174, 93);
            text-transform: capitalize;
            font-size: 2.6rem;
            font-weight: lighter;
            display: inline-block;
            padding-left: 1rem;
            padding-right: 1rem;
            text-align: center;
        }
        p {
          -webkit-text-size-adjust: 100%;
          font-family: "Open Sans", sans-serif;
          -webkit-font-smoothing: antialiased;
          text-align: center;
          box-sizing: border-box;
          color: rgb(121, 121, 121);
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        p a {
          line-height: 1.15;
          -webkit-text-size-adjust: 100%;
          font-family: "Open Sans", sans-serif;
          -webkit-font-smoothing: antialiased;
          box-sizing: border-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          text-decoration: none;
          cursor: pointer;
          color: rgb(38, 174, 93);
          background-color: transparent;
          border: 0.2rem solid rgb(38, 174, 93);
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          height: 4rem;
          outline: none;
          text-align: center;
          padding: 0px 1rem;
          max-width: 60%;
          margin: 0 auto;
        }
      </style> 
    </head>
    <body>
    <h1>Please wait...</h1>
    <script>
    ${sessionStorageItems}
    var now = new Date().valueOf();
    setTimeout(function () {
        if (new Date().valueOf() - now > 100) return;
        window.location.href = '${url}';
    }, 50);
    
    window.location.href = '${appUrl}';
    </script>
    <p><a href='${appUrl}'>Click here if you are not redirected automatically</a></p>
</body></html>`;
};

export const generateCodeVerifierHtml = (url, sessionStorageData) => {
  const sessionStorageItems = sessionStorageData
    .map(sessionStorageDataItem => {
      return `if ('${sessionStorageDataItem &&
        sessionStorageDataItem.key}'.length > 0 && '${sessionStorageDataItem &&
        sessionStorageDataItem.value}'.length > 0) {
      sessionStorage.setItem('${sessionStorageDataItem.key}', JSON.stringify('${
        sessionStorageDataItem.value
      }'));   
    };\n\n`;
    })
    .join('');
  return `<html lang='en'>
    <head>
      <title>Redirecting...</title>
      <style>
        body { text-align: center; }
        h1 {
            line-height: 1.15;
            -webkit-text-size-adjust: 100%;
            font-family: "Open Sans", sans-serif;
            -webkit-font-smoothing: antialiased;
            box-sizing: border-box;
            margin: .67em 0;
            color: rgb(38, 174, 93);
            text-transform: capitalize;
            font-size: 2.6rem;
            font-weight: lighter;
            display: inline-block;
            padding-left: 1rem;
            padding-right: 1rem;
            text-align: center;
        }
        p {
          -webkit-text-size-adjust: 100%;
          font-family: "Open Sans", sans-serif;
          -webkit-font-smoothing: antialiased;
          text-align: center;
          box-sizing: border-box;
          color: rgb(121, 121, 121);
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        p a {
          line-height: 1.15;
          -webkit-text-size-adjust: 100%;
          font-family: "Open Sans", sans-serif;
          -webkit-font-smoothing: antialiased;
          box-sizing: border-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          text-decoration: none;
          cursor: pointer;
          color: rgb(38, 174, 93);
          background-color: transparent;
          border: 0.2rem solid rgb(38, 174, 93);
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          height: 4rem;
          outline: none;
          text-align: center;
          padding: 0px 1rem;
          max-width: 60%;
          margin: 0 auto;
        }
      </style> 
    </head>
    <body>
    <h1>Please wait...</h1>
    <script>
    ${sessionStorageItems}
    window.location.href = '${url}';
    </script>
    <p><a href='${url}'>Click here if you are not redirected automatically</a></p>
</body></html>`;
};

export const generateAppLink = req => {
  const query = queryString.stringify(req.query);
  const fullUrl = (req.params[0] || '').toLowerCase().split('/');
  const route = fullUrl[0].toUpperCase();
  const fullParams = fullUrl[1] ? fullUrl[1].split('/') : '';
  const screen = fullParams[0] ? '/' + fullParams[0] : '';
  const params = fullParams[1] ? '/' + fullParams[1] : '';
  return `${JJ_APOLLO.URI}${JJ_APOLLO.ROUTES[route]}${screen}${params}?${query}`;
};

export const getAvailableAtDateString = dateArray => {
  if (!dateArray || (dateArray && !dateArray[1] && dateArray[2])) {
    return '';
  }
  const day = dateArray[2] < 10 ? '0' + dateArray[2] : dateArray[2];
  const month = dateArray[1] < 10 ? '0' + dateArray[1] : dateArray[1];
  return 'Available from ' + day + '/' + month;
};

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

export const markdownConvert = str => {
  if (!str) {
    return '';
  }
  return str
    .replace(/<span\s+class="([^"]*)"[^>]*>(.*?)<\/span>/g, '$2')
    .replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g, '[$2]($1)')
    .replace(
      /<img\s+(?:[^>]*?\s+)?src="([^"]*)"[^>]*\s*alt="([^"]*)"\s*\/?>/g,
      '![$2]($1)'
    )
    .replace(/<br\s*\/?\s*>/g, '  \n');
};
