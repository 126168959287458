import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function initReducer(
  state = initialState.fulfillmentDetails,
  action
) {
  switch (action.type) {
    case types.SET_FULFILLMENT_DETAILS_SUCCESS:
      return action.fulfillmentDetails;
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
