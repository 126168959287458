import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class marketingApi {
  static getMarketingOptions(email, headers) {
    const config = getApiConfig();
    const url = config.getMarketingOptionsApi;
    return axios.get(`${url}/${email}`, {headers}).then(res => res.data);
  }
  static updateMarketingOptions(marketingOptions, email, headers) {
    const config = getApiConfig();
    const url = config.updateMarketingOptionsApi;
    return axios
      .put(`${url}/${email}/${marketingOptions.patchUrl}`, marketingOptions, {
        headers,
      })
      .then(res => res.data)
      .catch(e => e);
  }
}
