import * as ActionTypes from './types.action';

export const setAuth = auth => ({
  type: ActionTypes.SET_AUTH_SUCCESS,
  auth,
});

export const setLoginToken = loginToken => ({
  type: ActionTypes.SET_LOGIN_TOKEN_SUCCESS,
  loginToken,
});

export const removeAuth = () => ({
  type: ActionTypes.REMOVE_AUTH_REQUESTED,
});

export const removeLoginToken = () => ({
  type: ActionTypes.REMOVE_LOGIN_TOKEN_REQUESTED,
});

export const refreshAuth = refreshToken => ({
  type: ActionTypes.REFRESH_AUTH_REQUESTED,
  refreshToken,
});

export const getAuth = () => ({
  type: ActionTypes.GET_AUTH_REQUESTED,
});

export const noAuthToken = () => ({
  type: ActionTypes.GET_AUTH_FAILED,
});

export const getTokenByCode = (
  codeToToken,
  isStaffAccount,
  domain,
  isRegister
) => ({
  type: ActionTypes.GET_LOGIN_TOKEN_REQUESTED,
  codeToToken,
  isStaffAccount,
  domain,
  isRegister,
});

export const reGetTokenByCode = (
  codeToToken,
  cAccount,
  isStaffAccount,
  domain
) => ({
  type: ActionTypes.GET_LOGIN_TOKEN_REQUESTED,
  codeToToken,
  cAccount,
  isStaffAccount,
  domain,
});

export const getTokenByPassword = data => ({
  type: ActionTypes.GET_TOKEN_BY_PASSWORD_REQUESTED,
  data,
});

export const requestForceLogin = () => ({
  type: ActionTypes.FORCE_LOGIN_REQUESTED,
});
