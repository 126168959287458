import {put, call, takeLatest, select} from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import authApi from '../api/auth.api';
import * as ActionTypes from '../actions/types.action';
import queryString from 'query-string';

import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
} from '../helpers/localStorage.helper';
import {ID, URL, JJ_LOCAL_STORAGE} from '../constants/constants';
import {getApiConfig} from '../config/configProvider';
import {saveTokenValidTime} from '../helpers/data.helper';

function* logout() {
  yield put({type: ActionTypes.REMOVE_AUTH_SUCCESS});
}

function* refresh(action) {
  try {
    const conf = getApiConfig();
    const getAuth = state => state.auth;
    let tokenObj = yield select(getAuth);
    let expiredToken;
    let refreshToken;
    if (tokenObj) {
      expiredToken = tokenObj;
    }
    if (!expiredToken) {
      const token = getDataFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN);
      expiredToken = jwt_decode(token);
    }
    if (!action.refreshToken) {
      refreshToken = getDataFromLocalStorage(JJ_LOCAL_STORAGE.REFRESH_TOKEN);
    }
    if (!action.refreshToken || !expiredToken || refreshToken) {
      return;
    }
    const data = {
      refresh_token: action.refreshToken || refreshToken,
      grant_type: 'refresh_token',
      scope: conf.azureScopes,
      account: expiredToken.c_account,
      access_token: expiredToken.access_token,
    };
    const auth = yield call(authApi.refresh, data);
    if (auth && auth.access_token) {
      const {access_token, refresh_token} = auth;
      yield put({type: ActionTypes.REFRESH_AUTH_SUCCESS, access_token});
      const decodeToken = jwt_decode(access_token);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.TOKEN, access_token);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.REFRESH_TOKEN, refresh_token);
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.TOKEN_VALID_UNTIL,
        decodeToken.exp
      );
    }
  } catch (e) {
    yield put({type: ActionTypes.REFRESH_AUTH_FAILED, message: e.message});
  }
}
function* getLoginToken(action) {
  try {
    const conf = getApiConfig();
    const reLoginRedirectUrl = !!conf
      ? conf.reLoginRedirectUrl
      : '/reLoginSuccess';
    const azureRedirectUrl = !!conf ? conf.azureRedirectUrl : '/success';
    const redirect_uri = `https://${action.domain}${
      !!action.cAccount ? reLoginRedirectUrl : azureRedirectUrl
    }`;
    const isDev = window.location.host.indexOf(URL.JJS_PROD) === -1;
    const azureAdClientId = !!conf ? conf.azureAdClientId : ID.AZURE_AD_CLIENT;
    const azureClientId = !!conf
      ? conf.azureClientId
      : isDev
      ? ID.AZURE_CLIENT_DEV
      : ID.AZURE_CLIENT_PROD;
    const data = {
      code: action.codeToToken.code,
      code_verifier: action.codeToToken.code_verifier,
      grant_type: 'authorization_code',
      client_id: action.isStaffAccount ? azureAdClientId : azureClientId,
      redirect_uri,
    };
    const azureAuthUrl = !!conf
      ? conf.azureAuthUrl
      : isDev
      ? URL.AZURE_AUTH_DEV
      : URL.AZURE_AUTH_PROD;
    const azureAdAuthUrl = !!conf ? conf.azureAdAuthUrl : URL.AZURE_AD;
    const azureToken = yield call(
      authApi.getLoginToken,
      queryString.stringify(data),
      action.isStaffAccount
        ? azureAdAuthUrl
        : action.isRegister
        ? conf.azureRegisterUrl
        : azureAuthUrl
    );
    if (azureToken && azureToken.access_token) {
      // set reloginAccount to true when it requires relogin
      if (action.cAccount) {
        yield put({type: ActionTypes.SET_RELOGIN, cAccount: action.cAccount});
      }
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.LOGIN_TOKEN,
        azureToken.access_token
      );
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.LAST_AUTH_TIME,
        new Date().getTime()
      );
      yield put({
        type: ActionTypes.SET_LOGIN_TOKEN_SUCCESS,
        loginToken: azureToken.access_token,
      });
    } else {
      yield put({
        type: ActionTypes.SET_LOGIN_TOKEN_FAILED,
        message: 'failed to get access token',
      });
    }
    if (azureToken && azureToken.refresh_token) {
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.REFRESH_TOKEN,
        azureToken.refresh_token
      );
      yield put({
        type: ActionTypes.SET_REFRESH_TOKEN_SUCCESS,
        refreshToken: azureToken.refresh_token,
      });
    }
    if (azureToken.expires_in) {
      saveTokenValidTime(azureToken.expires_in);
    }
  } catch (e) {
    alert(e);
    yield put({type: ActionTypes.SET_LOGIN_TOKEN_FAILED, message: e});
  }
}

function* getTokenByPassword(action) {
  try {
    const result = yield call(authApi.relogin, action.data);
    if (result && result.access_token) {
      yield put({
        type: ActionTypes.GET_TOKEN_BY_PASSWORD_SUCCESS,
        access_token: result.access_token,
      });
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.TOKEN, result.access_token);
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.LAST_AUTH_TIME,
        new Date().getTime()
      );
    } else {
      yield put({
        type: ActionTypes.GET_TOKEN_BY_PASSWORD_FAILED,
        message: 'No access tokens returned',
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.GET_TOKEN_BY_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

export default function* authYield() {
  yield takeLatest(ActionTypes.REMOVE_AUTH_REQUESTED, logout);
  yield takeLatest(ActionTypes.REFRESH_AUTH_REQUESTED, refresh);
  yield takeLatest(ActionTypes.GET_LOGIN_TOKEN_REQUESTED, getLoginToken);
  yield takeLatest(
    ActionTypes.GET_TOKEN_BY_PASSWORD_REQUESTED,
    getTokenByPassword
  );
}
