export const getBraintreeInitObj = (
  token,
  price,
  allowPaypal,
  allowApplePay,
  allowGooglePay
) => {
  const obj = {
    authorization: token,
    container: '#dropin-container',
    threeDSecure: true,
    showDefaultPaymentMethodFirst: true,
    dataCollector: true,
    vaultManager: true,
    card: {
      cardholderName: {
        required: true,
      },
    },
  };
  if (allowPaypal) {
    obj.paypal = {flow: 'vault'};
  }
  if (allowApplePay) {
    obj.applePay = {
      displayName: 'Apple Pay',
      paymentRequest: {
        total: {
          label: 'Order Amount',
          amount: price,
        },
        requiredBillingContactFields: ['postalAddress'],
      },
    };
  }
  if (allowGooglePay) {
    obj.googlePay = {
      googlePayVersion: 2,
      merchantId: 'merchant-id-from-google',
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: price.toString(),
        currencyCode: 'GBP',
      },
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL',
            },
          },
        },
      ],
    };
  }
  return obj;
};
