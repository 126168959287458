import React from 'react';
import {PostBar, PostBlock, PostBlockImg, PostTitle} from './BlogPage.style';
export const PostBlockItem = ({
  postImage,
  categorySlug,
  lastPublishedDate,
  title,
  slug,
  minutesToRead,
}) => {
  return (
    <PostBlock to={`/blog/${categorySlug}/${slug}`}>
      <PostBlockImg $bgImg={postImage} />
      <PostBar>
        <div>{lastPublishedDate}</div>
        {minutesToRead}
      </PostBar>
      <PostTitle>{title}</PostTitle>
    </PostBlock>
  );
};
