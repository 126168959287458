import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
`;

export const Content = styled.div`
  width: 80rem;
  padding: 2rem;
  background-color: ${p => p.theme.primaryColor};
  ${media.small`
    width: 100%;
  `};
`;

export const Title = styled.div`
  width: 26rem;
  font-size: 2rem;
  color: ${p => p.theme.primaryTextColor};
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: ${p => p.theme.secondaryColor};
  ${media.small`
    width: 100%;
  `};
`;

export const FormWrapper = styled.form`
  padding: 2rem;
  min-height: 12rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  ${media.small`
    flex-direction: column;
  `};
`;

export const Panel = styled.div`
  width: 50%;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.smaller`
    width: 100%;
  `};
`;

export const ErrorsField = styled.div`
  min-height: 1.6rem;
  padding: 0 0.4rem;
`;

export const Text = styled.div`
  color: ${p => p.theme.textColor};
  font-size: 1.2rem;
  padding: 0 0.5rem;
`;

export const Error = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
