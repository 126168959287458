import {NEVER} from '../constants/constants';

export class ServerCacheHelper {
  static SERVER_CACHE = {};

  static set(key, value, cacheTime) {
    if (!key) {
      return;
    }
    const expiresAt = cacheTime ? Date.now() + cacheTime : NEVER;
    this.SERVER_CACHE[key] = {key, value, expiresAt};
  }

  static get(key) {
    if (!key) {
      return;
    }
    if (
      !this.SERVER_CACHE[key] ||
      (this.SERVER_CACHE[key].expiresAt &&
        this.SERVER_CACHE[key].expiresAt < Date.now())
    ) {
      this.SERVER_CACHE[key] = null;
      return null;
    }
    return this.SERVER_CACHE[key].value;
  }

  static clear() {
    this.SERVER_CACHE = {};
  }
}
