import styled from 'styled-components';
import {media} from './media-queries';

export const Dropdown = styled.select`
  outline: none;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  appearance: none;
  height: 4rem;
  background: transparent;
  border: ${props => `solid 0.2rem ${props.theme.primaryTextColor}`};
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 1.4rem;
  color: ${props => props.theme.primaryTextColor};
  ${media.smallest`
    padding-right: 1rem;
  `};
`;

export const DropdownStandard = styled.select`
  outline: none;
  width: 100%;
  cursor: pointer;
  border-radius: 0;
  height: 4rem;
  background: transparent;
  text-transform: uppercase;
  border: ${p => `solid 0.1rem ${p.theme.borderColor}`};
  font-size: 1.4rem;
  color: ${props => props.theme.textColor};
  text-indent: 1rem;
  ${media.small`
    text-indent: 0;
  `};
`;
