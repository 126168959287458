import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function checkoutReducer(state = initialState.checkout, action) {
  switch (action.type) {
    case types.PROCESS_CHECKOUT_SUCCESS:
      return action.checkout;
    case types.RESET_CHECKOUT_REQUESTED:
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
