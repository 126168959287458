import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Wrapper} from './Thumbnail.style';
import {Block, Content} from './ProductTab.style';

export default class ProductTab extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    number: PropTypes.number,
  };

  render() {
    const {name, content} = this.props;
    return (
      <Wrapper>
        <Block>{name}</Block>
        <Content>{content}</Content>
      </Wrapper>
    );
  }
}
