import React, {Fragment, PureComponent} from 'react';
import PaymentPanel from '../PaymentPanel/PaymentPanel';
import {DEFAULT_PAYMENT_TYPE, PAYMENT_TYPES} from '../../constants/constants';
import {getAllSettings} from '../../actions/settings.action';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  PaymentSelectorWrapper,
  PromoInfoText,
  Title,
  CannotChangePaymentType,
} from './PaymentSelector.style';
import {setPaymentType} from '../../actions';
import {
  getPromoInfo,
  isCW2Charge,
  isSameDayDelivery,
} from '../../helpers/basket.helper';
import {setIsUseDefaultPbb} from '../../actions/isUseDefaultPbb.action';

class PaymentSelector extends PureComponent {
  state = {
    isSameDayDelivery: false,
    isCW2Delivery: false,
    promoInfo: '',
  };

  componentDidMount() {
    const {
      settings,
      getAllSettings,
      slot,
      fulfillmentType,
      fulfillmentStatus,
      promoTotal,
      paymentMethod,
      paymentOptions,
    } = this.props;
    if (!settings) {
      getAllSettings();
    }
    let promoInfo = '';
    // if (promoTotal) {
    //   promoInfo = getPromoInfo(promoTotal, paymentMethod, paymentOptions);
    // }
    this.setState({
      promoInfo,
      isSameDayDelivery:
        !!slot && isSameDayDelivery(slot, fulfillmentType, fulfillmentStatus),
      isCW2Delivery: !!slot && isCW2Charge(slot, fulfillmentType),
    });
  }

  componentDidUpdate(prevProps) {
    const {promoTotal, paymentMethod, paymentOptions} = this.props;
    if (
      (!prevProps.promoTotal && !this.state.promoInfo) ||
      (paymentMethod !== prevProps.paymentMethod && promoTotal)
    ) {
      // const promoInfo = getPromoInfo(promoTotal, paymentMethod, paymentOptions);
      // this.setState({promoInfo});
    }
  }

  changePaymentType = paymentType => {
    const {setPaymentType, basketUuid} = this.props;
    if (basketUuid) {
      setPaymentType({paymentMethod: paymentType});
    }
  };
  setUsePbbDefaultBank = value => {
    const {setIsUseDefaultPbb, isBasketPage, navToBasket} = this.props;
    setIsUseDefaultPbb(value);
    if (!isBasketPage) {
      navToBasket();
    }
  };

  render() {
    const {
      paymentOptions,
      paymentMethod,
      promoTotal,
      isEditMode,
      isBasketPage,
      editOrderOriginalPaymentMethod,
      pbbBankDetails,
      isUseDefaultPbb,
      basket,
      settings,
    } = this.props;
    const {isSameDayDelivery, isCW2Delivery, promoInfo} = this.state;
    const paymentMethodLocked =
      (editOrderOriginalPaymentMethod === PAYMENT_TYPES.CDC.mode ||
        editOrderOriginalPaymentMethod === PAYMENT_TYPES.BCDC.mode) &&
      isEditMode &&
      (paymentMethod === PAYMENT_TYPES.CDC.mode ||
        paymentMethod === PAYMENT_TYPES.BCDC.mode);
    const renderCannotChangePaymentType = paymentMethodLocked &&
      isEditMode &&
      paymentOptions &&
      paymentOptions.length > 1 && (
        <CannotChangePaymentType>
          Cannot change payment type when editing an order
        </CannotChangePaymentType>
      );
    const renderSameDayDelivery = isSameDayDelivery && !isEditMode && (
      <CannotChangePaymentType>
        Same day deliveries must be paid online
      </CannotChangePaymentType>
    );
    const renderCW2Charge = isCW2Delivery && !isEditMode && (
      <CannotChangePaymentType>
        Only card payment available for this slot
      </CannotChangePaymentType>
    );
    const renderCardPromoInfo = !!promoTotal && !!promoInfo && (
      <PromoInfoText>{promoInfo}</PromoInfoText>
    );
    const renderTitle = paymentOptions && paymentOptions.length > 1 && (
      <Title>Choose your payment type</Title>
    );
    return (
      <Fragment>
        <PaymentSelectorWrapper>
          {renderTitle}
          {renderCardPromoInfo}
          {renderCannotChangePaymentType}
          {renderSameDayDelivery}
          {renderCW2Charge}
        </PaymentSelectorWrapper>
        <PaymentPanel
          callback={this.changePaymentType}
          paymentOptions={paymentOptions || DEFAULT_PAYMENT_TYPE}
          selectedPaymentType={paymentMethod}
          pbbBankDetails={pbbBankDetails}
          setUsePbbDefaultBank={this.setUsePbbDefaultBank}
          isUseDefaultPbb={isUseDefaultPbb}
          isBasketPage={isBasketPage}
          isEditMode={isEditMode}
          editOrderOriginalPaymentMethod={editOrderOriginalPaymentMethod}
          commercialCardCharge={basket && basket.commercialCardCharge}
          separatedCommercialOption={!!settings && settings.adyen === 'true'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  isUseDefaultPbb: state.isUseDefaultPbb,
  pbbBankDetails: state.pbbBankDetails,
  basket: state.basket,
});
const mapDispatchToProps = dispatch => ({
  getAllSettings: bindActionCreators(getAllSettings, dispatch),
  setPaymentType: bindActionCreators(setPaymentType, dispatch),
  setIsUseDefaultPbb: bindActionCreators(setIsUseDefaultPbb, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSelector);
