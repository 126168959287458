import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class vacanciesApi {
  static getVacancies(headers) {
    const config = getApiConfig();
    const url = config.vacanciesApi;
    return axios.get(url, {headers}).then(res => res.data);
  }
}
