import React, {Fragment, PureComponent} from 'react';
import moment from 'moment';

import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  SubContentBlock,
  Title,
  TextWrapper,
  OK,
  ButtonsWrapper,
  Cancel,
  Uppercase,
} from './PopupModal.style';
import Loading from '../Loading/Loading';
import {H_MM_A_FORMAT} from '../../constants/constants';
import {getSlotDisplayDate} from '../../helpers/data.helper';

export default class SlotFeeModal extends PureComponent {
  state = {
    cutOffInfo: '',
  };

  componentDidMount() {
    const {time} = this.props;
    if (time && time.dateTimeToCutOff) {
      const cutOffInfo = getSlotDisplayDate(time.dateTimeToCutOff);
      if (cutOffInfo) {
        this.setState({cutOffInfo});
      }
    }
  }

  onOkClicked = () => {
    const {isReservedOnly, reserve, checkout, hideModal} = this.props;
    hideModal();
    if (isReservedOnly) {
      reserve();
    } else {
      checkout();
    }
  };

  render() {
    const {
      showModal,
      isLoadingModal,
      closeModalCallback,
      isToday,
      isTomorrow,
      date,
      time,
    } = this.props;
    const {cutOffInfo} = this.state;
    const renderDate = isToday ? 'today' : isTomorrow ? 'tomorrow' : date;
    const renderCancelButton = (
      <Cancel
        onClick={closeModalCallback}
        data-rw="slot-fee-modal--cancel-button"
      >
        Cancel
      </Cancel>
    );
    const cutoffTime =
      (time &&
        time.dateTimeToCutOff &&
        moment(time.dateTimeToCutOff).format(H_MM_A_FORMAT)) ||
      '';
    const rendering = isLoadingModal ? (
      <Fragment>
        <Loading isLight={false} />
        {renderCancelButton}
      </Fragment>
    ) : (
      <ButtonsWrapper>
        {renderCancelButton}
        <OK onClick={this.onOkClicked} data-rw="slot-fee-modal--ok-button">
          OK
        </OK>
      </ButtonsWrapper>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw={'slot-fee-modal'}
      >
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>Your delivery slot is booked</Title>
              <SubContent>
                <SubContentBlock>
                  <Uppercase>- {renderDate}</Uppercase>
                  <div>- {`${time.from} - ${time.to}`}</div>
                  <div>
                    {time.chargeValue &&
                      `- £${time.chargeValue} delivery fee applies`}
                  </div>
                </SubContentBlock>
                {`You must checkout by ${cutoffTime} ${cutOffInfo} to keep this slot`}
              </SubContent>
              {rendering}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
