import * as ActionTypes from './types.action';

export const getCheckoutData = (
  basketUuid,
  paymentType,
  isBrainTree,
  isEcoSpend,
  orderSource
) => ({
  type: ActionTypes.PROCESS_CHECKOUT_REQUESTED,
  basketUuid,
  paymentType,
  isBrainTree,
  isEcoSpend,
  orderSource,
});

export const resetCheckout = () => ({
  type: ActionTypes.RESET_CHECKOUT_REQUESTED,
});

export const authorizeOrder = (order, basket) => ({
  type: ActionTypes.AUTHORIZE_NON_CARD_PAYMENT_REQUESTED,
  order,
  basket,
});

export const authBraintreePayment = (url, paymentData, orderId, fromApp) => ({
  type: ActionTypes.AUTHORIZE_BRAINTREE_PAYMENT_REQUESTED,
  url,
  paymentData,
  orderId,
  fromApp,
});

export const authAdyenPayment = (
  url,
  stateData,
  orderId,
  isAdditionalDetails,
  storedPaymentMethod
) => ({
  type: ActionTypes.AUTHORIZE_ADYEN_PAYMENT_REQUESTED,
  url,
  stateData,
  orderId,
  isAdditionalDetails,
  storedPaymentMethod,
});
